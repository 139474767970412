export const ColumnsUser = [
    {
        Header: "Nama",
        accessor: "name",
    },
    {
        Header: "Mitra",
        accessor: "mitra",
    },
    {
        Header: "Router",
        accessor: "router",
    },
    {
        Header: "Harga Beli",
        accessor: "buyingPrice"
    },
    {
        Header: "Harga Jual",
        accessor: "sellingPrice"
    },
    {
        Header: "Rate Up",
        accessor: "rateUp"
    },
    {
        Header: "Rate Down",
        accessor: "rateDown"
    },
    {
        Header: "View",
        accessor: "actionsUser",
        sortable: false,
        filterable: false,
    }
]

export const ColumnsAdmin = [
    {
        Header: "Nama",
        accessor: "name",
    },
    {
        Header: "Mitra",
        accessor: "mitra",
    },
    {
        Header: "Router",
        accessor: "router",
    },
    {
        Header: "Harga Beli",
        accessor: "buyingPrice"
    },
    {
        Header: "Harga Jual",
        accessor: "sellingPrice"
    },
    {
        Header: "Rate Up",
        accessor: "rateUp"
    },
    {
        Header: "Rate Down",
        accessor: "rateDown"
    },
    {
        Header: "Update  Trash",
        accessor: "actions",
        sortable: false,
        filterable: false,
    },
]

export const ColumnsTrashAdmin = [
    {
        Header: "Nama",
        accessor: "name",
    },
    {
        Header: "Mitra",
        accessor: "mitra",
    },
    {
        Header: "Router",
        accessor: "router",
    },
    {
        Header: "Harga Beli",
        accessor: "buyingPrice"
    },
    {
        Header: "Harga Jual",
        accessor: "sellingPrice"
    },
    {
        Header: "Rate Up",
        accessor: "rateUp"
    },
    {
        Header: "Rate Down",
        accessor: "rateDown"
    },
    {
        Header: "Recover",
        accessor: "recover",
        sortable: false,
        filterable: false,
    },
    {
        Header: "Hapus Permanen",
        accessor: "actionsTrash",
        sortable: false,
        filterable: false,
    },
]

export const ColumnsTrashUser = [
    {
        Header: "Nama",
        accessor: "name",
    },
    {
        Header: "Mitra",
        accessor: "mitra",
    },
    {
        Header: "Router",
        accessor: "router",
    },
    {
        Header: "Harga Beli",
        accessor: "buyingPrice"
    },
    {
        Header: "Harga Jual",
        accessor: "sellingPrice"
    },
    {
        Header: "Rate Up",
        accessor: "rateUp"
    },
    {
        Header: "Rate Down",
        accessor: "rateDown"
    },
]