export const columns = [
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "Add-On",
      accessor: "name",
    },
    {
      Header: "Mitra",
      accessor: "owner",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Provinsi",
      accessor: "province",
    },
    {
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      filterable: false,
    }]

export const trashColumns = [
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "Add-On",
      accessor: "name",
    },
    {
      Header: "Mitra",
      accessor: "owner",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Provinsi",
      accessor: "province",
    },
    {
      Header: "Recover",
      accessor: "recover",
      sortable: false,
      filterable: false,
    },
    {
      Header: "Delete",
      accessor: "delete",
      sortable: false,
      filterable: false,
    }]