import LoginService from '../LoginService'
import APIconsume from '../APIconsume'
import { getAccessToken } from "variables/common"

class HostpotFinanceReportService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'hostpot'
    }

    async getFinanceStatement (mitra = '', year = '', reseller = '') {
        const url = `${this._url}/FinanceReport?year=${year}&mitra=${mitra}&reseller=${reseller}`

        const result = await this._consume.getData(url)
        return result.data
    }

    async getYearSelect (mitra = '') {
        const url = `${this._url}/FinanceReport/yearselect?mitra=${mitra}`

        const result = await this._consume.getData(url)
        return result.data
    }
}

export default HostpotFinanceReportService