export const columns = [
    {
        Header: "Profile ID",
        accessor: "id",
    },
    {
        Header: "Nama",
        accessor: "name",
    },
    {
        Header: "PPP-Profile",
        accessor: "pppProfile",
    },
    {
        Header: "Mitra",
        accessor: "owner",
    },
    {
        Header: "Kategori",
        accessor: "category",
    },
    {
        Header: "Router",
        accessor: "router",
    },
    {
        Header: "Harga Final",
        accessor: "finalPrice",
    },
    {
        Header: "Update Hapus",
        accessor: "actions",
        sortable: false,
        filterable: false,
    },
]