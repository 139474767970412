import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {getRefreshToken, getSubscriptionHistoryData, PriceFormat,
  getPaymentStatus, putSubscriptionHistoryPayload, addSubscriptionHistoryData } from "./../../../variables/common"
import { CatchError } from "Main/Exceptions/exceptions";
import { DateTimestr } from "Main/Utils";
import SubscriptionAddOnHistoryList from "../Add-On/SubscriptionHistoryAddOn/SubscriptionAddOnHistoryList";
import { getSubscriptionHistoryAddOnList } from "variables/Add-On/SubscriptionHistoryAddOn";
import SubscriptionAddOnHistoryService from "Main/Add-On/SubscriptionHistoryAddOnService";
import { addSubscriptionHistoryAddOnList } from "variables/Add-On/SubscriptionHistoryAddOn";
import { addSubscriptionHistoryAddOnData } from "variables/Add-On/SubscriptionHistoryAddOn";
import { CalculatePrecentagesFromPriceDIfference, verifySuperAdmin } from "Main/Utils";
import { PrecentagesToNumberDifferenceCalculation } from "Main/Utils";
import swal from "sweetalert";
import BasicInput from "components/Input/BasicInput";
import BillingService from "Main/BillingService";
import LoadingScreen from "components/Loader/LoadingScreen";

function SubscriptionHistoryView(props) {
  const paymentStatus = getPaymentStatus()
  const billingService =  new BillingService()
  const subscriptionAddOnHistoryService = new SubscriptionAddOnHistoryService()
  const refreshToken = getRefreshToken()
  const [BillingData, setBillingData] = useState(getSubscriptionHistoryData())
  const subs = BillingData.Subscription
  const idHist = subs.historyId
  const name = subs.subscription
  const owner = subs.owner
  const customer = subs.customerName
  const item =  subs.itemName
  const address  =  subs.addressName
  const addOnPrice = subs.addOnPrice
  const administrationFee = subs.administrationFee
  const status = paymentStatus.find((data) => data.value === subs.paid) || {}
  const currency = 'idr'
  const dailyPrc = subs.dailyPrice
  const totalPrc = subs.totalPrice
  const monthlyPrc = subs.monthlyPrice
  const taxPrice = subs.taxPrc
  const itemPrc = subs.itemPrice
  const discountPackage = subs.discountItem || 0
  const totalPrice = PriceFormat(totalPrc, currency)
  const start = DateTimestr(subs.startSubscribe)
  const end = DateTimestr(subs.endSubscribe)
  const [subscriptionAddOnList, setSubscriptionAddOnList] = useState(getSubscriptionHistoryAddOnList())
  const [loadingAddOn, setLoadingAddOn] = useState(false)
  const [loading, setLoading] = useState(false)
  const totalPackage = Number(itemPrc) - Number(discountPackage)
  const { mitraSuper } = verifySuperAdmin()
  const discount = subs.discountSubs || 0
  const discountFromPrecentages = CalculatePrecentagesFromPriceDIfference(monthlyPrc, discount)
  const [discountSubs, setDIscountSubs] = useState(discount)
  const [discountSubsPrecentages,setDIscountSubsPrecentages] = useState(discountFromPrecentages)
  const { instalationFee, memberFee } = subs

  const EditHistory = async() => {
    swal("Update", "Change Data Can't be Undo", "info", {
                buttons: {
                update: {text: "Update", value: 'up' },
                cancel: 'Cancel'
              }
            }
      ).then(async(value) => {
        switch (value) {
          case 'up':
            try {
              const payload = putSubscriptionHistoryPayload(discountSubs)
              const result = await billingService.putSubscriptionHistory(idHist, payload)
              await swal('Success', result.message, result.status)
              await fetchSubscriptionHistoryData(idHist)
              return result
            } catch (error) {
              console.log(error)
              return await CatchError(error, props, refreshToken)
            }

          default:
            break;
        }
      })
    }

  useEffect(() => {
    fetchSubscriptionAddOn()
    fetchSubscriptionHistoryData(idHist)
  }, [])

  const fetchSubscriptionAddOn = async () => {
    setLoadingAddOn(true)
    const result = await subscriptionAddOnHistoryService.getAllData(idHist)
    const addOnList = await result.data
    addSubscriptionHistoryAddOnList(addOnList)
    setSubscriptionAddOnList(addOnList)
    setLoadingAddOn(false)
    return addOnList
  }

  const fetchSubscriptionHistoryData = async (id) => {
    setLoading(true)
    const result = await billingService.getSubscriptionHistory(id)
    const { data } = result
    addSubscriptionHistoryData(data)
    setBillingData(data)
    setLoading(false)
  }

 const deleteAddOn = async(id) => {
      return swal("Delete", "Data Can't Recover", "info", {
            buttons: {
            cancel: 'Cancel',
            delete: {text: "Delete", value: 'del' }
          }
        }
      ).then(async(value) => {
          switch (value) {
            case 'del':
              try {
                setLoadingAddOn(true)
                const result = await subscriptionAddOnHistoryService.deleteData(id)
                await swal(result.status, result.message, 'success')
                await fetchSubscriptionAddOn()
                await fetchSubscriptionHistoryData(idHist)
                setLoadingAddOn(false)
                return true
              } catch (error) {
                setLoadingAddOn(false)
                return await CatchError(error, props, refreshToken)
              }

            default:
              await fetchSubscriptionAddOn()
              window.location.reload()
              return false
          }
        })
      }

    const viewAddOn = async(id)=> {
      try {
        const dat = await subscriptionAddOnHistoryService.getData(id)
        const result = dat.data
        addSubscriptionHistoryAddOnData(result)
        props.history.push('/admin/subscription-history-add-on-view')
      } catch (error) {
        return await CatchError(error, props, refreshToken)
      }
    }

    const AddSubscriptionAddOn = () => {
      props.history.push('/admin/subscription-history-add-on-page')
    }

    const discountLimmiter = (discount) => {
      if (discount >= 0 && discount <= 100) {
        setDIscountSubsPrecentages(discount)
        setDIscountSubs(PrecentagesToNumberDifferenceCalculation(monthlyPrc, discount))
      }
    }

    const children = (
      <div className="content">
          <Row>
            <Col md="12">
              <SubscriptionAddOnHistoryList
                fetch = {fetchSubscriptionAddOn}
                add = {AddSubscriptionAddOn}
                view = {viewAddOn}
                deleted = {deleteAddOn}
                loaded = {loadingAddOn}
                dataTable = {subscriptionAddOnList}
              />
              <Card>
                <CardHeader>
                  <h5 className="title">{name}</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>

                        <BasicInput
                          key = {1}
                          label = {"Owner"}
                          value = {owner}
                          onChange = {() => {}}
                        />
                        <BasicInput
                          key = {2}
                          label = {"Customer"}
                          value = {customer}
                          onChange = {() => {}}
                        />
                        <BasicInput
                          key = {3}
                          label = {"Produk Layanan"}
                          value = {item}
                          onChange = {() => {}}
                        />
                        <BasicInput
                          key = {4}
                          label = {"Harga Layanan"}
                          value = {PriceFormat(itemPrc, currency)}
                          onChange = {() => {}}
                          disabled
                        />
                        <BasicInput
                          key = {5}
                          label = 'Diskon Paket Langganan (IDR)'
                          value = {PriceFormat(discountPackage, 'IDR')}
                          onChange = {() => {}}
                          disabled
                        />
                        <BasicInput
                          key = {6}
                          label = 'Harga Paket Langganan (IDR)'
                          value = {PriceFormat(totalPackage < 0 ? 0 : totalPackage, 'IDR')}
                          onChange = {() => {}}
                          disabled
                        />
                        <BasicInput
                          key = {7}
                          label = {"Alamat"}
                          value = {address}
                        />
                        <BasicInput
                          key = {8}
                          label = {"Status"}
                          value = {status.label}
                        />
                        <BasicInput
                          key = {9}
                          label = {"Mulai Subscription"}
                          value = {start}
                        />
                        <BasicInput
                          key = {10}
                          label = {"Akhir Subscriptions"}
                          value = {end}
                        />
                        <BasicInput
                          key = {11}
                          label = {"Harga Harian"}
                          value = {PriceFormat(dailyPrc, currency)}
                          onChange = {() => {}}
                          disabled
                        />
                        <BasicInput
                          key = {12}
                          label = {"Harga Bulanan"}
                          value = {PriceFormat(monthlyPrc, currency)}
                          onChange = {() => {}}
                          disabled
                        />
                        <BasicInput
                          key = {13}
                          label = {"PPN"}
                          value = {PriceFormat(taxPrice, currency)}
                          onChange = {() => {}}
                          disabled
                        />
                        <BasicInput
                          key = {14}
                          label = {"Harga Total Subscription Addon"}
                          value = {PriceFormat(addOnPrice, currency)}
                          onChange = {() => {}}
                          disabled
                        />
                        <BasicInput
                          key = {15}
                          label = {"Biaya Admin"}
                          value = {PriceFormat(administrationFee, currency)}
                          onChange = {() => {}}
                          disabled
                        />
                        {
                          !instalationFee ? '' : (
                            <BasicInput
                              key = {16}
                              label = {"Biaya Pemasangan Jaringan"}
                              value = {PriceFormat(instalationFee, currency)}
                              onChange = {() => {}}
                              disabled
                            />
                          )
                        }
                        {
                          !memberFee ? '' : (
                            <BasicInput
                              key = {17}
                              label = {"Biaya Member Reseller"}
                              value = {PriceFormat(memberFee, currency)}
                              onChange = {() => {}}
                              disabled
                            />
                          )
                        }
                        <BasicInput
                          key = {18}
                          label = 'Presentase Restitusi Subscription'
                          value = {discountSubsPrecentages}
                          type = {'number'}
                          onChange = {discountLimmiter}
                        />
                        <BasicInput
                          key = {19}
                          label = 'Restitusi Subscription (IDR)'
                          value = {discountSubs}
                          type = {'number'}
                          onChange = {(value) => {
                            if (value >= 0 && value <= Number(monthlyPrc)) {
                              setDIscountSubs(value)
                              setDIscountSubsPrecentages(CalculatePrecentagesFromPriceDIfference(monthlyPrc, value))
                            }
                          }}
                        />
                        <Input
                          placeholder="Restitusi Subscription (IDR)"
                          disabled
                          value={PriceFormat(discountSubs, 'IDR')}
                          onChange = {() => {}}
                          type="text"
                        />
                        <BasicInput
                          key = {20}
                          label = {"Harga Total Layanan"}
                          value = {totalPrice}
                          onChange = {() => {}}
                          disabled
                        />
                        </FormGroup>
                      </Col>
                      </Row>
                      {
                        !mitraSuper ? null : (
                        <Button
                          onClick = {EditHistory}
                          // disabled
                          color="info"
                        >Edit</Button>
                      )
                      }
                  </Form>
                </CardBody>
              </Card>
          </Col>
        </Row>
      </div>
    )

    return (
        <>
          <PanelHeader size="sm" />
          <LoadingScreen
            children = {children}
            loading = {loading}
          />
        </>
    );
}


export default SubscriptionHistoryView;
