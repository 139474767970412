import { PriceFormat } from "variables/common"

const TotalFooter = ({value, currency = 'idr'}) => {
  if (!value) {
    return (<p></p>)
  }
  return (
    <table>
      <tr>
      <td colSpan="5" />
      <td className="td-total font-weight-bold">Total</td>
      <td className="td-price">
        {PriceFormat(value, currency)}
      </td>
    </tr>
    </table>
  )
}

export default TotalFooter