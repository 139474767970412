export const HostpotSessionColumns = [
    {
        Header: "Server",
        accessor: 'server',
        maxWidth: 150,
    },
    {
        Header: "User",
        accessor: 'user',
        maxWidth: 150,
    },
    {
        Header: "IP Address",
        accessor: 'address',
        maxWidth: 150,
    },
    {
        Header: "MAC Address",
        accessor: 'macAddress',
        maxWidth: 150,
    },
    {
        Header: "Uptime",
        accessor: 'uptime',
        maxWidth: 150,
    },
    {
        Header: "Bytes In",
        accessor: 'bytesIn',
        maxWidth: 150,
    },
    {
        Header: "Bytes Out",
        accessor: 'bytesOut',
        maxWidth: 150,
    },
    {
        Header: "Idle Time",
        accessor: 'idleTime',
        maxWidth: 150,
    },
]







