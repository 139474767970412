import LoginService from './LoginService'
import APIconsume from './APIconsume'
import { getAccessToken } from "./../variables/common"
import axios from 'axios'

class WaGtawayService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        // this._altLink = 'http:103.103.146.230:100'
        this._consume = new APIconsume(accessToken)
        this._url = 'ws/wa-gateway'
    }
    // Roles and Permission
    WaGatewayLink () {
        const result = this._consume._pathServer + this._url
        return {
            link: result,
            // link: this._altLink,
            headers: {'Authorization': this._consume._header}
        }
    }

async getContent() {
    const url = this._consume._pathServer + this._url
    // Use Auth Header
    const header = {'Authorization': this._consume._header};
    const iframe = document.getElementById('web_canvas_iframe').contentDocument;
    try {
        const response = await axios.get(url, { headers: header })
        iframe.write(response)
    } catch (error) {
        console.log(error)
    }
}
}

export default WaGtawayService