import React, { useEffect } from "react";
import LoadingScreen from "components/Loader/LoadingScreen";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import BillingService from "Main/BillingService";
import Select from 'react-select'
import { useState } from "react";
import {getRefreshToken,
  getCustomerOptions, getOwner, addAddressOptions,
  addCustomerOptions, addItemOptions,
  addBillingList, getUserData, billing,
  addBillingStatus, GetBillingStatus, getRouterSelect } from "./../../../variables/common"
import swal from "sweetalert";
import CustomerService from "Main/CustomerService";
import { CatchError } from "Main/Exceptions/exceptions";
import { /* verifyLength, */ useFormInput } from "Main/Utils"
import RouterService from "Main/RouterService";

function Billing(props) {
  const billingService =  new BillingService()
  const customerService = new CustomerService()
  const routerService = new RouterService()
  const refreshToken = getRefreshToken()
  var [selectCustomerOptions, selectCustomerOptionsOnChange] = useState(getCustomerOptions())
  var statusOptions = GetBillingStatus()
  const userData = getUserData()
  const ownerData = getOwner()
  const userType = userData.userType
  const [changeOwner, changeOwnerOnChange] = useState(false)
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const [routerSelectOption, routerSelectOptionOnChange] = useState(getRouterSelect())
  const name = useFormInput('')
  const [customer , customerOnChange] = useState('')
  const [item , itemOnChange] = useState('')
  const [address , addressOnChange] = useState('')
  const [status, statusOnChange] = useState('')
  const [loading, loadingOnChange] = useState(false)
  const start = useFormInput()
  const end = useFormInput()
  const [selectItemOptions, selectItemOptionsOnChange] = useState([])
  const [selectAddressOptions, selectAddressOptionsOnChange] = useState([])
  const [router, routerOnChange] = useState('')
  // const [networkidManual,  networkidManualOnChange] =  useState()
  const [pppUser,  pppUserOnChange] =  useState()
  const [pppPass,  pppPassOnChange] =  useState()
  // const [manualNetworkId, manualNetworkIdOnChange] = useState(false)
  // const [subsIdAvailable, subsIdAvailableOnChange ] = useState(true)
  const [manualpppUsername, manualpppUsernameOnChange] = useState(false)

  // const verifysubsId = async (id) => {
  //   const result = await customerService.verifySubsIdAvailable(id)
  //   const available = result.data
  //   subsIdAvailableOnChange(available)
  // }

  const getRouter = async(mitraid) => {
    // sessionStorage.removeItem('urban')
    const dat = await routerService.getRouterSelect(mitraid)
    const result = dat.data
    routerSelectOptionOnChange(result)
    return result
  }

  const getBilling =  async() => {
    const len = 'ALL'
    const result =  await billingService.getAllBilling(len)
    const itemOption  = await customerService.getItemSelect()
    const statusOption = await customerService.getBillingStatusSelect()
    const customerOption = await customerService.getCustomerSelect()
    const addressOption = await customerService.getAddressSelect()
    addItemOptions(itemOption.data)
    addBillingStatus(statusOption.data)
    addAddressOptions(addressOption.data)
    addCustomerOptions(customerOption.data)
    addBillingList(result.data)
  }

  const getCustomer = async (mitraid) => {
    const customerOption = await customerService.getCustomerSelect(mitraid)
    selectCustomerOptionsOnChange(customerOption.data)
  }

  const getItem = async(routerid, mitraid, category) => {
    // sessionStorage.removeItem('urban')
    const dat = await customerService.getItemSelect(routerid, mitraid, category)
    const result = dat.data
    selectItemOptionsOnChange(result)
    return result
  }

  const getAddress = async(customerId, mitraid) => {
    // sessionStorage.removeItem('urban')
    selectAddressOptionsOnChange([])
    const dat = await customerService.getAddressSelect(customerId, mitraid)
    const result = dat.data
    selectAddressOptionsOnChange(result)
    return result
  }

  useEffect(() => {
    getBilling()
  }, [])

  const addBillingData = async() => {
  try {
    loadingOnChange(true)
    const payload = billing(owner.mitraid, name.value, customer.value, item.value,
                  address.value, status.value, /* networkidManual, */ pppUser, pppPass,
                  start.value, end.value)
    console.log(payload)
    const result = await billingService.addBilling(payload)
    await getBilling()
    loadingOnChange(false)
    swal(result.status, result.message, "success").then(() => {
      props.history.push('/admin/Billing-list')
    })
  } catch (error) {
    loadingOnChange(false)
    return await CatchError(error, props, refreshToken)
  }}

  function deleteSelect () {
    customerOnChange([])
    routerOnChange('')
    itemOnChange('')
    addressOnChange('')
    selectItemOptionsOnChange([])
    getAddress('')
  }

  const children = (<div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Generate Customer Subscription</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <Label>Nama</Label>
                          <Input
                            {...name}
                            placeholder="Nama"
                            type="text"
                          />
                        <Label>Owner</Label>
                          {
                          (userType === 'SUPERADMIN') ? (
                            <>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="owner"
                              value={owner}
                              isDisabled = {!changeOwner}
                              options={ownerData}
                              onChange={ async(e) => {
                                ownerOnChange(e);
                                await getRouter(e.mitraid)
                                await getCustomer(e.mitraid)
                                deleteSelect()
                              } } />
                              <FormGroup check>
                                <Label check> Ganti Mitra
                                  <Input type="checkbox"
                                  value = {changeOwner}
                                  checked = {changeOwner}
                                  onChange = {async (e) => {
                                    ownerOnChange(ownerData.find(own => own.value === userData.organization))
                                    changeOwnerOnChange(e.target.checked)
                                    await getRouter(owner.mitraid)
                                    await getCustomer(owner.mitraid)
                                    if (owner.value !== userData.organization) {
                                      deleteSelect()
                                      routerSelectOptionOnChange(getRouterSelect())
                                      selectCustomerOptionsOnChange(getCustomerOptions())
                                    }
                                    }
                                  }
                                  />
                                  <span className="form-check-sign" />
                                </Label>
                              </FormGroup>
                              </>) : (
                              <Input
                              placeholder="owner"
                              value={owner.value}
                              />
                              )
                          }
                        <div>
                        <Label>Customer</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Customer"
                            name="singleSelect"
                            value = {customer}
                            options = {selectCustomerOptions}
                            onChange={ async (value) => { customerOnChange(value)
                              routerOnChange('')
                              itemOnChange('')
                              addressOnChange('')
                              selectItemOptionsOnChange([])
                              await getAddress(value.value, owner.mitraid)
                            }
                            }
                          />
                          <Label>Kategori Customer {customer.category}</Label>
                        </div>
                          <Label>Alamat</Label>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Alamat"
                              name="singleSelect"
                              value = {address}
                              options={selectAddressOptions}
                              onChange={(value) => {
                                addressOnChange(value)
                              }}
                            />
                        <Label>Lokasi Router</Label>
                            <div>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Lokasi Router"
                              name="singleSelect"
                              value = {router}
                              options={routerSelectOption}
                              onChange={async(value) => {routerOnChange(value)
                                await getItem(value.value, owner.mitraid, customer.category)
                                itemOnChange('')
                                // selectItemOptionsOnChange([])
                                }
                              }
                            />
                            {!router.status ? (
                              <label className="error">
                                Router Offline
                              </label>
                            ) : <label className="success">
                                Router Online
                              </label>}
                          </div>
                            <Label>Service Plan</Label>
                            <Select
                                // className="react-select"
                                // classNamePrefix="react-select"
                                placeholder="Service Plan"
                                name="singleSelect"
                                value = {item}
                                options={selectItemOptions}
                                onChange={(value) => {
                                  itemOnChange(value)
                                }}
                            />
                          <Label>Status</Label>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Status"
                              value = {status}
                              options = {statusOptions}
                              onChange = {(value) => statusOnChange(value)}
                              type="text"
                            />
                          <div>
                            <Label>PPOE User</Label>
                            <Input
                              disabled = {!manualpppUsername}
                              placeholder={manualpppUsername ? "Manual Generate" : "Auto Generate"}
                              type="text"
                              value = {pppUser}
                              onChange = {(e) => pppUserOnChange(e.target.value)}
                            />
                            <Label>PPOE Password</Label>
                            <Input
                              disabled = {!manualpppUsername}
                              placeholder={manualpppUsername ? "Manual Generate" : "Auto Generate"}
                              type="text"
                              value = {pppPass}
                              onChange = {(e) => pppPassOnChange(e.target.value)}
                            />
                          </div>
                          <Label>Kosongkan Password untuk AutoGenerate</Label>
                          <FormGroup check>
                          <Label check> Generate Manual PPP Secret
                            <Input type="checkbox"
                            value = {manualpppUsername}
                                  onChange = {e => manualpppUsernameOnChange(e.target.checked)}
                            />
                            <span className="form-check-sign" />
                          </Label>
                          </FormGroup>
                          <Label>Start Subscriptions</Label>
                            <Input
                              className = "Start"
                              placeholder="Start Date"
                              {...start}
                              type="date"
                            />
                          <Label>End Subscriptions</Label>
                            <Input
                              className = "End"
                              placeholder="End Date"
                              {...end}
                              type="date"
                            />
                        </FormGroup>
                      </Col>
                      </Row>
                  </Form>
                </CardBody>
              </Card>
            <Button onClick = {addBillingData} color="info">Submit</Button>
          </Col>
        </Row>
      </div>
    )

  return (
    <>
      <PanelHeader size="sm" />
      <LoadingScreen children={children} loading = {loading}/>
    </>
  );
}

export default Billing;
