import LoadingScreen from "components/Loader/LoadingScreen";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import { useState } from "react";
import {getRefreshToken, getOwner,
        GetItemCategory, GetItemCurrency,
        GetItemMeasurement, PriceFormat, getRouterSelect
} from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import RouterService from "Main/RouterService";
import BasicInput from "components/Input/BasicInput";
import SelectBasic from "components/Input/SelectBasic";
import { verifySuperAdmin } from "Main/Utils";
import SelectWithMessage from "components/Input/SelectWithMessage";
import ItemAddOnService from "Main/Add-On/ItemAddOnService";
import { getUserData } from "variables/common";
import SelecttWithUserTier from "components/Input/SelectWithUserTier";
import { addItemAddOnList } from "variables/Add-On/ItemAddOn";
import { payloadItemAddOn } from "variables/Add-On/ItemAddOn";
import { useEffect } from "react";
import ValidationMinMaxInput from "components/Input/ValidationMinMaxInput";

function ItemAddOnPage(props) {
  const itemAddOnService = new ItemAddOnService()
  const routerService = new RouterService()
  var selectMeasurementOptions = GetItemMeasurement()
  var selectCategoryOptions = GetItemCategory()
  var selectCurrencyOptions = GetItemCurrency()
  const [routerSelectOption, routerSelectOptionOnChange] = useState(getRouterSelect())
  const userData = getUserData()
  const ownerData = getOwner()
  const [changeOwner, changeOwnerOnChange] = useState(false)
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const refreshToken = getRefreshToken()
  const [name, setName] = useState('')
  const [priceValue, priceValueOnChange] = useState()
  const [discountValue, discountValueOnChange] = useState(0)
  const [value, setValue] = useState(0)
  const [Measurement, setMeasurement]  = useState(selectMeasurementOptions.find(data => data.value === 'Mbps'))
  const currency  = selectCurrencyOptions.find(data => data.label === 'Rupiah')
  const [category, categoryOnChange] = useState('')
  const [router, routerOnChange] = useState('')
  const [loading, loadingOnChange ] = useState(false)
  const { mitraSuper } = verifySuperAdmin()

  useEffect(() => {
    getRouter(owner.mitraid)
  }, [])

  const getItem =  async() => {
        const len =  'ALL'
        const result =  await itemAddOnService.getAllData(len)
        addItemAddOnList(result.data)
  }

  const getRouter = async(mitraid) => {
    // sessionStorage.removeItem('urban')
    const dat = await routerService.getRouterSelect(mitraid)
    const result = dat.data
    routerSelectOptionOnChange(result)
    return result
  }

  const addItemData = async() => {
      try {
          loadingOnChange(true)
          const payload = payloadItemAddOn(owner.mitraid, name, category.value, priceValue,  discountValue, Measurement.value, router.value, value)
          const result = await itemAddOnService.addData(payload)
          await getItem()
          loadingOnChange(false)
          swal(result.status, result.message, "success").then(() => {
              props.history.push('/admin/item-add-on-list')
          })
        } catch (error) {
          loadingOnChange(false)
          return await CatchError(error, props, refreshToken)
          }
        }
      //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')}

  function deleteSelect() {
    routerOnChange('')
  }

  const children = (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Form Produk Add-On</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <SelecttWithUserTier
                            label = {"Mitra"}
                            value={owner}
                            disabled = {changeOwner}
                            options={ownerData}
                            onChange = {ownerOnChange}
                            subFunction = { async (e) => {
                              await getRouter(e.mitraid)
                                deleteSelect()
                            }}
                            subFunctionCheck = {async () => {
                              ownerOnChange(ownerData.find(own => own.value === userData.organization))
                              await getRouter(owner.mitraid)
                              if (owner.value !== userData.organization) {
                                deleteSelect()
                                routerSelectOptionOnChange(getRouterSelect())
                                }
                              }
                            }
                            checkText = {"Pilih Mitra"}
                            checkValue = {changeOwner}
                            checkValueOnChange = {changeOwnerOnChange}
                          />
                        </FormGroup>
                        <SelectWithMessage
                            value = {router}
                            onChange = {routerOnChange}
                            condition = {!router.status}
                            errMesssage = {"Router Offline"}
                            message = {"Router Online"}
                            label = {"Lokasi Router"}
                            option = {routerSelectOption}
                            isDisabled = {!mitraSuper}
                        />
                        <FormGroup>
                        <BasicInput
                            label = 'Nama'
                            value = {name}
                            onChange = {setName}
                        />
                        <SelectBasic
                          label = {"Pengukuran"}
                          value = {Measurement}
                          onChange = {(value) => setMeasurement(value)}
                          isDisabled = {!mitraSuper}
                          option = {selectMeasurementOptions}
                        />
                        <label>QTY</label>
                          <Input
                            placeholder="QTY"
                            value = {value}
                            onChange = {(e) => {
                              if (e.target.value >= 0) {
                                setValue(e.target.value)
                              }
                            }}
                            type="number"
                          />
                        <BasicInput
                            label = 'Mata Uang'
                            value = {currency.label}
                          />
                        <SelectBasic
                            label = {"Kategori"}
                            value = {category}
                            option = {selectCategoryOptions}
                            onChange={(value) => categoryOnChange(value)}
                        />
                        <abel>Harga</abel>
                          <Input
                            placeholder="Harga"
                            value = {priceValue}
                            onChange = {(e) => {
                              if (e.target.value >= 0) {
                                priceValueOnChange(e.target.value)
                              }
                            }}
                            type="number"
                          />

                        <ValidationMinMaxInput
                          label = {"Diskon"}
                          value = {discountValue}
                          onChange = {discountValueOnChange}
                          min = {0}
                          max = {100}
                          type = {"number"}
                        />
                        <BasicInput
                            label = 'Harga Total'
                            value = {PriceFormat(Number(priceValue - ((discountValue/100)*priceValue)), 'IDR')}
                          />
                        </FormGroup>
                      </Col>
                      </Row>
                  </Form>
                </CardBody>
              </Card>
            <Button onClick = {addItemData}
            color="info">Submit</Button>
            </Col>
          </Row>
        </div>
      )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen children={children} loading = {loading} text={"Upload Data"}/>
      </>
    );
}


export default ItemAddOnPage;
