/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {getRefreshToken} from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions"
import CommonList from "../../../components/Table/CommonList"
import { ColumnsUser, ColumnsAdmin } from "./ErpNextClientColumns"
import { getUserPermission } from "variables/common";
import StatusButton from "components/Button/StatusButton";
import { verifySuperAdmin } from "Main/Utils";
import { customTextFromBoolean } from "Main/Utils";
import ErpNextClientService from "Main/ErpNextClient/ErpNextClientService";
import { addErpNextClientData } from "variables/ErpNextClient/ErpNextClient";
import { payloadSetSync } from "variables/ErpNextClient/ErpNextClient";

function ErpNextClientList(props) {
  const page = 'user'
  const erpNextClientService = new ErpNextClientService()
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role.toLowerCase() === page) || ''
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const refreshToken = getRefreshToken()
  // const [listState, listStateOnChange] = useState([])
  const [sync, setSync] = useState(false)
  const { mitraSuper } = verifySuperAdmin()
  const Columns = mitraSuper ? ColumnsAdmin : ColumnsUser

  try {
  const getAll =  async(trash = false) => {
    try {
      const len = 'ALL'
       setDataLoad(true)
      const dat =  await erpNextClientService.getAllData(len, trash)
      const result = dat.data
      setDataTable(result)
      setDataState(mapping(result))
      setDataLoad(false)
      return result
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  useEffect( () => {
    getAll()
  }, [])

  const deleteData = async (id) => {
    swal("Hapus Permanen", "Data Tidak Dapat di Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then(async(value) => {
      switch (value) {
        case 'del':
          try {
            setDataLoad(true)
            const result = await erpNextClientService.deleteData(id)
            await swal(result.status, result.message, 'success')
            const state = await getAll()
            const stateRaw = mapping(state)
            const newState = stateRaw.filter(data => data.id !== id)
            setDataState(newState)
            setDataLoad(false)
            return true
          } catch (error) {
            return await CatchError(error, props, refreshToken)
          }

        default:
          await getAll()
          return false
      }
    })
  }

  const addData = () => {
    props.history.push('/admin/erpnext-client-form')
  }

  const viewData = async(id)=> {
    try {
      const dat = await erpNextClientService.getData(id)
      const result = dat.data
      addErpNextClientData(result)
      props.history.push('/admin/erpnext-client-detail')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const setSyncronize = async(id, sync) => {
    try {
      // listStateOnChange([])
      var operation = !sync ? 'un-Syncronize' : 'Syncronize'
      // setDataLoad(true)
      const payload = payloadSetSync(id, sync)
      console.log(payload)
      const dat = await erpNextClientService.setSyncStatus(payload)
      await swal(operation, dat.message, dat.status)
      const result = dat.data
      // const statelist = listState.find(list => list.id === id)
      // console.log(listState, statelist)
      // statelist.status = Status
      const table = await getAll(false)
      setDataState(mapping(table))
      // setDataLoad(false)
      return result
    } catch (error) {
      swal('failed', error.message, 'warning')
    }
  }

  const mapping =  (Table) => {
    return Table.map((Data, i) => {
      try {
          // listState.push({id: Data.id, status: Data.enabled})
          return {
            id: Data.id,
            name: Data.name,
            url: Data.url,
            status: customTextFromBoolean(Data.connected, 'Connected', 'Disconect'),
            owner: Data.owner,
            sync: (
                <div className="actions-center">
                <StatusButton
                  value = {sync}
                  statusOnChange = { async(e) =>{
                    const status = !Data.sync
                    setSync(status)
                    await setSyncronize(Data.id, status)
                  }}
                  status = {Data.sync}
                  disableText='No'
                  enableText='Yes'
                  disableColor='warning'
                  disabled //= {Permission.Update}
                />
              </div>
            ),
            syncUsr: customTextFromBoolean(Data.sync, 'Yes', 'No'),
            actionsUser: (
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={ async () => {
                    await viewData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Read}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                </div>
            ),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={ async () => {
                    await viewData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Read}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                <Button
                  onClick={async () => {
                    await deleteData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}1`}
                  disabled = {!Permission.Delete}
                  color="danger"
                  size="sm"
                >
                <i className="fa fa-trash" />
                </Button>{" "}
              </div>
            ),
        };
        } catch (error) {
          console.log(error)
          return []
        }
      })}

  const [dataState, setDataState] = useState(
    mapping(Table)
  )

  const customMenu = (
    <Row>
      <Col md={10}>
        <div  className="actions-left">
          <Button onClick={addData} color="info"
          disabled = {!Permission.Create}
          >Add</Button>
        </div>
      </Col>
    </Row>
  )

  return (
        <>
        <PanelHeader size="sm" />
          {/* <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List ERPNext Client</h2>
              </div>
            }
          /> */}
          <div className="content">
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CommonList
                    key={1}
                    header={'ERPNext Client'}
                    columns={Columns}
                    dataState={dataState}
                    menu = {customMenu}
                    Add={addData}
                    load={load}
                    getData={getAll}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </>
    );
  } catch (error) {
    return (
        <>
        <PanelHeader size="sm" />
          {/* <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List ERPNext Client</h2>
              </div>
            }
          /> */}
          <div className="content">
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <h3>{error.message || error}</h3>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    );
  }
}

export default ErpNextClientList;
