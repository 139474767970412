/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken,
        getGridPoleData,
        GridPole} from "./../../../../variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions"
import { DateTimestr } from "Main/Utils";
import RouterService from "Main/RouterService";
import LoadingScreen from "components/Loader/LoadingScreen";
import InputCoordinate from "components/Input/InputCoordinate";

function GridPoleDeviceInventoryView(props) {
  const routerService = new RouterService()
  const refreshToken = getRefreshToken()
  const GridPoleData = getGridPoleData()
  const id = GridPoleData.id
  const routerid = GridPoleData.routerid
  const [name, nameOnChange] = useState(GridPoleData.name)
  const mitra = GridPoleData.mitraName
  const created = GridPoleData.creation
  const modified = GridPoleData.modified
  const province = GridPoleData.province_name
  const subDistrict = GridPoleData.sub_district
  const urban = GridPoleData.urban
  const router = GridPoleData.routerName + ': IP ' + GridPoleData.ip_address + ' PORT ' + GridPoleData.port
  const createdBy = GridPoleData.created_by
  const modifiedBy = GridPoleData.modified_by
  const [height, heightOnChange] = useState(GridPoleData.height)
  const [description, descriptionOnChange] = useState(GridPoleData.description)
  const [loading, loadingOnChange] = useState(false)
  const [latitude, setLatitude] = useState(GridPoleData.latitude)
  const [longitude, setLongitude] = useState(GridPoleData.longitude)

  const update = async() => {
    try {
      loadingOnChange(true)
      const payload = GridPole(name, routerid, height, latitude, longitude, description)
      const result = await routerService.putGridPole(id, payload)
      // await fetch(id)
      loadingOnChange(false)
      swal(result.status, result.message, "success").then(() => {
        props.history.push('/admin/router-update')
      })
    } catch (error) {
      loadingOnChange(false)
      return await CatchError(error, props, refreshToken)
    }}

    const children = (<div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Detail Tiang Jaringan</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <Label>Nama</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Nama"
                            value = {name}
                            onChange={(e)=>{
                              nameOnChange(e.target.value)
                            }}
                          />
                        <InputCoordinate
                          latitude = {latitude}
                          setLatitude = {setLatitude}
                          longitude = {longitude}
                          setLongitude = {setLongitude}
                        />
                        <Label>Tinggi</Label>
                        <Input
                          name="min_length_bank_number"
                          type="number"
                          placeholder="Tinggi"
                          value = {height}
                          onChange={(e) => {
                              heightOnChange(e.target.value)
                          }}
                        />
                        <Label>Deskripsi</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Deskripsi"
                            name="singleSelect"
                            value = {description}
                            onChange= {(value) => { descriptionOnChange(value.value)}}
                          />
                        </FormGroup>
                        <Label>Mitra</Label>
                            <Input
                              placeholder="Mitra"
                              value={mitra}
                              type="text"
                        />
                        <Label>Provinsi</Label>
                          <Input
                            placeholder="Provinsi"
                            value = {province}
                            type="text"
                          />
                          <Label>Kecamatan</Label>
                          <Input
                            placeholder="Kecamatan"
                            value = {subDistrict}
                            type="text"
                          />
                          <Label>Kelurahan</Label>
                          <Input
                            placeholder="Kelurahan"
                            value = {urban}
                            type="text"
                          />
                          <Label>Router</Label>
                          <Input
                            placeholder="router"
                            value = {router}
                            type="text"
                          />
                          <Label>Created</Label>
                          <Input
                            placeholder="Created"
                            value = {DateTimestr(created)}
                            type="text"
                          />
                          <Label>modified</Label>
                          <Input
                            placeholder="Modified"
                            value = {DateTimestr(modified)}
                            type="text"
                          />
                          <Label>created By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {createdBy}
                            type="email"
                          />
                          <Label>modified By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {modifiedBy}
                            type="email"
                          />
                      </Col>
                    </Row>
                  <Button onClick = {update} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen children={children} loading = {loading} text={"update Data"}/>
      </>
    );
}


export default GridPoleDeviceInventoryView;
