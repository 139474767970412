import LoginService from './../LoginService'
import APIconsume from './../APIconsume'
import { getAccessToken } from "./../../variables/common"

class SubscriptionAddOnService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'Subscription/AddOns'
    }

    async addData (payload) {
        const result = await this._consume.postData(payload, this._url)
        return result.data
    }

    async softDelete (payload) {
        const url = this._url + '/Delete'
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async getData (id) {
        const url = `${this._url}/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllData (subscriptionId, len = 'ALL', deleted = false, page = '') {
        let url = `${this._url}/view?subscriptionId=${subscriptionId}&line=${len}&deleted=${deleted}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async putData (id, payload) {
        const url = `${this._url}/${id}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async deleteData (id) {
        const url = `${this._url}/${id}`
        const result = await this._consume.deleteData(url)
        return result.data
    }
}

export default SubscriptionAddOnService