import React from 'react'
import { Button } from 'reactstrap'

function StatusButton(props) {
    const { statusOnChange, status, disableText, enableText, disableColor, enableColor, disabled} = props
    return (
        <Button
            disabled = {!disabled}
            className='statusbutton'
            onClick={statusOnChange}
            color={!status ? disableColor || 'danger': enableColor || 'success'}
        >
            {!status ? disableText || 'Disable' : enableText || 'Enable'}
        </Button>
    )
}

export default StatusButton

