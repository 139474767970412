import React from "react";
import LoadingScreen from "components/Loader/LoadingScreen";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken, PriceFormat } from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import BasicInput from "components/Input/BasicInput";
import { DateTimestr } from "Main/Utils";
import Checklist from "components/Button/Checklist";
import { verifySuperAdmin } from "Main/Utils";
import { payloadHostpotUserUpdate } from "variables/HostpotVoucher/HostpotUser";
import SelectBasic from "components/Input/SelectBasic";
import { getHostpotUserData } from "variables/HostpotVoucher/HostpotUser";
import { addHostpotUserList } from "variables/HostpotVoucher/HostpotUser";
import HostpotUserService from "Main/HostpotVoucher/HostpotUserService";
import HostpotProfileService from "Main/HostpotVoucher/HostpotProfileService";
import UserService from "Main/UserService";
import { useEffect } from "react";
import { calculatePriceDiscount } from "Main/Utils";

function HostpotUserUpdate(props) {
  const hostpotUser = getHostpotUserData()
  const hostpotUserService =  new HostpotUserService()
  const hostpotProfileService = new HostpotProfileService()
  const { mitraSuper, userSuper, id: userId } = verifySuperAdmin()
  const userService = new UserService()
  const refreshToken = getRefreshToken();
  const [loading, setLoading] = useState(false)
  const [hostpotProfileOption, setHostpotProfileOption] = useState([])
  const [mitra, setMitra] = useState(hostpotUser.mitra)
  const [username, setUsername] = useState(hostpotUser.username)
  const [password, setPassword] = useState(hostpotUser.password)
  const [discount, setDiscount] = useState(hostpotUser.discount)
  const [isPaid, setIsPaid] = useState(hostpotUser.isPaid)
  const [paidAmount, setPaidAmount] = useState(hostpotUser.paidAmount)
  const [description, setDescription ] = useState(hostpotUser.description)
  const [resellerOption, setResellerOption] = useState([])
  const [hostpotProfileId, setHostpotProfileId] = useState(hostpotProfileOption.find((data) => data.value === hostpotUser.profileId))
  const [reseller, setReseller] = useState(resellerOption.find((data) => data.userId === userId))
  const [router, setRouter] = useState(hostpotUser.routerName)
  const {id, creation, modified,
    createdBy, modifiedBy, mitraId, routerId, resellerId } = hostpotUser

  async function GetReseller(mitra) {
    const resellerList = await userService.getUserSelect(mitra)
    const resellerData = resellerList.data

    setResellerOption(resellerData)

    const defaultReseller = resellerData.find((data) => data.userId === resellerId)
    const finalSelection = !defaultReseller ? resellerData[0] : defaultReseller
    setReseller(finalSelection)
    return finalSelection.userId
  }

  async function GetHostpotProfile(routerId) {
    const hostpotProfileList = await hostpotProfileService.getProfileSelect(routerId)
    const hostpotProfileData = hostpotProfileList.data

    setHostpotProfileOption(hostpotProfileData)
    setHostpotProfileId(hostpotProfileData.find((data) => data.value === hostpotUser.profileId))
    return hostpotProfileData
  }

  useEffect(() => {
    GetHostpotProfile(routerId)
    GetReseller(mitraId)
  }, [])

  const getAll =  async(trash = false) => {
    const len = 'ALL'
    const result =  await hostpotUserService.getAll(len, trash)
    addHostpotUserList(result.data)
  }

  const update = async() => {
    const selected = await swal("Update", "Perubahan Data Tidak Dapat di Batalkan", "info", {
          buttons: {
          update: {text: "Update", value: 'up' },
          cancel: 'Batal'
        }
      }
    )

    switch (selected) {
        case 'up':
          try {
            setLoading(true)
            const payload = payloadHostpotUserUpdate( hostpotProfileId.value, username, password, discount, isPaid, paidAmount, description )

            const result = await hostpotUserService.put(id, payload)
            await getAll()
            await swal(result.status, result.message, 'success')
            setLoading(false)
            props.history.push('/admin/hostpot-user-list')
          } catch (error) {
            setLoading(false)
            return await CatchError(error, props, refreshToken)
          }
          break
        default:
          break;
      }
  }

  const children = (
    <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Detail Hostpot User</h5>
                </CardHeader>
                <CardBody>
                  <BasicInput
                    key={"1"}
                    label = {'Mitra'}
                    disabled
                    value = {mitra}
                    onChange = {setMitra}
                    type
                  />
                  <BasicInput
                    key={"2"}
                    label = {'Lokasi Router'}
                    value = {router}
                    onChange = {setRouter}
                    type
                  />
                  <SelectBasic
                    key={"3"}
                    label = {'Hostpot Profile'}
                    value = {hostpotProfileId}
                    onChange = {(e) => {
                      setHostpotProfileId(e)
                    }}
                    option = {hostpotProfileOption}
                  />
                  <SelectBasic
                    key={"4"}
                    label = {"Reseller"}
                    value = {reseller}
                    option = {resellerOption}
                    isDisabled = {!mitraSuper}
                    onChange = {async (e) => {
                      setReseller(e)
                    }}
                  />
                  <BasicInput
                    key={"5"}
                    label = {'Username'}
                    value = {username}
                    onChange = {setUsername}
                    type
                  />
                  <BasicInput
                    key={"6"}
                    label = {'Password'}
                    value = {password}
                    onChange = {setPassword}
                    type
                  />
                  <BasicInput
                    key={"7"}
                    label = {'Discount'}
                    value = {discount}
                    onChange = {(e) => {
                      if (e >= 0 && e <= 100) {
                        setDiscount(e)
                        setPaidAmount(calculatePriceDiscount(hostpotProfileId.price, e))
                      }
                    }}
                    type = {'number'}
                  />
                  <Checklist
                    key={"8"}
                    text = {"Lunas"}
                    value = {isPaid}
                    checked = {isPaid}
                    onChange = {(e) => {
                      setIsPaid(e.target.checked)
                      if (e.target.checked === true && !!hostpotProfileId) {
                        setPaidAmount(calculatePriceDiscount(hostpotProfileId.price, discount))
                      } else {
                        setPaidAmount(0)
                      }
                    }}
                  />
                  <BasicInput
                    key={"9"}
                    label = {'Nilai Bayar'}
                    value = {paidAmount}
                    onChange = {setPaidAmount}
                    disabled = {!isPaid}
                    type = {'number'}
                  />
                  <BasicInput
                    key={"10"}
                    label = {'Price Display'}
                    value = {PriceFormat(paidAmount, 'idr')}
                  />
                  <BasicInput
                    key={"11"}
                    label = {'Deskripsi'}
                    value = {description}
                    onChange = {setDescription}
                    type = {'textarea'}
                  />
                  <BasicInput
                    key={"12"}
                    label = {'Creation'}
                    disabled
                    value = {DateTimestr(creation)}
                    onChange = {() => {}}
                  />
                  <BasicInput
                    key={"13"}
                    label = {'Modified'}
                    disabled
                    value = {DateTimestr(modified)}
                    onChange = {() => {}}
                  />
                  <BasicInput
                    key={"14"}
                    label = {'CreatedBy'}
                    disabled
                    value = {createdBy}
                    onChange = {() => {}}
                  />
                  <BasicInput
                    key={"15"}
                    label = {'ModifiedBy'}
                    disabled
                    value = {modifiedBy}
                    onChange = {() => {}}
                  />

                  <Button
                    onClick = {update}
                    disabled = {!userSuper}
                    color="info"
                  >Submit</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
  )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen
          children={children}
          loading = {loading}
          text={"Loading..."}/>
      </>
    );
}


export default HostpotUserUpdate;
