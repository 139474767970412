import LoginService from './LoginService'
import APIconsume from './APIconsume'
import { getAccessToken } from "./../variables/common"

class MapService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'map'
    }

    async getTopology (id) {
        const url = `${this._url}/topology?mitra=${id}`
        const result = await this._consume.getData(url)
        return result.data
    }
}

export default MapService