import { addLogisticSupplierList } from "variables/common"
import { addLogisticItemList } from "variables/common"
import { addLogisticWarehouseList } from "variables/common"
import LogisticItemService from "./LogisticItemService"
import LogisticSupplierService from "./LogisticSupplierService"
import LogisticWarehouseService from "./LogisticWarehouseService"
import { addLogisticTypenRaw } from "variables/common"
import { addLogisticWarehouseOption } from "variables/common"
import { addLogisticSupplierOption } from "variables/common"
import { addLogisticItemOption } from "variables/common"
import { addLogisticTypeOption } from "variables/common"
import CustomerService from "Main/CustomerService"

const logisticWarehouseService = new LogisticWarehouseService()
const logisticItemService = new LogisticItemService()
const logisticSupplierService = new LogisticSupplierService()
const customerService = new CustomerService()

export const FetchLogisticList =  async (mitra) => {
    const logisticWarehouseList = await logisticWarehouseService.getAll(mitra)
    const logisticItemList = await logisticItemService.getAll(mitra)
    const logisticSupplierList = await logisticSupplierService.getAll(mitra)
    addLogisticWarehouseList(logisticWarehouseList.data)
    addLogisticItemList(logisticItemList.data)
    addLogisticSupplierList(logisticSupplierList.data)
}

export const FetchLogisticSelect = async (mitra) => {
    const logisticType = await customerService.getLogisticTypeOption(mitra)
    addLogisticTypeOption(logisticType.data)
    addLogisticTypenRaw(logisticType.raw)
    const logisticItem = await customerService.getLogisticItemOption(mitra)
    addLogisticItemOption(logisticItem.data)
    const logisticWarehouse = await customerService.getLogisticWarehouseOption(mitra)
    addLogisticWarehouseOption(logisticWarehouse.data)
    const logisticSupplier = await customerService.getLogisticSupplierOption(mitra)
    addLogisticSupplierOption(logisticSupplier.data)

   return {
    logisticType: logisticType.data,
    logisticItem: logisticItem.data,
    logisticWarehouse: logisticWarehouse.data,
    logisticSupplier: logisticSupplier.data
   }
}