/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";

// reactstrap components
import {
  Button, Col, Row, Card
} from "reactstrap";

// core components
import swal from "sweetalert";
import CommonList from "../../../../components/Table/CommonList";
import { columns, trashColumns } from "./SubscriptionAddOnColumn"
import { getUserPermission } from "variables/common";
import { GetBillingStatus } from "variables/common";
import Checklist from "components/Button/Checklist";
import { useEffect } from "react";

function SubscriptionAddOnList({fetch, add, view, deleted, loaded, dataTable, sendToTrash, recoverTrash, trash, setTrash}) {
  try {
    const pages = 'billing'
    const permission = getUserPermission()
    const Permission = permission.find(data => data.Role === pages) || ''
    var statusOptions = GetBillingStatus()


    useEffect(() => {
      fetchData()
    }, [])

    const mappingData = (data) => data.map((Data) => {
          try {
            return {
            id: Data.id,
            name: Data.addOnsName,
            owner: Data.owner,
            province: Data.Province,
            status: statusOptions.find(stat => stat.value ===  Data.status).label,
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={ async () => {
                    await view(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Read}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                <Button
                  onClick={async () => {
                    const status = await sendToTrash(Data.id)
                    if(status === true) {
                        await cropData(Data.id)
                    }
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}1`}
                  disabled = {!Permission.Delete}
                  color="danger"
                  size="sm"
                >
                <i className="fa fa-trash" />
                </Button>{" "}
              </div>
            ),
            recover: (
              <Button
                  onClick={ async () => {
                    const status = await recoverTrash(Data.id)
                    if(status === true) {
                        await cropData(Data.id)
                    }
                  }}
                  className="btn"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Update}
                  color="warning"
                  size="sm"
                >
                 Recover
                </Button>
            ),
            delete: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={ async () => {
                    const status = await deleted(Data.id)
                    if(status === true) {
                        await cropData(Data.id)
                    }
                  }}
                  className="btn-icon"
                  id={`tooltip${Data.id}1`}
                  disabled = {!Permission.Delete}
                  color="danger"
                  size="sm"
                >
                <i className="fa fa-times" />
                </Button>{" "}
              </div>
            ),
          };
          } catch (error) {
            return []
          }
        })

    const [dataState, setDataState] = useState(mappingData(dataTable))

    const fetchData = async () => {
        const data = await fetch()
        setDataState(mappingData(data))
        return data
    }

    const cropData = async (id) => {
        const state = await fetchData()
        const stateRaw = mappingData(state)
        const data = stateRaw.filter((data) => data.id !== id)
        setDataState(data)
    }

    const menu = (
      <Row>
        <Col md={3}>
          <Button onClick={add}
          color="info"
          disabled = {!Permission.Create}>Add</Button>
        </Col>
        <Col md={9}>
          <Checklist
              text = {"View Trash"}
              value = {trash}
              checked = {trash}
              onChange = {async (e) => {
                setTrash(e.target.checked)
                const data = await fetch(e.target.checked)
                setDataState(mappingData(data))
            }}
            />
        </Col>
      </Row>
      )

    return (
        <>
        <CommonList
          key={1}
          header={'Subscription Add-On'}
          columns={!trash ? columns : trashColumns}
          dataState={dataState}
          menu = {menu}
          addDisabled={Permission.Create}
          load={loaded}
          getData={fetchData}
        />
    </>
    );
  } catch (error) {
    swal('Error', error.message, 'warning')
    return (
        <>
          <Card>
            <div>
              <h5>{error.message}</h5>
            </div>
          </Card>
        </>
      );
  }
}

export default SubscriptionAddOnList;
