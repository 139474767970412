import Select from 'react-select'
import { Label } from "reactstrap"

function SelectBasic({value, noLabel, onChange, label, isDisabled, option, className}) {
    return (
        <>
        {
            !noLabel ? (
                <Label>{label || 'default Label'}</Label>
            ) : null
        }
        <div>
            <Select
                className={className}
                // classNamePrefix="react-select"
                placeholder= {label || 'Select Here'}
                name="singleSelect"
                value={value}
                options={option}
                isDisabled = {isDisabled}
                onChange={(value) => onChange(value)} />
        </div></>
    )
}

export default SelectBasic