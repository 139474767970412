const { getData, setData } = require("variables/common");

const identifier = ['submissionLogisticData', 'submissionLogisticList']
const [data, list] = identifier

export function addSubmissionLogisticData(payload) {
  setData(data, payload)
}

export function getSubmissionLogisticData() {
 return getData(data)
}

export function addSubmissionLogisticList(payload) {
  setData(list, payload)
}

export function getSubmissionLogisticList() {
 return getData(list)
}

export function payload(name, measurement, value, price, description, attachment, spendingTime, mitra) {
    return {
        name,
        measurement,
        value,
        price,
        description,
        attachment,
        spendingTime,
        mitra
    }
}

export function payloadUpdate(measurement, value, price, description) {
    return {
        measurement,
        value,
        price,
        description
    }
}

export function payloadTrash(id, trash) {
    return {
        id,
        trash
    }
}

export function payloadApprove(id, type, status) {
    return {
        id,
        type,
        status
    }
}

export function payloadDownload(id) {
    return {
        id
    }
}

export function FormDataPayload(name, measurement, value, price, description, attachment, spendingTime, mitra) {
    const formData = new FormData()
    const fileData = !attachment ? '' : attachment[0]
    const payloadRaw = payload(name, measurement, value, price, description, fileData, spendingTime, mitra)
    const keys = Object.keys(payloadRaw)
    for (const obj of keys) {
        if (obj === 'attachment') {
            const attachmentData = payloadRaw[obj]
            const attachmentArr = Array.from(attachmentData)
            for(const file of attachmentArr) {
                formData.append(obj, file, file.filename)
            }
        }
        formData.append(obj, payloadRaw[obj])
    }
    return formData
}