import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken,
  getLogisticItemData, getCustomerOptions,
  CustomerToWarehouse, getLogisticGoodsCustomerData,
  getLogisticWarehouseData, getLogisticSupplierData, getOwner} from "./../../../../variables/common"
import { CatchError } from "Main/Exceptions/exceptions";
import { getLogisticWarehouseOption } from "variables/common";
import { GetItemCurrency } from "variables/common";
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService";
import {  GlobalPropsPush } from "./FetchLogistic";
import TransferCustomerToWarehouseBody from "./Body/TransferCustomerToWarehouse";
import { FetchLogisticSelect } from "Main/Logistic/FetchLogistic";
import CustomerService from "Main/CustomerService";
import { useEffect } from "react";
import BasicInput from "components/Input/BasicInput";

function TransferCustomerToWarehouse(props) {
  const Service = new LogisticGoodsService()
  const customerService = new CustomerService()
  const refreshToken = getRefreshToken();
  const logisticData = getLogisticGoodsCustomerData()
  const name = logisticData.name
  const description = logisticData.description
  const itemData = getLogisticItemData()
  const itemId = itemData.id
  const sn = itemData.sn
  var selectCurrencyOptions = GetItemCurrency()
  const [warehouseOptions, setwarehouseOptions] = useState(getLogisticWarehouseOption())
  const [customerOptions, setcustomerOptions] = useState(getCustomerOptions())
  const customerFilterOption = customerOptions.filter((data) => data.value === logisticData.customerId)
  const idLogistic = logisticData.id
  const qtyState = logisticData.qty
  const warehouseData = getLogisticWarehouseData()
  const supplierData = getLogisticSupplierData()
  const warehouseId = warehouseData.id
  const supplierId = supplierData.id
  // const [logisticItem, logisticItemOnChange] = useState(logisticItemOptions.find((data) => data.value === itemId))
  const [warehouse, warehouseOnChange] = useState('')
  const [customer, customerOnChange] = useState(customerOptions.find((data) => data.value === logisticData.customerId))
  const priceValue = logisticData.price
  const [qty, qtyOnChange] = useState(1)
  const currency  = selectCurrencyOptions.find(data => data.label === 'Rupiah')
  const [insertDate, insertDateOnChange] = useState()
  const ownerOption = getOwner()
  const owner = itemData.owner || warehouseData.owner || supplierData.owner
  const mitra = ownerOption.find((data) => data.value === owner)

  useEffect(() => {
    FetchSelectData(mitra.mitraid)
  }, [])

  const submit = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = CustomerToWarehouse(warehouse.value, idLogistic, qty, insertDate)
      const result = await Service.transferCustomerToWarehouse(payload)
      await GlobalPropsPush(props, itemId, warehouseId, supplierId, result)
    } catch (error) {
      return CatchError(error, props, refreshToken)
    }
  }

  const FetchSelectData = async (mitra) => {
  const { logisticWarehouse } = await FetchLogisticSelect(mitra)
    setwarehouseOptions(logisticWarehouse)
    warehouseOnChange('')
    warehouseOnChange('')
    customerOnChange('')
    qtyOnChange(1)
    insertDateOnChange(null)
    await getCustomer(mitra)
  }

  const getCustomer = async (mitraid) => {
    const customerOption = await customerService.getCustomerSelect(mitraid)
    const options = customerOption.data
    setcustomerOptions(options)
    customerOnChange(options.find((data) => data.value === logisticData.customerId))
  }
  // const AddMany = () => {
  //   props.history.push('/admin/logistic-customer-to-warehouse-blob')
  // }

  return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Transfer Customer Ke Gudang</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <BasicInput
                      key={1}
                      label = {'Mitra'}
                      value = {mitra.label}
                    />
                    <TransferCustomerToWarehouseBody
                      key={2}
                      name = {name}
                      warehouse = { warehouse}
                      customerFilterOption = {customerFilterOption}
                      warehouseOnChange = {warehouseOnChange}
                      customer = {customer}
                      warehouseOptions = {warehouseOptions}
                      customerOnChange = {customerOnChange}
                      qty = {qty}
                      qtyState = {qtyState}
                      qtyOnChange = {qtyOnChange}
                      priceValue = {priceValue}
                      currency = {currency}
                      insertDate = {insertDate}
                      insertDateOnChange = {insertDateOnChange}
                      description = {description}
                    />
                  <Button onClick = {submit} disabled = {!sn} color="info">Submit</Button>
                  {/* <Button onClick = {AddMany} disabled = {!sn} color="info">Send Many</Button> */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default TransferCustomerToWarehouse;


