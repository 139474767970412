import React from "react";
// import NotificationAlert from "react-notification-alert";
import { getUserData } from "variables/common";
import background from "assets/img/pattern3.jpg";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Label,
  Input,
  FormGroup
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { Datestr } from "Main/Utils";

function User () {
  const userData = getUserData()
  return (
    <>
    {/* <NotificationAlert ref={notificationAlert} /> */}
      <PanelHeader size="sm"/>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Profile Saya</h5>
              </CardHeader>
              <CardBody>
                <Card className="card-user">
              <div className="image">
                <img alt="..." src={background}/>
              </div>
              <CardBody>
                <div className="author">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar border-gray full-profile-lg img-thumbnail"
                      src= {userData.imgUrl || require("assets/img/pattern1.jpg")}
                    />
                    <h5 className="title">{userData.username}</h5>
                  </a>
                  <p className="description">{userData.fullName}</p>
                </div>
                <p className="description text-center">
                  Created {Datestr(userData.creation)} <br />
                  Last Updated {Datestr(userData.modified)}<br />
                </p>
              </CardBody>
              <hr />
            </Card>
                <Form>
                  <FormGroup>
                        <Label>Username</Label>
                        <Input
                          value={userData.username}
                          placeholder="Username"
                          type="text"
                        />
                        <Label>Email</Label>
                        <Input
                          value={userData.email}
                          placeholder="Email"
                          type="text"
                        />
                        <Label>Nama Depan</Label>
                        <Input
                          value = {userData.firstName}
                          placeholder="Nama Depan"
                          type="text"
                        />

                        <Label>Nama Belakang</Label>
                        <Input
                          value = {userData.lastName}
                          placeholder="Nama Belakang"
                          type="text"
                        />

                        <Label>Nama Lengkap</Label>
                        <Input
                          value = {userData.fullName}
                          placeholder="Nama Lengkap"
                          type="text"
                        />

                        <Label>API key</Label>
                        <Input
                          value={userData.apiKey}
                          placeholder="API Key"
                          type="text"
                        />

                        <Label>API Secret</Label>
                        <Input
                          value={userData.apiSecret}
                          placeholder="API Secret"
                          type="password"
                        />

                        <Label>DocType</Label>
                        <Input
                          value={userData.docType}
                          placeholder="Doctype"
                          type="text"
                        />
                        <Label>Organisasi</Label>
                        <Input
                          value={userData.organization}
                          placeholder="Organisasi"
                          type="text"
                        />
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default User;
