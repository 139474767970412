import React from "react";
import LoadingScreen from "components/Loader/LoadingScreen";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken,  getUserData, getOwner, PriceFormat } from "variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions";
import BasicInput from "components/Input/BasicInput";
import { DateTimestr } from "Main/Utils";
import { Datestr } from "Main/Utils";
// import { payloadDownload } from "variables/MitraSubmission/SubmissionFinance";
// import { Downloads } from "Main/Utils";
import { verifySuperAdmin } from "Main/Utils";
import { getFinanceOutflowData } from "variables/MitraSubmission/FinanceOutflow";
import FinanceOutflowService from "Main/MitraSubmission/FinanceOutflowService";
import { addFinanceOutflowList } from "variables/MitraSubmission/FinanceOutflow";
import { payloadUpdate } from "variables/MitraSubmission/FinanceOutflow";

function FinanceOutflowView(props) {
  const submissionFinance = getFinanceOutflowData()
  const submissionFinanceService =  new FinanceOutflowService()
  const refreshToken = getRefreshToken();
  const userData = getUserData()
  const ownerData = getOwner()
  const owner = ownerData.find(own => own.value === userData.organization)
  const [loading, loadingOnChange ] = useState(false)
  const [price, setPrice] = useState(submissionFinance.price)
  const [desc, setDesc] = useState(submissionFinance.description)
  const {id, name, creation, modified,
    createdBy, modifiedBy, submitDate,
    filename } = submissionFinance
  const { userSuper } = verifySuperAdmin()

  const getAll =  async(trash = false) => {
    const len = 'ALL'
    const result =  await submissionFinanceService.getAllData(len, trash)
    addFinanceOutflowList(result.data)
  }

  const download = async(id) => {
    // loadingOnChange(true)
    const result = await submissionFinanceService.downloadPdf(id, filename)
    // loadingOnChange(false)
    return result
  }

const update = async() => {
    swal("Update", "Perubahan Data Tidak Dapat di Batalkan", "info", {
            buttons: {
            update: {text: "Update", value: 'up' },
            cancel: 'Batal'
          }
        }
    ).then(async(value) => {
      switch (value) {
            case 'up':
              try {
                const payload = payloadUpdate(price, desc)
                console.log(payload)
                const result = await submissionFinanceService.putData(id, payload)
                await getAll()
                swal(result.status, result.message, 'success').then(() => {
                  props.history.push('/admin/finance-outflow-list')
                })
              } catch (error) {
                return await CatchError(error, props, refreshToken)
              }
              break;

            default:
              break;
          }
        })
    }
  // function deleteSelect () {
  //   // todo
  // }

  const children = (
    <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Detail Pengeluaran</h5>
                </CardHeader>
                <CardBody>
                  <BasicInput
                    key={"1"}
                    label = {'Mitra'}
                    disabled
                    value = {owner.value}
                  />
                  <BasicInput
                    key={"2"}
                    label = {'nama'}
                    disabled
                    value = {name}
                  />
                  <BasicInput
                    key={"3"}
                    label = {'Harga'}
                    value = {price}
                    onChange = {setPrice}
                    type = {'number'}
                  />
                  <BasicInput
                    key={"4"}
                    label = {'Price Display'}
                    value = {PriceFormat(price, 'idr')}
                  />
                  <BasicInput
                    key={"5"}
                    label = {'Tanggal Submit'}
                    disabled
                    value = {Datestr(submitDate)}
                  />
                  <div>
                  <BasicInput
                    key={"6"}
                    label = {'Attachment'}
                    disabled
                    value = {filename}
                  />
                  <Button
                    key={"7"}
                    onClick = {() => download(id)}
                    disabled = {!filename}
                    color="info">Download</Button>
                  </div>
                  <BasicInput
                    key={"8"}
                    label = {'Deskripsi'}
                    value = {desc}
                    onChange = {setDesc}
                    type = {'textarea'}
                  />
                  <BasicInput
                    key={"9"}
                    label = {'Creation'}
                    disabled
                    value = {DateTimestr(creation)}
                  />
                  <BasicInput
                    key={"10"}
                    label = {'Modified'}
                    disabled
                    value = {DateTimestr(modified)}
                  />
                  <BasicInput
                    key={"11"}
                    label = {'CreatedBy'}
                    disabled
                    value = {createdBy}
                  />
                  <BasicInput
                    key={"12"}
                    label = {'ModifiedBy'}
                    disabled
                    value = {modifiedBy}
                  />
                  <Button onClick = {update}
                  disabled = {!userSuper}
                  color="info">Submit</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
  )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen
          children={children}
          loading = {loading}
          text={"Loading..."}/>
      </>
    );
}


export default FinanceOutflowView;
