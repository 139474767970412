import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";
// core components
import swal from "sweetalert";
import { TabsTable } from "components/Tabs/TabsTable";
import { indoorDeviceColumns } from "./RenderIndoorDevice";
import { useState } from "react";

function IndoorDeviceInventoryList(props) {
  const {tableData, add, loading, addPermission, dataState } = props
  const [tabIndex, setTabIndex] = useState(0)

  try {
    // Prototype
    return (
      <>
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <Row>
                  <Col md={12}>
                    <CardTitle tag="h4" className="title">Indoor Device Inventory</CardTitle>
                  </Col>
                  <Col>
                    <Button color="info" onClick={add} disabled = {!addPermission}>Add</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Col md = {12}>
                  {/* <ListData/> */}
                  <TabsTable
                    key = {'qwdwefwefwe'}
                    dataset = {tableData}
                    dataTable = {dataState}
                    columns = {indoorDeviceColumns}
                    isLoading = {loading}
                    tabIndex = {tabIndex}
                    setTabIndex = {setTabIndex}
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    )
  } catch (error) {
      swal(`Failed to Load Data err: ${error.message}`).then(() => {
        return (<p>Error</p>)
      }
    )
  }
}


export default IndoorDeviceInventoryList;
