import React, { useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import BillingService from "Main/BillingService";
import CustomerService from "Main/CustomerService";
import { validatePermission } from "Main/Utils";
import Select from "react-select"
import { useState } from "react";
import {getRefreshToken,
  getProvince, addCity, addSubDistrict, addUrban,
  getBillingAddressData, addCustomerData, getAddressType,
  getCustomerOptions, getOwner, billingAddressUpdate, getUserPermission } from "../../../variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils"
import InputCoordinate from "components/Input/InputCoordinate";

function BillingView(props) {
  const pages = 'address'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const billingService =  new BillingService()
  const customerService = new CustomerService()
  const refreshToken = getRefreshToken()
  const BillingData = getBillingAddressData()
  const selectAddressTypeOptions = getAddressType()
  const id = useFormInput(BillingData.addressId)
  const name = useFormInput(BillingData.name)
  const ownerData = getOwner()
  const owner = ownerData.find(own => own.value === BillingData.owner)
  // console.log(BillingData)
  const addressTitle = useFormInput(BillingData.addressTitle)
  const addressLine = useFormInput(BillingData.addressLine)
  const [addressType, addAddressTypeOnChage] = useState({value: BillingData.addressType, label: BillingData.addressType})
  const [province , provinceOnChange] = useState({value: BillingData.province, label: BillingData.province})
  const [city , cityOnChange] = useState({value: BillingData.city, label: BillingData.city})
  const [subDistrict , subDistrictOnChange] = useState({value: BillingData.subDistrict, label: BillingData.subDistrict})
  const [urban , urbanOnChange] = useState({value: BillingData.urban, label: BillingData.urban})
  const [PrimaryAddress, primaryAddressOnChange] = useState(BillingData.primaryAddress)
  const [ShippingAddress, ShippingAddressOnChage] = useState(BillingData.shippingAddress)
  const [cityOptions, cityOptionsOnChange] = useState([])
  const [subDistrictOptions, subDistrictOptionsOnChange] = useState([])
  const [UrbanOptions, urbanOptionsOnChange] = useState([])
  var selectProvinceOptions = getProvince()
  const CustomerOptions = getCustomerOptions()
  const [selectCustomerOptions, selectCustomerOptionsOnChange] = useState(CustomerOptions)
  const [customer , customerOnChange] = useState(selectCustomerOptions.find((option) => option.value === BillingData.customerId) || '')
  const [latitude, setLatitude] = useState(BillingData.latitude)
  const [longitude, setLongitude] = useState(BillingData.longitude)

  const CityLoad = async(city) => {
    const dat = await customerService.getCity(city.value)
    const result = dat.data
    cityOptionsOnChange(result)
    addCity(result)
    return result
  }

  const subDistrictLoad = async(subDistrict) => {
    const dat = await customerService.getSubDistrict(subDistrict.value)
    const result = dat.data
    addSubDistrict(result)
    subDistrictOptionsOnChange(result)
    return result
  }

  const getCustomer = async (mitraid) => {
    const customerOption = await customerService.getCustomerSelect(mitraid)
    console.log(customerOption.data)
    const optionData = customerOption.data
    selectCustomerOptionsOnChange(optionData)
    customerOnChange(optionData.find((option) => option.value === BillingData.customerId))
  }

  const UrbanLoad = async(urban) => {
    const dat = await customerService.getUrban(urban.value)
    const result = dat.data
    urbanOptionsOnChange(result)
    addUrban(result)
    return result
  }

  useEffect(() => {
    validatePermission(props, 2, 1)
    CityLoad(province)
    subDistrictLoad(city)
    UrbanLoad(subDistrict)
    getCustomer(owner.mitraid)
  }, [])

  const getBilling =  async() => {
    try {
      const dat =  await billingService.getBillingAddress(id.value)
      const result = dat.data
      addCustomerData(result)
      window.location.reload();
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }}

  const putBillingData = async() => {
    swal("Update", "Item Data Can't be Undo", "info", {
                buttons: {
                update: {text: "Update", value: 'up' },
                cancel: 'Cancel'
              }
            }
      ).then(async(value) => {
        switch (value) {
          case 'up':
            try {
              const payload = billingAddressUpdate(customer.value, name.value,
                            addressTitle.value, addressType.value, addressLine.value,
                            province.value, city.value,
                            subDistrict.value, urban.value,
                            PrimaryAddress, ShippingAddress,
                            latitude, longitude)
              // console.log(payload)
              const result = await billingService.putBillingAddress(id.value, payload)
              swal(result.status, result.message, "success").then(() => {
                props.history.push('/admin/Billing-Address-list')
              })
            } catch (error) {
              return await CatchError(error, props, refreshToken)
              }
            break;

          default:
            swal('Cancel', 'Update Cancel', 'info')
            break;
        }
      })
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content" onLoadStart ={getBilling}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">{name.value}</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <div>
                          <Label>Customer</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Customer"
                            name="singleSelect"
                            value = {customer}
                            options = {selectCustomerOptions}
                            onChange={(value) => { customerOnChange(value)
                            }
                            }
                          />
                          <Label>Customer Category {customer.category}</Label>
                        </div>
                        <Label>Mitra</Label>
                          <Input
                            placeholder="Mitra"
                            value = {owner.value}
                            type="email"
                          />
                          <Label>Judul Alamat</Label>
                          <Input
                            placeholder="Judul Alamat"
                            {...addressTitle}
                            type="text"
                          />
                          <Label>Alamat Spesifik</Label>
                          <Input
                            placeholder="Alamat Spesifik"
                            {...addressLine}
                            type="textarea"
                          />
                          <Label>Tipe Alamat</Label>
                          <Select
                            placeholder="Tipe Alamat"
                            // className="react-select"
                            // classNamePrefix="react-select"
                            name="singleSelect"
                            value = {addressType}
                            options = {selectAddressTypeOptions}
                            onChange={async(value) => {
                              addAddressTypeOnChage(value)
                          }}
                          />
                        <Label>provinsi</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Provinsi"
                            name="singleSelect"
                            value = {province}
                            options = {selectProvinceOptions}
                            onChange={async(value) => {
                              // sessionStorage.removeItem('city')
                              const data = await CityLoad(value)
                              cityOnChange(null)
                              subDistrictOnChange(null)
                              urbanOnChange(null)
                              provinceOnChange(value)
                              cityOptionsOnChange(data)
                              subDistrictOptionsOnChange([])
                              urbanOptionsOnChange([])
                          }
                            }
                          />
                          <div>
                          <Label>Kota</Label>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Kota"
                              name="singleSelect"
                              value = {city}
                              options={cityOptions}
                              onChange={async (value) => {
                                // sessionStorage.removeItem('subDistrict')
                                subDistrictOnChange(null)
                                urbanOnChange(null)
                                const data = await subDistrictLoad(value)
                                subDistrictOptionsOnChange(data)
                                urbanOptionsOnChange([])
                                cityOnChange(value)
                              }}
                            />
                            {!city || !city.status ? (
                              <label className="error">
                                Phone Code Not Available, Set Default code (000)
                              </label>
                            ) : <label className="success">
                                Phone Code Available
                              </label>}
                            </div>
                          <div>
                          <Label>Kecamatan</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Kecamatan"
                            name="singleSelect"
                            value = {subDistrict}
                            options={subDistrictOptions}
                            onChange={async (value) => {
                              // sessionStorage.removeItem('urban')
                              urbanOnChange(null)
                              subDistrictOnChange(value)
                              const data = await UrbanLoad(value)
                              urbanOptionsOnChange(data)
                            }}
                          />
                          {!subDistrict || !subDistrict.status ? (
                              <label className="error">
                                Phone Code Not Available, Set Default code (000)
                              </label>
                            ) : <label className="success">
                                Phone Code Available
                              </label>}
                            </div>
                          <Label>Kelurahan</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Kelurahan"
                            name="singleSelect"
                            value = {urban}
                            options={UrbanOptions}
                            onChange={(value) => urbanOnChange(value)}
                          />
                          <InputCoordinate
                            latitude = {latitude}
                            setLatitude = {setLatitude}
                            longitude = {longitude}
                            setLongitude = {setLongitude}
                          />
                          <FormGroup check>
                            <Label check> Alamat Utama
                              <Input type="checkbox"
                                  checked = {PrimaryAddress}
                                  value = {PrimaryAddress}
                                  onChange = {e => {primaryAddressOnChange(e.target.checked)
                                  ShippingAddressOnChage(false)
                                  }}
                                />
                              <span className="form-check-sign" />
                            </Label>
                      </FormGroup>

                      <FormGroup check>
                            <Label check> Alamat Pengiriman
                              <Input type="checkbox"
                                  checked = {ShippingAddress}
                                  value = {ShippingAddress}
                                  onChange = {e => {ShippingAddressOnChage(e.target.checked)
                                  primaryAddressOnChange(false)
                                  }}
                                />
                              <span className="form-check-sign" />
                            </Label>
                      </FormGroup>
                      </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            <Button onClick = {putBillingData} disabled = {!Permission.Update} color="info">Edit</Button>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default BillingView;
