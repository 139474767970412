import React, { useEffect } from "react";
import LoadingScreen from "components/Loader/LoadingScreen";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Select from 'react-select'
import { useState } from "react";
import {getRefreshToken, getUserData,
  getOwner, getProvince, addCity,
  addSubDistrict, addUrban, router,
  getRouterData, addRouterData, addGridPoleList,
  addIndoorDeviceList, addOutdoorDeviceList, getGridPoleList,
  getIndoorDeviceList, getOutdoorDeviceList, addRouterSelected,
  addIOutoorDeviceData, addGridPoleData, addIndoorDeviceData,
  PriceFormat, addItemData, addItemRouterList, getItemRouterList,
  addRouterList, getUserPermission } from "./../../../variables/common"
import RouterService from "./../../../Main/RouterService"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import CustomerService from "Main/CustomerService";
import GridPoleInventoryList from "./../DeviceInventory/GridPole/GridPoleDeviceInventoryList";
import IndoorDeviceInventoryList from "./../DeviceInventory/IndoorDevice/IndoorDeviceInventoryList";
import OutdoorDeviceInventoryList from "./../DeviceInventory/OutdoorDevice/OutdoorDeviceInventoryList";
import CommonList from '../../../components/Table/CommonList'
import { columns } from './../Item/ItemColumns'
import ItemService from "Main/ItemService";
import { verifySuperAdmin } from "Main/Utils";
import InputCoordinate from "components/Input/InputCoordinate";
import { DateTimestr } from "Main/Utils";
import { renderGridPoleTable } from "../DeviceInventory/GridPole/RenderGridPole";
import { renderMainIndoorDeviceTable } from "../DeviceInventory/IndoorDevice/RenderIndoorDevice";
import { renderMainOutdoorDeviceTable } from "../DeviceInventory/OutdoorDevice/RenderOutdoorDevice";
import { useFormInput } from "Main/Utils";

function RouterUpdate(props) {
  const pages = 'resource'
  var selectProvinceOptions = getProvince()
  var ownerData = getOwner()
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const routerService = new RouterService()
  const customerService = new CustomerService()
  const routerData = getRouterData()
  const id = routerData.id
  const userData = getUserData()
  const usrType = userData.userType
  const refreshToken = getRefreshToken()
  const name = useFormInput(routerData.name)
  const merk = useFormInput(routerData.merk)
  const type = useFormInput(routerData.type)
  const sn = useFormInput(routerData.sn)
  const description = useFormInput(routerData.description)
  const [province , provinceOnChange] = useState(selectProvinceOptions.find((prov => prov.label === routerData.province_name)))
  const [city , cityOnChange] = useState({value: routerData.city, label: routerData.city})
  const [subDistrict , subDistrictOnChange] = useState({value: routerData.sub_district, label: routerData.sub_district})
  const [urban , urbanOnChange] = useState({value: routerData.urban, label: routerData.urban})
  const [cityOptions, cityOptionsOnChange] = useState([])
  const [subDistrictOptions, subDistrictOptionsOnChange] = useState([])
  const [UrbanOptions, urbanOptionsOnChange] = useState([])
  const [ipaddress, ipaddressOnChange] = useState(routerData.ip_address)
  const [port, portOnChange] = useState(routerData.port)
  const owner = ownerData.find(data => data.value === routerData.owner)
  const ownerFilter = ownerData.filter(data => data.value !== routerData.owner)
  const [passwordValue, passwordValueOnChange] = useState()
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, loadingOnChange] = useState(false)
  const [loadingIndoorDevice, loadingIndoorDeviceOnChange] = useState(false)
  const [loadingOutdoorDevice, loadingOutdoorDeviceOnChange] = useState(false)
  const [loadingGridPole, loadingGridPoleOnChange] = useState(false)
  const [gridPoleList, gridPoleListOnChange] = useState(getGridPoleList())
  const [indoorDeviceList, indoorDeviceListOnChange] = useState(getIndoorDeviceList())
  const [outdoorDeviceList, outdoorDeviceListOnChange] = useState(getOutdoorDeviceList())
  const itemService =  new ItemService()
  const ItemLen = useFormInput('ALL')
  const [loaded, setDataLoaded] = useState(true)
  const dataTable = getItemRouterList()
  const [ownerTransfer, ownerTransferOnChange] = useState()
  const { userSuper } = verifySuperAdmin()
  const [latitude, setLatitude] = useState(routerData.latitude)
  const [longitude, setLongitude] = useState(routerData.longitude)
  const { creation, modified, createdBy, modifiedBy } = routerData

  const getItem =  async() => {
      try {
        setDataLoaded(true)
        const len = ItemLen.value
        const dat =  await routerService.getAllItemByRouterid(id, len)
        const result = dat.data
        addItemRouterList(result)
        setDataState(mapping(result))
        setDataLoaded(false)
        return result
      } catch (error) {
        setDataLoaded(false)
        return await CatchError(error, props, refreshToken)
      }
  }

  const deleteItem = async (Id) => {
    await swal("Delete", "Data Tidak Dapat di Recover", "info", {
          buttons: {
          cancel: 'Cancel',
          delete: {text: "Delete", value: 'del' }
        }
      }
      ).then( async(value) => {
        switch (value) {
          case 'del':
            try {
              setDataLoaded(true)
              const noRouter = false
              const respomse = await itemService.deleteItem(Id, noRouter)
              await swal(respomse.status, respomse.message, 'success')
              const data = await getItem()
              const stateRaw = mapping(data)
              const state = stateRaw.filter((data) => data.id !== Id)
              setDataState(state)
              setDataLoaded(false)
              return true
            } catch (error) {
              setDataLoaded(false)
              return await CatchError(error, props, refreshToken)
            }

          default:
            await getItem()
            return false
        }
      })
    }

    const viewItem = async(id)=> {
      try {
        const dat = await itemService.getItem(id)
        const result = dat.data
        addItemData(result)
        props.history.push('/admin/item-update')
      } catch (error) {
        return await CatchError(error, props, refreshToken)
        }
    }

    const mapping = (DataTable) => DataTable.map((Data) => {
          try {
            return {
            id: Data.id,
            name: Data.name,
            owner: Data.owner.toLowerCase(),
            category: Data.category,
            uplink: `${Data.uplink} ${Data.measurement}`,
            downlink: `${Data.downlink} ${Data.measurement}`,
            // price: PriceFormat(Data.price, Data.currency.toUpperCase()),
            // discount: `${Data.discount}%`,
            router: Data.routerName,
            finalPrice: PriceFormat(Data.finalPrice, Data.currency.toUpperCase()),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>

                {/* use this button to add a edit kind of action */}
                <Button
                  onClick={() => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    viewItem(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}8`}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                {/* use this button to remove the data row */}
                <Button
                  onClick={async () => {
                    await deleteItem(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}9`}
                  color="danger"
                  size="sm"
                >
                  <i className="fa fa-times" />
                </Button>{" "}
              </div>
            ),
          };
          } catch (error) {
            return []
          }
        })

  const [dataState, setDataState] = useState(mapping(dataTable))

  const getRouter =  async() => {
        const result =  await routerService.getRouter(id)
        const resultAll = await routerService.getAllRouter('ALL')
        addRouterData(result.data)
        addRouterList(resultAll.data)
  }

   const togglePassword = () => {
      setPasswordShown(!passwordShown);
  };

  const updateRouterData = async() => {
      try {
          loadingOnChange(true)
          const payload = router(name.value, ipaddress, passwordValue, port, province.label, city.label, subDistrict.label, urban.label, merk.value, type.value, sn.value, description.value, latitude, longitude)
          const result = await routerService.putRouter(id, payload)
          await getRouter()
          loadingOnChange(false)
          await swal(result.status, result.message, "success")
          props.history.push('/admin/router-list')
        } catch (error) {
          loadingOnChange(false)
          return await CatchError(error, props, refreshToken)
        }
    }

    const CityLoad = async(city) => {
      const dat = await customerService.getCity(city.value)
      const result = dat.data
      addCity(result)
      return result
    }

    const subDistrictLoad = async(subDistrict) => {
      const dat = await customerService.getSubDistrict(subDistrict.value)
      const result = dat.data
      addSubDistrict(result)
      return result
    }

    const UrbanLoad = async(urban) => {
      // sessionStorage.removeItem('urban')
      const dat = await customerService.getUrban(urban.value)
      const result = dat.data
      addUrban(result)
      return result
    }

    const gridPoleLoad = async() => {
      loadingGridPoleOnChange(true)
      const len = 'ALL'
      const dat = await routerService.getAllGridPole(id, len)
      const result = dat.data
      gridPoleListOnChange(result)
      addGridPoleList(result)
      const rawState = renderGridPoleTable(result, viewGridPole, deleteGridPole, Permission.Read, Permission.Delete)
      setgridPoleDataState(rawState)
      loadingGridPoleOnChange(false)
      return result
    }

    const IndoorDeviceLoad = async() => {
      loadingIndoorDeviceOnChange(true)
      const len = 'ALL'
      const dat = await routerService.getAllIndoorDevice(id, len)
      const result = dat.data
      indoorDeviceListOnChange(result)
      addIndoorDeviceList(result)
      const rawState = renderMainIndoorDeviceTable(result, viewIndoorDevice, deleteIndoorDevice, Permission.Read, Permission.Delete)
      setindoorDeviceDataState(rawState)
      loadingIndoorDeviceOnChange(false)
      return result
    }

    const OutdoorDeviceLoad = async() => {
      loadingOutdoorDeviceOnChange(true)
      const len = 'ALL'
      const dat = await routerService.getAllOutdoorDevice(id, len)
      const result = dat.data
      outdoorDeviceListOnChange(result)
      addOutdoorDeviceList(result)
      const rawState = renderMainOutdoorDeviceTable(result, viewOutdoorDevice, deleteOutdoorDevice, Permission.Read, Permission.Delete)
      setoutdoorDeviceDataState(rawState)
      loadingOutdoorDeviceOnChange(false)
      return result
    }

    useEffect(() => {
      CityLoad(province)
      subDistrictLoad(city)
      UrbanLoad(subDistrict)
      getRouter()
      gridPoleLoad()
      IndoorDeviceLoad()
      OutdoorDeviceLoad()
    }, [])


  const addGridPole = async() => {
    addRouterSelected(routerData)
    props.history.push('/admin/gridpole-page')
  }

  const addIndoorDevice = async() => {
    addRouterSelected(routerData)
    props.history.push('/admin/indoor-device-page')
  }

  const addOutdoorDevice = async() => {
    addRouterSelected(routerData)
    props.history.push('/admin/outdoor-device-page')
  }

  const viewGridPole = async (gridId) => {
    const data = await routerService.getGridPole(gridId)
    const gridPole = data.data
    addGridPoleData(gridPole)
    props.history.push('/admin/gridpole-update')
  }

  const viewIndoorDevice = async (indoorId) => {
    const data = await routerService.getIndoorDevice(indoorId)
    const indoorDevice = data.data
    addIndoorDeviceData(indoorDevice)
    props.history.push('/admin/indoor-device-update')
  }

  const viewOutdoorDevice = async (outdoorid) => {
    const data = await routerService.getOutdoorDevice(outdoorid)
    const outdoorDevice = data.data
    addIOutoorDeviceData(outdoorDevice)
    props.history.push('/admin/outdoor-device-update')
  }

  const deleteGridPole = async(Id) => {
   const value = await swal("Hapus", "Data Tidak Dapat di Recover", "info", {
        buttons: {
          cancel: 'Batal',
          delete: { text: "Hapus", value: 'del' }
        }
      }
      );
      switch (value) {
        case 'del':
          try {
            // setDataLoaded(true)
            const respomse = await routerService.deleteGridPole(Id);
            await swal(respomse.status, respomse.message, 'success')
            const rawData = await gridPoleLoad()
            const rawState = renderGridPoleTable(rawData, viewGridPole, deleteGridPole, Permission.Read, Permission.Delete)
            const newState = rawState.filter((data) => data.id !== Id)
            setgridPoleDataState(newState)
            return newState
          } catch (error) {
            // setDataLoaded(false)
            return await CatchError(error, props, refreshToken);
          }

        default:
          await gridPoleLoad();
          return false;
      }
  }

  const deleteIndoorDevice = async(Id) => {
      const value = await swal("Delete", "Indoor Device Data Can't Recover", "info", {
        buttons: {
          cancel: 'Cancel',
          delete: { text: "Delete", value: 'del' }
        }
      })

      switch (value) {
        case 'del':
          try {
            const respomse = await routerService.deleteIndoorDevice(Id);
            await swal(respomse.status, respomse.message, 'success')
            const rawData = await IndoorDeviceLoad()
            const newState = renderMainIndoorDeviceTable(rawData, viewIndoorDevice, deleteIndoorDevice, Permission.Read, Permission.Delete)
            setindoorDeviceDataState(newState)
            return newState
          } catch (error) {
            // setDataLoaded(false)
            return await CatchError(error, props, refreshToken);
          }

        default:
          await IndoorDeviceLoad();
          return false;
      }
  }

  const deleteOutdoorDevice = async(Id) => {
   const value = await swal("Delete", "Outdoor Device Data Can't Recover", "info", {
        buttons: {
          cancel: 'Cancel',
          delete: { text: "Delete", value: 'del' }
        }
      })

      switch (value) {
        case 'del':
          try {
            const respomse = await routerService.deleteOutdoorDevice(Id);
            await swal(respomse.status, respomse.message, 'success')
            const rawData = await OutdoorDeviceLoad()
            const rawState = renderMainOutdoorDeviceTable(rawData, viewOutdoorDevice, deleteOutdoorDevice, Permission.Read, Permission.Delete)
            setoutdoorDeviceDataState(rawState)
            return rawState
          } catch (error) {
            // setDataLoaded(false)
            return await CatchError(error, props, refreshToken);
          }
        default:
          await OutdoorDeviceLoad();
          return false;
      }
    }

    const ChangeOwner = async() => {
      try {
          loadingOnChange(true)
          const payload = {owner: ownerTransfer.value}
          const result = await routerService.changeRouterOwner(id, payload)
          await getRouter()
          loadingOnChange(false)
          swal(result.status, result.message, "success").then(() => {
              props.history.push('/admin/router-list')
          })
        } catch (error) {
          loadingOnChange(false)
          return await CatchError(error, props, refreshToken)
        }
    }

    function RouterChangeOwner ({transfer, transferOnChange, filterData, updateOwner}) {
    return (
      <>
        <Card>
          <CardHeader>
            <h5 className="title">Change Router Owner</h5>
          </CardHeader>
            <CardBody>
              <Form>
                <label>From Owner</label>
                <Input
                  className="react-select"
                  classNamePrefix="react-select"
                  placeholder="Owner"
                  name="singleSelect"
                  value={owner.value} />
                <label>To</label>
                <Select
                  // className="react-select"
                  // classNamePrefix="react-select"
                  placeholder="Transfer To"
                  name="singleSelect"
                  value={transfer}
                  options = {filterData}
                  onChange = {transferOnChange}
                />
                <Button onClick = {updateOwner} color="info">Transfer</Button>
            </Form>
          </CardBody>
        </Card>
      </>
    )}

    const SuperAdminMenu = () => {
      if (usrType === 'SUPERADMIN') {
        return (
          <>
          <RouterChangeOwner
            transfer={ownerTransfer}
            transferOnChange={ownerTransferOnChange}
            filterData={ownerFilter}
            updateOwner = {ChangeOwner}
          />
          <CommonList
            key={1}
            header={'Profile'}
            columns={columns}
            dataState={dataState}
            // Add={Add}
            load={loaded}
            getData={getItem} /></>
        )}
        return (<p></p>)
    }

    const [gridPoleDataState, setgridPoleDataState] = useState(renderGridPoleTable(gridPoleList, viewGridPole, deleteGridPole, Permission.Read, Permission.Delete))
    const [indoorDeviceDataState, setindoorDeviceDataState] = useState(renderMainIndoorDeviceTable(indoorDeviceList, viewIndoorDevice, deleteIndoorDevice, Permission.Read, Permission.Delete))
    const [outdoorDeviceDataState, setoutdoorDeviceDataState] = useState(renderMainOutdoorDeviceTable(outdoorDeviceList, viewOutdoorDevice, deleteOutdoorDevice, Permission.Read, Permission.Delete))


    const children = (
      <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Update Router</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <InputCoordinate
                            latitude = {latitude}
                            setLatitude = {setLatitude}
                            longitude = {longitude}
                            setLongitude = {setLongitude}
                          />
                          <Label>Username</Label>
                          <Input
                            {...name}
                            placeholder="Username"
                            type="text"
                          />
                          <Label>Owner</Label>
                          <Select
                            placeholder="Owner"
                            name="singleSelect"
                            value = {owner}
                          />
                          <Label>IP Address</Label>
                          <Input
                            placeholder="IP Address"
                            value = {ipaddress}
                            disabled = {!userSuper}
                            onChange = {(e) => {
                              if (e.target.value.length <= 15) {
                                ipaddressOnChange(e.target.value)
                              }
                            }}
                            type="text"
                          />
                          <Label>Port</Label>
                          <Input
                            placeholder="Port"
                            value = {port}
                            disabled = {!userSuper}
                            onChange = {(e) => {
                              if (e.target.value.length <= 4) {
                                portOnChange(e.target.value)
                              }
                            }}
                            type="number"
                          />

                          <Label>Password</Label>
                          <Input
                            placeholder="password"
                            value = {passwordValue}
                            disabled = {!userSuper}
                            onChange = { (e) => {
                              if (e.target.value.length <= 40) {
                                passwordValueOnChange(e.target.value)
                              }
                            }
                            }
                            type={passwordShown ? "text" : "password"}
                          />
                          <FormGroup check>
                            <Label check> Show
                              <Input type="checkbox"
                              value={passwordShown}
                              onChange={togglePassword}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                          <Label>Provinsi</Label>
                          <Select
                            placeholder="Provinsi"
                            name="singleSelect"
                            value = {province}
                            options = {selectProvinceOptions}
                            onChange={async(value) => {
                              // sessionStorage.removeItem('city')
                              const data = await CityLoad(value)
                              cityOnChange(null)
                              subDistrictOnChange(null)
                              urbanOnChange(null)
                              provinceOnChange(value)
                              cityOptionsOnChange(data)
                              subDistrictOptionsOnChange([])
                              urbanOptionsOnChange([])
                            }
                              }
                            />
                            <div>
                            <Label>Kota</Label>
                            <Select
                              placeholder="Kota"
                              name="singleSelect"
                              value = {city}
                              options={cityOptions}
                              onChange={async (value) => {
                                subDistrictOnChange(null)
                                urbanOnChange(null)
                                const data = await subDistrictLoad(value)
                                subDistrictOptionsOnChange(data)
                                urbanOptionsOnChange([])
                                cityOnChange(value)
                              }}
                            />
                            {!city || !city.status ? (
                              <label className="error">
                                Phone Code Not Available, Set Default code (000)
                              </label>
                            ) : <label className="success">
                                Phone Code Available
                              </label>}
                            </div>
                            <div>
                            <Label>Kecamatan</Label>
                            <Select
                              placeholder="Kecamatan"
                              name="singleSelect"
                              value = {subDistrict}
                              options={subDistrictOptions}
                              onChange={async (value) => {
                                urbanOnChange(null)
                                subDistrictOnChange(value)
                                const data = await UrbanLoad(value)
                                urbanOptionsOnChange(data)
                              }}
                            />
                            {!subDistrict || !subDistrict.status ? (
                              <label className="error">
                                Phone Code Not Available, Set Default code (000)
                              </label>
                            ) : <label className="success">
                                Phone Code Available
                              </label>}
                            </div>
                            <Label>Kelurahan</Label>
                            <Select
                              placeholder="Kelurahan"
                              name="singleSelect"
                              value = {urban}
                              options={UrbanOptions}
                              onChange={(value) => urbanOnChange(value)}
                            />
                            <Label>Merk</Label>
                            <Input
                              {...merk}
                              placeholder="Merk"
                              type="text"
                            />
                            <Label>Type</Label>
                            <Input
                              {...type}
                              placeholder="Type"
                              type="text"
                            />
                            <Label>Serial Number</Label>
                            <Input
                              {...sn}
                              placeholder="Serial Number"
                              type="text"
                            />
                            <Label>Deskripsi</Label>
                            <Input
                              {...description}
                              placeholder="Deskripsi"
                              type="text"
                            />
                            <Label>Created</Label>
                          <Input
                            placeholder="Created"
                            value = {DateTimestr(creation)}
                            type="text"
                          />
                          <Label>modified</Label>
                          <Input
                            placeholder="Modified"
                            value = {DateTimestr(modified)}
                            type="text"
                          />
                          <Label>created By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {createdBy}
                            type="email"
                          />
                          <Label>modified By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {modifiedBy}
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                      </Row>
                  </Form>
                    <Button
                      onClick = {updateRouterData}
                      disabled = {!Permission.Update}
                      color="info"
                    >Edit</Button>
                </CardBody>
              </Card>
              {
                SuperAdminMenu()
              }
              <IndoorDeviceInventoryList
                key = {'22e12e12e'}
                dataState = {indoorDeviceDataState}
                add={addIndoorDevice}
                loading={loadingIndoorDevice}
                viewPermission={Permission.Read}
                addPermission={Permission.Create}
                deletePermission={Permission.Delete}
                />
              <OutdoorDeviceInventoryList
                key = {'312e1e23e'}
                dataState = {outdoorDeviceDataState}
                add={addOutdoorDevice}
                loading={loadingOutdoorDevice}
                viewPermission={Permission.Read}
                addPermission={Permission.Create}
                deletePermission={Permission.Delete}
                />
              <GridPoleInventoryList
                key = {'4e12e2d3ef2f'}
                dataState = {gridPoleDataState}
                add={addGridPole}
                loading={loadingGridPole}
                viewPermission={Permission.Read}
                addPermission={Permission.Create}
                deletePermission={Permission.Delete}
                />
            </Col>
          </Row>
        </div>
    )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen
          key = {'5qwdqwdqwd'}
          children={children}
          loading = {loading}
          text={"Checking Router Credentials"}/>
      </>
    );
}

export default RouterUpdate;
