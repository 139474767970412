import React, { useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Select from 'react-select'
import { useState } from "react";
import {getRefreshToken, LogisticItem, getLogisticTypeOption,
  GetItemMeasurement, getLogisticItemData, addLogisticGoodsList,
  addLogisticGoodsData, addLogisticItemList, getLogisticGoodsList,
  addLogisticGoodsCustomerList,
  getLogisticGoodsCustomerList,
  addLogisticGoodsCustomerData,
  getLogisticMutationList,
  addLogisticMutationList,
  PriceFormat,
  addLogisticWarehouseData,
  addLogisticSupplierData,
  getUserPermission} from "./../../../variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import LogisticItemService from "Main/Logistic/LogisticItemService";
import { Datestr, DateTimestr } from "Main/Utils";
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService";
import CommonList from "../../../components/Table/CommonList";
import { nullCompensation } from "Main/Utils";
import { customTextFromBooleanReverse } from "Main/Utils";
import { MutationColumn } from "./LogisticGoods/MutationColumn";
import { FetchLogistic } from "./LogisticGoods/FetchLogistic"
import { customTextFromBooleanReverseDanger } from "Main/Utils";
import { customTextFromBoolean } from "Main/Utils";

function LogisticItemUpdate(props) {
  const pages = 'Logistic'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  var logisticTypeOptions = getLogisticTypeOption()
  var measurementOptions = GetItemMeasurement()
  const Service = new LogisticItemService()
  const logisticService = new LogisticGoodsService()
  const refreshToken = getRefreshToken();
  const logisticData = getLogisticItemData()
  const id = logisticData.id
  const itemId = logisticData.id
  const organization = logisticData.owner
  const name = useFormInput(logisticData.name)
  const description = useFormInput(logisticData.description)
  const [logisticType, logisticTypeOnChange] = useState(logisticTypeOptions.find((data) => data.label === logisticData.type))
  const [sn, snOnChage] = useState(logisticData.sn)
  const [measurement, measurementOnChange] = useState(measurementOptions.find((data) => data.value === logisticData.measurement))
  const created = logisticData.creation
  const modified = logisticData.modified
  const createdBy = logisticData.createdBy
  const modifiedBy = logisticData.modifiedBy
  const owner = logisticData.owner
  const [load, setDataLoad] = useState(true)
  const [loadCustomerGoods, setCustomerGoodsDataLoad] = useState(true)
  const [loadMutation, setMutationataLoad] = useState(true)
  const [dataTable, setDataTable] = useState(getLogisticGoodsList())
  const [customerGoodsDataTable, setDataTableCustomer] = useState(getLogisticGoodsCustomerList())
  const [mutatationDataTable, setDataTableMutatation] = useState(getLogisticMutationList())


  useEffect(() => {
    getLogisticMutation()
  }, [])

  const getAll =  async() => {
    const len = 'ALL'
    const result =  await Service.getAll(len)
      addLogisticItemList(result.data)
  }

  const getAllLogisticGoodsData =  async() => {
      try {
        setDataLoad(true)
        const len = 'ALL'
        const itemid = id
        const dat =  await logisticService.getAll({itemid, len})
        const result = dat.data
        // console.log(result)
        setDataTable(result)
        addLogisticGoodsList(result)
        setDataLoad(false)
        return result
      } catch (error) {
        return CatchError(error, props, refreshToken)
      }
    }

    const getAllLogisticGoodsCustomerData =  async() => {
      try {
        setDataLoad(true)
        const len = 'ALL'
        const itemid = id
        const dat =  await logisticService.getAllLogisticCustomer({itemid, len})
        const result = dat.data
        setDataTableCustomer(result)
        addLogisticGoodsCustomerList(result)
        setCustomerGoodsDataLoad(false)
        return result
      } catch (error) {
        return CatchError(error, props, refreshToken)
      }
    }

    const getLogisticMutation =  async() => {
      try {
        setDataLoad(true)
        const len = 'ALL'
        const dat =  await logisticService.getLogisticMutation(id, len)
        const result = dat.data
        console.log(result)
        setDataTableMutatation(result)
        addLogisticMutationList(result)
        setMutationataLoad(false)
        return result
      } catch (error) {
        setMutationataLoad(false)
        return CatchError(error, props, refreshToken)
      }
    }

    const DeleteLogisticGoodsData = (id) => {
      swal("Hapus", "Data Tidak Dapat di Recover", "info", {
        buttons: {
          cancel: 'Batal',
          delete: {text: "Hapus", value: 'del' }
        }
      }
      ).then(async (value) => {
        switch (value) {
          case 'del':
            try {
              setDataLoad(true)
              const response = await logisticService.delete(id)
              await FetchLogistic(itemId)
              await swal(response.status, response.message, 'success')
              const state = await getAllLogisticGoodsData()
              const stateRaw = mapping(state)
              const table = stateRaw.filter((dat) => dat.id !== id)
              setDataState(table)
              setDataLoad(false)
              return true
            } catch (error) {
              setDataLoad(false)
              return await CatchError(error, props, refreshToken)
            }

          default:
            await getAllLogisticGoodsData()
            // window.location.reload()
            return false
        }
      })
    }

    const DeleteLogisticGoodsCustomerData = (id) => {
      swal("Hapus", "Data Tidak Dapat di Recover", "info", {
        buttons: {
          cancel: 'Batal',
          delete: {text: "Hapus", value: 'del' }
        }
      }
      ).then(async (value) => {
        switch (value) {
          case 'del':
            try {
              setDataLoad(true)
              const response = await logisticService.deleteLogisticCustomer(id)
              await FetchLogistic(itemId)
              await swal(response.status, response.message, 'success')
              const state = await getAllLogisticGoodsCustomerData()
              const stateRaw = mapping(state)
              const table = stateRaw.filter((dat) => dat.id !== id)
              setcustomerGoodsDataState(table)
              setDataLoad(false)
              return true
            } catch (error) {
              setDataLoad(false)
              return await CatchError(error, props, refreshToken)
            }

          default:
            await getAllLogisticGoodsCustomerData()
            // window.location.reload()
            return false
        }
      })
    }

  const ViewLogisticGoodsData = async (id, transfer) => {
    try {
      const result = await logisticService.get(id)
      addLogisticWarehouseData([])
      addLogisticSupplierData([])
      addLogisticGoodsData(result.data)
      if (!transfer) {
        props.history.push('/admin/logistic-goods-update')
        return
      }
      props.history.push('/admin/logistic-warehouse-to-customer')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const AddLogisticGoods = () => {
    addLogisticWarehouseData([])
    addLogisticSupplierData([])
    props.history.push('/admin/logistic-goods-add')
  }


  const mapping = (TableData) => TableData.map((Data) => {
    try {
      return {
            id: Data.id,
            name: Data.name,
            qty: Data.qty,
            sn: nullCompensation(Data.sn),
            paid: customTextFromBoolean(Data.paid, 'Lunas', 'Belum-Bayar'),
            damaged: customTextFromBooleanReverse(Data.damaged, 'Bagus', 'Rusak'),
            price: PriceFormat((Number(Data.price) * Number(Data.qty)), 'idr'),
            location: Data.warehouse,
            organization: Data.owner,
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                {/* use this button to add a edit kind of action */}
                <Button
                disabled = {!Permission.Read}
                  onClick={ async () => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    await ViewLogisticGoodsData(Data.id, true)
                  }}
                  className="btn-icon btn-round"
                  color="primary"
                  id={`tooltip${Data.id}10`}
                  size="sm"
                >TF</Button>{" "}
                <Button
                disabled = {!Permission.Read}
                  onClick={ async () => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    await ViewLogisticGoodsData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  color="warning"
                  id={`tooltip${Data.id}10`}
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                <Button
                disabled = {!Permission.Delete}
                  onClick={ async () => {
                    await DeleteLogisticGoodsData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}11`}
                  color="danger"
                  size="sm"
                >
                  <i className="fa fa-times" />
                </Button>{" "}
              </div>
        ),
      };
    } catch (error) {
      return []
    }
  })
  const [dataState, setDataState] = React.useState(mapping(dataTable));

  const mappingCustomerGoods = (TableCustomerData) => TableCustomerData.map((Data) => {
    try {
          return {
            id: Data.id,
            name: Data.name,
            qty: Data.qty,
            sn: nullCompensation(Data.sn),
            paid: customTextFromBoolean(Data.paid, 'Lunas', 'Belum-Bayar'),
            damaged: customTextFromBooleanReverse(Data.damaged, 'Bagus', 'Rusak'),
            location: Data.customerName,
            organization: Data.owner,
            price: PriceFormat((Number(Data.price) * Number(Data.qty)), 'idr'),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>

                {/* use this button to add a edit kind of action */}
                <Button
                disabled = {!Permission.Read}
                  onClick={ async () => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    await ViewLogisticGoodsCustomerData(Data.id, true)
                  }}
                  className="btn-icon btn-round"
                  color="primary"
                  id={`tooltip${Data.id}10`}
                  size="sm"
                >TF</Button>{" "}
                <Button
                disabled = {!Permission.Read}
                  onClick={ async () => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    await ViewLogisticGoodsCustomerData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  color="warning"
                  id={`tooltip${Data.id}10`}
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                <Button
                disabled = {!Permission.Delete}
                  onClick={ async () => {
                    await DeleteLogisticGoodsCustomerData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}11`}
                  color="danger"
                  size="sm"
                >
                  <i className="fa fa-times" />
                </Button>{" "}
              </div>
            ),
          };
          } catch (error) {
            return []
          }
        })
    const [customerGoodsDataState, setcustomerGoodsDataState] = React.useState(
      mappingCustomerGoods(customerGoodsDataTable)
    )

    const mappingMutation = (TableData) => TableData.map((Data) => {
    try {
          return {
            id: Data.id,
            name: Data.name,
            qty: Data.qty,
            sn: nullCompensation(Data.sn),
            damaged: customTextFromBooleanReverse(Data.damaged, 'Bagus', 'Rusak'),
            location: Data.transferTo,
            organization: Data.owner,
            price: PriceFormat((Number(Data.price) * Number(Data.qty)), 'idr'),
            statusLogistic: !Data.trash ? customTextFromBooleanReverse(Data.statusOut, 'IN', 'OUT') 
            : customTextFromBooleanReverseDanger(Data.trash, '', 'DELETED'),
            date: Datestr(Data.creation)
          };
          } catch (error) {
            return []
          }
      }
    )
    // const [MutationDataState, setMutationDataState] = useState(
    //   mappingMutation(mutatationDataTable)
    // );
  const MutationDataState = mappingMutation(mutatationDataTable)

  const ViewLogisticGoodsCustomerData = async (id, transfer) => {
    try {
      const result = await logisticService.getLogisticCustomer(id)
      addLogisticGoodsCustomerData(result.data)
      if (!transfer) {
        props.history.push('/admin/logistic-goods-customer-update')
        return
      }
      props.history.push('/admin/logistic-customer-to-warehouse')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  // const AddLogisticGoodsCustomer = () => {
  //   props.history.push('/admin/logistic-goods-customer-add')
  // }

  const Edit = async() => {
    swal("Update", "Perubahan Tidak Dapat di Batalkan", "info", {
                buttons: {
                update: {text: "Update", value: 'up' },
                cancel: 'Batal'
              }
            }
      ).then(async(value) => {
        switch (value) {
          case 'up':
            try {
            const payload = LogisticItem(name.value, logisticType.value, measurement.value, sn, description.value)
              const result = await Service.put(id, payload)
              swal('Success', result.message, result.status).then(async () => {
                await getAll()
                props.history.push('/admin/logistic-item-list')
              })
            } catch (error) {
            return await CatchError(error, props, refreshToken)
            }
            break;

          default:
            break;
        }
      })
    }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Detail Item Gudang</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <Label>Nama *</Label>
                          <Input
                            placeholder="Nama"
                            {...name}
                            type="text"
                          />
                          <Label>Mitra</Label>
                          <Input
                            placeholder="Mitra"
                            value = {organization}
                            type="text"
                          />
                        <Label>Tipe Item</Label>
                        <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Tipe Item"
                            name="singleSelect"
                            value = {logisticType}
                            options = {logisticTypeOptions}
                            onChange={e => logisticTypeOnChange(e)
                          }
                          />
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Pengukuran"
                            name="singleSelect"
                            value = {measurement}
                            options = {measurementOptions}
                            onChange={e => measurementOnChange(e)
                          }
                          />
                          {/* <Label>Doctype</Label>
                          <Input
                            placeholder="Doctype"
                            value = 'User'
                            type="text"
                          /> */}
                          <FormGroup check>
                            <Label check> Memakai Serial Number
                              <Input type="checkbox"
                              value = {sn}
                              checked = {sn}
                              onChange = {e => snOnChage(e.target.checked)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                          <Label>Deskripsi</Label>
                          <Input
                          className="react-select"
                          type="textarea"
                          {...description}
                          />
                          <Label>Mitra</Label>
                          <Input
                            placeholder="Mitra"
                            value = {owner}
                            type="email"
                          />
                          <Label>Created</Label>
                          <Input
                            placeholder="Created"
                            value = {DateTimestr(created)}
                            type="text"
                          />
                          <Label>modified</Label>
                          <Input
                            placeholder="Modified"
                            value = {DateTimestr(modified)}
                            type="text"
                          />
                          <Label>created By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {createdBy}
                            type="email"
                          />
                          <Label>modified By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {modifiedBy}
                            type="email"
                          />
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {Edit} color="info" disabled = {!Permission.Update}>Edit</Button>
                  </Form>
                </CardBody>
              </Card>

              <CommonList
                key = {1}
                load={load}
                dataState={dataState}
                getData={getAllLogisticGoodsData}
                Add={AddLogisticGoods}
                addDisabled = {Permission.Create}
                header={'Logistik Barang Gudang'}
              />
              <CommonList
                key = {2}
                load={loadCustomerGoods}
                dataState={customerGoodsDataState}
                getData={getAllLogisticGoodsCustomerData}
                header={'Logistik Barang Customer'}
              />
              <CommonList
                key = {3}
                load={loadMutation}
                dataState={MutationDataState}
                // getData={getAllLogisticGoodsCustomerData}
                columns = {MutationColumn}
                header={'Mutasi Logistik'}
              />
            </Col>
          </Row>
        </div>
      </>
    );
}


export default LogisticItemUpdate;


