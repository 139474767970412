import { Col } from "reactstrap";

function CollStatic ({ value, title, type = "icon icon-success", logo = "now-ui-icons business_money-coins", className = '', md = '3'}) {
    return (
        <Col md={md}>
            <div className="statistics">
                <div className="title info">
                <div className= {type}>
                    <i className= {logo} />
                </div>
                <h3 className={"info " + className}>
                    {value}</h3>
                <h6 className="stats-title">{title}</h6>
                </div>
            </div>
        </Col>
    )
}

export default CollStatic