import React, { useEffect, useState } from "react";

// reactstrap components
import {
  UncontrolledTooltip,
  Row,
  Col,
  Button,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import BillingService from "Main/BillingService";
import { addBillingAddressData, getRefreshToken } from "variables/common";
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { validatePermission } from "Main/Utils";
import { getUserPermission } from "variables/common";
import CommonList from "components/Table/CommonList";

function BillingAddressList(props) {
  const pages = 'address'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const billingService =  new BillingService()
  const refreshToken = getRefreshToken()
  const [loading, setDataLoaded] = useState(true)
  const [dataTable, dataTableOnChange] = useState([])

  const getBillingAddress =  async() => {
    try {
      setDataLoaded(true)
      const len = 'ALL'
      const dat =  await billingService.getAllBillingAddress(len)
      const result = dat.data

      dataTableOnChange(result)
      setDataState(mapping(result))
      setDataLoaded(false)
      return result
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  useEffect(() => {
    validatePermission(props, 2, 1)
    getBillingAddress()
  }, [])

  const deleteBillingAddress = async(id) => {
    swal("Delete", "Billing Data Can't Recover", "info", {
          buttons: {
          cancel: 'Cancel',
          delete: {text: "Delete", value: 'del' }
        }
      }
    ).then(async(value) => {
        switch (value) {
          case 'del':
            try {
                setDataLoaded(true)
                validatePermission(props, 2, 3)
                await billingService.deleteBillingAddress(id)
                await swal('Success', 'Alamat Penagihan Berhasil di Hapus', 'success')
                const state = await getBillingAddress()
                const stateRaw = mapping(state)
                const data = stateRaw.filter((data) => data.id !== id)
                setDataState(data)
                setDataLoaded(false)
            } catch (error) {
              return await CatchError(error, props, refreshToken)
            }
            break;
          default:
            await getBillingAddress()
            window.location.reload()
            break;
        }
      })
  }

  const viewBillingAddress = async(id)=> {
    try {
      const dat = await billingService.getBillingAddress(id)
      const result = dat.data
      addBillingAddressData(result)
      props.history.push('/admin/billing-Address-update')
    } catch (error) {
      return CatchError(error, props, refreshToken)
    }
  }


  const AddAddress = () => {
    props.history.push('/admin/Billing-Address-page')
  }

  const mapping = (DataTable) => DataTable.map((Data) => {
        try {
          return {
          id: Data.id,
          name: Data.name,
          owner: Data.mitra_name,
          type: Data.address_type,
          province: Data.province_name.toLowerCase(),
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                disabled = {!Permission.Read}
                onClick={async() => {
                  await viewBillingAddress(Data.id)
                }}
                className="btn-icon btn-round"
                id= {`tooltip${Data.id}4`}
                color="warning"
                size="sm"
              >
                <i className="fa fa-edit" />
              </Button>{" "}
              <UncontrolledTooltip
                delay={0}
                target= {`tooltip${Data.id}4`}
              >Update</UncontrolledTooltip>
              {/* use this button to remove the data row */}
              <Button
                disabled = {!Permission.Delete}
                onClick={ async () => {
                  await deleteBillingAddress(Data.id)
                }}
                className="btn-icon btn-round"
                id= {`tooltip${Data.id}5`}
                color="danger"
                size="sm"
                >
                <i className="fa fa-times" />
                </Button>{" "}
              <UncontrolledTooltip
                delay={0}
                target={`tooltip${Data.id}5`}
              >Delete</UncontrolledTooltip>
            </div>
          ),
        };
        } catch (error) {
          return []
        }
      })

  const [dataState, setDataState] = React.useState(mapping(dataTable))

  const columns = [{
        Header: "Nama",
        accessor: "name",
      },
      {
        Header: "Mitra",
        accessor: "owner",
      },
      {
        Header: "Tipe",
        accessor: "type",
      },
      {
        Header: "Provinsi",
        accessor: "province",
      },
      {
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        filterable: false,
  }]

  const menus = (
    <Row>
      <Col md={10}>
        <Button disabled= {!Permission.Create} onClick={AddAddress} color="info">Add</Button>
      </Col>
    </Row>
  )

  try {
    return (
      <>
      <PanelHeader size="sm" />
      {/* <PanelHeader
        content={
        <div className="header text-center">
          <h2 className="title">List Alamat Penagihan</h2>
        </div>} /> */}
        <div className="content">
          <CommonList
            key={1}
            header={'Alamat Penagihan'}
            columns={columns}
            dataState={dataState}
            menu={menus}
            load={loading}
            getData={getBillingAddress} />
        </div>
      </>
    )
  } catch (error) {
    return (
      <>
      <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Alamat Penagihan</h2>
              </div>
            }
          />
      <h3>{error.message || error}</h3>
      </>
    )
  }
}

export default BillingAddressList;
