import { Button, Col, Row } from "reactstrap"

const defaultMenu = (Add, menu, addDisabled) => {
  if (!Add) {
    return menu || (<p></p>)
  }
  if (Add instanceof Function) {
    return menu || (<Row>
    <Col md={10}>
        <Button onClick={Add} color="info" disabled={!addDisabled}>Add</Button>
    </Col>
  </Row>)
  }
  return menu || (<p>"Add" Not A Function</p>)
}

export default defaultMenu