import React, { useEffect } from "react";
import { payDate, payType } from "Main/Utils";
import Modal from "react-bootstrap/Modal";
import parse from 'html-react-parser';
import { useReactToPrint } from "react-to-print"
// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  Label,
  Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import BillingService from "Main/BillingService";
import { useState } from "react";
import {getRefreshToken,
  getInvoiceData,
  addInvoiceData,
  PriceFormat } from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { Datestr } from "Main/Utils";
// import { Downloads } from "Main/Utils";
import CustomerService from "Main/CustomerService";
import { useFormInput } from "Main/Utils";
import LoadingScreen from "components/Loader/LoadingScreen";
import { DateTimestr } from "Main/Utils";

function InvoiceView(props) {
  const billingService =  new BillingService()
  const refreshToken = getRefreshToken()
  const [InvoiceData, invoiceOnChange] = useState(getInvoiceData())
  const [Preview, PreviewOnChange] = useState(() => {
    return ( <Label>No Data</Label>)
  })
  // console.log(InvoiceData)
  const customerService = new CustomerService()
  const customerInvoice = InvoiceData.invoice
  const customerData = InvoiceData.customer
  const invoice = customerData.customer
  const id = customerInvoice[0].invoiceId
  const owner = customerInvoice[0].owner
  const created = customerInvoice[0].creation
  const modified = customerInvoice[0].modified
  const start = customerInvoice[0].start
  const end = customerInvoice[0].end
  const due = customerInvoice[0].due
  const createdBy = customerInvoice[0].createdBy
  const modifiedBy = customerInvoice[0].modifiedBy
 // const [payof, payofOnChange] = useState(!!customerInvoice[0].paid)
  const description = useFormInput(customerInvoice[0].description)
  const payment = payDate(customerInvoice[0].paid)
  const paymentType = payType(customerInvoice[0].paidType)
  const [show, setShow] = useState(false);
  const [download, setDownload] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Price = []

  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    getInvoice()
  }, [])

  const PrintDocument = React.forwardRef((props, ref) => {
    return (<div ref={ref} className="marginprint bg-light">
    <div className="preview">
      {Preview}
    </div>
    </div>)
  })


  const getInvoice =  async() => {
    try {
      const dat =  await billingService.getInvoice(id)
      const Preview = await billingService.getPdfTemplate(id)
      const result = dat.data
      addInvoiceData(result)
      invoiceOnChange(result)
      PreviewOnChange(() => {
        return parse(Preview)
      })
    } catch (error) {
    return await CatchError(error, props, refreshToken)
  }}

  const exportInvoicePDF = async(id) => {
    try {
      setDownload(true)
      const rawData = await customerService.exportPDF(id)
      swal('Success', `${invoice} Download`, 'success').then(() =>{
       setDownload(false)
       handleClose()
       return rawData
     })
    } catch (error) {
      setDownload(false)
    }
  }

  const PdfModal = ({open, close}) => {
    return (
      <Modal
        show={open}
        onHide={close}
        backdrop= {true}
        dialogClassName="modal-150w"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton = {false} className="modal-color">
          <h4 className="font-weight-bold">Detail Tagihan</h4>
        </Modal.Header>
        <Modal.Body className="modal-color">
        <LoadingScreen children={<PrintDocument ref={componentRef} />} loading={download}/>
        </Modal.Body>
        <Modal.Footer  className="modal-color">
           {() => {if(download === true) {
            return (
              <Label>
                Loading...
              </Label>
            )
          } else {
            <Label>
              PDF
            </Label>
          }}}
          <Button variant="primary"  className="bg-danger bold" onClick={handleClose} disabled = {download}>
            Close
          </Button>
          <Button variant="primary" className="bg-success bold" onClick={handlePrint} disabled = {download}>Print</Button>
          {/* <Button variant="primary" className="bg-primary" onClick={() => exportInvoicePDF(id)} disabled = {download}>Download</Button> */}
        </Modal.Footer>
      </Modal>
    )
  }

    const CustomerInvoiceList = () => {
      const totalPrc = InvoiceData.total
      return (
        <>
        <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Customer Subscription</CardTitle>
              </CardHeader>
              <CardBody>
                <Table  responsive bordered hover>
                  <thead className="header">
                    <tr>
                      <th colSpan={1} >NetId</th>
                      <th colSpan={2} >Nama Produk</th>
                      <th colSpan={1} className="text-left">Restitusi Subscription</th>
                      <th colSpan={1} className="text-left">Biaya Admin</th>
                      <th colSpan={2} >Tipe</th>
                      <th colSpan={1} className="text-center">Uplink</th>
                      <th colSpan={1} className="text-center">Downlink</th>
                      <th colSpan={1} className="text-left">Harga</th>
                      <th colSpan={1} className="text-left">PPN</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerInvoice.map((Invoice) => {
                      Price.push(Invoice.price)
                      return (
                      <tr key={Invoice.networkId}>
                      <td colSpan={1}>{Invoice.networkId}</td>
                      <td colSpan={2}>{Invoice.itemName}</td>
                      <td colSpan={1} className="text-left">{PriceFormat(Invoice.discountSubs, Invoice.currency)}</td>
                      <td colSpan={1} className="text-left">{PriceFormat(Invoice.administrationFee, Invoice.currency)}</td>
                      <td colSpan={2}>{Invoice.category}</td>
                      <td colSpan={1} className="text-center">{`${Invoice.uplink} ${customerData.measurement}`}</td>
                      <td colSpan={1} className="text-center">{`${Invoice.downlink} ${customerData.measurement}`}</td>
                      <td colSpan={1} className="text-left">{PriceFormat(Invoice.price, Invoice.currency)}</td>
                      <td colSpan={1} className="text-left">{PriceFormat(Invoice.tax, Invoice.currency)}</td>
                    </tr>)
                    })}
                    <tr >
                      <td colSpan={8}></td>
                      <td colSpan={1} className="td-total">Total</td>
                      <td colSpan={3} className="td-price">{PriceFormat(totalPrc, customerInvoice[0].currency)}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </>
      )
    }
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content" onLoadStart ={getInvoice}>
          <Row>
            <Col md="12">
              {CustomerInvoiceList()}
              <Row md = "6">
                <Col md="2">
                  <Button onClick = {handleShow} className="bg-info bold">Exports PDF</Button>
                </Col>
              </Row>
              <Card>
                <CardHeader>
                  <h5 className="title">Detail Invoice</h5>
                </CardHeader>
                <CardBody>
                <PdfModal open={show} close={handleClose}/>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="12">
                          <label>Customer</label>
                          <Input
                            value = {invoice}
                            placeholder="Name"
                            type="text"
                          />
                        <label>Mitra</label>
                          <Input
                            placeholder="Mitra"
                            value = {owner}
                            type="email"
                          />
                          <label>Created</label>
                          <Input
                            placeholder="Created"
                            value = {Datestr(created)}
                            type="text"
                          />
                          <label>modified</label>
                          <Input
                            placeholder="Modified"
                            value = {Datestr(modified)}
                            type="datetime"
                          />
                          <label>Created By</label>
                          <Input
                            placeholder="Created By"
                            value = {createdBy}
                            type="email"
                          />
                          <label>modified By</label>
                          <Input
                            placeholder="Modified By"
                            value = {modifiedBy}
                            type="email"
                          />
                          <label>Tanggal Pembayaran</label>
                          <Input
                            placeholder="pembayaran"
                            value = {payment}
                            type="text"
                          />
                          <label>Pembayaran Melalui</label>
                          <Input
                            placeholder="Pembayaran Melalui"
                            value = {paymentType}
                            type="text"
                          />
                          <label>Tanggal Mulai</label>
                          <Input
                            placeholder="Tanggal Mulai"
                            value = {DateTimestr(start)}
                            type="text"
                          />
                          <label>Tanggal Akhir</label>
                          <Input
                            placeholder="Tanggal Akhir"
                            value = {DateTimestr(end)}
                            type="text"
                          />
                          <label>Jatuh Tempo</label>
                          <Input
                            placeholder="Jatuh Tempo"
                            value = {DateTimestr(due)}
                            type="text"
                          />
                          <label>Deskripsi</label>
                          <Input
                            placeholder="Deskripsi Invoice"
                            {...description}
                            type="textarea"
                          />
                        </Col>
                      </Row>
                  </Form>
                </CardBody>
              </Card>
              <Row>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default InvoiceView;
