const { getData, setData } = require("variables/common");

const identifier = ['MitraFinanceData', 'MitraFinanceList']
const [data, list] = identifier

export function addMitraFinanceData(payload) {
  setData(data, payload)
}

export function getMitraFinanceData() {
 return getData(data)
}

export function addMitraFinanceList(payload) {
  setData(list, payload)
}

export function getMitraFinanceList() {
 return getData(list)
}

export function getExportDataType() {
    return [{
        value: 'csv',
        label: 'CSV UTF-8',
        extention: '.csv'
    },{
        value: 'excel',
        label: 'Microsoft Excel (xlsx)',
        extention: '.xlsx'
    }]
}