export const columns=[
      {
        Header: "Nama",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Organisasi",
        accessor: "organization",
      },
      {
        Header: "Update  Hapus",
        accessor: "actions",
        sortable: false,
        filterable: false,
      },
]
