import LoginService from '../LoginService'
import APIconsume from '../APIconsume'
import { getAccessToken } from "variables/common"

class MessageLogService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'messageSender/log'
    }

    async getData (id) {
        const url = `${this._url}/detail/${id}`
        // console.log(this._accessToken)
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllData (mitra = '', period = '', type = '', status = '', len = 'ALL',  page = '') {
        let url = `${this._url}/view?mitra=${mitra}&period=${period}&type=${type}&status=${status}&line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getMessageLogPeriod (mitra) {
        // memakai Mitra ID
        const url = `res/message-log-period-list?mitra=${mitra}`
        // console.log(this._accessToken)
        const result = await this._consume.getData(url)
        return result.data
    }

    async getMessageLogStatus () {
        const url = `res/message-log-status-list`
        // console.log(this._accessToken)
        const result = await this._consume.getData(url)
        return result.data
    }

    async getMessageLogType () {
        const url = `res/message-log-type-list`
        // console.log(this._accessToken)
        const result = await this._consume.getData(url)
        return result.data
    }
}

export default MessageLogService