import { renderMainDeviceInventoryTable } from "variables/common"

const { Button } = require("reactstrap")

export const OutdoorDeviceColumns = [
         {
           Header: "ID",
           accessor: "id",
           maxWidth: 100
         },
         {
           Header: "Name",
           accessor: "name",
           maxWidth: 300
         },
         {
           Header: "Latitude",
           accessor: "latitude",
           maxWidth: 150
         },
         {
           Header: "Longitude",
           accessor: "longitude",
           maxWidth: 150
         },
         {
           Header: "Urban",
           accessor: "urban",
           maxWidth: 200
         },
         {
           Header: "Actions",
           accessor: "actions",
           sortable: false,
           filterable: false,
           maxWidth: 300
         },
    ]

export function renderOutdoorDeviceTable (dataset, view, deleteData /*Function*/, viewPermission /*Function*/, deletePermission) {
      const finalData = dataset || []
      return finalData.map((data) => {
        return {
          id: data.id,
          name: data.name,
          type: data.type,
          urban: data.urban,
          latitude: data.latitude,
          longitude: data.longitude,
          actions: (
            // we've added some custom button actions
            <div className="actions-right" key={data.id}>
            <Button
              onClick={ async () => {
                await view(data.id)
              }}
              disabled = {!viewPermission}
              className="btn-icon btn-round"
              color="warning"
              id={`tooltip${data.id}3`}
              size="sm"
            >
            <i className="fa fa-edit" />
            </Button>{" "}
            <Button
              disabled = {!deletePermission}
              onClick={ async () => {
                  deleteData(data.id)
              }}
              className="btn-icon btn-round"
              id={`tooltip${data.id}4`}
              color="danger"
              size="sm"
            >
              <i className="fa fa-times" />
            </Button>{" "}
            </div>
          ),
        }
     })
   }

export const renderMainOutdoorDeviceTable = (dataset, view /*Function*/, deleteData /*Function*/, viewPermission, deletePermission) => {
    return renderMainDeviceInventoryTable(dataset, renderOutdoorDeviceTable, view, deleteData, viewPermission, deletePermission)
}