const { getData, setData } = require("variables/common");

const identifier = ['subscriptionAddOnData', 'subscriptionAddOnList']
const [data, list] = identifier

export function addSubscriptionAddOnData(payload) {
  setData(data, payload)
}

export function getSubscriptionAddOnData() {
 return getData(data)
}

export function addSubscriptionAddOnList(payload) {
  setData(list, payload)
}

export function getSubscriptionAddOnList() {
 return getData(list)
}

export function payload(mitra, subscriptionId, addOnsId) {
    return {
        mitra, subscriptionId, addOnsId
    }
}

export function payloadUpdate(mitra, addOnsId) {
    return {
        mitra, addOnsId
    }
}

export function payloadTrash(id, deleted) {
    return {
        id,
        deleted
    }
}