import LoadingScreen from "components/Loader/LoadingScreen"
import Modal from "react-bootstrap/Modal";
import { Button } from "reactstrap"

 export const CustomerModal = ({open, close, child, loading, handleClose}) => {
    return (
      <Modal
        show={open}
        onHide={close}
        backdrop= {true}
        dialogClassName="modal-150w"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header
          closeButton = {false}
          className="modal-color">
          <h5 className="font-weight-bold">Informasi Tagihan</h5>
        </Modal.Header>
        <Modal.Body>
        <LoadingScreen
          children={child || (<p>No-Child</p>)}
          loading={loading}/>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="bg-danger bold"
            onClick={handleClose}
            disabled = {loading}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }