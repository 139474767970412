import PanelHeader from "components/PanelHeader/PanelHeader"
import { Row, Col, Card,
    CardHeader, CardTitle,
    CardBody } from "reactstrap"

function NotFoundPage () {
    return (
    <>
    <PanelHeader size="sm" />
    <Row>
        <Col md = '12'>
        <Card>
        <CardHeader>
            <CardTitle tag="h4"></CardTitle>
        </CardHeader>
        <CardBody>
            <div className="content">
            <div className="typography-line">
            <h1 className="title">404 Not Found</h1>
            <h6>Oops Tidak terdapat konten di sini</h6>
            </div>
            </div>
        </CardBody>
        </Card>
        </Col>
    </Row>
    </>
    )
}

export default NotFoundPage