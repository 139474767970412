const { getData, setData } = require("variables/common");

const identifier = ['subscriptionHistoryAddOnData', 'subscriptionHistoryAddOnList']
const [data, list] = identifier

export function addSubscriptionHistoryAddOnData(payload) {
  setData(data, payload)
}

export function getSubscriptionHistoryAddOnData() {
 return getData(data)
}

export function addSubscriptionHistoryAddOnList(payload) {
  setData(list, payload)
}

export function getSubscriptionHistoryAddOnList() {
 return getData(list)
}

export function payload(mitra, subscriptionAddOnId, subscriptionHistoryId) {
    return {
        mitra,
        subscriptionAddOnId,
        subscriptionHistoryId
    }
}

export function payloadGenerate(mitra, subscriptionHistoryId) {
    return {
        mitra,
        subscriptionHistoryId
    }
}
