import React, { useEffect } from "react";
// import CurrencyInput from "react-currency-input-field";
// reactstrap components
import LoadingScreen from "components/Loader/LoadingScreen";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import { useState } from "react";
import {getRefreshToken, getOwner,
        GetItemCategory,
        GetItemMeasurement, PriceFormat, getRouterSelect,
        getUserPermission
} from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import RouterService from "Main/RouterService";
import BasicInput from "components/Input/BasicInput";
import SelectBasic from "components/Input/SelectBasic";
import { verifySuperAdmin } from "Main/Utils";
import SelectWithMessage from "components/Input/SelectWithMessage";
import ItemAddOnService from "Main/Add-On/ItemAddOnService";
import { getItemAddOnData } from "variables/Add-On/ItemAddOn";
import { DateTimestr } from "Main/Utils";
import { payloadItemAddOnUpdate } from "variables/Add-On/ItemAddOn";
import ValidationMinMaxInput from "components/Input/ValidationMinMaxInput";

function ItemAddOnUpdate(props) {
  const pages = 'billing'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const itemAddOnService = new ItemAddOnService()
  const routerService = new RouterService()
  const refreshToken = getRefreshToken()
  var selectMeasurementOptions = GetItemMeasurement()
  var selectCategoryOptions = GetItemCategory()
  const [routerSelectOption, routerSelectOptionOnChange] = useState(getRouterSelect())
  var ownerData = getOwner()
  const ItemData = getItemAddOnData()
  const owner = ownerData.find(data => data.value === ItemData.owner) || ''
  const Id = ItemData.id
  const name = ItemData.name
  const creation = ItemData.creation
  const modified = ItemData.modified
  const [Measurement, setMeasurement] = useState(selectMeasurementOptions.find(val => val.value === ItemData.measurement))
  const [priceValue, priceValueOnChange] = useState(ItemData.price)
  const [values, setValue] = useState(ItemData.value)
  const [discountValue, setDiscount] = useState(ItemData.discount || 0)
  const [router, routerOnChange] = useState(routerSelectOption.find(router => router.value === ItemData.routerid) || {})
  const [category , categoryOnChange] = useState(selectCategoryOptions.find(val => val.value === ItemData.category))
  const [loading, loadingOnChange ] = useState(false)
  const { mitraSuper } = verifySuperAdmin()

  useEffect(() => {
    getRouter(owner.mitraid)
  }, [])

  const EditItem = async(e) => {
    swal("Update", "Data Can't be Undo", "info", {
              buttons: {
              update: {text: "Update", value: 'up' },
              cancel: 'Cancel'
            }
          }
      ).then(async(value) => {
        switch (value) {
          case 'up':
            try {
              e.preventDefault()
              loadingOnChange(true)
              const payload = payloadItemAddOnUpdate(name, category.value, priceValue,  discountValue, Measurement.value, router.value, values)
              const result = await itemAddOnService.putData(Id, payload)
              loadingOnChange(false)
              swal(result.status, result.message, 'success').then(() => {
                props.history.push('/admin/item-add-on-list')
              })
            } catch (error) {
              loadingOnChange(false)
              return await CatchError(error, props, refreshToken)
            }
            break;

          default:
            // swal('Cancel', 'Update Item Cancel', 'info')
            break;
        }
      })
    }

   const getRouter = async(mitraid) => {
    // sessionStorage.removeItem('urban')
    const dat = await routerService.getRouterSelect(mitraid)
    const result = dat.data
    routerSelectOptionOnChange(result)
    const selected = result.find(router => router.value === ItemData.routerid) || {}
    routerOnChange(selected)
    return result
  }


  const children = (<div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">{name}</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                     <Row>
                      <Col className="pr-1" md="10">
                        <SelectWithMessage
                            value = {router}
                            onChange = {routerOnChange}
                            condition = {router.status}
                            errMesssage = {"Router Offline"}
                            message = {"Router Online"}
                            label = {"Lokasi Router"}
                            option = {routerSelectOption}
                            isDisabled = {!mitraSuper}
                        />
                        <FormGroup>
                        <BasicInput
                            label = 'Nama'
                            value = {name}
                        />
                        <SelectBasic
                          label = {"Pengukuran"}
                          value = {Measurement}
                          onChange = {(value) => setMeasurement(value)}
                          isDisabled = {!mitraSuper}
                          option = {selectMeasurementOptions}
                        />
                        <label>Measurement Value</label>
                          <Input
                            placeholder="Nilai Pengukuran"
                            value = {values}
                            onChange = {(e) => {
                              if (e.target.value >= 0) {
                                setValue(e.target.value)
                              }
                            }}
                            type="number"
                          />
                        <SelectBasic
                            label = {"Kategori"}
                            value = {category}
                            option = {selectCategoryOptions}
                            onChange={(value) => categoryOnChange(value)}
                        />
                        <label>Price</label>
                          <Input
                            placeholder="Harga"
                            value = {priceValue}
                            onChange = {(e) => {
                              if (e.target.value >= 0) {
                                priceValueOnChange(e.target.value)
                              }
                            }}
                            type="number"
                          />
                        <ValidationMinMaxInput
                          label = {"Diskon"}
                          value = {discountValue}
                          onChange = {setDiscount}
                          min = {0}
                          max = {100}
                          type = {"number"}
                        />
                        <BasicInput
                            label = 'Harga Total'
                            value = {PriceFormat(Number(priceValue - ((discountValue/100)*priceValue)), 'IDR')}
                          />
                        <BasicInput
                            label = 'Creatiom'
                            value = {DateTimestr(creation)}
                          />
                        <BasicInput
                            label = 'Modified'
                            value = {DateTimestr(modified)}
                          />
                        </FormGroup>
                      </Col>
                      </Row>
                  </Form>
                </CardBody>
              </Card>
            <Button onClick = {(e) => {EditItem(e)}}
            color="info"
            disabled = {!Permission.Update}>Update</Button>
            </Col>
          </Row>
        </div>
      )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen children={children}
        loading = {loading}
        text={"update Data"}/>
      </>
    );
}


export default ItemAddOnUpdate;
