const {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    FormGroup,
    Input,
    Button,
    Table,
    UncontrolledTooltip
} = require("reactstrap")

function LogisticType ({StatusdataLoad,
    statusName,
    statusNameOnChange,
    submit,
    Data = [],
    deleteData,
    FetchLogisticType}) {
    try {
      if (!StatusdataLoad) {
        return (
      <>
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Logistic Type</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Input
                      placeholder="Add New"
                      type="text"
                      value={statusName}
                      onChange={(e) => {statusNameOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={ async () => { await submit()}}
                    >Submit</Button>
                  </FormGroup>
                <Table responsive bordered hover>
                  <thead className="header">
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((data, i) => {
                      return (
                        <>
                      <tr key={data.id}>
                      <td>{data.name}</td>
                      <td className="text-right">
                        <Button
                          className="btn-icon"
                          color="danger"
                          id={`tooltip${Data.id}13${i}`}
                          size="sm"
                          type="button"
                          onClick={ async () => {await deleteData(data.id)}}
                          >
                          <i className="now-ui-icons ui-1_simple-remove" />
                          </Button>
                          <UncontrolledTooltip
                              delay={0}
                              target={`tooltip${Data.id}13${i}`}
                        >Delete</UncontrolledTooltip>
                      </td>
                      </tr>
                    </>
                      )
                    }
                    )}

                  </tbody>
                </Table>
              </CardBody>
            </Card>
      </>
    )
      } else {
        FetchLogisticType()
        return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Logistic Type</CardTitle>
              </CardHeader>
              <CardBody>
              <p className="typhograpy-line">Loading...</p>
              </CardBody>
              </Card>
      )
      }
    } catch (error) {
      return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Status</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Input
                      placeholder="Add New"
                      type="text"
                      value={statusName}
                      onChange={(e) => {statusNameOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={ async () => { await submit()}}
                    >Submit</Button>
                  </FormGroup>
              <p className="typhograpy-line">No Data</p>
              </CardBody>
              </Card>
      )
    }
}

export default LogisticType