import { useState } from "react";

const { verifyLength } = require("Main/Utils");
const { Label, Input } = require("reactstrap");

function ValidationInput({
    label = "label",
    value,
    disabled,
    onChange,
    passedOnChange,
    passed,
    min,
    max,
    type = "text"
}) {
    const [minLengthState, setminLengthState] = useState('')
    return (
        <>
        <Label>{label}</Label>
        <Input
            name= {label}
            placeholder={label}
            type={type}
            value={value}
            disabled={disabled}
            onChange={(e) => {
                if (!verifyLength(e.target.value, Number(max) + 1)) {
                    onChange(e.target.value);
                }
                if (!verifyLength(e.target.value, min)) {
                    setminLengthState("has-danger");
                    passedOnChange(true);
                } else {
                    setminLengthState("has-success");
                    passedOnChange(false);
                }
                // setminLength(e.target.value);
            } } />
        {minLengthState === "has-danger" ? (
            <label className="error">
            Please enter at least {min} characters.
            </label>
        ) : null}
        </>
    )
}

export default ValidationInput