import { Button, Pagination,
    PaginationLink, PaginationItem } from "reactstrap";
import { useEffect, useState } from "react";
function PaginatedV1 (props) {
    const {
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        limitPaginate = 10,
        gotoPage,
        pageLen,
        pageStateSet,
        limitPagination,
        limitPaginationOnChange,
        validatePreviousNext,
        canPreviousSection,
        canNextSection
    } = props

    const [loading, loadingOnChange] = useState(true)
    // console.log(
    //     canPreviousPage,
    //     canNextPage,
    //     limitPaginate,
    //     pageLen
    // )

    useEffect(() => {
        loadingOnChange(true)
        validatePreviousNext(limitPagination)
        loadingOnChange(false)
    }, [])

   function nextSection () {
        loadingOnChange(true)
        const limit = Number(limitPagination) + Number(limitPaginate)
        limitPaginationOnChange(limit)
        validatePreviousNext(limit)
        paginatePage()
        loadingOnChange(false)
    }

   function previousSection () {
        loadingOnChange(true)
        const limit = Number(limitPagination) - Number(limitPaginate)
        limitPaginationOnChange(limit)
        validatePreviousNext(limit)
        paginatePage()
        loadingOnChange(false)
    }

    function paginatePage () {
        const dat = []
        for (let idx = 0; idx < pageLen; idx++) {
            const key = idx
            if (idx >= (Number(limitPagination) - Number(limitPaginate)) && idx < Number(limitPagination)) {
                const paginate = (
                <>
                    <PaginationItem>
                        <PaginationLink
                            onClick={() => {
                                validatePreviousNext(limitPagination)
                                gotoPage(idx)
                                !pageStateSet ? console.log('not initialize')
                                : pageStateSet(idx)
                            }}>
                            {key + 1}
                        </PaginationLink>
                    </PaginationItem>
                </>
                )
                dat.push(paginate)
            } else if(idx >= Number(limitPagination)){
                break
            }
        }
        return dat
    }
    return (
        <div className="pagination-bottom">
            <Pagination size="lg" aria-label="Page navigation example">
                <Button className="btn-sm" disabled={!canPreviousSection} onClick={previousSection} >Prev</Button>
                <PaginationItem>
                    <PaginationLink  disabled={!canPreviousPage} previous onClick={previousPage} />
                </PaginationItem>
            {!loading ? paginatePage()
            : '...Loading'}
            <PaginationItem>
                    <PaginationLink disabled={!canNextPage} next onClick={nextPage} />
            </PaginationItem>
            <Button className="btn-sm" disabled={!canNextSection} onClick={nextSection} >Next</Button>
            </Pagination>
        </div>
    )
}



export default PaginatedV1