import LoginService from './../LoginService'
import APIconsume from './../APIconsume'
import { getAccessToken } from "./../../variables/common"

class MitraSubmissionLogisticService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'submissionLogistic'
    }

    async addData (payload) {
        const type = 'multipart/form-data'
        const result = await this._consume.postData(payload, this._url, type)
        return result.data
    }

    async setApproval (payload) {
        const url = this._url + '/approval'
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async sendToTrash (payload) {
        const url = this._url + '/trash'
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async downloadPdf (id, filename) {
        const url = this._url + '/download/pdf/' + id
        const result = await this._consume.GlobalDownload(url, filename)
        return result
    }

    async getData (id) {
        const url = `${this._url}/${id}`
        // console.log(this._accessToken)
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllData (len = 'ALL', trash = '', page = '') {
        let url = `${this._url}/view?line=${len}&trash=${trash}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async putData (id, payload) {
        const url = `${this._url}/${id}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async deleteData (id) {
        const url = `${this._url}/${id}`
        const result = await this._consume.deleteData(url)
        return result.data
    }
}

export default MitraSubmissionLogisticService