function WebIframe({name = 'name', src}) {
    return (
            <div className="iframe-container">
                <iframe
                    title={name || "title"}
                    id="web_canvas_iframe"
                    src={src}>
                </iframe>
            </div>
        )
}

export default WebIframe;