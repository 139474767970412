export const ColumnsUser = [
    {
        Header: "Nama",
        accessor: "name",
    },
    {
        Header: "Mitra",
        accessor: "owner",
    },
    {
        Header: "Status",
        accessor: "status",
    },
    {
        Header: "Syncronize",
        accessor: "syncUsr",
        sortable: false,
        filterable: false
    },
    {
        Header: "View",
        accessor: "actionsUser",
        sortable: false,
        filterable: false,
    }
]

export const ColumnsAdmin = [
    {
        Header: "Nama",
        accessor: "name",
        width: 50,
        maxWidth: 150
    },
    {
        Header: "Mitra",
        accessor: "owner",
        width: 50,
        maxWidth: 150
    },
    {
        Header: "URL API",
        accessor: "url",
        width: 150,
        maxWidth: 450
    },
    {
        Header: "Status",
        accessor: "status",
        maxWidth: 150
    },
    {
        Header: "Syncronize",
        accessor: "sync",
        maxWidth: 150
    },
    {
        Header: "Update  Hapus",
        accessor: "actions",
        sortable: false,
        filterable: false,
        maxWidth: 100
    },
]