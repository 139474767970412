import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import {getRefreshToken, getLogisticSupplierData,
  LogisticSupplierPut, getLogisticGoodsList,
   addLogisticWarehouseData, addLogisticItemData,
  addLogisticGoodsData, addLogisticGoodsList,
  addLogisticSupplierList,
  PriceFormat,
  getUserPermission} from "./../../../variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import LogisticSupplierService from "Main/Logistic/LogisticSupplierService";
import { verifyLength } from "Main/Utils";
import { DateTimestr } from "Main/Utils";
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService";
import { nullCompensation } from "Main/Utils";
import { customTextFromBooleanReverse } from "Main/Utils";
import { FetchLogisticBySupplier } from "./../LogisticItem/LogisticGoods/FetchLogistic";
import CommonList from "../../../components/Table/CommonList"
import Checklist from "components/Button/Checklist";
import { calculateTotal } from "Main/Utils";
import { customerOperation } from "Main/Utils";
import StatusButton from "components/Button/StatusButton";

function LogisticSupplierUpdate(props) {
  const pages = 'Logistic'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const Service = new LogisticSupplierService()
  const logisticService = new LogisticGoodsService()
  const refreshToken = getRefreshToken();
  const Data = getLogisticSupplierData();
  const id = Data.id
  const name = useFormInput(Data.name)
  const address = useFormInput(Data.address)
  const [idManual, idManualOnChange] = useState(Data.id)
  const manualSupplierId = false
  const [minLengthState, setminLengthState] = React.useState("");
  const [minLengthPhoneState, setminLengthPhoneState] = React.useState("");
  const [email, emailOnChnage] = useState(Data.email)
  const [MobileNo, mobileNoOnChange] = useState(Data.phone)
  const [npwp, npwpOnChange] = useState(Data.npwp)
  const [mobileNoPassed, phonePassedOnChnage] = useState(true)
  const [npwpPassed, npwpPassedOnChange] = useState(true)
  const created = Data.creation
  const modified = Data.modified
  const createdBy = Data.createdBy
  const modifiedBy = Data.modifiedBy
  const owner = Data.owner
  const [dataTable, setDataTable] = useState(getLogisticGoodsList())
  const [load, setDataLoad] = useState(false)
  const [paid, paidOnChange] = useState(false)
  const [total, totalOnChange] = useState(calculateTotal(dataTable))

const getAllLogisticGoodsData =  async(isPaid = false) => {
      try {
        setDataLoad(true)
        const len = 'ALL'
        console.log(paid, isPaid)
        const dat =  await logisticService.getAllBySupplier(id, isPaid, len)
        const result = dat.data
        console.log(result)
        if (!result || result.length < 1) {
          // await getAllLogisticGoodsData()
          setDataLoad(true)
        } else{
          setDataTable(result)
          addLogisticGoodsList(result)
          totalOnChange(calculateTotal(result))
          setDataLoad(false)
        }
        return result
      } catch (error) {
        return CatchError(error, props, refreshToken)
      }
    }
  const getAll =  async() => {
        const len = 'ALL'
        const result =  await Service.getAll(len)
        addLogisticSupplierList(result.data)
  }

  const DeleteLogisticGoodsData = (id) => {
      swal("Hapus", "Data Tidak Dapat di Recover", "info", {
        buttons: {
          cancel: 'Batal',
          delete: {text: "Hapus", value: 'del' }
        }
      }
      ).then(async (value) => {
        switch (value) {
          case 'del':
            try {
              const response = await logisticService.delete(id)
              await FetchLogisticBySupplier(id)
              swal(response.status, response.message, 'success').then(() => {
                getAllLogisticGoodsData()
                setDataLoad(true)
                const table = dataState.filter((dat) => dat.id !== id)
                setDataState(table)
              })
              return true
            } catch (error) {
              return await CatchError(error, props, refreshToken)
            }

          default:
            getAllLogisticGoodsData()
            // window.location.reload()
            setDataLoad(true)
            return false
        }
      })
    }
const ViewLogisticGoodsData = async (id, transfer) => {
    try {
      addLogisticWarehouseData([])
      addLogisticItemData([])
      const result = await logisticService.get(id)
      addLogisticGoodsData(result.data)
      if (!transfer) {
        props.history.push('/admin/logistic-goods-update')
        return
      }
      props.history.push('/admin/logistic-warehouse-to-customer')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const submit = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
    const payload = LogisticSupplierPut(name.value, npwp, email, MobileNo, address.value)
      const result = await Service.put(id, payload)
      await getAll()
      console.log(result)
      swal("Success", "Data Sukses di Tambahkan", "success").then(() => {
       props.history.push('/admin/logistic-supplier-list')
      })
    } catch (error) {
      return CatchError(error, props, refreshToken)
    }
  }

  const AddLogisticGoods = () => {
    addLogisticWarehouseData([])
    addLogisticItemData([])
    props.history.push('/admin/logistic-goods-add')
  }

  const mapping = (TableData) => {
    return TableData.map((Data) => {
      try {
        return {
              id: Data.id,
              name: Data.name,
              qty: Data.qty,
              sn: nullCompensation(Data.sn),
              paid: (
                <StatusButton 
                disabled = {Permission.Update}
                status={Data.paid}
                enableText = {'Yes'}
                disableText = {'No'}
                disableColor = {'warning'}
                statusOnChange={async(e) =>{
                    await setPaidStatus(Data.id, Data.paid)
                  }}/>
              ),
              damaged: customTextFromBooleanReverse(Data.damaged, 'Bagus', 'Rusak'),
              price: PriceFormat((Number(Data.price) * Number(Data.qty)), 'idr'),
              location: Data.warehouse,
              organization: Data.owner,
              actions: (
                // we've added some custom button actions
                <div className="actions-right" key={Data.id}>
                  {/* use this button to add a edit kind of action */}
                  <Button
                  disabled = {!Permission.Read}
                    onClick={ async () => {
                      // let obj = dataState.find((o) => o.id === Data.id);
                      await ViewLogisticGoodsData(Data.id, true)
                    }}
                    className="btn-icon btn-round"
                    color="primary"
                    id={`tooltip${Data.id}10`}
                    size="sm"
                  >TF</Button>{" "}
                  <Button
                  disabled = {!Permission.Read}
                    onClick={ async () => {
                      // let obj = dataState.find((o) => o.id === Data.id);
                      await ViewLogisticGoodsData(Data.id)
                    }}
                    className="btn-icon btn-round"
                    color="warning"
                    id={`tooltip${Data.id}10`}
                    size="sm"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                  disabled = {!Permission.Delete}
                    onClick={ async () => {
                      await DeleteLogisticGoodsData(Data.id)
                    }}
                    className="btn-icon btn-round"
                    id={`tooltip${Data.id}11`}
                    color="danger"
                    size="sm"
                  >
                    <i className="fa fa-times" />
                  </Button>{" "}
                </div>
          ),
        };
      } catch (error) {
        return []
      }
  })}
  const [dataState, setDataState] = React.useState(mapping(dataTable));

  const setPaidStatus = async(id, status) => {
    try {
      const FinalStatus = !status
      var operation = customerOperation(FinalStatus, 'Paid', 'Unpaid')
      const dat = await logisticService.changePaidStatus(id, FinalStatus)
      paidOnChange(false)
      swal(operation, dat.message, dat.status)
      const result = dat.data
      const table = await getAllLogisticGoodsData(paid)
      setDataState(mapping(table))
      return result
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const customButton = (
      <Row>
        <Col md={3}>
            <Checklist
              text={'Paid Only'}
              value={paid}
              checked={paid}
              onChange={async (e) => {
                paidOnChange(e.target.checked)
                const localState = await getAllLogisticGoodsData(e.target.checked)
                setDataState(mapping(localState))
              }}
            />
        </Col>
        <Col md={2}>
            <Button onClick={AddLogisticGoods} color="info">Add</Button>
        </Col>
    </Row>
  )

  return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Detail Supplier</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <div>
                        <Label>Manual Supplier ID</Label>
                          <Input
                            placeholder="NetworkId"
                            value = {idManual}
                            onChange = {(e) => {
                              if (!verifyLength(e.target.value, 13)) {
                                 idManualOnChange(e.target.value)
                              }}
                            }
                            type="text"
                            disabled = {!manualSupplierId}
                          />
                          {/* {!subsIdAvailable ? (
                          <label className="error">
                            Network Id Telah dipakai, coba ID lain
                          </label>
                          ) : null} */}
                          </div>
                        <FormGroup>
                          <Label>Nama *</Label>
                          <Input
                            placeholder="Name"
                            {...name}
                            type="text"
                          />
                        <Label>Email</Label>
                          <Input
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder="Email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => {
                              emailOnChnage(e.target.value)
                            }}
                          />
                       <div>
                       <Label>NPWP</Label>
                        <Input
                          name="min_length_ktp"
                          type="number"
                          placeholder="KTP Number"
                          max={16}
                          value = {npwp}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 13)) {
                              npwpOnChange(e.target.value)
                              if (!verifyLength(e.target.value, 12)) {
                                setminLengthState("has-danger");
                                npwpPassedOnChange(true)
                              } else {
                                setminLengthState("has-success");
                                npwpPassedOnChange(false)
                              }
                              }
                          }}
                        />
                        {minLengthState === "has-danger" ? (
                          <label className="error">
                            Field Wajib di isi 12 Karakter.
                          </label>
                        ) : null}
                       </div>
                       <div>
                        <Label>Nomor Telepon</Label>
                        <Input
                          name="min_length_phone"
                          type="number"
                          placeholder="Nomor Telepon"
                          max={12}
                          value={MobileNo}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 13)) {
                              mobileNoOnChange(e.target.value)
                            if (!verifyLength(e.target.value, 12)) {
                              setminLengthPhoneState("has-danger");
                              phonePassedOnChnage(true)
                            } else {
                              setminLengthPhoneState("has-success");
                              phonePassedOnChnage(false)
                            }
                            }
                          }}
                        />
                        {minLengthPhoneState === "has-danger" ? (
                          <Label className="error">
                            Field Wajib di isi 12 Karakter.
                          </Label>
                        ) : null}
                       </div>
                          <Label>Alamat</Label>
                          <Input
                          className="react-select"
                          type="textarea"
                          {...address}
                          />
                          <Label>Mitra</Label>
                          <Input
                            placeholder="Mitra"
                            value = {owner}
                            type="email"
                          />
                          <Label>Created</Label>
                          <Input
                            placeholder="Created"
                            value = {DateTimestr(created)}
                            type="text"
                          />
                          <Label>modified</Label>
                          <Input
                            placeholder="Modified"
                            value = {DateTimestr(modified)}
                            type="text"
                          />
                          <Label>created By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {createdBy}
                            type="email"
                          />
                          <Label>modified By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {modifiedBy}
                            type="email"
                          />
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {submit}
                  disabled = {!Permission.Update && !npwpPassed & mobileNoPassed}
                  color="info">Edit</Button>
                  </Form>
                </CardBody>
              </Card>

              <CommonList
                key = {1}
                header={`Item Dibeli Dari ${name.value.toUpperCase()} (${!paid ? 'Belum-Bayar': 'Lunas'})`}
                total = {total}
                menu = {customButton}
                load={load}
                dataState={dataState}
                getData={getAllLogisticGoodsData}
                Add={AddLogisticGoods}
                addDisabled = {Permission.Create}
              />
            </Col>
          </Row>
        </div>
      </>
    );
}


export default LogisticSupplierUpdate;


