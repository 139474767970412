import LogisticGoodsService from "Main/Logistic/LogisticGoodsService"
import swal from "sweetalert"
import { addLogisticMutationList } from "variables/common"
import { addLogisticGoodsCustomerList } from "variables/common"
import { addLogisticGoodsList } from "variables/common"

const Service = new LogisticGoodsService()

export const FetchLogistic = async(itemid) => {
        const len = 'ALL'
        const result =  await Service.getAll({itemid, len})
        const resultCustomer =  await Service.getAllLogisticCustomer({itemid, len})
        const resultMutation = await Service.getLogisticMutation(itemid, len)
        addLogisticGoodsList(result.data)
        addLogisticGoodsCustomerList(resultCustomer.data)
        addLogisticMutationList(resultMutation.data)
  }

export const FetchLogisticByWarehouse = async(warehouseid) => {
        const len = 'ALL'
        const result =  await Service.getAll({warehouseid, len})
        addLogisticGoodsList(result.data)
  }

export const FetchLogisticBySupplier = async(supplierId, paid = false) => {
        const len = 'ALL'
        const result =  await Service.getAllBySupplier(supplierId, paid, len)
        addLogisticGoodsList(result.data)
  }

export const GlobalPropsPush = async (props, itemId, warehouseId, supplierId, result) => {
    if (!itemId === false) {
          await FetchLogistic(itemId)
       }
       if (!warehouseId === false) {
          await FetchLogisticByWarehouse(warehouseId)
       }
       if (!supplierId === false) {
          await FetchLogisticBySupplier(supplierId)
       }
      swal("Success", result.message, "success").then(() => {
       if (!itemId === false) {
          props.history.push('/admin/logistic-item-update')
       }
       if (!warehouseId === false) {
          props.history.push('/admin/logistic-warehouse-update')
       }
       if (!supplierId === false) {
          props.history.push('/admin/logistic-supplier-update')
       }
      })
}