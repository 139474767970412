import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {getRefreshToken, addUserList,
  LogisticWarehouse, getLogisticWarehouseData,
  getLogisticGoodsList, addLogisticGoodsList,
  addLogisticGoodsData, addLogisticItemData,
  addLogisticSupplierData, PriceFormat, getUserPermission} from "./../../../variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import LogisticWarehouseService from "Main/Logistic/LogisticWarehouseService";
import { DateTimestr } from "Main/Utils";
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService";
import CommonList from "../../../components/Table/CommonList";
import { customTextFromBooleanReverse } from "Main/Utils";
import { nullCompensation } from "Main/Utils";
import InputCoordinate from "components/Input/InputCoordinate";

function LogisticWarehouseUpdate(props) {
  const pages = 'Logistic'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const Service = new LogisticWarehouseService()
  const logisticService = new LogisticGoodsService()
  const refreshToken = getRefreshToken();
  const Data = getLogisticWarehouseData()
  const id = Data.id
  const name = useFormInput(Data.name)
  const [latitude, latitudeOnChange] = useState(Number(Data.latitude))
  const [longitude, longitudeOnChange] = useState(Number(Data.longitude))
  const address = useFormInput(Data.address)
  const created = Data.creation
  const modified = Data.modified
  const createdBy = Data.createdBy
  const modifiedBy = Data.modifiedBy
  const owner = Data.owner

  const [load, setDataLoad] = useState(true)
  const [dataTable, setDataTable] = useState(getLogisticGoodsList())

  const getAll =  async() => {
        const len = 'ALL'
        const result =  await Service.getAll(len)
        addUserList(result.data)
  }

  const getAllLogisticGoodsData =  async() => {
      try {
        setDataLoad(true)
        const len = 'ALL'
        const warehouseid = id
        const dat =  await logisticService.getAll({warehouseid, len})
        const result = dat.data
        if (!result) {
          setDataLoad(true)
        } else{
          setDataTable(result)
          addLogisticGoodsList(result)
          setDataLoad(false)
        }
        return result
      } catch (error) {
        return CatchError(error, props, refreshToken)
      }
    }

  const Edit = async() => {
    swal("Update", "Perubahan Tidak Dapat Dibatalkan", "info", {
                buttons: {
                update: {text: "Update", value: 'up' },
                cancel: 'Batal'
              }
            }
      ).then(async(value) => {
        switch (value) {
          case 'up':
            try {
            const payload = LogisticWarehouse(name.value, String(latitude), String(longitude), address.value)
              const result = await Service.put(id, payload)
              swal('Success', result.message, result.status).then(async () => {
                await getAll()
                props.history.push('/admin/logistic-warehouse-list')
              })
            } catch (error) {
            return await CatchError(error, props, refreshToken)
            }
            break;

          default:
            break;
        }
      })}

      const DeleteLogisticGoodsData = (id) => {
      swal("Delete", "Data Tidak Dapat di Recover", "info", {
        buttons: {
          cancel: 'Batal',
          delete: {text: "Hapus", value: 'del' }
        }
      }
      ).then(async (value) => {
        switch (value) {
          case 'del':
            try {
              const response = await logisticService.delete(id)
              swal(response.status, response.message, 'success').then(() => {
                getAllLogisticGoodsData()
                setDataLoad(true)
                const table = dataState.filter((dat) => dat.id !== id)
                setDataState(table)
              })
              return true
            } catch (error) {
              return await CatchError(error, props, refreshToken)
            }

          default:
            getAllLogisticGoodsData()
            // window.location.reload()
            setDataLoad(true)
            return false
        }
      }
    )
  }

  const ViewLogisticGoodsData = async (id, transfer) => {
    try {
      const result = await logisticService.get(id)
      addLogisticItemData([])
      addLogisticSupplierData([])
      addLogisticGoodsData(result.data)
      if (!transfer) {
        props.history.push('/admin/logistic-goods-update')
        return
      }
      props.history.push('/admin/logistic-warehouse-to-customer')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const AddLogisticGoods = () => {
    addLogisticItemData([])
    addLogisticSupplierData([])
    props.history.push('/admin/logistic-goods-add')
  }

  const mapping = (TableData) => TableData.map((Data) => {
    try {
      return {
            id: Data.id,
            name: Data.name,
            qty: Data.qty,
            paid: customTextFromBooleanReverse(Data.paid, 'Lunas', 'Belum-Bayar'),
            sn: nullCompensation(Data.sn),
            price: PriceFormat((Number(Data.price) * Number(Data.qty)), 'idr'),
            damaged: customTextFromBooleanReverse(Data.damaged, 'Bagus', 'Rusak'),
            location: Data.warehouse,
            organization: Data.owner,
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                {/* use this button to add a edit kind of action */}
                <Button
                disabled = {!Permission.Read}
                  onClick={ async () => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    await ViewLogisticGoodsData(Data.id, true)
                  }}
                  className="btn-icon btn-round"
                  color="primary"
                  id={`tooltip${Data.id}10`}
                  size="sm"
                >TF</Button>{" "}
                <Button
                  onClick={ async () => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    await ViewLogisticGoodsData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  color="warning"
                  id={`tooltip${Data.id}10`}
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                <Button
                disabled = {!Permission.Delete}
                  onClick={ async () => {
                    await DeleteLogisticGoodsData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}11`}
                  color="danger"
                  size="sm"
                >
                  <i className="fa fa-times" />
                </Button>{" "}
              </div>
        ),
      };
    } catch (error) {
      return []
    }
  })

  const [dataState, setDataState] = useState(mapping(dataTable));

  return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Update Gudang</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <Label>Nama *</Label>
                          <Input
                            placeholder="Nama"
                            {...name}
                            type="text"
                          />
                          <InputCoordinate
                            latitude = {latitude}
                            setLatitude = {latitudeOnChange}
                            longitude = {longitude}
                            setLongitude = {longitudeOnChange}
                          />
                          <Label>Alamat</Label>
                          <Input
                          className="react-select"
                          type="textarea"
                          {...address}
                          />
                          <Label>Created</Label>
                          <Input
                            placeholder="Created"
                            value = {DateTimestr(created)}
                            type="text"
                          />
                          <Label>modified</Label>
                          <Input
                            placeholder="Modified"
                            value = {DateTimestr(modified)}
                            type="text"
                          />
                          <Label>Mitra</Label>
                          <Input
                            placeholder="Mitra"
                            value = {owner}
                            type="email"
                          />
                          <Label>created By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {createdBy}
                            type="email"
                          />
                          <Label>modified By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {modifiedBy}
                            type="email"
                          />
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {Edit} color="info" disabled = {!Permission.Update}>Edit</Button>
                  </Form>
                </CardBody>
              </Card>

              <CommonList
                key = {1}
                header={'Logistik Barang Gudang'}
                load={load}
                dataState={dataState}
                getData={getAllLogisticGoodsData}
                Add={AddLogisticGoods}
                addDisabled = {Permission.Create}
              />
            </Col>
          </Row>
        </div>
      </>
    );
}


export default LogisticWarehouseUpdate;


