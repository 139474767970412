import React, { useState } from "react";
import { Route, Switch,
  Redirect, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications
// import NotificationAlert from "react-notification-alert";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import {getAccessToken,
  getRefreshToken
  } from "./../variables/common"

import routes from "routes.js";
import { useEffect } from "react";
import GlobalDataFetch from "Main/GlobalDataFetch";

var ps;

function Admin(props) {
  const accesstoken = getAccessToken()
  const refreshToken = getRefreshToken()
  const [sidebarMini, setSidebarMini] = useState(() => {
    document.body.classList.toggle("sidebar-mini");
    return true
  })
  const location = useLocation();
  const [backgroundColor] = React.useState("black");
  // const notificationAlert = React.useRef();
  const mainPanel = React.useRef();
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanel.current);
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });

    useEffect( () => {
      fetch()
    })

    const fetch = async () => {
      if (!accesstoken) {
        props.history.push('/auth/login-page')
      } else {
        await GlobalDataFetch()
      }
    }

    const minimizeSidebar = () => {
      // var message = "Sidebar mini ";
      // console.log(sidebarMini)
        if (!document.body.classList.contains("sidebar-mini")) {
          setSidebarMini(false);
        }
        document.body.classList.toggle("sidebar-mini");
    };

    React.useEffect(() => {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainPanel.current.scrollTop = 0;
    }, [location]);

    const getRoutes = (routes) => {
      return routes.map((prop, key) => {
        if (prop.collapse) {
          return getRoutes(prop.views);
        }
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else {
          return null;
        }
      });
    };

    const RedirectToAuth = () => {
      if (!refreshToken) {
        return (
          <Switch>
            <Redirect from="/admin" to="/auth" />
          </Switch>
        )
      }
      return (
        <div></div>
      )
    }

    const getActiveRoute = (routes) => {
      let activeRoute = "Artamedia Indonesia";
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].collapse) {
          let collapseActiveRoute = getActiveRoute(routes[i].views);
          if (collapseActiveRoute !== activeRoute) {
            return collapseActiveRoute;
          }
        } else {
          if (
            window.location.pathname.indexOf(
              routes[i].layout + routes[i].path
            ) !== -1
          ) {
            return routes[i].name;
          }
        }
      }
      return activeRoute;
    };
    return (
      <div className="wrapper">
        {/* <NotificationAlert ref={notificationAlert} /> */}
        <Sidebar
          {...props}
          routes={routes}
          minimizeSidebar={minimizeSidebar}
          backgroundColor={backgroundColor}
        />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar {...props} brandText={getActiveRoute(routes)} />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/" to="/admin/dashboard" />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            window.location.href.indexOf("full-screen-maps") !== -1 ? null : (
              <Footer fluid />
            )
          }
          <RedirectToAuth/>
        </div>

        {/* <FixedPlugin
          handleMiniClick={minimizeSidebar}
          sidebarMini={sidebarMini}
        /> */}
      </div>
    );
  }


export default Admin;
