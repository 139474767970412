const { Label, Input } = require("reactstrap");

function BasicInput({
    label = "label",
    value,
    disabled,
    onChange,
    type = "text",
    classLabel = ''
}) {
    return (
        <>
        <Label className={classLabel}>{label}</Label>
        <Input
            name= {label}
            placeholder={label}
            type={type}
            value={value}
            disabled={disabled}
            onChange={(e) => {
                if (typeof onChange === 'function') {
                    onChange(e.target.value);
                }
            } } />
        </>
    )
}

export default BasicInput