import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken,
  getCustomerOptions,
  CustomerToWarehouse} from "variables/common"
import { getLogisticItemOption } from "variables/common";
import { getLogisticWarehouseOption } from "variables/common";
import { GetItemCurrency } from "variables/common";
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService";
import TransferCustomerToWarehouseBody from "./../Body/TransferCustomerToWarehouse";
import CommonList from "../../../../../components/Table/CommonList";
import { MapObjToSelectObj } from "Main/Utils";
import swal from "sweetalert";
import Select from 'react-select'
import { nullCompensation } from "Main/Utils";
import { baseBlob } from "variables/common";
import { Datestr } from "Main/Utils";
import SubsStatus from "../StatusList";
import { getUserPermission } from "variables/common";
import { FetchLogisticSelect } from "Main/Logistic/FetchLogistic";
import { getOwner } from "variables/common";
import { verifySuperAdmin } from "Main/Utils";
import CustomerService from "Main/CustomerService";
import { useEffect } from "react";
import { getCustomerToWarehouseBlob } from "variables/WebStorage/LogisticBlobStorage";
import { setCustomerToWarehouseBlob } from "variables/WebStorage/LogisticBlobStorage";
import { deleteCustomerToWarehouseBlob } from "variables/WebStorage/LogisticBlobStorage";
import { BlobDataLen } from "Main/Utils";
import { CatchErrorFlushData } from "Main/Exceptions/exceptions";

function TransferBlobCustomerToWarehouse(props) {
  const pages = 'Logistic'
  const flushDesignation = 1
  const { mitraSuper, organization, id: userId } = verifySuperAdmin()
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const Service = new LogisticGoodsService()
  const customerService = new CustomerService()
  const [BlobData] = useState(getCustomerToWarehouseBlob(userId))
  const [BlobResult, BlobResultOnChange] = useState([])
  const refreshToken = getRefreshToken();
  const [logisticData, logisticDataOnChange ]= useState({})
  const [name, nameOnChange] = useState('')
  const [description, descriptionOnChange ]= useState('')
  // const itemSelect = MapObjToSelectObj(itemList)
  var selectCurrencyOptions = GetItemCurrency()
  const [logisticItemOptions, setlogisticItemOptions] = useState(getLogisticItemOption())
  const [warehouseOptions, setwarehouseOptions] = useState(getLogisticWarehouseOption())
  const [customerOptions, setcustomerOptions] = useState(getCustomerOptions())
  const [customerFilterOption, customerFilterOnChange] = useState([])
  const qtyState = logisticData.qty
  // const [logisticItem, logisticItemOnChange] = useState(logisticItemOptions.find((data) => data.value === itemId))
  const [warehouse, warehouseOnChange] = useState('')
  const [customer, customerOnChange] = useState(customerOptions.find((data) => data.value === logisticData.customerId))
  const priceValue = logisticData.price
  const [qty, qtyOnChange] = useState(1)
  const currency  = selectCurrencyOptions.find(data => data.label === 'Rupiah')
  const [insertDate, insertDateOnChange] = useState()
  const [item, itemOnChange] = useState('')
  const [LogisticGoodsOptions, logisticGoodsOptionsOnChange] = useState('')
  const [LogisticGoods, logisticGoodsOnChange] = useState('')
  const [dataLoad, loadOnChange] = useState(true)
  const [loadResult, setLoadResult] = useState(false)
  const ownerOption = getOwner()
  const [mitra, mitraOnChange] = useState(ownerOption.find((data) => data.value === organization))

  useEffect(() => {
    FetchSelectData(mitra.mitraid)
  }, [])

  const submit = async() => {
    try {
      setLoadResult(true)
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = baseBlob(BlobData)
      const result = await Service.transferCustomerToWarehouseBlob(payload)
      swal("Success", result.message, "success").then(() => {
        BlobData.splice(0, BlobData.length)
        setDataState(mapping(BlobData))
        BlobResultOnChange(result.data)
        deleteCustomerToWarehouseBlob(userId)
        setLoadResult(false)
      })
    } catch (error) {
      return CatchErrorFlushData(error, props, refreshToken, userId, flushDesignation)
    }
  }

  const GetResultData = () => {
    loadResult(true)
    loadResult(false)
  }

  const add = () => {
    loadOnChange(true)
    setLoadResult(true)
    const payload = CustomerToWarehouse(warehouse.value, LogisticGoods.value, qty, insertDate)
    BlobData.push(payload)
    setCustomerToWarehouseBlob(BlobData, userId)
    nameOnChange('')
    itemOnChange('')
    logisticGoodsOnChange('')
    warehouseOnChange('')
    customerOnChange('')
    qtyOnChange(1)
    insertDateOnChange(null)
    setDataState(mapping(BlobData))
    loadOnChange(false)
    BlobResult.splice(0, BlobResult.length)
    setLoadResult(false)
  }

  const GetLogisticGoodsCustomer = async (itemid) => {
    const len = 'ALL'
    const result = await Service.getAllLogisticCustomer({itemid, len})
    logisticGoodsOptionsOnChange(MapObjToSelectObj(result.data))
    nameOnChange('')
    descriptionOnChange('')
    customerFilterOnChange([])
    customerOnChange('')
  }

  const GetLogisticGoodsCustomerData = async (id) => {
    const result = await Service.getLogisticCustomer(id)
    const resultData = result.data
    logisticDataOnChange(result.data)
    customerOnChange(customerOptions.find((data) => data.value === resultData.customerId))
    qtyOnChange(1)
    warehouseOnChange('')
    nameOnChange(resultData.name)
    descriptionOnChange(resultData.description)
    customerFilterOnChange(customerOptions.filter((data) => data.value === resultData.customerId))
  }

  const columns = [
        {
        Header: "Src",
        accessor: "src",
        },
        {
        Header: "QTY",
        accessor: "qty",
        },
        {
        Header: "Transfer Ke",
        accessor: "transferTo",
        },
        {
        Header: "Tanggal",
        accessor: "date",
        },
        {
        Header: "Hapus",
        accessor: "actions",
        }
    ]

  const DeleteBlobData = (id) => {
    loadOnChange(true)
    BlobData.pop(id)
    setCustomerToWarehouseBlob(BlobData, userId)
    setDataState(mapping(BlobData))
    loadOnChange(false)
  }

  const mapping = (Dataset) => Dataset.map((Data, i) => {
    try {
      const warehouse = !warehouseOptions ? {label: Data.warehouseId || 'no-label'} : warehouseOptions.find((warehouse) => warehouse.value === Data.warehouseId)
      const goods = !LogisticGoodsOptions ? {label: Data.snTarget || 'no-label'} : LogisticGoodsOptions.find((goods) => goods.value === Data.snTarget)
          return {
            id: i,
            transferTo: warehouse.label,
            qty: Data.qtyChange,
            src: nullCompensation(goods.label),
            date: Datestr(Data.creation),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.snTarget}>
                <Button
                  onClick={ async () => {
                    await DeleteBlobData(i)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.snTarget}11`}
                  color="danger"
                  size="sm"
                >
                  <i className="fa fa-times" />
                </Button>{" "}
              </div>
            ),
          };
          } catch (error) {
            console.log(error.message)
            return []
          }
        })

  const [DataState, setDataState] = useState(
      mapping(BlobData)
  )
const FetchSelectData = async (mitra) => {
  const { logisticItem,
    logisticWarehouse } = await FetchLogisticSelect(mitra)
    setlogisticItemOptions(logisticItem)
    setwarehouseOptions(logisticWarehouse)
    customerOnChange('')
    warehouseOnChange('')
    itemOnChange('')
    nameOnChange('')
    logisticGoodsOnChange('')
    warehouseOnChange('')
    customerOnChange('')
    qtyOnChange(1)
    insertDateOnChange(null)
    await getCustomer(mitra)
  }

  const getCustomer = async (mitraid) => {
    const customerOption = await customerService.getCustomerSelect(mitraid)
    console.log(customerOption.data)
    setcustomerOptions(customerOption.data)
  }

  return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">BLOB Transfer Customer Ke Gudang</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Select
                      // className="react-select"
                      // classNamePrefix="react-select"
                      placeholder="Mitra"
                      value={mitra}
                      isDisabled = {!mitraSuper}
                      options={ownerOption}
                      onChange={ async (e) => {
                        mitraOnChange(e);
                        await FetchSelectData(e.mitraid)
                      } } />
                    <Label>Item</Label>
                    <Select
                      // className="react-select"
                      // classNamePrefix="react-select"
                      placeholder="Item"
                      name="singleSelect"
                      disabled
                      value = {item}
                      options = {logisticItemOptions}
                      onChange={async (e) => {
                        itemOnChange(e)
                        await GetLogisticGoodsCustomer(e.value)
                        nameOnChange('')
                        logisticGoodsOnChange('')
                        warehouseOnChange('')
                        customerOnChange('')
                        qtyOnChange(1)
                        insertDateOnChange(null)

                      }
                    }
                    />
                    <Label>Barang</Label>
                    <Select
                      // className="react-select"
                      // classNamePrefix="react-select"
                      placeholder="Barang"
                      name="singleSelect"
                      value = {LogisticGoods}
                      options = {LogisticGoodsOptions}
                      onChange={async (e) => {
                        logisticGoodsOnChange(e)
                        await GetLogisticGoodsCustomerData(e.value)
                      }
                    }
                    />
                    <TransferCustomerToWarehouseBody
                      name = {name}
                      nameOnChange = {nameOnChange}
                      warehouse = { warehouse}
                      customerFilterOption = {customerFilterOption}
                      warehouseOnChange = {warehouseOnChange}
                      customer = {customer}
                      warehouseOptions = {warehouseOptions}
                      customerOnChange = {customerOnChange}
                      qty = {qty}
                      qtyState = {qtyState}
                      qtyOnChange = {qtyOnChange}
                      priceValue = {priceValue}
                      currency = {currency}
                      insertDate = {insertDate}
                      insertDateOnChange = {insertDateOnChange}
                      description = {description}
                      descriptionOnChange = {descriptionOnChange}
                    />
                    <Button onClick = {add} disabled = {!item.sn} color="info">Add</Button>
                    <SubsStatus
                      Data={BlobResult}
                      load = {loadResult}
                      loadData = {GetResultData}
                    />
                    <CommonList
                      key = {1}
                      load={dataLoad}
                      dataState={DataState}
                      // getData={[]}
                      columns = {columns}
                      header={'Data Logistik'}
                    />
                  <BlobDataLen
                    blob = {BlobData}
                  />
                  <Button onClick = {submit} disabled = {!Permission.Update && !BlobData.length} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default TransferBlobCustomerToWarehouse;


