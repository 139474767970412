import React from 'react'
import LoadingOverlay from 'react-loading-overlay-ts'

export default function LoadingScreen({ loading, text, children }) {
  return (
    <LoadingOverlay
      active={loading}
      spinner
      text={!text ? 'Loading' : text}
    >
      {children}
    </LoadingOverlay>
  )
}