// source: Natural Earth http://www.naturalearthdata.com/ via geojson.xyz
// https://d2ad6b4ur7yvpq.cloudfront.net/
export const MAP_STYLE = 'https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json'

// Digital elevation model from https://www.usgs.gov/
export const TERRAIN_DATA = {
  elevationData: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/terrain.png',
  texture: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/terrain-mask.png',
  bounds: [-122.5233, 37.6493, -122.3566, 37.8159],
  elevationDecoder: {
          rScaler: 2,
          gScaler: 1,
          bScaler: 2,
          offset: 0
        }
}

export const naturalEarthGeoData_110m = {
    ADMIN_1_STATE_PROVINCE_SHP: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_1_states_provinces_shp.geojson',
    WGS84_BOUNDING_BOX: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_wgs84_bounding_box.geojson',
    RIVERS_LAKE_CENTERLINE: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_rivers_lake_centerlines.geojson',
    POPULATION_PLACES_SIMPLE: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_populated_places_simple.geojson',
    POPULATION_PLACES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_populated_places.geojson',
    LAND: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_land.geojson',
    GRATICULES5: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_graticules_5.geojson',
    GRATICULES30: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_graticules_30.geojson',
    GRATICULES20: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_graticules_20.geojson',
    GRATICULES15: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_graticules_15.geojson',
    GRATICULES10: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_graticules_10.geojson',
    GEOGRAPHY_REGION_POLYS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_geography_regions_polys.geojson',
    GEOGRAPHY_REGION_POINTS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_geography_regions_points.geojson',
    GEOGRAPHY_REGION_ELEVATION_POINTS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_geography_regions_elevation_points.geojson',
    GEOGRAPHY_MARINE_POLYS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_geography_marine_polys.geojson',
    GEOGRAPHIC_LINES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_geographic_lines.geojson',
    ADMIN_0_BOUNDARY_LINES_LAND: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_0_boundary_lines_land.geojson',
    ADMIN_0_COUNTRIES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_0_countries.geojson',
    ADMIN_0_MAP_UNITS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_0_map_units.geojson',
    ADMIN_0_PASIFIC_GROUPINGS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_0_pacific_groupings.geojson',
    ADMIN_0_SCALE_RANK: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_0_scale_rank.geojson',
    ADMIN_0_SOVEREIGNTY: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_0_sovereignty.geojson',
    ADMIN_0_TINY_COUNTRIES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_0_tiny_countries.geojson',
    ADMIN_1_STATES_PROVINCES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_1_states_provinces.geojson',
    ADMIN_1_STATES_PROVINCES_LINES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_1_states_provinces_lines.geojson',
    ADMIN_1_STATES_PROVINCES_SCALE_RANK: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_1_states_provinces_scale_rank.geojson',
    ADMIN_1_STATE_PROVINCE_SHP_SCALE_RANK: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_1_states_provinces_shp_scale_rank.geojson',
    GLACIATED_AREAS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_glaciated_areas.geojson',
    ADMIN_0_COUNTRIES_LAKES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_0_countries_lakes.geojson',
    ADMIN_1_STATES_PROVINCES_LAKES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_1_states_provinces_lakes.geojson',
    ADMIN_1_STATES_PROVINCES_LAKES_SHP: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_1_states_provinces_lakes_shp.geojson',
    LAKES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_lakes.geojson',
    OCEAN: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_ocean.geojson',
    COASTLINE: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_coastline.geojson'
}

export const naturalEarthGeoData_50m = {
    WGS84_BUILDING_BLOCK: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_wgs84_bounding_box.geojson',
    RIVERS_LAKE_CENTERLINE_SCALE_RANK: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_rivers_lake_centerlines_scale_rank.geojson',
    RIVERS_LAKE_CENTERLINE: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_rivers_lake_centerlines.geojson',
    POPULATION_PLACES_SIMPLE: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_populated_places_simple.geojson',
    POPULATION_PLACES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_populated_places.geojson',
    LAND: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_land.geojson',
    GRATICULES5: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_graticules_5.geojson',
    GRATICULES30: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_graticules_30.geojson',
    GRATICULES20: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_graticules_20.geojson',
    GRATICULES15: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_graticules_15.geojson',
    GRATICULES10: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_graticules_15.geojson',
    GEOGRAPHY_REGION_POLYS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_geography_regions_polys.geojson',
    GEOGRAPHY_REGION_POINTS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_geography_regions_points.geojson',
    GEOGRAPHY_REGION_ELEVATION_POINTS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_geography_regions_elevation_points.geojson',
    GEOGRAPHY_MARINE_POLYS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_geography_marine_polys.geojson',
    GEOGRAPHY_LINES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_geographic_lines.geojson',
    ADMIN_0_COUNTRIES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_countries.geojson',
    URBAN_AREA: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_urban_areas.geojson',
    ADMIN_0_TINY_COUNTRIES_SCALE_RANK: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_tiny_countries_scale_rank.geojson',
    ADMIN_0_TINY_COUNTRIES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_tiny_countries.geojson',
    ADMIN_0_SOVEREIGNTY: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_sovereignty.geojson',
    ADMIN_0_SCALE_RANK: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_scale_rank.geojson',
    ADMIN_0_PASIFIC_GROUPINGS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_pacific_groupings.geojson',
    ADMIN_0_MAP_UNITS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_map_units.geojson',
    ADMIN_0_MAP_SUBUNITS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_map_subunits.geojson',
    ADMIN_0_BREAKAWAY_DISPUTED_AREAS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_breakaway_disputed_areas.geojson',
    ADMIN_0_BOUNDARY_MAP_UNITS_50: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_boundary_map_units.geojson',
    ADMIN_0_BOUNDARY_LINES_MARITIME_INDICATOR: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_boundary_lines_maritime_indicator.geojson',
    ADMIN_0_BOUNDARY_LINES_LAND_50: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_boundary_lines_land.geojson',
    ADMIN_0_BOUNDATY_LINES_DISPUTED_AREAS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_boundary_lines_disputed_areas.geojson',
    COASTLINE: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_coastline.geojson',
    ADMIN_1_STATE_PROVINCE_SHP: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_1_states_provinces_shp.geojson',
    ADMIN_1_STATES_PROVINCES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_1_states_provinces.geojson',
    ADMIN_1_STATES_PROVINCES_SCALE_RANK: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_1_states_provinces_scale_rank.geojson',
    ADMIN_1_COUNTRIES_LINES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_1_states_provinces_lines.geojson',
    ADMIN_1_STATES_PROVINCES_LAKES_SHP: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_1_states_provinces_lakes_shp.geojson',
    ANTARCTIC_ICE_SHELVES_LINES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_antarctic_ice_shelves_lines.geojson',
    ANTARCTIC_ICE_SHELVES_POLYS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_antarctic_ice_shelves_polys.geojson',
    GLACIATED_AREAS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_glaciated_areas.geojson',
    ADMIN_1_STATES_PROVINCES_LAKES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_1_states_provinces_lakes.geojson',
    ADMIN_0_COUNTRIES_LAKES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_countries_lakes.geojson',
    LAKES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_lakes.geojson',
    LAKES_HISTORIC: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_lakes_historic.geojson',
    OCEAN: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_ocean.geojson',
    PLAYAS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_playas.geojson',
}

export const naturalEarthGeoData_10m = {
    GEOGRAPHIC_LINES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_geographic_lines.geojson',
    GEOGRAPHY_MARINE_POLYS_10: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_geography_marine_polys.geojson',
    GEOGRAPHY_REGION_ELEVATION_POINTS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_geography_regions_elevation_points.geojson',
    GEOGRAPHY_REGION_POINTS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_geography_regions_points.geojson',
    GRATICULES10: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_graticules_10.geojson',
    GRATICULES15: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_graticules_15.geojson',
    GRATICULES20: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_graticules_20.geojson',
    GRATICULES30: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_graticules_30.geojson',
    GRATICULES5: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_graticules_5.geojson',
    MINOR_ISLANDS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_minor_islands.geojson',
    MINOR_ISLAND_LABEL_POINTS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_minor_islands_label_points.geojson',
    PARK_AND_PROTECTED_LANDS_AREA: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_parks_and_protected_lands_area.geojson',
    PARK_AND_PROTECTED_LANDS_LINE: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_parks_and_protected_lands_line.geojson',
    PARK_AND_PROTECTED_LANDS_POINT: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_parks_and_protected_lands_point.geojson',
    PARK_AND_PROTECTED_LANDS_SCALE_RANK: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_parks_and_protected_lands_scale_rank.geojson',
    POPULATION_PLACE_SIMPLE: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_populated_places_simple.geojson',
    WGS84_BOUNDING_BOX: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_wgs84_bounding_box.geojson',
    RIVERS_NORTH_AMERICA: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_rivers_north_america.geojson',
    RIVERS_EUROPE: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_rivers_europe.geojson',
    ADMIN_0_ANTARCTIC_CLAIMS_LIMIT_LINES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_admin_0_antarctic_claim_limit_lines.geojson',
    URBAN_AREA_50: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_urban_areas.geojson',
    ADMIN_0_BOUNDARY_LINES_DISPUTED_AREAS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_admin_0_boundary_lines_disputed_areas.geojson',
    ADMIN_BOUNDARY_LINES_LAND: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_admin_0_boundary_lines_land.geojson',
    ADMIN_0_BOUNDARY_LINES_MAP_UNITS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_admin_0_boundary_lines_map_units.geojson',
    ADMIN_0_BOUNDARY_LINES_MARITIME_INDICATOR: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_admin_0_boundary_lines_maritime_indicator.geojson',
    ADMIN_0_DISPUTED_AREAS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_admin_0_disputed_areas.geojson',
    ADMIN_0_ANTARCTIC_CLAIMS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_admin_0_antarctic_claims.geojson',
    ADMIN_0_DISPUTED_AREA_SCALE_RANK_MINOR_ISLANDS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_admin_0_disputed_areas_scale_rank_minor_islands.geojson',
    ADMIN_0_LABEL_POINTS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_admin_0_label_points.geojson',
    PORTS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_ports.geojson',
    ADMIN_0_PASIFIC_GROUPINGS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_admin_0_pacific_groupings.geojson',
    ADMIN_0_SEAMS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_admin_0_seams.geojson',
    ADMIN_1_LABEL_POINTS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_admin_1_label_points.geojson',
    ADMIN_1_SEAMS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_admin_1_seams.geojson',
    AIRPORTS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_airports.geojson',
    RAILROADS_NORTH_AMERICA: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_railroads_north_america.geojson',
    BATHYMETRY_B9000: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_bathymetry_B_9000.geojson',
    BATHYMETRY_D7000: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_bathymetry_D_7000.geojson',
    BATHYMETRY_E6000: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_bathymetry_E_6000.geojson',
    BATHYMETRY_J1000: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_bathymetry_J_1000.geojson',
    BATHYMETRY_A10000: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_bathymetry_A_10000.geojson',
    BATHYMETRY_C8000: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_bathymetry_C_8000.geojson',
    MINOR_ISLANDS_COASTLINE: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_minor_islands_coastline.geojson',
    lAKES_PLUVIAL: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_lakes_pluvial.geojson',
    LAKES_NORTH_AMERICA: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_lakes_north_america.geojson',
    LAKES_HISTORIC: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_lakes_historic.geojson',
    LAKES_EUROPE: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_lakes_europe.geojson',
    ANTARCTIC_ICE_SHELVES_POLYS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_antarctic_ice_shelves_polys.geojson',
    ANTARCTIC_ICE_SHELVES_LINES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_antarctic_ice_shelves_lines.geojson',
    LAKES: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_lakes.geojson',
    LAND_OCEAN_SEAMS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_ocean.geojson',
    LAND_OCEAN_LABEL_POINTS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_land_ocean_label_points.geojson',
    PLAYAS: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_10m_playas.geojson'
   }

export function radiusSize (f) {
          const type = f.type

          if (type === 'mitra') {
            return 12
          }
          if (type === 'mitra') {
            return 10
          }
          if (type === 'outdoorDevice') {
            return 8
          }
          if (type === 'indoorDevice') {
            return 6
          }
          if (type === 'gridPole') {
            return 4
          }
          if (type === 'customer') {
            return 2
          }
          return 1
        }

export function typeColor (f) {
          const type = f.type

          if (type === 'mitra') {
            return [10, 138, 49]
          }
          if (type === 'indoorDevice') {
            return [245, 21, 0]
          }
          if (type === 'outdoorDevice') {
            return [100, 245, 65]
          }
          if (type === 'gridPole') {
            return [0, 21, 252]
          }
          if (type === 'customer') {
            return [52, 80, 0]
          }
          return [155, 140, 0]
        }