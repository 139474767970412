import React, { useState, useEffect } from "react"

// reactstrap components
import {
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  Label
} from "reactstrap"

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js"
import CustomerService from "Main/CustomerService"
import { getRefreshToken, addCustomerData } from "variables/common"
import swal from "sweetalert"
import { CatchError } from "Main/Exceptions/exceptions"
import Switch from "react-switch"
import { setStatus } from "variables/common"
import { customerOperation } from "Main/Utils"
import { validatePermission } from "Main/Utils"
import { ExportsCSV } from "variables/common"
import BankAccountService from "Main/BankAccountService"
import { addVirtualAccountList } from "variables/common"
import { useFormInput } from "Main/Utils"
import { addLogisticGoodsCustomerList } from "variables/common"
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService"
import CommonList from "../../../components/Table/CommonList"
import { customerColumns, customerColumnsAdmin, customerTrashColumns } from "./CustomerColumns"
import { getUserData } from "variables/common"
import { getUserPermission } from "variables/common"
import { customTextFromBoolean } from "Main/Utils"
import LoadingScreen from "components/Loader/LoadingScreen"
import { DateTimestr } from "Main/Utils"
import { verifySuperAdmin } from "Main/Utils"
import { CustomerModal } from "../../../components/Modal/CustomerModal"
import { Datestr } from "Main/Utils"
import { customTextAndBgColorFromBoolean } from "Main/Utils"
import { customTextFromBooleanReverse } from "Main/Utils"
import { ModalColorSelector } from "Main/Utils"
import NoLabelSelect from "components/Input/NoLabelSelect"
import { customerStatusOption } from "variables/referenceImport"

function CustomerList(props) {
  const page = 'customer'
  const customerService =  new CustomerService()
  const bankAccountService = new BankAccountService()
  const logisticService = new LogisticGoodsService()
  const customerLen = useFormInput('ALL')
  const userData = getUserData()
  const { organization } = userData
  const permission = getUserPermission()
  const customerPermission = permission.find(data => data.Role === page) || ''
  const [load, setDataLoad] = useState(true)
  const [loadEx, setLoadEx] = useState(false)
  const [Table, setDataTable] = useState([])
  const [status, statusOnChange] = useState({})
  const refreshToken = getRefreshToken()
  const [viewTrash, viewTrashOnChange] = useState(false)
  const { mitraSuper } = verifySuperAdmin()
  const enabledVaSync = false
  const Columns = !mitraSuper ? customerColumnsAdmin : customerColumns
  const filename = 'Import-Customer-' + organization
  const [show, setShow] = useState(false)
  const [modalLoading] = useState(false)
  const [modalData, setModalData] = useState({})

  const [statusSelected, setStatusSelected] = useState(customerStatusOption[1])

  const handleClose = () => {
    setModalData({})
    setShow(false)
  }
  const handleShow = (data) => {
    setModalData(data)
    setShow(true)
  }

  try {
  const getCustomer =  async(filter, trash = false) => {
    try {
      setDataLoad(true)
      const len = 'ALL'
      const dat =  await customerService.getAllCustomer(len, filter, trash)
      const result = dat.data

      setDataState(mapping(result))
      setDataTable(result)
      setDataLoad(false)
      return result
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  useEffect( () => {
    validatePermission(props, 0, 1)
    getCustomer(statusSelected?.value, viewTrash)
  }, [])

  const deleteCustomer = async (id) => {
    swal("Delete", "Customer Data Can't Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then(async(value) => {
      switch (value) {
        case 'del':
          try {
            setDataLoad(true)
            await customerService.deleteCustomer(id)
            await swal('Success', 'Delete Customer Success', 'success')
            sessionStorage.removeItem('customerList')
            await getCustomer(statusSelected?.value, viewTrash)
            const newState = dataState.filter(data => data.id !== id)
            setDataState(newState)
            viewTrashOnChange(false)
            setDataLoad(false)
            // window.location.reload()
            return true
          } catch (error) {
            setDataLoad(false)
            return await CatchError(error, props, refreshToken)
          }

        default:
          await getCustomer(statusSelected?.value, viewTrash)
          return false
      }
    })
  }

  const sendToTrash = async (id) => {
    swal("Trash", "hapus pelanggan ke Trash", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then(async(value) => {
      switch (value) {
        case 'del':
          try {
            setDataLoad(true)
            const result = await customerService.sendCustomerToTrash(id)
            await swal('Success', result.message, 'success')
            const state = await getCustomer(statusSelected?.value, viewTrash)
            const stateRaw = mapping(state)
            const newState = stateRaw.filter(data => data.id !== id)
            setDataState(newState)
            setDataLoad(false)
            return true
          } catch (error) {
            setDataLoad(false)
            return await CatchError(error, props, refreshToken)
          }

        default:
          await getCustomer(statusSelected?.value, viewTrash)
          return false
      }
    })
  }

  const recoverTrash = async (id) => {
    swal("Recover", "Recover data Pelanggan", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Recover", value: 'del' }
      }
    }
    ).then(async(value) => {
      switch (value) {
        case 'del':
          try {
            setDataLoad(true)
            const result = await customerService.recoverCustomerFromTrash(id)
            swal('Success', result.message, 'success').then( async () => {
              const table = await getCustomer(statusSelected?.value, viewTrash)
              setDataState(mapping(table))
              viewTrashOnChange(false)
              setDataLoad(false)
            })
            setDataLoad(false)
            return true
          } catch (error) {
            setDataLoad(false)
            return await CatchError(error, props, refreshToken)
          }

        default:
          // await getCustomer(true)
          return false
      }
    })
  }

  const exportInvoiceCSV = async() => {
    try {
      setLoadEx(true)
      const payload = ExportsCSV(["*"])
      const rawData = await customerService.exportCSV(payload, filename)
      setLoadEx(false)
      return rawData
    } catch (error) {
      setLoadEx(false)
      return await CatchError(error, props, refreshToken)
    }
  }

   const exportInvoiceExcel = async() => {
    try {
      setLoadEx(true)
      const payload = ExportsCSV(["*"])
      const rawData = await customerService.exportExcel(payload, filename)
      setLoadEx(false)
      return rawData
    } catch (error) {
      setLoadEx(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  const addCustomer = () => {
    props.history.push('/admin/customer-page')
  }

  const viewCustomer = async(id)=> {
    try {
      // const pages = 1
      // const limits = 10
      const dat = await customerService.getCustomer(id)
      const vaData =  await bankAccountService.getAllVirtualAccount(id)
      const logistic =  await logisticService.getAllLogisticCustomer({customerid: id, len: customerLen.value})
      const vaResult = vaData.data
      const result = dat.data
      addCustomerData(result)
      addVirtualAccountList(vaResult)
      addLogisticGoodsCustomerList(logistic.data)
      props.history.push('/admin/customer-view')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const setCustomerStatus = async(id, Status) => {
    try {
      setLoadEx(true)
      // listStateOnChange([])
      const payload = setStatus(Status)
      var operation = customerOperation(Status)
      // setDataLoad(true)
      const dat = await customerService.setCustomerStatus(id, payload)
      await swal(operation, dat.message, dat.status)
      const result = dat.data
      // const statelist = listState.find(list => list.id === id)
      // console.log(listState, statelist)
      // statelist.status = Status
      const table = await getCustomer(statusSelected?.value, viewTrash)
      setDataState(mapping(table))
      // setDataLoad(false)
      setLoadEx(false)
      return result
    } catch (error) {
      setLoadEx(false)
      swal('failed', error.message, 'warning')
    }
  }

    const syncRouter = async () => {
      try {
        setLoadEx(true)
        const data = await customerService.syncRouter()
        await swal('success', data.message, 'success')
        await syncSecret()
        setLoadEx(false)
      } catch (error) {
        setLoadEx(false)
        swal('failed', error.response.statusText, 'warning')
      }
  }

  const syncSecret = async () => {
      try {
        setLoadEx(true)
        const data = await customerService.syncSubs()
        await swal('success', data.message, 'success')
        setLoadEx(false)
      } catch (error) {
        setLoadEx(false)
        swal('failed',  error.response.statusText, 'warning')
      }
  }

  const syncVa = async () => {
      try {
        setLoadEx(true)
        const data = await customerService.syncVa()
        await swal('success', data.message, 'success')
        setLoadEx(false)
      } catch (error) {
        setLoadEx(false)
        swal('failed',  error.response.statusText, 'warning')
      }
  }

  const mapping =  (Table) => {
    return Table.map((Data, i) => {
      try {
          const {
            dueColorList,
            duePositiveColorList
          } = ModalColorSelector(Data)

          return {
            id: Data.id,
            name: Data.name,
            networkIdElement: (<div className="bg-success text-center font-weight-bold" onClick={() => handleShow(Data)}>{Data.networkId || 'no data'}</div>),
            customerId: Data.id || 'no data',
            fullname: Data.fullname,
            teritory: Data.teritory.toLowerCase(),
            virtualAccount: Data.va,
            tax: customTextFromBoolean(Data.ppn, 'Yes', 'No'),
            owner: Data.mitraName,
            subsPeriod: Data.lastSubs,
            subsPeriodElement: (
              <div className="text-center font-weight-bold">
                {customTextAndBgColorFromBoolean((Data.paidDate && Data.enabled && !Data.lastSubsUnpaid), Data.lastSubs || 'Periode Kosong', Data.lastSubs || 'Periode Kosong', duePositiveColorList, dueColorList)}
              </div>
            ),
            paidDate: customTextFromBoolean(!!Data.paidDate, DateTimestr(Data.paidDate), 'Belum-Bayar'),
            status: (
              <div className="actions-center">
                <Switch
                  className="react-switch"
                  id = {`${Data.id}`}
                  onText={<i className="now-ui-icons ui-1_check" />}
                  offColor="#08f"
                  onColor="#08f"
                  offHandleColor="#E5FF2E"
                  onHandleColor="#7AE846"
                  // checked = {status}
                  offText={
                  <i className="now-ui-icons ui-1_simple-remove" />
                  }
                  checked = {Data.enabled}
                  value = {status}
                  disabled = {!customerPermission.Update}
                  onChange = { async(e) =>{
                      // handleStatusChange(e, Data.id)
                      // let obj = dataState.find((o) => o.id === Data.id)
                      statusOnChange(e)
                      await setCustomerStatus(Data.id, e)
                    }
                  }
                />
              </div>
            ),
            StatustoUser: (customTextFromBoolean(Data.enabled, 'Aktif', 'Suspend')),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={ async () => {
                    await viewCustomer(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}`}
                  disabled = {!customerPermission.Read}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                <Button
                  onClick={async () => {
                    await sendToTrash(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}1`}
                  disabled = {!customerPermission.Delete}
                  color="danger"
                  size="sm"
                >
                <i className="fa fa-trash" />
                </Button>{" "}
              </div>
            ),
            recover: (
              <Button
                  onClick={ async () => {
                    await recoverTrash(Data.id)
                  }}
                  className="btn"
                  id={`tooltip${Data.id}`}
                  disabled = {!customerPermission.Update}
                  color="warning"
                  size="sm"
                >
                 Recover
                </Button>
            ),
            actionsTrash: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={ async () => {
                    await deleteCustomer(Data.id)
                  }}
                  className="btn-icon"
                  id={`tooltip${Data.id}1`}
                  disabled = {!customerPermission.Delete}
                  color="danger"
                  size="sm"
                >
                <i className="fa fa-times" />
                </Button>{" "}
              </div>
            ),
        }
        } catch (error) {
          console.log(error)
          return []
        }
      })}

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [dataState, setDataState] = useState(
    mapping(Table)
  )

  const viewCustomerTrash = async (trash) => {
    setDataLoad(true)
    const data = await getCustomer(statusSelected?.value, trash)
    setDataState(mapping(data))
    setDataLoad(false)
  }

  const exportData = async() => {
      swal("Export Semua", "pilih tipe data", {
                buttons: {
                csv: {text: "CSV", value: 'csv' },
                excel: {text: "Excel", value: 'excl' },
                cancel: 'Cancel'
              }
            }
      ).then(async(value) => {
        switch (value) {
          case 'csv':
            try {
              await exportInvoiceCSV()
            } catch (error) {
              return CatchError(error, props, refreshToken)
            }
            break
            case 'excl':
              try {
                await exportInvoiceExcel()
              } catch (error) {
                return CatchError(error, props, refreshToken)
              }
            break

            default:
            break
        }
    })
  }

  const customMenu = (
    <Row>
      <Col md={ 1 }>
        <div  className="actions-left">
          <Button onClick={addCustomer} color="info"
          disabled = {!customerPermission.Create}
          >Add</Button>
        </div>
      </Col>
      <Col md = { 3 }>
        <NoLabelSelect
          placeholder="Pilih Status Customer"
          value = {statusSelected}
          onChange = {async (e) => {
            setDataLoad(true)
            await getCustomer(e.value, viewTrash)
            setStatusSelected(e)
            setDataLoad(false)
          }}
          option = {customerStatusOption}
        />
      </Col>
      <Col md={2}>
        <div className="actions-right">
          <Button onClick={syncRouter} color="info"
          disabled = {!customerPermission.Update}
          >Sync Router</Button>
        </div>
      </Col>
      {!enabledVaSync ? null : (<Col md={2}>
        <div className="actions-right">
          <Button onClick={syncVa} color="info"
          disabled = {!customerPermission.Update}
          >Sync VA</Button>
        </div>
      </Col>)}
      <Col md={1}>
        <div className="actions-center">
          <Button onClick={exportData} color="info"
          disabled = {!customerPermission.Read}
          >Export</Button>
        </div>
      </Col>
      <Col md={2}>
        <div className="actions-right">
          <FormGroup check>
            <Label check> View Trash
              <Input type="checkbox"
              value = {viewTrash}
              checked = {viewTrash}
                onChange = {async (e) => {
                  viewTrashOnChange(e.target.checked)
                  await viewCustomerTrash(e.target.checked)
                }}
              />
              <span className="form-check-sign" />
            </Label>
          </FormGroup>
        </div>
      </Col>
      <Col md={3}>
          <div className="text-black text-center font-weight-bold">
            <div>Legend Status Langganan</div>
            <div className="bg-danger">Overdue</div>
            <div className="bg-warning">Waiting</div>
            <div className="bg-success">Paid</div>
          </div>
      </Col>
    </Row>
  )

  const modalChild = (data) => {
    const {
      paidAltStatus,
      dueColor,
      duePositiveColor
    } = ModalColorSelector(data)
    const invoicePeriod = `${Datestr(data.startSubsUnpaid)} - ${Datestr(data.lastSubsUnpaid)}`
    return (
      <>
        <div className="table bold text-black">
          <Row className="border border-dark" md = {12}>
            <Col className="font-weight-bold text-white bg-dark" md = {4}>Customer ID</Col>
            <Col className="font-weight-bold text-center" md = {8}>{data.id}</Col>
          </Row>
          <Row className="border border-dark" md = {12}>
            <Col className="font-weight-bold text-white bg-dark" md = {4}>Network ID</Col>
            <Col className="font-weight-bold text-center" md = {8}>{data.networkId}</Col>
          </Row>
          <Row className="border border-dark" md = {12}>
            <Col className="font-weight-bold text-white bg-dark" md = {4}>Nama Customer</Col>
            <Col className="font-weight-bold text-center" md = {8}>{data.name}</Col>
          </Row>
          <Row className="border border-dark" md = {12}>
            <Col className="font-weight-bold text-white bg-dark" md = {4}>BNI VA</Col>
            <Col className="font-weight-bold text-center" md = {8}>{customTextFromBooleanReverse(!data.bniVa, data.bniVa, 'No-Data')}</Col>
          </Row>
          <Row className="border border-dark" md = {12}>
            <Col className="font-weight-bold text-white bg-dark" md = {4}>Mandiri VA</Col>
            <Col className="font-weight-bold text-center" md = {8}>{customTextFromBooleanReverse(!data.mandiriVa, data.mandiriVa, 'No-Data')}</Col>
          </Row>
          <Row className="border border-dark" md = {12}>
            <Col className="font-weight-bold text-white bg-dark" md = {4}>BRI VA</Col>
            <Col className="font-weight-bold text-center" md = {8}>{customTextFromBooleanReverse(!data.briVa, data.briVa, 'No-Data')}</Col>
          </Row>
          <Row className="border border-dark" md = {12}>
            <Col className="font-weight-bold text-white bg-dark" md = {4}>Status Customer</Col>
            <Col className="font-weight-bold text-center" md = {8}>{customTextFromBoolean(data.enabled, 'Aktif', 'Suspend')}</Col>
          </Row>
          <Row className="border border-dark" md = {12}>
            <Col className="font-weight-bold text-white bg-dark" md = {4}>Pembayaran Terakhir Tagihan</Col>
            <Col className="font-weight-bold text-center" md = {8}>{customTextFromBoolean(!!data.paidDate, DateTimestr(data.paidDate), 'No-History')}</Col>
          </Row>
          <Row className="border border-dark" md = {12}>
            <Col className="font-weight-bold text-white bg-dark" md = {4}>Periode Tagihan Belum-Lunas</Col>
            <Col className="font-weight-bold text-center" md = {8}>{customTextFromBoolean((data.paidDate && data.enabled && !data.lastSubsUnpaid), 'Invoice Lunas', invoicePeriod)}</Col>
          </Row>
          <Row className="border border-dark" md = {12}>
            <Col className="font-weight-bold text-white bg-dark" md = {4}>Jatuh Tempo</Col>
            <Col className="font-weight-bold text-center" md = {8}>{customTextAndBgColorFromBoolean(!!data.dueSubsUnpaid, DateTimestr(data.dueSubsUnpaid), 'Tidak Ada Invoice yang memasuki Jatuh Tempo', duePositiveColor, dueColor)}</Col>
          </Row>
          <Row className="border border-dark" md = {12}>
            <Col className="font-weight-bold text-white bg-dark" md = {4}>Status Tagihan</Col>
            <Col className="font-weight-bold text-center" md = {8}>{customTextFromBoolean((data.paidDate && data.enabled && !data.lastSubsUnpaid), 'Lunas', paidAltStatus)}</Col>
          </Row>
        </div>
      </>
    )
  }

  const children = (
        <>
          <div className="content">
            <Row>
              <Col xs={12} md={12}>
                  <CommonList
                    key={1}
                    header={!viewTrash ? 'Customer': 'Trash'}
                    columns={!viewTrash ? Columns : customerTrashColumns}
                    dataState={dataState}
                    menu = {customMenu}
                    Add={addCustomer}
                    load={load}
                    getData={getCustomer}
                  />
                  <CustomerModal
                    open={show}
                    close={handleClose}
                    child = {modalChild(modalData)}
                    loading = {modalLoading}
                    handleClose = {handleClose}
                  />
              </Col>
            </Row>
          </div>
        </>
    )

    return (
      <>
      <PanelHeader size="sm" />
      <LoadingScreen
        text = {'Sync'}
        loading = {loadEx}
        children={children} />
      </>
    )
  } catch (error) {
    return (
      <>
      <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Customer</h2>
              </div>
            }
          />
      <h3>{error.message || error}</h3>
      </>
    )
  }
}

export default CustomerList
