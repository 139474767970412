/*eslint-disable*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import BillingService from "Main/BillingService";
import { addBillingData, getRefreshToken } from "variables/common";
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { addAddressOptions } from "variables/common";
import CustomerService from "Main/CustomerService";
import { getUserPermission } from "variables/common";
import CommonList from "components/Table/CommonList";
import { GetBillingStatus } from "variables/common";



function BillingList(props) {
  const pages = 'billing'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const billingService =  new BillingService()
  const customerService = new CustomerService()
  const refreshToken = getRefreshToken()
  const [loading, setDataLoaded] = useState(true)
  const [dataTable, dataTableOnChange] = useState([])
  const statusOptions = GetBillingStatus()

  try {

    const getBilling =  async() => {
      try {
        setDataLoaded(true)
        const len = 'ALL'
        const dat =  await billingService.getAllBilling(len)
        const result = dat.data

        setDataState(mapping(result))
        dataTableOnChange(result)
        setDataLoaded(false)
        return result
      } catch (error) {
        setDataLoaded(false)
        return CatchError(error, props, refreshToken)
      }
    }

    useEffect( () => {
      getBilling()
    }, [])

    // getBilling()
    const deleteBilling = async(id) => {
      swal("Delete", "Billing Data Can't Recover", "info", {
            buttons: {
            cancel: 'Cancel',
            delete: {text: "Delete", value: 'del' }
          }
        }
      ).then(async(value) => {
          switch (value) {
            case 'del':
              try {
                setDataLoaded(true)
                await billingService.deleteBilling(id)
                await swal('Success', 'Delete Billing Success', 'success')
                const state = await getBilling()
                const stateRaw = mapping(state)
                const table = stateRaw.filter((dat) => dat.id !== id)
                setDataState(table)
              } catch (error) {
                return await CatchError(error, props, refreshToken)
              }
            break;

            default:
              await getBilling()
              // window.location.reload()
              break;
          }
        })
      }

    const viewBilling = async(id, customer)=> {
      try {
        const dat = await billingService.getBilling(id)
        const addressOption = await customerService.getAddressSelect(customer)
        const result = dat.data
        addAddressOptions(addressOption.data)
        addBillingData(result)
        props.history.push('/admin/billing-update')
      } catch (error) {
        return await CatchError(error, props, refreshToken)
      }
    }

    const Add = () => {
      props.history.push('/admin/Billing-page')
    }

    const columns=[
            {
              Header: "Customer",
              accessor: "customer",
            },
            {
              Header: "Network ID",
              accessor: "id",
            },
            {
              Header: "Owner",
              accessor: "owner",
            },
            {
              Header: "Status",
              accessor: "status",
            },
            {
              Header: "Provinsi",
              accessor: "province",
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
            },
    ]

    const menu = (
      <Row>
        <Col md={10}>
          <Button onClick={Add}
          color="info"
          disabled = {!Permission.Create}>Add</Button>
        </Col>
      </Row>
    )

    const mapping = (DataTable) => DataTable.map((Data) => {
          try {
            return {
            id: Data.id,
            customer: Data.customer,
            name: Data.name,
            owner: Data.owner,
            status: statusOptions.find(stat => stat.value === Data.status).label.toUpperCase(),
            province: Data.Province.toLowerCase(),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>

                {/* use this button to add a edit kind of action */}
                <Button
                  disabled = {!Permission.Read}
                  onClick={() => {
                    viewBilling(Data.id, Data.customerid)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}1`}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                {/* <UncontrolledTooltip
                  delay={0}
                  target={`tooltip${Data.id}1`}
                >Update</UncontrolledTooltip> */}
                {/* use this button to remove the data row */}
                <Button
                  disabled = {!Permission.Delete}
                  onClick={() => {
                    deleteBilling(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}2`}
                  color="danger"
                  size="sm"
                  >
                  <i className="fa fa-times" />
                  </Button>{" "}
                  {/* <UncontrolledTooltip
                  delay={0}
                  target={`tooltip${Data.id}2`}
                  >Delete</UncontrolledTooltip> */}
              </div>
            ),
          };
          } catch (error) {
            return []
          }
        })

    const [dataState, setDataState] = useState(
        mapping(dataTable)
    );

    return (
        <>
        <PanelHeader size="sm" />
          {/* <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Subscription</h2>
              </div>
            }
          /> */}
          <div className="content">
            <CommonList
              key={1}
              header={'Subscription'}
              columns={columns}
              dataState={dataState}
              menu = {menu}
              // Add={addInvoiceData}
              load={loading}
              getData={getBilling}
                  />
          </div>
        </>
      );
  } catch (error) {
    return (
      <>
      <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Subscription</h2>
              </div>
            }
          />
      <h3>{error.message || error}</h3>
      </>
    )
  }
}

export default BillingList;
