import React, { useEffect } from "react";
import Select from "react-select"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken, addCustomerOptions,
        getMitraBankSelect, addMitraBankSelect,
        getVirtualAccountData, VirtualAccount, getUserPermission } from "./../../../variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions"
import BankAccountService from "Main/BankAccountService";
import CustomerService from "Main/CustomerService";
import { DateTimestr, verifyLength } from "Main/Utils";

function VirtualAccountView(props) {
  const pages = 'bank-account'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const bankAccountService =  new BankAccountService()
  const customerService = new CustomerService()
  const refreshToken = getRefreshToken()
  const virtualAccountData = getVirtualAccountData()
  const [BankOption, BankOptionOnChange] = useState(getMitraBankSelect())
  const [minLengthState, setminLengthState] = React.useState("");
  console.log(virtualAccountData)
  const id = virtualAccountData.id
  const customerId = virtualAccountData.customerid
  const category = virtualAccountData.category
  const mitra = virtualAccountData.mitraName
  const mitraId = virtualAccountData.mitraId
  const region = virtualAccountData.bank_region
  const bankNum = virtualAccountData.bank_number
  const created = virtualAccountData.creation
  const modified = virtualAccountData.modified
  const createdBy = virtualAccountData.created_by
  const modifiedBy = virtualAccountData.modified_by
  const customer = virtualAccountData.customerName
  const [bankName, bankNameOnChange] = useState(BankOption.find(e => e.label === virtualAccountData.bankName))
  const [bankNumber, bankNumberOnChange] = useState(virtualAccountData.number)
  const [isAuto, isAutoOnChage] = useState(false)
  const [isManual, isManualOnChage] = useState(false)
  const [companyNumber, companyNumberOnChange] = useState('')
  const [netId, netIdOnChage] = useState(category === 'BROADBAND')

  const fetch = async(Id) => {
      const customerOption = await customerService.getCustomerSelect()
      const BankOptions = await bankAccountService.getBankAccountSelect()
      // const bankData = await bankAccountService.getVirtualAccount(Id)
      addCustomerOptions(customerOption.data)
      addMitraBankSelect(BankOptions.data)
      BankOptionOnChange(BankOptions.data)
      GetCompanyNumber(bankName.value)
      // VirtualAccountDataOnChange(bankData.data)
  }

  useEffect(() => {
    fetch(id)
  }, [])


   const GetCompanyNumber = async (idBank) => {
    const payload = await bankAccountService.getVirtualAccountCompany(idBank)
    const companyNumber = payload.data.companyNumber || 'Company Number tidak tersedia'
    companyNumberOnChange(companyNumber)
  }

  const [bankNumberPassed, bankNumberPassedOnChange] = useState(true)
  const updateVirtualBankAccount = async() => {
    try {
      var payload = VirtualAccount(mitraId, customerId, bankName.value, isAuto, netId)
      if (!isAuto) {
        payload = VirtualAccount(mitraId, customerId, bankName.value, isAuto, netId, bankNumber)
      }
      console.log(payload)
      const result = await bankAccountService.putVirtualAccount(id, payload)
      swal(result.status, result.message, "success").then(async () => {
        await fetch()
        props.history.push('/admin/customer-view')
      })
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Detail Virtual Account</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <Label>Customer</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Customer"
                            value = {customer}
                          />
                          <Label>Kategori Customer {category}</Label>
                          <FormGroup check>
                            <Label check> Genereate with Network Id
                              <Input type="checkbox"
                              value = {netId}
                              onChange = {e => {
                                netIdOnChage(e.target.checked)
                                isManualOnChage(false)
                                }
                              }
                              />
                              <span className="form-check-sign" />
                            </Label>
                        </FormGroup>
                        <Label>Nama Bank</Label>
                            <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Bank"
                            name="singleSelect"
                            value = {bankName}
                            options = {BankOption}
                            onChange={(value) => { bankNameOnChange(value) }
                            }
                          />
                        <FormGroup check>
                            <Label check> Auto Generate
                              <Input type="checkbox"
                              value = {isAuto}
                              onChange = {e => {
                                isAutoOnChage(e.target.checked)
                                isManualOnChage(false)
                                }
                              }
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        <Label>Company Number</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Company Number"
                            name="singleSelect"
                            value = {companyNumber}
                          />
                        <FormGroup check>
                            <Label check> Isi Manual
                              <Input type="checkbox"
                              value = {isManual}
                              onChange = {e => isManualOnChage(e.target.checked)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        <Label>Nomor Virtual Account</Label>
                        <Input
                          name="min_length_bank_number"
                          type="number"
                          placeholder="Virtual Account"
                          disabled = {isAuto}
                          value = {bankNumber}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, !isManual ? 13: 17)) {
                              bankNumberOnChange(e.target.value)
                            }
                            if (!verifyLength(e.target.value, !isManual ? 8: 12)) {
                              setminLengthState("has-danger");
                              bankNumberPassedOnChange(true)
                            } else {
                              setminLengthState("has-success");
                              bankNumberPassedOnChange(false)
                            }
                          }}
                        />
                        {minLengthState === "has-danger" ? (
                          <label className="error">
                            field harus berisi {!isManual ? 8: 12} characters.
                          </label>
                        ) : null}
                        </FormGroup>
                        <Label>Mitra</Label>
                            <Input
                              placeholder="Mitra"
                              value={mitra}
                              type="text"
                        />
                        <Label>Wilayah</Label>
                            <Input
                              placeholder="Wilayah"
                              value={region}
                              type="text"
                        />
                        <Label>Nomor Rekening Bank</Label>
                            <Input
                              placeholder="Nomor Rekening Bank"
                              value={bankNum}
                              type="text"
                        />
                        <label>Created</label>
                          <Input
                            placeholder="Created"
                            value = {DateTimestr(created)}
                            type="text"
                          />
                          <label>modified</label>
                          <Input
                            placeholder="Modified"
                            value = {DateTimestr(modified)}
                            type="text"
                          />
                          <label>created By</label>
                          <Input
                            placeholder="Modified By"
                            value = {createdBy}
                            type="email"
                          />
                          <label>modified By</label>
                          <Input
                            placeholder="Modified By"
                            value = {modifiedBy}
                            type="email"
                          />
                      </Col>
                    </Row>
                  <Button onClick = {updateVirtualBankAccount} disabled = {(!Permission.Update && bankNumberPassed && !isAuto)} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default VirtualAccountView;
