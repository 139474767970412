import LoginService from '../LoginService'
import APIconsume from '../APIconsume'
import { getAccessToken } from "../../variables/common"

class ItemAddOnService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'Item/AddOns'
    }

    async addData (payload) {
        const result = await this._consume.postData(payload, this._url)
        return result.data
    }

    async getData (id) {
        const url = `${this._url}/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllData (len = 'ALL', page = '') {
        let url = `${this._url}/view?line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

      async getAllDataByRouter (routerid, len = 'ALL', page = '') {
        let url = `${this._url}/view?routerid=${routerid}&line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async putData (id, payload) {
        const url = `${this._url}/${id}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async deleteData (id) {
        const url = `${this._url}/${id}`
        const result = await this._consume.deleteData(url)
        return result.data
    }
}

export default ItemAddOnService