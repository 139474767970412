import React, { useEffect } from "react";
import Select from "react-select"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken, getGlobalTemplateOption,
        GlobalTemplate, addCustomerOptions,
        addGlobalTemplateList} from "variables/common"
import swal from "sweetalert";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {CatchError} from "Main/Exceptions/exceptions"
import TemplateService from "Main/TemplateService";
import CustomerService from "Main/CustomerService";
import { getUserData } from "variables/common";
import { getOwner } from "variables/common";

function GlobalTemplatePage(props) {
  const templateService =  new TemplateService()
  const customerService = new CustomerService()
  const userData = getUserData()
  const ownerData = getOwner()
  const template = '<div></div>'
  const [editorState, editorStateOnChange] = useState(() => EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(template))))
  const [bodyeditorState, bodyeditorStateOnChange] = useState(() => EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(template))))
  const [footereditorState, footereditorStateOnChange] = useState(() => EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(template))))
  const refreshToken = getRefreshToken()
  const TypeOptions = getGlobalTemplateOption()
  const [name, nameOnChange ] = useState('')
  const [type, typeOnChange] = useState('')
  const [priority, priorityOnChange] = useState(false)
  const [warningNotification, warningNotificationOnChange] = useState(false)
  const [overdue, overdueOnChange] = useState(false)
  const [payment, paymentOnChange] = useState(false)
  const [active, activeOnChange] = useState(false)
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const mitraType = userData.mitraType
  const [changeOwner, changeOwnerOnChange] = useState(false)

  const getGlobalTemplate =  async(len = 'ALL') => {
    try {
      const dat =  await templateService.getAllGlobalTemplate(len)
      const result = dat.data
      addGlobalTemplateList(result)
      return result
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const fetch = async() => {
      const customerOption = await customerService.getCustomerSelect()
      addCustomerOptions(customerOption.data)
  }

  useEffect(() => {
    fetch()
  }, [])

  const updateTextDescription = async (state) => {
      await editorStateOnChange(state)
      convertToRaw(editorState.getCurrentContent())
      // console.log(htmlDat)
  }

  const updateBodyTextDescription = async (state) => {
      await bodyeditorStateOnChange(state)
      convertToRaw(bodyeditorState.getCurrentContent())
      // console.log(htmlDat)
  }

  const updateFooterTextDescription = async (state) => {
      await footereditorStateOnChange(state)
      convertToRaw(footereditorState.getCurrentContent())
      // console.log(htmlDat)
  }


  const submitGlobalTemplate = async() => {
    try {
    const datPayload = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    const bodyPayload = draftToHtml(convertToRaw(bodyeditorState.getCurrentContent()))
    const footerPayload = draftToHtml(convertToRaw(footereditorState.getCurrentContent()))
    const payload = GlobalTemplate(
      name,
      type.value,
      datPayload,
      bodyPayload,
      footerPayload,
      priority,
      warningNotification,
      overdue,
      payment,
      active,
      owner.mitraid
      )
      console.log(payload)
      const result = await templateService.addGlobalTemplate(payload)
      swal(result.status, result.message, "success").then(async () => {
        await getGlobalTemplate()
        props.history.push('/admin/global-template-list')
      })
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Template Manager</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <Label>Nama</Label>
                          <Input
                            placeholder="Nama"
                            type="text"
                            value = {name}
                            onChange={(e) => { nameOnChange(e.target.value) }
                            }
                          />
                         <Label>Mitra</Label>
                        {
                        (mitraType === 'SUPERADMIN') ? (
                            <>
                            <Select
                                // className="react-select"
                                // classNamePrefix="react-select"
                                placeholder="owner"
                                value={owner}
                                isDisabled = {!changeOwner}
                                options={ownerData}
                                onChange={ async (e) => {
                                  ownerOnChange(e);
                                } }
                              />
                              <FormGroup check>
                                <Label check> Ganti Mitra
                                  <Input type="checkbox"
                                  value = {changeOwner}
                                  checked = {changeOwner}
                                  onChange = {e => {
                                      ownerOnChange(ownerData.find(own => own.value === userData.organization))
                                      changeOwnerOnChange(e.target.checked)
                                    }
                                  }
                                  />
                                  <span className="form-check-sign" />
                                </Label>
                              </FormGroup>
                              </>) : (
                                <Input
                                  placeholder="Mitra"
                                  value={owner.value}
                                />
                              )
                        }
                        <Label>Tipe</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Tipe"
                            name="singleSelect"
                            value = {type}
                            options = {TypeOptions}
                            onChange={(e) => {
                              typeOnChange(e)
                            }
                            }
                          />
                          <Label>Kosongkan Template CheckList untuk normal Template</Label>
                          <FormGroup check>
                            <Label check> Priority {type.label} Template
                              <Input type="checkbox"
                                  value = {priority}
                                  checked = {priority}
                                  onChange = {e => {priorityOnChange(e.target.checked)
                                    warningNotificationOnChange(false)
                                    paymentOnChange(false)
                                    overdueOnChange(false)
                                    activeOnChange(false)
                                  }}
                                />
                              <span className="form-check-sign" />
                            </Label>
                            </FormGroup>
                            <FormGroup check>
                            <Label check> Warning Notification Template
                              <Input type="checkbox"
                                  value = {warningNotification}
                                  checked = {warningNotification}
                                  onChange = {e => {warningNotificationOnChange(e.target.checked)
                                    overdueOnChange(false)
                                    paymentOnChange(false)
                                    priorityOnChange(false)
                                    activeOnChange(false)
                                  }}
                                />
                              <span className="form-check-sign" />
                            </Label>
                            </FormGroup>
                            <FormGroup check>
                            <Label check> Overdue Notification Template
                              <Input type="checkbox"
                                  value = {overdue}
                                  checked = {overdue}
                                  onChange = {e => {overdueOnChange(e.target.checked)
                                  priorityOnChange(false)
                                  paymentOnChange(false)
                                  warningNotificationOnChange(false)
                                  activeOnChange(false)
                                  }}
                                />
                              <span className="form-check-sign" />
                            </Label>
                            </FormGroup>
                            <FormGroup check>
                            <Label check> Payment Notification Template
                              <Input type="checkbox"
                                  value = {payment}
                                  checked = {payment}
                                  onChange = {e => {
                                    paymentOnChange(e.target.checked)
                                    overdueOnChange(false)
                                    priorityOnChange(false)
                                    warningNotificationOnChange(false)
                                    activeOnChange(false)
                                  }}
                                />
                              <span className="form-check-sign" />
                            </Label>
                            </FormGroup>
                            <FormGroup check>
                            <Label check> Active Notification Template
                              <Input type="checkbox"
                                  value = {active}
                                  checked = {active}
                                  onChange = {e => {
                                    activeOnChange(e.target.checked)
                                    paymentOnChange(false)
                                    overdueOnChange(false)
                                    priorityOnChange(false)
                                    warningNotificationOnChange(false)
                                  }}
                                />
                              <span className="form-check-sign" />
                            </Label>
                            </FormGroup>
                      <label>Header</label>
                        <div>
                          <Editor
                             wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            editorState = {editorState}
                            onEditorStateChange = {updateTextDescription}
                          />
                        <extarea
                          disabled
                          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                        />
                        </div>
                        <label>Body</label>
                        <div>
                          <Editor
                             wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            editorState = {bodyeditorState}
                            onEditorStateChange = {updateBodyTextDescription}
                          />
                        <extarea
                          disabled
                          value={draftToHtml(convertToRaw(bodyeditorState.getCurrentContent()))}
                        />
                        </div>
                        <label>Footer</label>
                        <div>
                          <Editor
                             wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            editorState = {footereditorState}
                            onEditorStateChange = {updateFooterTextDescription}
                          />
                        <extarea
                          disabled
                          value={draftToHtml(convertToRaw(footereditorState.getCurrentContent()))}
                        />
                        </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  <Button onClick = {submitGlobalTemplate} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default GlobalTemplatePage;
