async function getCurrentPosition() {
    return new Promise( (resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            position => resolve(position),
            error => reject(error)
        )
    })
}

export async function HandleGeolocation() {
    try {
        if (navigator.geolocation) {
            const position = await getCurrentPosition()
            const accuracy = position.coords.accuracy
            const latitude = position.coords.latitude
            const longitude = position.coords.longitude
            return {
                latitude,
                longitude,
                accuracy
            }
        }
        return {
          latitude: 0,
          longitude: 0,
          accuracy: 10000,
          message: 'Geolokasi tidak di Support Browser.'
        }
    } catch (error) {
        return {
          latitude: 0,
          longitude: 0,
          accuracy: 10000,
          message: error.message
        }
    }
  };