/*eslint-disable*/
import React, { useState, useEffect } from "react";
// reactstrap components
import {
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import UserService from "Main/UserService";
import {getRefreshToken, setStatus,
  addUserManagerData, addRoleManagerList} from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { userStatus, customerOperation } from "Main/Utils";
import { validatePermission, useFormInput } from "Main/Utils";
import StatusButton from "components/Button/StatusButton";
import { customTextFromBoolean } from "Main/Utils";
import { getUserPermission } from "variables/common";
import CommonList from "components/Table/CommonList";



function UserManagerList(props) {
  const pages = 'userManager'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const userService =  new UserService()
  const userLen = useFormInput('ALL')
  const refreshToken = getRefreshToken()
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const [status, statusOnChange] = useState({})
  const [page, pageOnChange] = useState(0)

  try {
    const getUser =  async() => {
      try {
        setDataLoad(true)
        const len = userLen.value
        const dat =  await userService.getAllUser(len)
        const result = dat.data

        setDataState(mapping(result))
        setDataTable(result)
        setDataLoad(false)
        return result
      } catch (error) {
        setDataLoad(false)
        return CatchError(error, props, refreshToken)
      }
    }

    const setPageState = (pages) => {
      pageOnChange(pages)
    }

    useEffect( () => {
      validatePermission(props, 3, 1)
      getUser()
      }, [])

      const deleteUser = (id) => {
      swal("Delete", "Data Tidak Dapat di Recover", "info", {
        buttons: {
          cancel: 'Batal',
          delete: {text: "Hapus", value: 'del' }
        }
      }
      ).then(async (value) => {
        switch (value) {
          case 'del':
            try {
              setDataLoad(true)
              validatePermission(props, 3, 3)
              const response = await userService.deleteUser(id)
              await swal(response.status, response.message, 'success')
              const state = await getUser()
              const stateRaw = mapping(state)
              const table = stateRaw.filter((dat) => dat.id !== id)
              setDataState(table)
              setDataLoad(false)
              return true
            } catch (error) {
              return await CatchError(error, props, refreshToken)
            }

          default:
            await getUser()
            return false
        }
      })
    }

  const viewUser = async (id) => {
    try {
      const result = await userService.getUser(id)
      const userPermission = await userService.getAllPermission(id)
      addUserManagerData(result.data)
      addRoleManagerList(userPermission.data)
      props.history.push('/admin/user-manager-view')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

    const setUserStatus = async(id, Status) => {
      try {
        const payload = setStatus(!Status)
        var operation = customerOperation(!Status)
        const dat = await userService.setUserStatus(id, payload)
        swal(operation, dat.message, dat.status)
        const result = dat.data
        const table = await getUser()
        setDataState(mapping(table))
      return result
      } catch (error) {
        return await CatchError(error, props, refreshToken)
      }
    }

    const Add = () => {
        props.history.push('/admin/user-manager-add')
    }

    const mapping = (TableData) => TableData.map((Data) => {
          try {
            return {
            id: Data.id,
            username: Data.username,
            email: Data.email,
            type: Data.userType,
            login: customTextFromBoolean(Data.login),
            statusData: userStatus(Data.enabled),
            organization: Data.owner,
            status: (
                  <div className="actions-center">
                  <StatusButton
                    disabled = {Permission.Update}
                    statusOnChange={ async(e) =>{
                      // handleStatusChange(e, Data.id)
                      // let obj = dataState.find((o) => o.id === Data.id);
                      statusOnChange(e)
                      await setUserStatus(Data.id, Data.enabled)
                    }}
                    status={Data.enabled}
                  />
                  </div>
            ),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>

                {/* use this button to add a edit kind of action */}
                <Button
                  disabled={!Permission.Read}
                  onClick={() => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    viewUser(Data.id)
                  }}
                  className="btn-icon btn-round"
                  color="warning"
                  id={`tooltip${Data.id}10`}
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                <UncontrolledTooltip
                    delay={0}
                    target={`tooltip${Data.id}10`}
                >Update</UncontrolledTooltip>
                {/* use this button to remove the data row */}
                <Button
                  disabled={!Permission.Delete}
                  onClick={() => {
                    deleteUser(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}11`}
                  color="danger"
                  size="sm"
                >
                  <i className="fa fa-times" />
                </Button>{" "}
                <UncontrolledTooltip
                    delay={0}
                    target={`tooltip${Data.id}11`}
                  >Delete</UncontrolledTooltip>
              </div>
            ),
          };
          } catch (error) {
            return []
          }
        })
    const dataTable = Table
    const [dataState, setDataState] = React.useState(mapping(dataTable));

    const columns = [{
                        Header: "Username",
                        accessor: "username",
                      },
                      {
                        Header: "Email",
                        accessor: "email",
                      },
                      {
                        Header: "Tipe User",
                        accessor: "type",
                      },
                      {
                        Header: "Organisasi",
                        accessor: "organization",
                      },
                      {
                        Header: "Login",
                        accessor: "login",
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                      },
                      {
                        Header: "Update  Hapus",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },]
  const menus = (
    <Row>
      <Col md={10}>
        <Button onClick={Add} color="info" disabled={!Permission.Create}>Add</Button>
      </Col>
    </Row>
  )

  return (
    <>
    <PanelHeader size="sm" />
      {/* <PanelHeader
              content={
                <div className="header text-center">
                  <h2 className="title">List User</h2>
                </div>
              }
            /> */}
      <div className="content">
        <CommonList
          key={1}
          header={'User Manager'}
          columns={columns}
          dataState={dataState}
          menu={menus}
          load={load}
          getData={getUser} />
      </div>
    </>
  )
  } catch (error) {
    return (
      <>
        <PanelHeader
                content={
                  <div className="header text-center">
                    <h2 className="title">List User</h2>
                  </div>
                }
              />
        <div className="content">
          <h3>{error.message || error}</h3>
        </div>
      </>
    )
  }
}

export default UserManagerList;
