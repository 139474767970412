import React, { useEffect } from "react";
// import CurrencyInput from "react-currency-input-field";
// reactstrap components
import LoadingScreen from "components/Loader/LoadingScreen";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";


import Select from 'react-select'
import { useState } from "react";
import {getRefreshToken, getOwner,
        getItemData, GetItemCategory, GetItemCurrency,
        GetItemMeasurement, PriceFormat, getRouterSelect,
        itemUpdate, getUserPermission
} from "./../../../variables/common"
import ItemService from "Main/ItemService";
import swal from "sweetalert";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import RouterService from "Main/RouterService";
import SelectBasic from "components/Input/SelectBasic";
import Checklist from "components/Button/Checklist";

function ItemUpdate(props) {
  const pages = 'item'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const itemService = new ItemService()
  const routerService = new RouterService()
  const refreshToken = getRefreshToken()
  var selectMeasurementOptions = GetItemMeasurement()
  var selectCategoryOptions = GetItemCategory()
  var selectCurrencyOptions = GetItemCurrency()
  const [routerSelectOption, routerSelectOptionOnChange] = useState(getRouterSelect())
  var ownerData = getOwner()
  const ItemData = getItemData()
  const Id = ItemData.itemId
  const name = useFormInput(ItemData.name)
  const [ip, ipOnChange] = useState(ItemData.ipPool)
  const [subnet, subnetOnChange] = useState(ItemData.subnetPool)
  const [upValue, upValueOnChange] = useState(ItemData.uplink)
  const [downValue, downValueOnChange] = useState(ItemData.downlink)
  const Measurement = selectMeasurementOptions.find(val => val.value === ItemData.measurement)
  const currency = useState(selectCurrencyOptions.find(val => val.name = ItemData.currency))
  const owner = ownerData.find(data => data.value === ItemData.owner)
  const [priceValue, priceValueOnChange] = useState(ItemData.price)
  const [discountValue, discountValueOnChange] = useState(ItemData.discount || 0)
  const imgUrl = ItemData.imgUrl
  const [router, routerOnChange] = useState(routerSelectOption.find(router => router.value === ItemData.routerid) || '')
  const [category , categoryOnChange] = useState(selectCategoryOptions.find(val => val.value === ItemData.category))
  const [loading, loadingOnChange ] = useState(false)
  const [useProfilePPPlist, setUseProfilePPPlist] = useState(false)
  const [pppName, setPppName] = useState(ItemData.pppProfileName)
  const [RouterpppProfile, SetRouterpppProfile] = useState({})
  const [RouterpppProfileList, SetRouterpppProfileList] = useState([])

  const SendImage = () => {
    const url = 'item/pictures/' + Id
    return (
      <>
          <Form>
            <Col xs={12} md={12}>
              <ImageUpload link = {url} image = {imgUrl}/>
            </Col>
          </Form>
      </>
    )
  }

  useEffect(() => {
    getRouter(owner.mitraid)
  }, [])

  const getRouterPPP =  async(routerId) => {
    const result =  await routerService.getpppProfileSelect(routerId)
    SetRouterpppProfileList(result.data)
    return result.data
  }

  const EditItem = async(e) => {
    swal("Update", "Data tidak dapat di Recover", "info", {
              buttons: {
              update: {text: "Update", value: 'up' },
              cancel: 'Cancel'
            }
          }
      ).then(async(value) => {
        switch (value) {
          case 'up':
            try {
              e.preventDefault()
              loadingOnChange(true)
             const payload = itemUpdate(name.value, upValue, downValue, category.value,
                          /* currency.value,*/ priceValue, discountValue, Measurement.value, router.value, ip, subnet, pppName)
              const result = await itemService.putItem(Id, payload)
              loadingOnChange(false)
              swal(result.status, result.message, 'success').then(() => {
                props.history.push('/admin/item-list')
              })
            } catch (error) {
              loadingOnChange(false)
              return await CatchError(error, props, refreshToken)
            }
            break;

          default:
            // swal('Cancel', 'Update Item Cancel', 'info')
            break;
        }
      })
    }

   const getRouter = async(mitraid) => {
    // sessionStorage.removeItem('urban')
    const dat = await routerService.getRouterSelect(mitraid)
    const result = dat.data
    routerSelectOptionOnChange(result)
    const selected = result.find(router => router.value === ItemData.routerid)
    routerOnChange(selected)
    return result
  }


  const children = (<div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">{name.value}</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                     <Row>
                      <Col className="pr-1" md="10">
                        {SendImage()}
                        <FormGroup>
                          <div>
                          <Label>Lokasi Router</Label>
                          <Select
                            placeholder="Lokasi Router"
                            name="singleSelect"
                            value = {router}
                            options={routerSelectOption}
                            onChange={(value) => routerOnChange(value)}
                          />
                          {!router.status ? (
                              <label className="error">
                                Router Offline
                              </label>
                            ) : <label className="success">
                                Router Online
                              </label>}
                          </div>
                          <Label>Name</Label>
                          <Input
                            {...name}
                            placeholder="Nama"
                            type="text"
                          />
                          {!useProfilePPPlist ? (
                            <>
                              <Label>Nama PPP-Profile</Label>
                              <Input
                                value = {pppName}
                                disabled = {!useProfilePPPlist}
                                onChange={e => setPppName(e)}
                                placeholder="PPP-Profile"
                                type="text"
                              />
                           </>
                              ) : (
                                <>
                                <Label>PPOE Profile List</Label>
                                <SelectBasic
                                  label={" Select PPP From Router"}
                                  value={RouterpppProfile}
                                  option={RouterpppProfileList}
                                  onChange={(e) => {
                                    SetRouterpppProfile(e)
                                    setPppName(e.value)
                                    ipOnChange(e.ip)
                                    subnetOnChange(Number(e.subnet))
                                    }} />
                                </>
                              )
                          }
                          <Label>IP</Label>
                              <Input
                                value = {ip}
                                disabled = {useProfilePPPlist}
                                onChange = {(e) => {
                                  if (e.target.value.length <= 15) {
                                    ipOnChange(e.target.value)
                                  }
                                }}
                                placeholder="IP POOL"
                                type="text"
                              />
                              <Label>Subnet CIDR</Label>
                              <Input
                                value = {subnet}
                                disabled = {useProfilePPPlist}
                                placeholder="Subnet CIDR"
                                type="number"
                                onChange={(e) => {
                                  if (e.target.value <= 32) {
                                    subnetOnChange(e.target.value)
                                  }
                                }}
                              />
                          <Checklist
                              text = {"Memakai PPP Profile Router"}
                              value = {useProfilePPPlist}
                              onChange = { async (e) => {
                                setUseProfilePPPlist(e.target.checked)
                                setPppName('')
                                ipOnChange('')
                                subnetOnChange(24)
                                if (!e.target.checked) {
                                  SetRouterpppProfile([])
                                  return e
                                }
                                await getRouterPPP(router.value)
                              }}
                            />
                          <Label>Mitra</Label>
                          <Select
                            placeholder="Mitra"
                            name="singleSelect"
                            value = {owner}
                            onChange={() => {}}
                          />
                          <Label>Pengukuran</Label>
                          <Select
                            placeholder="Pengukuran"
                            name="singleSelect"
                            value = {Measurement}
                            onChange={() => {}}
                          />
                          <Label>Mata Uang</Label>
                          <Select
                            placeholder="Mata Uang"
                            name="singleSelect"
                            value = {currency}
                            onChange={() => {}}
                          />
                          <Label>Kategori</Label>
                          <Select
                            placeholder="Kategori"
                            name="singleSelect"
                            value = {category}
                            options={selectCategoryOptions}
                            onChange={(value) => categoryOnChange(value)}
                          />
                          <Label>Kecepatan Download</Label>
                          <Input
                            placeholder="Kecepatan Download"
                            value = {downValue}
                            onChange = {(e) => {
                              if (e.target.value >= 0) {
                                downValueOnChange(e.target.value)
                              }
                            }}
                            type="number"
                          />
                          <Label>Kecepatam Upload</Label>
                          <Input
                            placeholder="Kecepatam Upload"
                            value = {upValue}
                            onChange = {(e) => {
                              if (e.target.value >= 0) {
                                upValueOnChange(e.target.value)
                              }
                            }}
                            type="number"
                          />
                          <Label>Harga</Label>
                          <Input
                            placeholder="Harga"
                            value = {priceValue}
                            onChange = {(e) => {
                              if (e.target.value >= 0) {
                                priceValueOnChange(e.target.value)
                              }
                            }}
                            type="number"
                          />
                          <Label>Diskon</Label>
                          <Input
                            placeholder="Presentase Diskon"
                            value = {discountValue}
                            onChange = { (e) => {
                              if (e.target.value >= 0 && e.target.value <= 100) {
                                discountValueOnChange(e.target.value)
                              }
                            }
                            }
                            type="number"
                          />
                          <Label>Harga Final</Label>
                          <Input
                            placeholder="Harga"
                            value={PriceFormat(Number(priceValue - ((discountValue/100)*priceValue)), 'IDR')}
                            type="text"
                            disabled
                            onChange={() => {}}
                          />
                        </FormGroup>
                      </Col>
                      </Row>
                  </Form>
                </CardBody>
              </Card>
            <Button onClick = {(e) => {EditItem(e)}} color="info" disabled = {!Permission.Update}>Update</Button>
            </Col>
          </Row>
        </div>
      )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen children={children} loading = {loading} text={"update Data"}/>
      </>
    );
}


export default ItemUpdate;
