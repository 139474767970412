export const columns = [
    {
        Header: "ID",
        accessor: "id",
    },
    {
        Header: "Nama",
        accessor: "name",
    },
    {
        Header: "Mitra",
        accessor: "owner",
    },
    {
        Header: "Kategori",
        accessor: "category",
    },
    {
        Header: "Router",
        accessor: "router",
    },
    {
        Header: "Total Harga",
        accessor: "finalPrice",
    },
    {
        Header: "Update Delete",
        accessor: "actions",
        sortable: false,
        filterable: false,
    },
]