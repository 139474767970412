import React, { useRef } from 'react';
import DeckGL, { GeoJsonLayer, LineLayer, ScatterplotLayer, TerrainLayer, TextLayer } from 'deck.gl';
import { naturalEarthGeoData_50m, naturalEarthGeoData_10m,
  MAP_STYLE, radiusSize, typeColor, TERRAIN_DATA } from 'variables/GeoData';
import swal from 'sweetalert';

const { LAND, COASTLINE, RIVERS_LAKE_CENTERLINE,
ADMIN_0_COUNTRIES_LAKES, LAKES } = naturalEarthGeoData_50m
const {
  GRATICULES5,
  ADMIN_BOUNDARY_LINES_LAND,
  GEOGRAPHY_REGION_POINTS,
 } = naturalEarthGeoData_10m

export function DeckGLMap(props) {
  const deckRef = useRef(null);
  const {INITIAL_VIEW_STATE, NETWORK_DEVICE, onClick} = props

  const { latitude, longitude } = INITIAL_VIEW_STATE || {}
  const DEFAULT_INITIAL_VIEW_STATE = {
    latitude: latitude || 0,
    longitude: longitude || 0,
    zoom: 15,
    bearing: 0,
    pitch: 0,
    controller: true
  }

  const { longitude: defaultLong, latitude: defaultLat } = DEFAULT_INITIAL_VIEW_STATE
  const initialState = [{coordinates: [defaultLong, defaultLat]}]

  const topologyState = NETWORK_DEVICE || initialState
  const zoomThreshold = 15

  const onClickDefault = (info) => {
    const { object: data } = info

    if (!!data) {
      swal(`Name: ${data.name}\n
       Type: (${data.type})\n
       SN: ${data.sn}\n
       Coordinate: ${data.coordinates},
       Target Coordinate: ${data.targetCoordinates}
       Description: ${data.description}`)
    }
  }

  const onClickFinal = async (data) => {
    if (typeof onclick === 'function') {
      return onClick(data)
    }
    return await onClickDefault(data)
  }

  return (
    <div>
      <DeckGL
        controller={true}
        ref={deckRef}
        // views = {new OrbitView()}
        canvas = {deckRef.current}
        container = {deckRef.current}
        mapStyle = {MAP_STYLE}
        initialViewState={DEFAULT_INITIAL_VIEW_STATE}>
          {/* Base Layer */}
          <GeoJsonLayer
            id="base-map-layer"
            data={LAND}
            stroked={true}
            filled={true}
            lineWidthMinPixels={1}
            opacity={0.7}
            getLineColor={[60, 60, 60]}
            getFillColor={[200, 200, 150]}
          />
          <GeoJsonLayer
            id="base-coast-layer"
            data={COASTLINE}
            stroked={true}
            filled={true}
            lineWidthMinPixels={1}
            opacity={0.4}
            getFillColor={[200, 100, 200]}
          />
          <TerrainLayer
            id="3d-terrain-layer"
            elevationDecoder = {TERRAIN_DATA.elevationDecoder}
            elevationData = {TERRAIN_DATA.elevationData}
            texture = {TERRAIN_DATA.texture}
            bounds = {TERRAIN_DATA.bounds}
          />
          <GeoJsonLayer
            id="base-river-centerline-layer"
            data={RIVERS_LAKE_CENTERLINE}
            stroked={true}
            filled={true}
            lineWidthMinPixels={2}
            opacity={0.5}
            getFillColor={[200, 200, 225]}
          />
          <GeoJsonLayer
            id="base-country-lakes-layer"
            data={ADMIN_0_COUNTRIES_LAKES}
            stroked={true}
            filled={true}
            lineWidthMinPixels={1}
            opacity={0.4}
            getFillColor={[200, 200, 150]}
          />
          <GeoJsonLayer
            id="base-lakes-layer"
            data={LAKES}
            stroked={true}
            filled={true}
            lineWidthMinPixels={1}
            opacity={0.4}
            getFillColor={[200, 200, 260]}
          />
          <GeoJsonLayer
            id="geo-boundary-layer"
            data={ADMIN_BOUNDARY_LINES_LAND}
            stroked={true}
            filled={true}
            lineWidthMinPixels={1}
            opacity={1}
          />
          <TextLayer
            id="region-point-layer"
            data={GEOGRAPHY_REGION_POINTS}
            filled={true}
            pointRadiusMinPixels={2}
            pointRadiusScale={500}
            getFillColor={[200, 0, 80, 180]}
            pickable={true}
            autoHighlight={true}
          />
          <GeoJsonLayer
            id="graicule-layer"
            data={GRATICULES5}
            stroked={true}
            filled={true}
            lineWidthMinPixels={1}
            getLineColor = {[250, 250, 240]}
            opacity={1}
          />
          {/* Data Layer */}
          <ScatterplotLayer
            id="locationLayer-layer"
            dataTransform={d => d.filter(f => !f.coordinateUnavailable)}
            pointRadiusMinPixels={1}
            pointRadiusScale={100}
            autoHighlight={true}
            data = {topologyState}
            pickable = {true}
            opacity = {0.4}
            stroked = {true}
            filled = {true}
            radiusScale = {10}
            radiusMinPixels = {1}
            radiusMaxPixels = {50}
            lineWidthMinPixels = {0}
            getPosition = {d => d.coordinates}
            getRadius = {radiusSize}
            getFillColor = {typeColor}
            getLineColor = {typeColor}
            onClick={onClickFinal}
          />
          <TextLayer
            id = "text-layer"
            data = {topologyState}
            pickable = {true}
            getPosition = {d => d.coordinates}
            getText = {d => `${d.name} (${d.type})`}
            getAngle = {0}
            getAlignmentBaseline = {'center'}
            getSize = {() => {return !deckRef.current ? 0.3 : Math.floor(deckRef.current.deck.viewState.zoom) < zoomThreshold ? 0.3 : 1}}
            sizeUnits = {'meters'}
            getPixelOffset = {[8, 10]}
            getTextAnchor = {() => {return !deckRef.current ? 'middle' : Math.floor(deckRef.current.deck.viewState.zoom) < zoomThreshold ? 'start' : 'middle'}}
          />
          {/* lineLayer */}
          <LineLayer
            id="line-topology-layer"
            data={topologyState}
            dataTransform={d => d.filter(f => !f.coordinateUnavailable)}
            getSourcePosition={f => f.targetCoordinates}
            getTargetPosition={f => f.coordinates}
            getSourceColor={[0, 128, 200]}
            getTargetColor={[200, 0, 80]}
            getWidth={1}
          />
      </DeckGL>
    </div>
  );
}