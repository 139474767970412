/*eslint-disable*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Row,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {getRefreshToken} from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import RouterService from "./../../../Main/RouterService"
import { addRouterData } from "variables/common";
import { customTextFromBoolean } from "Main/Utils";
import { nullCompensation } from "Main/Utils";
import { addItemRouterList } from "variables/common";
import CommonList from "components/Table/CommonList";
import { getUserPermission } from "variables/common";

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}
function RouterList(props) {
  const pages = 'resource'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const routerService = new RouterService()
  const ItemLen = useFormInput('ALL')
  const refreshToken = getRefreshToken()
  const [loaded, setDataLoaded] = useState(true)
  const [dataTable, dataTableOnChange] = useState([])

  try {
    const getRouter =  async() => {
      try {
        setDataLoaded(true)
        const len = ItemLen.value
        const dat =  await routerService.getAllRouter(len)
        const result = dat.data

        setDataState(render(result))
        dataTableOnChange(result)
        setDataLoaded(false)
        return result
      } catch (error) {
        return await CatchError(error, props, refreshToken)
      }
    }

    useEffect(() => {
      getRouter()
     }, [])

    const deleteRouter = async (Id) => {
      const value = await swal("Delete", "Router Data Can't Recover", "info", {
        buttons: {
          cancel: 'Cancel',
          delete: { text: "Delete", value: 'del' }
        }
      }
      );
      switch (value) {
        case 'del':
          try {
            setDataLoaded(true)
            const respomse = await routerService.deleteRouter(Id);
            await swal(respomse.status, respomse.message, 'success')
            const state = await getRouter()
            const stateRaw = render(state)
            const newState = stateRaw.filter((data) => data.id !== Id)
            setDataState(newState);
            setDataLoaded(false)
            return true;
          } catch (error) {
            setDataLoaded(false)
            return await CatchError(error, props, refreshToken);
          }
          break;

        default:
          await getRouter();
          return false;
      }
    }

    const viewRouter = async(id)=> {
      try {
        const dat = await routerService.getRouter(id)
        const result = dat.data
        const len = `ALL`
        const datProfile =  await routerService.getAllItemByRouterid(id, len)
        const resultProfile = datProfile.data
        addItemRouterList(resultProfile)
        addRouterData(result)
        props.history.push('/admin/router-update')
      } catch (error) {
        return await CatchError(error, props, refreshToken)
        }
    }

    const testConnection = async (id) => {
      try {
        const result = await routerService.testRouter(id)
        await swal('connected', result.message, 'success')
        const table = await getRouter()
        const state = render(table)
        setDataState(state)
      } catch (error) {
        const err = error.response
        await swal('failed', err.data.message, 'warning')
        const table = await getRouter()
        const state = render(table)
        setDataState(state)
      }
    }

    const render = (Table) => Table.map((Data) => {
          try {
            console.log(Data.connection)
            return {
            id: Data.id,
            username: Data.name,
            owner: Data.mitra_name,
            host: Data.ip_address,
            port: Data.port,
            location: Data.urban,
            routerName: nullCompensation(Data.routerName),
            connection: customTextFromBoolean(Data.connection, 'Connected', 'Disconnect'),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={() => testConnection(Data.id)
                  }
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}7`}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-message" >Test Connection</i>
                </Button>{" "}
                {/* use this button to add a edit kind of action */}
                <Button
                  disabled = {!Permission.Read}
                  onClick={() => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    viewRouter(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}8`}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                {/* <UncontrolledTooltip
                  delay={0}
                  target={`tooltip${Data.id}8`}
                >Update</UncontrolledTooltip> */}
                {/* use this button to remove the data row */}
                <Button
                  disabled = {!Permission.Delete}
                  onClick={async () => {
                      await deleteRouter(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}9`}
                  color="danger"
                  size="sm"
                >
                  <i className="fa fa-times" />
                </Button>{" "}
                {/* <UncontrolledTooltip
                  delay={0}
                  target={`tooltip${Data.id}9`}
                >Delete</UncontrolledTooltip> */}
              </div>
            ),
          };
          } catch (error) {
            return []
          }
        })

    const [dataState, setDataState] = React.useState(
        render(dataTable)
      );

    const Add = () => {
      props.history.push('/admin/router-page')
    }

    const columns = [
        {
          Header: "Nama Router",
          accessor: "routerName",
        },
        {
          Header: "Lokasi",
          accessor: "location",
        },
        {
          Header: "Owner",
          accessor: "owner",
        },
        {
          Header: "Username",
          accessor: "username",
        },
        {
          Header: "Host",
          accessor: "host",
        },
        {
          Header: "Port",
          accessor: "port",
        },
        {
          Header: "Connection",
          accessor: "connection",
        },
        {
          Header: "Update Hapus",
          accessor: "actions",
          sortable: false,
          filterable: false,
        },
      ]

    return (
        <>
        <PanelHeader size="sm" />
          {/* <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Router</h2>
              </div>
            }
          /> */}
          <div className="content">
            <Row>
              <CommonList
                key={1}
                header={'Router'}
                columns={columns}
                dataState={dataState}
                Add={Add}
                addDisabled={Permission.Create}
                load={loaded}
                getData={getRouter}
              />
            </Row>
          </div>
        </>
      );
  } catch (error) {
    return (
      <>
      <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Router</h2>
              </div>
            }
          />
      <h3>{error.message || error}</h3>
      </>
    )
  }
}

export default RouterList;
