import React, { useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Select from 'react-select'
import { useState } from "react";
import {user, getRefreshToken, addUserList,
  getUserData, getUserType, getRole, permission, getOwner} from "./../../../variables/common"
import UserService from "Main/UserService";
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { validatePermission,  verifyLength, useFormInput } from "Main/Utils";
import SelecttWithUserTier from "components/Input/SelectWithUserTier";
import LoadingScreen from "components/Loader/LoadingScreen";

function UserManagerPage(props) {
  const userService = new UserService()
  const refreshToken = getRefreshToken();
  const userData = getUserData()
  const [minLengthState, setminLengthState] = React.useState("");
  const [minLength, setminLength] = React.useState("");
  const [userType, userTypeOnChange] = useState('')
  const [dataLoad, setDataLoad] = useState('')
  const organization = userData.organization
  const name = useFormInput('')
  const email = useFormInput('')
  const firstname = useFormInput('')
  const lastname = useFormInput('')
  const [password, passwordOnChange] = useState('')
  const [Enable, EnableOnChage] = useState(false)
  const [passwordPassed, passwordPassedOnChange] = useState(true)
  const [role, roleOnChange] = useState()
  const [create, createOnChange] = useState(false)
  const [read, readOnChange] = useState(false)
  const [update, updatedOnChange] = useState(false)
  const [deleted, deletedOnChange] = useState(false)
  var roleOptions = getRole()
  var userTypeOptions = getUserType()
  const userTypeFilter = userTypeOptions.filter((type) => type.value !== 'SUPERADMIN')
  const [passwordShown, setPasswordShown] = useState(false);
  const ownerData = getOwner()
  // const bankOptionMaster = getBankSelect()
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === organization))
  const [ changeMitra, setChangeMitra ] = useState(false)

  useEffect(() => {
    validatePermission(props, 3, 1)
  }, [])

  const getUser =  async() => {
        const len = 'ALL'
        const result =  await userService.getAllUser(len)
        addUserList(result.data)
  }

  const submitPermission = async() => {
    try {
      setDataLoad(true)
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = permission(name.value, role.value, create, read, update, deleted)
      const result = await userService.addPermission(payload)
      await getUser()
      await swal(result.status, result.message, "success")
      setDataLoad(false)
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  }
  const addPermissionCard = () => {
    return (
      <>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">User Permission</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  <Row>
                    <Label sm="2">Permission Role</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Type"
                            name="singleSelect"
                            value = {role}
                            options = {roleOptions}
                            onChange={e => roleOnChange(e)
                          }
                          />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Permission</Label>
                    <Col className="checkbox-radios" sm="10">
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" value={create} onChange={e => createOnChange(e.target.checked)}/>
                          <span className="form-check-sign" />
                          Create
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" value={read} onChange={e => readOnChange(e.target.checked)}/>
                          <span className="form-check-sign" />
                          Read
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" value={update} onChange={e => updatedOnChange(e.target.checked)}/>
                          <span className="form-check-sign" />
                          Update
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" value={deleted} onChange={e => deletedOnChange(e.target.checked)}/>
                          <span className="form-check-sign" />
                          Delete
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button onClick = {submitPermission} color="info">Add Permission</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
      </>
    )
  }
  const submitUser = async() => {
    try {
      setDataLoad(true)
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
    const payload = user(name.value, email.value,password, firstname.value, lastname.value,
                    Enable, userType.value, owner.value)
      const result = await userService.addUser(payload)
      await getUser()
      await swal("Success", "User Berhasil di tambahkan", "success")
      props.history.push('/admin/user-manager-list')
      setDataLoad(false)
     return result
    } catch (error) {
      setDataLoad(false)
      return CatchError(error, props, refreshToken)
    }
  }
  const togglePassword = () => {
      // When the handler is invoked
      // inverse the boolean state of passwordShown
      setPasswordShown(!passwordShown);
  };

  const cleanOption = () => {}

  const child = (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Form User Manager</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <Label>Username *</Label>
                          <Input
                            placeholder="Username"
                            {...name}
                            type="text"
                          />
                          <SelecttWithUserTier
                              key={"1xcc"}
                              label = {'User Mitra'}
                              value = {owner}
                              onChange = {ownerOnChange}
                              disabled = {changeMitra}
                              options = {ownerData}
                              subFunction = {cleanOption}
                              // subFunction = {deleteSelect}
                              checkText = {'Ubah Mitra'}
                              checkValue = {changeMitra}
                              checkValueOnChange = {setChangeMitra}
                            />
                        <div>
                         <Label>Email *</Label>
                          <Input
                            placeholder="Email"
                            {...email}
                            type="email"
                          />
                          </div>
                          <FormGroup>
                            <div>
                            <Label>Password</Label>
                            <Input
                              name="min_length_password"
                              type={passwordShown ? "text" : "password"}
                              placeholder="Password"
                              max={16}
                              value = {password}
                              onChange={(e) => {
                                  passwordOnChange(e.target.value)
                                if (!verifyLength(e.target.value, 8)) {
                                  setminLengthState("has-danger");
                                  passwordPassedOnChange(true)
                                } else {
                                  setminLengthState("has-success");
                                  passwordPassedOnChange(false)
                                }
                                setminLength(e.target.value);
                              }}
                            />
                            {minLengthState === "has-danger" ? (
                              <label className="error">
                                Kamu baru mengisi {minLength.length} Karakter, Isi minimal 8 Karakter dan Maksimal 16 Karakter.
                              </label>
                            ) : null}
                          </div>
                          <FormGroup check>
                            <Label check> Show
                              <Input type="checkbox"
                              value={passwordShown}
                              onChange={togglePassword}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                          </FormGroup>
                          <Label>Nama Depan *</Label>
                          <Input
                            placeholder="Nama Depan"
                            {...firstname}
                            type="text"
                          />
                          <Label>Nama Belakang *</Label>
                          <Input
                            placeholder="Nama Belakang"
                            {...lastname}
                            type="text"
                          />
                          <Label>Nama Lengkap </Label>
                          <Input
                            placeholder="Nama Lengkap"
                            value = {`${firstname.value} ${lastname.value}`}
                            type="text"
                          />
                        <label>Jenis User</label>
                        <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Jenis User"
                            name="singleSelect"
                            value = {userType}
                            options = {userTypeFilter}
                            onChange={e => userTypeOnChange(e)
                          }
                          />
                          <Label>Doctype</Label>
                          <Input
                            placeholder="Doctype"
                            value = 'User'
                            type="text"
                          />
                          <FormGroup check>
                            <Label check> Aktif User
                              <Input type="checkbox"
                              value = {Enable}
                              onChange = {e => EnableOnChage(e.target.checked)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {submitUser} disabled = {passwordPassed} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {addPermissionCard()}
      </>
    );

    return (
    <>
      <LoadingScreen children={child} loading = {dataLoad} text={"Submit New User"}/>
    </>
  )
}


export default UserManagerPage;


