/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {getRefreshToken,
   PriceFormat} from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import CommonList from "../../../../components/Table/CommonList";
import {columns} from "./ItemAddOnColumns"
import { getUserPermission } from "variables/common";
import { getItemAddOnList } from "variables/Add-On/ItemAddOn";
import { addItemAddOnList } from "variables/Add-On/ItemAddOn";
import ItemAddOnService from "Main/Add-On/ItemAddOnService";
import { addItemAddOnData } from "variables/Add-On/ItemAddOn";

function ItemAddOnList(props) {
  const pages = 'billing'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const itemAddOnService =  new ItemAddOnService()
  const ItemLen = useFormInput('ALL')
  const refreshToken = getRefreshToken()
  const [dataTable, setDataTable] = useState(getItemAddOnList())
  const [loaded, setDataLoaded] = useState(true)

  try {
    const getItemAddon =  async() => {
      try {
        setDataLoaded(true)
        const len = ItemLen.value
        const dat =  await itemAddOnService.getAllData(len)
        const result = dat.data
        addItemAddOnList(result)
        setDataTable(result)
        setDataState(mappingData(result))
        setDataLoaded(false)
        return result
      } catch (error) {
        setDataLoaded(false)
        return await CatchError(error, props, refreshToken)
      }
    }

    useEffect(() => {
      getItemAddon()
     }, [])

    const deleteItemAddOn = (Id) => {
      swal("Delete", "Data Can't Recover", "info", {
          buttons: {
          cancel: 'Cancel',
          delete: {text: "Delete", value: 'del' }
        }
      }
      ).then( async(value) => {
        switch (value) {
          case 'del':
            try {
              setDataLoaded(true)
              const noRouter = false
              const respomse = await itemAddOnService.deleteData(Id, noRouter)
              setDataLoaded(false)
              await swal(respomse.status, respomse.message, 'success')
              const state = await getItemAddon()
              const stateRaw = mappingData(state)
              const newState = stateRaw.filter((data) => data.id !== Id)
              setDataState(newState)
              return true
            } catch (error) {
              setDataLoaded(false)
              return await CatchError(error, props, refreshToken)
            }

          default:
            await getItemAddon()
            return false
        }
      })
    }

    const viewItemAddOn = async(id)=> {
      try {
        const dat = await itemAddOnService.getData(id)
        const result = dat.data
        addItemAddOnData(result)
        props.history.push('/admin/item-add-on-update')
      } catch (error) {
        return await CatchError(error, props, refreshToken)
        }
    }

    const mappingData = (data) => data.map((Data) => {
          try {
            return {
            id: Data.id,
            name: Data.name,
            owner: Data.owner,
            category: Data.category,
            value: `${Data.value} ${Data.measurement}`,
            router: Data.routerName,
            finalPrice: PriceFormat(Data.final_price, Data.currency.toUpperCase()),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>

                {/* use this button to add a edit kind of action */}
                <Button
                disabled={!Permission.Read}
                  onClick={async () => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    await viewItemAddOn(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}8`}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                {/* use this button to remove the data row */}
                <Button
                disabled={!Permission.Delete}
                  onClick={async () => {
                    await deleteItemAddOn(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}9`}
                  color="danger"
                  size="sm"
                >
                  <i className="fa fa-times" />
                </Button>{" "}
              </div>
            ),
          };
          } catch (error) {
            return []
          }
        })

  const [dataState, setDataState] = useState(mappingData(dataTable))

  const Add = () => {
    props.history.push('/admin/item-add-on-page')
  }

  return (
        <>
        <PanelHeader size="sm" />
          {/* <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Produk Add-On</h2>
              </div>
            }
          /> */}
          <div className="content">
            <Row>
              <Col xs={12} md={12}>
                <Card>
                    <CommonList
                      key={1}
                      header={'Add-On Product'}
                      columns={columns}
                      dataState={dataState}
                      Add={Add}
                      addDisabled={Permission.Create}
                      load={loaded}
                      getData={getItemAddon}
                    />
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
  } catch (error) {
    swal('Error', error.message, 'warning')
    // props.push('/admin')
    return (
        <>
          <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Produk Add-On</h2>
              </div>
            }
          />
          <h2>{error.message}</h2>
        </>
      );
  }
}

export default ItemAddOnList;
