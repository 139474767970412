import Dashboard from "views/Dashboard/Dashboard.js";
// Outside Template
//User
import LoginPage from "views/Pages/User/LoginPage.js";
import UserPage from "views/Pages/User/UserPage.js";
import userUpdate from "views/Pages/User/UserUpdate"
// Customer
import customerViewUpdate from "views/Pages/Customer/CustomerViewUpdate"
import customerPage from "views/Pages/Customer/CustomerPage"
import CustomerLists from "views/Pages/Customer/CustomerLists"
// Billing
import AddBilling from "views/Pages/Subscription/AddBilling"
import BillingUpdate from "views/Pages/Subscription/BillingUpdate"
import BillingList from "views/Pages/Subscription/BillingLists"
// Billing Address
import BillingAddressViewUpdate from "views/Pages/Address/BillingAddessUpdate"
import AddBillingAddress from "views/Pages/Address/AddBillingAddress"
import BillingAddressLists from "views/Pages/Address/BillingAddressLists";
// Invoice
import AddInvoice from "views/Pages/Invoice/AddInvoice"
import InvoiceUpdate from "views/Pages/Invoice/InvoiceUpdate"
import InvoiceList from "views/Pages/Invoice/InvoiceList"

// Item
import ItemLists from "views/Pages/Item/ItemList"
import AddItem from "views/Pages/Item/AddItem"
import ItemViewUpdate from "views/Pages/Item/ItemUpdate"

// Error
import ForbiddenError from "views/Pages/ErrorPage/ForbiddenErrorPage"
import ServerError from "views/Pages/ErrorPage/ServerErrorPage"

// User Manager
import UserManagerList from "views/Pages/UserManager/UserManagerLists";
import UserManagerPage from "views/Pages/UserManager/UserManagerPage";
import UserManagerView from "views/Pages/UserManager/UserManagerUpdate";

// Email Sender
import EmailPage from "views/Pages/Subscription/EmailPage"

// Mitra
import MitraForm from "views/Pages/Mitra/MitraPage"
import MitraList from "views/Pages/Mitra/MitraLists"
import MitraUpdate from "views/Pages/Mitra/MitraViewUpdate"

//Reesource
import ResourecForm from "views/Pages/ResourcesLayout/ResourcePage"

//Bank Account
import BankAccountList from "views/Pages/BankAccount/BankAccountLists"
import BankAccountPage from "views/Pages/BankAccount/BankAccountPage"
import BankAccountView from "views/Pages/BankAccount/BankAccountViewUpdate"
// Virtual Account
import VirtualAccountPage from "views/Pages/VirtualAccount/VirtualAccountPage";
import VirtualAccountView from "views/Pages/VirtualAccount/VirtualAccountView";
// History
import SubscriptionHistory from "views/Pages/Subscription/SubscriptionHistoryView"

//Datalog
import DatalogList from "views/Pages/Datalog/DatalogList"
import DatalogView from "views/Pages/Datalog/DatalogView"

// Global Template
import GlobalTemplateList from "views/Pages/GlobalTemplate/GlobalTemplateLists"
import GlobalTemplatePage from "views/Pages/GlobalTemplate/GlobalTemplatePage"
import GlobalTemplateView from "views/Pages/GlobalTemplate/GlobalTemplateViewUpdate"

// Router Manager
import RouterList from "views/Pages/RouterManager/RouterList"
import RouterUpdate from "views/Pages/RouterManager/RouterUpdate"
import RouterAdd from "views/Pages/RouterManager/AddRouter";

// Router manager inventory
import GridPoleInventoryPage from "views/Pages/DeviceInventory/GridPole/GridPoleInventoryPage";
import GridPoleDeviceInventoryView from "views/Pages/DeviceInventory/GridPole/GridPoleDeviceInventoryView";
import IndoorDeviceInventoryPage from "views/Pages/DeviceInventory/IndoorDevice/IndoorDeviceInventoryPage";
import IndoorDeviceInventoryView from "views/Pages/DeviceInventory/IndoorDevice/IndoorDeviceInventoryView";
import OutdoorDeviceInventoryPage from "views/Pages/DeviceInventory/OutdoorDevice/OutdoorDeviceInventoryPage";
import OutdoorDeviceInventoryView from "views/Pages/DeviceInventory/OutdoorDevice/OutdoorDeviceInventoryView";

// Phone Code Manager
// import PhoneCodeManagerList from "views/Pages/PhoneCodeManager/PhoneCodeManagerLists"
import PhoneCodeManagerPage from "views/Pages/PhoneCodeManager/PhoneCodeManagerPage"
import PhoneCodeManagerViewUpdate from "views/Pages/PhoneCodeManager/PhoneCodeManagerViewUpdate"

// Logistic
import LogisticItemSubmit from "views/Pages/LogisticItem/LogisticItemSubmit"
import LogisticItemList from "views/Pages/LogisticItem/LogisticItemLists"
import LogisticItemUpdate from "views/Pages/LogisticItem/LogisticItemUpdate"

import LogisticSupplierSubmit from "views/Pages/LogisticSupplier/LogisticSupplierSubmit"
import LogisticSupplierList from "views/Pages/LogisticSupplier/LogisticSupplierLists"
import LogisticSupplierUpdate from "views/Pages/LogisticSupplier/LogisticSupplierUpdate"

import LogisticWarehouseSubmit from "views/Pages/LogisticWarehouse/LogisticWarehouseSubmit"
import LogisticWarehouseList from "views/Pages/LogisticWarehouse/LogisticWarehouseLists"
import LogisticWarehouseUpdate from "views/Pages/LogisticWarehouse/LogisticWarehouseUpdate"

import LogisticGoodsSubmit from "views/Pages/LogisticItem/LogisticGoods/LogisticGoodsSubmit"
import LogisticGoodsUpdate from "views/Pages/LogisticItem/LogisticGoods/LogisticGoodsUpdate"
import LogisticGoodsCustomerUpdate from "views/Pages/LogisticItem/LogisticGoods/LogisticGoodsCustomerUpdate"
import TransferCustomerToWarehouse from "views/Pages/LogisticItem/LogisticGoods/TransferCustomerToWarehouse";
import TransferWarehouseToCustomer from "views/Pages/LogisticItem/LogisticGoods/TransferWarehouseToCustomer";
import LogisticExport from "views/Pages/LogisticWarehouse/LogisticExport";
import TransferWarehouseToWarehouse from "views/Pages/LogisticItem/LogisticGoods/TransferWarehouseToWarehouse"
import TransferBlobCustomerToWarehouse from "views/Pages/LogisticItem/LogisticGoods/BlobLogistic/TransferBlobCustomerToWarehouse";
import TransferBlobWarehouseToCustomer from "views/Pages/LogisticItem/LogisticGoods/BlobLogistic/TransferBlobWarehouseToCustomer";
import TransferBlobWarehouseToWarehouse from "views/Pages/LogisticItem/LogisticGoods/BlobLogistic/TransferBlobWarehouseToWarehouse";
import LogisticGoodsBlobPage from "views/Pages/LogisticItem/LogisticGoods/BlobLogistic/LogisticGoodsBlobSubmit";
import SubmissionFinanceList from "views/MitraSubmission/SubmissionFinance/SubmissionFinanceLists";
import SubmissionFinancePage from "views/MitraSubmission/SubmissionFinance/SubmissionFinancePage";
import SubmissionFinanceView from "views/MitraSubmission/SubmissionFinance/SubmissionFinanceUpdate";
import SubmissionLogisticList from "views/MitraSubmission/SubmissionLogistic/SubmissionLogisticLists";
import SubmissionLogisticPage from "views/MitraSubmission/SubmissionLogistic/SubmissionLogisticPage";
import SubmissionLogisticUpdate from "views/MitraSubmission/SubmissionLogistic/SubmissionLogisticUpdate";
import FinanceStatementList from "views/Pages/FinanceStatement/FinaneStatementList";
import FinanceStatementDetail from "views/Pages/FinanceStatement/FinanceStatementDetail";
import MessageBroadcastPage from "views/Pages/CRM/MessageBroadcast";
import ItemAddOnList from "views/Pages/Add-On/ItemAddOn/ItemAddOnList";
import ItemAddOnPage from "views/Pages/Add-On/ItemAddOn/AddItemAddOn";
import ItemAddOnUpdate from "views/Pages/Add-On/ItemAddOn/ItemAddOnUpdate";
import SubscriptionAddOnPage from "views/Pages/Add-On/SubscriptionAddOn/SubscriptionAddOnPage";
import SubscriptionAddOnUpdate from "views/Pages/Add-On/SubscriptionAddOn/SubscriptionAddOnUpdate";
import SubscriptionAddOnHistoryPage from "views/Pages/Add-On/SubscriptionHistoryAddOn/SubscriptionAddOnHistoryPage";
import SubscriptionAddOnHistoryView from "views/Pages/Add-On/SubscriptionHistoryAddOn/SubscriptionAddOnHistoryUpdate";
import FinacneOutflowList from "views/MitraSubmission/FinanceOutflow/FinanceOutflowLists";
import FinanceOutflowPage from "views/MitraSubmission/FinanceOutflow/FinanceOutflowPage";
import FinanceOutflowView from "views/MitraSubmission/FinanceOutflow/FinanceOutflowUpdate";
import ErpNextClientList from "views/Pages/ErpNextClient/ErpNextClientLists";
import ErpNextClientPage from "views/Pages/ErpNextClient/ErpNextClientPage";
import ErpNextClientUpdate from "views/Pages/ErpNextClient/ErpNextClientUpdate";
import MessageLogDetail from "views/Pages/MessageLog/MessageLogDetail";
import MessageLogList from "views/Pages/MessageLog/MessageLogLists";
import ExportInvoice from "views/Pages/Invoice/InvoiceExport";
import TopologyMap from "views/Pages/Topology/TopologyMap";
import NotFoundPage from "views/Pages/ErrorPage/NotFoundPage";
import WaGatewayPage from "views/Pages/WA_Gateway/WaGatewayPage";
import InvoiceMailSender from "views/Pages/Invoice/InvoiceMailSender";
import ItemExport from "views/Pages/Item/ItemExport";
import HostpotProfileList from "views/Pages/HostpotVoucher/HostpotProfile/HostpotProfileLists";
import HostpotProfilePage from "views/Pages/HostpotVoucher/HostpotProfile/HostpotProfilePage";
import HostpotProfileUpdate from "views/Pages/HostpotVoucher/HostpotProfile/HostpotProfileUpdate";
import HostpotUserList from "views/Pages/HostpotVoucher/HostpotUser/HostpotUserLists";
import HostpotUserPage from "views/Pages/HostpotVoucher/HostpotUser/HostpotUserPage";
import HostpotUserGenerate from "views/Pages/HostpotVoucher/HostpotUser/HostpotUserGenerate";
import HostpotUserUpdate from "views/Pages/HostpotVoucher/HostpotUser/HostpotUserUpdate";
import HostpotFinanceStatement from "views/Pages/HostpotVoucher/HostpotFinanceReport/HostpotFinanceReport";
import HostpotHistoryDetail from "views/Pages/HostpotVoucher/HostpotFinanceReport/HostpotHistoryDetail";
import PPPSessionList from "views/Pages/SessionView/PPPSession/PPPSessionLists";
import HostpotSessionList from "views/Pages/SessionView/HostpotSession/HostpotSessionLists";

let routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "now-ui-icons business_bank",
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    path: '/users',
    name: 'Mitra Manager',
    state: "openMitraManagerPages",
    icon: "now-ui-icons business_badge",
        views: [
      {
        path: "/user-manager-mitra-list",
        name: "List Mitra",
        mini: "VU",
        component: MitraList,
        layout: "/admin",
      },
      {
        path: "/erpnext-client-list",
        name: "List ERPNext Client",
        mini: "EC",
        component: ErpNextClientList,
        layout: "/admin",
      },
    ]
  },
  {
    collapse: true,
    path: '/users',
    name: 'User Manager',
    state: "openUserManagerPages",
    icon: "now-ui-icons users_single-02",
        views: [
      {
        path: "/user-manager-list",
        name: "List User",
        mini: "VU",
        component: UserManagerList,
        layout: "/admin",
      }
    ]
  },
  {
    collapse: true,
    path: '/submission',
    name: 'Keuangan',
    state: "openSubmissionFinancePages",
    icon: "now-ui-icons files_paper",
        views: [
      {
        path: "/finance-statement-list",
        name: "Laporan Keuangan",
        mini: "FR",
        component: FinanceStatementList,
        layout: "/admin",
      },
      {
        path: "/submission-finance-list",
        name: "Pengajuan Dana",
        mini: "SF",
        component: SubmissionFinanceList,
        layout: "/admin",
      },
      {
        path: "/submission-logistic-list",
        name: "Pengajuan Barang",
        mini: "SL",
        component: SubmissionLogisticList,
        layout: "/admin",
      },
      {
        path: "/finance-outflow-list",
        name: "Pengeluaran",
        mini: "FO",
        component: FinacneOutflowList,
        layout: "/admin",
      },
    ]
  },
  {
    collapse: true,
    path: '/customer',
    name: 'Customer',
    state: "openCustomerPages",
    icon: "now-ui-icons business_briefcase-24",
      views: [


      {
        path: "/customer-all",
        name: "List Customer",
        mini: "CA",
        component: CustomerLists,
        layout: "/admin",
      }
    ]
  },
  {
    collapse: true,
    path: '/item',
    name: 'Product',
    state: "openItemPages",
    icon: "now-ui-icons files_box",
      views: [
      {
        path: "/item-list",
        name: "List Product",
        mini: "BR",
        component: ItemLists,
        layout: "/admin",
      },
      {
        path: "/item-add-on-list",
        name: "List Product Add-On",
        mini: "IA",
        component: ItemAddOnList,
        layout: "/admin",
      }
    ]
  },
  {
    collapse: true,
    path: '/router',
    name: 'Router Manager',
    state: "openRouterPages",
    icon: "now-ui-icons business_globe",
        views: [
      {
        path: "/router-list",
        name: "List Router",
        mini: "VR",
        component: RouterList,
        layout: "/admin",
      },
      {
        path: "/PPP-session",
        name: "PPPOE Session",
        mini: "PS",
        component: PPPSessionList,
        layout: "/admin",
      },
      {
        path: "/router-map",
        name: "Topology Map",
        mini: "TM",
        component: TopologyMap,
        layout: "/admin",
      },
      // {
      //   path: "/phoneCode-manager-list",
      //   name: "View Phone Code",
      //   mini: "VP",
      //   component: PhoneCodeManagerList,
      //   layout: "/admin",
      // },
    ]
  },
  {
    collapse: true,
    path: '/bank',
    name: 'Bank Account',
    state: "openBankAccountPages",
    icon: "now-ui-icons business_money-coins",
      views: [
      {
        path: "/bank-account-list",
        name: "List Akun Bank",
        mini: "AL",
        component: BankAccountList,
        layout: "/admin",
      },
    ]
  },
  {
    collapse: true,
    path: '/template',
    name: 'Template Manager',
    state: "openGlobalTemplatePages",
    icon: "now-ui-icons files_paper",
        views: [

      {
        path: "/global-template-list",
        name: "List Global Template",
        mini: "AL",
        component: GlobalTemplateList,
        layout: "/admin",
      },
    ]
  },
  {
    collapse: true,
    path: '/voucher',
    name: 'Hostpot',
    state: "openVoucherPages",
    icon: "now-ui-icons arrows-1_cloud-upload-94",
      views: [
          {
            path: "/hostpot-profile-list",
            name: "List Hostpot Profile",
            mini: "HP",
            component: HostpotProfileList,
            layout: "/admin",
          },
          {
            path: "/hostpot-user-list",
            name: "List Hostpot User",
            mini: "HU",
            component: HostpotUserList,
            layout: "/admin",
          },
          {
            path: "/hostpot-session",
            name: "Hostpot Session",
            mini: "HS",
            component: HostpotSessionList,
            layout: "/admin",
          },
          {
            path: "/hostpot-finance",
            name: "Finance Statement",
            mini: "HF",
            component: HostpotFinanceStatement,
            layout: "/admin",
          },
    ]
  },
  {
    collapse: true,
    path: '/subscription-manager',
    name: 'Subscription',
    state: "openSubsMasterPages",
    icon: "now-ui-icons ui-1_calendar-60",
      views: [
        {
          path: "/Billing-list",
          name: "List Subscription",
          mini: "BL",
          component: BillingList,
          layout: "/admin",
        },
        {
          path: "/Billing-Address-list",
          name: "List Alamat",
          mini: "BL",
          component: BillingAddressLists,
          layout: "/admin",
        },
        {
          path: "/Invoice-list",
          name: "List Invoice",
          mini: "BL",
          component: InvoiceList,
          layout: "/admin",
        },
        {
          path: "/message-log-list",
          name: "List Log Pesan",
          mini: "LP",
          component: MessageLogList,
          layout: "/admin",
        },
    ]
  },
  {
    collapse: true,
    path: '/logistic-manager',
    name: 'Logistic Manager',
    state: "openLogisticMasterPages",
    icon: "now-ui-icons shopping_delivery-fast",
      views: [
       {
        path: "/logistic-supplier-list",
        name: "List Supplier",
        mini: "VS",
        component: LogisticSupplierList,
        layout: "/admin",
      },
      {
        path: "/logistic-warehouse-list",
        name: "List Gudang",
        mini: "VW",
        component: LogisticWarehouseList,
        layout: "/admin",
      },
      {
        path: "/logistic-item-list",
        name: "List Item Gudang",
        mini: "VT",
        component: LogisticItemList,
        layout: "/admin",
      },
    ]
  },
  {
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "now-ui-icons design_image",
    views: [
      {
        path: "/user-page",
        name: "User Profile",
        mini: "UP",
        component: UserPage,
        layout: "/admin",
      },
      {
        path: "/login-page",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        component: LoginPage,
        layout: "/auth",
      }
    ],
  },
  {
    path: "/Billing-Address-update",
    name: "Customer Address",
    mini: "CA",
    component: BillingAddressViewUpdate,
    layout: "/admin",
  },
  {
    path: "/user-update",
    name: "User Manager",
    mini: "US",
    component: userUpdate,
    layout: "/admin",
  },
  {
    path: "/user-manager-view",
    name: "User Manager View",
    mini: "UM",
    component: UserManagerView,
    layout: "/admin",
  },
  {
    path: "/customer-view",
    name: "Customer Manager",
    mini: "VM",
    component: customerViewUpdate,
    layout: "/admin",
  },
  {
    path: "/Item-update",
    name: "Item Manager",
    mini: "VM",
    component: ItemViewUpdate,
    layout: "/admin",
  },
  {
    path: "/Billing-update",
    name: "Subscriptions",
    mini: "SB",
    component: BillingUpdate,
    layout: "/admin",
  },
    {
    path: "/Subscription-history",
    name: "SubscriptionsHistory",
    mini: "SH",
    component: SubscriptionHistory,
    layout: "/admin",
  },
  {
    path: "/Invoice-update",
    name: "Invoice",
    mini: "VM",
    component: InvoiceUpdate,
    layout: "/admin",
  },
  {
    path: "/bank-account-view",
    name: "Bank Account",
    mini: "BA",
    component: BankAccountView,
    layout: "/admin",
  },
  {
    path: "/forbidden",
    name: "Access Control",
    component: ForbiddenError,
    layout: "/admin",
  },
  {
    path: "/server-error",
    name: "Access Control",
    component: ServerError,
    layout: "/admin",
  },
  {
    path: "/user-manager-view",
    name: "User Manager",
    component: UserManagerView,
    layout: "/admin",
  },
  {
    path: "/email-page",
    name: "Notify Customer",
    component: EmailPage,
    layout: "/admin",
  },
  {
    path: "/user-manager-mitra-view",
    name: "Mitra Manager",
    component: MitraUpdate,
    layout: "/admin",
  },
  {
    path: "/Resource-page",
    name: "Resource Manager",
    mini: "RM",
    component: ResourecForm,
    layout: "/admin",
  },
  {
    path: "/datalog-list",
    name: "Datalog",
    component: DatalogList,
    layout: "/admin",
  },
  {
    path: "/datalog-view",
    name: "Datalog View",
    mini: "RM",
    component: DatalogView,
    layout: "/admin",
  },
  {
    path: "/user-manager-add",
    name: "Add User",
    mini: "AU",
    component: UserManagerPage,
    layout: "/admin",
  },
  {
    path: "/user-manager-mitra-add",
    name: "Add Mitra",
    mini: "AM",
    component: MitraForm,
    layout: "/admin",
  },
  {
    path: "/customer-page",
    name: "Add New Customer",
    mini: "CF",
    component: customerPage,
    layout: "/admin",
  },
  {
    path: "/item-page",
    name: "Add Item",
    mini: "AB",
    component: AddItem,
    layout: "/admin",
  },
  {
    path: "/bank-account-page",
    name: "Add Bank Account",
    mini: "AP",
    component: BankAccountPage,
    layout: "/admin",
  },
    {
    path: "/virtualAccount-page",
    name: "Add Virtual Account",
    mini: "VA",
    component: VirtualAccountPage,
    layout: "/admin",
  },
    {
    path: "/virtualAccount-view",
    name: "View Virtual Account",
    mini: "VP",
    component: VirtualAccountView,
    layout: "/admin",
  },
  {
    path: "/Billing-page",
    name: "Add Subscription",
    mini: "AB",
    component: AddBilling,
    layout: "/admin",
  },
  {
    path: "/Billing-Address-page",
    name: "Add Billing Address",
    mini: "AB",
    component: AddBillingAddress,
    layout: "/admin",
  },
  {
    path: "/invoice-page",
    name: "Add Invoice",
    mini: "AB",
    component: AddInvoice,
    layout: "/admin",
  },
  {
    path: "/global-template-page",
    name: "Add Global Template",
    mini: "AT",
    component: GlobalTemplatePage,
    layout: "/admin",
  },
  {
    path: "/global-template-view",
    name: "View Global Template",
    mini: "VT",
    component: GlobalTemplateView,
    layout: "/admin",
  },
  {
    path: "/router-page",
    name: "Add Router",
    mini: "AT",
    component: RouterAdd,
    layout: "/admin",
  },
  {
    path: "/router-update",
    name: "Update Router",
    mini: "UR",
    component: RouterUpdate,
    layout: "/admin",
  },
  {
    path: "/phoneCode-Manager-page",
    name: "Add Phone Code",
    mini: "AP",
    component: PhoneCodeManagerPage,
    layout: "/admin",
  },
  {
    path: "/phoneCode-Manager-update",
    name: "Update Phone Code",
    mini: "UP",
    component: PhoneCodeManagerViewUpdate,
    layout: "/admin",
  },
  {
    path: "/gridpole-page",
    name: "Add Grid Pole",
    mini: "AT",
    component: GridPoleInventoryPage,
    layout: "/admin",
  },
  {
    path: "/gridpole-update",
    name: "Update Grid Pole",
    mini: "UR",
    component: GridPoleDeviceInventoryView,
    layout: "/admin",
  },
  {
    path: "/indoor-device-page",
    name: "Add Indoor Device",
    mini: "AT",
    component: IndoorDeviceInventoryPage,
    layout: "/admin",
  },
  {
    path: "/indoor-device-update",
    name: "Update Indoor Device",
    mini: "UR",
    component: IndoorDeviceInventoryView,
    layout: "/admin",
  },
  {
    path: "/outdoor-device-page",
    name: "Add Outdoor Device",
    mini: "AT",
    component: OutdoorDeviceInventoryPage,
    layout: "/admin",
  },
  {
    path: "/outdoor-device-update",
    name: "Update Outdoor Device",
    mini: "UR",
    component: OutdoorDeviceInventoryView,
    layout: "/admin",
  },
  {
    path: "/logistic-item-add",
    name: "Add Logistic Item",
    mini: "AT",
    component: LogisticItemSubmit,
    layout: "/admin",
  },
  {
    path: "/logistic-item-update",
    name: "Update Logistic Item",
    mini: "UT",
    component: LogisticItemUpdate,
    layout: "/admin",
  },
  {
    path: "/logistic-supplier-add",
    name: "Add Logistic Supplier",
    mini: "AT",
    component: LogisticSupplierSubmit,
    layout: "/admin",
  },
  {
    path: "/logistic-supplier-update",
    name: "Update Logistic Supplier",
    mini: "UT",
    component: LogisticSupplierUpdate,
    layout: "/admin",
  },
  {
    path: "/logistic-warehouse-add",
    name: "Add Logistic Warehouse",
    mini: "AT",
    component: LogisticWarehouseSubmit,
    layout: "/admin",
  },
  {
    path: "/logistic-warehouse-update",
    name: "Update Logistic Warehouse",
    mini: "UT",
    component: LogisticWarehouseUpdate,
    layout: "/admin",
  },
  {
    path: "/logistic-goods-add",
    name: "Add Logistic Goods",
    mini: "AG",
    component: LogisticGoodsSubmit,
    layout: "/admin",
  },
  {
    path: "/logistic-goods-update",
    name: "Update Logistic Goods",
    mini: "UG",
    component: LogisticGoodsUpdate,
    layout: "/admin",
  },
  {
    path: "/logistic-goods-customer-update",
    name: "Update Logistic Customer Goods",
    mini: "CG",
    component: LogisticGoodsCustomerUpdate,
    layout: "/admin",
  },
  {
    path: "/logistic-customer-to-warehouse",
    name: "Customer To Warehouse",
    mini: "CW",
    component: TransferCustomerToWarehouse,
    layout: "/admin",
  },
  {
    path: "/logistic-warehouse-to-customer",
    name: "Warehouse To Customer",
    mini: "HW",
    component: TransferWarehouseToCustomer,
    layout: "/admin",
  },
  {
    path: "/logistic-warehouse-exports",
    name: "Export Logistic",
    mini: "XT",
    component: LogisticExport,
    layout: "/admin",
  },
  {
    path: "/logistic-warehouse-to-warehouse",
    name: "Warehouse To Customer",
    mini: "HW",
    component: TransferWarehouseToWarehouse,
    layout: "/admin",
  },
  {
    path: "/submission-finance-add",
    name: "Add Submission Finance",
    mini: "AF",
    component: SubmissionFinancePage,
    layout: "/admin",
  },
  {
    path: "/submission-finance-view",
    name: "View Submission Finance",
    mini: "VF",
    component: SubmissionFinanceView,
    layout: "/admin",
  },
  {
    path: "/submission-logistic-add",
    name: "Add Submission Logistic",
    mini: "AL",
    component: SubmissionLogisticPage,
    layout: "/admin",
  },
  {
    path: "/submission-logistic-view",
    name: "View Submission Logistic",
    mini: "VL",
    component: SubmissionLogisticUpdate,
    layout: "/admin",
  },
  {
    path: "/finance-statement-detail",
    name: "Finance Report Detail",
    mini: "FD",
    component: FinanceStatementDetail,
    layout: "/admin",
  },
  {
    path: "/logistic-goods-add-blob",
    name: "Add Logistic Goods BLOB",
    mini: "AGB",
    component: LogisticGoodsBlobPage,
    layout: "/admin",
  },
  {
    path: "/logistic-warehouse-to-customer-blob",
    name: "Transfer To Customer",
    mini: "CT",
    component: TransferBlobWarehouseToCustomer,
    layout: "/admin",
  },
  {
    path: "/logistic-customer-to-warehouse-blob",
    name: "Transfer To Warehouse",
    mini: "WT",
    component: TransferBlobCustomerToWarehouse,
    layout: "/admin",
  },
  {
    path: "/logistic-warehouse-to-warehouse-blob",
    name: "Switch Warehouse",
    mini: "SW",
    component: TransferBlobWarehouseToWarehouse,
    layout: "/admin",
  },
  {
    path: "/message-broadcast",
    name: "Broadcast Message",
    mini: "BM",
    component: MessageBroadcastPage,
    layout: "/admin",
  },
  {
    path: "/item-add-on-page",
    name: "Add-On Product Page",
    mini: "AP",
    component: ItemAddOnPage,
    layout: "/admin",
  },
  {
    path: "/item-add-on-update",
    name: "Add-On Product View",
    mini: "AU",
    component: ItemAddOnUpdate,
    layout: "/admin",
  },
  {
    path: "/subscription-add-on-page",
    name: "Add-On Subscription Page",
    mini: "SP",
    component: SubscriptionAddOnPage,
    layout: "/admin",
  },
  {
    path: "/subscription-add-on-update",
    name: "Add-On Subscription View",
    mini: "SU",
    component: SubscriptionAddOnUpdate,
    layout: "/admin",
  },
  {
    path: "/subscription-History-add-on-page",
    name: "Add-On History Subscription Page",
    mini: "HP",
    component: SubscriptionAddOnHistoryPage,
    layout: "/admin",
  },
  {
    path: "/subscription-History-add-on-view",
    name: "Add-On History Subscription View",
    mini: "HV",
    component: SubscriptionAddOnHistoryView,
    layout: "/admin",
  },
        {
    path: "/finance-outflow-add",
    name: "Add Finance Outflow",
    mini: "AO",
    component: FinanceOutflowPage,
    layout: "/admin",
  },
  {
    path: "/finance-outflow-view",
    name: "View Finance Outflow",
    mini: "VO",
    component: FinanceOutflowView,
    layout: "/admin",
  },
  {
    path: "/erpnext-client-form",
    name: "ERPNext Client Form",
    mini: "EF",
    component: ErpNextClientPage,
    layout: "/admin",
  },
  {
    path: "/erpnext-client-detail",
    name: "ERPNext Client Detail",
    mini: "ED",
    component: ErpNextClientUpdate,
    layout: "/admin",
  },
  {
    path: "/message-log-view",
    name: "Log Pesan",
    mini: "LP",
    component: MessageLogDetail,
    layout: "/admin",
  },
  {
    path: "/invoice-export",
    name: "Export Invoice",
    mini: "EI",
    component: ExportInvoice,
    layout: "/admin",
  },
  {
    path: "/wa-gateway",
    name: "WhatsApp Gateway",
    mini: "WG",
    component: WaGatewayPage,
    layout: "/admin",
  },
  {
    path: "/invoice-notification",
    name: "Notifikasi Invoice",
    mini: "MS",
    component: InvoiceMailSender,
    layout: "/admin",
  },
  {
    path: "/item-export",
    name: "Export Item Profile",
    mini: "IE",
    component: ItemExport,
    layout: "/admin",
  },
  {
    path: "/hostpot-profile-page",
    name: "Add Hostpot Profile",
    mini: "HP",
    component: HostpotProfilePage,
    layout: "/admin",
  },
  {
    path: "/hostpot-profile-update",
    name: "Update Hostpot Profile",
    mini: "HD",
    component: HostpotProfileUpdate,
    layout: "/admin",
  },
  {
    path: "/hostpot-user-page",
    name: "Add Hostpot User",
    mini: "HP",
    component: HostpotUserPage,
    layout: "/admin",
  },
  {
    path: "/hostpot-user-generate",
    name: "Add Hostpot User",
    mini: "HP",
    component: HostpotUserGenerate,
    layout: "/admin",
  },
  {
    path: "/hostpot-user-update",
    name: "Update Hostpot User",
    mini: "HD",
    component: HostpotUserUpdate,
    layout: "/admin",
  },
  {
    path: "/hostpot-history-detail",
    name: "Detail Hostpot History",
    mini: "DH",
    component: HostpotHistoryDetail,
    layout: "/admin",
  },
  {
    path: '*',
    name: "Error handler",
    component: NotFoundPage,
    layout: ''
  },
];

export default routes;
