import LoginService from '../LoginService'
import APIconsume from '../APIconsume'
import { getAccessToken } from "variables/common"

class HostpotProfileService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'hostpotProfile'
    }

    async post (payload) {
        const result = await this._consume.postData(payload, this._url)
        return result.data
    }

    async get (name) {
        const url = `${this._url}/${name}`

        const result = await this._consume.getData(url)
        return result.data
    }

    async getProfileSelect (id) {
        const url = `${this._url}/Select/profile/${id}`

        const result = await this._consume.getData(url)
        return result.data
    }

    async getInternetMeasurement () {
        const url = `${this._url}/select/internetMeasurement`

        const result = await this._consume.getData(url)
        return result.data
    }

    async getTimeMeasurement () {
        const url = `${this._url}/select/timeMeasurement`

        const result = await this._consume.getData(url)
        return result.data
    }

    async getAll (len = 'ALL', trash = false, mitra = '', page = '') {
        let url = `${this._url}/view?mitra=${mitra}&trash=${trash}&line=${len}&page=${page}`

        const result = await this._consume.getData(url)
        return result.data
    }

    async put (name, payload) {
        const url = `${this._url}/${name}`

        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async setTrash (id, payload) {
        const url = `${this._url}/setTrash/${id}`
        const result = await this._consume.patchData(payload, url)
        return result.data
    }

    async delete (name) {
        const url = `${this._url}/${name}`

        const result = await this._consume.deleteData(url)
        return result.data
    }
}

export default HostpotProfileService