import { Tabs, TabList } from 'react-tabs'
import { IndividualTabs, NetInventoryTabs } from "./Tabs"

export function TabsTable(props) {
    const { dataTable, columns, isLoading, tabIndex, setTabIndex } = props
    const datasetFinal = dataTable || []
    return (
        <>
        <Tabs selectedIndex={tabIndex} onSelect={(idx) => setTabIndex(idx)}>
        <TabList>
            {datasetFinal.map((data, i) => {
                return NetInventoryTabs(data, i)
              }
            )}
        </TabList>
            {datasetFinal.map((dataTable, i) => {
                return IndividualTabs(dataTable, i, columns, isLoading)
            })}
        </Tabs>
        </>
    )
}