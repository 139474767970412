const { Table } = require("reactstrap")

const DynamicTable = ({Data}) => {
  if (!Data || !Data.length) {
    return (
        <h5>No Data</h5>
    )
  }
  const header = Object.keys(Data[0])
  return (
    <Table responsive bordered className="bg-secondary text-light">
        <thead className="text-light">
            <tr>
              {header.map((hd) => {
                return (<th>{hd}</th>)
              })

              }
            </tr>
          </thead>
          <tbody className="text-light">
          {Data.map((data, i) => {
            return (
              <>
            <tr key={i}>
            {header.map((hd) => {
              return (<td>{data[hd]}</td>)
            })

            }
            </tr>
          </>
           )
        }
        )}
      </tbody>
    </Table>)
}

  export default DynamicTable