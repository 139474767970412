import { StatusDisplay } from "Main/Utils";
// import { useEffect } from "react";
const { Table } = require("reactstrap")

function  SubsStatus ({Data, load, getData}) {
    // useEffect( () => {
    //   if (!getData) {
    //     console.log('no-data')
    //   } else {
    //     getData()
    //   }},
    // [])

   if (!load) {
     return (
        <Table responsive bordered hover className="bg-secondary text-light" max-height= "400px">
            <thead className="text-light">
                <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Name</th>
                    <th>Message</th>
                </tr>
            </thead>
            <tbody>
            {Data.map((data, i) => {
                try {
                    console.log(data)
                    return (
                        <>
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td className={!data.success ? "bg-danger": "bg-success"}>{StatusDisplay(data.success)}</td>
                            <td>{data.name}</td>
                            <td>{data.message || data.data}</td>
                        </tr>
                        </>
                    )
                } catch (error) {
                    return (
                    <tr>
                        {error.mesage}
                    </tr>)
                }
            }
            )}
        </tbody>
        </Table>)
   }
    return (
        <Table responsive bordered hover className="bg-secondary text-light" max-height= "400px">
            <thead className="text-light">
                <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Name</th>
                    <th>Message</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    Loading...
                </tr>
        </tbody>
        </Table>)
  }

export default SubsStatus