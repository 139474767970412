import React from "react";
import LoadingScreen from "components/Loader/LoadingScreen";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Select from 'react-select';
import { useState } from "react";
import {item, getRefreshToken, GetItemCategory, GetItemCurrency,
  addItemList, GetItemMeasurement, getUserData, getOwner, PriceFormat, 
  getRouterSelect} from "./../../../variables/common"
import ItemService from "Main/ItemService";
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import RouterService from "Main/RouterService";
import Checklist from "components/Button/Checklist";
import SelectBasic from "components/Input/SelectBasic";

function ItemAdd(props) {
  const itemService = new ItemService()
  const routerService = new RouterService()
  var selectMeasurementOptions = GetItemMeasurement()
  var selectCategoryOptions = GetItemCategory()
  var selectCurrencyOptions = GetItemCurrency()
  const [routerSelectOption, routerSelectOptionOnChange] = useState(getRouterSelect())
  const userData = getUserData()
  const ownerData = getOwner()
  const userType = userData.userType
  const [changeOwner, changeOwnerOnChange] = useState(false)
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const refreshToken = getRefreshToken()
  const name = useFormInput('')
  const [ip, ipOnChange] = useState('')
  const [subnet, subnetOnChange] = useState('')
  const [upValue, upValueOnChange] = useState(0)
  const [downValue, downValueOnChange] = useState(0)
  const [priceValue, priceValueOnChange] = useState()
  const [discountValue, discountValueOnChange] = useState(0)
  const Measurement  = selectMeasurementOptions.find(data => data.value === 'Mbps')
  const currency  = selectCurrencyOptions.find(data => data.label === 'Rupiah')
  const [category, categoryOnChange] = useState('')
  const [router, routerOnChange] = useState('')
  const [loading, loadingOnChange ] = useState(false)
  const [useProfilePPPlist, setUseProfilePPPlist] = useState(false)
  const [pppName, setPppName] = useState('')
  const [RouterpppProfile, SetRouterpppProfile] = useState({})
  const [RouterpppProfileList, SetRouterpppProfileList] = useState([])

  const getItem =  async() => {
        const len =  'ALL'
        const result =  await itemService.getAllItem(len)
        addItemList(result.data)
  }

  const getRouter = async(mitraid) => {
    const dat = await routerService.getRouterSelect(mitraid)
    const result = dat.data
    routerSelectOptionOnChange(result)
    return result
  }

  const addItemData = async() => {
      try {
          loadingOnChange(true)
          const payload = item(owner.mitraid, name.value, upValue, downValue, category.value,
                          priceValue, discountValue, Measurement.value, router.value, ip, subnet, pppName)
          console.log(payload)

          const result = await itemService.addItem(payload)
          await getItem()
          loadingOnChange(false)
          await swal(result.status, result.message, "success")
          props.history.push('/admin/item-list')
      } catch (error) {
        loadingOnChange(false)
        return await CatchError(error, props, refreshToken)
      }
    }
      //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')}

  function deleteSelect() {
    routerOnChange('')
  }

  const getRouterPPP =  async(routerId) => {
    const result =  await routerService.getpppProfileSelect(routerId)
    SetRouterpppProfileList(result.data)
    return result.data
  }

  useState(() => {
    getRouter(owner.mitraid)
  }, [])

  const children = (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Form Produk</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <Label>Mitra</Label>
                          {
                          (userType === 'SUPERADMIN') ? (
                            <>
                            <Select
                              placeholder="mitra"
                              value={owner}
                              isDisabled = {!changeOwner}
                              options={ownerData}
                              onChange={ async(e) => {
                                ownerOnChange(e);
                                await getRouter(e.mitraid)
                                deleteSelect()
                              } } />
                              <FormGroup check>
                                <Label check> Ubah Mitra
                                  <Input type="checkbox"
                                  value = {changeOwner}
                                  checked = {changeOwner}
                                  onChange = {async (e) => {
                                    ownerOnChange(ownerData.find(own => own.value === userData.organization))
                                    changeOwnerOnChange(e.target.checked)
                                    await getRouter(owner.mitraid)
                                    if (owner.value !== userData.organization) {
                                      deleteSelect()
                                      routerSelectOptionOnChange(getRouterSelect())
                                    }
                                    }
                                  }
                                  />
                                  <span className="form-check-sign" />
                                </Label>
                              </FormGroup>
                              </>) : (
                              <Input
                              placeholder="mitra"
                              value={owner.value}
                              />
                              )
                          }
                          <Label>Lokasi Router</Label>
                          <div>
                          <Select
                            placeholder="Lokasi Router"
                            name="singleSelect"
                            value = {router}
                            options={routerSelectOption}
                            onChange={async (value) => {
                              routerOnChange(value)
                              await getRouterPPP(router.value)
                            }}
                          />
                          {!router.status ? (
                              <label className="error">
                                Router Offline
                              </label>
                            ) : <label className="success">
                                Router Online
                              </label>}
                          </div>
                          <Label>Nama</Label>
                          <Input
                            {...name}
                            placeholder="Nama"
                            type="text"
                          />
                          {!useProfilePPPlist ? (
                            <>
                              <Label>Nama PPP-Profile</Label>
                              <Input
                                value = {pppName}
                                onChange={e => setPppName(e.target.value)}
                                placeholder="Nama"
                                type="text"
                              />
                           </>
                              ) : (
                                <>
                                <Label>PPOE Profile List</Label>
                                <SelectBasic
                                  label={" Select PPP From Router"}
                                  value={RouterpppProfile}
                                  option={RouterpppProfileList}
                                  onChange={(e) => {
                                    SetRouterpppProfile(e)
                                    setPppName(e.value)
                                    ipOnChange(e.ip)
                                    subnetOnChange(Number(e.subnet))
                                    }} />
                                </>
                              )
                          }
                          <Label>IP</Label>
                              <Input
                                value = {ip}
                                disabled = {useProfilePPPlist}
                                onChange = {(e) => {
                                  if (e.target.value.length <= 15) {
                                    ipOnChange(e.target.value)
                                  }
                                }}
                                placeholder="IP POOL"
                                type="text"
                              />
                              <Label>Subnet CIDR</Label>
                              <Input
                                value = {subnet}
                                disabled = {useProfilePPPlist}
                                placeholder="Subnet CIDR"
                                type="number"
                                onChange={(e) => {
                                  if (e.target.value <= 32) {
                                    subnetOnChange(e.target.value)
                                  }
                                }}
                              />
                          <Checklist
                              text = {"Memakai PPP Profile Router"}
                              value = {useProfilePPPlist}
                              onChange = { async (e) => {
                                setUseProfilePPPlist(e.target.checked)
                                setPppName('')
                                ipOnChange('')
                                subnetOnChange(24)
                                if (!e.target.checked) {
                                  SetRouterpppProfile([])
                                  return e
                                }
                                await getRouterPPP(router.value)
                              }}
                            />
                          <Label>Pengukuran</Label>
                          <Select
                            placeholder="Pengukuran"
                            name="singleSelect"
                            value = {Measurement}
                          />
                        <Label>Mata Uang</Label>
                          <Select
                            placeholder="Mata uang"
                            name="singleSelect"
                            value = {currency}
                          />
                          <Label>Kategori</Label>
                          <Select
                            placeholder="Kategori"
                            name="singleSelect"
                            value = {category}
                            options={selectCategoryOptions}
                            onChange={(value) => categoryOnChange(value)}
                          />
                          <Label>Kecepatan Download</Label>
                          <Input
                            placeholder="Kecepatan Download"
                            value = {downValue}
                            onChange = {(e) => {
                              if (e.target.value >= 0) {
                                downValueOnChange(e.target.value)
                              }
                            }}
                            type="number"
                          />
                          <Label>Kecepatan Upload</Label>
                          <Input
                            placeholder="Kecepatan Upload"
                            value = {upValue}
                            onChange = {(e) => {
                              if (e.target.value >= 0) {
                                upValueOnChange(e.target.value)
                              }
                            }}
                            type="number"
                          />
                          <Label>Harga</Label>
                          <Input
                            placeholder="Harga"
                            value = {priceValue}
                            onChange = {(e) => {
                              if (e.target.value <= 0) {
                                priceValueOnChange(null)
                              }
                              if (e.target.value >= 0) {
                                priceValueOnChange(e.target.value)
                              }
                            }}
                            type="number"
                          />

                          <Label>Diskon</Label>
                          <Input
                            placeholder="Diskon"
                            value = {discountValue}
                            onChange = { (e) => {
                              if (e.target.value >= 0 && e.target.value <= 100) {
                                discountValueOnChange(e.target.value)
                              }
                            }
                            }
                            type="number"
                          />
                          <Label>Harga Final</Label>
                          <Input
                            placeholder="Price"
                            value={PriceFormat(Number(priceValue - ((discountValue/100)*priceValue)), currency.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      </Row>
                  </Form>
                </CardBody>
              </Card>
            <Button onClick = {addItemData} color="info">Submit</Button>
            </Col>
          </Row>
        </div>
      )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen children={children} loading = {loading} text={"Upload Data"}/>
      </>
    );
}


export default ItemAdd;
