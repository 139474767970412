import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken,
  WarehouseToWarehouse,
  addLogisticGoodsData} from "variables/common"
import { CatchError } from "Main/Exceptions/exceptions";
import { getLogisticWarehouseOption } from "variables/common";
import { GetItemCurrency } from "variables/common";
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService";
import swal from "sweetalert";
import TransferWarehouseToWarehouseBody from "./../Body/TransferWarehouseToWarehouse";
import CommonList from "../../../../../components/Table/CommonList";
import { MapObjToSelectObj } from "Main/Utils";
import { getLogisticItemOption } from "variables/common";
import Select from 'react-select'
import { nullCompensation } from "Main/Utils";
import { baseBlob } from "variables/common";
import { Datestr } from "Main/Utils";
import SubsStatus from "../StatusList";
import { getUserPermission } from "variables/common";
import { FetchLogisticSelect } from "Main/Logistic/FetchLogistic";
import { getOwner } from "variables/common";
import { verifySuperAdmin } from "Main/Utils";
import { useEffect } from "react";
import { getWarehouseToWarehouseBlob } from "variables/WebStorage/LogisticBlobStorage";
import { deleteWarehouseToWarehouseBlob } from "variables/WebStorage/LogisticBlobStorage";
import { setWarehouseToWarehouseBlob } from "variables/WebStorage/LogisticBlobStorage";
import { BlobDataLen } from "Main/Utils";
import { CatchErrorFlushData } from "Main/Exceptions/exceptions";

function TransferBlobWarehouseToWarehouse(props) {
  const pages = 'Logistic'
  const flushDesignation = 3
  const { mitraSuper, organization, id: userId } = verifySuperAdmin()
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const Service = new LogisticGoodsService()
  const [BlobData] = useState(getWarehouseToWarehouseBlob(userId))
  const refreshToken = getRefreshToken();
  const [BlobResult, BlobResultOnChange] = useState([])
  const [logisticData, logisticDataOnChange] = useState('')
  const [description, descriptionOnChange] = useState('')
  var selectCurrencyOptions = GetItemCurrency()
  const [warehouseOptions, setwarehouseOptions] = useState(getLogisticWarehouseOption())
  const [logisticItemOptions, setlogisticItemOptions] = useState(getLogisticItemOption())
  const [warehouseSrcOption, warehouseSrcOptionsOnChange]  = useState([])
  const [warehouseFilterOptions, warehouseFilterOnChange] = useState([])
  // const [logisticItem, logisticItemOnChange] = useState(logisticItemOptions.find((data) => data.value === itemId))
  const [warehouse, warehouseOnChange] = useState('')
  const [warehouseTo, warehouseToOnChange] = useState('')
  const idLogistic = logisticData.id
  const qtyState = logisticData.qty
  const priceValue = logisticData.price
  const [qty, qtyOnChange] = useState(1)
  const currency  = selectCurrencyOptions.find(data => data.label === 'Rupiah')
  const [insertDate, insertDateOnChange] = useState()
  const [name, nameOnChange] = useState()
  const [item, itemOnChange] = useState('')
  const [LogisticGoodsOptions, logisticGoodsOptionsOnChange] = useState('')
  const [LogisticGoods, logisticGoodsOnChange] = useState('')
  const [listLoad, setListLoad] = useState(true)
  const [loadResult, setLoadResult] = useState(false)
  const ownerOption = getOwner()
  const [mitra, mitraOnChange] = useState(ownerOption.find((data) => data.value === organization))

  useEffect(() => {
    FetchSelectData(mitra.mitraid)
  }, [])

  const ViewLogisticGoodsData = async (id) => {
    try {
      const result = await Service.get(id)
      addLogisticGoodsData(result.data)
    } catch (error) {
      return CatchError(error, props, refreshToken)
    }
  }

  const GetResultData = () => {
    loadResult(true)
    loadResult(false)
  }

  const submit = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      setLoadResult(true)
      const payload = baseBlob(BlobData)
      const result = await Service.transferWarehouseToWarehouseBlob(payload)
      await ViewLogisticGoodsData(idLogistic)
      swal("Success", result.message, "success").then(() => {
        BlobData.splice(0, BlobData.length)
        BlobResultOnChange(result.data)
        deleteWarehouseToWarehouseBlob(userId)
        setDataState(mapping(BlobData))
        setLoadResult(false)
      })
    } catch (error) {
      return await CatchErrorFlushData(error, props, refreshToken, userId, flushDesignation)
    }
  }

  const add = () => {
    setListLoad(true)
    setLoadResult(true)
    const payload = WarehouseToWarehouse(warehouseTo.value, LogisticGoods.value, qty, insertDate)
    BlobData.push(payload)
    // BlobDataonChange(BlobData)
    setWarehouseToWarehouseBlob(BlobData, userId)
    warehouseToOnChange('')
    qtyOnChange(1)
    insertDateOnChange(null)
    warehouseOnChange('')
    nameOnChange('')
    descriptionOnChange('')
    logisticGoodsOnChange('')
    itemOnChange('')
    setDataState(mapping(BlobData))
    BlobResult.splice(0, BlobResult.length)
    setListLoad(false)
    setLoadResult(false)
  }

  const GetLogisticGoods = async (itemid) => {
    const len = 'ALL'
    const result = await Service.getAll({itemid, len})
    logisticGoodsOptionsOnChange(MapObjToSelectObj(result.data))
    nameOnChange('')
    descriptionOnChange('')
    warehouseFilterOnChange([])
  }

  const GetLogisticGoodsData = async (id) => {
    const result = await Service.get(id)
    const resultData = result.data
    const warehouseId = resultData.warehouseId
    logisticDataOnChange(result.data)
    warehouseSrcOptionsOnChange(warehouseOptions.filter((data) => data.value === warehouseId))
    qtyOnChange(1)
    warehouseOnChange(warehouseOptions.find((data) => data.value === warehouseId))
    nameOnChange(resultData.name)
    descriptionOnChange(resultData.description)
    warehouseOnChange(warehouseOptions.find((data) => data.value === warehouseId))
    warehouseFilterOnChange(warehouseOptions.filter((data) => data.value !== warehouseId))
  }

  const columns = [
        {
        Header: "Src",
        accessor: "src",
        },
        {
        Header: "QTY",
        accessor: "qty",
        },
        {
        Header: "Transfer Ke",
        accessor: "transferTo",
        },
        {
        Header: "Tanggal",
        accessor: "date",
        },
        {
        Header: "Hapus",
        accessor: "actions",
        }
    ]

 const DeleteBlobData = (id) => {
    BlobData.pop(id)
    setWarehouseToWarehouseBlob(BlobData, userId)
    setDataState(mapping(BlobData))
  }

  const mapping = (Dataset) => Dataset.map((Data, i) => {
    try {
      const warehouse = !warehouseOptions ? {label: Data.warehouseDestId || 'no-label'} : warehouseOptions.find((warehouse) => warehouse.value === Data.warehouseDestId) || ''
      const goods = !LogisticGoodsOptions ? {label: Data.snTarget || 'no-label'} : LogisticGoodsOptions.find((goods) => goods.value === Data.snTarget) || ''
          return {
            id: i,
            transferTo: warehouse.label,
            qty: (<input
            type="number"
            value={Data.qtyChange}
            />),
            src: nullCompensation(goods.label),
            date: Datestr(Data.creation),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.snTarget}>
                <Button
                  onClick={ async () => {
                    await DeleteBlobData(i)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.snTarget}11`}
                  color="danger"
                  size="sm"
                >
                  <i className="fa fa-times" />
                </Button>{" "}
              </div>
            ),
          };
          } catch (error) {
            console.log(error.message)
            return []
          }
        })

  const [DataState, setDataState] = useState(
      mapping(BlobData)
  )

  const FetchSelectData = async (mitra) => {
  const { logisticItem,
    logisticWarehouse } = await FetchLogisticSelect(mitra)
    setwarehouseOptions(logisticWarehouse)
    setlogisticItemOptions(logisticItem)
    warehouseOnChange('')
    itemOnChange('')
    nameOnChange('')
    logisticGoodsOnChange('')
    qtyOnChange(1)
    insertDateOnChange(null)
  }

  return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">BLOB Transfer Antar Gudang</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Select
                      // className="react-select"
                      // classNamePrefix="react-select"
                      placeholder="Mitra"
                      value={mitra}
                      isDisabled = {!mitraSuper}
                      options={ownerOption}
                      onChange={ async (e) => {
                        mitraOnChange(e);
                        await FetchSelectData(e.mitraid)
                      } } />
                    <Label>Item</Label>
                    <Select
                      // className="react-select"
                      // classNamePrefix="react-select"
                      placeholder="Item"
                      name="singleSelect"
                      disabled
                      value = {item}
                      options = {logisticItemOptions}
                      onChange={async (e) => {
                        itemOnChange(e)
                        await GetLogisticGoods(e.value)
                        warehouseToOnChange('')
                        qtyOnChange(1)
                        insertDateOnChange(null)
                        warehouseOnChange('')
                        nameOnChange('')
                        descriptionOnChange('')
                        logisticGoodsOnChange('')

                      }
                    }
                    />
                    <Label>Barang</Label>
                    <Select
                      // className="react-select"
                      // classNamePrefix="react-select"
                      placeholder="Barang"
                      name="singleSelect"
                      value = {LogisticGoods}
                      options = {LogisticGoodsOptions}
                      onChange={async (e) => {
                        logisticGoodsOnChange(e)
                        await GetLogisticGoodsData(e.value)
                      }
                    }
                    />
                    <TransferWarehouseToWarehouseBody
                    name = {name}
                    nameOnChange = {nameOnChange}
                    warehouse = { warehouse}
                    warehouseFilterOptions = { warehouseFilterOptions}
                    warehouseOnChange = {warehouseOnChange}
                    warehouseSrcOption = {warehouseSrcOption}
                    warehouseTo = {warehouseTo}
                    warehouseToOnChange = {warehouseToOnChange}
                    qty = {qty}
                    qtyState = {qtyState}
                    qtyOnChange = {qtyOnChange}
                    priceValue = {priceValue}
                    currency = { currency}
                    insertDate = {insertDate}
                    insertDateOnChange = {insertDateOnChange}
                    description = {description}
                    descriptionOnChange = {descriptionOnChange}
                    />
                  <Button onClick = {add}  disabled = {!LogisticGoods.value && !warehouseTo.value} color="info">Add</Button>
                  <SubsStatus
                      Data={BlobResult}
                      load = {loadResult}
                      loadData = {GetResultData}
                    />
                  <CommonList
                      key = {1}
                      load={listLoad}
                      dataState={DataState}
                      // getData={[]}
                      columns = {columns}
                      header={'Logistic Data'}
                    />
                  <BlobDataLen
                    blob = {BlobData}
                  />
                  <Button onClick = {submit} disabled = {!Permission.Update && !BlobData.length} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default TransferBlobWarehouseToWarehouse;


