import React, { useEffect, useState } from "react";
import { DeckGLMap } from "components/Maps/DeckGlMap";
import { Card, CardBody, CardHeader } from "reactstrap";

import SelectBasic from "components/Input/SelectBasic";
import { HandleGeolocation } from "Main/Geolocation/HandleGeolocation";
import UserService from "Main/UserService";
import LoadingScreen from "components/Loader/LoadingScreen";
import { CatchError } from "Main/Exceptions/exceptions";
import { getRefreshToken, getOwner } from "variables/common";
import MapService from "Main/MapService";
import { decreaseFloatPrecission } from "variables/common";
import { getUserData } from "variables/common";

function TopologyMap(props) {
  const userService = new UserService()
  const mapService = new MapService()
   const userData = getUserData()
  const mitraList = getOwner()
  const [mitra, setMitra] = useState(mitraList.find(own => own.value === userData.organization))
  const [load, setLoad] = useState(false)
  const refreshToken = getRefreshToken()
  const [topologyDataState, setTopologyDataState] = useState(null)
  const [mapState, setMapState] = useState(null)

  const loadBaseLocation = async () => {
    setLoad(true)
    if (!mapState) {
      const { latitude, longitude } = await HandleGeolocation()
      setMapState({
        latitude: decreaseFloatPrecission(latitude),
        longitude: decreaseFloatPrecission(longitude)
      })
    }
    await loadMitraLocation(mitra)
    setLoad(false)
  }

  const loadMitraLocation = async (data) => {
    try {
      setLoad(true)

      setMitra(data)
      const selectedMitra = await userService.getMitraData(data.value)
      const { latitude, longitude } = selectedMitra.data
      const topologyData = await mapService.getTopology(data.mitraid)
      setTopologyDataState(topologyData.data)
      setMapState({
        latitude: decreaseFloatPrecission(latitude),
        longitude: decreaseFloatPrecission(longitude)
      })
      setLoad(false)
      console.log('Map State:', mapState)
    } catch (error) {
      setLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  }


  useEffect(() => {
    loadBaseLocation()
  }, [])

  const child = (
    <>
        <div>
          <Card>
            <CardHeader className="bg-dark">
              <div className="text-center bg-dark">
                <h3 className="text-light">Network Topology</h3>
              </div>
            </CardHeader>
            <CardBody className="content">
              <SelectBasic
                className = "font-weight-bold center bg-primary"
                label = {'Select Mitra'}
                noLabel = {true}
                option = {mitraList}
                value = {mitra}
                onChange = {loadMitraLocation}
              />
              <div id="map" className="bg-secondary" >
                <DeckGLMap
                  INITIAL_VIEW_STATE = {mapState}
                  NETWORK_DEVICE = {topologyDataState}
                />
              </div>
            </CardBody>
          </Card>
        </div>
    </>
  );

  return (
    <LoadingScreen
      text = {'Load Topology'}
      children = {child}
      loading = {load}
    />
  )
}

export default TopologyMap;
