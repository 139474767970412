import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken,
  getLogisticItemData,
  getCustomerOptions,
  WarehouseToCustomer,
  getLogisticGoodsData,
  getLogisticWarehouseData,
  getLogisticSupplierData,
  getOwner} from "./../../../../variables/common"
import { CatchError } from "Main/Exceptions/exceptions";
import { getLogisticWarehouseOption } from "variables/common";
import { GetItemCurrency } from "variables/common";
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService";
import { GlobalPropsPush } from "./FetchLogistic";
import TransferInventoryToCustomer from "./Body/TransferInventoryToCustomer";
import { FetchLogisticSelect } from "Main/Logistic/FetchLogistic";
import CustomerService from "Main/CustomerService";
import { useEffect } from "react";
import BasicInput from "components/Input/BasicInput";

function TransferWarehouseToCustomer(props) {
  const Service = new LogisticGoodsService()
  const customerService = new CustomerService()
  const refreshToken = getRefreshToken();
  const logisticData = getLogisticGoodsData()
  const name = logisticData.name
  const description = logisticData.description
  const itemData = getLogisticItemData()
  const itemId = itemData.id
  var selectCurrencyOptions = GetItemCurrency()
  const [warehouseOptions, setwarehouseOptions] = useState(getLogisticWarehouseOption())
  const warehouseFilterOptions = warehouseOptions.filter((data) => data.value === logisticData.warehouseId)
  const [customerOptions, setcustomerOptions] = useState(getCustomerOptions())
  const idLogistic = logisticData.id
  const qtyState = logisticData.qty
  const warehouseData = getLogisticWarehouseData()
  const supplierData = getLogisticSupplierData()
  const warehouseId = warehouseData.id
  const supplierId = supplierData.id
  // const [logisticItem, logisticItemOnChange] = useState(logisticItemOptions.find((data) => data.value === itemId))
  const [warehouse, warehouseOnChange] = useState(warehouseOptions.find((data) => data.value === logisticData.warehouseId))
  const [customer, customerOnChange] = useState('')
  const priceValue = logisticData.price
  const [qty, qtyOnChange] = useState(1)
  const currency  = selectCurrencyOptions.find(data => data.label === 'Rupiah')
  const [insertDate, insertDateOnChange] = useState()
  const ownerOption = getOwner()
  const owner = itemData.owner || warehouseData.owner || supplierData.owner
  const mitra = ownerOption.find((data) => data.value === owner)

  useEffect(() => {
    FetchSelectData(mitra.mitraid)
  }, [])

  const submit = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = WarehouseToCustomer(customer.value, idLogistic, qty, insertDate)
      const result = await Service.transferWarehouseToCustomer(payload)
      await GlobalPropsPush(props, itemId, warehouseId, supplierId, result)
    } catch (error) {
      return CatchError(error, props, refreshToken)
    }
  }

  // const AddMany = () => {
  //   props.history.push('/admin/logistic-warehouse-to-customer-blob')
  // }

  const FetchSelectData = async (mitra) => {
  const { logisticWarehouse } = await FetchLogisticSelect(mitra)
    setwarehouseOptions(logisticWarehouse)
    customerOnChange('')
    warehouseOnChange(logisticWarehouse.find((data) => data.value === logisticData.warehouseId))
    customerOnChange('')
    qtyOnChange(1)
    insertDateOnChange(null)
    await getCustomer(mitra)
  }

  const getCustomer = async (mitraid) => {
    const customerOption = await customerService.getCustomerSelect(mitraid)
    setcustomerOptions(customerOption.data)
  }

  return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Transfer Gudang ke Customer</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <BasicInput
                      key={1}
                      label = {'Mitra'}
                      value = {mitra.label}
                    />
                    <TransferInventoryToCustomer
                      key={2}
                      name = {name}
                      warehouse = {warehouse}
                      warehouseFilterOptions = {warehouseFilterOptions}
                      warehouseOnChange = {warehouseOnChange}
                      customer = {customer}
                      customerOptions = {customerOptions}
                      customerOnChange = {customerOnChange}
                      qty = {qty}
                      qtyState = { qtyState}
                      qtyOnChange = {qtyOnChange}
                      priceValue = {priceValue}
                      currency = { currency}
                      insertDate = {insertDate}
                      insertDateOnChange = {insertDateOnChange}
                      description = {description}
                    />
                  <Button onClick = {submit} color="info">Submit</Button>
                  {/* <Button onClick = {AddMany} color="info">Send Many</Button> */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default TransferWarehouseToCustomer;


