const { getData, setData } = require("variables/common");

const identifier = ['erpnextClientData', 'erpnextClientList']
const [data, list] = identifier

export function addErpNextClientData(payload) {
  setData(data, payload)
}

export function getErpNextClientData() {
 return getData(data)
}

export function addErpNextClientList(payload) {
  setData(list, payload)
}

export function getErpNextClientList() {
 return getData(list)
}

export function payload(mitra, name, url, apiKey, secretKey, sync) {
    return {
        mitra,
        name,
        url,
        apiKey,
        secretKey,
        sync,
        connected: false
    }
}

export function payloadUpdate(name, url, apiKey, secretKey, sync) {
    return {
        name,
        url,
        apiKey,
        secretKey,
        sync,
        connected: false
    }
}

export function payloadSetSync(id, sync) {
    return {
        id,
        sync
    }
}
