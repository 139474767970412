/*eslint-disable*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import ItemService from "Main/ItemService";
import {getRefreshToken, addItemData, PriceFormat} from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import CommonList from "../../../components/Table/CommonList";
import {columns} from "./ItemColumns"
import { getUserPermission } from "variables/common";

function ItemList(props) {
  const pages = 'item'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const itemService =  new ItemService()
  const ItemLen = useFormInput('ALL')
  const refreshToken = getRefreshToken()
  const [dataTable, setDataTable] = useState([])
  const [loaded, setDataLoaded] = useState(true)

  try {

    const getItem =  async() => {
      try {
        setDataLoaded(true)
        const len = ItemLen.value
        const dat =  await itemService.getAllItem(len)
        const result = dat.data

        setDataTable(result)
        setDataState(mapping(result))
        setDataLoaded(false)
        return result
      } catch (error) {
        setDataLoaded(false)
        return await CatchError(error, props, refreshToken)
      }
    }

    useEffect(() => {
      getItem()
     }, [])

    const deleteItem = (Id) => {
      swal("Hapus", "Data Tidak Dapat di Recover", "info", {
          buttons: {
          cancel: 'Cancel',
          delete: {text: "Hapus", value: 'del' }
        }
      }
      ).then( async(value) => {
        switch (value) {
          case 'del':
            try {
              setDataLoaded(true)
              const noRouter = false
              const respomse = await itemService.deleteItem(Id, noRouter)
              setDataLoaded(false)
              await swal(respomse.status, respomse.message, 'success')
              const data = await getItem()
              const stateRaw = mapping(data)
              const state = stateRaw.filter((data) => data.id !== Id)
              setDataState(state)
              return true
            } catch (error) {
              setDataLoaded(false)
              return await CatchError(error, props, refreshToken)
            }
            break;

          default:
            getItem()
            return false
        }
      })
    }

    const viewItem = async(id)=> {
      try {
        const dat = await itemService.getItem(id)
        const result = dat.data
        console.log(result)
        addItemData(result)
        props.history.push('/admin/item-update')
      } catch (error) {
        return await CatchError(error, props, refreshToken)
        }
    }

    const mapping = (DataTable) => DataTable.map((Data) => {
          try {
            return {
            id: Data.id,
            name: Data.name,
            owner: Data.owner,
            category: Data.category,
            pppProfile: Data.ppp_profile_name,
            uplink: `${Data.uplink} ${Data.measurement}`,
            downlink: `${Data.downlink} ${Data.measurement}`,
            // price: PriceFormat(Data.price, Data.currency.toUpperCase()),
            // discount: `${Data.discount}%`,
            router: Data.routerName,
            finalPrice: PriceFormat(Data.finalPrice, Data.currency.toUpperCase()),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>

                {/* use this button to add a edit kind of action */}
                <Button
                disabled={!Permission.Read}
                  onClick={() => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    viewItem(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}8`}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={`tooltip${Data.id}8`}
                >Update</UncontrolledTooltip>
                {/* use this button to remove the data row */}
                <Button
                disabled={!Permission.Delete}
                  onClick={() => {
                    deleteItem(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}9`}
                  color="danger"
                  size="sm"
                >
                  <i className="fa fa-times" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={`tooltip${Data.id}9`}
                >Delete</UncontrolledTooltip>
              </div>
            ),
          };
          } catch (error) {
            return []
          }
        })

    const [dataState, setDataState] = React.useState(mapping(dataTable))

  const Add = () => {
    props.history.push('/admin/item-page')
  }

  const Export = () => {
    props.history.push('/admin/item-export')
  }

   const customMenu = (
    <Row>
      <Col md={2}>
        <div  className="actions-left">
          <Button onClick={Add} color="info"
          disabled = {!Permission.Create}
          >Add</Button>
        </div>
      </Col>
      <Col md={8}>
      </Col>
      <Col md={2}>
        <div className="actions-right">
          <Button onClick={Export} color="info"
          disabled = {!Permission.Read}
          >Export</Button>
        </div>
      </Col>
    </Row>
  )

  return (
        <>
        <PanelHeader size="sm" />
          {/* <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Product</h2>
              </div>
            }
          /> */}
          <div className="content">
            <Row>
              <Col xs={12} md={12}>
                <Card>
                    <CommonList
                      key={1}
                      header={'Profile Product'}
                      columns={columns}
                      dataState={dataState}
                      Add={Add}
                      addDisabled={Permission.Create}
                      load={loaded}
                      getData={getItem}
                      menu = {customMenu}
                    />
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
  } catch (error) {
    return (
      <>
      <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Product</h2>
              </div>
            }
          />
      <h3>{error.message || error}</h3>
      </>
    )
  }
}

export default ItemList;
