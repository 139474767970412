import { useState } from "react";

const { useRef } = require("react");
const { FormGroup, Input, Label } = require("reactstrap");

function MultipleFileInput({label, fileOnChange, placeholder}) {
    const multipleFileRef = useRef()
    const [multipleFileName, setMultipleFileName] = useState('')
    const handleMultipleFileInput = (e) => {
        multipleFileRef.current.click(e);
    }
    const addMultipleFile = (e, type) => {
      let fileNames = "";
      let files = e.target.files;
      for (let i = 0; i < e.target.files.length; i++) {
        fileNames = fileNames + e.target.files[i].name;
        if (i !== e.target.files.length - 1) {
          fileNames = fileNames + ", ";
        }
      }
      fileOnChange(files);
      setMultipleFileName(fileNames);
    };
    return (
        <>
        <Label>{label || 'Multiple File Input'}</Label>
        <FormGroup className="form-file-upload form-file-simple">
            <Input
            type="text"
            className="inputFileVisible"
            placeholder={placeholder || "Insert File"}
            onClick={(e) => handleMultipleFileInput(e)}
            defaultValue={multipleFileName}
            />
            <input
            multiple
            type="file"
            className="inputFileHidden"
            style={{ zIndex: -1 }}
            ref={multipleFileRef}
             onChange={(e, type) => addMultipleFile(e, type)}
            />
        </FormGroup>
        </>
    )
}

export default MultipleFileInput