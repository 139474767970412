export const MutationColumn = [
        {
        Header: "Tanggal",
        accessor: "date",
        },
        {
        Header: "Nama",
        accessor: "name",
        },
        {
        Header: "QTY",
        accessor: "qty",
        },
        {
        Header: "Status",
        accessor: "damaged",
        },
        {
        Header: "Serial Number",
        accessor: "sn",
        },
        {
        Header: "Transfer Ke",
        accessor: "location",
        },
        {
        Header: "Harga",
        accessor: "price",
        },
        {
        Header: "IN/OUT",
        accessor: "statusLogistic",
        }
    ]