import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Row,
  Col,
  Button
} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions"
// import Switch from "react-bootstrap-switch";
import { getRefreshToken, addBankAccountData } from "variables/common";
import BankAccountService from "Main/BankAccountService";
import { useFormInput } from "Main/Utils";
import { getUserPermission } from "variables/common";
import CommonList from "components/Table/CommonList";

function BankAccountList(props) {
  const pages = 'bank-account'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const bankAccountService = new BankAccountService()
  const mitraLen = useFormInput('ALL')
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const refreshToken = getRefreshToken()

  const getBankAccount =  async() => {
    try {
      setDataLoad(true)
      const len = mitraLen.value
      const dat =  await bankAccountService.getAllBankAccount(len)
      const result = dat.data

      setDataTable(result)
      setDataState(mapping(result))
      setDataLoad(false)
      return result
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  useEffect( () => {
    getBankAccount()
  }, [])

  const viewBankAccount = async(name)=> {
    try {
      const dat = await bankAccountService.getBankAccount(name)
      const result = dat.data[0]
      addBankAccountData(result)
      props.history.push('/admin/bank-account-view')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const deleteBankAccount = (id) => swal("Delete", "Data Can't Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then(async(value) => {
      switch (value) {
        case 'del':
          try {
            setDataLoad(true)
            const result = await bankAccountService.deleteBankAccount(id)
            await swal(result.status, result.message, 'success')
            const data = await getBankAccount()
            const stateRaw = mapping(data)
            const State = stateRaw.filter((data) => data.id !== id)
            setDataState(State)
            setDataLoad(false)
            return true
          } catch (error) {
            setDataLoad(false)
            return await CatchError(error, props, refreshToken)
          }

        default:
          await getBankAccount()
          return false
      }
    })

    const AddBankAccount = () => {
      props.history.push('/admin/bank-account-page')
    }

  // const handleStatusChange = (event, row) => {
  //   statusOnChange({ id: row.id, check: event.state.value });
  // };
  const mapping = (TableData) => TableData.map((Data, i) => {
        try {
          return {
            id: Data.id,
            name: Data.name,
            bank: Data.bank_name,
            bankNumber: Data.bank_number,
            region: Data.bank_region,
            owner: Data.mitra_name,
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                {/* use this button to add a edit kind of action */}
                <Button
                  disabled = {!Permission.Read}
                  onClick={() => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    viewBankAccount(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}${i}`}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                {/* <UncontrolledTooltip
                    delay={0}
                    target={`tooltip${Data.id}${i}`}
                  >Update</UncontrolledTooltip> */}
                {/* use this button to remove the data row */}
                <Button
                  disabled = {!Permission.Delete}
                  onClick={() => {
                    deleteBankAccount(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltips${Data.id}2${i}`}
                  color="danger"
                  size="sm"
                >
                <i className="fa fa-times" />
                </Button>{" "}
                {/* <UncontrolledTooltip
                    delay={0}
                    target={`tooltips${Data.id}2${i}`}
                  >Delete</UncontrolledTooltip> */}
              </div>
            ),
        };
        } catch (error) {
          console.log(error)
          return []
        }
  })

  const [dataState, setDataState] = useState(mapping(Table))
  const columns = [
    {
      Header: "Nama",
      accessor: "name",
    },
    {
      Header: "Bank",
      accessor: "bank",
    },
    {
      Header: "Nomor Rekening",
      accessor: "bankNumber",
    },
    {
      Header: "Lokasi",
      accessor: "region",
    },
    {
      Header: "Update  Delete",
      accessor: "actions",
      sortable: false,
      filterable: false,
    },
  ]

  const menus = (
    <Row>
      <Col md={10}>
        <Button onClick={AddBankAccount} disabled = {!Permission.Create} color="info">Add</Button>
      </Col>
    </Row>
  )

  try {
    return (
      <>
      <PanelHeader size="sm" />
      {/* <PanelHeader
          content={
            <div className="header text-center">
              <h2 className="title">List Bank Account</h2>
            </div>
          }
        /> */}

        <div className="content">
          <CommonList
            key={1}
            header={'Bank Account'}
            columns={columns}
            dataState={dataState}
            menu={menus}
            load={load}
            getData={getBankAccount} />
        </div>
      </>
    )
  } catch (error) {
    return (
      <>
      <PanelHeader
          content={
            <div className="header text-center">
              <h2 className="title">List Bank Account</h2>
            </div>
          }
        />
      <h3>{error.message || error}</h3>
      </>
    )
  }
}

export default BankAccountList;
