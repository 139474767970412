import React from "react";
import { Card, CardBody } from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader";
import WebIframe from "components/WebIframe/WebInframe";
import WaGtawayService from "Main/WaService";

function WaGatewayPage(props) {
  const waGatewayService = new WaGtawayService()
  const src = waGatewayService.WaGatewayLink()

  return (
    <>
    <PanelHeader size="sm"/>
    <div className="content">
      <Card>
        <CardBody>
          <WebIframe
            src = {src.link}
          />
        </CardBody>
      </Card>
    </div>
    </>
  )
}

export default WaGatewayPage;
