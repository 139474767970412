import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { getRefreshToken } from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions"
import { validatePermission } from "Main/Utils";
import CommonList from "../../../../components/Table/CommonList"
import { ColumnsUser, ColumnsAdmin,
  ColumnsTrashAdmin,ColumnsTrashUser } from "./HostpotProfileColumns"
import { getUserPermission } from "variables/common";
import { verifySuperAdmin } from "Main/Utils";
import HostpotProfileService from "Main/HostpotVoucher/HostpotProfileService";
import { addHostpotProfileData } from "variables/HostpotVoucher/HostpotProfile";
import NoLabelSelect from "components/Input/NoLabelSelect";
import { getOwner } from "variables/common";
import { PriceFormat } from "variables/common";
import { payloadHostpotProfileTrash } from "variables/HostpotVoucher/HostpotProfile";

function HostpotProfileList(props) {
  const page = 'voucherhostpot'
  const hostpotProfileService = new HostpotProfileService()
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role.toLowerCase() === page) || ''
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const refreshToken = getRefreshToken()
  const [viewTrashPage, viewTrashPageOnChange] = useState(false)
  const { mitraSuper, organization } = verifySuperAdmin()
  const Columns = mitraSuper ? ColumnsAdmin : ColumnsUser
  const ColumnsTrash = mitraSuper ? ColumnsTrashAdmin : ColumnsTrashUser
  const ownerOption = getOwner()
  const [mitra, setMitra] = useState(ownerOption.find((data) => data.value === organization))

  const getAll =  async(mitra = '', trash = false) => {
    try {
      setDataLoad(true)
      const len = 'ALL'
      const dat =  await hostpotProfileService.getAll(len, trash, mitra)
      const result = dat.data

      setDataState(mapping(result))
      setDataTable(result)
      setDataLoad(false)
      return result
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  useEffect( () => {
    validatePermission(props, 0, 1)
    getAll(mitra.mitraId, viewTrashPage)
  }, [])

  const deleteData = async (id) => {
      const selection = await swal("Delete", "Data Tidak Dapat di Recover", "info", {
        buttons: {
          cancel: 'Batal',
          delete: {text: "Hapus", value: 'del' }
        }
      }
      )
      switch (selection) {
          case 'del':
            try {
              setDataLoad(true)
              const result = await hostpotProfileService.delete(id)
              await swal(result.status, result.message, 'success')
              await getAll(mitra.mitraid, viewTrashPage)
              const newState = dataState.filter(data => data.id !== id)
              setDataState(newState)
              viewTrashPageOnChange(false)
              setDataLoad(false)
              return true
            } catch (error) {
              return await CatchError(error, props, refreshToken)
            }

          default:
            await getAll(mitra.mitraId, viewTrashPage)
            return false
        }
    }

    const sendTrash = async (id, trash) => {
      try {
          setDataLoad(true)
          const payload = payloadHostpotProfileTrash(trash)
          const result = await hostpotProfileService.setTrash(id, payload)
          await swal('Success', result.message, 'success')
          if (trash === true) {
            const state = await getAll(mitra.mitraid, viewTrashPage)
            const stateRaw = mapping(state)
            const newState = stateRaw.filter(data => data.id !== id)
            setDataState(newState)
          } else {
            await getAll(mitra.mitraid, viewTrashPage)
            viewTrashPageOnChange(false)
          }
          setDataLoad(false)
          return true
      } catch (error) {
        setDataLoad(false)
        return await CatchError(error, props, refreshToken)
      }
    }

    const sendToTrash = async (id) => {
      const setTrash = await swal("Trash", "Send To Trash", "info", {
        buttons: {
          cancel: 'Cancel',
          delete: {text: "Continue", value: 'del' }
        }}
      )
      switch (setTrash) {
          case 'del':
            const trash = true
            return await sendTrash(id, trash)
          default:
            await getAll(mitra.mitraid, trash)
            return false
        }
    }

    const recoverTrash = async (id) => {
      const setTrash = await swal("Recover", "Recover Data", "info", {
        buttons: {
          cancel: 'Cancel',
          delete: {text: "Continue", value: 'del' }
        }}
      )

      switch (setTrash) {
        case 'del':
          const trash = false
          return await sendTrash(id, trash)
        default:
          return false
      }
    }

    const addData = () => {
      props.history.push('/admin/hostpot-profile-page')
    }

    const viewData = async(id) => {
      try {
        const dat = await hostpotProfileService.get(id)
        const result = dat.data
        addHostpotProfileData(result)
        props.history.push('/admin/hostpot-profile-update')
      } catch (error) {
        return await CatchError(error, props, refreshToken)
      }
    }

    const mapping =  (Table) => {
      const currency = 'idr'
      return Table.map((Data, i) => {
        try {
            return {
              key: i,
              id: Data.id,
              name: Data.name,
              mitra: Data.mitra,
              router: Data.routerName,
              buyingPrice: PriceFormat(Data.buyingPrice, currency),
              sellingPrice: PriceFormat(Data.sellingPrice, currency),
              rateUp: `${Data.rateUp} ${Data.rateUpMeasurement}`,
              rateDown: `${Data.rateDown} ${Data.rateDownMeasurement}`,
              actionsUser: (
                <div className="actions-right" key={Data.id}>
                  <Button
                    onClick={ async () => {
                      await viewData(Data.id)
                    }}
                    className="btn-icon btn-round"
                    id={`tooltip${Data.id}`}
                    disabled = {!Permission.Read}
                    color="warning"
                    size="sm"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  </div>
              ),
              actions: (
                // we've added some custom button actions
                <div className="actions-right" key={Data.id}>
                  <Button
                    onClick={ async () => {
                      await viewData(Data.id)
                    }}
                    className="btn-icon btn-round"
                    id={`tooltip${Data.id}`}
                    disabled = {!Permission.Read}
                    color="warning"
                    size="sm"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    onClick={async () => {
                      await sendToTrash(Data.id)
                    }}
                    className="btn-icon btn-round"
                    id={`tooltip${Data.id}1`}
                    disabled = {!Permission.Delete}
                    color="danger"
                    size="sm"
                  >
                  <i className="fa fa-trash" />
                  </Button>{" "}
                </div>
              ),
              recover: (
                <Button
                    onClick={ async () => {
                      await recoverTrash(Data.id)
                    }}
                    className="btn"
                    id={`tooltip${Data.id}`}
                    disabled = {!Permission.Update}
                    color="warning"
                    size="sm"
                  >
                   Recover
                  </Button>
              ),
              actionsTrash: (
                <div className="actions-right" key={Data.id}>
                  <Button
                    onClick={ async () => {
                      await deleteData(Data.id)
                    }}
                    className="btn-icon"
                    id={`tooltip${Data.id}1`}
                    disabled = {!Permission.Delete}
                    color="danger"
                    size="sm"
                  >
                  <i className="fa fa-times" />
                  </Button>{" "}
                </div>
              ),
          };
          } catch (error) {
            console.log(error.message)
            return []
          }
        }
      )
  }

  const [dataState, setDataState] = useState(mapping(Table))

  const viewTrash = async (trash) => {
    setDataLoad(true)
    const data = await getAll(mitra.mitraid, trash)
    setDataState(mapping(data))
    setDataLoad(false)
  }

  const customMenu = (
    <Row>
      <Col md={2}>
        <div  className="actions-left">
          <Button onClick={addData} color="info"
          disabled = {!Permission.Create}
          >Add</Button>
        </div>
      </Col>
      <Col md={8}>
        <NoLabelSelect
          placeholder = {"Mitra"}
          value = {mitra}
          option = {ownerOption}
          isDisabled = {!mitraSuper}
          onChange = {async (e) => {
            setMitra(e)
            await getAll(e.mitraid, viewTrashPage)
          }}
        />
      </Col>
      <Col md={2}>
        <div className="actions-right">
          <FormGroup check>
            <Label check> View Trash
              <Input type="checkbox"
              value = {viewTrashPage}
              checked = {viewTrashPage}
                onChange = {async (e) => {
                  viewTrashPageOnChange(e.target.checked)
                  await viewTrash(e.target.checked)
                }}
              />
              <span className="form-check-sign" />
            </Label>
          </FormGroup>
        </div>
      </Col>
    </Row>
  )

  return (
    <>
    <PanelHeader size="sm" />
      {/* <PanelHeader
        content={
          <div className="header text-center">
            <h2 className="title">List Hostpot Profile</h2>
          </div>
        }
      /> */}
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CommonList
                key={1}
                header={!viewTrashPage ? 'Hostpot Profile': 'Trash'}
                columns={!viewTrashPage ? Columns : ColumnsTrash}
                dataState={dataState}
                menu = {customMenu}
                Add={addData}
                load={load}
                getData={getAll}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default HostpotProfileList;
