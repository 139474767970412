import LoginService from "./../LoginService"
import APIconsume from './../APIconsume'
import { getAccessToken } from "variables/common"

class CrmService extends LoginService{
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._url = 'Billing/'
        this._consume = new APIconsume(accessToken)
    }

    async BroadcastMessage(payload) {
        const url = 'Email/Broadcast'
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async GetStaticTemplate() {
        const url = this._url + 'Invoice/static-template'
        const result = await this._consume.getData(url)
        return result.data
    }
}


export default CrmService;