import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

function PanelHeader(props) {
  return (
    <div
      className={
        "panel-header " +
        (props.size !== undefined ? "panel-header-" + props.size : "")
      }
    >
      {props.content}
    </div>
  );
}

PanelHeader.defaultProps = {
  size: undefined,
  content: null,
};

PanelHeader.propTypes = {
  // size of the panel header
  size: PropTypes.oneOf(["sm", "lg", "md", undefined]),
  // content
  content: PropTypes.node,
};

export default PanelHeader;
