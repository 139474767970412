import LoginService from './LoginService'
import APIconsume from './APIconsume'
import { getAccessToken } from "./../variables/common"

class BillingService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'Billing'
        this._sub = 'Subscription'
    }
    // Billing Address
    async addBillingAddress (payload) {
        const result = await this._consume.postData(payload, `${this._url}/address`)
        return result.data
    }

    async getBillingAddress (name) {
        const url = `${this._url}/address/${name}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllBillingAddress (len = 'ALL', page = '') {
        let url = `${this._url}/address/view?line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async putBillingAddress (name, payload) {
        const url = `${this._url}/address/${name}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async deleteBillingAddress (name) {
        const url = `${this._url}/address/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async syncBniVa (payload = {}) {
        const result = await this._consume.postData(payload, `VirtualAccount/sync`)
        return result.data
    }

    async addBilling (payload) {
        const result = await this._consume.postData(payload, `${this._sub}`)
        return result.data
    }

    async getBilling (name) {
        const url = `${this._sub}/${name}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getSubscriptionHistory (id) {
        const url = `${this._sub}/history/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllBilling (len = 'ALL', page = '') {
        let url = `${this._sub}/view?line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllSubscriptionCustomer (id = '', line = '', page = '') {
        let url = `${this._sub}/customer?id=${id}&line=${line}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllSubscriptionHistory (id = '', line = '', page = '') {
        let url = `${this._sub}/history/view?customerId=${id}&line=${line}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async putSubscriptionHistory (name, payload) {
        const url = `${this._sub}/history/${name}`
        console.log(url, payload)
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async putBilling (name, payload) {
        const url = `${this._sub}/${name}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async deleteBilling (name) {
        const url = `${this._sub}/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async addInvoice (payload) {
        const result = await this._consume.postData(payload, `${this._url}/Invoice`)
        return result.data
    }

    async renewInvoiceVa (payload) {
        const result = await this._consume.postData(payload, `${this._url}/Invoice/virtualAccount/renew`)
        return result.data
    }

    async getInvoice (id) {
        const url = `${this._url}/Invoice/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllInvoice (len = 'ALL') {
        const url = `${this._url}/Invoice/view/${len}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllInvoiceGroup (len = '', period = '', page = '') {
        let url = `${this._url}/Invoice/view?line=${len}&period=${period}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }


    async patchInvoice (id, payload) {
        const url = `${this._url}/Invoice/${id}`
        const result = await this._consume.patchData(payload, url)
        return result.data
    }

    async deleteInvoice (id) {
        const url = `${this._url}/Invoice/${id}`
        const result = await this._consume.deleteData(url)
        return result.data
    }


    async getDefaultTemplate(id) {
        const url = `${this._url}/Invoice/mail-template/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getPdfTemplate(id) {
        const url = `${this._url}/Invoice/pdf-template/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    // Get Invoice Period by CustomerId
    async getInvoicePeriod(id) {
        const url = `res/invoice-history/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getInvoiceListPeriod(mitra = '') {
        const url = `res/invoice-period-list?mitra=${mitra}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async downloadInvoice (payload) {
        const { mitra, type, period } = payload
        const url = `export/invoice`
        const filename = 'Invoice-' + mitra + '-' + type + '-' + period + '-Download_Date-' + new Date().toDateString()
        const result = await this._consume.GlobalDownloadPost(payload, url, filename)
        return result
    }

    async downloadInvoiceMutation (mitra, year, type) {
        const url = `payment-report/download?mitra=${mitra}&year=${year}&type=${type}`
        const filename = 'Invoice-' + mitra + '-' + type + '-' + year + '-Download_Date-' + new Date().toDateString()
        const result = await this._consume.GlobalDownload(url, filename)
        return result
    }
}

export default BillingService