export const ColumnsHistory = [
    {
        Header: "id",
        accessor: "id",
    },
    {
        Header: "Username",
        accessor: "username",
    },
    {
        Header: "Password",
        accessor: "password",
    },
    {
        Header: "Mitra",
        accessor: "mitra",
    },
    {
        Header: "Router",
        accessor: "router",
    },
    {
        Header: "Rate Up",
        accessor: "rateUp"
    },
    {
        Header: "Rate Down",
        accessor: "rateDown"
    },
    {
        Header: "Status",
        accessor: "status"
    },
    {
        Header: "View",
        accessor: "actions",
        sortable: false,
        filterable: false,
    },
]

export default ColumnsHistory