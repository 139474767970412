import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { CatchError } from "Main/Exceptions/exceptions";
import { useState } from "react";
import {getRefreshToken, getCustomerName,
  getUserData, sendEmail,
  getGlobalTemplate, getInvoiceData, renewInvoiceVa } from "./../../../variables/common"
import swal from "sweetalert";
import Select from "react-select"
import CustomerService from "Main/CustomerService";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState,
  convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useFormInput } from "Main/Utils"
import { selectMailTemplate } from "Main/Utils";
import LoadingScreen from "components/Loader/LoadingScreen";
import ErrorTable from "./ErrorTable";
import BillingService from "Main/BillingService";
import MultipleFileInput from "components/Input/MultipleFileInput";

function EmailPage(props) {
    const customerData = getCustomerName()
    const refreshToken = getRefreshToken()
    const invoice = getInvoiceData()
    // console.log(invoice)
    const invoiceId = invoice.invoiceId
    const paid = invoice.invoice[0].paid !== null || undefined
    const userData = getUserData()
    const Customer = customerData.customer
    const invoiceData = getInvoiceData()
    const selectTemplateOptions = getGlobalTemplate()
    const [template, templateOnChange] = useState(selectMailTemplate(selectTemplateOptions, invoiceData.invoice))
    const [editorState, editorStateOnChange] = useState(EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(template.value || '<h1>No Data</h1>'))))
    const [attachment, attachmentOnChamge] = useState({})
    const customerService = new CustomerService()
    const billingService = new BillingService()
    const title = useFormInput(`${userData.organization} Back Office`)
    const from = useFormInput(userData.organizationEmail)
    const to = useFormInput(customerData.customer.email)
    const phone = customerData.customer.mobileNo
    const subject = useFormInput(`${userData.organization} | Invoice Tagihan Bulanan | ${Customer.customer} | ${Customer.period} | ${Customer.year}`)
    const [loading, loadingOnChange] = useState(false)
    // const [renewResult, SetrenewResult] = useState([{renew: false, create: false, err: true, data: {data: [{status: 'tes'}]}}])
    const [renewResult, SetrenewResult] = useState([])

  // const getCustomer =  async() => {
  //     try {
  //       const dat =  await customerService.getCustomer(customerName.name)
  //       const result = dat.data
  //       console.log(result)
  //       customerDataOnChange(result)
  //       addCustomerData(result)
  //     } catch (error) {
  //       return CatchError(error, props, refreshToken)
  //       }
  //   }

  //   useEffect( () => {
  //     getCustomer()
  //   })

    // const sendRedirect = () => {
    //   const dotcom = to.value
    //   const mail = 'mailto:'
    //   window.open(mail  + dotcom)
    // }
    const updateTextDescription = async (state) => {
      await editorStateOnChange(state)
      convertToRaw(editorState.getCurrentContent())
      // console.log(htmlDat)
    }

    const SendEmail = async() => {
      swal("Send", "Notify Customer", "info", {
                  buttons: {
                  update: {text: "Send", value: 'up' },
                  cancel: 'Cancel'
                }
              }
        ).then(async(value) => {
          switch (value) {
            case 'up':
              loadingOnChange(true)
              const datPayload = draftToHtml(convertToRaw(editorState.getCurrentContent()))
              const To = to.value
              const payload = sendEmail(title.value, from.value, To, subject.value, datPayload, phone, attachment, invoiceId)
              // console.log(payload)
              try {
                const result = await customerService.sendEmail(payload)
                swal('Success', 'Email berhasil dikirim', 'success').then(() => {
                  props.history.push('/admin/Invoice-list')
                })
                return result
              } catch (error) {
                try {
                  const result = await customerService.sendEmailBack(payload)
                  swal('Success', 'Email berhasil dikirim dengan internal server', 'success').then(() => {
                    loadingOnChange(false)
                    props.history.push('/admin/Invoice-list')
                  })
                  return result
                } catch (error) {
                  loadingOnChange(false)
                  return await CatchError(error, props, refreshToken)
                }
              }

            default:
              break;
          }
        })
      }

    const RenewInvoice = async() => {
      swal("Renew", "Renew Invoice Virtual Account", "info", {
                  buttons: {
                  update: {text: "Renew", value: 'up' },
                  cancel: 'Cancel'
                }
              }
        ).then(async(value) => {
          switch (value) {
            case 'up':
              loadingOnChange(true)
              const payload = renewInvoiceVa(invoiceId)
              try {
                const result = await billingService.renewInvoiceVa(payload)
                swal('Success', 'Berhasil Renew Virtual Account', 'success').then(() => {
                  SetrenewResult(result.data)
                  loadingOnChange(false)
                })
                return result
              } catch (error) {
                loadingOnChange(false)
                return await CatchError(error, props, refreshToken)
              }

            default:
              break;
          }
        })
      }

    const children = (
      <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Kirim Pesan</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="12">
                        <FormGroup>
                        <Label>Title</Label>
                          <Input
                            {...title}
                            placeholder="title"
                            type="text"
                          />
                          </FormGroup>
                        <FormGroup>
                        <Label>Dari</Label>
                          <Input
                            {...from}
                            placeholder="Dari"
                            type="text"
                          />
                        </FormGroup>
                        <FormGroup>
                        <label>Email Tujuan</label>
                          <Input
                            placeholder="Email Tujuan"
                            {...to}
                          />
                        </FormGroup>
                        <FormGroup>
                        <label>WhatsApp Tujuan</label>
                          <Input
                            placeholder="WhatsApp Tujuan"
                            value={phone}
                          />
                        </FormGroup>
                        <FormGroup>
                        <label>Subject</label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Subject"
                            {...subject}
                          />
                        </FormGroup>
                        <Label>Template Pesan</Label>
                          <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Template"
                              name="singleSelect"
                              value = {template}
                              options={selectTemplateOptions}
                              onChange={(value) => {
                                templateOnChange(value)
                                editorStateOnChange(() =>
                                EditorState.createWithContent(
                                  ContentState.createFromBlockArray(
                                    convertFromHTML(value.value)
                                    )
                                  )
                                )
                              }}
                          />
                        <label>text</label>
                        <div>
                          <Editor
                             wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            editorState = {editorState}
                            onEditorStateChange = {updateTextDescription}
                          />
                        <textarea
                          disabled
                          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                        />
                        </div>
                        <MultipleFileInput
                          label = {'Attachment'}
                          placeholder = {'Multiple File...'}
                          fileOnChange = {attachmentOnChamge}
                        />
                      </Col>
                      </Row>
                  </Form>
                     <Button onClick = {SendEmail}
                      color="info" >
                        <i className="fab fa-google-plus-g" />Kirim Pesan</Button>
                     <Button onClick = {RenewInvoice}
                      color="primary"
                      disabled = {paid}
                      >Renew Virtual Account</Button>
                     <ErrorTable key={1} Data = {renewResult}/>
                </CardBody>
              </Card>
          </Col>
        </Row>
      </div>
  )
  return (
    <>
      <PanelHeader size="sm" />
      <LoadingScreen children={children} loading = {loading}/>
    </>
  );
}

export default EmailPage