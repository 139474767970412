import React from "react";
import Select from "react-select"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import {addMitraData, getMitraData,
  getRefreshToken, MitraPut,
  getUserType, addCustomerData,
  getUserData, getUserPermission,
  ExportsCSV, PriceFormat} from "./../../../variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions"
import UserService from "Main/UserService";
import { DateTimestr } from "Main/Utils";
import { useFormInput, verifyLength } from "Main/Utils";
import CommonList from "../../../components/Table/CommonList"
import Switch from "react-switch";
// import Switch from "react-bootstrap-switch";
import { setStatus } from "variables/common";
import { customerOperation } from "Main/Utils";
import BankAccountService from "Main/BankAccountService";
import { addVirtualAccountList } from "variables/common";
import { addLogisticGoodsCustomerList } from "variables/common";
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService";
import CustomerService from "Main/CustomerService";
import { customerGroupMitraColumns, customerTrashColumns } from "../Customer/CustomerColumns";
import BasicInput from "components/Input/BasicInput";
import { customTextFromBoolean } from "Main/Utils";
import InputCoordinate from "components/Input/InputCoordinate";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import DateIncrementInput from "components/Input/DateIncrementInput";
import Checklist from "components/Button/Checklist";
import NoLabelSelect from "components/Input/NoLabelSelect";
import { customerStatusOption } from "variables/referenceImport";
import { customTextAndBgColorFromBoolean } from "Main/Utils";
import { ModalColorSelector } from "Main/Utils";

function MitraView(props) {
  const pages = 'userManager'
  const currency = 'idr'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const PermissionCustomer = permission.find(data => data.Role === 'customer') || ''
  const userService =  new UserService()
  const refreshToken = getRefreshToken()
  const mitraData = getMitraData()
  const email = useFormInput(mitraData.email)
  const name = useFormInput(mitraData.name)
  const firstname = useFormInput(mitraData.firstname)
  const lastname = useFormInput(mitraData.lastname)
  const [ktpNumber, ktpNumberOnChange] = useState(mitraData.ktp)
  const [npwp, npwpOnChange] = useState(mitraData.npwp)
  const [MobileNo, mobileNoOnChange] = useState(mitraData.mobileNo)
  const [isGenerate, isGenerateOnChage] = useState(mitraData.generateInvoice || false)
  const namingSeries = useFormInput(mitraData.namingSeries || '')
  const generateDate = DateTimestr(mitraData.generateTime)
  const [Date, DateOnChange] = useState(mitraData.generateTime)
  const created = DateTimestr(mitraData.creation)
  const modified = DateTimestr(mitraData.modified)
  const createdBy = useFormInput(mitraData.modifiedBy)
  const modifiedBy = useFormInput(mitraData.modifiedBy)
  const [enabled, enabledOnChage] = useState(mitraData.enabled)
  const [dynamicInvoiceDate, dynamicInvoiceDateOnChage] = useState(mitraData.dynamicInvoiceDate)
  const [minLengthState, setminLengthState] = React.useState("");
  const [minLengthNpwpState, setminLengthNpwpState] = React.useState("");
  const [minLengthPhoneState, setminLengthPhoneState] = React.useState("");
  const [due, dueOnChange] = useState(mitraData.dueDate)
  const TypeOptions = getUserType()
  const [Type, TypeOnChange] = useState(TypeOptions.find(e => e.label === mitraData.type))
  const [ktpPassed, ktpPassedOnChange] = useState(false)
  const [mobileNoPassed, phonePassedOnChnage] = useState(false)
  const [ppn, ppnOnChange] = useState(mitraData.ppn)
  const [beforeDue, beforeDueOnChange] = useState(mitraData.beforeDue)
  const [administrationFee, setAdministrationFee] = useState(mitraData.administrationFee)
  const customerService =  new CustomerService()
  const bankAccountService = new BankAccountService()
  const logisticService = new LogisticGoodsService()
  const customerLen = useFormInput('ALL')
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const [status, statusOnChange] = useState({})
  const [viewTrash, viewTrashOnChange] = useState(false)
  const [maxMergeDate, setMaxMergeDate] = useState(mitraData.maxMergeDate)
  const [maxMergePrice, setMaxMergePrice] = useState(mitraData.maxMergePrice)
  const userData = getUserData()
  const usrType = userData.userType
  const mitraId = mitraData.id
  const refKey = mitraData.referenceKey
  const province = mitraData.province
  const filename = 'Import-Customer-' + mitraData.name
  const [latitude, setLatitude] = useState(mitraData.latitude)
  const [longitude, setLongitude] = useState(mitraData.longitude)
  const [instalationFee, setInstalationFee] = useState(mitraData.instalationFee)
  const [memberFee, setMemberFee] = useState(mitraData.memberFee)
  const [syncVa, setSyncVa] = useState(mitraData.syncVa)
  const [syncHostpot, setSyncHostpot] = useState(mitraData.syncHostpot)
  const [bypassExitingProRate, setBypassExitingProRate] = useState(mitraData.bypassExitingProRate)
  const [statusSelected, setStatusSelected] = useState(customerStatusOption[1])
  const [waGroupNotification, setWaGroupNotification] = useState(mitraData.waGroupNotification)
  const [sendNotification, setSendNotification] = useState(mitraData.sendNotification)
  const [allowInvoiceClosing, allowInvoiceClosingOnChage] = useState(mitraData.allowInvoiceClosing)

  const getCustomer =  async(filter, trash = false) => {
    try {
      setDataLoad(true)
      const len = customerLen.value
      const dat =  await userService.getAllCustomerByMitra(name.value, filter, len, trash)
      const result = dat.data

      setDataState(mapping(result))
      setDataTable(result)
      setDataLoad(false)
      return result
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  const deleteCustomer = async (id) => {
    swal("Delete", "Customer Data Can't Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then(async(value) => {
      switch (value) {
        case 'del':
          try {
            setDataLoad(true)
            await customerService.deleteCustomer(id)
            await swal('Success', 'Customer Sukses di Hapus', 'success')
            sessionStorage.removeItem('customerList')
            await getCustomer(statusSelected.value, viewTrash)
            const newState = dataState.filter(data => data.id !== id)
            setDataState(newState)
            viewTrashOnChange(false)
            setDataLoad(false)
            return true
          } catch (error) {
            return await CatchError(error, props, refreshToken)
          }

        default:
          await getCustomer(statusSelected.value, viewTrash)
          return false
      }
    })
  }

  const sendToTrash = async (id) => {
    swal("Trash", "hapus pelanggan ke Trash", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then(async(value) => {
      switch (value) {
        case 'del':
          try {
            setDataLoad(true)
            const result = await customerService.sendCustomerToTrash(id)
            await swal('Success', result.message, 'success')
            // sessionStorage.removeItem('customerList')
            const state = await getCustomer(statusSelected.value, viewTrash)
            const stateRaw = mapping(state)
            const newState = stateRaw.filter(data => data.id !== id)
            setDataState(newState)
            viewTrashOnChange(false)
            setDataLoad(false)
            // window.location.reload()
            return true
          } catch (error) {
            return await CatchError(error, props, refreshToken)
          }

        default:
          await getCustomer(statusSelected.value, viewTrash)
          return false
      }
    })
  }

  const recoverTrash = async (id) => {
    swal("Recover", "Recover data Pelanggan", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Recover", value: 'del' }
      }
    }
    ).then(async(value) => {
      switch (value) {
        case 'del':
          try {
            setDataLoad(true)
            const result = await customerService.recoverCustomerFromTrash(id)
            swal('Success', result.message, 'success').then( async () => {
              // sessionStorage.removeItem('customerList')
              const state = await getCustomer(statusSelected.value, viewTrash)
              // const newState = dataState.filter(data => data.id !== id)
              setDataState(mapping(state))
              viewTrashOnChange(false)
              setDataLoad(false)
              // viewTrashOnChange(false)
            })
            return true
          } catch (error) {
            return await CatchError(error, props, refreshToken)
          }

        default:
          // await getCustomer(true)
          return false
      }
    })
  }

  const addCustomer = () => {
    props.history.push('/admin/customer-page')
  }

  const viewCustomer = async(id)=> {
    try {
      // const pages = 1
      // const limits = 10
      const dat = await customerService.getCustomer(id)
      const vaData =  await bankAccountService.getAllVirtualAccount(id)
      const logistic =  await logisticService.getAllLogisticCustomer({customerid: id, len: customerLen.value})
      const vaResult = vaData.data
      const result = dat.data
      addCustomerData(result)
      addVirtualAccountList(vaResult)
      addLogisticGoodsCustomerList(logistic.data)
      props.history.push('/admin/customer-view')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

const setCustomerStatus = async(id, Status) => {
    try {
      // listStateOnChange([])
      const payload = setStatus(Status)
      var operation = customerOperation(Status)
      // setDataLoad(true)
      const dat = await customerService.setCustomerStatus(id, payload)
      await swal(operation, dat.message, dat.status)
      const result = dat.data
      // const statelist = listState.find(list => list.id === id)
      // console.log(listState, statelist)
      // statelist.status = Status
      const state = await getCustomer(statusSelected.value, viewTrash)
      const stateRaw = mapping(state)
      const newState = stateRaw.filter(data => data.id !== id)
      setDataState(newState)
      setDataLoad(false)
      return result
    } catch (error) {
      swal('failed', 'Gagal merubah status Customer', 'warning')
    }
  }

  const mapping =  (Table) => {
    return Table.map((Data, i) => {
      try {
          const {
            dueColorList,
            duePositiveColorList
          } = ModalColorSelector(Data)

          return {
            id: Data.id,
            name: Data.name,
            customerId: Data.id || 'no data',
            fullname: Data.fullname,
            teritory: Data.teritory.toLowerCase(),
            owner: Data.mitraName,
            virtualAccount: Data.va,
            networkId: Data.networkId,
            networkIdElement: (<div className="bg-success text-center font-weight-bold">{Data.networkId}</div>),
            subsPeriod: Data.lastSubs,
            subsPeriodElement: (
              <div className="text-center font-weight-bold">
                {customTextAndBgColorFromBoolean((Data.paidDate && Data.enabled && !Data.lastSubsUnpaid), Data.lastSubs || 'Periode Kosong', Data.lastSubs || 'Periode Kosong', duePositiveColorList, dueColorList)}
              </div>
            ),
            paidDate: customTextFromBoolean(!!Data.paidDate, DateTimestr(Data.paidDate), 'Belum-Bayar'),
            tax: customTextFromBoolean(Data.ppn, 'Yes', 'No'),
            status: (
                <div className="actions-center">
                <Switch
                disabled = {!PermissionCustomer.Update}
                  className="react-switch"
                  id = {`${Data.id}`}
                  onText={<i className="now-ui-icons ui-1_check" />}
                  offColor="#08f"
                  onColor="#08f"
                  offHandleColor="#E5FF2E"
                  onHandleColor="#7AE846"
                  // checked = {status}
                  offText={
                  <i className="now-ui-icons ui-1_simple-remove" />
                  }
                  checked = {Data.enabled}
                  value = {status}
                  onChange = { async(e) =>{
                    // handleStatusChange(e, Data.id)
                    // let obj = dataState.find((o) => o.id === Data.id);
                    statusOnChange(e)
                    await setCustomerStatus(Data.id, e)
                  }
                  }
                /></div>
            ),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                <Button
                disabled = {!PermissionCustomer.Read}
                  onClick={ async () => {
                    await viewCustomer(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}`}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                <Button
                disabled = {!PermissionCustomer.Delete}
                  onClick={async () => {
                    await sendToTrash(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}1`}
                  color="danger"
                  size="sm"
                >
                <i className="fa fa-trash" />
                </Button>{" "}
              </div>
            ),
            recover: (
              <Button
              disabled = {!PermissionCustomer.Update}
                  onClick={ async () => {
                    await recoverTrash(Data.id)
                  }}
                  className="btn"
                  id={`tooltip${Data.id}`}
                  color="warning"
                  size="sm"
                >
                 Recover
                </Button>
            ),
            actionsTrash: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                <Button
                disabled = {!PermissionCustomer.Delete}
                  onClick={ async () => {
                    await deleteCustomer(Data.id)
                  }}
                  className="btn-icon"
                  id={`tooltip${Data.id}1`}
                  color="danger"
                  size="sm"
                >
                <i className="fa fa-times" />
                </Button>{" "}
              </div>
            ),
        };
        } catch (error) {
          console.log(error)
          return []
        }
      })}

  const [dataState, setDataState] = useState(
    mapping(Table)
  );

  const getMitra =  async() => {
    try {
      const result =  await userService.getMitraData()
      addMitraData(result)
    } catch (error) {
      return await CatchError(error, props, refreshToken)
      }
  }

  const putMitra = async() => {
    // console.log(maxMergeDate, maxMergePrice)
    swal("Update", "Data Tidak Dapat di Recover", "info", {
            buttons: {
            update: {text: "Update", value: 'up' },
            cancel: 'Cancel'
          }
        }
    ).then(async(value) => {
      switch (value) {
        case 'up':
          try {
            const payload =  MitraPut(name.value, email.value, firstname.value, lastname.value,
                              MobileNo, ktpNumber, npwp, enabled, namingSeries.value, isGenerate,
                              Date, Type.value, due, ppn, beforeDue, administrationFee, maxMergeDate,
                              maxMergePrice, latitude, longitude, instalationFee, memberFee, syncVa,
                              syncHostpot, waGroupNotification, sendNotification, dynamicInvoiceDate,
                              allowInvoiceClosing)

            // console.log(waGroupNotification)
            const result = await userService.putMitra(name.value, payload)
            await swal(result.status, result.message, 'success')
            props.history.push('/admin/user-manager-mitra-list')
          } catch (error) {
            return await CatchError(error, props, refreshToken)
          }
          break;

        default:
          break;
        }
      }
    )
  }

  const viewCustomerTrash = async (trash) => {
    setDataLoad(true)
    const data = await getCustomer(statusSelected.value, trash)
    setDataState(mapping(data))
    setDataLoad(false)
  }

  const exportInvoiceCSV = async() => {
    try {
      const payload = ExportsCSV(["*"], mitraId)
      const rawData = await customerService.exportCSV(payload, filename)
      return rawData
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

   const exportInvoiceExcel = async() => {
    try {
      const payload = ExportsCSV(["*"], mitraId)
      const rawData = await customerService.exportExcel(payload, filename)
      return rawData
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const exportData = async() => {
      swal("Export ALL", "pilih tipe data", {
                buttons: {
                csv: {text: "CSV", value: 'csv' },
                excel: {text: "Excel", value: 'excl' },
                cancel: 'Cancel'
              }
            }
      ).then(async(value) => {
        switch (value) {
          case 'csv':
            try {
              await exportInvoiceCSV()
            } catch (error) {
              return CatchError(error, props, refreshToken)
            }
            break;
            case 'excl':
              try {
                await exportInvoiceExcel()
              } catch (error) {
                return CatchError(error, props, refreshToken)
              }
            break;

            default:
            break;
        }
    })
  }


  const customMenu = (
    <Row>
      <Col md={2}>
        <div className="actions-right">
          <FormGroup check>
            <Label check> View Trash
              <Input type="checkbox"
              value = {viewTrash}
              checked = {viewTrash}
                onChange = {async (e) => {
                  viewTrashOnChange(e.target.checked)
                  await viewCustomerTrash(e.target.checked)
                }}
              />
              <span className="form-check-sign" />
            </Label>
          </FormGroup>
        </div>
      </Col>
      <Col md = { 3 }>
        <NoLabelSelect
          placeholder="Pilih Status Customer"
          value = {statusSelected}
          onChange = {async (e) => {
            setDataLoad(true)
            await getCustomer(e.value, viewTrash)
            setStatusSelected(e)
            setDataLoad(false)
          }}
          option = {customerStatusOption}
        />
      </Col>
      <Col md={2}>
        <div className="actions-right">
          <Button onClick={exportData} color="info"
          disabled = {!PermissionCustomer.Read}
          >Export</Button>
        </div>
      </Col>
    </Row>
  )

  const mitraDetail = (
    <>
    <TabPanel>
      <Card>
        <CardHeader>
          <h5 className="title">{name.value}</h5>
        </CardHeader>
        <CardBody>
            <Col className="pr-1" md="12">
              <FormGroup>
                <BasicInput
                  key = {1}
                  label = {"Province"}
                  value = {province}
                  disabled
                  onChange = {() => {}}
                />
                <InputCoordinate
                  key = {2}
                  latitude = {latitude}
                  setLatitude = {setLatitude}
                  longitude = {longitude}
                  setLongitude = {setLongitude}
                />
                <BasicInput
                  key = {3}
                  label = {"Reference Key"}
                  value = {refKey}
                  disabled
                  onChange = {() => {}}
                />
                <Label>Firstname</Label>
                <Input
                  placeholder="firstname"
                  {...firstname}
                  type="text"
                />
                <Label>Lastname</Label>
                <Input
                  placeholder="lastname"
                  {...lastname}
                  type="text"
                />
                <Label>Fullname</Label>
                <Input
                  placeholder="fullname"
                  value={`${firstname.value} ${lastname.value}`}
                  type="text"
                />
                <div>
                <Label>Email</Label>
                <Input
                  className="react-select"
                  classNamePrefix="react-select"
                  placeholder="Email"
                  name="email"
                  type="email"
                  {...email}
                />
                <Label>KTP</Label>
                <Input
                  name="min_length_ktp"
                  type="number"
                  value = {ktpNumber}
                  onChange={(e) => {
                    if (!verifyLength(e.target.value, 17)) {
                      ktpNumberOnChange(e.target.value)
                    if (!verifyLength(e.target.value, 16)) {
                      setminLengthState("has-danger");
                      ktpPassedOnChange(true)
                    } else {
                      setminLengthState("has-success");
                      ktpPassedOnChange(false)
                    }
                    }
                  }}
                />
                {minLengthState === "has-danger" ? (
                  <label className="error">
                     KTP harus 16 Karakter.
                  </label>
                ) : null}
               </div>
               <div>
               <Label>NPWP</Label>
                <Input
                  name="min_length_npwp"
                  type="number"
                  value = {npwp}
                  onChange={(e) => {
                    if (!verifyLength(e.target.value, 16)) {
                      npwpOnChange(e.target.value)
                      if (!verifyLength(e.target.value, 15)) {
                        setminLengthNpwpState("has-danger");
                        ktpPassedOnChange(true)
                      } else {
                        setminLengthNpwpState("has-success");
                        ktpPassedOnChange(false)
                      }
                    }
                  }}
                />
                {minLengthNpwpState === "has-danger" ? (
                  <label className="error">
                    Please enter at least 16 characters.
                  </label>
                ) : null}
               </div>
               <div>
                <Label>Phone Number</Label>
                <Input
                  name="min_length_phone"
                  type="number"
                  value={MobileNo}
                  onChange={(e) => {
                    if (!verifyLength(e.target.value, 13)) {
                      mobileNoOnChange(e.target.value)
                      if (!verifyLength(e.target.value, 11)) {
                        setminLengthPhoneState("has-danger");
                        phonePassedOnChnage(true)
                      } else {
                        setminLengthPhoneState("has-success");
                        phonePassedOnChnage(false)
                      }
                    }
                  }}
                />
                {minLengthPhoneState === "has-danger" ? (
                  <label className="error">
                    Nomor Telepon Minimal 8 Karakter dan Maksimal 13 Karakter.
                  </label>
                ) : null}
               </div>
                <div className="bg-success bg-gradient font-weight-bold text-dark">
                  <h5 className="center">Notifikasi WhatsApp</h5>
                  <div className="mb-20">
                    <Label className={"text-dark"}>Nomor WhatsApp Tujuan</Label>
                    <Input
                      placeholder="Nomor WhatsApp"
                      value = {waGroupNotification}
                      onChange = {(e) => setWaGroupNotification(e.target.value)}
                      type="text"
                    />
                    <Checklist
                      classLabel = {'text-dark'}
                      key = {6}
                      text = 'Kirim Notifikasi Pelanggan Non-Aktif'
                      value = {sendNotification}
                      checked = {sendNotification}
                      onChange = {e => setSendNotification(e.target.checked)}
                    />
                  </div>
                  <Label  className={"text-danger"}>Pengiriman Notifikasi Pelanggan Non-Aktif di Jadwalkan setiap tanggal 1 Jam 08:00</Label>
               </div>
                <Label>Type</Label>
                <Select
                    placeholder="Type"
                    name="singleSelect"
                    value = {Type}
                    options = {TypeOptions}
                    onChange={e => TypeOnChange(e)}
                  />
                  <Label>Created</Label>
                  <Input
                    placeholder="Created"
                    value = {created}
                    type="text"
                  />
                  <Label>modified</Label>
                  <Input
                    placeholder="Modified"
                    value = {modified}
                    type="text"
                  />
                  <Label>created By</Label>
                  <Input
                    placeholder="created By"
                    value = {createdBy.value}
                    type="email"
                  />
                  <Label>modified By</Label>
                  <Input
                    placeholder="Modified By"
                    value = {modifiedBy.value}
                    type="email"
                  />
                </FormGroup>
                <FormGroup>
                <div className="bg-warning bg-gradient font-weight-bold text-dark">
                  <h5 className="center">Bisnis Model</h5>
                  <Checklist
                    classLabel = {'text-dark'}
                    key = {5}
                    text = 'Active'
                    value = {enabled}
                    checked = {enabled}
                    onChange = {e => enabledOnChage(e.target.checked)}
                  />
                  <Checklist
                    classLabel = {'text-dark'}
                    key = {5}
                    text = 'Tanggal Generate Invoice Mengikuti Aktivasi Pelanggan'
                    value = {dynamicInvoiceDate}
                    checked = {dynamicInvoiceDate}
                    onChange = {e => dynamicInvoiceDateOnChage(e.target.checked)}
                  />
                  <Checklist
                    classLabel = {'text-dark'}
                    key = {6}
                    text = 'Auto Generate Invoice'
                    value = {isGenerate}
                    checked = {isGenerate}
                    onChange = {e => isGenerateOnChage(e.target.checked)}
                  />
                  <Label className={"text-dark"}>Generate Date</Label>
                  <Input
                    placeholder="Generate"
                    value = {generateDate}
                    type="text"
                  />
                  <Label className={"text-dark"}>Set Invoice Generate Date</Label>
                  <Input
                    className = "generate"
                    placeholder="Generate Date"
                    disabled = {!isGenerate || dynamicInvoiceDate}
                    value = {Date}
                    onChange = {e => {
                        DateOnChange(e.target.value)
                      }}
                    type="datetime-local"
                  />
                  <DateIncrementInput
                    classLabel = {'text-dark'}
                    key = {7}
                    labelInput = {"Jatuh Tempo (MIN -30 MAX 120 Day)"}
                    labelOutput = {'Tanggal Tertampil'}
                    value = {due}
                    onChange={(e) => {
                      if (e.target.value >= -30 && e.target.value <= 120) {
                        dueOnChange(e.target.value)
                      }
                    }}
                  />
                  <DateIncrementInput
                    classLabel = {'text-dark'}
                    key = {8}
                    labelInput = {"Notifikasi Sebelum Jatuh Tempo (MAX 30 Day)"}
                    labelOutput = {'Tanggal Tertampil'}
                    value = {beforeDue}
                    dueDisplayValue = {due}
                    decr = {true}
                    onChange={(e) => {
                      if (e.target.value >= 0 && e.target.value <= 30) {
                        beforeDueOnChange(e.target.value)
                      }
                    }}
                  />
                  <Label className={"text-dark"}>PPN (Tax Percentage)</Label>
                  <Input
                    value = {ppn}
                    placeholder="PPN"
                    type="number"
                    onChange={(e) => {
                      if (e.target.value <= 100) {
                        ppnOnChange(e.target.value)
                      }
                    }}
                  />
                  <div className="row">
                    <div className="col md-6">
                      <BasicInput
                        classLabel = {'text-dark'}
                        key = {9}
                        label = {'Biaya Admin'}
                        value = {administrationFee}
                        type = {"number"}
                        onChange = {setAdministrationFee}
                      />
                    </div>
                    <div className="col md-6">
                      <BasicInput
                        classLabel = {'text-dark'}
                        key = {10}
                        value = {PriceFormat(administrationFee, currency)}
                        onChange = {()=>{}}
                        disabled
                        label="Harga Tertampil"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col md-6">
                      <BasicInput
                        classLabel = {'text-dark'}
                        key = {11}
                        label = {'Biaya Instalasi Jaringan'}
                        value = {instalationFee}
                        type = {"number"}
                        onChange = {setInstalationFee}
                      />
                    </div>
                    <div className="col md-6">
                      <BasicInput
                        classLabel = {'text-dark'}
                        key = {12}
                        value = {PriceFormat(instalationFee, currency)}
                        onChange = {()=>{}}
                        disabled
                        label={"Harga Tertampil"}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col md-6">
                      <BasicInput
                        classLabel = {'text-dark'}
                        key = {13}
                        label = {'Biaya Member Reseller'}
                        value = {memberFee}
                        type = {"number"}
                        onChange = {setMemberFee}
                      />
                    </div>
                    <div className="col md-6">
                      <BasicInput
                        classLabel = {'text-dark'}
                        key = {14}
                        value = {PriceFormat(memberFee, currency)}
                        onChange = {()=>{}}
                        disabled
                        label = {"Harga Tertampil"}
                      />
                    </div>
                  </div>
                  <Label className={"text-dark"}>Tanggal minimal Pengabungan Invoice (Customer Baru)</Label>
                  <Input
                    value = {maxMergeDate}
                    placeholder="Tanggal minimal Pengabungan Invoice"
                    type="number"
                    onChange={(e) => {
                      if (e.target.value >= 1 && e.target.value <= 25) {
                        setMaxMergeDate(e.target.value)
                      }
                    }}
                  />
                  <BasicInput
                    classLabel = {'text-dark'}
                    key = {15}
                    label = {'Harga Maksimal Pengabungan Invoice (Customer Lama)'}
                    value = {maxMergePrice}
                    type = {"number"}
                    onChange = {setMaxMergePrice}
                  />
                  <Input
                    value = {PriceFormat(maxMergePrice, currency)}
                    placeholder="Harga Maksimal Pengabungan Invoice"
                  />
                <Checklist
                  classLabel = {'text-dark'}
                  key = {16}
                  text = 'Sinkronisasi Harian Virtual Account'
                  value = {syncVa}
                  checked = {syncVa}
                  onChange = {(e) => setSyncVa(e.target.checked)}
                />
                <Checklist
                  classLabel = {'text-dark'}
                  key = {17}
                  text = 'Sinkronisasi Hostpot'
                  value = {syncHostpot}
                  checked = {syncHostpot}
                  onChange = {(e) => setSyncHostpot(e.target.checked)}
                />
                <Checklist
                  classLabel = {'text-dark'}
                  key = {5}
                  text = 'Izinkan Closing Invoice'
                  value = {allowInvoiceClosing}
                  checked = {allowInvoiceClosing}
                  onChange = {e => allowInvoiceClosingOnChage(e.target.checked)}
                />
                <Checklist
                  classLabel = {'text-dark'}
                  key = {17}
                  text = 'Nonaktif Pro-Rate pada Exiting Customer'
                  value = {bypassExitingProRate}
                  checked = {bypassExitingProRate}
                  onChange = {(e) => setBypassExitingProRate(e.target.checked)}
                />
              </div>
              <Button onClick = {putMitra} disabled = {!Permission.Update && mobileNoPassed & ktpPassed} color="info">Edit</Button>
            </FormGroup>
          </Col>
        </CardBody>
      </Card>
      </TabPanel>
    </>
  )

  const customerMitraList = (
    <>
    <TabPanel>
    { usrType === 'SUPERADMIN' ? (
        <Card>
          <CommonList
            key={1}
            header={!viewTrash ? 'Customer': 'Trash'}
            columns={!viewTrash ? customerGroupMitraColumns : customerTrashColumns}
            dataState={dataState}
            menu = {customMenu}
            Add={addCustomer}
            load={load}
            getData={getCustomer}
          />
        </Card>
        ) : <h3>No Permission</h3>
      }
    </TabPanel>
  </>
  )

  return (
      <>
      {/* <NotificationAlert ref={notificationAlert} /> */}
        <PanelHeader size = 'sm'/>
        <div className="content" onLoadStart ={getMitra}>
          <Card>
            <Tabs>
              <TabList>
                <Tab>Mitra Detail</Tab>
                <Tab>List Mitra Customer</Tab>
              </TabList>
              {mitraDetail}
              {customerMitraList}
            </Tabs>
          </Card>
        </div>
      </>
    );
}


export default MitraView;
