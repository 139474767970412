/*eslint-disable*/
import React from "react";
import { Container } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { appVersion } from "version.js";

function Footer(props) {
  return (
    <footer className={"footer" + (props.default ? " footer-default" : "")}>
      <Container fluid={props.fluid}>
        <nav>
          <ul>
            <li>
              <a
                className="mr-4-px"
                href="#"
                target="_blank">
                v.{appVersion}
              </a>
            </li>
            <li>
              <a
                href="https://stats.uptimerobot.com/LGLssG4lma"
                className="mr-4-px text-warning"
                target="_blank">Server Status
              </a>
            </li>
            <li>
              <a
                href="https://artamedianet.co.id/artamedia1/about/"
                className="mr-4-px"
                target="_blank">
                About Us
              </a>
            </li>
            <li>
              <a
                href="https://artamedianet.co.id/artamedia1/internet-dedicated/"
                target="_blank">
                Solution
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright mr-4-px">
          &copy; {1900 + new Date().getYear()}, Artamedia Citra Telematika Indonesia
        </div>
      </Container>
    </footer>
  );
}

Footer.defaultProps = {
  default: false,
  fluid: false,
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
