export const customerColumns = [
    {
        Header: "Nama",
        accessor: "name",
        maxWidth: 150
    },
    {
        Header: "Customer ID",
        accessor: "customerId",
        maxWidth: 150
    },
    {
        Header: "Network ID",
        accessor: "networkIdElement",
        maxWidth: 150
    },
    // {
    //     Header: "Lokasi",
    //     accessor: "teritory",
    //     maxWidth: 300
    // },
    {
        Header: "Mandiri VA",
        accessor: "virtualAccount",
        maxWidth: 150
    },
    {
        Header: "Periode Subscription",
        accessor: "subsPeriodElement",
        maxWidth: 150
    },
    // {
    //     Header: "Tanggal Pembayaran Terakhir",
    //     accessor: "paidDate",
    //     maxWidth: 150
    // },
    {
        Header: "Mitra",
        accessor: "owner",
        maxWidth: 150
    },
    {
        Header: "Status",
        accessor: "status",
        sortable: false,
        filterable: false,
        maxWidth: 75
    },
    {
        Header: "Update  Hapus",
        accessor: "actions",
        sortable: false,
        filterable: false,
        maxWidth: 100
    },
]

export const customerGroupMitraColumns = [
    {
        Header: "Name",
        accessor: "name",
        maxWidth: 150
    },
    {
        Header: "Customer ID",
        accessor: "customerId",
        maxWidth: 150
    },
    {
        Header: "Network ID",
        accessor: "networkIdElement",
        maxWidth: 150
    },
    {
        Header: "Mandiri VA",
        accessor: "virtualAccount",
        maxWidth: 150
    },
    // {
    //     Header: "Tanggal Pembayaran Terakhir",
    //     accessor: "paidDate",
    //     maxWidth: 150
    // },
    {
        Header: "Periode Subscription",
        accessor: "subsPeriodElement",
        maxWidth: 150
    },
    {
        Header: "Lokasi",
        accessor: "teritory",
        maxWidth: 150
    },
    {
        Header: "Status",
        accessor: "status",
        sortable: false,
        filterable: false,
        maxWidth: 75
    },
    {
        Header: "Update  Hapus",
        accessor: "actions",
        sortable: false,
        filterable: false,
        maxWidth: 100
    },
]

export const customerColumnsAdmin = [
    {
        Header: "Nama",
        accessor: "name",
        maxWidth: 150
    },
    {
        Header: "Customer ID",
        accessor: "customerId",
        maxWidth: 150
    },
    {
        Header: "Network ID",
        accessor: "networkIdElement",
        maxWidth: 150
    },
    // {
    //     Header: "Lokasi",
    //     accessor: "teritory",
    //     maxWidth: 300
    // },
    {
        Header: "Periode Subscription",
        accessor: "subsPeriodElement",
        maxWidth: 150
    },
    // {
    //     Header: "Tanggal Pembayaran Terakhir",
    //     accessor: "paidDate",
    //     maxWidth: 150
    // },
    {
        Header: "Mitra",
        accessor: "owner",
        maxWidth: 150
    },
    {
        Header: "Status",
        accessor: "StatustoUser",
        sortable: false,
        filterable: false,
        maxWidth: 100
    },
    {
        Header: "Update  Hapus",
        accessor: "actions",
        sortable: false,
        filterable: false,
        maxWidth: 100
    },
]

export const customerTrashColumns = [
    {
        Header: "Nama",
        accessor: "name",
    },
    {
        Header: "Customer ID",
        accessor: "customerId",
    },
    {
        Header: "Network ID",
        accessor: "networkIdElement",
        maxWidth: 150
    },
    {
        Header: "Periode Subscription",
        accessor: "subsPeriodElement",
        maxWidth: 150
    },
    // {
    //     Header: "Tanggal Pembayaran Terakhir",
    //     accessor: "paidDate",
    //     maxWidth: 150
    // },
    {
        Header: "Lokasi",
        accessor: "teritory",
    },
    {
        Header: "Owner",
        accessor: "owner",
    },
    {
        Header: "Recover",
        accessor: "recover",
        sortable: false,
        filterable: false,
    },
    {
        Header: "Hapus Permanen",
        accessor: "actionsTrash",
        sortable: false,
        filterable: false,
    },
]