import React from "react";
import Select from 'react-select'
import DateIncrementInput from "components/Input/DateIncrementInput";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken,
  getUserData,  Mitra, addMitraList,
  getUserType, 
  PriceFormat,
  getProvince} from "./../../../variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions"
import UserService from "Main/UserService";
import { useFormInput, verifyLength } from "Main/Utils";
import BasicInput from "components/Input/BasicInput";
import SelectBasic from "components/Input/SelectBasic";
import InputCoordinate from "components/Input/InputCoordinate";

function MitraPage(props) {
  const currency = 'idr'
  const userService = new UserService()
  const refreshToken = getRefreshToken();
  const userData = getUserData()
  const [minLengthState, setminLengthState] = React.useState("");
  const [minLengthNpwpState, setminLengthNpwpState] = React.useState("");
  const [minLengthPhoneState, setminLengthPhoneState] = React.useState("");
  // const [minLengthPhone, setminLengthPhone] = React.useState("");
  // const [minLengthNPWP, setminLengthNPWP] = React.useState("");
  const owner = userData.organization
  const name = useFormInput('')
  const firstname = useFormInput('')
  const lastname = useFormInput('')
  const email = useFormInput('')
  const [ktpNumber, ktpNumberOnChange] = useState('')
  const [npwpPassed, npwpPassedOnChange] = useState('')
  const [MobileNo, mobileNoOnChange] = useState('')
  const [enable, enableOnChange] = useState(false)
  const [npwp, npwpOnChange] = useState('')
  const [ktpPassed, ktpPassedOnChange] = useState(true)
  const [mobileNoPassed, phonePassedOnChnage] = useState(true)
  const [Type, TypeOnChange] = useState({})
  const [due, dueOnChange] = useState()
  const [ppn, ppnOnChange] = useState(11)
  const [province, setProvince] = useState('')
  const provinceOption = getProvince()
  const [beforeDue, beforeDueOnChange] = useState()
  const TypeOptions = getUserType()
  const optionsFilter = TypeOptions.filter((type) => type.value !== 'SUPERADMIN')
  const [administrationFee, setAdministrationFee] = useState(0)
  const [latitude, setLatitude] = useState(0.0)
  const [longitude, setLongitude] = useState(0.0)
  const [instalationFee, setInstalationFee] = useState(0)
  const [memberFee, setMemberFee] = useState(0)

  const getMitra =  async(len = 'ALL') => {
    try {
      const dat =  await userService.getAllMitra(len)
      const result = dat.data
        addMitraList(result)
      return result
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const submitMitra = async() => {
    try {
    const payload = Mitra(name.value, email.value, firstname.value, lastname.value,
                    MobileNo, ktpNumber, npwp, enable, Type.value, due, ppn,
                    beforeDue, administrationFee, province.value, latitude,
                    longitude, instalationFee, memberFee)

      const result = await userService.addMitra(payload)
      swal("success", result.message, result.status).then( async() => {
        await getMitra()
        props.history.push('/admin/user-manager-mitra-list')
      })
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}
  console.log(ktpPassed)
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Mitra Form</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <SelectBasic
                          key = {1}
                          label = {'Provinsi *'}
                          value = {province}
                          onChange = {setProvince}
                          option = {provinceOption}
                        />
                        <InputCoordinate
                          key = {2}
                          latitude = {latitude}
                          setLatitude = {setLatitude}
                          longitude = {longitude}
                          setLongitude = {setLongitude}
                        />
                        <Label>Name *</Label>
                        <Input
                            placeholder="Nama"
                            {...name}
                            type="text"
                          />
                        <Label>Created By</Label>
                        <Input
                            placeholder="Created By"
                            value = {owner}
                            type="text"
                          />
                        <Label>Nama Depan *</Label>
                        <Input
                            placeholder="Nama Depan"
                            {...firstname}
                            type="text"
                          />
                        <Label>Nama Belakang *</Label>
                        <Input
                            placeholder="Nama Belakang"
                            {...lastname}
                            type="text"
                          />
                        <Label>Nama Lengkap </Label>
                        <Input
                            placeholder="Nama Lengkap"
                            value = {`${firstname.value} ${lastname.value}`}
                            type="text"
                          />
                       <div>
                        <Label>Email</Label>
                          <Input
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder="Email"
                            name="email"
                            type="email"
                            {...email}
                          />
                        <Label>KTP</Label>
                        <Input
                          name="min_length_ktp"
                          type="number"
                          placeholder="KTP Number"
                          max={16}
                          value = {ktpNumber}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 17)) {
                              ktpNumberOnChange(e.target.value)
                            if (!verifyLength(e.target.value, 16)) {
                              setminLengthState("has-danger");
                              ktpPassedOnChange(true)
                            } else {
                              setminLengthState("has-success");
                              ktpPassedOnChange(false)
                            }
                            }
                          }}
                        />
                        {minLengthState === "has-danger" ? (
                          <Label className="error">
                            KTP harus 16 Karakter.
                          </Label>
                        ) : null}
                       </div>
                       <Label>NPWP</Label>
                        <Input
                          name="min_length_ktp"
                          type="number"
                          placeholder="NPWP"
                          max={16}
                          value = {npwp}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 16)) {
                              npwpOnChange(e.target.value)
                              if (!verifyLength(e.target.value, 15)) {
                                setminLengthNpwpState("has-danger");
                                npwpPassedOnChange(true)
                              } else {
                                setminLengthNpwpState("has-success");
                                npwpPassedOnChange(false)
                              }
                            }
                            // setminLengthNPWP(e.target.value);
                          }}
                        />
                        {minLengthNpwpState === "has-danger" ? (
                          <Label className="error">
                            NPWP Harus 15 Karakter.
                          </Label>
                        ) : null}
                       <div>
                        <Label>Nomor Telepon</Label>
                        <Input
                          name="min_length_phone"
                          type="number"
                          placeholder="Nomor Telepon"
                          max={12}
                          value={MobileNo}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 13)) {
                              mobileNoOnChange(e.target.value)
                            if (!verifyLength(e.target.value, 11)) {
                              setminLengthPhoneState("has-danger");
                              phonePassedOnChnage(true)
                            } else {
                              setminLengthPhoneState("has-success");
                              phonePassedOnChnage(false)
                            }
                            }
                            // setminLengthPhone(e.target.value);
                          }}
                        />
                        {minLengthPhoneState === "has-danger" ? (
                          <Label className="error">
                            Nomor Telepon Minimal 8 Karakter dan Maksimal 13 Karakter.
                          </Label>
                        ) : null}
                       </div>
                       <Label>Tipe</Label>
                        <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Type"
                            name="singleSelect"
                            value = {Type}
                            options = {optionsFilter}
                            onChange={e => TypeOnChange(e)
                          }
                          />
                          <DateIncrementInput
                            key = {3}
                            labelInput = {"Jatuh Tempo (MIN -30 MAX 120 Day)"}
                            labelOutput = {'Tanggal Tertampil'}
                            value = {due}
                            onChange={(e) => {
                              if (e.target.value >= -30 && e.target.value <= 120) {
                                dueOnChange(e.target.value)
                              }
                            }}
                          />
                          <DateIncrementInput
                            key = {4}
                            labelInput = {"Notifikasi Sebelum Jatuh Tempo (MAX 30 Day)"}
                            labelOutput = {'Tanggal Tertampil'}
                            value = {beforeDue}
                            dueDisplayValue = {due}
                            decr = {true}
                            onChange={(e) => {
                              if (e.target.value >= 0 && e.target.value <= 30) {
                                beforeDueOnChange(e.target.value)
                              }
                            }}
                          />
                          <Label>PPN (Tax Percentage)</Label>
                          <Input
                            value = {ppn}
                            placeholder="PPN"
                            type="number"
                            onChange={(e) => {
                              if (e.target.value <= 100) {
                                ppnOnChange(e.target.value)
                              }
                            }}
                          />
                          <div className="row">
                            <div className="col md-6">
                              <BasicInput
                                key = {5}
                                label = {'Biaya Admin'}
                                value = {administrationFee}
                                type = {"number"}
                                onChange = {setAdministrationFee}
                              />
                            </div>
                            <div className="col md-6">
                              <BasicInput
                                key = {6}
                                value = {PriceFormat(administrationFee, currency)}
                                onChange = {()=>{}}
                                disabled
                                label="Harga Tertampil"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col md-6">
                              <BasicInput
                                key = {7}
                                label = {'Biaya Instalasi Jaringan'}
                                value = {instalationFee}
                                type = {"number"}
                                onChange = {setInstalationFee}
                              />
                            </div>
                            <div className="col md-6">
                              <BasicInput
                                key = {8}
                                value = {PriceFormat(instalationFee, currency)}
                                onChange = {()=>{}}
                                disabled
                                label={"Harga Tertampil"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col md-6">
                              <BasicInput
                                key = {9}
                                label = {'Biaya Member Reseller'}
                                value = {memberFee}
                                type = {"number"}
                                onChange = {setMemberFee}
                              />
                            </div>
                            <div className="col md-6">
                              <BasicInput
                                key = {10}
                                value = {PriceFormat(memberFee, currency)}
                                onChange = {()=>{}}
                                disabled
                                label = {"Harga Tertampil"}
                              />
                            </div>
                          </div>
                          <FormGroup check>
                            <Label check> Activate Mitra
                              <Input type="checkbox"
                                value = {enable}
                                onChange = {e => enableOnChange(e.target.checked)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {submitMitra} disabled = {mobileNoPassed & npwpPassed} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default MitraPage;
