/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card
} from "reactstrap";

// core components
import swal from "sweetalert";
import CommonList from "../../../../components/Table/CommonList";
import { columns, userColumns } from "./SubscriptionAddOnHistoryColumn"
import { getUserPermission } from "variables/common";
import { getPaymentStatus } from "variables/common";
import { PriceFormat } from "variables/common";
import { verifySuperAdmin } from "Main/Utils";

function SubscriptionAddOnHistoryList({fetch, add, view, deleted, loaded, dataTable}) {
  const pages = 'billing'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  var statusOptions = getPaymentStatus()
  const { mitraSuper } = verifySuperAdmin()


  try {
    const mappingData = (data) => data.map((Data) => {
        console.log('mapping', Data)
        const price = Number(Data.total_price) + Number(Data.tax_prc)
        try {
            return {
            id: Data.id,
            name: Data.name,
            owner: Data.owner,
            price: PriceFormat(price, 'idr'),
            status: statusOptions.find(stat => stat.value ===  Data.paid).label,
            actionsSuper: (
              <>
              <div className="actions-right" key={Data.id}>
                  <Button
                    onClick={async () => {
                      await view(Data.id);
                    } }
                    className="btn-icon"
                    id={`tooltip${Data.id}`}
                    disabled={!Permission.Read}
                    color="warning"
                    size="sm"
                  >
                    <i className="fa fa-edit" />
                  </Button>{" "}
                  <Button
                    onClick={async () => {
                      const status = await deleted(Data.id);
                      if (status === true) {
                        await cropData(Data.id);
                      }
                    } }
                    className="btn-icon"
                    id={`tooltip${Data.id}1`}
                    disabled={!Permission.Delete}
                    color="danger"
                    size="sm"
                    >
                    <i className="fa fa-times" />
                  </Button>{" "}
                </div>
              </>

            ),
            actionsUser: (
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={ async () => {
                    await view(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Read}
                  color="warning"
                  size="sm"
                >
                <i className="fa fa-edit" />
                </Button>{" "}
              </div>
            ),
          };
          } catch (error) {
            console.log('mapping error', error.message)
            return []
          }
        })

    const [dataState, setDataState] = useState(mappingData(dataTable))

    const fetchData = async () => {
        const data = await fetch()
        console.log('datfecth', data)
        setDataState(mappingData(data))
        return data
    }

    const cropData = async (id) => {
        const state = await fetchData()
        const stateRaw = mappingData(state)
        const data = stateRaw.filter((data) => data.id !== id)
        setDataState(data)
    }

    console.log('datastate', dataState)


return (
        <>
        <CommonList
          key={1}
          header={'Riwayat Subscription Add-On'}
          columns={!mitraSuper ? userColumns : columns}
          dataState={dataState}
          // menu = {menu}
          Add = {add}
          addDisabled={Permission.Create}
          load={loaded}
          getData={fetchData}
        />
    </>
    );
  } catch (error) {
    swal('Error', error.message, 'warning')
    return (
        <>
          <Card>
            <div>
              <h5>{error.message}</h5>
            </div>
          </Card>
        </>
      );
  }
}

export default SubscriptionAddOnHistoryList;
