export const ColumnsUser = [
    {
        Header: "Nama",
        accessor: "name",
    },
    {
        Header: "Mitra",
        accessor: "owner",
    },
    {
        Header: "Pengeluaran",
        accessor: "outflow",
    },
    {
        Header: "File",
        accessor: "filename",
    },
    {
        Header: "View",
        accessor: "actionsUser",
        sortable: false,
        filterable: false,
    }
]

export const ColumnsAdmin = [
    {
        Header: "Nama",
        accessor: "name",
    },
    {
        Header: "Mitra",
        accessor: "owner",
    },
    {
        Header: "Pengeluaran",
        accessor: "outflow",
    },
    {
        Header: "File",
        accessor: "filename",
    },
    {
        Header: "Update  Trash",
        accessor: "actions",
        sortable: false,
        filterable: false,
    },
]

export const ColumnsTrashAdmin = [
    {
        Header: "Nama",
        accessor: "name",
    },
    {
        Header: "Mitra",
        accessor: "owner",
    },
    {
        Header: "Pengeluaran",
        accessor: "outflow",
    },
    {
        Header: "File",
        accessor: "filename",
    },
    {
        Header: "Recover",
        accessor: "recover",
        sortable: false,
        filterable: false,
    },
    {
        Header: "Hapus Permanen",
        accessor: "actionsTrash",
        sortable: false,
        filterable: false,
    },
]

export const ColumnsTrashUser = [
    {
        Header: "Nama",
        accessor: "name",
    },
    {
        Header: "Mitra",
        accessor: "owner",
    },
    {
        Header: "Pengeluaran",
        accessor: "outflow",
    },
    {
        Header: "Status",
        accessor: "approvalUser",
    },
    {
        Header: "File",
        accessor: "filename",
    },
]