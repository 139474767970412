import React, { useEffect } from "react";
import LoadingScreen from "components/Loader/LoadingScreen";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import {getRefreshToken, getOwner } from "variables/common"
import swal from "sweetalert";
import CustomerService from "Main/CustomerService";
import { CatchError } from "Main/Exceptions/exceptions";
import BasicInput from "components/Input/BasicInput";
import { payload } from "variables/Add-On/SubscriptionHistoryAddOn";
import { getSubscriptionHistoryData } from "variables/common";
import SubscriptionAddOnHistoryService from "Main/Add-On/SubscriptionHistoryAddOnService";
import SelectBasic from "components/Input/SelectBasic";
import Checklist from "components/Button/Checklist";
import { payloadGenerate } from "variables/Add-On/SubscriptionHistoryAddOn";

function SubscriptionAddOnHistoryPage(props) {
  const subscriptionAddOnHistoryService = new SubscriptionAddOnHistoryService()
  const customerService = new CustomerService()
  const refreshToken = getRefreshToken()
  const ownerData = getOwner()

  const BillingData = getSubscriptionHistoryData()
  const historyId = BillingData.Subscription.historyId
  const networkId = BillingData.Subscription.networkId
  const name = BillingData.Subscription.subscription
  const owner = ownerData.find((own) => own.value === BillingData.Subscription.owner)
  const customerName = BillingData.Subscription.customerName
  const [AddOnSubscription , setAddOnSubscription] = useState('')
  const [loading, loadingOnChange] = useState(false)
  const [AddOnSubscriptionOptions, setAddOnSubscriptionOptions] = useState([])
  const [autoGenerate, setAutoGenerate] = useState(true)

  const fetchData = async() => {
    const dat = await customerService.getSubscriptionAddOnSelect(networkId)
    const result = dat.data
    setAddOnSubscriptionOptions(result)
    return result
  }

  useEffect(() => {
    fetchData()
  }, [])

  const addSubscriptionAddOnHistoryData = async() => {
    try {
      loadingOnChange(true)
      const Payload = payload(owner.mitraid, AddOnSubscription.value, historyId)
      const payloadAutoGenerate = payloadGenerate(owner.mitraid, historyId)
      const result = !autoGenerate
        ? await subscriptionAddOnHistoryService.addData(Payload)
        : await subscriptionAddOnHistoryService.generate(payloadAutoGenerate)
      await fetchData()
      loadingOnChange(false)
      swal(result.status, result.message, "success").then(() => {
        props.history.push('/admin/Subscription-history')
      })
    } catch (error) {
      loadingOnChange(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  const children = (<div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Generate Riwayat Subscription Add-On Pelanggan</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <BasicInput
                          label = {"ID Jaringan"}
                          value = {networkId}
                        />
                        <BasicInput
                          label = {"ID Riwayat Subscription Add-On"}
                          value = {historyId}
                        />
                        <BasicInput
                          label = {"Subscription"}
                          value = {name}
                        />
                        <BasicInput
                          label = {"Customer"}
                          value = {customerName}
                        />
                        <SelectBasic
                          label = {"Subscription Add-On"}
                          value = {AddOnSubscription}
                          isDisabled = {autoGenerate}
                          option={AddOnSubscriptionOptions}
                          onChange={(value) => {
                            setAddOnSubscription(value)
                          }}
                        />
                        <Checklist
                          text = {"Generate Otomatis"}
                          value = {autoGenerate}
                          disabled = {true}
                          checked = {autoGenerate}
                          onChange={(e) => {
                            setAutoGenerate(e.target.checked)
                          }}
                        />
                      <Button onClick = {addSubscriptionAddOnHistoryData}
                        color="info">{!autoGenerate ? "Submit": "Generate Otomatis"}</Button>
                        </FormGroup>
                      </Col>
                      </Row>
                  </Form>
                </CardBody>
              </Card>
          </Col>
        </Row>
      </div>
    )

  return (
    <>
      <PanelHeader size="sm" />
      <LoadingScreen children={children}
      loading = {loading}/>
    </>
  );
}

export default SubscriptionAddOnHistoryPage;
