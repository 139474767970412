import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  Label
} from "reactstrap"

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { getDatalogData, getRefreshToken } from "variables/common"
import { CatchError } from "Main/Exceptions/exceptions"
import BankAccountService from "Main/BankAccountService"
import { DateTimestr } from "Main/Utils";
import { RenderJSon } from "components/Output/JsonView";


function DatalogView(props) {
  const bankAccountService =  new BankAccountService()
  const refreshToken = getRefreshToken()
  const datalog = getDatalogData()
  const method = datalog.method
  const status = datalog.status
  const created = datalog.creation
  const modified = datalog.modified
  const responses = datalog.response
  const getLog =  async() => {
    try {
      await bankAccountService.getBankAccount()
      // console.log(result)
    } catch (error) {
      return CatchError(error, props, refreshToken)
      }
  }

  return (
      <>
        <PanelHeader size = 'sm'/>
        <div className="content" onLoadStart ={getLog}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">{method}</h5>
                </CardHeader>
                <CardBody>
                <Form>
                  <Label>Method</Label>
                    <Input
                      placeholder="Created"
                      value = {method}
                      type="text"
                    />
                  <Label>Status</Label>
                    <Input
                      placeholder="Modified"
                      value = {status}
                      type="text"
                    />
                  <Label>Created</Label>
                    <Input
                      placeholder="Created"
                      value = {DateTimestr(created)}
                      type="text"
                    />
                  <Label>Modified</Label>
                    <Input
                      placeholder="Modified"
                      value = {DateTimestr(modified)}
                      type="text"
                    />
                   <Label>Detail</Label>
                    <RenderJSon
                      id={'main-render'}
                      jsonData = {responses}
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default DatalogView;
