/*eslint-disable*/
import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Row,
  Col,
  Button
} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions"
import { getRefreshToken, addGlobalTemplateData } from "variables/common";
import TemplateService from "Main/TemplateService";
import { templateType } from "Main/Utils";
import CommonList from "components/Table/CommonList";
import { useFormInput } from "Main/Utils";

function GlobalTemplateList(props) {
  const templateService = new TemplateService()
  const mitraLen = useFormInput('ALL')
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const refreshToken = getRefreshToken()

 try {
  const getGlobalTemplate =  async() => {
    try {
      setDataLoad(true)
      const len = mitraLen.value
      const dat =  await templateService.getAllGlobalTemplate(len)
      const result = dat.data
      setDataTable(result)
      setDataState(mapping(result))
      setDataLoad(false)
      return result
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  useEffect( () => {
    getGlobalTemplate()
  }, [])

  const viewGlobalTemplate = async(id)=> {
    try {
      const dat = await templateService.getGlobalTemplate(id)
      const result = dat.data[0]
      addGlobalTemplateData(result)
      props.history.push('/admin/global-template-view')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const deleteGlobalTemplate = (id) => swal("Hapus", "Data Tidak Dapat di Recover", "info", {
      buttons: {
        cancel: 'Batal',
        delete: {text: "Hapus", value: 'del' }
      }
    }
    ).then(async(value) => {
      switch (value) {
        case 'del':
          try {
            setDataLoad(true)
            const result = await templateService.deleteGlobalTemplate(id)
            await swal(result.status, result.message, 'success')
            const state = await getGlobalTemplate()
            const stateRaw = mapping(state)
            const table = stateRaw.filter((dat) => dat.id !== id)
            setDataState(table)
            setDataLoad(false)
            return true
          } catch (error) {
            setDataLoad(false)
            return await CatchError(error, props, refreshToken)
          }

        default:
          await getGlobalTemplate()
          return false
      }
    })

    const AddGlobalTemplate = () => {
      props.history.push('/admin/global-template-page')
    }

  const mapping = (TableData) => TableData.map((Data, i) => {
        try {
          return {
            id: Data.id,
            name: Data.name,
            type: Data.type,
            category: templateType(Data.priority, Data.warningNotification, Data.overdue, Data.paymentNotification, Data.activeNotification),
            owner: Data.mitraName,
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                {/* use this button to add a edit kind of action */}
                <Button
                  onClick={() => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    viewGlobalTemplate(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}${i}`}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                {/* <UncontrolledTooltip
                    delay={0}
                    target={`tooltip${Data.id}${i}`}
                  >Update</UncontrolledTooltip> */}
                {/* use this button to remove the data row */}
                <Button
                  onClick={ async () => {
                    await deleteGlobalTemplate(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltips${Data.id}2${i}`}
                  color="danger"
                  size="sm"
                >
                <i className="fa fa-times" />
                </Button>{" "}
                {/* <UncontrolledTooltip
                    delay={0}
                    target={`tooltips${Data.id}2${i}`}
                  >Delete</UncontrolledTooltip> */}
              </div>
            ),
        };
        } catch (error) {
          console.log(error)
          return []
        }
  })

  const [dataState, setDataState] = useState(mapping(Table))

  const columns = [{
                      Header: "Nama",
                      accessor: "name",
                    },
                    {
                      Header: "Tipe",
                      accessor: "type",
                    },
                    {
                      Header: "Kategori",
                      accessor: "category",
                    },
                    {
                      Header: "Owner",
                      accessor: "owner",
                    },
                    {
                      Header: "Update  Hapus",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },]
  const menus = (
    <Row>
      <Col md={10}>
        <Button onClick={AddGlobalTemplate} color="info">Add</Button>
      </Col>
    </Row>
  )

  return (
    <>
    <PanelHeader size="sm" />
      {/* <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Template</h2>
              </div>
            }
          /> */}
      <div className="content">
        <CommonList
          key={1}
          header={'Template Manager'}
          columns={columns}
          dataState={dataState}
          menu={menus}
          load={load}
          getData={getGlobalTemplate} />
      </div>
    </>
  )
  } catch (error) {
    return (
      <>
      <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Template</h2>
              </div>
            }
          />
      <h3>{error.message || error}</h3>
      </>
    )
  }
}

export default GlobalTemplateList;
