import React from "react";
import LoadingScreen from "components/Loader/LoadingScreen";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken,  getUserData, getOwner, PriceFormat } from "variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions";
import BasicInput from "components/Input/BasicInput";
import SelecttWithUserTier from "components/Input/SelectWithUserTier";
import SingleFileInput from "components/Input/SingleFileInput";
import { FormDataPayload } from "variables/MitraSubmission/FinanceOutflow";
import FinanceOutflowService from "Main/MitraSubmission/FinanceOutflowService";
import { addFinanceOutflowList } from "variables/MitraSubmission/FinanceOutflow";

function FinanceOutflowPage(props) {
  const financeOutflowService =  new FinanceOutflowService()
  const refreshToken = getRefreshToken();
  const userData = getUserData()
  const ownerData = getOwner()
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const [loading, loadingOnChange ] = useState(false)
  const [changeMitra, changeMitraOnChange] = useState(false)
  const [name, setName] = useState('')
  const [price, setPrice] = useState()
  const [spendingTime, setspendingTime] = useState('')
  const [desc, setDesc] = useState('')
  const [attachment, setAttachment] = useState()

  const getAll =  async(trash = false) => {
    const len = 'ALL'
    const result =  await financeOutflowService.getAllData(len, trash)
    addFinanceOutflowList(result.data)
  }

  const submit = async(e) => {
    e.preventDefault()
    try {
      loadingOnChange(true)
      //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = FormDataPayload(name, price, desc, attachment, spendingTime, owner.value)
      const result = await financeOutflowService.addData(payload)
      await getAll()
      loadingOnChange(false)
      swal(result.status, result.message, "success").then(() => {
        props.history.push('/admin/finance-outflow-list')
      })
    } catch (error) {
      loadingOnChange(false)
      return await CatchError(error, props, refreshToken)
  }}

  // function deleteSelect () {
  //   // todo
  // }

  const children = (
    <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Form Pengeluaran</h5>
                </CardHeader>
                <CardBody>
                  <SelecttWithUserTier
                    key={"1"}
                    label = {'Mitra'}
                    value = {owner}
                    onChange = {ownerOnChange}
                    disabled = {changeMitra}
                    options = {ownerData}
                    // subFunction = {deleteSelect}
                    checkText = {'Change Mitra'}
                    checkValue = {changeMitra}
                    checkValueOnChange = {changeMitraOnChange}
                  />
                  <BasicInput
                    key={"2"}
                    label = {'nama'}
                    value = {name}
                    onChange = {setName}
                    type
                  />
                  <BasicInput
                    key={"3"}
                    label = {'Harga'}
                    value = {price}
                    onChange = {setPrice}
                    type = {'number'}
                  />
                  <BasicInput
                    key={"4"}
                    label = {'Price Display'}
                    value = {PriceFormat(price, 'idr')}
                  />
                  <BasicInput
                    key={"5"}
                    label = {'Tanggal'}
                    value = {spendingTime}
                    onChange = {setspendingTime}
                    type = {'date'}
                  />
                  <SingleFileInput
                    key={"6"}
                    label = {'Attachment (Gambar, PDF, Ms Word, Ms Excell)'}
                    placeholder = {'Pilih File'}
                    setFile = {setAttachment}
                  />
                  <BasicInput
                    key={"7"}
                    label = {'Deskripsi'}
                    value = {desc}
                    onChange = {setDesc}
                    type = {'textarea'}
                  />
                  <Button onClick = {submit}
                  disabled = {!price}
                  color="info">Submit</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
  )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen
          children={children}
          loading = {loading}
          text={"Submit"}/>
      </>
    );
}


export default FinanceOutflowPage;
