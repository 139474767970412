const columns=[
        {
           Header: "Nama",
           accessor: "name",
         },
         {
           Header: "Pengukuran",
           accessor: "measurement",
         },
         {
           Header: "Tipe",
           accessor: "type",
         },
         {
           Header: "Organisasi",
           accessor: "organization",
         },
         {
           Header: "Serial Number",
           accessor: "sn",
         },
         {
           Header: "Update  Hapus",
           accessor: "actions",
           sortable: false,
           filterable: false,
         },
    ]
module.exports = { columns }