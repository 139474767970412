import { setIncrementDate } from 'Main/Utils';
import React from 'react';
import { Input, FormGroup, Label, Col, Row } from 'reactstrap';

function DateIncrementInput({labelInput, labelOutput, value, dueDisplayValue, onChange, disabled, decr = false, classLabel = ''}) {
    const today = new Date()
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0)
    const lastDayOfMonthFinal = new Date(lastDayOfMonth.setHours(23, 59))
    const incrementValue = !dueDisplayValue ? Number(value) : (Number(dueDisplayValue) - Number(value))
    return (
        <Row>
            <Col>
                <FormGroup>
                  <Label className={classLabel} for="latitudeInput">{labelInput}</Label>
                  <Input
                    type="number"
                    id="latitudeInput"
                    value={value}
                    onChange={e => onChange(e)}
                    disabled = {disabled}
                  />
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                  <Label className={classLabel} for="longitudeInput">{labelOutput}</Label>
                  <Input
                    type="text"
                    disabled
                    id="longitudeInput"
                    value={setIncrementDate(lastDayOfMonthFinal, incrementValue, decr)}
                    onChange={() => {}}
                  />
                </FormGroup>
            </Col>
        </Row>
    )
}

export default DateIncrementInput