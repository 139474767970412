import LoginService from './../LoginService'
import APIconsume from './../APIconsume'
import { getAccessToken } from "./../../variables/common"

class FinanceStatementService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'finance-report'
        this._urlResource = 'res/invoice-history/'
    }
    async getData ({mitra = '', year = ''}) {
        const url = `${this._url}?mitra=${mitra}&year=${year}`
        const result = await this._consume.getData(url)
        return result.data
    }


    async getAllFinanceReport () {
        const url = `${this._url}/view`
        const result = await this._consume.getData(url)
        return result.data
    }

    async downloadFinanceReport ({mitra = '', customer = '', year = '', month = '', type = '', mitraName = ''}) {
        const url = `${this._url}/download?mitra=${mitra}&customer=${customer}&year=${year}&month=${month}&type=${type}`
        const filename = 'FinanceReport-' + mitraName + '-' + year + '-' + (customer || 'ALL') + '-D_Date-' + new Date().toDateString()
        const result = await this._consume.GlobalDownload(url, filename)
        return result
    }

    async getInvoiceYear(mitra) {
        const url = `${this._urlResource}year/` + mitra
        const result = await this._consume.getData(url)
        return result.data
    }

    async getInvoiceMonth({year = '', mitra = ''}) {
        const url = `${this._urlResource}month?year=${year}&mitra=${mitra}`
        const result = await this._consume.getData(url)
        return result.data
    }
}

export default FinanceStatementService