import swal from "sweetalert"
import UserService from "Main/UserService"
import { deleteAddBlob } from "variables/WebStorage/LogisticBlobStorage"
import { deleteCustomerToWarehouseBlob } from "variables/WebStorage/LogisticBlobStorage"
import { deleteWarehouseToWarehouseBlob } from "variables/WebStorage/LogisticBlobStorage"
import { deleteWarehouseToCustomerBlob } from "variables/WebStorage/LogisticBlobStorage"
const userService = new UserService()


 export const CatchError = async (error, props, refreshtoken) => {
  const response =  error.response
  if (!response) {
    swal("Error", `Unxepected Error\n\n ${error.message}`,"warning" )
  } else {
    if (response.status === 401 && refreshtoken) {
        alert(`${response.status} ${response.statusText}`)
        try {
          await userService.refreshUser(refreshtoken)
          props.history.push('/admin/')
        } catch (error) {
          console.log(error.message)
          props.history.push('/auth/')
        }
    } else if (response.status === 401 && !refreshtoken) {
        props.history.push('/auth/')
    } else if(response.status === 403){
      props.history.push('/admin/forbidden')
    } else if(response.status === 500){
      props.history.push('/admin/server-error')
    } else if(response.status === 400 || response.status === 404 || response.status === 422){
      swal(`${response.status} ${response.statusText}`, `${response.data.message}`,"warning" )
    } else {
      swal("Error", `Unxepected Error\n\n ${error.message}`,"warning" )
  }
  }
}

export const CatchErrorFlushData = async (error, props, refreshtoken, userId, option = 0) => {
  const response =  error.response
  if (!response) {
    swal("Error", `Unxepected Error\n\n ${error.message}`,"warning" )
  } else {
    if (response.status === 401 && refreshtoken) {
        alert(`${response.status} ${response.statusText}`)
        try {
          await userService.refreshUser(refreshtoken)
          props.history.push('/admin/')
        } catch (error) {
          console.log(error.message)
          props.history.push('/auth/')
        }
    } else if (response.status === 401 && !refreshtoken) {
        props.history.push('/auth/')
    } else if(response.status === 403){
      props.history.push('/admin/forbidden')
    } else if(response.status === 500){
      props.history.push('/admin/server-error')
    } else if(response.status === 400 || response.status === 404 || response.status === 422){
      swal(`${response.status} ${response.statusText}`, `${response.data.message}`,"warning", {
            buttons: {
            cancel: 'Kembali',
            delete: {text: "Flush Data", value: 'del' }
          }
        }
      ).then(async(value) => {
          switch (value) {
            case 'del':
              if (option === 0) {
                deleteAddBlob(userId)
              } else if (option === 1) {
                deleteCustomerToWarehouseBlob(userId)
              } else if (option === 2) {
                deleteWarehouseToCustomerBlob(userId)
              } else if (option === 3) {
                deleteWarehouseToWarehouseBlob(userId)
              } else {
                console.log('No Option')
              }
              window.location.reload()
              break
            default:
              break
          }
        })
    } else {
      swal("Error", `Unxepected Error\n\n ${error.message}`,"warning" )
  }
  }
}

   export const CatchLoginError = (error) => {
    const response =  error.response
    if (!response) {
      swal("Error", `Server Offline \n\n ${error.message}`,"warning" )
    } else {
      if (!response.status) {
      swal("Error", `Unxepected Error\n\n ${error.message}`,"warning" )
      } else {
        swal(`${response.status} ${response.statusText}`, `${response.data.message}`,"warning" )
      }
    }
  }