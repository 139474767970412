import React from "react"
import LoadingScreen from "components/Loader/LoadingScreen"

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col
} from "reactstrap"

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js"
import { useState } from "react"
import { PriceFormat } from "variables/common"
import BasicInput from "components/Input/BasicInput"
import { DateTimestr } from "Main/Utils"
import Checklist from "components/Button/Checklist"
import { calculatePriceDiscount } from "Main/Utils"
import { getHostpotUserHistoryData } from "variables/HostpotVoucher/HostpotUserHistory"

function HostpotHistoryDetail(props) {
  const hostpotUser = getHostpotUserHistoryData()
  console.log(hostpotUser)
  const [mitra, setMitra] = useState(hostpotUser.mitra)
  const [username, setUsername] = useState(hostpotUser.username)
  const [password, setPassword] = useState(hostpotUser.password)
  const [discount, setDiscount] = useState(hostpotUser.discount)
  const [isPaid, setIsPaid] = useState(hostpotUser.isPaid)
  const [paidAmount, setPaidAmount] = useState(hostpotUser.paidAmount)
  const [description, setDescription ] = useState(hostpotUser.description)
  const [hostpotProfileId, setHostpotProfileId] = useState(hostpotUser.profileName)
  const [reseller, setReseller] = useState(hostpotUser.reseller)
  const [router, setRouter] = useState(hostpotUser.routerName)
  const { creation, modified, createdBy, modifiedBy, rateUpMeasurement, rateDownMeasurement, rateUp, rateDown, timeLimit, timeLimitMeasurement } = hostpotUser

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Detail History Voucher</h5>
                </CardHeader>
                <CardBody>
                  <BasicInput
                    key={"12323e"}
                    label = {'Mitra'}
                    disabled
                    value = {mitra}
                    onChange = {setMitra}
                    type
                  />
                  <BasicInput
                    key={"2dwedwed"}
                    label = {'Lokasi Router'}
                    value = {router}
                    disabled
                    onChange = {setRouter}
                    type
                  />
                  <BasicInput
                    key={"3dwed23e23"}
                    label = {'Hostpot Profile'}
                    value = {hostpotProfileId}
                    disabled
                    onChange = {(e) => {
                      setHostpotProfileId(e)
                    }}
                  />
                  <BasicInput
                    key={"4d2edwed"}
                    label = {"Reseller"}
                    value = {reseller}
                    disabled
                    onChange = {async (e) => {
                      setReseller(e)
                    }}
                  />
                  <BasicInput
                    key={"5wedwdd"}
                    label = {'Username'}
                    value = {username}
                    disabled
                    onChange = {setUsername}
                    type
                  />
                  <BasicInput
                    key={"6wedwe"}
                    label = {'Password'}
                    value = {password}
                    disabled
                    onChange = {setPassword}
                    type
                  />
                  <BasicInput
                    key={"7wedwed"}
                    label = {'Discount'}
                    value = {discount}
                    disabled
                    onChange = {(e) => {
                      if (e >= 0 && e <= 100) {
                        setDiscount(e)
                        setPaidAmount(calculatePriceDiscount(hostpotProfileId.price, e))
                      }
                    }}
                    type = {'number'}
                  />
                  <Checklist
                    key={"8wedwed"}
                    text = {"Lunas"}
                    value = {isPaid}
                    checked = {isPaid}
                    disabled
                    onChange = {(e) => {
                      setIsPaid(e.target.checked)
                      if (e.target.checked === true && !!hostpotProfileId) {
                        setPaidAmount(calculatePriceDiscount(hostpotProfileId.price, discount))
                      } else {
                        setPaidAmount(0)
                      }
                    }}
                  />
                  <BasicInput
                    key={"10wedwed"}
                    label = {'Nilai Bayar'}
                    value = {PriceFormat(paidAmount, 'idr')}
                    onChange = {() => {}}
                  />
                  <BasicInput
                    key={"11wdwe"}
                    label = {'Deskripsi'}
                    value = {description}
                    onChange = {setDescription}
                    disabled
                    type = {'textarea'}
                  />
                  <BasicInput
                    key={"12wedwed"}
                    label = {'Rate Upload'}
                    disabled
                    value = {`${rateUp} ${rateUpMeasurement}`}
                    onChange = {() => {}}
                  />
                  <BasicInput
                    key={"13wdwed"}
                    label = {'Rate Download'}
                    disabled
                    value = {`${rateDown} ${rateDownMeasurement}`}
                    onChange = {() => {}}
                  />
                  <BasicInput
                    key={"14wdwed"}
                    label = {'Limit Waktu'}
                    disabled
                    value = {`${timeLimit} ${timeLimitMeasurement}`}
                    onChange = {() => {}}
                  />
                  <BasicInput
                    key={"15wedwed"}
                    label = {'Creation'}
                    disabled
                    value = {DateTimestr(creation)}
                    onChange = {() => {}}
                  />
                  <BasicInput
                    key={"16d23d23d"}
                    label = {'Modified'}
                    disabled
                    value = {DateTimestr(modified)}
                    onChange = {() => {}}
                  />
                  <BasicInput
                    key={"17wedwed23e"}
                    label = {'CreatedBy'}
                    disabled
                    value = {createdBy}
                    onChange = {() => {}}
                  />
                  <BasicInput
                    key={"18jqweugfqeyfhwe"}
                    label = {'ModifiedBy'}
                    disabled
                    value = {modifiedBy}
                    onChange = {() => {}}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    </>
  )
}


export default HostpotHistoryDetail;
