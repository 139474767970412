const { getData, setData } = require("variables/common");

const identifier = ['ItemAddOnData', 'ItemAddOnList']
const [data, list] = identifier

export function addItemAddOnData(payload) {
  setData(data, payload)
}

export function getItemAddOnData() {
 return getData(data)
}

export function addItemAddOnList(payload) {
  setData(list, payload)
}

export function getItemAddOnList() {
 return getData(list)
}

export function payloadItemAddOn(mitra, name, category, price,  discount, Measurement, routerId,
    value) {
    return {
    mitra,
    name: name,
    category: category,
    price: Number(price),
    discount: Number(discount),
    measurement: Measurement,
    routerId,
    value
    }
}

export function payloadItemAddOnUpdate(name, category, price,  discount, Measurement, routerId,
    value) {
    return {
        name: name,
        category: category,
        price: Number(price),
        discount: Number(discount),
        measurement: Measurement,
        routerId,
        value
    }
}