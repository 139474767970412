import React, { useState, useEffect } from "react"

// reactstrap components
import {
  Card,
  Row,
  Col
} from "reactstrap"

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js"
import {getRefreshToken} from "variables/common"
import { CatchError } from "Main/Exceptions/exceptions"
import CommonList from "components/Table/CommonList"
import { HostpotSessionColumns } from "./HostpotSessionColumns"
import { verifySuperAdmin } from "Main/Utils"
import { getOwner } from "variables/common"
import NoLabelSelect from "components/Input/NoLabelSelect"
import RouterService from "Main/RouterService"

function HostpotSessionList(props) {
  const routerService = new RouterService()
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const refreshToken = getRefreshToken()
  const { mitraSuper, organization, userAdmin } = verifySuperAdmin()
  const ownerOption = getOwner()
  const [routerOption, setRouterOption] = useState([])
  const [mitra, setMitra] = useState(ownerOption.find((data) => data.value === organization))
  const [router, setRouter] = useState(routerOption.find(router => router.mitra === organization) || '')

  async function GetRouter(mitra) {
      const routerList = await routerService.getRouterSelect(mitra)
      const { data } = routerList

      setRouterOption(data)

      if (!data) {
        setRouter({})
        return ''
      }
      const defaultRouter = routerOption.find(router => router.mitraId === mitra)
      const finalSelection = !defaultRouter ? data[0] : defaultRouter
      setRouter(finalSelection)
      return finalSelection.value
  }

  const fetchHistory =  async(routerId = '') => {
    try {
      setDataLoad(true)
      const result =  await routerService.getHostpotSession(routerId)
      const { data = [] } = result

      setDataTable(data)
      setDataState(mapping(data))
      setDataLoad(false)
      return data
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  useEffect(() => {
    GetRouter(mitra.mitraid)
    fetchHistory(router.value)
  }, [])

  const mapping =  (Table) => {
    return Table.map((Data, i) => {
      try {
        const { server, user, address, macAddress, uptime, idleTime, bytesIn, bytesOut } = Data
          return {
            key: i,
            server,
            user,
            address,
            macAddress,
            uptime,
            idleTime,
            bytesIn,
            bytesOut
          }
        } catch (error) {
          return {
            key: i,
            server: error.message,
            user: '',
            address: '',
            macAddress: '',
            uptime: '',
            idleTime: '',
            bytesIn: '',
            bytesOut: ''
          }
        }
      }
    )
  }

  const [dataState, setDataState] = useState(mapping(Table))

  const customMenu = (
    <Row>
      <Col md={6}>
        <NoLabelSelect
          key = {'2efeerferf'}
          placeholder = {"Mitra"}
          value = {mitra}
          option = {ownerOption}
          isDisabled = {!mitraSuper}
          onChange = {async (e) => {
            setMitra(e)
            const routerSelected = await GetRouter(e.mitraid)
            await fetchHistory(routerSelected)
          }}
        />
      </Col>
      <Col md={6}>
        <NoLabelSelect
          key = {'3erferfer'}
          placeholder = {"Router NAS"}
          value = {router}
          option = {routerOption}
          onChange = {async (e) => {
            setRouter(e)
            await fetchHistory(e.value)
          }}
        />
      </Col>
    </Row>
  )

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CommonList
                key={'1dqwdqwd'}
                header={'Hostpot Client Session'}
                columns={HostpotSessionColumns}
                dataState={dataState}
                menu = {customMenu}
                load={load}
                getData={fetchHistory}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default HostpotSessionList;
