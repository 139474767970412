import React from "react";
import Select from "react-select"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken, PhoneCode, getProvince,
  addCity, addPhoneCodeList, addSubDistrict } from "./../../../variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions"
import PhoneCodeManagerService from "Main/PhoneCodeManagerService";
import CustomerService from "Main/CustomerService";
import { useFormInput, verifyLength } from "Main/Utils";

function PhoneCodeManagerPage(props) {
  const phoneCodeManagerService =  new PhoneCodeManagerService()
  const customerService = new CustomerService()
  const refreshToken = getRefreshToken()
  const [code, codeOnChange] = useState()
  const carrier = useFormInput('')
  const type = useFormInput('')
  var selectProvinceOptions = getProvince()
  const [ minLengthState, minLengthStateOnChange ] = useState()
  const [region, regionOnChange] = useState('')
  const [cityOptions, cityOptionsOnChange] = useState([])
  const [city, cityOnChange] = useState('')
  const [phoneCodeNumberPassed, phoneCodeNumberPassedOnChange] = useState(true)
  const [subDistrict , subDistrictOnChange] = useState('')
  const [subDistrictOptions, subDistrictOptionsOnChange] = useState([])

  const getPhoneCodeManager =  async(len = 'ALL') => {
    try {
      const dat =  await phoneCodeManagerService.getAll(len)
      const result = dat.data
      addPhoneCodeList(result)
      return result
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const CityLoad = async(city) => {
    const dat = await customerService.getCity(city.value)
    const result = dat.data
    console.log(result)
    addCity(result)
    return result
  }

  const subDistrictLoad = async(subDistrict) => {
    const dat = await customerService.getSubDistrict(subDistrict.value)
    const result = dat.data
    console.log(result)
    addSubDistrict(result)
    subDistrictOptionsOnChange(result)
    return result
  }


  const submit = async() => {
    try {
        let payload = PhoneCode(code, region.value, subDistrict.value, carrier.value, type.value)
      const result = await phoneCodeManagerService.add(payload)
      swal(result.status, result.message, "success").then(async () => {
        await getPhoneCodeManager()
        props.history.push('/admin/phoneCode-manager-list')
      })
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

  console.log(city)
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Phone Code Manager</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <Label>Phone Code</Label>
                        <Input
                          name="min_length_bank_number"
                          placeholder="Phone Code"
                          type="number"
                          value = {code}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 5)) {
                              codeOnChange(e.target.value)
                            }
                            if (!verifyLength(e.target.value, 1)) {
                              minLengthStateOnChange("has-danger");
                              phoneCodeNumberPassedOnChange(true)
                            } else {
                              minLengthStateOnChange("has-success");
                              phoneCodeNumberPassedOnChange(false)
                            }
                            // setminLength(e.target.value);
                          }}
                        />
                        {minLengthState === "has-danger" ? (
                          <label className="error">
                            Please enter at least 1 characters.
                          </label>
                        ) : null}
                        <Label>province</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Province"
                            name="singleSelect"
                            value = {region}
                            options = {selectProvinceOptions}
                            onChange={async(value) => {
                              // sessionStorage.removeItem('city')
                              const data = await CityLoad(value)
                              cityOnChange(null)
                              regionOnChange(value)
                              cityOptionsOnChange(data)
                            }
                              }
                            />
                            <div>
                            <Label>City</Label>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="City"
                              name="singleSelect"
                              value = {city}
                              options={cityOptions}
                              onChange={async (value) => {
                                // sessionStorage.removeItem('subDistrict')
                                cityOnChange(value)
                                subDistrictOnChange('')
                                const subDistrict = await subDistrictLoad(value)
                                subDistrictOptionsOnChange(subDistrict)
                              }}
                            />
                            {!city || !city.status ? (
                              <label className="error">
                                No Phone Code
                              </label>
                            ) : <label className="success">
                                Already have Phone Code
                              </label>}
                            </div>
                             <div>
                            <Label>Sub District</Label>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Sub District"
                              name="singleSelect"
                              value = {subDistrict}
                              options={subDistrictOptions}
                              onChange={async (value) => {
                                subDistrictOnChange(value)
                              }}
                            />
                            {!subDistrict || !subDistrict.status ? (
                              <label className="error">
                                No Phone Code
                              </label>
                            ) : <label className="success">
                                Already have Phone Code
                              </label>}
                            </div>
                        <Label>Carrier</Label>
                          <Input
                            placeholder="Carrier"
                            {...carrier}
                            type="text"
                        />
                        <Label>Type</Label>
                          <Input
                            placeholder="Type"
                            {...type}
                            type="text"
                        />
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {submit} disabled = {phoneCodeNumberPassed && (!subDistrict || subDistrict.status)} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default PhoneCodeManagerPage;
