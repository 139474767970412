/*eslint-disable*/
import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button
} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions"
// import Switch from "react-bootstrap-switch";
import Select from "react-select"
import { getRefreshToken, addDatalogList, getDatalogList, addDatalogData, getDatalogParam } from "variables/common";
import UserService from "Main/UserService";
import { addDatalogParam } from "variables/common";
import { DateTimestr } from "Main/Utils";
import { makePageValue } from "Main/Utils";
import Paginated from "components/Pagination/Paginated";


function DatalogList(props) {
  const userService = new UserService()
  const defaultVal = {value: '%', label: 'ALL'}
  const [load, setDataLoad] = useState(true)
  const [datalog, dataLogOnChange] = useState(getDatalogList())
  const refreshToken = getRefreshToken()
  const [page, pageOnChange] = useState({value: 1, label: 'Home'})
  const [limit, limitOnChange] = useState(100)
  const [param, paramOnChange] = useState(getDatalogParam())
  const [method, methodOnChange] = useState(defaultVal)
  const [status, statusOnChange] = useState(defaultVal)
  const methodOption = param.method || []
  const statusOption = param.status || []
  const canPreviousPage = param.previous
  const canNextPage = param.next
  const previous = param.previousPage
  const next = param.nextPage
  const totalPage = datalog.totalPage
  const totalPageOption = makePageValue(totalPage)
 try {
  const getDatalog =  async(pages = page.value, limits = limit, methods = method.value, stat = status.value) => {
    try {
      setDataLoad(true)
      // console.log('trigger')
      const dat =  await userService.getDatalog(pages, limits, methods, stat)
      const params = await userService.getDatalogParam()
      const result = dat.data
      const param = params.data
      addDatalogList(result)
      addDatalogParam(param)
      dataLogOnChange(result)
      paramOnChange(param)
      methodOption.push(defaultVal)
      statusOption.push(defaultVal)
      // const pagesCount = (pages <= 1) ? 1 : pages + 1
      pageOnChange({ value: pages, label: pages })
      // console.log('fetch')
      setDataLoad(false)
      return result
    } catch (error) {
      // console.log('err')
      setDataLoad(false)
      return CatchError(error, props, refreshToken)
    }
  }

  useEffect( () => {
    // validatePermission(props, 0, 1)
    getDatalog()
  }, [])

    const viewDatalog = async(data)=> {
    try {
      addDatalogData(data)
      props.history.push('/admin/datalog-view')
    } catch (error) {
      return CatchError(error, props, refreshToken)
    }
  }

  const gotoPage = async (key) => {
    setDataLoad(true)
    // console.log('goto', key)
    await getDatalog(key)
    // console.log('passed')
  }

  const previousPage = async () => {
    await getDatalog(previous)
    // console.log('passed')
  }

  const nextPage = async () => {
    await getDatalog(next)
    // console.log('passed')
  }


  const option = () => {
    return (
      <tr>
          <td colSpan={3}>
          <Select
            className="React-Select"
            placeholder="Page"
            name="singleSelect"
            value={page}
            options={totalPageOption}
            onChange={ async (value) => {
              pageOnChange(value);
              setDataLoad(true);
              await getDatalog(value.value);
            } } />
          </td>
          <td colSpan={5}>
            <Select className="React-Select"
              placeholder="Method"
              name="singleSelect"
              value={method}
              options={methodOption}
              onChange={(value) => {
                methodOnChange(value);
                setDataLoad(true);
                getDatalog(page.value, limit, value.value);
              } } /></td>
          <td colSpan={4}>
            <Select className="React-Select"
              placeholder="Status"
              name="singleSelect"
              value={status}
              options={statusOption}
              onChange={(value) => {
                statusOnChange(value);
                setDataLoad(true);
                getDatalog(page.value, limit, method.value, value.value);
              } } /></td>
        </tr>
    )
  }

  const ListLogData = () => {
    const dat = datalog.result
    if (load) {
      return (
          <Table responsive bordered hover>
          {option()}
            <thead className="text-primary">
              <tr>
                <th colSpan={1} className="text-center">#</th>
                <th colSpan={3} >Tanggal</th>
                <th colSpan={5} >Method</th>
                <th colSpan={2} >Code</th>
                {/* <th colSpan={1} className="text-center">Delete</th> */}
              </tr>
            </thead>
            <tbody>
               <tr>
                  <td colSpan={12} className="text-center">Loading</td>
               </tr>
            </tbody>
            {/* <Paginate/> */}
           </Table>
      )
    } else if (!dat) {
      return (
          <Table responsive bordered hover>
          {option()}
            <thead className="text-primary">
              <tr>
                <th colSpan={1} className="text-center">#</th>
                <th colSpan={3} >Tanggal</th>
                <th colSpan={5} >Method</th>
                <th colSpan={2} >Code</th>
                {/* <th colSpan={1} className="text-center">Delete</th> */}
              </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan={12} className="text-center">No Data</td>
                </tr>
            </tbody>
            {/* <Paginate/> */}
           </Table>
      )
    } else {
      return (
          <Table responsive bordered hover>
                {option()}
                  <thead className="text-primary">
                    <tr>
                      <th colSpan={1} className="text-center">#</th>
                      <th colSpan={3} >Tanggal</th>
                      <th colSpan={5} >Method</th>
                      <th colSpan={2} >Code</th>
                      {/* <th colSpan={1} className="text-center">Delete</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {dat.map((log) => {
                      return (
                      <tr key={log.id}>
                      <td colSpan={1} className="text-center">
                      <Button className="small danger" color="info"
                        onClick={ async() => {
                          await viewDatalog(log)
                        }}
                        >View</Button>
                      </td>
                      <td colSpan={3}>{DateTimestr(log.creation)}</td>
                      <td colSpan={5}>{log.method}</td>
                      <td colSpan={2}>{log.status}</td>
                    </tr>)
                    })}
                  </tbody>
                  {/* <Paginate/> */}
                </Table>
      )
    }
    }

  return (
        <>
          <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Network Trafic Log</h2>
              </div>
            }
          />
          <div className="content">
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md={6}>
                        <CardTitle tag="h4" className="title">Datalog</CardTitle>
                      </Col>
                      <Col md={6}>
                        <CardTitle tag="h4">Page {page.value}/{datalog.totalPage}</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Col md = {12}>
                      < ListLogData/>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    );
  } catch (error) {
    console.log(error)
      swal('Failed to Load Data').then(
      props.history.push('/admin/')
    )
  }
}


export default DatalogList;
