/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken, addVirtualAccountList,
   getRouterSelected, GridPole} from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions"
import RouterService from "Main/RouterService";
import InputCoordinate from "components/Input/InputCoordinate";
function GridPoleInventoryPage(props) {
  const router = getRouterSelected()
  const routerService = new RouterService()
  const refreshToken = getRefreshToken()
  const [name, nameOnChange] = useState('')
  const [height, heightOnChange] = useState('')
  const [description, descriptionOnChange] = useState('')
  const [latitude, setLatitude] = useState(0.0)
  const [longitude, setLongitude] = useState(0.0)

  const submit = async() => {
    try {
      const payload = GridPole(name, router.id, height, latitude, longitude, description)
      const result = await routerService.addGridPole(payload)
      swal(result.status, result.message, "success").then(async () => {
        const dat =  await routerService.getAllGridPole(router.id)
        const result = dat.data
        addVirtualAccountList(result)
        props.history.push('/admin/router-update')
      })
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Form Tiang Jaringan</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <Label>Nama</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Nama"
                            value = {name}
                            onChange={(e) => { nameOnChange(e.target.value) }
                            }
                          />
                        <InputCoordinate
                          latitude = {latitude}
                          setLatitude = {setLatitude}
                          longitude = {longitude}
                          setLongitude = {setLongitude}
                        />
                        <Label>Tinggi</Label>
                            <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            type="number"
                            placeholder="Tinggi"
                            value = {height}
                            onChange={ async (e) => {
                              heightOnChange(e.target.value)
                            }
                            }
                          />
                        <div>
                        </div>
                        <div>
                        <Label>Deskripsi</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Deskripsi"
                            value = {description}
                            onChange = {(e) => {descriptionOnChange(e.target.value)}}
                          />
                       </div>
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {submit} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default GridPoleInventoryPage;
