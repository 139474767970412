import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Select from 'react-select'
import { useState } from "react";
import {getRefreshToken,
  getLogisticWarehouseOption,
  addLogisticWarehouseList,
  getUserPermission} from "./../../../variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService";
import LogisticWarehouseService from "Main/Logistic/LogisticWarehouseService";

function LogisticExport(props) {
  const pages = 'Logistic'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const Service = new LogisticGoodsService()
  const refreshToken = getRefreshToken();
  const name = useFormInput('')
  const [warehouse, warehouseOnChange] = useState('')
  const [status, statusOnChange] = useState(false)
  const [load, loadOnChange] = useState(false)
  const logisticService = new LogisticWarehouseService()
  var Options = getLogisticWarehouseOption()
  var warehouseOptionsFilter = []
  warehouseOptionsFilter = warehouseOptionsFilter.concat([{value: '*', label: 'ALL'}], Options)

  const getAll =  async() => {
      try {
        const len = 'ALL'
        const dat =  await logisticService.getAll(len)
        const result = dat.data
        addLogisticWarehouseList(result)
        return result
      } catch (error) {
        return CatchError(error, props, refreshToken)
      }
    }

  const submit = async() => {
    try {
      loadOnChange(true)
      const rawData = await Service.exportLogistic(name.value, warehouse.value, status)
      swal("Success", "Export Success", "success").then( async () => {
        loadOnChange(false)
       await getAll()
       props.history.push('/admin/logistic-warehouse-list')
      })
      return rawData
    } catch (error) {
      loadOnChange(false)
      return CatchError(error, props, refreshToken)
    }
  }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Export Data Logistic (Excel)</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <Label>Gudang</Label>
                        <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Warehouse"
                            name="singleSelect"
                            value = {warehouse}
                            options = {warehouseOptionsFilter}
                            onChange={e => warehouseOnChange(e)
                          }
                        />
                        <Label>Nama File *</Label>
                          <Input
                            placeholder="Nama File"
                            {...name}
                            type="text"
                        />
                        <FormGroup check>
                          <Label check> Hanya Export Barang Rusak
                            <Input type="checkbox"
                            value = {status}
                                  onChange = {e => statusOnChange(e.target.checked)}
                            />
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {submit} disabled = {!Permission.Create && load} color="info">Export</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default LogisticExport;


