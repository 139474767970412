import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Select from 'react-select'
import { useState } from "react";
import {getRefreshToken, getLogisticGoodsData,
  getLogisticItemData, LogisticGoodsPut,
  getLogisticWarehouseData, getLogisticSupplierData,
  getUserPermission, getOwner} from "./../../../../variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import { getLogisticItemOption } from "variables/common";
import { getLogisticWarehouseOption } from "variables/common";
import { getLogisticSupplierOption } from "variables/common";
import { GetItemCurrency } from "variables/common";
import { PriceFormat } from "variables/common";
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService";
import { GlobalPropsPush } from "./FetchLogistic";
import { DateTimestr } from "Main/Utils";
import Checklist from "components/Button/Checklist";
import { FetchLogisticSelect } from "Main/Logistic/FetchLogistic";
import { useEffect } from "react";

function LogisticGoodsUpdate(props) {
  const pages = 'Logistic'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const Service = new LogisticGoodsService()
  var selectCurrencyOptions = GetItemCurrency()
  const [logisticItemOptions, setlogisticItemOptions] = useState(getLogisticItemOption())
  const [warehouseOptions, setwarehouseOptions] = useState(getLogisticWarehouseOption())
  const [supplierOptions, setsupplierOptions] = useState(getLogisticSupplierOption())
  const refreshToken = getRefreshToken();
  const LogisticGoodsData = getLogisticGoodsData()
  const itemData = getLogisticItemData()
  const warehouseData = getLogisticWarehouseData()
  const supplierData = getLogisticSupplierData()
  const id = LogisticGoodsData.id
  const itemId = itemData.id
  const warehouseId = warehouseData.id
  const supplierId = supplierData.id

  const name = useFormInput(LogisticGoodsData.name)
  const description = useFormInput(LogisticGoodsData.description)
  const [logisticItem, logisticItemOnChange] = useState(logisticItemOptions.find((data) => data.value === LogisticGoodsData.logisticItemId)  || '')
  const [warehouse, warehouseOnChange] = useState(warehouseOptions.find((data) => data.value === LogisticGoodsData.warehouseId))
  const [supplier, supplierOnChange] = useState(supplierOptions.find((data) => data.value === LogisticGoodsData.supplierId))
  const [sn, snOnChage] = useState(LogisticGoodsData.sn)
  const [damage, damageOnChange] = useState(LogisticGoodsData.damaged)
  const [ppn, ppnOnChange] = useState(LogisticGoodsData.ppn)
  const [priceValue, priceValueOnChange] = useState(LogisticGoodsData.price)
  const [paid, paidOnChange] = useState(LogisticGoodsData.paid)
  const qty= useFormInput(LogisticGoodsData.qty)
  const currency  = selectCurrencyOptions.find(data => data.label === 'Rupiah')
  const created = LogisticGoodsData.creation
  const modified = LogisticGoodsData.modified
  const createdBy = LogisticGoodsData.createdBy
  const modifiedBy = LogisticGoodsData.modifiedBy
  const owner = LogisticGoodsData.owner
  const ownerOption = getOwner()
  const mitra = ownerOption.find((data) => data.value === owner)

  useEffect(() => {
    FetchSelectData(mitra.mitraid)
  }, [])

  const Edit = async() => {
    swal("Update", "Perubahan Data Tidak Dapat di Batalkan", "info", {
                buttons: {
                update: {text: "Update", value: 'up' },
                cancel: 'Batal'
              }
            }
      ).then(async(value) => {
        switch (value) {
          case 'up':
            try {
            const payload = LogisticGoodsPut(name.value,
                logisticItem.value,
                supplier.value,
                warehouse.value,
                damage,
                priceValue,
                paid,
                qty.value,
                ppn,
                description.value)
              const result = await Service.put(id, payload)
              await GlobalPropsPush(props, itemId, warehouseId, supplierId, result)
            } catch (error) {
            return await CatchError(error, props, refreshToken)
            }
            break;

          default:
            break;
        }
      })
    }

    const Transfer = () => {
      props.history.push('/admin/logistic-warehouse-to-warehouse')
    }

  const FetchSelectData = async (mitra) => {
    const { logisticItem,
    logisticWarehouse,
    logisticSupplier } = await FetchLogisticSelect(mitra)
    setlogisticItemOptions(logisticItem)
    setwarehouseOptions(logisticWarehouse)
    setsupplierOptions(logisticSupplier)
    logisticItemOnChange(logisticItem.find((data) => data.value === itemId))
    warehouseOnChange(logisticWarehouse.find((data) => data.value === warehouseId))
    supplierOnChange(logisticSupplier.find((data) => data.value === supplierId))
  }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Detail Logistik Barang</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <Label>Nama *</Label>
                          <Input
                            placeholder="Nama"
                            {...name}
                            type="text"
                          />
                        <Label>Item Gudang</Label>
                        <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Item Gudang"
                            name="singleSelect"
                            value = {logisticItem}
                            options = {logisticItemOptions}
                            onChange={e => logisticItemOnChange(e)
                          }
                          />
                          <Label>Supplier</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Supplier"
                            name="singleSelect"
                            value = {supplier}
                            options = {supplierOptions}
                            onChange={e => supplierOnChange(e)
                          }
                          />
                          <Label>Gudang</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Gudang"
                            name="singleSelect"
                            value = {warehouse}
                            options = {warehouseOptions}
                            onChange={e => warehouseOnChange(e)
                          }
                          />
                          <FormGroup check>
                            <Label check> PPN
                              <Input type="checkbox"
                              value = {ppn}
                              checked={ppn}
                              onChange = {e => ppnOnChange(e.target.checked)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check> Rusak
                              <Input type="checkbox"
                              value = {damage}
                              checked={damage}
                              onChange = {e => damageOnChange(e.target.checked)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                          <Label>Serial Number</Label>
                          <Input
                          className="react-select"
                          type="textarea"
                          value={sn}
                          disabled={!logisticItem.sn}
                          onChange={(e) => snOnChage(e.target.value.toUpperCase())
                          }
                          />
                          <Checklist
                            text={'Lunas'}
                            value={paid}
                            onChange={(e) => {
                              paidOnChange(e.target.checked)
                            }}
                            checked={paid}
                          />
                          <Label>QTY</Label>
                          <Input
                            placeholder="QTY"
                            {...qty}
                            disabled={logisticItem.sn}
                            type="number"
                          />
                          <Label>Harga</Label>
                          <Input
                            placeholder="Harga"
                            value = {priceValue}
                            onChange = {(e) => {
                              if (e.target.value <= 0) {
                                priceValueOnChange(null)
                              }
                              if (e.target.value >= 0) {
                                priceValueOnChange(e.target.value)
                              }
                            }}
                            type="number"
                          />
                          <Input
                            placeholder="Harga"
                            value={PriceFormat(Number(priceValue), currency.value)}
                            type="text"
                          />
                          <Label>Deskripsi</Label>
                          <Input
                          className="react-select"
                          type="textarea"
                          {...description}
                          />
                          <Label>Owner</Label>
                          <Input
                            placeholder="Modified By"
                            value = {owner}
                            type="email"
                          />
                          <Label>Created</Label>
                          <Input
                            placeholder="Created"
                            value = {DateTimestr(created)}
                            type="text"
                          />
                          <Label>modified</Label>
                          <Input
                            placeholder="Modified"
                            value = {DateTimestr(modified)}
                            type="text"
                          />
                          <Label>created By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {createdBy}
                            type="email"
                          />
                          <Label>modified By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {modifiedBy}
                            type="email"
                          />
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {Edit} color="info" disabled = {!Permission.Update}>Edit</Button>
                  <Button onClick = {Transfer} color="info" disabled = {!Permission.Update}>Transfer Warehouse</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default LogisticGoodsUpdate;


