import { renderMainDeviceInventoryTable } from "variables/common"

const { Button } = require("reactstrap")

export const indoorDeviceColumns = [
        {
          Header: "ID",
          accessor: "id",
          maxWidth: 100
        },
        {
           Header: "Name",
           accessor: "name",
           maxWidth: 200
         },
         {
           Header: "Latitude",
           accessor: "latitude",
           maxWidth: 100
         },
         {
           Header: "Longitude",
           accessor: "longitude",
           maxWidth: 100
         },
        {
          Header: "Merk",
          accessor: "merk",
          maxWidth: 200
        },
        {
          Header: "Type",
          accessor: "type",
          maxWidth: 100
        },
        {
          Header: "Serial Number",
          accessor: "sn",
          maxWidth: 200
        },
        {
          Header: "Actions",
          accessor: "actions",
          sortable: false,
          filterable: false,
          maxWidth: 200
        },
    ]


function renderIndoorDeviceTable (dataset, view /*Function*/, deleteData /*Function*/, viewPermission, deletePermission) {
    const finalData = dataset || []
    return finalData.map((data) => {
        return {
          id: data.id,
          name: data.name,
          latitude: data.latitude,
          longitude: data.longitude,
          merk: data.merk,
          type: data.type,
          sn: data.sn,
          actions: (
            // we've added some custom button actions
            <div className="actions-right" key={data.id}>
            <Button
              onClick={ async () => {
                await view(data.id)
              }}
              disabled = {!viewPermission}
              className="btn-icon btn-round"
              color="warning"
              id={`tooltip${data.id}3`}
              size="sm">
            <i className="fa fa-edit" />
            </Button>{" "}
            <Button
              disabled = {!deletePermission}
              onClick={ async () => {
                  deleteData(data.id)
              }}
              className="btn-icon btn-round"
              id={`tooltip${data.id}4`}
              color="danger"
              size="sm">
              <i className="fa fa-times" />
            </Button>{" "}
            </div>
          ),
        }
     })
  }

export const renderMainIndoorDeviceTable = (dataset, view /*Function*/, deleteData /*Function*/, viewPermission, deletePermission) => {
    return renderMainDeviceInventoryTable(dataset, renderIndoorDeviceTable, view /*Function*/, deleteData /*Function*/, viewPermission, deletePermission)
}