import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import BillingService from "Main/BillingService";
import Select from 'react-select'
import { useState, useEffect } from "react";
import {invoice, getRefreshToken, addBillingOptions,
  getOwner, addInvoiceList, getUserData,
  getCustomerOptions } from "./../../../variables/common"
import CustomerService from "Main/CustomerService";
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import { mapInvoicetoPeriod } from "Main/Utils";
import LoadingScreen from "components/Loader/LoadingScreen";

function AddInvoice(props) {
  const billingService =  new BillingService()
  const customerService = new CustomerService()
  const refreshToken = getRefreshToken()
  var [selectSubscriptionOptions, selectCustomerOptionsOnChange] = useState(getCustomerOptions())
  const ownerData = getOwner()
  const userData = getUserData()
  const userType = userData.userType
  const [changeOwner, changeOwnerOnChange] = useState(false)
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const description = useFormInput(`Manual Generated, Company ${owner.value} ${new Date()}`)
  const [Subscription , subscriptionOnChange] = useState('')
  const [period, periodOnChange] = useState([])
  const [selectPeriodOptions, selectPeriodOptionsOnChange] = useState([])
  const [selectPeriodOptionsExits, selectPeriodOptionsExitsOnChange] = useState([])
  const [usePeriod, PeriodOnChange] = useState(false)
  const [specificInvoice, specificInvoiceOnChange] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const option = specificInvoice ? (usePeriod ? "Generate Previous" :"Generate") : "Generate All"

  const getInvoice =  async() => {
    const len = 'ALL'
    const result =  await billingService.getAllInvoiceGroup(len)
    addInvoiceList(result.data)
    return result.data
  }

  const getCustomer = async (mitraid) => {
    const customerOption = await customerService.getCustomerSelect(mitraid)
    console.log(customerOption.data)
    selectCustomerOptionsOnChange(customerOption.data)
  }

  const getInvoicePeriod =  async(id) => {
    const result =  await billingService.getInvoicePeriod(id)
    const invPeriodData = await filterDate(id, result.data)
    // console.log(invPeriodData)
    selectPeriodOptionsOnChange(invPeriodData.invPeriod)
    // console.log('View Period: ', selectPeriodOptionsExits)
    // console.log('INV Period: ', invPeriodData)
    selectPeriodOptionsExitsOnChange(invPeriodData.invPeriodExits)
  }

  useEffect(() => {
    const data = async () => {
      const select = await customerService.getBillingSelect()
      addBillingOptions(select)
      }
    data()
    })

  const filterDate = async(customerid, invPeriodData) => {
    const invData = await getInvoice()
    // console.log('view inv data: ', invData)
    const invFilter = invData.filter((inv) => inv.customerId === customerid)
    const invMap = mapInvoicetoPeriod(invFilter)
    // console.log(invMap)
    // const periodData = period
    const invPeriod = invPeriodData.filter(function(item) {
      const inv = invMap.find(invDat => invDat.month.replaceAll(' ', '').toLowerCase() ===
      item.value[0].month.replaceAll(' ', '').toLowerCase() && invDat.year === item.value[0].year)
      // console.log(state1, state2, state1.length, state2.length)
      if (inv){
        return false;
      }
      return true;
    })

    const invPeriodExits = invPeriodData.filter(function(item) {
      const inv = invMap.find(invDat => invDat.month.replaceAll(' ', '').toLowerCase() ===
      item.value[0].month.replaceAll(' ', '').toLowerCase() && invDat.year === item.value[0].year)
      // console.log(state1, state2, state1.length, state2.length)
      if (!inv){
        return false;
      }
      return true;
    })

    return {invPeriod, invPeriodExits}
  }



  const addInvoiceData = async() => {
  try {
    setLoading(true)
    let payload = invoice(owner.mitraid)
    if (!period) {
      payload = invoice( owner.mitraid, Subscription.value, description.value)
    }
    if (period && specificInvoice) {
      payload = invoice( owner.mitraid, Subscription.value, description.value, period.value)
    }
    console.log(payload)
    const result = await billingService.addInvoice(payload)
    await getInvoice()
    const subsExits = result.data.subsExits[0]
    if (!subsExits) {
      await swal('No Subscription', 'Customer Tidak Memiliki Subscription', "info")
      setLoading(false)
      return
    }
    await swal(result.status, result.message, "success")
    if (!usePeriod) {
      props.history.push('/admin/Invoice-list')
      setLoading(false)
      return
    }
    setLoading(false)
    return await getInvoicePeriod(Subscription.value)
  } catch (error) {
    setLoading(false)
    return await CatchError(error, props, refreshToken)
  }}

  function deleteSelect() {
    subscriptionOnChange([])
    periodOnChange([])
    selectPeriodOptionsOnChange([])
  }

  const children = (
      <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Generate Invoice</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <Label>Mitra</Label>
                          {
                          (userType === 'SUPERADMIN') ? (
                            <>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="owner"
                              value={owner}
                              isDisabled = {!changeOwner}
                              options={ownerData}
                              onChange={ async(e) => {
                                ownerOnChange(e);
                                await getCustomer(e.mitraid)
                                deleteSelect()
                              } } />
                              <FormGroup check>
                                <Label check> Ganti Mitra
                                  <Input type="checkbox"
                                  value = {changeOwner}
                                  checked = {changeOwner}
                                  onChange = {async (e) => {
                                    ownerOnChange(ownerData.find(own => own.value === userData.organization))
                                    changeOwnerOnChange(e.target.checked)
                                    await getCustomer(owner.mitraid)
                                    if (owner.value !== userData.organization) {
                                      deleteSelect()
                                      selectCustomerOptionsOnChange(getCustomerOptions())
                                    }
                                    }
                                  }
                                  />
                                  <span className="form-check-sign" />
                                </Label>
                              </FormGroup>
                              </>) : (
                              <Input
                              placeholder="Mitra"
                              value={owner.value}
                              />
                              )
                          }
                        <label>Customer</label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Select Customer"
                            name="singleSelect"
                            isDisabled = {!specificInvoice}
                            value = {Subscription}
                            options = {selectSubscriptionOptions}
                            onChange={async(value) => {
                              await getInvoicePeriod(value.value)
                              subscriptionOnChange(value)
                              periodOnChange([])
                            }
                            }
                          />
                        <FormGroup check>
                          <Label check> Generate Specific Invoice
                            <Input type="checkbox"
                            value={specificInvoice}
                            onChange={(e) => {specificInvoiceOnChange(e.target.checked)
                            subscriptionOnChange('')
                            }}
                            />
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <label>Periode Subscription</label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Periode Subscription"
                            name="singleSelect"
                            isDisabled= {!usePeriod}
                            value = {period}
                            options = {selectPeriodOptions}
                            onChange={(value) => periodOnChange(value)}
                          />
                        {selectPeriodOptionsExits.length >= 1 ? (
                          <label className="error">
                            {selectPeriodOptionsExits.length >= 1 && !selectPeriodOptions.length ? 'Semua' :
                            selectPeriodOptionsExits.length} Periode Invoice telah di generate, {selectPeriodOptions.length} belum di generate
                          </label>
                          ) : null}
                        <FormGroup check>
                          <Label check> Generate Invoice Bulan Sebelum-nya
                            <Input type="checkbox"
                            value={usePeriod}
                            onChange={(e) => {PeriodOnChange(e.target.checked)
                            periodOnChange([])
                            }}
                            />
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                      <label>Deskripsi</label>
                        <Input
                            placeholder="Deskripsi"
                            {...description}
                            type="textarea"
                          />
                        </FormGroup>
                      </Col>
                      </Row>
                  </Form>
                </CardBody>
              </Card>
            <Button onClick = {addInvoiceData} color="info">{option}</Button>
            </Col>
          </Row>
        </div>
    )

  return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen children={children} loading = {loading} text={"Generate Invoice"}/>
      </>
    );
}


export default AddInvoice;
