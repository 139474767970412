import React from "react";
import LoadingScreen from "components/Loader/LoadingScreen";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken,  getOwner, PriceFormat } from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import BasicInput from "components/Input/BasicInput";
import SelecttWithUserTier from "components/Input/SelectWithUserTier";
import SelectBasic from "components/Input/SelectBasic";
import Checklist from "components/Button/Checklist";
import { payloadHostpotUser } from "variables/HostpotVoucher/HostpotUser";
import UserService from "Main/UserService";
import { verifySuperAdmin } from "Main/Utils";
import { useEffect } from "react";
import HostpotUserService from "Main/HostpotVoucher/HostpotUserService";
import HostpotProfileService from "Main/HostpotVoucher/HostpotProfileService";
import Select from 'react-select';
import RouterService from "Main/RouterService";
import { calculatePriceDiscount } from "Main/Utils";

function HostpotUserPage(props) {
  const hostpotUserService =  new HostpotUserService()
  const hostpotProfileService = new HostpotProfileService()
  const routerService = new RouterService()
  const { mitraSuper, organization, id: userId, userAdmin } = verifySuperAdmin()
  const userService = new UserService()
  const refreshToken = getRefreshToken();
  const ownerData = getOwner()
  const [mitra, setMitra] = useState(ownerData.find(own => own.value === organization))
  const [loading, loadingOnChange ] = useState(false)
  const [changeMitra, changeMitraOnChange] = useState(false)

  const [hostpotProfileId, setHostpotProfileId] = useState([])
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [discount, setDiscount] = useState(0)
  const [isPaid, setIsPaid] = useState(false)
  const [paidAmount, setPaidAmount] = useState(0)
  const [description, setDescription ] = useState('')
  const [resellerOption, setResellerOption] = useState([]) // getUserSelect())
  const [hostpotProfileOption, setHostpotProfileOption] = useState([])
  const [reseller, setReseller] = useState(resellerOption.find((data) => data.userId === userId))
  const [routerSelectOption, routerSelectOptionOnChange] = useState([])
  const [router, setRouter] = useState('')

  const getRouter = async(mitraid) => {
    const dat = await routerService.getRouterSelect(mitraid)
    const result = dat.data
    setReseller([])
    setHostpotProfileId([])
    routerSelectOptionOnChange(result)
    return result
  }

  async function GetReseller(mitra) {
      const resellerList = await userService.getUserSelect(mitra)
      const resellerData = resellerList.data

      setResellerOption(resellerData)

      const defaultReseller = resellerData.find((data) => data.userId === userId)
      const finalSelection = !defaultReseller ? resellerData[0] : defaultReseller
      setReseller(finalSelection)
      return finalSelection.userId
  }

  async function GetHostpotProfile(routerId) {
      const resellerList = await hostpotProfileService.getProfileSelect(routerId)
      const resellerData = resellerList.data

      setHostpotProfileOption(resellerData)
      return resellerData
  }

  useEffect(() => {
    getRouter(mitra.mitraid)
    GetReseller(mitra.mitraid)
  }, [])

  const submit = async(e) => {
    e.preventDefault()
    try {
      loadingOnChange(true)
      const payload = payloadHostpotUser( mitra.mitraid, hostpotProfileId.value, reseller.userId, username, password, discount, isPaid, paidAmount, description)
      console.log(payload)
      const result = await hostpotUserService.post(payload)
      await swal(result.status, result.message, "success")
      loadingOnChange(false)
      props.history.push('/admin/hostpot-user-list')
    } catch (error) {
      loadingOnChange(false)
      return await CatchError(error, props, refreshToken)
  }}

  const children = (
    <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Form Hostpot User</h5>
                </CardHeader>
                <CardBody>
                  <SelecttWithUserTier
                    key={"1"}
                    label = {'Mitra'}
                    value = {mitra}
                    onChange = {setMitra}
                    disabled = {changeMitra}
                    subFunction = {async (e) => {
                      routerSelectOptionOnChange([])
                      setResellerOption([])
                      setHostpotProfileOption([])
                      setReseller('')
                      setHostpotProfileId('')
                      setRouter('')
                      await getRouter(e.mitraid)
                      await GetReseller(e.mitraid)
                    }}
                    options = {ownerData}
                    checkText = {'Change Mitra'}
                    checkValue = {changeMitra}
                    checkValueOnChange = {changeMitraOnChange}
                  />
                  <Label>Lokasi Router</Label>
                  <div>
                    <Select
                      placeholder="Lokasi Router"
                      name="singleSelect"
                      value = {router}
                      options={routerSelectOption}
                      onChange={ async (e) => {
                          setRouter(e)
                          await GetHostpotProfile(e.value)
                        }
                      }
                    />
                    {!router || !router.status ? (
                      <label className="error">
                        Router Offline
                      </label>
                    ) : <label className="success">
                        Router Online
                      </label>}
                  </div>
                  <BasicInput
                    key={"2"}
                    label = {'Username'}
                    value = {username}
                    onChange = {setUsername}
                    type
                  />
                  <BasicInput
                    key={"3"}
                    label = {'Password'}
                    value = {password}
                    onChange = {setPassword}
                    type
                  />
                  <SelectBasic
                    key={"4"}
                    label = {'Hostpot Profile'}
                    value = {hostpotProfileId}
                    onChange = {(e) => {
                      setHostpotProfileId(e)
                    }}
                    option = {hostpotProfileOption}
                  />
                  <SelectBasic
                    key={"5"}
                    label = {"Reseller"}
                    value = {reseller}
                    option = {resellerOption}
                    isDisabled = {!mitraSuper && !userAdmin}
                    onChange = {async (e) => {
                      setReseller(e)
                    }}
                  />
                  <BasicInput
                    key={"6"}
                    label = {'Discount'}
                    value = {discount}
                    onChange = {(e) => {
                      if (e >= 0 && e <= 100) {
                        setDiscount(e)
                        setPaidAmount(calculatePriceDiscount(hostpotProfileId.price, e))
                      }
                    }}
                    type = {'number'}
                  />
                  {!mitraSuper ? null : (
                    <Checklist
                      key={"7"}
                      text = {"Lunas"}
                      value = {isPaid}
                      onChange = {(e) => {
                        setIsPaid(e.target.checked)
                        if (e.target.checked === true && !!hostpotProfileId) {
                          setPaidAmount(calculatePriceDiscount(hostpotProfileId.price, discount))
                        } else {
                          setPaidAmount(0)
                        }
                      }}
                    />
                  )

                  }
                  <BasicInput
                    key={"8"}
                    label = {'Nilai Bayar'}
                    value = {paidAmount}
                    onChange = {setPaidAmount}
                    disabled = {!isPaid}
                    type = {'number'}
                  />
                  <BasicInput
                    key={"9"}
                    label = {'Price Display'}
                    value = {PriceFormat(paidAmount, 'idr')}
                  />
                  <BasicInput
                    key={"10"}
                    label = {'Deskripsi'}
                    value = {description}
                    onChange = {setDescription}
                    type = {'textarea'}
                  />
                  <Button
                    onClick = {submit}
                    disabled = {!username}
                    color="info"
                  >Submit</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
  )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen
          children={children}
          loading = {loading}
          text={"Submit"}/>
      </>
    );
}

export default HostpotUserPage;
