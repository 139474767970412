import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Select from 'react-select'
import { useState } from "react";
import {getRefreshToken,
  getLogisticTypeOption, GetItemMeasurement,
  addLogisticItemList,
  getOwner,
  LogisticItemAdd} from "./../../../variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import LogisticItemService from "Main/Logistic/LogisticItemService";
import { verifyLength } from "Main/Utils";
import SelectBasic from "components/Input/SelectBasic";
import { verifySuperAdmin } from "Main/Utils";

function LogisticItemPage(props) {
  const Service = new LogisticItemService()
  const refreshToken = getRefreshToken();
  const name = useFormInput('')
  const description = useFormInput('')
  const [logisticType, logisticTypeOnChange] = useState('')
  const [sn, snOnChage] = useState(false)
  const [measurement, measurementOnChange] = useState(true)
  var logisticTypeOptions = getLogisticTypeOption()
  var measurementOptions = GetItemMeasurement()
  const [manualId, manualIdOnChange] = useState(false)
  const [idManual, idManualOnChange] = useState()
  const ownerOption = getOwner()
  const { mitraSuper, organization } = verifySuperAdmin()
  const [mitra, mitraOnChange] = useState(ownerOption.find((data) => data.value === organization))

  const getAll =  async() => {
    const len = 'ALL'
    const result =  await Service.getAll(len)
    addLogisticItemList(result.data)
  }

  const submit = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
    const payload = LogisticItemAdd(name.value, logisticType.value, measurement.value, sn, description.value, mitra.value)
      const result = await Service.post(payload)
      await getAll()
      console.log(result)
      swal("Success", "Data Sukses di Tambahkan", "success").then(() => {
       props.history.push('/admin/logistic-item-list')
      })
    } catch (error) {
      return CatchError(error, props, refreshToken)
    }
  }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Form Item Gudang</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <div>
                        <Label>Manual ID</Label>
                          <Input
                            placeholder="Manual Id"
                            value = {idManual}
                            onChange = {(e) => {
                              if (!verifyLength(e.target.value, 13)) {
                                 idManualOnChange(e.target.value)
                              }}
                            }
                            type="text"
                            disabled = {!manualId}
                          />
                          </div>
                          <FormGroup check>
                          <Label check> Generate Manual
                            <Input type="checkbox"
                            value = {manualId}
                                  onChange = {e => manualIdOnChange(e.target.checked)}
                            />
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <SelectBasic
                          label = {'Mitra'}
                          value = {mitra}
                          onChange = {mitraOnChange}
                          isDisabled = {!mitraSuper}
                          option = {ownerOption}
                        />
                        <FormGroup>
                          <Label>Nama *</Label>
                          <Input
                            placeholder="Nama"
                            {...name}
                            type="text"
                          />
                        <Label>Tipe</Label>
                        <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Tipe"
                            name="singleSelect"
                            value = {logisticType}
                            options = {logisticTypeOptions}
                            onChange={e => logisticTypeOnChange(e)
                          }
                          />
                          <Label>Pengukuran</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Pengukuran"
                            name="singleSelect"
                            value = {measurement}
                            options = {measurementOptions}
                            onChange={e => measurementOnChange(e)
                          }
                          />
                          <FormGroup check>
                            <Label check> Memakai Serial Number
                              <Input type="checkbox"
                              value = {sn}
                              onChange = {e => snOnChage(e.target.checked)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                          <Label>Deskripsi</Label>
                          <Input
                          className="react-select"
                          type="textarea"
                          {...description}
                          />
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {submit} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default LogisticItemPage;


