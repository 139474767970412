export const columns = [
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "Add-On",
      accessor: "name",
    },
    {
      Header: "Mitra",
      accessor: "owner",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Harga",
      accessor: "price",
    },
    {
      Header: "Actions",
      accessor: "actionsSuper",
      sortable: false,
      filterable: false,
    }]

export const userColumns = [
     {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "Add-On",
      accessor: "name",
    },
    {
      Header: "Mitra",
      accessor: "owner",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Harga",
      accessor: "price",
    },
    {
      Header: "Actions",
      accessor: "actionsUser",
      sortable: false,
      filterable: false,
    }]