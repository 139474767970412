import React, { useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import BillingService from "Main/BillingService";
import Select from 'react-select'
import { useState } from "react";
import {billingAddress, getRefreshToken,
  getProvince, getOwner,
  addCity, addBillingAddressList,
  addSubDistrict, addUrban,
  getAddressType, getUserData, getCustomerOptions } from "../../../variables/common"

import CustomerService from "Main/CustomerService";
import swal from "sweetalert";
import { validatePermission } from "Main/Utils";
import { CatchError } from "Main/Exceptions/exceptions"
import { useFormInput } from "Main/Utils"
import InputCoordinate from "components/Input/InputCoordinate";

function BillingAddress(props) {
  const billingService =  new BillingService()
  const customerService = new CustomerService()
  const refreshToken = getRefreshToken()
  var selectProvinceOptions = getProvince()
  var addressTypeOptions = getAddressType()
  const [selectCustomerOptions, selectCustomerOptionsOnChange] = useState(getCustomerOptions())
  const userData = getUserData()
  const name = useFormInput('')
  const ownerData = getOwner()
  const userType = userData.userType
  const [changeOwner, changeOwnerOnChange] = useState(false)
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const addressTitle = useFormInput('')
  const addressLine = useFormInput('')
  const [province , provinceOnChange] = useState('')
  const [city , cityOnChange] = useState('')
  const [subDistrict , subDistrictOnChange] = useState('')
  const [addType, addTypeOnChange] = useState('')
  const [urban , urbanOnChange] = useState('')
  const [PrimaryAddress, primaryAddressOnChange] = useState(false)
  const [ShippingAddress, ShippingAddressOnChage] = useState(false)
  const [cityOptions, cityOptionsOnChange] = useState([])
  const [subDistrictOptions, subDistrictOptionsOnChange] = useState([])
  const [UrbanOptions, urbanOptionsOnChange] = useState([])
  const link = useFormInput('')
  const [customer, customerOnChange] = useState('')
  const [latitude, setLatitude] = useState(0.0)
  const [longitude, setLongitude] = useState(0.0)

  useEffect(() => {
    validatePermission(props, 2, 0)
  }, [])

  const getCustomer = async (mitraid) => {
    const customerOption = await customerService.getCustomerSelect(mitraid)
    // console.log(customerOption.data)
    selectCustomerOptionsOnChange(customerOption.data)
  }

  const getBilling =  async() => {
    const len = 'ALL'
    const result =  await billingService.getAllBillingAddress(len)
    addBillingAddressList(result.data)
  }

  const CityLoad = async(city) => {
    const dat = await customerService.getCity(city.value)
    const result = dat.data
    cityOptionsOnChange(result)
    addCity(result)
    return result
  }

  const subDistrictLoad = async(subDistrict) => {
    const dat = await customerService.getSubDistrict(subDistrict.value)
    const result = dat.data
    addSubDistrict(result)
    subDistrictOptionsOnChange(result)
    return result
  }

  const UrbanLoad = async(urban) => {
    // sessionStorage.removeItem('urban')
    const dat = await customerService.getUrban(urban.value)
    const result = dat.data
    urbanOptionsOnChange(result)
    addUrban(result)
    return result
  }

  const addBillingAddressData = async() => {
  try {
    const payload = billingAddress(owner.mitraid, customer.value, name.value, addressTitle.value, addType.value,
                  addressLine.value, province.value,
                  city.value, subDistrict.value,
                  urban.value, PrimaryAddress,
                  ShippingAddress, latitude, longitude)

    const result = await billingService.addBillingAddress(payload)
    await getBilling()
    swal(result.status, result.message, "success").then(() => {
        props.history.push('/admin/Billing-Address-list')
  })

  } catch (error) {
    return await CatchError(error, props, refreshToken)
  }}

  function deleteSelect() {
    CityLoad([])
    customerOnChange('')
    cityOnChange('')
    subDistrictOnChange('')
    urbanOnChange('')
    provinceOnChange([])
    cityOptionsOnChange([])
    subDistrictOptionsOnChange([])
    urbanOptionsOnChange([])
  }

  return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Generate Alamat Pelanggan</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <Label>Nama</Label>
                          <Input
                            {...name}
                            placeholder="Nama"
                            type="text"
                          />
                          <Label>Owner</Label>
                          {
                          (userType === 'SUPERADMIN') ? (
                            <>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Mitra"
                              value={owner}
                              isDisabled = {!changeOwner}
                              options={ownerData}
                              onChange={ async(e) => {
                                ownerOnChange(e);
                                await getCustomer(e.mitraid)
                                deleteSelect()
                              } } />
                              <FormGroup check>
                                <Label check> Ubah Mitra
                                  <Input type="checkbox"
                                  value = {changeOwner}
                                  checked = {changeOwner}
                                  onChange = {async (e) => {
                                    ownerOnChange(ownerData.find(own => own.value === userData.organization))
                                    changeOwnerOnChange(e.target.checked)
                                    await getCustomer(owner.mitraid)
                                    if (owner.value !== userData.organization) {
                                      deleteSelect()
                                      selectCustomerOptionsOnChange(getCustomerOptions())
                                    }
                                    }
                                  }
                                  />
                                  <span className="form-check-sign" />
                                </Label>
                              </FormGroup>
                              </>) : (
                              <Input
                              placeholder="Mitra"
                              value={owner.value}
                              />
                              )
                          }
                        <div>
                        <Label>Customer</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Customer"
                            name="singleSelect"
                            value = {customer}
                            options = {selectCustomerOptions}
                            onChange={(value) => {
                              customerOnChange(value)
                              }
                            }
                          />
                          <Label>Customer Category {customer.category}</Label>
                        </div>
                        <Label>Provinsi</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Provinsi"
                            name="singleSelect"
                            value = {province}
                            options = {selectProvinceOptions}
                            onChange={async(value) => {
                              // sessionStorage.removeItem('city')
                              const data = await CityLoad(value)
                              cityOnChange(null)
                              subDistrictOnChange(null)
                              urbanOnChange(null)
                              provinceOnChange(value)
                              cityOptionsOnChange(data)
                              subDistrictOptionsOnChange([])
                              urbanOptionsOnChange([])
                          }
                            }
                          />
                          <div>
                          <Label>Kota</Label>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Kota"
                              name="singleSelect"
                              value = {city}
                              options={cityOptions}
                              onChange={async (value) => {
                                // sessionStorage.removeItem('subDistrict')
                                subDistrictOnChange(null)
                                urbanOnChange(null)
                                const data = await subDistrictLoad(value)
                                subDistrictOptionsOnChange(data)
                                urbanOptionsOnChange([])
                                cityOnChange(value)
                              }}
                            />
                            {!city || !city.status ? (
                              <label className="error">
                                Phone Code Not Available, Set Default code (000)
                              </label>
                            ) : <label className="success">
                                Phone Code Available
                              </label>}
                            </div>
                          <div>
                          <Label>Kecamatan</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Kecamatan"
                            name="singleSelect"
                            value = {subDistrict}
                            options={subDistrictOptions}
                            onChange={async (value) => {
                              // sessionStorage.removeItem('urban')
                              urbanOnChange(null)
                              subDistrictOnChange(value)
                              const data = await UrbanLoad(value)
                              urbanOptionsOnChange(data)
                            }}
                          />
                          {!subDistrict || !subDistrict.status ? (
                              <label className="error">
                                Phone Code Not Available, Set Default code (000)
                              </label>
                            ) : <label className="success">
                                Phone Code Available
                              </label>}
                            </div>
                          <Label>Kelurahan</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Kelurahan"
                            name="singleSelect"
                            value = {urban}
                            options={UrbanOptions}
                            onChange={(value) => urbanOnChange(value)}
                          />
                          <Label>Judul Alamat</Label>
                          <Input
                            placeholder="Judul Alamat"
                            {...addressTitle}
                            type="text"
                          />
                          <Label>Tipe Alamat</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Tipe Alamat"
                            value = {addType}
                            options = {addressTypeOptions}
                            onChange = {(value) => addTypeOnChange(value)}
                            type="text"
                          />
                      <Label>Alamat Spesifik</Label>
                        <Input
                            placeholder="Alamat Spesifik"
                            {...addressLine}
                            type="textarea"
                          />
                      <InputCoordinate
                        latitude = {latitude}
                        setLatitude = {setLatitude}
                        longitude = {longitude}
                        setLongitude = {setLongitude}
                      />
                      <FormGroup check>
                            <Label check> Alamat Utama
                              <Input type="checkbox"
                                  value = {PrimaryAddress}
                                onChange = {e => primaryAddressOnChange(e.target.checked)}
                                />
                              <span className="form-check-sign" />
                            </Label>
                      </FormGroup>

                      <FormGroup check>
                            <Label check> Alamat Pengiriman
                              <Input type="checkbox"
                                  value = {ShippingAddress}
                                  onChange = {e => ShippingAddressOnChage(e.target.checked)}
                                />
                              <span className="form-check-sign" />
                            </Label>
                      </FormGroup>
                      </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            <Button onClick = {addBillingAddressData} color="info">Submit</Button>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default BillingAddress;
