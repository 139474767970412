import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import BasicInput from "components/Input/BasicInput";
import { DateTimestr } from "Main/Utils";
import { getMessageLogData } from "variables/MessageLog/MessageLog";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState,
  convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import { customTextFromBoolean } from "Main/Utils";
import swal from "sweetalert";
import CustomerService from "Main/CustomerService";
import { CatchError } from "Main/Exceptions/exceptions";
import LoadingScreen from "components/Loader/LoadingScreen";
import { getRefreshToken } from "variables/common";

function MessageLogDetail(props) {
  const messageLogs = getMessageLogData()
  const customerService = new CustomerService()
  const refreshToken = getRefreshToken()
  const [loading, loadingOnChange] = useState(false)
  const { id, msgType, msgFrom, msgTo,
  message, status, response, creation,
  modified, createdBy, modifiedBy, mitra } = messageLogs

  const [editorState] = useState(EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(message || '<h1>No Data</h1>'))))

      const SendEmail = async() => {
      swal("Send", "Kirim Kembali", "info", {
                  buttons: {
                  update: {text: "Send", value: 'up' },
                  cancel: 'Cancel'
                }
              }
        ).then(async(value) => {
          switch (value) {
            case 'up':
              loadingOnChange(true)

              const payload = {
                id
              }

              try {
                const result = await customerService.resendEmail(payload)
                await swal('Success', 'Pesan berhasil dikirim', 'success')
                props.history.push('/admin/message-log-list')
                loadingOnChange(false)
                return result
              } catch (error) {
                  loadingOnChange(false)
                  return await CatchError(error, props, refreshToken)
              }

            default:
              break;
          }
        })
      }

  const child = (
    <>
         <PanelHeader size="sm" />
      <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Detail Log Pesan</h5>
                </CardHeader>
                <Col md = "12">
                {
                  customTextFromBoolean((status === 1), 'Terkirim', 'Gagal')
                }
                </Col>
                <CardBody>
                  <BasicInput
                    key={"1"}
                    label = {'Mitra'}
                    value = {mitra}
                  />
                  <BasicInput
                    key={"2"}
                    label = {'Tipe Pesan'}
                    value = {msgType}
                  />
                  <BasicInput
                    key={"3"}
                    label = {'Pengirim'}
                    value = {msgFrom}
                  />
                  <BasicInput
                    key={"4"}
                    label = {'Penerima'}
                    value = {msgTo}
                  />
                  <div>
                    <Editor
                      key={"5"}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      editorState = {editorState}
                      disabled
                      // onEditorStateChange = {updateTextDescription}
                      />
                    <extarea
                      disabled
                      value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                    />
                  </div>
                  <BasicInput
                    key={"6"}
                    label = {'Response'}
                    value = {response}
                    type = {'textarea'}
                  />
                  <BasicInput
                    key={"7"}
                    label = {'Creation'}
                    disabled
                    value = {DateTimestr(creation)}
                  />
                  <BasicInput
                    key={"8"}
                    label = {'Modified'}
                    disabled
                    value = {DateTimestr(modified)}
                  />
                  <BasicInput
                    key={"9"}
                    label = {'CreatedBy'}
                    disabled
                    value = {createdBy}
                  />
                  <BasicInput
                    key={"10"}
                    label = {'ModifiedBy'}
                    disabled
                    value = {modifiedBy}
                  />
                  {
                    (status !== 1) ? (<>
                      <Button onClick = {SendEmail}
                        color="info" >
                        <i className="fab fa-google-plus-g" />Kirim Ulang</Button>
                    </>) : (<></>)
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    </>
  )
  return (
    <>
    <LoadingScreen
      load = {loading}
      children = {child}
    />
    </>
  );
}


export default MessageLogDetail;
