import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getUserData,  getOwner } from "./../../../variables/common"
import swal from "sweetalert";
import Select from "react-select"
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState,
  convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useFormInput } from "Main/Utils"
import LoadingScreen from "components/Loader/LoadingScreen";
import SelectBasic from "components/Input/SelectBasic";
import { verifySuperAdmin } from "Main/Utils";
import { BroadcastMessage } from "variables/CRM/MessageBroadcastVariable";
import BasicInput from "components/Input/BasicInput";
import CrmService from "Main/CRM/CrmService";
import { useEffect } from "react";

function MessageBroadcastPage(props) {
    const userData = getUserData()
    const [selectTemplateOptions, setselectTemplateOptions] = useState([])
    const [template, templateOnChange] = useState(selectTemplateOptions[0] || {})
    const [editorState, editorStateOnChange] = useState(EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(template.value || '<h1>No Data</h1>'))))
    const crmService = new CrmService()
    const title = useFormInput(`${userData.organization} Back Office`)
    const from = userData.organizationEmail
    const [loading, loadingOnChange] = useState(false)
    const ownerOption = getOwner()
    const { mitraSuper, organization } = verifySuperAdmin()
    const [mitra, mitraOnChange] = useState(ownerOption.find((data) => data.value === organization))

    const updateTextDescription = async (state) => {
      await editorStateOnChange(state)
      convertToRaw(editorState.getCurrentContent())
    }

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        loadingOnChange(true)
        const result = await crmService.GetStaticTemplate()
        const { data } = result
        setselectTemplateOptions(data)
        loadingOnChange(false)
    }

    const broadcast = async() => {
      swal("Send", "broadcast Pesan ke Customer", "info", {
                  buttons: {
                  update: {text: "Kirim", value: 'up' },
                  cancel: 'Cancel'
                }
              }
        ).then(async(value) => {
          switch (value) {
            case 'up':
              loadingOnChange(true)
              const datPayload = draftToHtml(convertToRaw(editorState.getCurrentContent()))
              const payload = BroadcastMessage(mitra.mitraid, title.value, datPayload)
              const result = await crmService.BroadcastMessage(payload)
              swal('Success', result.message, 'success').then(() => {
                loadingOnChange(false)
                props.history.push('/admin/Invoice-list')
                })
              return result
            default:
                loadingOnChange(false)
              break;
          }
        })
      }

    const children = (
      <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Broadcast Pesan</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="12">
                        <FormGroup>
                        <SelectBasic
                          label = {'Broadcast Ke'}
                          value = {mitra}
                          onChange = {mitraOnChange}
                          isDisabled = {!mitraSuper}
                          option = {ownerOption}
                        />
                        <Label>Title</Label>
                          <Input
                            {...title}
                            placeholder="title"
                            type="text"
                          />
                          </FormGroup>
                        <FormGroup>
                        <BasicInput
                            title = 'Dari'
                            value = {from}
                        />
                        </FormGroup>
                        <Label>Template Pesan</Label>
                          <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Template"
                              name="singleSelect"
                              value = {template}
                              options={selectTemplateOptions}
                              onChange={(value) => {
                                templateOnChange(value)
                                editorStateOnChange(() =>
                                EditorState.createWithContent(
                                  ContentState.createFromBlockArray(
                                    convertFromHTML(value.value)
                                    )
                                  )
                                )
                              }}
                          />
                        <label>text</label>
                        <div>
                          <Editor
                             wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            editorState = {editorState}
                            onEditorStateChange = {updateTextDescription}
                          />
                        <extarea
                          disabled
                          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                        />
                        </div>
                      </Col>
                      </Row>
                  </Form>
                     <Button onClick = {broadcast}
                      color="info" >
                        <i className="fab fa-google-plus-g" />Broadcast</Button>
                </CardBody>
              </Card>
          </Col>
        </Row>
      </div>
  )
  return (
    <>
      <PanelHeader size="sm" />
      <LoadingScreen children={children} loading = {loading}/>
    </>
  );
}

export default MessageBroadcastPage