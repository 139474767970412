export const csvReferences = () => {
    return [{
    name: `Text`,
    firstname: `Text`,
    lastname: `Text`,
    namingSeries: `Text`,
    email: `Text (Email)`,
    ktp: `Text (must be 16 digit)`,
    mobileNo: `Number Text (min 8 max 13 digit)`,
    customergroup: `Text`,
    enabled:`TRUE/FALSE`,
    salesTeam: `Text`,
    item: `Text (itemId)`,
    province: `Text`,
    city: `Text`,
    subDistrict: `Text`,
    urban: `Text`,
    addressTitle: `Text`,
    addressType: `Text`,
    addressLine: `Text`,
    primaryAddress:`TRUE/FALSE`,
    shippingAddress:`TRUE/FALSE`,
    category:`BROADBAND/DEDICATED`,
    manualCustomerId:`Optional (customerId)`,
    /* manualBillId: `Optional (subscriptionId)`, */
    pppUser: `Optional (Text)`,
    pppPassword: `Optional (Text)`,
    ppn: `Optional (number 1 - 100)`,
    mitra: 'Optional (mitraId)',
    registration: 'Optional. Format mandatory (YYYY-MM-DD hh:mm:ss.007Z) (007Z Timezone)',
    endperiod: 'Optional. Format mandatory (YYYY-MM-DD hh:mm:ss.007Z) (007Z Timezone)',
    bniVa: 'Optional. Number Max 16 Digit',
    discount: 'Default 0, Nilai diskon mengunakan mata uang Rupiah',
    latitude: 'Opsional, Floating Number',
    longitude: 'Opsional, Floating Number'
    }]
}

export const csvItemReferences = () => {
    return [{
    id: `Number (Optional)`,
    mitra: `Text (Mandatory)`,
    name: `Text (Mandatory)`,
    pppProfileName: `Text (Mandatory)`,
    uplink: 'Number (Mandatory)',
    downlink: 'Number (Mandatory)',
    category: 'Number (Mandatory)',
    ip: `Text (Mandatory)`,
    price: 'Text (Mandatory)',
    routerid: 'Number (Mandatory)',
    discount: 'Number (Mandatory)',
    subnet: 'Number (Mandatory)'
    }]
}

export const customerStatusOption = [
    {
      label: 'Menunggu Aktivasi',
      value: 'c-new'
    },
    {
      label: 'Pelanggan Aktif',
      value: 'c-active'
    },
    {
      label: 'Pelanggan Suspend',
      value: 'c-suspend'
    },
    {
      label: 'Pelanggan Non-Aktif',
      value: 'c-disable'
    },
    {
      label: 'Pelanggan Corrupted',
      value: 'c-corrupted'
    }
  ]