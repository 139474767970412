import React from "react";
import LoadingScreen from "components/Loader/LoadingScreen";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Select from 'react-select'
import { useState } from "react";
import {getRefreshToken, getUserData,
  getOwner, getProvince, addCity,
  addSubDistrict, addUrban, addRouterList, routerAdd } from "./../../../variables/common"
import RouterService from "./../../../Main/RouterService"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import CustomerService from "Main/CustomerService";
import { useFormInput } from "Main/Utils";
import { verifySuperAdmin } from "Main/Utils";
import InputCoordinate from "components/Input/InputCoordinate";


function RouterAdd(props) {
  const routerService = new RouterService()
  const customerService = new CustomerService()
  var ownerData = getOwner()
  const userData = getUserData()
  const refreshToken = getRefreshToken()
  const name = useFormInput('')
  const merk = useFormInput('')
  const type = useFormInput('')
  const sn = useFormInput('')
  const description = useFormInput('')
  const [province, provinceOnChange] = useState('')
  const [city , cityOnChange] = useState('')
  const [subDistrict , subDistrictOnChange] = useState('')
  const [urban , urbanOnChange] = useState('')
  const [cityOptions, cityOptionsOnChange] = useState([])
  const [subDistrictOptions, subDistrictOptionsOnChange] = useState([])
  const [UrbanOptions, urbanOptionsOnChange] = useState([])
  const [ipaddress, ipaddressOnChange] = useState('')
  const [port, portOnChange] = useState(8728)
  const [owner, ownerOnChange] = useState(ownerData.find(data => data.value === userData.organization))
  const [passwordValue, passwordValueOnChange] = useState('')
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, loadingOnChange] = useState(false)
  var selectProvinceOptions = getProvince()
  const { userSuper } = verifySuperAdmin()
  const [latitude, setLatitude] = useState(0.0)
  const [longitude, setLongitude] = useState(0.0)

  const getRouter =  async() => {
        const len =  'ALL'
        const result =  await routerService.getAllRouter(len)
        addRouterList(result.data)
  }

   const togglePassword = () => {
      // When the handler is invoked
      // inverse the boolean state of passwordShown
      setPasswordShown(!passwordShown);
  };

  const addRouterData = async() => {
      try {
          loadingOnChange(true)
          const payload = routerAdd(name.value,
            ipaddress,
            passwordValue,
            port,
            province.label,
            city.label,
            subDistrict.label,
            urban.label,
            merk.value,
            type.value,
            sn.value,
            description.value,
            owner.value,
            latitude,
            longitude)
          const result = await routerService.addRouter(payload)
          await getRouter()
          loadingOnChange(false)
          swal(result.status, result.message, "success").then(() => {
              props.history.push('/admin/router-list')
          })
        } catch (error) {
          loadingOnChange(false)
          return await CatchError(error, props, refreshToken)
          }
        }
      //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')}
    const CityLoad = async(city) => {
      const dat = await customerService.getCity(city.value)
      const result = dat.data
      console.log(result)
      addCity(result)
      return result
    }

    const subDistrictLoad = async(subDistrict) => {
      const dat = await customerService.getSubDistrict(subDistrict.value)
      const result = dat.data
      console.log(result)
      addSubDistrict(result)
      return result
    }

    const UrbanLoad = async(urban) => {
      // sessionStorage.removeItem('urban')
      const dat = await customerService.getUrban(urban.value)
      const result = dat.data
      console.log(result)
      addUrban(result)
      return result
    }

    const children = (
      <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Form Router</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <InputCoordinate
                            latitude = {latitude}
                            setLatitude = {setLatitude}
                            longitude = {longitude}
                            setLongitude = {setLongitude}
                          />
                          <Label>Username</Label>
                          <Input
                            {...name}
                            placeholder="Username"
                            type="text"
                          />
                          <Label>Mitra</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Mitra"
                            name="singleSelect"
                            value = {owner}
                            options = {ownerData}
                            isDisabled = {!userSuper}
                            onChange={(value) => ownerOnChange(value)}
                          />

                          <Label>IP Address</Label>
                          <Input
                            placeholder="IP Address"
                            value = {ipaddress}
                            onChange = {(e) => {
                              if (e.target.value.length <= 15) {
                                ipaddressOnChange(e.target.value)
                              }
                            }}
                            type="text"
                          />
                          <Label>Port</Label>
                          <Input
                            placeholder="Port"
                            value = {port}
                            onChange = {(e) => {
                              if (e.target.value.length <= 4) {
                                portOnChange(e.target.value)
                              }
                            }}
                            type="number"
                          />

                          <Label>Password</Label>
                          <Input
                            placeholder="password"
                            value = {passwordValue}
                            onChange = { (e) => {
                              if (e.target.value.length <= 40) {
                                passwordValueOnChange(e.target.value)
                              }
                            }
                            }
                            type={passwordShown ? "text" : "password"}
                          />
                          <FormGroup check>
                            <Label check> Show
                              <Input type="checkbox"
                              value={passwordShown}
                              onChange={togglePassword}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                          <Label>provinsi</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Provinsi"
                            name="singleSelect"
                            value = {province}
                            options = {selectProvinceOptions}
                            onChange={async(value) => {
                              // sessionStorage.removeItem('city')
                              const data = await CityLoad(value)
                              cityOnChange(null)
                              subDistrictOnChange(null)
                              urbanOnChange(null)
                              provinceOnChange(value)
                              cityOptionsOnChange(data)
                              subDistrictOptionsOnChange([])
                              urbanOptionsOnChange([])
                            }
                              }
                            />
                            <div>
                            <Label>Kota</Label>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Kota"
                              name="singleSelect"
                              value = {city}
                              options={cityOptions}
                              onChange={async (value) => {
                                // sessionStorage.removeItem('subDistrict')
                                subDistrictOnChange(null)
                                urbanOnChange(null)
                                const data = await subDistrictLoad(value)
                                subDistrictOptionsOnChange(data)
                                urbanOptionsOnChange([])
                                cityOnChange(value)
                              }}
                            />
                            {!city || !city.status ? (
                              <label className="error">
                                Phone Code Not Available, Set Default code (000)
                              </label>
                            ) : <label className="success">
                                Phone Code Available
                              </label>}
                            </div>
                            <div>
                            <Label>Kecamatan</Label>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Kecamatan"
                              name="singleSelect"
                              value = {subDistrict}
                              options={subDistrictOptions}
                              onChange={async (value) => {
                                // sessionStorage.removeItem('urban')
                                urbanOnChange(null)
                                subDistrictOnChange(value)
                                const data = await UrbanLoad(value)
                                urbanOptionsOnChange(data)
                              }}
                            />
                            {!subDistrict || !subDistrict.status ? (
                              <label className="error">
                                Phone Code Not Available, Set Default code (000)
                              </label>
                            ) : <label className="success">
                                Phone Code Available
                              </label>}
                            </div>
                            <Label>Kelurahan</Label>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Kelurahan"
                              name="singleSelect"
                              value = {urban}
                              options={UrbanOptions}
                              onChange={(value) => urbanOnChange(value)}
                            />
                            <Label>Merk</Label>
                            <Input
                              {...merk}
                              placeholder="Merk"
                              type="text"
                            />
                            <Label>Type</Label>
                            <Input
                              {...type}
                              placeholder="Type"
                              type="text"
                            />
                            <Label>Serial Number</Label>
                            <Input
                              {...sn}
                              placeholder="Serial Number"
                              type="text"
                            />
                            <Label>Deskripsi</Label>
                            <Input
                              {...description}
                              placeholder="Deskripsi"
                              type="text"
                            />
                        </FormGroup>
                      </Col>
                      </Row>
                  </Form>
                </CardBody>
              </Card>
            <Button onClick = {addRouterData} color="info">Submit</Button>
            </Col>
          </Row>
        </div>
    )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen children={children} loading = {loading} text={"Checking Router Credentials"}/>
      </>
    );
}


export default RouterAdd;
