import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// reactstrap components
import { Container, Row, Col, FormGroup, Input } from "reactstrap";
import Paginated from "components/Pagination/PaginationV1";
import { htmlToText } from "html-to-text";

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <FormGroup>
      <Input
        placeholder={`Search ${count} records...`}
        type="text"
        value={filterValue}
        onChange={(e) => {
          // const filterValue = htmlToText(e.target.value, {
          //     wordwrap: 200
          //   })

          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    </FormGroup>
  );
}

const nextSec = (limit, len, limitPage) => {
  return (limit + Number(limitPage)) <= (len + limitPage)
}

const prevSec = (limit, limitPage) => {
  return (limit - Number(limitPage)) > 0
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  console.log('fuzzyTextFilterFn')
  return matchSorter(rows, filterValue, { keys: [(row) => {
    console.log(row.values, filterValue, id)
    return row.values[id]
  }] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data, pageState, pageStateSet }) {
  // console.log('Table')
  const [limitPage] = useState(10)
  const [numberOfRows, setNumberOfRows] = React.useState({
    value: 10,
    label: "10 Line",
  });

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        console.log('Set Filter')
        return rows.filter((row) => {
          const rowValue = row.values[id];
          // const rowValueAlt = row.values[`${id}Index`];
          // console.log(rowValue, rowValueAlt)
          const finalRowValue = rowValue // || rowValueAlt
          return finalRowValue !== undefined
           ? String(finalRowValue) // ? String(htmlToText(finalRowValue, {
            //   wordwrap: 200
            // }))
                .toLowerCase()
                .startsWith(
                  String(filterValue //htmlToText(filterValue, {wordwrap: 200})
                ).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage
  } = useTable(
        {
          columns,
          data,
          defaultColumn, // Be sure to pass the defaultColumn option
          filterTypes,
          initialState: { pageSize: 10, pageIndex: pageState || 0 },
        },
        useFilters, // useFilters!
        useSortBy,
        usePagination
      );

  let numberOfRowsData = [5, 10, 20, 25, 50, 100];

  // console.log('Dfault coll: ', pageCount)
  const [limitPagination, limitPaginationOnChange] = useState(Number(limitPage))

  const nextSectionState = nextSec(limitPagination, pageCount, limitPage)
  // console.log('Next Section: ', nextSectionState)
  const [canNextSection, canNextSectionOnChange] = useState(nextSectionState)
  // console.log('Next SectionL1: ', canNextSection)

  const prevSectionState = prevSec(limitPagination, limitPage)
  // console.log('Prev Section: ', prevSectionState)
  const [canPreviousSection, canPreviousOnChange] = useState(prevSectionState)
  // console.log('Prev SectionL1: ', canPreviousSection)

  function validatePreviousNext (limit) {
      // console.log('Set Limit: ', limit)
      canNextSectionOnChange(nextSec(limit, pageCount, limitPage))
      canPreviousOnChange(prevSec(limit, limitPage))
  }

  return (
    <>
      <div className="ReactTable -bordered -highlight primary-pagination">
        <div className="pagination-top">
          <div className="-pagination">
            <div className="-center">
              <Container>
                <Row className="justify-content-center">
                  <Col md="12" sm="6" xs="12">
                    <Select
                      // className="react-select"
                      // classNamePrefix="react-select"
                      name="numberOfRows"
                      value={numberOfRows}
                      onChange={(value) => {
                        setPageSize(value.value);
                        setNumberOfRows(value);
                      }}
                      options={numberOfRowsData.map((prop) => {
                        return {
                          value: prop,
                          label: prop + " Line",
                        };
                      })}
                      placeholder="Choose Rows"
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <table {...getTableProps()} className="rt-table">
          <thead className="rt-thead -header">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {headerGroup.headers.map((column, key) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps(
                      {
                      style: { minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        width: column.width },
                    }
                    ))}
                    className={classnames("rt-th rt-resizable-header", {
                      "-cursor-pointer": headerGroup.headers.length - 1 !== key,
                      "-sort-asc": column.isSorted && !column.isSortedDesc,
                      "-sort-desc": column.isSorted && column.isSortedDesc,
                    })}
                  >
                    <div className="rt-resizable-header-content">
                      {column.render("Header")}
                    </div>
                    {/* Render the columns filter UI */}
                    <div>
                      {headerGroup.headers.length - 1 === key
                        ? null
                        : column.canFilter
                        ? column.render("Filter")
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="rt-tbody">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={classnames(
                    "rt-tr",
                    { " -odd": i % 2 === 0 },
                    { " -even": i % 2 === 1 }
                  )}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          maxWidth: cell.column.maxWidth,
                          width: cell.column.width,
                        },
                      })} className="rt-td">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <Paginated
              key={2}
              previousPage = {previousPage}
              nextPage = {nextPage}
              canPreviousPage = {canPreviousPage}
              canNextPage = {canNextPage}
              pageLen = {pageCount}
              limitPaginate = {limitPage}
              gotoPage = {gotoPage}
              pageStateSet = {pageStateSet}
              limitPagination = {limitPagination}
              limitPaginationOnChange = {limitPaginationOnChange}
              validatePreviousNext = {validatePreviousNext}
              canPreviousSection = {canPreviousSection}
              canNextSection = {canNextSection || nextSectionState}
            />
          </tfoot>
        </table>
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;