const { getData, setData } = require("variables/common");

const identifier = ['messageLogData', 'messageLogList']
const [data, list] = identifier

export function addMessageLogData(payload) {
  setData(data, payload)
}

export function getMessageLogData() {
 return getData(data)
}

export function addMessageLogList(payload) {
  setData(list, payload)
}

export function getMessageLogList() {
 return getData(list)
}

