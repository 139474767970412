import LoginService from './LoginService'
import APIconsume from './APIconsume'
import { getAccessToken } from "./../variables/common"

class BankAccountService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'Bank-Account'
        this._vUrl = 'Virtual-Account'
    }

    async addBankAccount (payload) {
        const result = await this._consume.postData(payload, this._url)
        return result.data
    }

    async getBankAccount (id) {
        const url = `${this._url}/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getBankAccountSelect (mitraid) {
        const url = !mitraid ? `${this._url}/dropdown` :
        `${this._url}/dropdown?mitraid=${mitraid}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllBankAccount (len = 'ALL', page = '') {
        let url = `${this._url}/view?line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async putBankAccount (id, payload) {
        const url = `${this._url}/${id}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async deleteBankAccount (id) {
        const url = `${this._url}/${id}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async addVirtualAccount (payload) {
        const result = await this._consume.postData(payload, this._vUrl)
        return result.data
    }

    async getVirtualAccount (id) {
        const url = `${this._vUrl}/${id}`

        const result = await this._consume.getData(url)
        return result.data
    }

    async getVirtualAccountCompany (id) {
        const url = `${this._url}/company/${id}`

        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllVirtualAccount (id = '', limit = '', page = '') {
        let url = `${this._vUrl}/view?customerId=${id}&limit=${limit}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async putVirtualAccount (id, payload) {
        const url = `${this._vUrl}/${id}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async deleteVirtualAccount (id) {
        const url = `${this._vUrl}/${id}`
        const result = await this._consume.deleteData(url)
        return result.data
    }
}

export default BankAccountService