import React, { useEffect, useState } from "react";
import Select from 'react-select';
import LoadingScreen from "components/Loader/LoadingScreen";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import CustomerService from "Main/CustomerService";
import { customer, getRefreshToken,
  getCustomerGroup, addCustomerList,
  getUserData, getAddressType,
  addBillingAddressList, GetItemCategory,
  addBillingList,
  getOwner, PriceFormat } from "./../../../variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions"
import BillingService from "Main/BillingService";
import { validatePermission } from "Main/Utils";
import RouterService from "Main/RouterService";
import { verifyLength, useFormInput } from "Main/Utils"
import BasicInput from "components/Input/BasicInput";
import InputCoordinate from "components/Input/InputCoordinate";
import Checklist from "components/Button/Checklist";
import SelectBasic from "components/Input/SelectBasic";
import { hexDecoder } from "Main/Utils";
import UserService from "Main/UserService";

function Customer(props) {
  const customerService =  new CustomerService()
  const routerService = new RouterService()
  const userService = new UserService()
  const refreshToken = getRefreshToken()
  const userData = getUserData()
  const ownerData = getOwner()
  const [minLengthState, setminLengthState] = React.useState("");
  const [minLengthPhoneState, setminLengthPhoneState] = React.useState("");
  const [minLengthPhone, setminLengthPhone] = React.useState("");
  // const [province, provinceOnChange] = useState('')
  const [customerGroup, customerGroupOnChange] = useState('')
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const ppnPercentage = userData.ppn
  const name = useFormInput('')
  const namingSeries = useFormInput("XX.YY.dd")
  const firstname = useFormInput('')
  const lastname = useFormInput('')
  const email = useFormInput('')
  const description = useFormInput('')
  const [ktpNumber, ktpNumberOnChange] = useState('')
  const [MobileNo, mobileNoOnChange] = useState('')
  const [ktpPassed, ktpPassedOnChange] = useState(true)
  const [mobileNoPassed, phonePassedOnChnage] = useState(true)
  const [salesTeam, salesTeamOnChange] = useState('')
  const [loading, loadingOnChange ] = useState(false)
  const [customerIdAvailable, customerIdAvailableOnChange ] = useState(true)
  const [UsePPPlist, SetUsePPPlist] = useState(false)
  const mitraType = userData.mitraType
  const enabled = false

  // Address
  const billingService =  new BillingService()
  // var selectProvinceOptions = getProvince()
  var addressTypeOptions = getAddressType()
  var selectCustomerGroupOptions = getCustomerGroup()
  var selectCategoryOptions = GetItemCategory()
  const [userSelectOption, setUserSelectOption] = useState([])
  const [selectItemOptions, selectItemOptionsOnChange] = useState([])
  var [routerSelectOption, routerSelectOptionOnChange] = useState([])
  const [item, itemOnChange] = useState('')
  const addressTitle = useFormInput('')
  const addressLine = useFormInput('')
  const [customeridManual, customeridManualOnChange] =  useState()
  const [addType, addTypeOnChange] = useState('')
  const [PrimaryAddress, primaryAddressOnChange] = useState(false)
  const [ShippingAddress, ShippingAddressOnChage] = useState(false)
  const [router, routerOnChange] = useState('')
  const [manualCustomerId, manualCustomerIdOnChange] = useState(false)
  const [changeOwner, changeOwnerOnChange] = useState(false)
  const [category, categoryOnChange] = useState('')
  const [pppUser,  pppUserOnChange] =  useState()
  const [pppPass,  pppPassOnChange] =  useState()
  const [manualpppUsername, manualpppUsernameOnChange] = useState(false)
  const [ppn,  ppnOnChange] =  useState(true)
  const [discount, setDIscount] = useState(0)
  const [latitude, setLatitude] = useState(0.0)
  const [longitude, setLongitude] = useState(0.0)
  const [RouterPPPUser, SetRouterPPPUser] = useState({})
  const [RouterPPPUserList, SetRouterPPPUserList] = useState([])

  const getCustomer =  async() => {
    const len = 'ALL'
    const result =  await customerService.getAllCustomer(len)
    addCustomerList(result.data)
  }

  const getRouterPPP =  async(routerId) => {
    const result =  await routerService.getRouterSecret(routerId)
    SetRouterPPPUserList(result.data)
    return result.data
  }

  const getBilling =  async() => {
    const len = 'ALL'
    const result =  await billingService.getAllBillingAddress(len)
    const subscription = await billingService.getAllBilling(len)
    addBillingAddressList(result.data)
    addBillingList(subscription.data)
    return result.data
  }

  const getRouter = async(mitraid, urbanid) => {
    // sessionStorage.removeItem('urban')
    const dat = await routerService.getRouterSelect(mitraid, urbanid)
    const result = dat.data
    selectItemOptionsOnChange([])
    routerSelectOptionOnChange(result)
    return result
  }

  const getItem = async(routerid, mitraid, type) => {
    // sessionStorage.removeItem('urban')
    const dat = await customerService.getItemSelect(routerid, mitraid, type)
    const result = dat.data
    selectItemOptionsOnChange(result)
    return result
  }

  const verifyCustomerId = async (id) => {
    const result = await customerService.verifyCustomerIdAvailable(id)
    const available = result.data
    customerIdAvailableOnChange(available)
    return result.data
  }

  async function getUserReseller(mitra) {
    const resellerList = await userService.getUserSelect(mitra)
    const resellerData = resellerList.data

    setUserSelectOption(resellerData)
    return resellerList
  }

  useEffect(() => {
    getRouter(owner.mitraid)
    getUserReseller(owner.mitraid)
    validatePermission(props, 0, 0)
  },[])

  const submitCustomer = async(e) => {
    e.preventDefault()
    try {
      loadingOnChange(true)
      const pppUserFinal = !UsePPPlist ? pppUser : RouterPPPUser.value
      const pppPassFinal = !UsePPPlist ? pppPass : hexDecoder(RouterPPPUser.pass)
      const { province, city, subDistrict, urban } = router
      const payload = customer(name.value, firstname.value, lastname.value,
                      namingSeries.value, email.value, ktpNumber,
                      MobileNo, customerGroup.value,
                      enabled, salesTeam.value, item.value, province,
                      city, subDistrict, urban, addressTitle.value,
                      addType.value, addressLine.value, PrimaryAddress, ShippingAddress, category.value,
                      manualCustomerId === true ? customeridManual : null,
                      /* manualNetworkId === true ? networkidManual : null, */ pppUserFinal, pppPassFinal, ppn,
                      owner.value, description.value, discount, latitude, longitude)

      const result = await customerService.addCustomer(payload)
      await getCustomer()
      await getBilling()
      loadingOnChange(false)
      await swal(result.status, result.message, "success")
      props.history.push('/admin/customer-all')
    } catch (error) {
      loadingOnChange(false)
      return await CatchError(error, props, refreshToken)
  }}

  function deleteSelect () {
    // provinceOnChange('')
    routerOnChange('')
    itemOnChange('')
    SetRouterPPPUser({})
    routerSelectOptionOnChange([])
    selectItemOptionsOnChange([])
    SetRouterPPPUserList([])
    salesTeamOnChange({})
  }

  const children = (
    <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Customer Form</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <div>
                          <Label>Manual Customer ID</Label>
                          <Input
                            placeholder="customerId"
                            value = {customeridManual}
                            onChange = {(e) => {
                              if (!verifyLength(e.target.value, 13)) {
                                customeridManualOnChange(e.target.value)
                                verifyCustomerId(e.target.value)
                              }}
                            }
                            type="text"
                            disabled = {!manualCustomerId}
                          />
                          {!customerIdAvailable ? (
                            <label className="error">
                              Customer Id Telah dipakai, coba ID lain
                            </label>
                          ) : null}
                          </div>
                          <FormGroup check>
                          <Label check> Generate Customer-ID Manual
                            <Input type="checkbox"
                              value = {manualCustomerId}
                              onChange = {e => manualCustomerIdOnChange(e.target.checked)}
                            />
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <Label>Nama *</Label>
                        <Input
                          placeholder="Nama"
                          {...name}
                          type="text"
                        />
                        <InputCoordinate
                          latitude = {latitude}
                          setLatitude = {setLatitude}
                          longitude = {longitude}
                          setLongitude = {setLongitude}
                        />
                        <Label>Mitra</Label>
                        {
                        (mitraType === 'SUPERADMIN') ? (
                            <>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="owner"
                              value={owner}
                              isDisabled = {!changeOwner}
                              options={ownerData}
                              onChange={ async (e) => {
                                ownerOnChange(e);
                                deleteSelect()
                                await getRouter(e.mitraid)
                                await getUserReseller(e.mitraid)
                              } } />
                              <FormGroup check>
                                <Label check> Ganti Mitra
                                  <Input type="checkbox"
                                  value = {changeOwner}
                                  checked = {changeOwner}
                                  onChange = {e => {
                                    ownerOnChange(ownerData.find(own => own.value === userData.organization))
                                    changeOwnerOnChange(e.target.checked)
                                    if (owner.value !== userData.organization) {
                                      deleteSelect()
                                    }
                                    }
                                  }
                                  />
                                  <span className="form-check-sign" />
                                </Label>
                              </FormGroup>
                              </>) : (
                              <Input
                              placeholder="Mitra"
                              value={owner.value}
                              />
                              )
                          }
                          <Label>Nama Depan *</Label>
                          <Input
                            placeholder="firstname"
                            {...firstname}
                            type="text"
                          />
                          <Label>nama Belakang *</Label>
                          <Input
                            placeholder="lastname"
                            {...lastname}
                            type="text"
                          />
                          <Label>Nama Lengkap </Label>
                          <Input
                            placeholder="Nama Lengkap"
                            value = {`${firstname.value} ${lastname.value}`}
                            type="text"
                          />
                        <Label>Naming Series</Label>
                          <Input
                            value = {namingSeries.value}
                            type="text"
                          />
                       <div>
                        <Label>Email</Label>
                          <Input
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder="Email"
                            name="email"
                            type="email"
                            {...email}
                          />
                        <Label>KTP</Label>
                        <Input
                          name="min_length_ktp"
                          type="number"
                          placeholder="KTP Number"
                          max={16}
                          value = {ktpNumber}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 17)) {
                              ktpNumberOnChange(e.target.value)
                              if (!verifyLength(e.target.value, 16)) {
                                setminLengthState("has-danger");
                                ktpPassedOnChange(true)
                              } else {
                                setminLengthState("has-success");
                                ktpPassedOnChange(false)
                              }
                              }
                          }}
                        />
                        {minLengthState === "has-danger" ? (
                          <label className="error">
                            Nomor KTP Harus 16 Karakter.
                          </label>
                        ) : null}
                       </div>
                       <div>
                        <Label>Nomor Telepon</Label>
                        <Input
                          name="min_length_phone"
                          type="number"
                          placeholder="Nomor Telepon"
                          max={12}
                          value={MobileNo}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 14)) {
                              mobileNoOnChange(e.target.value)
                            if (!verifyLength(e.target.value, 11)) {
                              setminLengthPhoneState("has-danger");
                              phonePassedOnChnage(true)
                            } else {
                              setminLengthPhoneState("has-success");
                              phonePassedOnChnage(false)
                            }
                            setminLengthPhone(e.target.value);
                            }
                          }}
                        />
                        {minLengthPhoneState === "has-danger" ? (
                          <Label className="error">
                            Masukkan Minimal 8 Karakter dan Maksimal 13 Karakter.
                          </Label>
                        ) : null}
                       </div>
                        <Label>Group Customer</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="group customer"
                            name="singleSelect"
                            value = {customerGroup}
                            options={selectCustomerGroupOptions}
                          onChange={(value) => customerGroupOnChange(value)}
                          />
                          <Label>Lokasi Router</Label>
                          <div>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Lokasi Router"
                              name="singleSelect"
                              value = {router}
                              options={routerSelectOption}
                              onChange={ async (e) => {
                                  routerOnChange(e)
                                  itemOnChange('')
                                  categoryOnChange('')
                                  SetRouterPPPUser({})
                                  await getRouterPPP(e.value)
                                }
                              }
                            />
                            {!router.status ? (
                              <label className="error">
                                Router Offline
                              </label>
                            ) : <label className="success">
                                Router Online
                              </label>}
                          </div>
                          <div>
                          <Label>Kategori</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Kategori"
                            name="singleSelect"
                            value = {category}
                            options={selectCategoryOptions}
                            onChange={ async (value) => {
                              categoryOnChange(value)
                              await getItem(router.value, owner.mitraid, value.value)
                              itemOnChange('')
                            }}
                          />
                          <Label>Auto Suspend hanya bekerja untuk Broadband</Label>
                          </div>
                            <Label>Paket Layanan</Label>
                            <Select
                                // className="react-select"
                                // classNamePrefix="react-select"
                                placeholder="Paket Layanan"
                                name="singleSelect"
                                value = {item}
                                options={selectItemOptions}
                                onChange={(value) => {
                                  itemOnChange(value)
                                }}
                            />
                            <FormGroup check>
                            <Label check> PPN {ppnPercentage}%
                              <Input type="checkbox"
                                  value = {ppn}
                                  checked = {ppn}
                                  onChange = {e => ppnOnChange(e.target.checked)}
                                />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                            {
                              !UsePPPlist ? (
                                <>
                              <div>
                                <Label>PPOE User</Label>
                                <Input
                                  disabled={!manualpppUsername}
                                  placeholder={manualpppUsername ? "Manual Generate" : "Auto Generate"}
                                  type="text"
                                  value={pppUser}
                                  onChange={(e) => pppUserOnChange(e.target.value)} />

                                  <Label>PPOE Password</Label>
                                  <Input
                                    disabled={!manualpppUsername}
                                    placeholder={manualpppUsername ? "Manual Generate" : "Auto Generate"}
                                    type="text"
                                    value={pppPass}
                                    onChange={(e) => pppPassOnChange(e.target.value)} />
                                </div>
                                <Label>Kosongkan Password untuk AutoGenerate</Label>
                                <Checklist
                                  text = {"Generate PPP Secret secara Manual"}
                                  value = {manualpppUsername}
                                  checked = {manualpppUsername}
                                  onChange = {e => {
                                    if (!e.target.checked) {
                                      manualpppUsernameOnChange(e.target.checked)
                                      SetRouterPPPUser({})
                                      SetRouterPPPUserList([])
                                      pppUserOnChange('')
                                      pppPassOnChange('')
                                      return e
                                    }
                                    manualpppUsernameOnChange(e.target.checked)
                                  }}
                                />
                           </>
                              ) : (
                                <>
                                <Label>PPOE User List</Label>
                                <SelectBasic
                                  disabled={!UsePPPlist}
                                  label={UsePPPlist ? " Select PPP From Router" : " Auto Generate"}
                                  value={RouterPPPUser}
                                  option={RouterPPPUserList}
                                  onChange={(e) => SetRouterPPPUser(e)} />
                                </>
                              )
                            }
                          <Checklist
                              text = {"Memakai PPP Secret Router"}
                              value = {UsePPPlist}
                              onChange = { async (e) => {
                                SetUsePPPlist(e.target.checked)
                                pppUserOnChange('')
                                pppPassOnChange('')
                                if (!e.target.checked) {
                                  SetRouterPPPUser({})
                                  SetRouterPPPUserList([])
                                  return e
                                }
                                manualpppUsernameOnChange(!e.target.checked)
                                await getRouterPPP(router.value)
                              }}
                            />
                          <Label>Judul Alamat</Label>
                          <Input
                            placeholder="Judul Alamat"
                            {...addressTitle}
                            type="text"
                          />
                          <Label>Tipe Alamat</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Tipe Alamat"
                            value = {addType}
                            options = {addressTypeOptions}
                            onChange = {(value) => addTypeOnChange(value)}
                            type="text"
                          />
                          <Label>Alamat Spesifik</Label>
                          <Input
                              placeholder="Alamat Spesifik"
                              {...addressLine}
                              type="textarea"
                            />
                          <Label>Deskripsi</Label>
                          <Input
                              placeholder="Deskripsi"
                              {...description}
                              type="textarea"
                            />
                          <div>
                          </div>
                          <Label>Doctype</Label>
                          <Input
                            placeholder="Doctype"
                            value = 'Customer'
                            type="text"
                          />
                          <Label>Sales Team</Label>
                          <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Sales Team"
                              value = {salesTeam}
                              options = {userSelectOption}
                              onChange = {(value) => salesTeamOnChange(value)}
                              type="text"
                            />
                            <BasicInput
                              label = 'Diskon Customer (IDR)'
                              value = {discount}
                              onChange = {setDIscount}
                            />
                            <Input
                              placeholder="Diskon Customer (IDR)"
                              disabled
                              value={PriceFormat(discount, 'IDR')}
                              type="text"
                            />
                        </FormGroup>
                        </Col>
                    </Row>
                    <div className = "form-group">
                    <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                    </FormGroup>
                    </Col>
                    </Row>
                    <FormGroup>
                        <FormGroup check>
                          <Label check> Alamat Utama
                            <Input type="checkbox"
                            value = {PrimaryAddress}
                            checked = {PrimaryAddress}
                              onChange = {e => {primaryAddressOnChange(e.target.checked)
                                    ShippingAddressOnChage(false)}}
                            />
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>

                        <FormGroup check>
                          <Label check> Alamat Pengiriman
                            <Input type="checkbox"
                            value = {ShippingAddress}
                            checked = {ShippingAddress}
                            onChange = {e => {ShippingAddressOnChage(e.target.checked)
                                  primaryAddressOnChange(false)}}
                            />
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                    </FormGroup>
                    </div>
                  <Button onClick = {(e) => submitCustomer(e)} disabled = {mobileNoPassed
                    & customerIdAvailable
                    & minLengthPhone
                    & ktpPassed} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
  )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen children={children} loading = {loading} text={"Upload Data"}/>
      </>
    );
}


export default Customer;
