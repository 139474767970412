export const invoiceColumnsSuperUser = [
                        {
                          Header: "Invoice ID",
                          accessor: "id",
                           maxWidth: 100
                        },
                        {
                          Header: "Customer ID",
                          accessor: "customerId",
                           maxWidth: 150
                        },
                        {
                          Header: "Customer",
                          accessor: "customer",
                          maxWidth: 300
                        },
                        {
                          Header: "Mitra",
                          accessor: "owner",
                        },
                        {
                          Header: "Periode",
                          accessor: "period",
                          maxWidth: 150
                        },
                        {
                          Header: "Status VA",
                          accessor: "bankSync",
                        },

                        {
                          Header: "Status Pesan",
                          accessor: "messageSend",
                        },
                        {
                          Header: "Status Invoice",
                          accessor: "invoiceStatus",
                          sortable: false,
                          filterable: false,
                        },
                        {
                          Header: "Actions",
                          accessor: "actions",
                          sortable: false,
                          filterable: false,
                          maxWidth: 120
                        },
                      ]

export const invoiceColumnsUser = [
                        {
                          Header: "Invoice ID",
                          accessor: "id",
                           maxWidth: 100
                        },
                        {
                          Header: "Customer ID",
                          accessor: "customerId",
                           maxWidth: 150
                        },
                        {
                          Header: "Customer",
                          accessor: "customer",
                          maxWidth: 300
                        },
                        {
                          Header: "Owner",
                          accessor: "owner",
                        },
                        {
                          Header: "Status VA",
                          accessor: "bankSync",
                        },

                        {
                          Header: "Status Pesan",
                          accessor: "messageSend",
                        },
                        {
                          Header: "Period",
                          accessor: "period",
                          maxWidth: 150
                        },
                        {
                          Header: "Status Invoice",
                          accessor: "invoiceStatusUser",
                          sortable: false,
                          filterable: false,
                        },
                        {
                          Header: "Actions",
                          accessor: "actions",
                          sortable: false,
                          filterable: false,
                          maxWidth: 120
                        },
                      ]