import PanelHeader from "components/PanelHeader/PanelHeader"
import { Row, Col,
    Card, CardHeader,
    CardTitle, CardBody } from "reactstrap"
function ServerError() {
    return (
      <>
    <>
    <PanelHeader size="sm" />
    <Row>
        <Col md = '12'>
        <Card>
        <CardHeader>
            <CardTitle tag="h4"></CardTitle>
        </CardHeader>
        <CardBody>
            <div className="content">
            <div className="typography-line">
            <h1 className="title">ARTAMEDIA</h1>
            <h6>Server Has Been Crashed Please Contact Administrator</h6>
            <p>Please contact Network Operating Center HelpDesk 0811-2596-111.</p>
            <p>500 Internal Server Error</p>
            </div>
            </div>
        </CardBody>
        </Card>
        </Col>
    </Row>
    </>
         </>
    )
}

export default ServerError