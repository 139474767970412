/*eslint-disable*/
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// used for making the prop types of this component
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, Collapse, Button } from "reactstrap";
import artaLogo from "assets/img/Artamedia-logo.png";

// core components
import avatar from "assets/img/default-avatar.png";
// import logo from "logo-white.svg";
import {decodeToken,getRefreshToken,
        addUserData, addRoleList,
        getUserData} from "./../../variables/common"
import UserService from "./../../Main/UserService"
import { CatchError } from "Main/Exceptions/exceptions";
import defaultAvatar from "assets/img/pattern1.jpg";
var ps;

function Sidebar(props) {
  const sidebar = React.useRef();
  const [openAvatar, setOpenAvatar] = useState(true);
  const [collapseStates, setCollapseStates] = useState({});
  const refreshToken = getRefreshToken()
  const userService = new UserService()
  const userData = getUserData()
  const User = decodeToken(refreshToken);
  const userId = User.id
  const type = userData.userType
  const min = 0
  const max = 12

  let Menu = props.routes.slice(min,max)
  if (type === 'ADMINISTRATOR') {
    Menu = Menu.filter(function (obj) {
      // 👇 testing whether the properties match the criteria
      return obj.name !== "Mitra Manager" &&
      obj.name !== 'Bank Account'
      // && obj.name !== 'Template Manager'
    });
  }
  if (type === 'USER') {
    Menu = Menu.filter(function (obj) {
      // 👇 testing whether the properties match the criteria
      return obj.name !== "Mitra Manager" &&
      obj.name !== 'Bank Account' &&
      obj.name !== 'User Manager' &&
      obj.name !== 'Template Manager' &&
      obj.name !== 'Router Manager' &&
      obj.name !== 'Logistic Manager'
    });
  }

  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(1, 1);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const user = getUserData()
  const username = user.username || 'no-data'
  const imgUrl = user.imgUrl
  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      // to stop the warning of calling setState of unmounted component
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  });

  React.useEffect(() => {
    setCollapseStates(getCollapseStates(props.routes.slice(min,max)));
  }, []);
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  const LogOut = async() => {
    swal("Logout", "Logout sekarang","info", {
                buttons: {
                logOut: {text: "Logout", value: 'lgout' },
                cancel: 'Cancel'
              }
            }
      ).then(async(value) => {
        switch (value) {
          case 'lgout':
            try {
              await userService.LogOut(refreshToken)
              props.history.push('/auth/')
            } catch (error) {
              props.history.push('/auth/')
            }
            break;
            default:
            break;
        }
    })
  }

  const Setting = async() => {
    const user = await userService.getUser(userId)
    addUserData(user.data)
    const userPermission = await userService.getAllPermission(userId)
    addRoleList(userPermission.data)
    props.history.push('/admin/Resource-page')
  }


  const Profile = async() => {
    try {
      const user = await userService.getUser(userId)
      addUserData(user.data)
      props.history.push('/admin/user-page')
    } catch (error) {
      return CatchError(error, props, refreshToken)
    }
  }

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !collapseStates[prop.state];
        return (
          <li
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={collapseStates[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setCollapseStates(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={collapseStates[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <>
      <div className="sidebar" data-color={props.backgroundColor}>
        <div className="logo">
          <a
            href="https://artamedianet.co.id/"
            className="simple-text logo-mini"
            target="_blank"
          >
            <div className="logo-img">
              <img src={artaLogo} alt="arta-logo" />
            </div>
          </a>
          <a
            className="simple-text logo-normal"
            target="_blank"
          >
            Arta Billing
          </a>
          <div className="navbar-minimize">
            <Button
              outline
              className="btn-round btn-icon"
              color="neutral"
              id="minimizeSidebar"
              onClick={() => props.minimizeSidebar()}
            >
              <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
              <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
            </Button>
          </div>
        </div>

        <div className="sidebar-wrapper" ref={sidebar}>
          <div className="user">
            <div className="photo">
              <img alt={avatar} className="avatar border-gray full-profile-sm img-thumbnail" src={imgUrl || defaultAvatar} />
            </div>
            <div className="info">
              <a
                href="#pablo"
                data-toggle="collapse"
                aria-expanded={openAvatar}
                onClick={() => setOpenAvatar(!openAvatar)}
              >
                <span>
                  {username}
                  <b className="caret" />
                </span>
              </a>
              <Collapse isOpen={openAvatar}>
                <ul className="nav">
                  <li>
                    <a onClick={Profile}>
                      <span className="sidebar-mini-icon">MP</span>
                      <span className="sidebar-normal">Profile Saya</span>
                    </a>
                  </li>
                  {/* <li>
                    <a onClick={editProfile}>
                      <span className="sidebar-mini-icon">EP</span>
                      <span className="sidebar-normal">Edit Profile</span>
                    </a>
                  </li> */}
                  <li>
                    <a onClick={Setting}>
                      <span className="sidebar-mini-icon">S</span>
                      <span className="sidebar-normal">Pengaturan</span>
                    </a>
                  </li>
                  <li>
                    <a onClick={LogOut}>
                      <span className="sidebar-mini-icon">LG</span>
                      <span className="sidebar-normal">LogOut</span>
                    </a>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
          <Nav>{createLinks(Menu)}</Nav>
        </div>
      </div>
    </>
  );
}

Sidebar.defaultProps = {
  routes: [],
  showNotification: true,
  backgroundColor: "black",
  minimizeSidebar: () => {},
};

Sidebar.propTypes = {
  // links that are rendered
  routes: PropTypes.arrayOf(PropTypes.object),
  // if you want to show a notification when switching between mini sidebar and normal
  showNotification: PropTypes.bool,
  // background color for the component
  backgroundColor: PropTypes.oneOf([
    "blue",
    "yellow",
    "green",
    "orange",
    "red",
    "black",
  ]),
  // function that is called upon pressing the button near the logo
  minimizeSidebar: PropTypes.func,
};

export default Sidebar;
