import React, { useEffect } from "react";
import LoadingScreen from "components/Loader/LoadingScreen";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Select from "react-select"
import { useState } from "react";
import {getRouterSelect, getOwner} from "variables/common";
import CustomerService from "Main/CustomerService";
import { DateTimestr } from "Main/Utils";
import RouterService from "Main/RouterService";
import BasicInput from "components/Input/BasicInput";
import SelectBasic from "components/Input/SelectBasic";
import { getPaymentStatus } from "variables/common";
import { PriceFormat } from "variables/common";
import { getSubscriptionHistoryAddOnData } from "variables/Add-On/SubscriptionHistoryAddOn";


function SubscriptionAddOnHistoryView(props) {
  const customerService = new CustomerService()
  const routerService = new RouterService()
  const BillingData = getSubscriptionHistoryAddOnData()
  var statusOptions = getPaymentStatus()
  const ownerData = getOwner()
  const [routerSelectOption, routerSelectOptionOnChange] = useState(getRouterSelect())
  const [selectItemOptions, selectItemOptionsOnChange] = useState([])
  const {creation, modified,
    createdBy, modifiedBy,
    routerid, AddonsId,
    customerName, customerCategory: category,
    subscriptionName: name, paid,
    total_price: price, tax_prc: taxPrc,
    startSubscribe, endSubscribe} = BillingData
  const currency = 'idr'
  const totalPrice = PriceFormat(Number(price) + Number(taxPrc), currency)
  const owner = ownerData.find((own) => own.value === BillingData.owner)
  const [itemAddOn , itemAddOnOnChange] = useState(selectItemOptions.find(item => item.value === AddonsId || []))
  const [status , statusOnChange] = useState(statusOptions.find(stat => stat.value === paid))
  const [loading, loadingOnChange] = useState(false)
  const start = DateTimestr(startSubscribe)
  const end = DateTimestr(endSubscribe)
  const [router, routerOnChange] = useState(routerSelectOption.find((rtr) => rtr.value === routerid) || [])

  useEffect(() => {
    loadingOnChange(true)
    getRouter(owner.mitraid)
    loadingOnChange(false)
  }, [])

  const getRouter = async(mitraid) => {
    // sessionStorage.removeItem('urban')
    const dat = await routerService.getRouterSelect(mitraid)
    const result = dat.data
    routerSelectOptionOnChange(result)
    const selected = result.find((rtr) => rtr.value === routerid) || []
    routerOnChange(selected)
    await getItemAddOn(selected.value, owner.mitraid, category)
    return result
  }

  const getItemAddOn = async(routerid, mitraid, category) => {
    // sessionStorage.removeItem('urban')
    const dat = await customerService.getItemAddOnSelect(routerid, mitraid, category)
    const result = dat.data
    selectItemOptionsOnChange(result)
    itemAddOnOnChange(result.find(item => item.value === AddonsId) || [])
    return result
  }


  const children = (
    <><div className="content">
          <Row>
          <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">{name} Riwayat Subscription Add-On</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <BasicInput
                          label = "Owner"
                          value={owner.value}
                          />
                        <div>
                          <BasicInput
                            label = "Customer"
                            value={customerName}
                            />
                          <Label>Customer Category {category}</Label>
                        </div>
                        <Label>Lokasi Router</Label>
                            <div>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Pilih Lokasi Router"
                              name="singleSelect"
                              value = {router}
                              isDisabled ={true}
                              options={routerSelectOption}
                              onChange={(value) => {
                                routerOnChange(value)
                                getItemAddOn(value.value, owner.mitraid, category)
                                itemAddOnOnChange('')
                                }
                              }
                            />
                            {!router.status ? (
                              <label className="error">
                                Router Offline
                              </label>
                            ) : <label className="success">
                                Router Online
                              </label>}
                          </div>
                        <SelectBasic
                            label = {"Paket Layanan Add-On"}
                            value = {itemAddOn}
                            isDisabled = {true}
                            option={selectItemOptions}
                            onChange={(value) => {
                              itemAddOnOnChange(value)
                            }}
                        />
                        <SelectBasic
                            label = {"Status"}
                            isDisabled = {true}
                            value = {status}
                            option={statusOptions}
                            onChange={(value) => {
                              statusOnChange(value)
                            }}
                        />
                        <BasicInput
                          label = "Mulai Subscription"
                          value={start}
                        />
                        <BasicInput
                          label = "Akhir Subscription"
                          value={end}
                        />
                        <BasicInput
                          label = "Harga"
                          value={PriceFormat(price, currency)}
                        />
                        <BasicInput
                          label = "Pajak PPN"
                          value={PriceFormat(taxPrc, currency)}
                        />
                        <BasicInput
                          label = "Total Harga"
                          value={totalPrice}
                        />
                        <BasicInput
                          label = "Creation"
                          value={DateTimestr(creation)}
                        />
                        <BasicInput
                          label = "Modified"
                          value={DateTimestr(modified)}
                        />
                        <BasicInput
                          label = "Created By"
                          value={createdBy}
                        />
                        <BasicInput
                          label = "Modified By"
                          value={modifiedBy}
                        />
                        </FormGroup>
                      </Col>
                      </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
        </Row>
      </div>
    </>
  );
return (
    <>
    <PanelHeader size="sm" />
    <LoadingScreen children={children} loading = {loading}/>
    </>
  );
}


export default SubscriptionAddOnHistoryView;
