import React, { useEffect } from "react";
import Select from "react-select"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken, addCustomerOptions,
        getMitraBankSelect, addMitraBankSelect,
        VirtualAccount, getCustomerData,
        addVirtualAccountList, 
        getUserData} from "./../../../variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions"
import BankAccountService from "Main/BankAccountService";
import CustomerService from "Main/CustomerService";
import ValidationInput from "components/Input/ValidationInput";

function VirtualAccountPage(props) {
  const bankAccountService =  new BankAccountService()
  const customerService = new CustomerService()
  const refreshToken = getRefreshToken()
  const customerData = getCustomerData()
  const userData = getUserData()
  const id = customerData.id
  const category = customerData.category
  const mitraid = userData.mitraId
  const [BankOption, BankOptionOnChange] = useState(getMitraBankSelect())
  const [customer, customerOnChange ] = useState(customerData.name)
  const [bankName, bankNameOnChange] = useState('')
  const [bankNumber, bankNumberOnChange] = useState('')
  const [isAuto, isAutoOnChage] = useState(false)
  const [isManual, isManualOnChage] = useState(false)
  const [isManualPrf, isManualPrfOnChange] = useState(false)
  const [netId, netIdOnChage] = useState(false)
  const [companyNumber, companyNumberOnChange] = useState()
  const [prefix, prefixOnChange] = useState()
  const [cid, cidOnChange] = useState()
  const [limitMin, limitMinOnChange] = useState(1)
  const [limitMax, limitMaxOnChange] = useState(1)
  // const [selectCustomerOptions, selectCustomerOptionsOnChange] = useState(getCustomerOptions())
  // const customerOption = selectCustomerOptions.find(e => {
  //   const result = e.label === customerData.name
  //   return [result]})

  const fetch = async() => {
    console.log(mitraid)
    const customerOption = await customerService.getCustomerSelect()
    const BankOptions = await bankAccountService.getBankAccountSelect(mitraid)
    addCustomerOptions(customerOption.data)
    // selectCustomerOptionsOnChange(customerOption.data)
    addMitraBankSelect(BankOptions.data)
    BankOptionOnChange(BankOptions.data)
  }

  useEffect(() => {
    fetch()
  }, [])

  const GetCompanyNumber = async (idBank) => {
    const payload = await bankAccountService.getVirtualAccountCompany(idBank)
    const companyNumber = payload.data.companyNumber || 'Company Number tidak tersedia'
    const prefix = payload.data.prefix || 'Prefix tidak tersedia'
    const cid = payload.data.clientId || 'ClientID tidak tersedia'
    companyNumberOnChange(companyNumber)
    prefixOnChange(prefix)
    cidOnChange(cid)
    checkPrefixCIdCombineLen(payload.data.prefix, payload.data.clientId)
  }

  const [bankNumberPassed, bankNumberPassedOnChange] = useState(true)

  const submitVirtualBankAccount = async() => {
    try {
      var payload = VirtualAccount(mitraid, id, bankName.value, isAuto,  netId)
      if (!isAuto) {
        const bankNum = !isManualPrf ? bankNumber : prefix + cid + bankNumber
        payload = VirtualAccount(mitraid, id, bankName.value, isAuto, netId, bankNum)
      }
      console.log(payload)
      const result = await bankAccountService.addVirtualAccount(payload)
      swal(result.status, result.message, "success").then(async () => {
        await fetch()
        // const pages = 1
        // const limits = 10
        const dat =  await bankAccountService.getAllVirtualAccount(id)
        const result = dat.data
        addVirtualAccountList(result)
        props.history.push('/admin/customer-view')
      })
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

  function checkPrefixCIdCombineLen (prefix = '', cid = '', Manual, Manual16){
    const combine = prefix + cid
    const combineLen = combine.length || 0
    const min =  !Manual ? !Manual16 ? 8: 12 : (16 - combineLen) - 1
    const max =  !Manual ?  !Manual16 ? 12: 16 : (16 - combineLen)
    limitMinOnChange(min)
    limitMaxOnChange(max)
    return combineLen
  }

  return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Form Virtual Account</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <Label>Customer</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Customer"
                            name="singleSelect"
                            value = {customer}
                            onChange={(value) => { customerOnChange(value) }
                            }
                          />
                          <Label>Kategori Customer {category}</Label>
                          <FormGroup check>
                            <Label check> Genereate Dengan Network Id
                              <Input type="checkbox"
                              value = {netId}
                              onChange = {e => netIdOnChage(e.target.checked)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                        </FormGroup>
                        <Label>Nama Bank</Label>
                            <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Bank"
                            name="singleSelect"
                            value = {bankName}
                            options = {BankOption}
                            onChange={ async (value) => {
                              bankNameOnChange(value)
                              await GetCompanyNumber(value.value)
                            }
                            }
                          />
                        <div>
                        <FormGroup check>
                            <Label check> Auto Generate
                              <Input type="checkbox"
                              value = {isAuto}
                              onChange = {e => {
                                isAutoOnChage(e.target.checked)
                                isManualOnChage(false)
                                }
                              }
                              />
                              <span className="form-check-sign" />
                            </Label>
                        </FormGroup>
                        <Label>Company Number</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Company Number"
                            name="singleSelect"
                            value = {companyNumber}
                          />
                        <Label>Prefix</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Prefix"
                            name="singleSelect"
                            value = {prefix}
                          />
                        <Label>Client ID</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="ClientID"
                            name="singleSelect"
                            value = {cid}
                          />
                        <FormGroup check>
                            <Label check> Isi Manual
                              <Input type="checkbox"
                              value = {isManual}
                              onChange = {e => {
                                isManualOnChage(e.target.checked)
                                checkPrefixCIdCombineLen(prefix, cid, isManualPrf, e.target.checked)
                                }
                              }
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        <ValidationInput
                          label = {`Nomor Virtual Account (min ${limitMin} max ${limitMax}`}
                          disabled = {isAuto}
                          value = {bankNumber}
                          onChange = {bankNumberOnChange}
                          min = {limitMin}
                          max = {limitMax}
                          passed = {bankNumberPassed}
                          passedOnChange = {bankNumberPassedOnChange}
                        />
                        <FormGroup check>
                            <Label check> Isi Manual dengan Prefix dan ClientID
                              <Input type="checkbox"
                              value = {isManualPrf}
                              onChange = {e => {
                                isManualPrfOnChange(e.target.checked)
                                checkPrefixCIdCombineLen(prefix, cid, e.target.checked, isManual)
                              }}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                       </div>
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {submitVirtualBankAccount} disabled = {(bankNumberPassed && !isAuto & !bankName.value)} color="info">{(isManual & !isAuto) ? 'Submit': 'Generate'}</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default VirtualAccountPage;
