import React from "react";
import Select from 'react-select'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label
} from "reactstrap"

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
// import NotificationAlert from "react-notification-alert";
import { useState } from "react";
import {addBankAccountData, getBankAccountData,
        getRefreshToken, BankAccount, getBankSelect, getUserPermission} from "./../../../variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions"
import BankAccountService from "Main/BankAccountService";
import { DateTimestr } from "Main/Utils";
import { verifyLength, useFormInput } from "Main/Utils"

function BankAccountView(props) {
  const pages = 'bank-account'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const bankAccountService =  new BankAccountService()
  const notificationAlert = React.useRef();
  const refreshToken = getRefreshToken()
  const bankAccountData = getBankAccountData()
  const BankOptions = getBankSelect()
  const id = bankAccountData.id
  const owner = bankAccountData.mitra_name
  const email = useFormInput(bankAccountData.mitra_email)
  const name = bankAccountData.name
  const [bankName, bankNameOnChange] = useState(BankOptions.find(dat => dat.value === bankAccountData.bank_name))
  const bankRegion = useFormInput(bankAccountData.bank_region || 'INDONESIA')
  const [bankNumber, bankNumberOnChange] = useState(bankAccountData.bank_number)
  const created = DateTimestr(bankAccountData.creation)
  const modified = DateTimestr(bankAccountData.modified)
  const createdBy = useFormInput(bankAccountData.modified_by)
  const modifiedBy = useFormInput(bankAccountData.modified_by)
  const clientName = useFormInput(bankAccountData.client_name)
  const qauthAccessKey = useFormInput(bankAccountData.qauth_access_key)
  const qauthSecretKey = useFormInput(bankAccountData.qauth_secret_key)
  const xApiKey = useFormInput(bankAccountData.x_api_key)
  const secretKey = useFormInput(bankAccountData.secret_key)
  const [minLengthState, setminLengthState] = React.useState('');
  const [companyNumber, companyNumberOnChange] = useState(bankAccountData.company_number)
  const [isCompany, isCompanyOnChange] = useState(!(!companyNumber))
  const [companyminLengthState, setCompanyminLengthState] = useState('');
  const [integrateAPI, integrateAPIOnChange] = useState(!(!clientName.value && !qauthAccessKey.value && !qauthSecretKey.value && !xApiKey.value && !secretKey.value))
  const [bankNumberPassed, bankNumberPassedOnChange] = useState(!verifyLength(bankNumber, 10))
  const [prefixminLengthState, setprefixminLengthState] = useState("");
  const [cidminLengthState, setcidminLengthState] = useState("");
  const [sckminLengthState, setsckminLengthState] = useState("");
  const [prefix, prefixOnChange] = useState(bankAccountData.prefix)
  const [cid, cidOnChange] = useState(bankAccountData.client_id)
  const [sck, sckOnChange] = useState(bankAccountData.sck)
  const [prefixPassed, prefixPassedOnChange] = useState(true)
  const [cidPassed, cidPassedOnChange] = useState(true)
  const [sckPassed, sckPassedOnChange] = useState(true)

  const getBankAccount =  async() => {
    try {
      const result =  await bankAccountService.getBankAccount()
      // console.log(result)
      addBankAccountData(result)
    } catch (error) {
      return await CatchError(error, props, refreshToken)
      }
  }

  const putBankAccount = async() => {
    swal("Update", "Data Tidak Dapat di Pulihkan", "info", {
            buttons: {
            update: {text: "Update", value: 'up' },
            cancel: 'Cancel'
          }
        }
    ).then(async(value) => {
      var result
      switch (value) {
            case 'up':
              try {
                let payload =  !isCompany ? BankAccount(owner,
                bankName.value,
                bankNumber,
                bankRegion.value) : BankAccount(owner,
                  bankName.value,
                  bankNumber,
                  bankRegion.value,
                  companyNumber,
                  prefix,
                  cid,
                  sck)

              if (integrateAPI) {
                  payload = BankAccount(owner, bankName.value,
                    bankNumber, bankRegion.value, companyNumber, prefix, cid, sck, clientName.value, qauthAccessKey.value,
                    qauthSecretKey.value, xApiKey.value, secretKey.value)
                }
                result = await bankAccountService.putBankAccount(id, payload)
                swal(result.status, result.message, 'success')
              } catch (error) {
                return await CatchError(error, props, refreshToken)
              }
              break;

            default:
              break;
          }
        })
  }
    return (
      <>
      {/* <NotificationAlert ref={notificationAlert} /> */}
        <PanelHeader size = 'sm'/>
        <div className="content" onLoadStart ={getBankAccount}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">{name}</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <Label>Nama Bank</Label>
                            <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Nama Bank"
                            name="singleSelect"
                            value = {bankName}
                            options = {BankOptions}
                            onChange={(value) => { bankNameOnChange(value) }
                            }
                          />
                          <Label>Lokasi Bank</Label>
                          <Input
                            placeholder="Lokasi Bank"
                            {...bankRegion}
                            type="text"
                          />
                          <Label>Owner Email</Label>
                          <Input
                            placeholder="owner email"
                            value={email.value}
                            type="email"
                          />
                          <Label>Owner</Label>
                          <Input
                            placeholder="owner"
                            name="owner"
                            type="text"
                            value= {owner}
                          />
                        <FormGroup check>
                            <Label check> Company Account
                              <Input type="checkbox"
                                  checked = {isCompany}
                                  value = {isCompany}
                                  onChange = {e => isCompanyOnChange(e.target.checked)}
                                />
                              <span className="form-check-sign" />
                            </Label>
                        </FormGroup>
                        <Label>Company Number</Label>
                        <Input
                          name="min_length_bank_number"
                          placeholder="Company Number"
                          type="number"
                          value = {companyNumber}
                          disabled = {!isCompany}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 5)) {
                              companyNumberOnChange(e.target.value)
                            }
                            if (!verifyLength(e.target.value, 4)) {
                              setCompanyminLengthState("has-danger");
                              bankNumberPassedOnChange(true)
                            } else {
                              setCompanyminLengthState("has-success");
                              bankNumberPassedOnChange(false)
                            }
                            // setminLength(e.target.value);
                          }}
                        />
                        {companyminLengthState === "has-danger" ? (
                          <label className="error">
                            Please enter at least 4 characters.
                          </label>
                        ) : null}
                        <Label>Prefix Virtual Account</Label>
                        <Input
                          name="min_length_bank_number"
                          placeholder="Prefix VA"
                          type="number"
                          value = {prefix}
                          disabled = {!isCompany}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 6)) {
                              prefixOnChange(e.target.value)
                            }
                            if (!verifyLength(e.target.value, 1)) {
                              setprefixminLengthState("has-danger");
                              prefixPassedOnChange(true)
                            } else {
                              setprefixminLengthState("has-success");
                              prefixPassedOnChange(false)
                            }
                            // setminLength(e.target.value);
                          }}
                        />
                        {prefixminLengthState === "has-danger" ? (
                          <label className="error">
                            Isi Minimal 4 Karakter.
                          </label>
                        ) : null}
                        <Label>CID Virtual Account</Label>
                        <Input
                          name="min_length_bank_number"
                          placeholder="CID"
                          type="number"
                          value = {cid}
                          disabled = {!isCompany}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 9)) {
                              cidOnChange(e.target.value)
                            }
                            if (!verifyLength(e.target.value, 1)) {
                              setcidminLengthState("has-danger");
                              cidPassedOnChange(true)
                            } else {
                              setcidminLengthState("has-success");
                              cidPassedOnChange(false)
                            }
                            // setminLength(e.target.value);
                          }}
                        />
                        {cidminLengthState === "has-danger" ? (
                          <label className="error">
                            Isi Minimal 4 Karakter.
                          </label>
                        ) : null}
                        <Label>Secret Key Virtual Account</Label>
                        <Input
                          name="min_length_bank_number"
                          placeholder="SCK"
                          type="text"
                          value = {sck}
                          disabled = {!isCompany}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 31)) {
                              sckOnChange(e.target.value)
                            }
                            if (!verifyLength(e.target.value, 4)) {
                              setsckminLengthState("has-danger");
                              sckPassedOnChange(true)
                            } else {
                              setsckminLengthState("has-success");
                              sckPassedOnChange(false)
                            }
                            // setminLength(e.target.value);
                          }}
                        />
                        {sckminLengthState === "has-danger" ? (
                          <label className="error">
                            Isi Minimal 4 Karakter.
                          </label>
                        ) : null}
                        <Label>Lokasi Bank</Label>
                          <Input
                            placeholder="bank region"
                            {...bankRegion}
                            type="text"
                        />
                         <div>
                        <Label>Nomor Rekening</Label>
                        <Input
                          name="min_length_bank_number"
                          placeholder="Bank Number"
                          type="number"
                          value = {bankNumber}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 17)) {
                              bankNumberOnChange(e.target.value)
                            }
                            if (!verifyLength(e.target.value, 10)) {
                              setminLengthState("has-danger");
                              bankNumberPassedOnChange(true)
                            } else {
                              setminLengthState("has-success");
                              bankNumberPassedOnChange(false)
                            }
                            // setminLength(e.target.value);
                          }}
                        />
                        {minLengthState === "has-danger" ? (
                          <label className="error">
                            Isi Minimal 10 Karakter.
                          </label>
                        ) : null}
                       </div>
                         <FormGroup check>
                            <Label check> Integrasi Dengan API
                              <Input type="checkbox"
                                  checked = {integrateAPI}
                                  value = {integrateAPI}
                                  onChange = {e => integrateAPIOnChange(e.target.checked)}
                                />
                              <span className="form-check-sign" />
                            </Label>
                      </FormGroup>
                        <Label>Nama Client</Label>
                          <Input
                            placeholder="Nama Client"
                            disabled = {!integrateAPI}
                            {...clientName}
                            type="text"
                        />
                        <Label>Qauth Access Key</Label>
                          <Input
                            placeholder="Qauth Access Key"
                            disabled = {!integrateAPI}
                            {...qauthAccessKey}
                            type="text"
                        />
                        <Label>Qauth Secret Key</Label>
                          <Input
                            placeholder="Qauth Secret Key"
                            disabled = {!integrateAPI}
                            {...qauthSecretKey}
                            type="text"
                        />
                        <Label>X API Key</Label>
                          <Input
                            placeholder="X API Key"
                            disabled = {!integrateAPI}
                            {...xApiKey}
                            type="text"
                        />
                        <Label>Secret Key</Label>
                          <Input
                            placeholder="Secret Key"
                            disabled = {!integrateAPI}
                            {...secretKey}
                            type="text"
                        />
                        <Label>Created</Label>
                          <Input
                            placeholder="Created"
                            value = {created}
                            type="text"
                          />
                          <Label>modified</Label>
                          <Input
                            placeholder="Modified"
                            value = {modified}
                            type="text"
                          />
                          <Label>created By</Label>
                          <Input
                            placeholder="created By"
                            value = {createdBy.value}
                            type="email"
                          />
                          <Label>modified By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {modifiedBy.value}
                            type="email"
                          />

                        </FormGroup>
                        </Col>
                    </Row>
                  <Row>
                    </Row>
                    <Row>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            <Button onClick = {putBankAccount}
            disabled = {!Permission.Update
              && bankNumberPassed
              && prefixPassed
              && cidPassed
              && sckPassed} color="info">Edit</Button>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default BankAccountView;
