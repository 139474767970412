export const PPPSessionColumns = [
    {
        Header: "Name",
        accessor: 'name',
        maxWidth: 350,
    },
    {
        Header: "Service",
        accessor: 'service',
        maxWidth: 150,
    },
    {
        Header: "Address",
        accessor: 'address',
        maxWidth: 250,
    },
    // {
    //     Header: "Limit Byte In",
    //     accessor: 'limitByteIn',
    //     maxWidth: 200,
    // },
    // {
    //     Header: "Limit Byte Out",
    //     accessor: 'limitByteOut',
    //     maxWidth: 200,
    // },
    {
        Header: "Uptime",
        accessor: 'uptime',
        maxWidth: 150,
    },
    {
        Header: "Radius",
        accessor: 'radius',
        maxWidth: 80,
    },
]
