import CollStatic from "components/Chart/CollStatic";
import { ClassnameByNumberValue } from "Main/Utils";
import { Card, CardBody, CardTitle, Row } from "reactstrap";
import { PriceFormat } from "variables/common";

function HostpotCardStats ({ data, currency = 'idr', title, size = '4'}) {
    const {
      qtyPaid,
      buyingPricePaid,
      sellingPricePaid,
      realizeProfit,
      qtyUnpaid,
      qtyExpired
    } = data

    return (
        <Card className="card-stats card-raised">
              <CardBody>
                {!title ? null : <CardTitle tag="p">{title}</CardTitle>}
                <Row>
                  <CollStatic
                    key = {1}
                    className = {ClassnameByNumberValue(-buyingPricePaid)}
                    value = {PriceFormat(-buyingPricePaid, currency)}
                    title = {"Total Pengeluaran Tahunan"}
                    type = {"icon icon-primary"}
                    logo = {"now-ui-icons business_money-coins"}
                    md = {size}
                  />
                  <CollStatic
                    key = {1}
                    className = {ClassnameByNumberValue(sellingPricePaid)}
                    value = {PriceFormat(sellingPricePaid, currency)}
                    title = {"Pendapatan Kotor Tahunan"}
                    type = {"icon icon-info"}
                    logo = {"now-ui-icons business_money-coins"}
                    md = {size}
                  />
                  <CollStatic
                    key = {2}
                    className = {ClassnameByNumberValue(realizeProfit)}
                    value = {PriceFormat(realizeProfit, currency)}
                    title = {"Profit Tahunan"}
                    type = {"icon icon-success"}
                    logo = {"now-ui-icons business_money-coins"}
                    md = {size}
                  />
                  <CollStatic
                    key = {9}
                    value = {qtyPaid}
                    title = {"Voucher Terbayar"}
                    type = {"icon icon-success"}
                    logo = {"now-ui-icons users_single-02"}
                    md = {size}
                  />
                  <CollStatic
                    key = {10}
                    value = {qtyUnpaid}
                    title = {"Voucher Belum Bayar"}
                    type = {"icon icon-info"}
                    logo = {"now-ui-icons users_single-02"}
                    md = {size}
                  />
                  <CollStatic
                    key = {11}
                    value = {qtyExpired}
                    title = {"Voucher Expired"}
                    type = {"icon icon-danger"}
                    logo = {"now-ui-icons users_single-02"}
                    md = {size}
                  />
                </Row>
              </CardBody>
            </Card>
    )
}

export default HostpotCardStats