import React, { useEffect } from "react";
import Select from "react-select"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken, getBankSelect,
        BankAccount, addCustomerOptions,
        addBankAccountList, getOwner } from "./../../../variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions"
import BankAccountService from "Main/BankAccountService";
import CustomerService from "Main/CustomerService";
import { verifyLength, useFormInput } from "Main/Utils"

function BankAccountPage(props) {
  const bankAccountService =  new BankAccountService()
  const customerService = new CustomerService()
  const refreshToken = getRefreshToken()
  const BankOptions = getBankSelect()
  const [mitra, mitraOnChange ] = useState('')
  const [bankName, bankNameOnChange] = useState('')
  const bankRegion = useFormInput('INDONESIA')
  const clientName = useFormInput('')
  const qauthAccessKey = useFormInput('')
  const qauthSecretKey = useFormInput('')
  const xApiKey = useFormInput('')
  const secretKey = useFormInput('')
  const [bankNumber, bankNumberOnChange] = useState('')
  const [companyNumber, companyNumberOnChange] = useState(null)
  const [minLengthState, setminLengthState] = useState("");
  const [companyminLengthState, setCompanyminLengthState] = useState("");
  const [prefixminLengthState, setprefixminLengthState] = useState("");
  const [cidminLengthState, setcidminLengthState] = useState("");
  const [sckminLengthState, setsckminLengthState] = useState("");
  const [prefix, prefixOnChange] = useState(null)
  const [cid, cidOnChange] = useState(null)
  const [sck, sckOnChange] = useState(null)
  const [integrateAPI, integrateAPIOnChange] = useState(false)
  const [isCompany, isCompanyOnChange] = useState(false)
  const [bankNumberPassed, bankNumberPassedOnChange] = useState(true)
  const [prefixPassed, prefixPassedOnChange] = useState(true)
  const [cidPassed, cidPassedOnChange] = useState(true)
  const [sckPassed, sckPassedOnChange] = useState(true)
  const selectMitraOptions = getOwner()

   const getBankAccount =  async(len = 'ALL') => {
    try {
      const dat =  await bankAccountService.getAllBankAccount(len)
      const result = dat.data
      addBankAccountList(result)
      return result
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const fetch = async() => {
      const customerOption = await customerService.getCustomerSelect()
      addCustomerOptions(customerOption.data)
  }

  useEffect(() => {
    fetch()
  }, [])


  const submitBankAccount = async() => {
    try {
        let payload =  !isCompany ? BankAccount(mitra.value,
          bankName.value,
          bankNumber,
          bankRegion.value) : BankAccount(mitra.value,
            bankName.value,
            bankNumber,
            bankRegion.value,
            companyNumber,
            prefix,
            cid,
            sck)

        if (integrateAPI) {
            payload = BankAccount(mitra.value, bankName.value,
              bankNumber, bankRegion.value,
              companyNumber, prefix, cid, sck,
              clientName.value, qauthAccessKey.value,
              qauthSecretKey.value, xApiKey.value, secretKey.value)
        }
      const result = await bankAccountService.addBankAccount(payload)
      swal(result.status, result.message, "success").then(async () => {
        await getBankAccount()
        props.history.push('/admin/bank-account-list')
      })
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Bank Account Manager</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <Label>Mitra</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Mitra"
                            name="singleSelect"
                            value = {mitra}
                            options = {selectMitraOptions}
                            onChange={(value) => { mitraOnChange(value) }
                            }
                          />
                        <Label>Nama Bank</Label>
                            <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Nama Bank"
                            name="singleSelect"
                            value = {bankName}
                            options = {BankOptions}
                            onChange={(value) => { bankNameOnChange(value) }
                            }
                          />
                         <FormGroup check>
                            <Label check> Company Account atau Pengunaan Virtual Account
                              <Input type="checkbox"
                                  value = {isCompany}
                                  onChange = {e => isCompanyOnChange(e.target.checked)}
                                />
                              <span className="form-check-sign" />
                            </Label>
                        </FormGroup>
                        <Label>Company Number</Label>
                        <Input
                          name="min_length_bank_number"
                          placeholder="Company Number"
                          type="number"
                          value = {companyNumber}
                          disabled = {!isCompany}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 5)) {
                              companyNumberOnChange(e.target.value)
                            }
                            if (!verifyLength(e.target.value, 4)) {
                              setCompanyminLengthState("has-danger");
                              bankNumberPassedOnChange(true)
                            } else {
                              setCompanyminLengthState("has-success");
                              bankNumberPassedOnChange(false)
                            }
                            // setminLength(e.target.value);
                          }}
                        />
                        {companyminLengthState === "has-danger" ? (
                          <label className="error">
                            Isi Minimal 4 Karakter.
                          </label>
                        ) : null}
                        <Label>Prefix Virtual Account</Label>
                        <Input
                          name="min_length_bank_number"
                          placeholder="Prefix VA"
                          type="number"
                          value = {prefix}
                          disabled = {!isCompany}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 6)) {
                              prefixOnChange(e.target.value)
                            }
                            if (!verifyLength(e.target.value, 1)) {
                              setprefixminLengthState("has-danger");
                              prefixPassedOnChange(true)
                            } else {
                              setprefixminLengthState("has-success");
                              prefixPassedOnChange(false)
                            }
                            // setminLength(e.target.value);
                          }}
                        />
                        {prefixminLengthState === "has-danger" ? (
                          <label className="error">
                            Isi Minimal 4 Karakter.
                          </label>
                        ) : null}
                        <Label>CID Virtual Account</Label>
                        <Input
                          name="min_length_bank_number"
                          placeholder="CID"
                          type="number"
                          value = {cid}
                          disabled = {!isCompany}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 9)) {
                              cidOnChange(e.target.value)
                            }
                            if (!verifyLength(e.target.value, 1)) {
                              setcidminLengthState("has-danger");
                              cidPassedOnChange(true)
                            } else {
                              setcidminLengthState("has-success");
                              cidPassedOnChange(false)
                            }
                            // setminLength(e.target.value);
                          }}
                        />
                        {cidminLengthState === "has-danger" ? (
                          <label className="error">
                            Isi Minimal 4 Karakter.
                          </label>
                        ) : null}
                        <Label>Secret Key Virtual Account</Label>
                        <Input
                          name="min_length_bank_number"
                          placeholder="SCK"
                          type="text"
                          value = {sck}
                          disabled = {!isCompany}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 31)) {
                              sckOnChange(e.target.value)
                            }
                            if (!verifyLength(e.target.value, 4)) {
                              setsckminLengthState("has-danger");
                              sckPassedOnChange(true)
                            } else {
                              setsckminLengthState("has-success");
                              sckPassedOnChange(false)
                            }
                            // setminLength(e.target.value);
                          }}
                        />
                        {sckminLengthState === "has-danger" ? (
                          <label className="error">
                            Isi Minimal 4 Karakter.
                          </label>
                        ) : null}
                        <Label>Lokasi Bank</Label>
                          <Input
                            placeholder="Lokasi Bank"
                            {...bankRegion}
                            type="text"
                        />
                         <div>
                        <Label>Nomor Rekening</Label>
                        <Input
                          name="min_length_bank_number"
                          placeholder="Nomor Rekening"
                          type="number"
                          value = {bankNumber}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 17)) {
                              bankNumberOnChange(e.target.value)
                            }
                            if (!verifyLength(e.target.value, 10)) {
                              setminLengthState("has-danger");
                              bankNumberPassedOnChange(true)
                            } else {
                              setminLengthState("has-success");
                              bankNumberPassedOnChange(false)
                            }
                            // setminLength(e.target.value);
                          }}
                        />
                        {minLengthState === "has-danger" ? (
                          <label className="error">
                            Isi Minimal 10 Karakter
                          </label>
                        ) : null}
                       </div>
                        <FormGroup check>
                            <Label check> Integrasi Dengan API
                              <Input type="checkbox"
                                  value = {integrateAPI}
                                  onChange = {e => integrateAPIOnChange(e.target.checked)}
                                />
                              <span className="form-check-sign" />
                            </Label>
                      </FormGroup>
                        <Label>Nama Client</Label>
                          <Input
                            placeholder="Nama Client"
                            disabled = {!integrateAPI}
                            {...clientName}
                            type="text"
                        />
                        <Label>Qauth Access Key</Label>
                          <Input
                            placeholder="Qauth Access Key"
                            disabled = {!integrateAPI}
                            {...qauthAccessKey}
                            type="text"
                        />
                        <Label>Qauth Secret Key</Label>
                          <Input
                            placeholder="Qauth Secret Key"
                            disabled = {!integrateAPI}
                            {...qauthSecretKey}
                            type="text"
                        />
                        <Label>X API Key</Label>
                          <Input
                            placeholder="X API Key"
                            disabled = {!integrateAPI}
                            {...xApiKey}
                            type="text"
                        />
                        <Label>Secret Key</Label>
                          <Input
                            placeholder="Secret Key"
                            disabled = {!integrateAPI}
                            {...secretKey}
                            type="text"
                        />
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {submitBankAccount}
                  disabled = {bankNumberPassed
                    && prefixPassed
                    && cidPassed
                    && sckPassed}
                  color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}

export default BankAccountPage;
