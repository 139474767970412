import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {getRefreshToken} from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions"
import { validatePermission } from "Main/Utils";
import CommonList from "../../../../components/Table/CommonList"
import { ColumnsUser, ColumnsAdmin, ColumnsTrashAdmin,
ColumnsTrashUser } from "./HostpotUserColumns"
import { getUserPermission, getUserSelect } from "variables/common";
import StatusButton from "components/Button/StatusButton";
import { verifySuperAdmin } from "Main/Utils";
import HostpotUserService from "Main/HostpotVoucher/HostpotUserService";
import { getOwner } from "variables/common";
import NoLabelSelect from "components/Input/NoLabelSelect";
import { customTextFromBoolean } from "Main/Utils";
import UserService from "Main/UserService";
import { addHostpotUserData } from "variables/HostpotVoucher/HostpotUser";
import { payloadHostpotProfileTrash } from "variables/HostpotVoucher/HostpotProfile";
import { payloadHostpotUserPaid } from "variables/HostpotVoucher/HostpotUser";

function HostpotUserList(props) {
  const page = 'voucherhostpot'
  const hostpotUserService = new HostpotUserService()
  const userService = new UserService()
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role.toLowerCase() === page) || ''
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const refreshToken = getRefreshToken()
  const [viewTrashPage, viewTrashPageOnChange] = useState(false)
  const { mitraSuper, organization, id: userId, userAdmin } = verifySuperAdmin()
  const Columns = mitraSuper ? ColumnsAdmin : ColumnsUser
  const ColumnsTrash = mitraSuper ? ColumnsTrashAdmin : ColumnsTrashUser
  const ownerOption = getOwner()
  const [resellerOption, setResellerOption] = useState(getUserSelect())
  const [mitra, setMitra] = useState(ownerOption.find((data) => data.value === organization))
  const [reseller, setReseller] = useState(resellerOption.find((data) => data.userId === userId))

  async function GetReseller(mitra) {
      const resellerList = await userService.getUserSelect(mitra)
      const resellerData = resellerList.data

      setResellerOption(resellerData)

      const defaultReseller = resellerData.find((data) => data.userId === userId)
      const finalSelection = !defaultReseller ? resellerData[0] : defaultReseller
      setReseller(finalSelection)
      return finalSelection.userId
  }

  const getAll =  async(trash = false, mitra = '', reseller = '') => {
    try {
      setDataLoad(true)

      const len = 'ALL'
      const dat =  await hostpotUserService.getAll(len, trash, mitra, reseller)
      const result = dat.data

      setDataState(mapping(result))
      setDataTable(result)
      setDataLoad(false)
      return result
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  useEffect(() => {
    validatePermission(props, 0, 1)
    GetReseller(mitra.mitraid)
    getAll(viewTrashPage, mitra.mitraid, reseller.userId)
  }, [])

    const deleteData = async (id) => {
      const selection = await swal("Delete", "Data Tidak Dapat di Recover", "info", {
        buttons: {
          cancel: 'Batal',
          delete: {text: "Hapus", value: 'del' }
        }}
      )

      switch (selection) {
          case 'del':
            try {
              setDataLoad(true)
              const result = await hostpotUserService.delete(id)
              await swal(result.status, result.message, 'success')
              await getAll(viewTrashPage, mitra.mitraid, reseller.userId)
              const newState = dataState.filter(data => data.id !== id)
              setDataState(newState)
              viewTrashPageOnChange(false)
              setDataLoad(false)
              return true
            } catch (error) {
              return await CatchError(error, props, refreshToken)
            }

          default:
            await getAll(viewTrashPage, mitra.mitraid, reseller.userId)
            return false
        }
    }

    const sendTrash = async (id, trash) => {
      try {
          setDataLoad(true)
          const payload = payloadHostpotProfileTrash(trash)
          const result = await hostpotUserService.setTrash(id, payload)
          await swal('Success', result.message, 'success')
          if (trash === true) {
            const state = await getAll(viewTrashPage, mitra.mitraid, reseller.userId)
            const stateRaw = mapping(state)
            const newState = stateRaw.filter(data => data.id !== id)
            setDataState(newState)
          } else {
            await getAll(viewTrashPage, mitra.mitraid, reseller.userId)
            viewTrashPageOnChange(false)
          }
          setDataLoad(false)
          return true
      } catch (error) {
        return await CatchError(error, props, refreshToken)
      }
    }

    const sendToTrash = async (id) => {
      const setTrash = await swal("Trash", "Send To Trash", "info", {
        buttons: {
          cancel: 'Cancel',
          delete: {text: "Continue", value: 'del' }
        }}
      )
      switch (setTrash) {
        case 'del':
          const trash = true
          return await sendTrash(id, trash)
        default:
          await getAll(viewTrashPage, mitra.mitraid, reseller.userId)
          return false
      }
    }

    const recoverTrash = async (id) => {
      const setTrash = await swal("Recover", "Recover Data", "info", {
        buttons: {
          cancel: 'Cancel',
          delete: {text: "Continue", value: 'del' }
        }}
      )

      switch (setTrash) {
        case 'del':
          const trash = false
          return await sendTrash(id, trash)
        default:
          return false
      }
    }

    const addData = () => {
      props.history.push('/admin/hostpot-user-page')
    }

    const generateData = () => {
      props.history.push('/admin/hostpot-user-generate')
    }

  const viewData = async(id) => {
    try {
      const dat = await hostpotUserService.get(id)
      const result = dat.data
      addHostpotUserData(result)
      props.history.push('/admin/hostpot-user-update')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const setPaidStatus = async(id, status) => {
    try {
      setDataLoad(true)
      var operation = status ? 'Bayar' : 'Belum-Bayar'
      const paidAmmount = 0
      const payload = payloadHostpotUserPaid(status, paidAmmount)
      const dat = await hostpotUserService.setPaid(id, payload)
      await swal(operation, dat.message, dat.status)
      const result = dat.data
      const newState = await getAll(viewTrashPage, mitra.mitraid, reseller.userId)
      setDataState(mapping(newState))
      setDataLoad(false)
      return result
    } catch (error) {
      setDataLoad(false)
      await swal('failed', error.message, 'warning')
    }
  }

  const mapping =  (Table) => {
    return Table.map((Data, i) => {
      try {
          return {
            id: Data.id,
            username: Data.username,
            password: Data.password,
            router: Data.routerName,
            rateUp: `${Data.rateUp} ${Data.rateUpMeasurement}`,
            rateDown: `${Data.rateDown} ${Data.rateDownMeasurement}`,
            mitra: Data.mitra,
          setPaidAdmin: (
              <div className="actions-center">
                  <StatusButton
                    value = {Data.isPaid}
                    statusOnChange = {async (e) => {
                      const status = !Data.isPaid
                      await setPaidStatus(Data.id, status)
                    }}
                    status = {Data.isPaid}
                    disableText='Unpaid'
                    enableText='Paid'
                    disableColor='warning'
                    enableColor = 'success'
                    disabled = {Permission.Update}
                  />
              </div>
            ),
            setPaidUser: customTextFromBoolean(Data.isPaid, 'Bayar', 'Belum-Bayar'),
            actionsUser: (
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={ async () => {
                    await viewData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Read}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
              </div>
            ),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={ async () => {
                    await viewData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Read}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                <Button
                  onClick={async () => {
                    await sendToTrash(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}1`}
                  disabled = {!Permission.Delete}
                  color="danger"
                  size="sm"
                >
                <i className="fa fa-trash" />
                </Button>{" "}
              </div>
            ),
            recover: (
              <Button
                  onClick={ async () => {
                    await recoverTrash(Data.id)
                  }}
                  className="btn"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Update}
                  color="warning"
                  size="sm"
                >
                 Recover
              </Button>
            ),
            actionsTrash: (
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={ async () => {
                    await deleteData(Data.id)
                  }}
                  className="btn-icon"
                  id={`tooltip${Data.id}1`}
                  disabled = {!Permission.Delete}
                  color="danger"
                  size="sm"
                >
                <i className="fa fa-times" />
                </Button>{" "}
              </div>
            ),
        };
        } catch (error) {
          return []
        }
      }
    )
  }

  const [dataState, setDataState] = useState(mapping(Table))

  const viewTrash = async (trash) => {
    setDataLoad(true)
    await getAll(trash, mitra.mitraid, reseller.userId)
    setDataLoad(false)
  }

  const customMenu = (
    <Row>
      <Col md={2}>
        <div  className="actions-left">
          <Button onClick={generateData} color="info"
          disabled = {!Permission.Create}
          >Generate</Button>
        </div>
      </Col>
      <Col md={1}>
        <div  className="actions-left">
          <Button onClick={addData} color="info"
          disabled = {!Permission.Create}
          >Add</Button>
        </div>
      </Col>
      <Col md={3}>
        <NoLabelSelect
          key = {'2'}
          placeholder = {"Mitra"}
          value = {mitra}
          option = {ownerOption}
          isDisabled = {!mitraSuper}
          onChange = {async (e) => {
            setMitra(e)
            const resellerData = await GetReseller(e.mitraid)
            await getAll(viewTrashPage, e.mitraid, resellerData)
          }}
        />
      </Col>
      <Col md={4}>
        <NoLabelSelect
          key = {'3'}
          placeholder = {"Reseller"}
          value = {reseller}
          option = {resellerOption}
          isDisabled = {!mitraSuper && !userAdmin}
          onChange = {async (e) => {
            setReseller(e)
            await getAll(viewTrashPage, mitra.mitraid, e.userId)
          }}
        />
      </Col>
      <Col md={2}>
        <div className="actions-right">
          <FormGroup check>
            <Label check> View Trash
              <Input type="checkbox"
              value = {viewTrashPage}
              checked = {viewTrashPage}
                onChange = {async (e) => {
                  viewTrashPageOnChange(e.target.checked)
                  await viewTrash(e.target.checked)
                }}
              />
              <span className="form-check-sign" />
            </Label>
          </FormGroup>
        </div>
      </Col>
    </Row>
  )

  return (
    <>
    <PanelHeader size="sm" />
      {/* <PanelHeader
        content={
          <div className="header text-center">
            <h2 className="title">List Hostpot User</h2>
          </div>
        }
      /> */}
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CommonList
                key={'1'}
                header={!viewTrashPage ? 'Hostpot User': 'Trash'}
                columns={!viewTrashPage ? Columns : ColumnsTrash}
                dataState={dataState}
                menu = {customMenu}
                Add={addData}
                load={load}
                getData={getAll}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default HostpotUserList;
