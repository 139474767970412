import 'chart.js/auto';
import { Chart } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Card } from "reactstrap";
import { customerPanelChart } from "variables/charts";

function CustomerHistoryChart ({data, legend = true, chartLine1Label, chartLine2Label, chartLine3Label, chartLine4Label, transparantPanel = false, responsive = true, maintainAspectRatio = false, backgroundColor, gridColor, tickColor}) {
    try {
        ChartJS.register(
         CategoryScale,
         LinearScale,
         PointElement,
         LineElement,
         Title,
         Tooltip,
         Legend
        )

        const PanelChart = customerPanelChart(data, chartLine1Label, chartLine2Label, chartLine3Label, chartLine4Label, legend, responsive, maintainAspectRatio, backgroundColor, gridColor, tickColor)
        const panelData = PanelChart.data

        // const { chartIncome } = chartData
        // const panelIndividual = chartsLine1(chartIncome)
        // const { data: charInvdividualData, options: chartIndividualOption } = panelIndividual
        return (
            <Card className="shadow-md mt-1 mb-3 mt-2">
                <div className="panel-body">
                    <Chart
                        type="bar"
                        key={'2hweuwbef'}
                        data={panelData}
                        options={PanelChart.options}
                    />
                </div>
            </Card>
        )
    } catch (error) {
        console.log(error.message)
        return (
            <Card className="shadow-md mt-1 mb-3 mt-2">
                <div className="panel-body">
                    <p>{error.message}</p>
                </div>
            </Card>
        )
    }
}

export default CustomerHistoryChart