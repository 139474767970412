import React, { useEffect } from "react";
import LoadingScreen from "components/Loader/LoadingScreen";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Label,
} from "reactstrap";
import BasicInput from "components/Input/BasicInput";
import SelectBasic from "components/Input/SelectBasic";
import Checklist from "components/Button/Checklist";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken, PriceFormat } from "variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions";
import { DateTimestr } from "Main/Utils";
import { verifySuperAdmin } from "Main/Utils";
import { getHostpotProfileData } from "variables/HostpotVoucher/HostpotProfile";
import { payloadHostpotProfileUpdate } from "variables/HostpotVoucher/HostpotProfile";
import { gethostpotProfileTimeMeasurementData } from "variables/HostpotVoucher/HostpotProfile";
import { gethostpotProfileInternetMeasurementData } from "variables/HostpotVoucher/HostpotProfile";
import RouterService from "Main/RouterService";
import HostpotProfileService from "Main/HostpotVoucher/HostpotProfileService";

function HostpotProfileUpdate(props) {
  const hostpotProfile = getHostpotProfileData()
  const timeMeasurement = gethostpotProfileTimeMeasurementData()
  const internetMeasurement = gethostpotProfileInternetMeasurementData()
  const routerService = new RouterService()
  const hostpotProfileService =  new HostpotProfileService()
  const refreshToken = getRefreshToken();
  const timeLimitMeasurementSelect = gethostpotProfileTimeMeasurementData()
  const internetLimitMeasurementSelect = gethostpotProfileInternetMeasurementData()
  const { userSuper } = verifySuperAdmin()
  const [poolSelect, setPoolSelect] = useState([])
  const [mitra, mitraOnChange] = useState(hostpotProfile.mitra)
  const [loading, loadingOnChange ] = useState(false)
  const [name, setName] = useState(hostpotProfile.name)
  const [buyingPrice, setBuyingPrice] = useState(hostpotProfile.buyingPrice)
  const [sellingPrice, setSellingPrice] = useState(hostpotProfile.sellingPrice)
  const [pool, setPool] = useState(hostpotProfile.pool)
  const [ip, setIp] = useState(hostpotProfile.ip)
  const [subnet, setSubnet] = useState(hostpotProfile.subnet || 24)
  const [sharedUser, setSharedUser] = useState(hostpotProfile.sharedUser)
  const [rateUp, setRateUp] = useState(hostpotProfile.rateUp)
  const [rateDown, setRateDown] = useState(hostpotProfile.rateDown)
  const [priority, setPriority] = useState(hostpotProfile.priority)
  const [rateUpMeasurement, setRateUpMeasurement] = useState(internetLimitMeasurementSelect.find(own => own.value === hostpotProfile.rateUpMeasurement))
  const [rateDownMeasurement, setRateDownMeasurement] = useState(internetLimitMeasurementSelect.find(own => own.value === hostpotProfile.rateDownMeasurement))
  const [lockMac, setLockMac] = useState(hostpotProfile.lockMac)
  const [timeLimit, setTimeLimit] = useState(hostpotProfile.timeLimit)
  const [quotaLimit, setQuotaLimit] = useState(hostpotProfile.quotaLimit)
  const [timeLimitMeasurement, setTimeLimitMeasurement] = useState(timeLimitMeasurementSelect.find(own => own.value === hostpotProfile.timeLimitMeasurement))
  const [quotaLimitMeasurement, setQuotaLimitMeasurement] = useState(internetLimitMeasurementSelect.find(own => own.value === hostpotProfile.quotaLimitMeasurement))
  const [description, setDescription ] = useState(hostpotProfile.description)
  const [router, setRouter] = useState(hostpotProfile.routerName)
  const [useProfilePPPlist, setUseProfilePPPlist] = useState(false)
  const [RouterPool, SetRouterPool] = useState({})
  const {id, creation, modified,
    createdBy, modifiedBy, routerId } = hostpotProfile

  const getRouterPPP =  async(routerId) => {
    const result =  await routerService.getpppProfileSelect(routerId)
    const poolData = result.data
    setPoolSelect(poolData)
    const poolSelected = poolData.find(poolData.find(e => e.value === hostpotProfile.pool))
    SetRouterPool(poolSelected)
    setPool((!poolSelected || !poolSelected.value) ? hostpotProfile.pool : poolSelected.value)
    return result.data
  }

  useEffect(() => {
    getRouterPPP(routerId)
  }, [])


  const update = async() => {
    const selection = await swal("Update", "Perubahan Data Tidak Dapat di Batalkan", "info", {
            buttons: {
            update: {text: "Update", value: 'up' },
            cancel: 'Batal'
          }
        }
    )
    switch (selection) {
        case 'up':
          try {
            loadingOnChange(true)
            const payload = payloadHostpotProfileUpdate(name, buyingPrice, sellingPrice, pool, ip, subnet, sharedUser, rateUp, rateDown, priority, rateUpMeasurement.value, rateDownMeasurement.value, lockMac, timeLimit, quotaLimit, timeLimitMeasurement.value, quotaLimitMeasurement.value, description)
            // console.log(payload)
            const result = await hostpotProfileService.put(id, payload)

            await swal(result.status, result.message, 'success')
            loadingOnChange(false)
            props.history.push('/admin/hostpot-profile-list')
          } catch (error) {
            loadingOnChange(false)
            return await CatchError(error, props, refreshToken)
          }
          break;

        default:
          break;
      }
  }

  const children = (
    <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Detail Hostpot Profile</h5>
                </CardHeader>
                <CardBody>
                  <BasicInput
                    key={"2"}
                    label = {'Mitra'}
                    value = {mitra}
                    disabled
                    onChange = {mitraOnChange}
                    type
                  />
                  <BasicInput
                    key={"2"}
                    label = {'name'}
                    value = {name}
                    onChange = {setName}
                    type
                  />
                  <BasicInput
                    key={"2"}
                    label = {'Lokasi Router'}
                    value = {router}
                    disabled
                    onChange = {setRouter}
                    type
                  />
                  <Row>
                    <Col>
                      <BasicInput
                        key={"3"}
                        label = {'Harga Beli'}
                        value = {buyingPrice}
                        onChange = {(e) => {
                          if (e >= 0) {
                            setBuyingPrice(e)
                          }
                        }}
                        type = {'number'}
                      />
                    </Col>
                    <Col>
                      <BasicInput
                        key={"4"}
                        label = {'Price Display'}
                        value = {PriceFormat(buyingPrice, 'idr')}
                        disabled
                        onChange = {() => {}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <BasicInput
                        key={"5"}
                        label = {'Harga Jual'}
                        value = {sellingPrice}
                        onChange = {(e) => {
                          if (e >= 0) {
                            setSellingPrice(e)
                          }
                        }}
                        type = {'number'}
                      />
                    </Col>
                    <Col>
                      <BasicInput
                        key={"6"}
                        label = {'Price Display'}
                        value = {PriceFormat(sellingPrice, 'idr')}
                        disabled
                        onChange = {() => {}}
                      />
                    </Col>
                  </Row>
                  {!useProfilePPPlist ? (
                      <>
                         <BasicInput
                           key={"8"}
                           label = {'Nama Pool'}
                           value = {pool}
                           onChange = {setPool}
                         />
                      </>
                    ) : (
                    <>
                      <Label>Pool List</Label>
                      <SelectBasic
                        key={"9"}
                        label={" Select Pool From Router"}
                        value={RouterPool}
                        option={poolSelect}
                        onChange={(e) => {
                          SetRouterPool(e)
                          setPool(e.value)
                          setIp(e.ip)
                          setSubnet(Number(e.subnet))
                          }} />
                      </>
                    )
                  }
                  <BasicInput
                    key={"8"}
                    label = {'IP Address'}
                    value = {ip}
                    onChange = {setIp}
                  />
                  <BasicInput
                    key={"9"}
                    label = {'Subnet'}
                    value = {subnet}
                    onChange = {(e) => {
                        if (e >= 0 && e <= 32) {
                          setSubnet(e)
                        }
                      }
                    }
                    type = {'number'}
                  />
                  <Checklist
                    key={"9"}
                    text = {"Memakai Pool Router"}
                    value = {useProfilePPPlist}
                    onChange = { async (e) => {
                      setUseProfilePPPlist(e.target.checked)
                      setPool('')
                      setIp('0.0.0.0')
                      setSubnet(24)
                      if (!e.target.checked) {
                        SetRouterPool([])
                        return e
                      }
                      await getRouterPPP(router.value)
                    }}
                  />
                  <Row>
                    <Col>
                      <BasicInput
                        key={"10"}
                        label = {'Kecepatan Upload'}
                        value = {rateUp}
                        onChange = {(e) => {
                          if (e >= 0) {
                            setRateUp(e)
                          }
                        }
                        }
                        type = {'number'}
                      />
                    </Col>
                    <Col>
                      <SelectBasic
                        key={"11"}
                        label = {'Upload Measurement'}
                        value = {rateUpMeasurement}
                        onChange = {setRateUpMeasurement}
                        option = {internetMeasurement}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <BasicInput
                        key={"12"}
                        label = {'Kecepatan Download'}
                        value = {rateDown}
                        onChange = {(e) => {
                          if (e >= 0) {
                            setRateDown(e)
                          }
                        }}
                        type = {'number'}
                      />
                    </Col>
                    <Col>
                      <SelectBasic
                        key={"13"}
                        label = {'Download Measurement'}
                        value = {rateDownMeasurement}
                        onChange = {setRateDownMeasurement}
                        option = {internetMeasurement}
                      />
                    </Col>
                  </Row>
                  <BasicInput
                    key={"14"}
                    label = {'Priority'}
                    value = {priority}
                    onChange = {(e) => {
                      if (e > 0 && e <= 10) {
                        setPriority(e)
                      }
                    }}
                    type = {'number'}
                  />
                  <BasicInput
                    key={"15"}
                    label = {'Shared User'}
                    value = {sharedUser}
                    onChange = {(e) => {
                      if (e >= 0) {
                        setSharedUser(e)
                      }
                    }}
                    type = {'number'}
                  />
                  <Row>
                    <Col>
                      <BasicInput
                        key={"16"}
                        label = {'Limit Quota'}
                        value = {quotaLimit}
                        onChange = {(e) => {
                          if (e >= 0) {
                            setQuotaLimit(e)
                          }
                        }}
                        type = {'number'}
                      />
                    </Col>
                    <Col>
                      <SelectBasic
                        key={"17"}
                        label = {'Quota Measurement'}
                        value = {quotaLimitMeasurement}
                        onChange = {setQuotaLimitMeasurement}
                        option = {internetMeasurement}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <BasicInput
                        key={"18"}
                        label = {'Limit Waktu'}
                        value = {timeLimit}
                        onChange = {(e) => {
                          if (e >= 0) {
                            setTimeLimit(e)
                          }
                        }}
                        type = {'number'}
                      />
                    </Col>
                    <Col>
                      <SelectBasic
                        key={"19"}
                        label = {'Time Measurement'}
                        value = {timeLimitMeasurement}
                        onChange = {setTimeLimitMeasurement}
                        option = {timeMeasurement}
                      />
                    </Col>
                  </Row>
                  <Checklist
                    key = {"20"}
                    text = {'Lock MAC Address'}
                    value = {lockMac}
                    checked = {lockMac}
                    onChange = {(e) => {
                      setLockMac(e.target.checked)
                    }}
                  />
                  <BasicInput
                    key={"21"}
                    label = {'Deskripsi'}
                    value = {description}
                    onChange = {setDescription}
                    type = {'textarea'}
                  />
                  <BasicInput
                    key={"9"}
                    label = {'Creation'}
                    disabled
                    value = {DateTimestr(creation)}
                  />
                  <BasicInput
                    key={"10"}
                    label = {'Modified'}
                    disabled
                    value = {DateTimestr(modified)}
                  />
                  <BasicInput
                    key={"11"}
                    label = {'CreatedBy'}
                    disabled
                    value = {createdBy}
                  />
                  <BasicInput
                    key={"12"}
                    label = {'ModifiedBy'}
                    disabled
                    value = {modifiedBy}
                  />

                  <Button onClick = {update}
                  disabled = {!userSuper}
                  color="info">Submit</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
  )
  return (
    <>
      <PanelHeader size="sm" />
      <LoadingScreen
        children={children}
        loading = {loading}
        text={"Loading..."}/>
    </>
  );
}


export default HostpotProfileUpdate;
