import { customTextFromBoolean } from "Main/Utils"
import { StatusDisplay } from "Main/Utils"
const { Table } = require("reactstrap")

function ErrorTable ({Data, header})  {
    const defaultHeader = header || (<>
        <th>Renew</th>
        <th>Create</th>
        <th>Status</th>
        <th>Message</th>
    </>)
    return (
    <Table responsive bordered hover className="bg-secondary text-light" max-height= "400px">
          <thead className="text-light">
            <tr>
              {defaultHeader}
            </tr>
          </thead>
          <tbody>
          {Data.map((data, i) => {
            return (
              <>
            <tr key={i}>
            <td className={!data.renew ? "bg-warning": "bg-success"}>{customTextFromBoolean(data.renew, 'Renew', 'Not-Renew')}</td>
            <td className={!data.create ? "bg-warning": "bg-success"}>{customTextFromBoolean(data.create, 'Create', 'Not-Create')}</td>
            <td className={data.err ? "bg-danger": "bg-success"}>{StatusDisplay(!data.err)}</td>
            <td>{!data.data[0] ? 'No Message' : data.data[0].message}</td>
            </tr>
          </>
           )
        }
        )}
      </tbody>
    </Table>)
  }

  export default ErrorTable