import { addMitraFinanceList } from "variables/FinanceStatement/FinanceVariable"
import FinanceStatementService from "./FinanceStatementService"

const FetchFinanceStatementData =  async () => {
    const financestatementService = new FinanceStatementService()
    const financeList = await financestatementService.getAllFinanceReport()
    const data = financeList.data
    addMitraFinanceList(data)
    return data
}

export default FetchFinanceStatementData