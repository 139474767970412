import { SplitString } from "Main/Utils";

const { ExtractHeaderData } = require("Main/Utils");

function FileDownloads(response, Filename, Extention) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const { extention, filename } = ExtractHeaderData(response)

        let filenameFinal = Filename
        const ext = SplitString(Filename || filename, '.')
        if (!ext[1]) {
            filenameFinal = `${Filename || filename}.${Extention || extention}`
        }
        link.setAttribute(
            "download",
            filenameFinal
        );
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
}

export default FileDownloads;