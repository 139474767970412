import React from "react";
import LoadingScreen from "components/Loader/LoadingScreen";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken,  getOwner } from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import BasicInput from "components/Input/BasicInput";
import { DateTimestr } from "Main/Utils";
import Checklist from "components/Button/Checklist";
// import { payloadDownload } from "variables/MitraSubmission/SubmissionFinance";
// import { Downloads } from "Main/Utils";
import { payloadUpdate } from "variables/ErpNextClient/ErpNextClient";
import { verifySuperAdmin } from "Main/Utils";
import ErpNextClientService from "Main/ErpNextClient/ErpNextClientService";
import { getErpNextClientData } from "variables/ErpNextClient/ErpNextClient";
import { addErpNextClientList } from "variables/ErpNextClient/ErpNextClient";

function ErpNextClientUpdate(props) {
  const erpNextClientData = getErpNextClientData()
  const submissionFinanceService =  new ErpNextClientService()
  const refreshToken = getRefreshToken();
  const ownerData = getOwner()
  const [loading, loadingOnChange ] = useState(false)
  const {id, creation, modified, owner: Owner,
    createdBy, modifiedBy, name, url, username, sync } = erpNextClientData
  const owner = ownerData.find(own => own.value === Owner)
  const [Name, setName] = useState(name)
  const [Url, setUrl] = useState(url)
  const [apiKey, setApiKey] = useState(username)
  const [apiSecret, setApiSecret] = useState('')
  const [Sync, setSync] = useState(sync)
  const { userSuper } = verifySuperAdmin()
  const [view, setView] = useState(false)

  const getAll =  async() => {
    const len = 'ALL'
    const result =  await submissionFinanceService.getAllData(len)
    addErpNextClientList(result.data)
  }

const update = async() => {
    swal("Update", "Data Can't be Undo", "info", {
            buttons: {
            update: {text: "Update", value: 'up' },
            cancel: 'Cancel'
          }
        }
    ).then(async(value) => {
      switch (value) {
            case 'up':
              try {
                loadingOnChange(true)
                const payload = payloadUpdate(Name, Url, apiKey, apiSecret, Sync)
                console.log(payload)
                const result = await submissionFinanceService.putData(id, payload)
                await getAll()
                swal(result.status, result.message, 'success').then(() => {
                  loadingOnChange(false)
                  props.history.push('/admin/erpnext-client-list')
                })
              } catch (error) {
                loadingOnChange(false)
                return await CatchError(error, props, refreshToken)
              }
              break;

            default:
              break;
          }
        })
    }

  const children = (
    <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Detail ERPnext Client</h5>
                </CardHeader>
                <CardBody>
                  <BasicInput
                    key={"1"}
                    label = {'Mitra'}
                    disabled
                    value = {owner.value}
                  />
                 <BasicInput
                    key={"2"}
                    label = {'nama'}
                    value = {Name}
                    onChange = {setName}
                    type
                  />
                  <BasicInput
                    key={"3"}
                    label = {'url'}
                    value = {Url}
                    onChange = {setUrl}
                    type
                  />
                  <BasicInput
                    key={"4"}
                    label = {'API Key'}
                    value = {apiKey}
                    onChange = {setApiKey}
                    type = {"text"}
                  />
                  <BasicInput
                    key={"5"}
                    label = {'Secret Key'}
                    value = {apiSecret}
                    onChange = {setApiSecret}
                    type = {!view ? "password" : "text"}
                  />
                  <Checklist
                    key={"6"}
                    text = {"View"}
                    value = {view}
                    onChange = {e => setView(e.target.checked)}
                  />
                  <BasicInput
                    key={"9"}
                    label = {'Creation'}
                    disabled
                    value = {DateTimestr(creation)}
                  />
                  <BasicInput
                    key={"10"}
                    label = {'Modified'}
                    disabled
                    value = {DateTimestr(modified)}
                  />
                  <BasicInput
                    key={"11"}
                    label = {'CreatedBy'}
                    disabled
                    value = {createdBy}
                  />
                  <BasicInput
                    key={"12"}
                    label = {'ModifiedBy'}
                    disabled
                    value = {modifiedBy}
                  />
                  <Checklist
                    key={"13"}
                    text = {'Aktifkan Sinkronisasi ERP-Next'}
                    value = {Sync}
                    disabled = {!userSuper}
                    onChange = {(e) => setSync(e.target.checked)}
                    checked = {Sync}
                  />
                  <Button onClick = {update}
                  disabled = {!userSuper}
                  color="info">Edit</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
  )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen
          children={children}
          loading = {loading}
          text={"Loading..."}/>
      </>
    );
}


export default ErpNextClientUpdate;
