import React from "react";

import defaultImage from "assets/img/pattern1.jpg";
import { BinnaryUpload } from "Main/Utils";
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import ItemService from "Main/ItemService";

function PictureUpload(props) {
  // eslint-disable-next-line
  const stateNow = props.image
  const [fileState, setFileState] = React.useState(stateNow);
  const itemService = new ItemService()
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState( stateNow || defaultImage);
  const url = props.link
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFileState(file);
      setImagePreviewUrl(reader.result);
      handleSubmit(e)
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  // eslint-disable-next-line
  const handleSubmit = async (e) => {
   try {
      // e.preventDefault();
    const payload = await BinnaryUpload(e.target.files)
    const response = await itemService.addImage(payload, url)
    await swal('Upload',response.message, response.status)
    // fileState is the file/image uploaded
    // in this function you can save the image (fileState) on form submit
    // you have to call it yourself
   } catch (error) {
      return CatchError(error, props)
   }
  };
  return (
    <div className="picture-container">
      <div className="picture">
        <img src={imagePreviewUrl} class="rounded mx-auto d-block full-profile img-thumbnail" alt="..." />
        <input type="file" onChange={(e) => handleImageChange(e)} />
      </div>
      <h6 className="description">Choose Picture</h6>
    </div>
  );
}

export default PictureUpload;
