export const Columns = [
    {
        Header: "Nama",
        accessor: "name",
    },
    {
        Header: "Tahun",
        accessor: "year"
    },
    {
        Header: "Pendapatan",
        accessor: "revenue"
    },
    {
        Header: "Pengeluaran",
        accessor: "expense"
    },
    {
        Header: "Pendapatan Bersih",
        accessor: "netIncome"
    },
    {
        Header: "View",
        accessor: "actions",
        sortable: false,
        filterable: false,
    }
]