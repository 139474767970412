/*eslint-disable*/
import React, { useState, useEffect } from "react";
// reactstrap components
import Select from 'react-select';
import {
  Card,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
// import Switch from "react-switch";
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {getRefreshToken } from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import LogisticSupplierService from "Main/Logistic/LogisticSupplierService";
import { addLogisticSupplierData } from "variables/common";
import { FetchLogisticBySupplier } from "./../LogisticItem/LogisticGoods/FetchLogistic";
import { getUserPermission } from "variables/common";
import { columns } from "./LogisticSupplierColumn";
import CommonList from "components/Table/CommonList";
import { getOwner } from "variables/common";
import { verifySuperAdmin } from "Main/Utils";



function LogisticSupplierList(props) {
  const pages = 'Logistic'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const logisticService =  new LogisticSupplierService()
  const userLen = useFormInput('ALL')
  const refreshToken = getRefreshToken()
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const ownerOption = getOwner()
  const { mitraSuper, organization } = verifySuperAdmin()
  const [mitra, mitraOnChange] = useState(ownerOption.find((data) => data.value === organization))

  try {
    const getData =  async(selectedMitra) => {
      try {
        setDataLoad(true)
        const len = userLen.value
        const dat =  await logisticService.getAll(selectedMitra, len)
        const result = dat.data

        setDataTable(result)
        setDataState(mapping(result))
        setDataLoad(false)
        return result
      } catch (error) {
        setDataLoad(false)
        return CatchError(error, props, refreshToken)
      }
    }

    useEffect( () => {
      getData()
    }, [])

    const DeleteData = (id) => {
      swal("Delete", "Data Tidak Dapat di Recover", "info", {
        buttons: {
          cancel: 'Cancel',
          delete: {text: "Delete", value: 'del' }
        }
      }
      ).then(async (value) => {
        switch (value) {
          case 'del':
            try {
              setDataLoad(true)
              const response = await logisticService.delete(id)
              await swal(response.status, response.message, 'success')
              const state = await getData()
              const stateRaw = mapping(state)
              const table = stateRaw.filter((dat) => dat.id !== id)
              setDataState(table)
              setDataLoad(false)
              return true
            } catch (error) {
              setDataLoad(false)
              return await CatchError(error, props, refreshToken)
            }

          default:
            await getData()
            return false
        }
      })
    }

    const ViewData = async (id) => {
    try {
      const result = await logisticService.get(id)
      await FetchLogisticBySupplier(id)
      addLogisticSupplierData(result.data)
      props.history.push('/admin/logistic-supplier-update')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

    const Add = () => {
        props.history.push('/admin/logistic-supplier-add')
    }

    const mapping = (TableData) => TableData.map((Data) => {
          try {
            return {
            id: Data.id,
            name: Data.name,
            email: Data.email,
            organization: Data.owner,
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>

                {/* use this button to add a edit kind of action */}
                <Button
                disabled = {!Permission.Update}
                  onClick={async () => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    await ViewData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  color="warning"
                  id={`tooltip${Data.id}10`}
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                {/* use this button to remove the data row */}
                <Button
                disabled = {!Permission.Delete}
                  onClick={ async () => {
                    await DeleteData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}11`}
                  color="danger"
                  size="sm"
                >
                  <i className="fa fa-times" />
                </Button>{" "}

              </div>
            ),
          };
          } catch (error) {
            return []
          }
        })
    const dataTable = Table
    const [dataState, setDataState] = React.useState(mapping(dataTable));

    const customMenu = (
      <Row>
        <Col md={10}>
          <Button onClick={Add} color="info" disabled = {!Permission.Create}>Add</Button>
        </Col>
        <Col md = {12}>
          <Select
            // className="react-select"
            // classNamePrefix="react-select"
            placeholder="owner"
            value={mitra}
            isDisabled = {!mitraSuper}
            options={ownerOption}
            onChange={ async (e) => {
              mitraOnChange(e);
              const list = await getData(e.mitraid)
              setDataState(mapping(list))
          } } />
        </Col>
      </Row>
    )

      return (
          <>
          <PanelHeader size="sm" />
          {/* <PanelHeader
              content={
                <div className="header text-center">
                  <h2 className="title">List Logistik Supplier</h2>
                </div>
              }
            /> */}
          <div className="content">
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CommonList
                    key={1}
                    header={'Logistik Supplier'}
                    columns={columns}
                    dataState={dataState}
                    menu = {customMenu}
                    Add={Add}
                    load={load}
                    getData={getData}
                  />
                </Card>
              </Col>
            </Row>
          </div>
          </>
      );
  } catch (error) {
    return (
      <>
      <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Logistik Supplier</h2>
              </div>
            }
          />
      <h3>{error.message || error}</h3>
      </>
    )
  }
}

export default LogisticSupplierList;
