import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { getRefreshToken, getOwner, getUserData } from "variables/common"
import { CatchError } from "Main/Exceptions/exceptions";
import LoadingScreen from "components/Loader/LoadingScreen";
import SelecttWithUserTier from "components/Input/SelectWithUserTier";
import SelectBasic from "components/Input/SelectBasic";
import CustomerService from "Main/CustomerService";
import swal from "sweetalert";

function InvoiceMailSender(props) {
  const customerService = new CustomerService()
  const refreshToken = getRefreshToken()
  const userData = getUserData()
  const ownerData = getOwner()
  // const bankOptionMaster = getBankSelect()
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const [type, setType] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ changeMitra, setChangeMitra ] = useState(false)
  const broadcastOption = [
    { label: 'Notifikasi Peringatan Jatuh Tempo', value: true },
    { label: 'Kirim Notifikasi Otomatis', value: false }
  ]

    const SendNotification = async() => {
        try {
          const value = await swal("Broadcast Notifikasi Invoice", "Kirim Notifikasi ke Semua Customer", "info", {
                    buttons: {
                    update: {text: "Broadcast", value: 'up' },
                    cancel: 'Cancel'
                  }
                }
          )
          switch (value) {
              case 'up':
                setLoading(true)
                const result = await customerService.sendEmailAll(owner.mitraid, type.value)
                await swal('notify', result.message, 'success')
                setLoading(false)
                return result
              default:
                break;
            }

        } catch (error) {
          setLoading(false)
          return await CatchError(error, props, refreshToken)
        }
    }

    async function cleanOption () {
        setType({})
    }

    const children = (
      <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Broadcast Notifikasi Invoice</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <SelecttWithUserTier
                              key={"1"}
                              label = {'Mitra *Wajib di Isi'}
                              value = {owner}
                              onChange = {ownerOnChange}
                              disabled = {changeMitra}
                              options = {ownerData}
                              subFunction = {cleanOption}
                              // subFunction = {deleteSelect}
                              checkText = {'Ubah Mitra'}
                              checkValue = {changeMitra}
                              checkValueOnChange = {setChangeMitra}
                            />
                          <SelectBasic
                            key={"3"}
                            label = {'Tipe Broadcast Pesan'}
                            value = {type}
                            option = {broadcastOption}
                            onChange = {setType}
                          />
                        </FormGroup>
                      </Col>
                      </Row>
                  </Form>
                  <Button onClick = {SendNotification} color="info">Broadcast Notifikasi</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    )

    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen children={children} loading = {loading} text={"Send Invoice Notification"}/>
      </>
    );
}


export default InvoiceMailSender;
