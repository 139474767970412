const { getData, setData } = require("variables/common");

const identifier = ['hostpotUserHistoryData', 'hostpotUserHistoryList']
const [data] = identifier

export function addHostpotUseHistoryrData(payload) {
  setData(data, payload)
}

export function getHostpotUserHistoryData() {
 return getData(data)
}
