function BasicMapIframe({name, lat, long, width="425",
                height="400"}) {

    const src = `https://maps.google.com/maps?q=${lat},${long}&t=&z=20&ie=UTF8&iwloc=&output=embed`
    return (
        <div className="mapouter">
            <div className="gmap_canvas">
                <iframe
                title={name || "title"}
                width= {width}
                height= {height}
                id="gmap_canvas"
                src={src}
                scrolling="no"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0">
                </iframe>
                </div>
            </div>
    )
}

export default BasicMapIframe;