import React from "react";
import Select from "react-select"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getIndoorDeviceData, getRefreshToken,
  IndoorDevice, indoorDeviceTypeOption } from "./../../../../variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions"
import { DateTimestr } from "Main/Utils";
import RouterService from "Main/RouterService";
import LoadingScreen from "components/Loader/LoadingScreen";
import InputCoordinate from "components/Input/InputCoordinate";

function IndoorDeviceInventoryView(props) {
  const routerService = new RouterService()
  const refreshToken = getRefreshToken()
  const indoorDeviceData = getIndoorDeviceData()
  const DeviceType = indoorDeviceTypeOption()
  console.log(DeviceType, indoorDeviceData, indoorDeviceData.device_type)
  const id = indoorDeviceData.id
  const routerid = indoorDeviceData.routerid
  const [name, nameOnChange] = useState(indoorDeviceData.name)
  const mitra = indoorDeviceData.mitraName
  const created = indoorDeviceData.creation
  const modified = indoorDeviceData.modified
  const createdBy = indoorDeviceData.created_by
  const modifiedBy = indoorDeviceData.modified_by
  const province = indoorDeviceData.province_name
  const subDistrict = indoorDeviceData.sub_district
  const urban = indoorDeviceData.urban
  const router = indoorDeviceData.routerName + ': IP ' + indoorDeviceData.ip_address + ' PORT ' + indoorDeviceData.port
  const [deviceType, deviceTypeOnChange] = useState(DeviceType.find(e => e.value === indoorDeviceData.device_type.toUpperCase()))
  const [type, typeOnChange] = useState(indoorDeviceData.type)
  const [sn, snOnChange] = useState(indoorDeviceData.sn)
  const [merk, merkOnChange] = useState(indoorDeviceData.merk)
  const [description, descriptionOnChange] = useState(indoorDeviceData.description)
  const [loading, loadingOnChange] = useState(false)
  const [latitude, setLatitude] = useState(indoorDeviceData.latitude)
  const [longitude, setLongitude] = useState(indoorDeviceData.longitude)

  const update = async() => {
    try {
          loadingOnChange(true)
          const payload = IndoorDevice(name, routerid, deviceType.value, merk, sn, type, latitude, longitude, description)
          console.log(payload)
          const result = await routerService.putIndoorDevice(id, payload)
          // await fetch(id)
          loadingOnChange(false)
          swal(result.status, result.message, "success").then(() => {
            props.history.push('/admin/router-update')
          })
      } catch (error) {
        loadingOnChange(false)
        return await CatchError(error, props, refreshToken)
      }
  }

  const children = (
      <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Detail Perangkat Indoor</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <Label>Nama</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Nama"
                            value = {name}
                            onChange = {(e) => {nameOnChange(e.target.value)}}
                          />
                        <InputCoordinate
                          latitude = {latitude}
                          setLatitude = {setLatitude}
                          longitude = {longitude}
                          setLongitude = {setLongitude}
                        />
                        <Label>Jenis Perangkat</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Jenis Perangkat"
                            name="singleSelect"
                            value = {deviceType}
                            options = {DeviceType}
                            onChange={(e) => { deviceTypeOnChange(e) }
                            }
                          />
                        <Label>Merk</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Merk"
                            name="singleSelect"
                            value = {merk}
                            onChange = {(e) => merkOnChange(e.target.value)}
                          />
                        <Label>Tipe</Label>
                        <Input
                          name="min_length_bank_number"
                          type="text"
                          placeholder="Tipe"
                          value = {type}
                          onChange={(e) => {
                            typeOnChange(e.target.value)
                          }}
                        />
                        </FormGroup>
                        <Label>Serial Number</Label>
                            <Input
                              placeholder="Serial Number"
                              value={sn}
                              onChange={(e) => snOnChange(e.target.value.toUpperCase())}
                              type="text"
                        />
                        <Label>Deskripsi</Label>
                            <Input
                              placeholder="Deskripsi"
                              value={description}
                              onChange={(e) => {descriptionOnChange(e.target.value)}}
                              type="text"
                        />
                        <Label>Mitra</Label>
                            <Input
                              placeholder="Mitra"
                              value={mitra}
                              type="text"
                        />
                        <Label>Provinsi</Label>
                          <Input
                            placeholder="Province"
                            value = {province}
                            type="text"
                          />
                          <Label>kabupaten</Label>
                          <Input
                            placeholder="Kabupaten"
                            value = {subDistrict}
                            type="text"
                          />
                          <Label>Kecamatan</Label>
                          <Input
                            placeholder="Kecamatan"
                            value = {urban}
                            type="text"
                          />
                          <Label>Router</Label>
                          <Input
                            placeholder="router"
                            value = {router}
                            type="text"
                          />
                        <Label>Created</Label>
                          <Input
                            placeholder="Created"
                            value = {DateTimestr(created)}
                            type="text"
                          />
                          <Label>modified</Label>
                          <Input
                            placeholder="Modified"
                            value = {DateTimestr(modified)}
                            type="text"
                          />
                          <Label>created By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {createdBy}
                            type="email"
                          />
                          <Label>modified By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {modifiedBy}
                            type="email"
                          />
                      </Col>
                    </Row>
                  <Button onClick = {update} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    )

  return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen children={children} loading = {loading} text={"Update Data"}/>
      </>
    );
}


export default IndoorDeviceInventoryView;
