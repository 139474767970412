import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {getRefreshToken} from "variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions"
import CommonList from "../../../components/Table/CommonList"
import { ColumnsUser, ColumnsAdmin, ColumnsTrashAdmin,
ColumnsTrashUser } from "./FinanceOutflowColumns"
import { getUserPermission } from "variables/common";
import { payloadTrash } from "variables/MitraSubmission/SubmissionFinance";
import { verifySuperAdmin } from "Main/Utils";
import FinanceOutflowService from "Main/MitraSubmission/FinanceOutflowService";
import { addFinanceOutflowData } from "variables/MitraSubmission/FinanceOutflow";
import { PriceFormat } from "variables/common";

function FinacneOutflowList(props) {
  const page = 'submission'
  const financeOutflowService =  new FinanceOutflowService()
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role.toLowerCase() === page) || ''
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const refreshToken = getRefreshToken()
  const [viewTrashPage, viewTrashPageOnChange] = useState(false)
  const { mitraSuper } = verifySuperAdmin()
  const Columns = mitraSuper ? ColumnsAdmin : ColumnsUser
  const ColumnsTrash = mitraSuper ? ColumnsTrashAdmin : ColumnsTrashUser

  // try {
  const getAll =  async(trash = false) => {
    try {
      setDataLoad(true)
      const len = 'ALL'
      const dat =  await financeOutflowService.getAllData(len, trash)
      const result = dat.data

      setDataState(mapping(result))
      setDataTable(result)
      setDataLoad(false)
      return result
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  useEffect( () => {
    getAll()
  }, [])

  const deleteData = async (id) => {
    swal("Hapus", "Data Tidak Dapat di Recover", "info", {
      buttons: {
        cancel: 'Batal',
        delete: {text: "Hapus", value: 'del' }
      }
    }
    ).then(async(value) => {
      switch (value) {
        case 'del':
          try {
            setDataLoad(true)
            const result = await financeOutflowService.deleteData(id)
            await swal(result.status, result.message, 'success')
            const state = await getAll()
            const newState = mapping(state)
            // const newState = stateRaw.filter(data => data.id !== id)
            setDataState(newState)
            viewTrashPageOnChange(false)
            setDataLoad(false)
            return true
          } catch (error) {
            return await CatchError(error, props, refreshToken)
          }

        default:
          await getAll()
          return false
      }
    })
  }

  const sendTrash = async (id, trash) => {
    try {
        setDataLoad(true)
        const payload = payloadTrash(id, trash)
        const result = await financeOutflowService.sendToTrash(payload)
        await swal('Success', result.message, 'success')
        if (trash === true) {
            const state = await getAll()
            const stateRaw = mapping(state)
            const newState = stateRaw.filter(data => data.id !== id)
            setDataState(newState)
        } else {
          // window.location.reload()
          await getAll()
          viewTrashPageOnChange(false)
        }
        setDataLoad(false)
        return true
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }
  const sendToTrash = async (id) => {
    swal("Trash", "Send To Trash", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Continue", value: 'del' }
      }
    }
    ).then(async(value) => {
      switch (value) {
        case 'del':
          const trash = true
          await sendTrash(id, trash)
          break
        default:
          await getAll()
          return false
      }
    })
  }

  const recoverTrash = async (id) => {
    swal("Recover", "Recover Data", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Continue", value: 'del' }
      }
    }
    ).then(async(value) => {
      switch (value) {
        case 'del':
          const trash = false
          await sendTrash(id, trash)
          break
        default:
          viewTrashPageOnChange(false)
          await getAll()
          return false
      }
    })
  }

  const addData = () => {
    props.history.push('/admin/finance-outflow-add')
  }

  const viewData = async(id)=> {
    try {
      const dat = await financeOutflowService.getData(id)
      const result = dat.data
      addFinanceOutflowData(result)
      props.history.push('/admin/finance-outflow-view')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const mapping =  (Table) => {
    return Table.map((Data, i) => {
      try {
          // listState.push({id: Data.id, status: Data.enabled})
          return {
            id: Data.id,
            name: Data.name,
            filename: Data.filename,
            owner: Data.owner,
            outflow: PriceFormat(Data.price, 'idr'),
            actionsUser: (
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={ async () => {
                    await viewData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Read}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                </div>
            ),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={ async () => {
                    await viewData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Read}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                <Button
                  onClick={async () => {
                    await sendToTrash(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}1`}
                  disabled = {!Permission.Delete}
                  color="danger"
                  size="sm"
                >
                <i className="fa fa-trash" />
                </Button>{" "}
              </div>
            ),
            recover: (
              <Button
                  onClick={ async () => {
                    await recoverTrash(Data.id)
                  }}
                  className="btn"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Update}
                  color="warning"
                  size="sm"
                >
                 Recover
                </Button>
            ),
            actionsTrash: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={ async () => {
                    await deleteData(Data.id)
                  }}
                  className="btn-icon"
                  id={`tooltip${Data.id}1`}
                  disabled = {!Permission.Delete}
                  color="danger"
                  size="sm"
                >
                <i className="fa fa-times" />
                </Button>{" "}
              </div>
            ),
        };
        } catch (error) {
          console.log(error)
          return []
        }
      })}

  const [dataState, setDataState] = useState(
    mapping(Table)
  );

  const viewTrash = async (trash) => {
    setDataLoad(true)
    const data = await getAll(trash)
    setDataState(mapping(data))
    setDataLoad(false)
  }

  const customMenu = (
    <Row>
      <Col md={10}>
        <div  className="actions-left">
          <Button onClick={addData} color="info"
          disabled = {!Permission.Create}
          >Add</Button>
        </div>
      </Col>
      <Col md={2}>
        <div className="actions-right">
          <FormGroup check>
            <Label check> View Trash
              <Input type="checkbox"
              value = {viewTrashPage}
              checked = {viewTrashPage}
                onChange = {async (e) => {
                  viewTrashPageOnChange(e.target.checked)
                  await viewTrash(e.target.checked)
                }}
              />
              <span className="form-check-sign" />
            </Label>
          </FormGroup>
        </div>
      </Col>
    </Row>
  )

  return (
        <>
        <PanelHeader size="sm" />
          {/* <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Pengeluaran</h2>
              </div>
            }
          /> */}
          <div className="content">
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CommonList
                    key={1}
                    header={!viewTrashPage ? 'List Pengeluaran': 'Trash'}
                    columns={!viewTrashPage ? Columns : ColumnsTrash}
                    dataState={dataState}
                    menu = {customMenu}
                    Add={addData}
                    load={load}
                    getData={getAll}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </>
    );
  // } catch (error) {
  //   console.log(error)
  //     swal('Failed to Load Data').then(
  //     props.history.push('/admin/')
  //   )
  // }
}

export default FinacneOutflowList;
