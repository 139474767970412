export const columns=[
  {
    Header: "Nama",
    accessor: "name",
  },
  {
    Header: "Organisasi",
    accessor: "organization",
  },
  {
    Header: "Update  Delete",
    accessor: "actions",
    sortable: false,
    filterable: false,
  },
]

export const columnsUser=[
  {
    Header: "Nama",
    accessor: "name",
  },
  {
    Header: "Organisasi",
    accessor: "organization",
  },
  {
    Header: "Update",
    accessor: "actionUser",
    sortable: false,
    filterable: false,
  },
]