/*eslint-disable*/
import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {getRefreshToken} from "variables/common";
import { CatchError } from "Main/Exceptions/exceptions";
import CommonList from "../../../components/Table/CommonList";
import { getUserPermission } from "variables/common";
import FetchFinanceStatementData from "Main/FinanceStatement/FetchFinanceStatementData";
import { PriceFormat } from "variables/common";
import FinanceStatementService from "Main/FinanceStatement/FinanceStatementService";
import { Columns } from "./FinanceStatementColumn";
import { addMitraFinanceData } from "variables/FinanceStatement/FinanceVariable";
import { ClassnameByNumberValue } from "Main/Utils";

function FinanceStatementList(props) {
  const page = 'submission'
  const financialStatementService =  new FinanceStatementService()
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role.toLowerCase() === page) || ''
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const refreshToken = getRefreshToken()
  const formatPrc = 'idr'

  try {
  const getAll =  async(trash = false) => {
    try {
      setDataLoad(true)
      const result =  await FetchFinanceStatementData()

      setDataState(mapping(result))
      if (!trash) {
        setDataLoad(false)
        return result
      }
      setDataTable(result)
      setDataLoad(false)
      return result
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  useEffect( () => {
    getAll()
  }, [])

  const viewData = async(mitra)=> {
    try {
      const dat = await financialStatementService.getData({ mitra })
      const result = dat.data
      addMitraFinanceData(result)
      props.history.push('/admin/finance-statement-detail')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const mapping =  (Table) => {
    return Table.map((Data, i) => {
      try {
          // listState.push({id: Data.id, status: Data.enabled})
          return {
            id: Data.id,
            name: Data.name,
            year: Data.year,
            revenue: (<p className={"title " + ClassnameByNumberValue(Data.revenue)}>{PriceFormat(Data.revenue, formatPrc)}</p>),
            expense: (<p className={"title " + ClassnameByNumberValue(Data.expense)}>{PriceFormat(Data.expense, formatPrc)}</p>),
            netIncome: (<p className={"title " + ClassnameByNumberValue(Data.netRevenue)}>{PriceFormat(Data.netRevenue, formatPrc)}</p>),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={ async () => {
                    await viewData(Data.name)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Read}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
              </div>
            )
        };
        } catch (error) {
          console.log(error)
          return []
        }
      })}

  const [dataState, setDataState] = useState(
    mapping(Table)
  );


  return (
        <>
        <PanelHeader size="sm" />
          {/* <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Laporan Keuangan</h2>
              </div>
            }
          /> */}
          <div className="content">
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CommonList
                    key={1}
                    header={'Laporan Keuangan'}
                    columns={Columns}
                    dataState={dataState}
                    load={load}
                    getData={getAll}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </>
    );
  } catch (error) {
    return (
      <>
      <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Laporan Keuangan</h2>
              </div>
            }
          />
      <h3>{error.message || error}</h3>
      </>
    )
  }
}

export default FinanceStatementList;
