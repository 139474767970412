import LoginService from './LoginService'
import APIconsume from './APIconsume'
import { getAccessToken } from "./../variables/common"

class TemplateService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'global-template'
    }
    // Global Template
    async addGlobalTemplate (payload) {
        const result = await this._consume.postData(payload, this._url)
        return result.data
    }

    async getGlobalTemplate (id) {
        const url = `${this._url}/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getGlobalTemplateType () {
        const url = `res/template-type`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getGlobalTemplateCategory (id) {
        const url = `res/template-category/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllGlobalTemplate (len = 'ALL', page = '') {
        let url = `${this._url}/view?line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async putGlobalTemplate (id, payload) {
        const url = `${this._url}/${id}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async deleteGlobalTemplate (id) {
        const url = `${this._url}/${id}`
        const result = await this._consume.deleteData(url)
        return result.data
    }
}

export default TemplateService