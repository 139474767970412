import LoginService from './LoginService'
import APIconsume from './APIconsume'
import { getAccessToken } from "./../variables/common"

class ItemService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'Item'
    }

    async addItem (payload) {
        const result = await this._consume.postData(payload, this._url)
        return result.data
    }

    async importItem (data) {
        const url = 'item/import'
        const type = 'multipart/form-data;'
        const result = await this._consume.postData(data, url, type)
        return result.data
    }

    async addImage (payload, Url) {
        const url = Url //|| this._url + 'pictures/' + id
        const type = 'multipart/form-data;'
        const result = await this._consume.postData(payload, url, type)
        return result.data
    }

    async deleteImage (Url) {
        const url = Url //|| this._url + 'pictures/' + id
        const result = await this._consume.putData({}, url)
        return result.data
    }

    async getItem (name) {
        const url = `${this._url}/${name}`
        // console.log(this._accessToken)
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllItem (len = 'ALL', page = '') {
        let url = `${this._url}/view?line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async putItem (name, payload) {
        const url = `${this._url}/${name}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async deleteItem (name, noRouter) {
        const url = `${this._url}/${name}` //`${this._url}/${name}?noRouter=${!noRouter}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async downloadItem (payload) {
        const { mitra } = payload
        const url = `export/item`
        const filename = 'ItemExport-' + mitra + '-Download_Date-' + new Date().toDateString()
        const result = await this._consume.GlobalDownloadPost(payload, url, filename)
        return result
    }
}

export default ItemService