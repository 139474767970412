import LoginService from '../LoginService'
import APIconsume from '../APIconsume'
import { getAccessToken } from "variables/common"

class HostpotUserHistoryService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'hostpotHistory'
    }
    async get (id) {
        const url = `${this._url}/${id}`

        const result = await this._consume.getData(url)
        return result.data
    }

    async getAll (len = 'ALL', mitra = '', year = '', reseller = '', page = '') {
        let url = `${this._url}?mitra=${mitra}&year=${year}&reseller=${reseller}&line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }
}

export default HostpotUserHistoryService