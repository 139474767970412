import React, { useState} from "react";
import Select from 'react-select'
import UserService from "Main/UserService";
// import NotificationAlert from "react-notification-alert";
import Switch from "react-switch";
import {addUserData, getRefreshToken,
  getUserData, getUserType,
  getRole, putPermission,
  permission, user,
  getRoleList, addRoleList} from "variables/common"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  Label,
  CardTitle,
  FormGroup,
  UncontrolledTooltip,
  Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import PictureUpload from "components/CustomUpload/PictureUpload";
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils"

function UserUpdate (props) {
  const [passwordShown, setPasswordShown] = useState(false);
  const userData = getUserData()

  const userService = new UserService()
  const refreshToken = getRefreshToken()
  const [dataLoad, setDataLoad] = useState(false)
  var userTypeOptions = getUserType() || []
  const [registerConfirmPasswordState, setregisterConfirmPasswordState] = useState('');
  const [registerPassword, setregisterPassword] = useState("");
  const [IsComplete, setIsComplete] = useState(true)
  const [registerPasswordState, setregisterPasswordState] = useState("");
  const [RoleList, RoleListOnChange] = useState(getRoleList())
  const Id = useFormInput(userData.id)
  const imgUrl = userData.imgUrl
  const organization = userData.organization
  const username = useFormInput(userData.username)
  const email = useFormInput(userData.email)
  const firstName = useFormInput(userData.firstName)
  const lastName = useFormInput(userData.lastName)
  const [Password, PasswordOnChange] = useState('')
  var roleOptions = getRole()
  const [role, roleOnChange] = useState('')
  const [Enable, EnableOnChage] = useState(userData.enabled)
  const [userType, userTypeOnChange] = useState(userTypeOptions.find(data => data.value === userData.userType) || {})

  const [create, createOnChange] = useState(false)
  const [read, readOnChange] = useState(false)
  const [update, updatedOnChange] = useState(false)
  const [deleted, deletedOnChange] = useState(false)
  const url = 'user/pictures/' + Id.value
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  // function that verifies if two strings are equal
  const compare = (string1, string2) => {
    if (string1 === string2) {
      return true;
    }
    return false;
  };

  const getAllPermission = async () => {
    try {
      const result = await userService.getAllPermission(Id.value)
      addRoleList(result)
      RoleListOnChange(result)
      setDataLoad(false)
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

 const PermissionList = ()  => {
    const Data = RoleList
    const [Create, CreateOnChange] = useState(Data.Create)
    const [Read, ReadOnChange] = useState(Data.Read)
    const [Update, UpdateOnChange] = useState(Data.Update)
    const [Delete, DeleteOnChange] = useState(Data.Delete)

    try {
      if (!dataLoad) {
        return (
      <>
          <Col md="12">
            <Card>
              <CardHeader>
              <CardTitle tag="h4">User Permission</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="header">
                    <tr>
                      <th>Permission</th>
                      <th>Create</th>
                      <th >Read</th>
                      <th >Update</th>
                      <th >Delete</th>

                    </tr>
                  </thead>
                  <tbody>
                    {Data.map(data => {
                      return (
                        <>
                      <tr key={data.id}>
                      <td>{data.Role}</td>
                      <td><Switch
                          // onText={<i className="now-ui-icons ui-1_check" />}
                          // onColor="green"
                          // offColor="red"
                          // // checked = {status}
                          // offText={
                          // <i className="now-ui-icons ui-1_simple-remove" />
                          // }
                          Value={data.Create}
                          checked = {data.Create}
                          onChange={e => CreateOnChange(e.checked)}
                        />{" "}</td>
                      <td><Switch
                          // onText={<i className="now-ui-icons ui-1_check" />}
                          // onColor="green"
                          // offColor="red"
                          // // checked = {status}
                          // offText={
                          // <i className="now-ui-icons ui-1_simple-remove" />
                          // }
                          Value={data.Read}
                          checked = {data.Read}
                          onChange={e => ReadOnChange(e.checked)}
                        />{" "}</td>
                      <td><Switch
                          // onText={<i className="now-ui-icons ui-1_check" />}
                          // onColor="green"
                          // offColor="red"
                          // // checked = {status}
                          // offText={
                          // <i className="now-ui-icons ui-1_simple-remove" />
                          // }
                          Value={data.Update}
                          checked = {data.Update}
                          onChange={e => UpdateOnChange(e.checked)}
                        />{" "}</td>
                      <td><Switch
                          // onText={<i className="now-ui-icons ui-1_check" />}
                          // onColor="green"
                          // offColor="red"
                          // // checked = {status}
                          // offText={
                          // <i className="now-ui-icons ui-1_simple-remove" />
                          // }
                          Value={data.Delete}
                          checked = {data.Delete}
                          onChange={e => DeleteOnChange(e.checked)}
                        />{" "}</td>
                      <td className="text-right btns-mr-5">
                      <Button
                          className="btn-icon"
                          color="success"
                          id="tooltip26024663"
                          size="sm"
                          type="button"
                          disabled = {true}
                          onClick={() => {editPermission(data.id, Create, Read, Update, Delete)}}
                      >
                          <i className="now-ui-icons ui-2_settings-90" />
                      </Button>
                      <UncontrolledTooltip
                          delay={0}
                          target="tooltip26024663"
                      >Update</UncontrolledTooltip>
                      <Button
                          className="btn-icon"
                          color="danger"
                          id="tooltip930083782"
                          size="sm"
                          type="button"
                          disabled = {true}
                          onClick={() => {deletePermission(data.id)}}
                      >
                      <i className="now-ui-icons ui-1_simple-remove" />
                      </Button>
                      <UncontrolledTooltip
                          delay={0}
                          text = "Delete"
                          target="tooltip930083782"
                      >Delete</UncontrolledTooltip>
                      </td>
                    </tr>
                    </>
                      )
                    }
                    )}

                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
      </>
    )
      } else {
        return (
          <Col md="8">
            <Card>
              <CardHeader>
              <CardTitle tag="h4">User Permission</CardTitle>
              </CardHeader>
              <CardBody>
              <p className="typhograpy-line">Loading...</p>
              </CardBody>
              </Card>
          </Col>
      )
      }
    } catch (error) {
      return (
          <Col md="8">
            <Card>
              <CardHeader>
              <CardTitle tag="h4">User Permission</CardTitle>
              </CardHeader>
              <CardBody>
              <p className="typhograpy-line">No Data</p>
              </CardBody>
              </Card>
          </Col>
      )
    }
  }
    const editPermission = async(id, Create, Read, Update, Deleted) => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = putPermission(Create, Read, Update, Deleted)
      await userService.putPermission(id, payload)
      swal("Success", "Add User Permission Success", "success")
      getAllPermission()
    } catch (error) {
      return CatchError(error, props, refreshToken)
    }}

    const deletePermission = (name) => {
    console.log(name)
    swal("Delete", "Data Tidak Dapat di Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then( async (value) => {
      switch (value) {
        case 'del':
          try {
            await userService.deletePermission(name)
            swal('Success', 'User Permission Berhasil di Hapus', 'success').then(() => {
              sessionStorage.removeItem('roleList')
              setDataLoad(true)
              getAllPermission()
            })
            return true
          } catch (error) {
            return CatchError(error, props, refreshToken)
          }

        default:
          // getUser()
          // window.location.reload()
          setDataLoad(true)
          return false
      }
    })
  }

  const addPermission = async() => {
    try {
      const payload = permission( userData.username, role.value, create, read, update, deleted)
      const result = await userService.addPermission(payload)
      setDataLoad(true)
      swal("Success", result.message, result.status)
      getAllPermission()
    } catch (error) {
      return CatchError(error, props, refreshToken)
  }}
  const PermissionData = () => {
    return (
      <>
      <Col md="12">
          <Card className="card-user">
            <CardHeader>
              <CardTitle tag="h5" >Add New Permission</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  <Row>
                    <Label sm="2">Permission Role</Label>
                    <Col sm="8">
                      <FormGroup>
                        <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Type"
                            name="singleSelect"
                            value = {role}
                            options = {roleOptions}
                            onChange={e => roleOnChange(e)
                          }
                          />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Permission</Label>
                    <Col className="checkbox-radios" sm="10">
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" value={create} onChange={e => createOnChange(e.target.checked)}/>
                          <span className="form-check-sign" />
                          Create
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" value={read} onChange={e => readOnChange(e.target.checked)}/>
                          <span className="form-check-sign" />
                          Read
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" value={update} onChange={e => updatedOnChange(e.target.checked)}/>
                          <span className="form-check-sign" />
                          Update
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" value={deleted} onChange={e => deletedOnChange(e.target.checked)}/>
                          <span className="form-check-sign" />
                          Delete
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button onClick = {addPermission} disabled = {true} color="info">Apply</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
      </>
    )
  }
  const EditUser = async() => {
    swal("Update", "Item Data Can't be Undo", "info", {
                buttons: {
                update: {text: "Update", value: 'up' },
                cancel: 'Cancel'
              }
            }
      ).then(async(value) => {
        switch (value) {
          case 'up':
            try {
            const payload = user(username.value, email.value,Password, firstName.value, lastName.value,
                    Enable, userType.value, organization)
              const result = await userService.putUser(Id.value, payload)
              const refresh = await userService.getUser(Id.value)
              addUserData(refresh)
              swal('Success', result.message, result.status).then(()=> {
                  window.location.reload()
                }
              )
            } catch (error) {
              return CatchError(error, props, refreshToken)
          }
          break;

          default:
            swal('Cancel', 'Update Cancel', 'info')
            break;
        }
      })}

  const togglePassword = () => {
      // When the handler is invoked
      // inverse the boolean state of passwordShown
      setPasswordShown(!passwordShown);
  };

  return (
    <>
    {/* <NotificationAlert ref={notificationAlert} /> */}
      <PanelHeader size="sm"/>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Ubah Profile User</h5>
              </CardHeader>
              <CardBody>
                <Form>
                <FormGroup>
                    <PictureUpload avatar link = {url} image = {imgUrl} />
                </FormGroup>
                <FormGroup>
                      <label>Username</label>
                      <Input
                        {...username}
                        placeholder="Username"
                        type="text"
                      />
                  </FormGroup>
                  <FormGroup>
                      <label>Email</label>
                      <Input
                        {...email}
                        placeholder="Email"
                        type="text"
                      />
                  </FormGroup>
                  <FormGroup className={`has-label ${registerPasswordState}`}>
                    <label>Password *</label>
                    <Input
                      id="registerPassword"
                      name="password"
                      type={passwordShown ? "text" : "password"}
                      placeholder = "Password"
                      autoComplete="off"
                      onChange={(e) => {
                        if (!verifyLength(e.target.value, 1)) {
                          setregisterPasswordState("has-danger");
                        } else {
                          setregisterPasswordState("has-success");
                        }
                        setregisterPassword(e.target.value);
                      }}
                    />
                    {registerPasswordState === "has-danger" ? (
                      <label className="error">Field ini wajib di isi.</label>
                    ) : null}
                  </FormGroup>
                  <FormGroup
                    className={`has-label ${registerConfirmPasswordState}`}
                  >
                    <label>Konfirmasi Password *</label>
                    <Input
                      equalto="#registerPassword"
                      id="registerPasswordConfirmation"
                      name="password_confirmation"
                      type={passwordShown ? "text" : "password"}
                      placeholder = "Konfirmasi Password"
                      autoComplete="off"
                      value = {Password}
                      onChange={(e) => {
                        if (!compare(e.target.value, registerPassword)) {
                          setregisterConfirmPasswordState("has-danger");
                          setIsComplete(true)
                          setregisterPasswordState("has-danger");
                        } else {
                          setregisterConfirmPasswordState("has-success");
                          setIsComplete(false)
                          setregisterPasswordState("has-success");
                        }
                        PasswordOnChange(e.target.value);
                      }}
                    />
                    {registerConfirmPasswordState === "has-danger" ? (
                      <label className="error">Field ini wajib di isi.</label>
                    ) : null}
                      <FormGroup check>
                          <Label check> Show
                            <Input type="checkbox"
                            value={passwordShown}
                            onChange={togglePassword}
                            />
                            <span className="form-check-sign" />
                          </Label>
                      </FormGroup>
                  </FormGroup>
                      <FormGroup>
                        <label>Nama Depan</label>
                        <Input
                          {...firstName}
                          placeholder="Nama Depan"
                          type="text"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>Nama Belakang</label>
                        <Input
                          {...lastName}
                          placeholder="Nama Belakang"
                          type="text"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>Nama Lengkap</label>
                        <Input
                          value = {`${firstName.value} ${lastName.value}`}
                          placeholder="Nama Lengkap"
                          type="text"
                        />
                      </FormGroup>
                  <Row>
                    <Col md="5">
                      <FormGroup>
                        <label>API key</label>
                        <Input
                          value={userData.apiKey}
                          placeholder="API Key"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <FormGroup>
                        <label>API Secret</label>
                        <Input
                          value={userData.apiSecret}
                          placeholder="API Secret"
                          type="password"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                      <FormGroup>
                        <label>DocType</label>
                        <Input
                          value={userData.docType}
                          placeholder="Doctype"
                          type="text"
                        />
                      </FormGroup>
                      <label>Jenis User</label>
                        <Select
                            className="primary"
                            // classNamePrefix="react-select"
                            placeholder="Jenis User"
                            name="singleSelect"
                            isDisabled = {userType.value !== 'SUPERADMIN'}
                            value = {userType}
                            options = {userTypeOptions}
                            onChange={e => userTypeOnChange(e)
                          }
                        />
                      <FormGroup>
                        <label>Organisasi</label>
                        <Input
                          value={organization}
                          placeholder="Organisasi"
                          type="text"
                        />
                        <Row>
                      <Col className="pr-4" md="5">
                      <FormGroup>
                          <Row>
                            <Col><label>Enable</label></Col>
                              <Col>
                                <Input
                                  placeholder="Frozen"
                                  value = {Enable}
                                  checked = {Enable}
                                  onChange = {e => EnableOnChage(e.target.checked)}
                                  type="checkbox"
                                />
                              </Col>
                          </Row>
                      </FormGroup>
                      </Col>
                    </Row>
                      </FormGroup>
                    <Button onClick = {EditUser} disabled = {IsComplete} color="info">Update</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
          {userData.userType === 'SUPERADMIN' ? PermissionData() : <p></p>}
          {PermissionList()}
        </Row>
      </div>
    </>
  );
}

export default UserUpdate;
