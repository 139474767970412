import React from "react"
import { TabPanel, Tabs, Tab, TabList } from "react-tabs";
import { getPaymentStatus, GetBillingStatus } from "variables/common";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  CardTitle,
  Table,
  Collapse
} from "reactstrap";

function CustomerSubscriptionList ({CustomerSubscription, HistorySubscription, viewSubscription, viewHistory, exportInvoice}) {
  const statusList = GetBillingStatus()
  const paymentStatusList = getPaymentStatus()

  const SubscriptionTabs = (
  <>
    <TabPanel>
      <Card>
            <CardHeader>
              <CardTitle tag="h4">Subscription</CardTitle>
            </CardHeader>
            <Collapse isOpen = {true}>
            <CardBody>
              <Table  responsive bordered hover>
                <thead className="header">
                  <tr>
                    <th colSpan={1} className="text-center">#</th>
                    <th colSpan={1} >NetId</th>
                    <th colSpan={4} >Nama Produk</th>
                    <th colSpan={2} >Periode</th>
                    <th colSpan={1} className="text-center">Tahun</th>
                    <th colSpan={1} className="text-center">Uplink</th>
                    <th colSpan={1} className="text-center">Downlink</th>
                    <th colSpan={1} className="text-left">Status</th>
                  </tr>
                </thead>
                <tbody className="overflow-auto">
                  {CustomerSubscription.map((subs) => {
                    return (
                    <tr key={subs.id}>
                    <td colSpan={1} className="text-center">
                      <Button className="small danger" color="info"
                        onClick={ async() => {
                          let id = subs.networkId
                          await viewSubscription(id)
                        }}
                      >View</Button>
                    </td>
                    <td colSpan={1}>{subs.networkId}</td>
                    <td colSpan={4}>{subs.packet}</td>
                    <td colSpan={2}>{subs.periodInvoice}</td>
                    <td colSpan={1} className="text-center">{`${subs.year}`}</td>
                    <td colSpan={1} className="text-center">{`${subs.uplink} ${subs.measurement}`}</td>
                    <td colSpan={1} className="text-center">{`${subs.downlink} ${subs.measurement}`}</td>
                    <td colSpan={1} className="text-left">{statusList.find((data) => data.value === subs.status).label}</td>
                  </tr>)
                  })}
                </tbody>
              </Table>
              <Button onClick={exportInvoice} color="info">Export</Button>
            </CardBody>
            </Collapse>
        </Card>
    </TabPanel>
  </>)

  const SubscriptionHistoryTab = (
    <>
      <TabPanel>
        <Card>
              <CardHeader>
                <CardTitle tag="h4">Riwayat Subscription</CardTitle>
              </CardHeader>
              <Collapse isOpen = {true}>
              <CardBody>
                <Table  responsive bordered hover>
                  <thead className="header">
                    <tr>
                      <th colSpan={1} className="text-center">#</th>
                      <th colSpan={1} >NetId</th>
                      <th colSpan={4} >Nama Produk</th>
                      <th colSpan={2} >Periode</th>
                      <th colSpan={1} className="text-center">Tahun</th>
                      <th colSpan={1} className="text-center">Uplink</th>
                      <th colSpan={1} className="text-center">Downlink</th>
                      <th colSpan={1} className="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody className="overflow-auto">
                      {HistorySubscription.map((subs) => {
                      return (
                      <tr key={subs.id}>
                      <td colSpan={1} className="text-center">
                        <Button className="small danger" color="info"
                        onClick={ async() => {
                          await viewHistory(subs.id)
                        }}
                        >View</Button>
                      </td>
                      <td colSpan={1}>{subs.networkId}</td>
                      <td colSpan={4}>{subs.packet}</td>
                      <td colSpan={2}>{subs.periodInvoice}</td>
                      <td colSpan={1} className="text-center">{`${subs.year}`}</td>
                      <td colSpan={1} className="text-center">{`${subs.uplink} ${subs.measurement}`}</td>
                      <td colSpan={1} className="text-center">{`${subs.downlink} ${subs.measurement}`}</td>
                      <td colSpan={1} className="text-left">{paymentStatusList.find((data) => data.value === subs.paid).label}</td>
                      {/* <td colSpan={3} className="text-left">{PriceFormat(subs.price, subs.currency)}</td> */}
                    </tr>)
                    })}
                  </tbody>
                </Table>
              </CardBody>
              </Collapse>
            </Card>
      </TabPanel>
    </>
  )

  return (
        <>
        <Card>
          <Col md="12">
            <Tabs>
              <TabList>
                <Tab>Subscription</Tab>
                <Tab>Riwayat Subscription</Tab>
              </TabList>
              {SubscriptionTabs}
              {SubscriptionHistoryTab}
            </Tabs>
          </Col>
        </Card>
        </>
      )
}

export default CustomerSubscriptionList