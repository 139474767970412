import { Tab, TabPanel } from 'react-tabs'
import CommonList from "components/Table/CommonList"

export const NetInventoryTabs = (data, index) => {
  return (
    <>
      <Tab
        key={index}
        id={String(index)}>{data.title}</Tab>
    </>
    )
}

export function IndividualTabs(dataTable, index, columns, isLoading) {
    try {
        const { title, data } = dataTable
        const key = (Math.random() + index).toString(36).substring(7)
        return (
            <TabPanel key={index} id={String(index)}>
                <CommonList
                    key={key}
                    header={title}
                    columns={columns}
                    dataState={data}
                    menu = {(<p></p>)}
                    load={isLoading}
                />
            </TabPanel>
        )
    } catch (error) {
        return (
            <TabPanel
                key={index}
                id={String(index)}
                >
                <h2>{error.message}</h2>
            </TabPanel>
        )
    }
}