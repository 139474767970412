import React, { useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
// import NotificationAlert from "react-notification-alert";
import CustomerService from "Main/CustomerService";
import Select from "react-select"
import { useState } from "react";
import {addCustomerData, getCustomerData, getRefreshToken,GetItemCategory,
        getCustomerGroup, getStatusOptions, customerPut, addSubscriptionCustomer,
        addSubscriptionHistory, getSubscriptionHistory, getSubscriptionCustomer,
        addSubscriptionHistoryData, ExportsCSV, addVirtualAccountList,
        addBillingData, /* getUserSelect,*/ getLogisticGoodsCustomerList,
        addLogisticGoodsCustomerData, addLogisticGoodsCustomerList,
        PriceFormat, getUserPermission, addCustomerList} from "./../../../variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions"
// import { validatePermission } from "Main/Utils";
import BillingService from "Main/BillingService";
import { DateTimestr } from "Main/Utils";
import VirtualAccountList from "../VirtualAccount/virtualAccountList";
import BankAccountService from "Main/BankAccountService";
import CustomerSubscriptionList from "./CustomerSubscriptionList";
import CommonList from "../../../components/Table/CommonList"
import { nullCompensation } from "Main/Utils";
import { customTextFromBooleanReverse } from "Main/Utils";
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService";
import { customTextFromBoolean } from "Main/Utils";
import { verifySuperAdmin } from "Main/Utils";
import Checklist from "components/Button/Checklist";
import { useFormInput } from "Main/Utils";
import BasicInput from "components/Input/BasicInput";
import InputCoordinate from "components/Input/InputCoordinate";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

function CustomerView(props) {
  const page = 'customer'
  const permission = getUserPermission()
  const customerPermission = permission.find(data => data.Role === page)
  const customerService =  new CustomerService()
  const bankAccountService = new BankAccountService()
  const billingService = new BillingService()
  const refreshToken = getRefreshToken()
  const customerData = getCustomerData()
  var selectCustomerGroupOptions = getCustomerGroup()
  var statusOptions = getStatusOptions()
  var selectCategoryOptions = GetItemCategory()
  // var userSelectOption = getUserSelect()
  const id = customerData.id
  const email = useFormInput(customerData.email)
  const name = useFormInput(customerData.name)
  const namingSeries = useFormInput(customerData.namingSeries)
  const firstname = useFormInput(customerData.firstname)
  const lastname = useFormInput(customerData.lastname)
  const [ktpNumber, ktpNumberOnChange] = useState(customerData.ktp)
  const [MobileNo, mobileNoOnChange] = useState(customerData.mobileNo)
  const province  = customerData.teritory
  const [status, statusOnChange] = useState(statusOptions.find(data =>(data.status === customerData.status)))
  const [minLengthState, setminLengthState] = React.useState("");
  const [minLengthPhoneState, setminLengthPhoneState] = React.useState("");
  const [CustomerSubscription, CustomerSubscriptionOnChange] = useState(getSubscriptionCustomer() || [])
  const [HistorySubscription, HistorySubscriptionOnChange] = useState(getSubscriptionHistory() || [])
  const [customerGroup, customerGroupOnChange] = useState(selectCustomerGroupOptions.find(val => val.name = customerData.customerGroup))
  // const [salesTeam, salesTeamOnChange] = useState(userSelectOption.find(usr => usr.value === customerData.salesTeam) || '')
  const [salesTeam, salesTeamOnChange] = useState(customerData.salesTeam || '')
  const [description, setDescription] = useState(customerData.description)
  const created = customerData.creation
  const modified = customerData.modified
  const createdBy = useFormInput(customerData.createdBy)
  const modifiedBy = useFormInput(customerData.modifiedBy)
  const docType = useFormInput(customerData.docType)
  const idx = useFormInput(customerData.idx)
  const owner = customerData.ownerName
  const [activate, activateOnChage] = useState(customerData.enabled)
  const [ppn, ppnOnChage] = useState(customerData.ppn)
  const [category, categoryOnChange] = useState(selectCategoryOptions.find((categoryOption) => categoryOption.value === customerData.category))
  // const [colapse, setColapseState] = useState(false)
  const VAList = VirtualAccountList(props, id)
  const logisticService = new LogisticGoodsService()
  const [loadCustomerGoods, setCustomerGoodsDataLoad] = useState(true)
  const [customerGoodsDataTable, setDataTableCustomer] = useState(getLogisticGoodsCustomerList())
  const { userSuper, mitraSuper } = verifySuperAdmin()
  const filename = 'Import-Customer-' + customerData.name + '-' + owner
  const { activation } = customerData
  const [discount, setDIscount] = useState(customerData.discount)
  const { latitude, longitude } = customerData


  const ViewLogisticGoodsCustomerData = async (id, transfer) => {
    try {
      const result = await logisticService.getLogisticCustomer(id)
      addLogisticGoodsCustomerData(result.data)
      if (!transfer) {
        props.history.push('/admin/logistic-goods-customer-update')
        return
      }
      props.history.push('/admin/logistic-customer-to-warehouse')
    } catch (error) {
      console.log(error.message)
      // return await CatchError(error, props, refreshToken)
    }
  }
    const getAllLogisticGoodsCustomerData =  async() => {
      try {
        setCustomerGoodsDataLoad(true)
        const len = 'ALL'
        const dat =  await logisticService.getAllLogisticCustomer({customerid: id, len})
        const result = dat.data
        if (!result) {
          setCustomerGoodsDataLoad(true)
        } else{
          setDataTableCustomer(result)
          addLogisticGoodsCustomerList(result)
          setCustomerGoodsDataLoad(false)
        }
        return result
      } catch (error) {
        console.log(error.message)
        // return CatchError(error, props, refreshToken)
      }
    }
 const DeleteLogisticGoodsCustomerData = (id) => {
      swal("Delete", "Data Can't Recover", "info", {
        buttons: {
          cancel: 'Cancel',
          delete: {text: "Delete", value: 'del' }
        }
      }
      ).then(async (value) => {
        switch (value) {
          case 'del':
            try {
              const response = await logisticService.deleteLogisticCustomer(id)
              swal(response.status, response.message, 'success').then(() => {
                getAllLogisticGoodsCustomerData()
                setCustomerGoodsDataLoad(true)
                const table = customerGoodsDataState.filter((dat) => dat.id !== id)
                setcustomerGoodsDataState(table)
              })
              return true
            } catch (error) {
              return await CatchError(error, props, refreshToken)
            }

          default:
            getAllLogisticGoodsCustomerData()
            // window.location.reload()
            setCustomerGoodsDataLoad(true)
            return false
        }
      })
    }
  const mappingCustomerGoods = (TableCustomerData) => TableCustomerData.map((Data) => {
    try {
          return {
            id: Data.id,
            name: Data.name,
            qty: Data.qty,
            paid: customTextFromBoolean(Data.paid, 'Paid', 'Unpaid'),
            sn: nullCompensation(Data.sn),
            price: PriceFormat((Number(Data.price) * Number(Data.qty)), 'idr'),
            damaged: customTextFromBooleanReverse(Data.damaged, 'Good', 'Damaged'),
            location: Data.customerName,
            organization: Data.owner,
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>

                {/* use this button to add a edit kind of action */}
                <Button
                  onClick={ async () => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    await ViewLogisticGoodsCustomerData(Data.id, true)
                  }}
                  className="btn-icon btn-round"
                  color="primary"
                  id={`tooltip${Data.id}10`}
                  size="sm"
                >TF</Button>{" "}
                <Button
                  onClick={ async () => {
                    // let obj = dataState.find((o) => o.id === Data.id);
                    await ViewLogisticGoodsCustomerData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  color="warning"
                  id={`tooltip${Data.id}10`}
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
                <Button
                  onClick={ async () => {
                    await DeleteLogisticGoodsCustomerData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}11`}
                  color="danger"
                  size="sm"
                >
                  <i className="fa fa-times" />
                </Button>{" "}
              </div>
            ),
          };
          } catch (error) {
            return []
          }
        })
    const [customerGoodsDataState, setcustomerGoodsDataState] = React.useState(
      mappingCustomerGoods(customerGoodsDataTable)
    )

  useEffect(() => {
    getSubs()
    getAllLogisticGoodsCustomerData()
    // validatePermission(props, 0, 1)
  }, [])

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const [ktpPassed, ktpPassedOnChange] = useState(!verifyLength(ktpNumber, 16))
  const [mobileNoPassed, phonePassedOnChnage] = useState(!verifyLength(MobileNo, 12))

  const getCustomer =  async() => {
    try {
      const result =  await customerService.getCustomer(id)

      addCustomerData(result)
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const getCustomerList =  async() => {
    try {
      const result =  await customerService.getAllCustomer('ALL')
      addCustomerList(result.data)
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const exportInvoiceCSV = async() => {
    try {
      const payload = ExportsCSV([id])
      const rawData = await customerService.exportCSV(payload, filename)
      return rawData
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const exportInvoiceExcel = async() => {
    try {
      const payload = ExportsCSV([id])
      const rawData = await customerService.exportExcel(payload, filename)
      return rawData
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const viewSubscription = async(id)=> {
      try {
        const dat = await billingService.getBilling(id)
        const result = dat.data
        addBillingData(result)
        props.history.push('/admin/billing-update')
      } catch (error) {
        console.log(error.message)
        // return await CatchError(error, props, refreshToken)
      }
  }
  const getSubs = async() => {
      const subsHistory = await billingService.getAllSubscriptionHistory(id)
      const subsCustomer = await billingService.getAllSubscriptionCustomer(id)
      const vaData =  await await bankAccountService.getAllVirtualAccount(id)
      const vaResult = vaData.data
      addVirtualAccountList(vaResult)
      addSubscriptionCustomer(subsCustomer.data)
      addSubscriptionHistory(subsHistory.data)
      CustomerSubscriptionOnChange(subsCustomer.data)
      HistorySubscriptionOnChange(subsHistory.data)
  }

    const exportInvoice = async() => {
      swal("Invoice Export", "pilih tipe data", {
                buttons: {
                csv: {text: "CSV", value: 'csv' },
                excel: {text: "Excel", value: 'excl' },
                cancel: 'Cancel'
              }
            }
      ).then(async(value) => {
        switch (value) {
          case 'csv':
            try {
              await exportInvoiceCSV()
            } catch (error) {
              return await CatchError(error, props, refreshToken)
            }
            break;
            case 'excl':
              try {
                await exportInvoiceExcel()
              } catch (error) {
                return await CatchError(error, props, refreshToken)
              }
            break;

            default:
            break;
        }
    })
  }
  const viewHistory = async(id)=> {
    try {
      const dat = await billingService.getSubscriptionHistory(id)
      const result = dat.data
      addSubscriptionHistoryData(result)
      props.history.push('/admin/Subscription-history')
    } catch (error) {
      console.log(error.message)
      // return await CatchError(error, props, refreshToken)
    }
  }


  const putCustomer = async() => {
    swal("Update", "Customer Data Can't be Undo", "info", {
            buttons: {
            update: {text: "Update", value: 'up' },
            cancel: 'Cancel'
          }
        }
    ).then(async(value) => {
      switch (value) {
            case 'up':
              try {
                const payload = customerPut(name.value, firstname.value,
                  lastname.value, namingSeries.value, email.value, ktpNumber,
                  MobileNo, customerGroup.value, province, status.value, activate,
                  salesTeam || '', category.value, ppn, description, discount)

                await customerService.putCustomer(id, payload)
                await swal('Success', 'Update Customer Data Success', 'success')
                await getCustomerList()
                props.history.push('/admin/customer-all')
              } catch (error) {
                return await CatchError(error, props, refreshToken)
              }
              break;

            default:
              break;
          }
        })
    }

    const customerPanel = (
      <>
       <Card>
          <CardHeader>
            <h5 className="title">{name.value}</h5>
          </CardHeader>
          <CardBody>
            <Form>
              <Row>
                <Col className="pr-1" md="12">
                  <InputCoordinate
                    key = {'edwed'}
                    name = {name}
                    latitude = {latitude}
                    longitude = {longitude}
                    disabledLat = {true}
                    disabledLog = {true}
                    disabledGeo = {true}
                  />
                  <FormGroup>
                  <Label>Aktivasi</Label>
                  <Input
                    placeholder="Customer Activation"
                    type="text"
                    value={DateTimestr(activation)}
                  />
                  <Label>Nama</Label>
                    <Input
                      {...name}
                      placeholder="Nama"
                      type="text"
                    />
                  <Label>Mitra</Label>
                    <Input
                      placeholder="Mitra"
                      value = {owner}
                      type="text"
                    />
                    <Label>Nama Depan</Label>
                    <Input
                      placeholder="Nama Depan"
                      {...firstname}
                      type="text"
                    />
                    <Label>Nama Belakang</Label>
                    <Input
                      placeholder="Nama Belakang"
                      {...lastname}
                      type="text"
                    />
                    <Label>Nama Lengkap</Label>
                    <Input
                      placeholder="Nama Lengkap"
                      value={`${firstname.value} ${lastname.value}`}
                      type="text"
                    />
                  <Label>Naming Series</Label>
                    <Input
                      placeholder="Naming Series"
                      value = {namingSeries.value}
                      type="text"
                    />
                    <div>
                    <Label>Email</Label>
                    <Input
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder="Email"
                      name="email"
                      type="email"
                      {...email}
                    />
                  <Label>KTP</Label>
                  <Input
                    name="min_length_ktp"
                    type="number"
                    value = {ktpNumber}
                    onChange={(e) => {
                      if (!verifyLength(e.target.value, 17)) {
                        ktpNumberOnChange(e.target.value)
                      if (!verifyLength(e.target.value, 16)) {
                        setminLengthState("has-danger");
                        ktpPassedOnChange(true)
                      } else {
                        setminLengthState("has-success");
                        ktpPassedOnChange(false)
                      }
                      }
                    }}
                  />
                  {minLengthState === "has-danger" ? (
                    <label className="error">
                      Nomor KTP Harus 16 Karakter.
                    </label>
                  ) : null}
                 </div>
                 <div>
                    <Label>Phone Number</Label>
                  <Input
                    name="min_length_phone"
                    type="number"
                    value={MobileNo}
                    onChange={(e) => {
                      if (!verifyLength(e.target.value, 14)) {
                        mobileNoOnChange(e.target.value)
                      if (!verifyLength(e.target.value, 11)) {
                        setminLengthPhoneState("has-danger");
                        phonePassedOnChnage(true)
                      } else {
                        setminLengthPhoneState("has-success");
                        phonePassedOnChnage(false)
                      }
                      }
                    }}
                  />
                  {minLengthPhoneState === "has-danger" ? (
                    <label className="error">
                       Masukkan Minimal 8 Karakter dan Maksimal 13 Karakter.
                    </label>
                  ) : null}
                 </div>
                 <div>
                 <Label>Kategori</Label>
                    <Select
                      key={'93euwdwe'}
                      // className="react-select"
                      // classNamePrefix="react-select"
                      placeholder="Kategori"
                      name="singleSelect"
                      isDisabled = {!userSuper}
                      value = {category}
                      options={selectCategoryOptions}
                      onChange={(value) => {
                        categoryOnChange(value)
                      }}
                    />
                      <Label>Auto Suspend hanya bekerja untuk Broadband</Label>
                    </div>
                    <Label>Created</Label>
                    <Input
                      placeholder="Created"
                      value = {DateTimestr(created)}
                      type="text"
                    />
                    <Label>modified</Label>
                    <Input
                      placeholder="Modified"
                      value = {DateTimestr(modified)}
                      type="text"
                    />
                    <Label>created By</Label>
                    <Input
                      placeholder="Modified By"
                      value = {createdBy.value}
                      type="email"
                    />
                    <Label>modified By</Label>
                    <Input
                      placeholder="Modified By"
                      value = {modifiedBy.value}
                      type="email"
                    />
                    <Label>Index Customer</Label>
                    <Input
                      placeholder="Index Customer"
                      value = {idx.value}
                      type="text"
                    />
                    <Label>Group Customer*</Label>
                    <Select
                      key = {'23e23ss'}
                      // className="react-select"
                      // classNamePrefix="react-select"
                      placeholder="Group Customer"
                      name="singleSelect"
                      value = {customerGroup}
                      options={selectCustomerGroupOptions}
                    onChange={(value) => customerGroupOnChange(value)}
                    />
                    <Label>Lokasi *</Label>
                    <Input
                      placeholder="Lokasi"
                      name="singleSelect"
                      value = {province}
                    />
                    <Label>Status</Label>
                      <Select
                        key = {'wedwed12'}
                        // className="react-select"
                        // classNamePrefix="react-select"
                        placeholder="Status"
                        value = {status}
                        isDisabled = {!userSuper}
                        options = {statusOptions}
                        onChange = {(value) => statusOnChange(value)}
                        type="text"
                    />
                    <Label>Doctype</Label>
                    <Input
                      placeholder="Doctype"
                      {...docType}
                      type="text"
                    />
                    <Label>Sales Team</Label>
                    {/* <Select
                        // className="react-select"
                        // classNamePrefix="react-select"
                        placeholder="Sales Team"
                        value = {salesTeam}
                        options = {userSelectOption}
                        onChange = {(value) => salesTeamOnChange(value)}
                        type="text"
                      /> */}
                    <Input
                        placeholder="Sales Team"
                        value = {salesTeam}
                        disabled
                        onChange = {(value) => salesTeamOnChange(value)}
                        type="text"
                    />
                    <Label>Deskripsi</Label>
                    <Input
                      placeholder="Deskripsi"
                      value = {description}
                      onChange = {(value) => setDescription(value.target.value)}
                      type="textarea"
                    />
                    <BasicInput
                        key = {'wd23d2323'}
                        label = 'Diskon Customer (IDR)'
                        value = {discount}
                        onChange = {setDIscount}
                      />
                      <Input
                        placeholder="Diskon Customer (IDR)"
                        disabled
                        value={PriceFormat(discount, 'IDR')}
                        type="text"
                      />
                  </FormGroup>
                  </Col>
              </Row>
            <Row>
          </Row>
          <Checklist
            key = {'dwdwesd'}
            text = {"Customer Aktif"}
            disabled = {!mitraSuper}
            value = {activate}
            checked = {activate}
            onChange = {(e) => activateOnChage(e.target.checked)}
          />
          <Checklist
            key = {'9qded239h'}
            text = {"Pajak PPN"}
            disabled = {!mitraSuper}
            value = {ppn}
            checked = {ppn}
            onChange = {(e) => ppnOnChage(e.target.checked)}
          />
          </Form>
          <Button onClick = {putCustomer}
            disabled = {!customerPermission.Update && (mobileNoPassed && ktpPassed)}
            color="info">Edit</Button>
          </CardBody>
        </Card>
      </>
    )
    return (
      <>
      {/* <NotificationAlert ref={notificationAlert} /> */}
        <PanelHeader size = 'sm'/>
        <div className="content" onLoadStart ={getCustomer}>
          <Row>
            <Col md="12">
              <CustomerSubscriptionList
                key = {'e823h2ud'}
                CustomerSubscription = {CustomerSubscription}
                HistorySubscription = {HistorySubscription}
                viewSubscription = {viewSubscription}
                viewHistory = {viewHistory}
                exportInvoice={exportInvoice}/>
              <Card>
                <Tabs>
                  <TabList>
                    <Tab>Customer</Tab>
                    <Tab>Virtual Account</Tab>
                    <Tab>Logistik Barang Customer</Tab>
                  </TabList>
                  <TabPanel>
                    {customerPanel}
                  </TabPanel>
                  <TabPanel>
                    {VAList}
                  </TabPanel>
                  <TabPanel>
                    <CommonList
                      key = {'dwdwed3221'}
                      load={loadCustomerGoods}
                      dataState={customerGoodsDataState}
                      getData={getAllLogisticGoodsCustomerData}
                      header={'Logistik Barang Customer'}
                    />
                  </TabPanel>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}

export default CustomerView;
