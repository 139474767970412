import LoginService from '../LoginService'
import { MapToParamQuery } from 'Main/Utils'
import APIconsume from './../APIconsume'
import { getAccessToken } from "./../../variables/common"

class LogisticGoodsService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'LogisticGoods'
        this._url_customer = 'LogisticGoodsCustomer'
        this._warehouseTowarehouse = 'TransferFromWarehouseToWarehouse'
        this._warehouseTocustomer = 'TransferFromWarehouseToCustomer'
        this._customerTowarehouse = 'TransferFromCustomerToWarehouse'
        this._url_logistic = 'LogisticGoods/mutation'
    }

    async post (payload) {
        const result = await this._consume.postData(payload, this._url)
        return result.data
    }

    async postBlob (payload) {
        const result = await this._consume.postData(payload, this._url + 'Blob')
        return result.data
    }

    async changePaidStatus (id, paid) {
        const payload = {id, paid}
        const result = await this._consume.postData(payload, `${this._url}Status`)
        return result.data
    }

    async transferWarehouseToWarehouse (payload) {
        const result = await this._consume.postData(payload, this._warehouseTowarehouse)
        return result.data
    }

    async transferWarehouseToCustomer (payload) {
        const result = await this._consume.postData(payload, this._warehouseTocustomer)
        return result.data
    }

    async transferCustomerToWarehouse (payload) {
        const result = await this._consume.postData(payload, this._customerTowarehouse)
        return result.data
    }

    async transferWarehouseToWarehouseBlob (payload) {
        const result = await this._consume.postData(payload, this._warehouseTowarehouse + 'Blob')
        return result.data
    }

    async transferWarehouseToCustomerBlob (payload) {
        const result = await this._consume.postData(payload, this._warehouseTocustomer + 'Blob')
        return result.data
    }

    async transferCustomerToWarehouseBlob (payload) {
        const result = await this._consume.postData(payload, this._customerTowarehouse + 'Blob')
        return result.data
    }

    async get (id) {
        const url = `${this._url}/${id}`
        // console.log(this._accessToken)
        const result = await this._consume.getData(url)
        return result.data
    }

    async getLogisticMutation (itemid = '', len = '', page = '') {
        let url = `${this._url_logistic}/view?itemId=${itemid}&line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }
    async getAll ({itemid = '', warehouseid = '', len = 'ALL', page = ''}) {
        let url = `${this._url}/view?itemid=${itemid}&warehouseid=${warehouseid}&line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllBySupplier (supplier = '', paid = '', len = 'ALL', page = '') {
        let url = `${this._url}/supplier/view?supplierId=${supplier}&paid=${paid}&line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async put (id, payload) {
        const url = `${this._url}/${id}`
        console.log(url)
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async delete (id, noRouter) {
        const url = `${this._url}/${id}` //`${this._url}/${name}?noRouter=${!noRouter}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async getLogisticCustomer (id) {
        const url = `${this._url_customer}/${id}`
        // console.log(this._accessToken)
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllLogisticCustomer ({itemid = '', customerid = '', len = 'ALL', page = ''}) {
        let url = `${this._url_customer}/view?itemid=${itemid}&line=${len}&page=${page}&customerid=${customerid}`
        //  console.log(url, itemid, customerid)
        const result = await this._consume.getData(url)
        return result.data
    }

    async putLogisticCustomer (id, payload) {
        const url = `${this._url_customer}/${id}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async deleteLogisticCustomer (id) {
        const url = `${this._url_customer}/${id}` //`${this._url}/${name}?noRouter=${!noRouter}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async exportLogistic (name, warehouseid, damaged) {
        const payload = {name, warehouseid, damaged}
        const queryParam = MapToParamQuery(payload)
        const url = 'export/logistic/excel' + queryParam
        const filename = name + '-Logistic-Goods-Export-' + new Date().toDateString()
        const result = await this._consume.GlobalDownload(url, filename)
        return result
    }
}

export default LogisticGoodsService