const defaultColumn = [
    {
    Header: "ID",
    accessor: "id",
    },
    {
    Header: "Name",
    accessor: "name",
    },
    {
    Header: "QTY",
    accessor: "qty",
    },
    {
    Header: "Lunas",
    accessor: "paid",
    },
    {
    Header: "Status",
    accessor: "damaged",
    },
    {
    Header: "Serial Number",
    accessor: "sn",
    },
    {
    Header: "Location",
    accessor: "location",
    },
    {
    Header: "Price",
    accessor: "price",
    },
    {
    Header: "Update  Delete",
    accessor: "actions",
    sortable: false,
    filterable: false,
    },
]

export default defaultColumn