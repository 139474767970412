const { Label, Input } = require("reactstrap");

function ValidationMinMaxInput({
    label = "label",
    value,
    disabled,
    onChange,
    min,
    max,
    type = "text"
}) {

    return (
        <>
        <Label>{label}</Label>
        <Input
            name= {label}
            placeholder={label}
            type={type}
            value={value}
            disabled={disabled}
            onChange={(e) => {
                if (e.target.value >= min && e.target.value <= max) {
                    onChange(e.target.value);
                }
            } } />
        </>
    )
}

export default ValidationMinMaxInput