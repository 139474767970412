import React from "react";
import LoadingScreen from "components/Loader/LoadingScreen";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken,  getUserData, getOwner, GetItemMeasurement, PriceFormat } from "../../../variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions";
import BasicInput from "components/Input/BasicInput";
import { DateTimestr } from "Main/Utils";
import { Datestr } from "Main/Utils";
import Checklist from "components/Button/Checklist";
// import { Downloads } from "Main/Utils";
import { payloadUpdate,
  addSubmissionLogisticList  } from "variables/MitraSubmission/SubmissionLogistic";
import MitraSubmissionLogisticService from "Main/MitraSubmission/MitraSubmissionLogistic";
import { getSubmissionLogisticData } from "variables/MitraSubmission/SubmissionLogistic";
import SelectBasic from "components/Input/SelectBasic";
import { verifySuperAdmin } from "Main/Utils";

function SubmissionLogisticUpdate(props) {
  const submissionLogistic = getSubmissionLogisticData()
  const submissionLogisticService =  new MitraSubmissionLogisticService()
  const refreshToken = getRefreshToken();
  const userData = getUserData()
  const ownerData = getOwner()
  const measurementOption = GetItemMeasurement()
  const owner = ownerData.find(own => own.value === userData.organization)
  const [loading, loadingOnChange ] = useState(false)
  const [price, setPrice] = useState(submissionLogistic.price)
  const [desc, setDesc] = useState(submissionLogistic.description)
  const {id, name, creation, modified,
    createdBy, modifiedBy, submitDate,
    measurement, filename, approve, decline} = submissionLogistic
  const [selectMeasurement, setSelectMeasurement] = useState(measurementOption.find((opt) => opt.value === measurement) || [])
  const [valueMeasurement, setValueMeasurement] = useState(submissionLogistic.value)
  const { userSuper } = verifySuperAdmin()


  const getAll =  async(trash = false) => {
    const len = 'ALL'
    const result =  await submissionLogisticService.getAllData(len, trash)
    addSubmissionLogisticList(result.data)
  }

  const download = async(id) => {
    // loadingOnChange(true)
    const result = await submissionLogisticService.downloadPdf(id, filename)
    // loadingOnChange(false)
    return result
  }

const update = async() => {
    swal("Update", "Perubahan Data Tidak Dapat di Batalkan", "info", {
            buttons: {
            update: {text: "Update", value: 'up' },
            cancel: 'Batal'
          }
        }
    ).then(async(value) => {
      switch (value) {
            case 'up':
              try {
                const payload = payloadUpdate(selectMeasurement.value, valueMeasurement, price, desc)
                // console.log(payload)
                const result = await submissionLogisticService.putData(id, payload)
                await getAll()
                swal(result.status, result.message, 'success').then(() => {
                  props.history.push('/admin/submission-logistic-list')
                })
              } catch (error) {
                return await CatchError(error, props, refreshToken)
              }
              break;

            default:
              break;
          }
        })
    }
  // function deleteSelect () {
  //   // todo
  // }

  const children = (
    <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Detail Pengajuan Barang</h5>
                </CardHeader>
                <CardBody>
                  <BasicInput
                    key={"1"}
                    label = {'Mitra'}
                    disabled
                    value = {owner.value}
                  />
                  <BasicInput
                    key={"2"}
                    label = {'nama'}
                    disabled
                    value = {name}
                  />
                  <SelectBasic
                    key={"3"}
                    label = {'Pengukuran'}
                    value = {selectMeasurement}
                    option = {measurementOption}
                    onChange = {setSelectMeasurement}
                  />
                  <BasicInput
                    key={"4"}
                    label = {'QTY'}
                    value = {valueMeasurement}
                    onChange = {setValueMeasurement}
                    type = {'number'}
                  />
                  <BasicInput
                    key={"5"}
                    label = {'Harga'}
                    value = {price}
                    onChange = {setPrice}
                    type = {'number'}
                  />
                  <BasicInput
                    key={"6"}
                    label = {'Total Harga'}
                    value = {PriceFormat((price * valueMeasurement), 'idr')}
                  />
                  <BasicInput
                    key={"7"}
                    label = {'Tanggal Submit'}
                    disabled
                    value = {Datestr(submitDate)}
                  />
                  <div>
                  <BasicInput
                    key={"8"}
                    label = {'Attachment'}
                    disabled
                    value = {filename}
                  />
                  <Button
                    key={"9"}
                    onClick = {() => download(id)}
                    disabled = {!filename}
                    color="info">Download</Button>
                  </div>
                  <BasicInput
                    key={"10"}
                    label = {'Deskripsi'}
                    value = {desc}
                    onChange = {setDesc}
                    type = {'textarea'}
                  />
                  <BasicInput
                    key={"11"}
                    label = {'Creation'}
                    disabled
                    value = {DateTimestr(creation)}
                  />
                  <BasicInput
                    key={"12"}
                    label = {'Modified'}
                    disabled
                    value = {DateTimestr(modified)}
                  />
                  <BasicInput
                    key={"13"}
                    label = {'CreatedBy'}
                    disabled
                    value = {createdBy}
                  />
                  <BasicInput
                    key={"14"}
                    label = {'ModifiedBy'}
                    disabled
                    value = {modifiedBy}
                  />
                  <Checklist
                    key={"15"}
                    text = {'Approve'}
                    value = {approve}
                    checked = {approve}
                  />
                  <Checklist
                    key={"16"}
                    text = {'Decline'}
                    value = {decline}
                    checked = {decline}
                  />
                  <Button onClick = {update}
                  disabled = {!userSuper}
                  color="info">update</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
  )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen
          children={children}
          loading = {loading}
          text={"Loading..."}/>
      </>
    );
}


export default SubmissionLogisticUpdate;
