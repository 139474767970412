import { useState } from "react"
import { Buffer } from "buffer"
import { getUserPermission } from "variables/common"
import TableLoadingIMG from "assets/img/TableLoading.gif"
import { getUserData } from "variables/common"
// const { ExportToCsv } = require('export-to-csv')

export const payDate = (date) => {
  if (!date) {
    return 'No Payment'
  } else {
    return new Date(date).toString()
  }
}

export const payType = (type) => {
  if (!type) {
    return 'No Payment'
  } else {
    return type
  }
}

export const nullCompensation = (data) => {
  if (!data) {
    return 'No Data'
  } else {
    return data
  }
}

export const templateType = (priority, warning, overdue, paymentNotification, activeNotification) => {
  if (priority === true) {
    return (<div className="bg-success center">Priority</div>)
  } else if (warning === true) {
    return (<div className="bg-warning center">Warning</div>)
  } else if (overdue === true) {
    return (<div className="bg-danger text-light center">Overdue</div>)
  } else if (paymentNotification === true) {
    return (<div className="bg-info center">Payment Notification</div>)
  } else if (activeNotification === true) {
    return (<div className="bg-primary text-light center">Active Notification</div>)
  }
  return (<div className="center bg-secondary text-light">Normal</div>)
}

export const Datestr = (date) => {
  if (!date) {
    return 'Date not Set'
  }
  return new Date(date).toLocaleDateString('in-ID')
}

export const DateTimestr = (date) => {
  if (!date) {
    return 'Date not Set'
  }
  return new Date(date).toLocaleString('in-ID')
}

export const invoiceStatus = (date, bool = false) => {
  if (!date) {
    if (!bool) {
      return 'belum bayar'
    } else {
      return false
    }
  } else {
    if (!bool) {
      return 'lunas'
     } else {
      return true
    }
  }
}

export const userStatus = (status) => {
  if (!status) {
    return 'disable'
  } else {
    return 'active'
  }
}

export const customTextFromBoolean = (status, trueText, falseText) => {
  if (!status) {
    return (<div className="bg-warning center">{falseText || 'Offline'}</div>)
  }
    return (<div className="bg-success center">{trueText || 'Online'}</div>)
}

export const customTextAndBgColorFromBoolean = (status, trueText, falseText, trueColor = "bg-success", falseColor = "bg-warning") => {
  if (!status) {
    return (<div className={`${falseColor} center`}>{falseText || 'Offline'}</div>)
  }
    return (<div className={`${trueColor} center`}>{trueText || 'Online'}</div>)
}

export const customTextFromBooleanDanger = (status, trueText, falseText) => {
  if (!status) {
    return (<div className="bg-danger center">{falseText || 'Offline'}</div>)
  }
    return (<div className="bg-success center">{trueText || 'Online'}</div>)
}

export const customTextFromBooleanReverse = (status, trueText, falseText) => {
  if (!status) {
    return (<div className="bg-success center">{trueText || 'Online'}</div>)
  }
    return (<div className="bg-warning center">{falseText || 'Offline'}</div>)
}

export const customTextFromBooleanReverseDanger = (status, trueText, falseText) => {
  if (!status) {
    return (<div className="bg-success center">{trueText || 'Online'}</div>)
  }
    return (<div className="bg-danger center">{falseText || 'Offline'}</div>)
}

export const StatusDisplay = (status) => {
  if (!status) {
    return 'Failed'
  }
    return 'Success'
}

export const customerOperation = (status, trueText, falseText) => {
  if (!status) {
    return falseText || 'Non-Active'
  }
    return trueText || 'Active'
}

export const validatePermission = (props, rule, operat) => {
  const data = getUserPermission()
  const roleList = ['customer', 'billing', 'address', 'user', 'item', 'invoice', 'resource']
  const operations = ['create', 'read', 'update', 'delete']
  const roleData = roleList[rule]
  const operation = operations[operat]
  const result = data.find((e) => e.Role === roleData)
  if (!result) {
    props.history.push('/admin/forbidden')
  } else {
      const role = result
      if (operation === 'create') {
        if (role.Create === false || !role.Create) {
          props.history.push('/admin/forbidden')
        } else {
          return result
        }
        } else if (operation === 'read') {
          if (role.Read === false || !role.Read) {
            props.history.push('/admin/forbidden')
          } else {
            return result
          }
        } else if (operation === 'update') {
          if (role.Update === false || !role.Update) {
            props.history.push('/admin/forbidden')
          } else {
            return result
          }
        } else if (operation === 'delete') {
          if (role.Delete === false || !role.Delete) {
            props.history.push('/admin/forbidden')
          } else {
            return result
        }
      }
    }
  }

export const Downloads = (rawData, name, type, extention, middleName = 'exports' ) => {
  const today = new Date().toLocaleString()
  const blob = new Blob([rawData], { "type": type })
  // 2. Create blob link to download
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}-${middleName}-${today}${extention}`);
  // 3. Append to html page
  document.body.appendChild(link);
  // 4. Force download
  link.click();
  // 5. Clean up and remove the link
  link.parentNode.removeChild(link);
}

// export const ExportCSV = (title, data) => {
//   const option = {
//     fieldSeparator: ',',
//     quoteStrings: '"',
//     decimalSeparator: '.',
//     showLabels: true,
//     showTitle: true,
//     title: title,
//     useTextFile: false,
//     useBom: true,
//     useKeysAsHeaders: false
//   // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
//   }
//   const exports = new ExportToCsv(option)
//   const csv = exports.generateCsv(data)
//    return csv
// }

export const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export const GlobalValueMap = (data) => {
  const keys = Object.typedKeys(data)
  const list = []
  for (let idx = 0; idx < keys.length; idx++) {
    const element = keys[idx]
     if (!data[element]) {
      continue
    } else {
      list.push({ value: data[element], label: element })
      GlobalValueMap(data[element])
    }
  }

  return list
}


export const BinnaryUpload = ( fileData ) => {
  const files = Array.from(fileData)

  const formData = new FormData()

  files.forEach((file) => {
    formData.append('data', file, file.name)
  })

  return formData
}

export const makePageValue = (total) => {
  const list = []
  if (total === 1 || !total) {
    list.push({value: 1, label: 'Home'})
  } else {
    for (let idx = 1; idx <= total; idx++) {
      if (idx === 1) {
        list.push({value: idx, label: 'Home'})
      } else {
        list.push({value: idx, label: idx})
        }
      }
    }
    return list
}


export const TableLoading = () => {
  return (
  <div className="actions-center">
    <img src={TableLoadingIMG} alt= "Loading..."/>
  </div>
  )
}

export const MainChartData = (data) => {
  const chart = []
  for (let i = 1; i <= 12; i++) {
    if (!data) {
      chart.push(null)
      continue
    }
    const e = data.find(dat => Number(dat.month) === i)
    if (!e) {
      chart.push(null)
      continue
    }

    chart.push(Number(e.revenue))
  }
  return chart
}

export const MainChartDataProcessing = (data) => {
  if (!data) {
    return {
      chartRevenue: null,
      chartIncome: null,
      chartReceivable: null,
      chartExpense: null
    }
  }

  const { historyIncome, historyExpense, historyRevenue, historyReceivable } = data
  const chartIncome = MainChartData(historyIncome)
  const chartExpense = MainChartData(historyExpense)
  const chartRevenue = MainChartData(historyRevenue)
  const chartReceivable = MainChartData(historyReceivable)

  return {
    chartIncome,
    chartExpense,
    chartRevenue,
    chartReceivable
  }
}

export const CustomerChartDataProcessing = (data) => {
  if (!data) {
    return {
      activeCustomer: null,
      suspendCustomer: null,
      disableCustomer: null
    }
  }

  const activeCustomer = []
  const suspendCustomer = []
  const disableCustomer = []

  for (let i = 1; i <= 12; i++) {
    if (!data) {
      activeCustomer.push(null)
      suspendCustomer.push(null)
      disableCustomer.push(null)
      continue
    }
    const hist = data.find(dat => Number(dat.month) === i)
    if (!hist) {
      activeCustomer.push(null)
      suspendCustomer.push(null)
      disableCustomer.push(null)
      continue
    }

    activeCustomer.push(hist.customeractiveqty)
    suspendCustomer.push(hist.customersuspendqty)
    disableCustomer.push(hist.customerdisabledqty)
  }

  return {
    activeCustomer,
    suspendCustomer,
    disableCustomer
  }
}

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
export const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
}

export const verifyLength = (value, length) => {
    if (value.length >= length) {
        return true;
    }
    return false;
};

export const compare = (string1, string2) => {
    if (string1 === string2) {
      return true;
    }
    return false;
  };

 export function parseDatetime (datetime) {
    if (!datetime) {
      return {
        year: 0,
        month: 0,
        day: 0,
        hours: 0,
        minute: 0,
        second: 0,
        milisecond: 0
      }
    }
    const date = new Date(datetime)
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    const hours = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    const milisecond = date.getMilliseconds()
    return {
      year,
      month,
      day,
      hours,
      minute,
      second,
      milisecond
    }
  }

  export function mapInvoicetoPeriod(invoiceData) {
    return invoiceData.map((inv) => {
      const { year} = parseDatetime(inv.date)
      const invDate = new Date(inv.date)
      const month = invDate.toLocaleString('default', { month: 'long' });
      return { month, year}
    })
  }

export function generateTime (dayGen, hoursGen, minuteGen) {
    return `${dayGen}-${hoursGen}-${minuteGen}`
  }

export function selectMailTemplate (dataMail, data)  {
  const today = new Date().toISOString()
  const { day, hours, minute } = parseDatetime(today)
  const Today = generateTime(day, hours, minute)
  const inv = data[0]
  if (!inv.paid) {
    const due = inv.due
    const { day, hours, minute } = parseDatetime(due)
    const duedate = generateTime(day, hours, minute)
    const beforeDueDate = generateTime((day - 3), hours, minute)
    if (Today === duedate) {
      return dataMail.find(e => e.label.toLowerCase() === 'overdue')
    } else if (Today === beforeDueDate) {
       return dataMail.find(e => e.label.toLowerCase() === 'warning')
    }
  } if (inv.paid) {
    return dataMail.find(e => e.label.toLowerCase() === 'payment')
  }
  return dataMail.find(e => e.label.toLowerCase() === 'email')
}

export function calculateTotal (data){
  // Hanya untuk object dengan Price, qty
  var total = 0
  for(const val of data) {
    total = total + Number(val.price || 0) * Number(val.qty || 0)
  }
  return total
}

export function MapObjToSelectObj(listData) {
  return listData.map((list) => {return {value: list.id, label: list.name}})
}

export const convertDateLocaleString = (date) => {
    const today = new Date()
    const tz = today.getTimezoneOffset
    const date12 = new Date(date).toLocaleString('en-US', {hour12: false})
    const [first, second] = date12.split(',').map(item => item.trim());
    const [month, day, year] = first.split('/');
    const [hours, minutes, seconds] = second.split(':');
    // console.log(date)
    // console.log(year, month - 1, day, hours, minutes, seconds)
    const constructDate = `${year}-${month}-${day}:${hours}:${minutes}:${seconds}.${tz}`
    // console.log(constructDate)
    const dates = new Date(constructDate)
    return  dates.toISOString()
  }

export const verifySuperAdmin = () => {
  const userData = getUserData()
  const mitraType = userData.mitraType
  const userType = userData.userType
  const mitraSuper = mitraType === 'SUPERADMIN'
  const userSuper = userType === 'SUPERADMIN'
  const userAdmin = userType === 'ADMINISTRATOR'
  const mitraAdmin = mitraType === 'ADMINISTRATOR'
  return { mitraSuper, mitraAdmin, userSuper, mitraType, userType, userAdmin, ...userData}
}

export const CleanStringSpace = (str) => {
  return str.replace(/\s+/g, '')
}

export const ApprovalOperation = (type, status) => {
  if (type === 0) {
    return !status ? 'Declined' : 'Approved'
  }
  if (type === 1) {
    return !status ? 'Approved' : 'Declined'
  }
}

export const DownloadBinary = (response, filename) => {
    const blob = new Blob([response.data])
    console.log(response.data)
    const fileURL = window.URL.createObjectURL(blob);
    // Setting various property values
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = filename;
    alink.click();
  }

export const SplitString = (type, separator = '/') => {
    return type.split(separator)
  }

export const ExtractHeaderData = (response) => {
  const typeData = response.headers['content-type'];
  // "text/csv; charset=utf-8"
  const contentDispossion = response.headers['content-disposition'];
  const [type, encoding] = SplitString(typeData || ';', ';')
  const [prefix, extention] = SplitString(type)
  const disposition = SplitString(contentDispossion || ';', ';')
  const fileNameRaw = disposition[1] || type  + '-' + prefix + '-' + new Date().toLocaleDateString()
  const file = SplitString(fileNameRaw, '=')

  return {
    type,
    prefix,
    encoding,
    extention,
    filename: file[0]
  }
}

export const MapToParamQuery = (data) => {
  if (!data) {
    return '?'
  }
  const keys = Object.keys(data)
  let list =''
  keys.forEach((key, i) => {
    if (i === 0) {
      return list = list + '?' + key + '=' + data[key]
    }
    list = list + '&' + key + '=' + data[key]
  })
  return list
}

export function BlobDataLen({ blob }) {
  if (!blob) {
    return (<div className="bg-success center">Waiting Data</div>)
  }
  return (<div className="bg-warning center">{blob.length} Data Pending</div>)
}

export function ClassnameByNumberValue(value) {
  if (value > 0) {
    return 'text-success'
  }
  if (value < 0) {
    return 'text-danger'
  }
  return 'text-warning'
}

export const isComplexJson = (obj, depth = 0) => {
    if (depth >= 4) {
      return true; // Maximum depth reached
    }
    if (typeof obj !== "object" || obj === null) {
      return false; // Reached a primitive value
    }
    return Object.values(obj).some((value) => isComplexJson(value, depth + 1));
  }

  export const NumberToPrecentagesCalculation = (price, discountPrice) => {
    return Math.ceil((Number(discountPrice) / Number(price)) * 100)
  }

  export const PrecentagesToNumberCalculation = (price, precentages) => {
      return Math.ceil(Number(price) - PrecentagesToNumberDifferenceCalculation(price, precentages))
  }

  export const PrecentagesToNumberDifferenceCalculation = (price, discount) => {
    return Math.ceil(Number(price) * (Number(discount) / 100))
  }

  export const CalculatePrecentagesFromPriceDIfference = (monthlyPrc, discountPrc) => {
    const difference = Math.abs(Number(monthlyPrc) - Number(discountPrc))
    const result = 100 - ((difference / Number(monthlyPrc)) * 100)
    return result.toFixed(4)
  }

export const hexEncoder = (str) => {
  const buffStr = Buffer.from(str).toString('hex')
  return buffStr.toString()
}

export const hexDecoder = (buffStr) => {
  const buffHex = Buffer.from(buffStr.toString(), 'hex').toString()
  return buffHex
}

export const setIncrementDate = (date, dueDay) => {
  const period = !date ? new Date() : new Date(date)
  const periodDate = period.getDate()
  const dueFilter = !dueDay
      ? 0
      : (dueDay < -30)
          ? ((periodDate === 31) ? -30 : -29)
          : dueDay
    // if periodDate == 31 and dueDay < 0 set dueDay + 1
  const dueFinal = (periodDate === 31 && (dueFilter < 0 && dueDay > -30))
    ? (dueFilter - 1)
    : dueFilter
  const due = new Date(period.setDate((periodDate + dueFinal)))
  return DateTimestr(due)
}

export function calculatePriceDiscount (price, disc) {
    return Number(price - (price * (disc / 100)))
  }

export function ModalColorSelector(data) {
  if (!data) {
    return {
      isDue: true,
      paidAltStatus: '',
      dueColor: '',
      duePositiveColor: '',
      duePositiveColorList: '',
      dueColorList: ''
    }
  }
    const { dueSubsUnpaid, paid } = data

    const isDue = (new Date(dueSubsUnpaid) > new Date())
    const paidAltStatus = isDue ? 'Menunggu-Pembayaran' : 'Jatuh-Tempo'
    const dueColor = !isDue ? 'bg-warning' : 'bg-danger'
    const duePositiveColor = isDue ? 'bg-success' : 'bg-danger'

    const duePositiveColorList = !paid ? (!isDue ? 'bg-danger' : 'bg-warning') : 'bg-success'
    const dueColorList = !isDue ? 'bg-danger' : 'bg-warning'
    return {
      isDue,
      paidAltStatus,
      dueColor,
      duePositiveColor,
      duePositiveColorList,
      dueColorList
    }
}

