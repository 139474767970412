import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import {getRefreshToken, LogisticSupplier,
  addLogisticSupplierList,
  getOwner} from "./../../../variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import LogisticSupplierService from "Main/Logistic/LogisticSupplierService";
import { verifyLength } from "Main/Utils";
import SelectBasic from "components/Input/SelectBasic";
import { verifySuperAdmin } from "Main/Utils";

function LogisticSupplierSubmit(props) {
  const Service = new LogisticSupplierService()
  const refreshToken = getRefreshToken();
  const name = useFormInput('')
  const address = useFormInput('')
  const [idManual, idManualOnChange] = useState()
  const [manualSupplierId, manualSupplierIdOnChange] = useState(false)
  const [minLengthState, setminLengthState] = React.useState("");
  const [minLengthPhoneState, setminLengthPhoneState] = React.useState("");
  const [email, emailOnChnage] = useState('')
  const [MobileNo, mobileNoOnChange] = useState('')
  const [npwp, npwpOnChange] = useState('')
  const [mobileNoPassed, phonePassedOnChnage] = useState(true)
  const [npwpPassed, npwpPassedOnChange] = useState(true)
  const ownerOption = getOwner()
  const { mitraSuper, organization } = verifySuperAdmin()
  const [mitra, mitraOnChange] = useState(ownerOption.find((data) => data.value === organization))

  const getAll =  async() => {
        const len = 'ALL'
        const result =  await Service.getAll(len)
        addLogisticSupplierList(result.data)
  }

  const submit = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
    const payload = LogisticSupplier(idManual, name.value, npwp, email, MobileNo, address.value, mitra.value)
      const result = await Service.post(payload)
      await getAll()
      console.log(result)
      swal("Success", "Data Sukses di Tambahkan", "success").then(() => {
       props.history.push('/admin/logistic-supplier-list')
      })
    } catch (error) {
      return CatchError(error, props, refreshToken)
    }
  }

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Form Logistik Supplier</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <div>
                        <Label>Manual Supplier ID</Label>
                          <Input
                            placeholder="Manual Id"
                            value = {idManual}
                            onChange = {(e) => {
                              if (!verifyLength(e.target.value, 13)) {
                                 idManualOnChange(e.target.value)
                              }}
                            }
                            type="text"
                            disabled = {!manualSupplierId}
                          />
                          {/* {!subsIdAvailable ? (
                          <label className="error">
                            Network Id Telah dipakai, coba ID lain
                          </label>
                          ) : null} */}
                          </div>
                          <FormGroup check>
                          <Label check> Generate Manual
                            <Input type="checkbox"
                            value = {manualSupplierId}
                                  onChange = {e => manualSupplierIdOnChange(e.target.checked)}
                            />
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <SelectBasic
                          label = {'Mitra'}
                          value = {mitra}
                          onChange = {mitraOnChange}
                          isDisabled = {!mitraSuper}
                          option = {ownerOption}
                        />
                        <FormGroup>
                          <Label>Nama *</Label>
                          <Input
                            placeholder="Nama"
                            {...name}
                            type="text"
                          />
                        <Label>Email</Label>
                          <Input
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder="Email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => {
                              emailOnChnage(e.target.value)
                            }}
                          />
                       <div>
                       <Label>NPWP</Label>
                        <Input
                          name="min_length_ktp"
                          type="number"
                          placeholder="KTP Number"
                          max={16}
                          value = {npwp}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 13)) {
                              npwpOnChange(e.target.value)
                              if (!verifyLength(e.target.value, 12)) {
                                setminLengthState("has-danger");
                                npwpPassedOnChange(true)
                              } else {
                                setminLengthState("has-success");
                                npwpPassedOnChange(false)
                              }
                              }
                          }}
                        />
                        {minLengthState === "has-danger" ? (
                          <label className="error">
                            NPWP harus di isi 12 Karakter.
                          </label>
                        ) : null}
                       </div>
                       <div>
                        <Label>Nomor Telepon</Label>
                        <Input
                          name="min_length_phone"
                          type="number"
                          placeholder="Phone Number"
                          max={12}
                          value={MobileNo}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 13)) {
                              mobileNoOnChange(e.target.value)
                            if (!verifyLength(e.target.value, 12)) {
                              setminLengthPhoneState("has-danger");
                              phonePassedOnChnage(true)
                            } else {
                              setminLengthPhoneState("has-success");
                              phonePassedOnChnage(false)
                            }
                            }
                          }}
                        />
                        {minLengthPhoneState === "has-danger" ? (
                          <Label className="error">
                            Field Harus di isi 12 Karakter.
                          </Label>
                        ) : null}
                       </div>
                          <Label>Alamat</Label>
                          <Input
                          className="react-select"
                          type="textarea"
                          {...address}
                          />
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {submit} disabled = {!npwpPassed & mobileNoPassed} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default LogisticSupplierSubmit;


