import { HandleGeolocation } from 'Main/Geolocation/HandleGeolocation';
import BasicMapIframe from 'components/Maps/BasicMapIframe';
import React, { useState } from 'react';
import { Button, Input, FormGroup, Label, Col, Row } from 'reactstrap';

function InputCoordinate ({ latitude, setLatitude, longitude, setLongitude, disabledLat, disabledLog, disabledGeo}) {
  const [accuracy, setAccuracy] = useState('');

  const handleGeolocation = async () => {
    const geoData = await HandleGeolocation()
    const { latitude, longitude, accuracy, message } = geoData
    console.log(geoData)
    setLatitude(latitude);
    setLongitude(longitude);
    const accMsg = `${accuracy.toFixed(3)} Meter`
    setAccuracy(message || accMsg);
  };

  return (
      <div>
        <BasicMapIframe
            lat = {latitude}
            long = {longitude}
        />
        <Row>
            <Col>
                <FormGroup>
                  <Label for="latitudeInput">Latitude:</Label>
                  <Input
                    type="text"
                    id="latitudeInput"
                    value={latitude}
                    onChange={e => setLatitude(e.target.value)}
                    disabled = {disabledLat}
                  />
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                  <Label for="longitudeInput">Longitude:</Label>
                  <Input
                    type="text"
                    id="longitudeInput"
                    value={longitude}
                    onChange={e => setLongitude(e.target.value)}
                    disabled = {disabledLog}
                  />
                </FormGroup>
            </Col>
        </Row>
          {
            !disabledGeo ? (
                <>
                <FormGroup>
                    <Label for="accuracyInput">Akurasi:</Label>
                        <Input
                            type="text"
                            id="accuracyInput"
                            value={accuracy} />
                </FormGroup>
                <Button
                  className='bold bg-success'
                  onClick={handleGeolocation}>
                    <i className="now-ui-icons location_pin" /> Lokasi Saat Ini</Button>
                </>
            ) : null
          }
      </div>
  );
};

export default InputCoordinate;