export const getDataLocal = (name) => {
    console.log(name, '---get')
    const data = localStorage.getItem(name)
    try {
        if (!data) {
            return []
        }
        return JSON.parse(data)
    } catch (error) {
        console.log(error.message)
        return []
    }
}

export const setDataLocal = (name, payload) => {
    console.log(name, '----set')
    localStorage.setItem(name, JSON.stringify(payload))
}

export const deleteDataLocal = (name) => {
    console.log(name, '---delete')
    localStorage.removeItem(name)
}
