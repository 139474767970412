import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Button
} from "reactstrap";
// core components
import swal from "sweetalert";
import CommonList from "components/Table/CommonList";
import { gridPoleColumns } from "./RenderGridPole";

function GridPoleInventoryList(props) {
  const {add, loading, addPermission, getData, dataState } = props
  try {

   const menu = (
     <Row>
       <Col>
         <Button color="info" onClick={add} disabled = {!addPermission}>Add</Button>
       </Col>
     </Row>
   )

   return (
        <>
           <Row>
             <Col xs={12} md={12}>
               <CommonList
                 key={'qwdqwd2e21e'}
                 header={'Tiang Jaringan'}
                 columns={gridPoleColumns}
                 // columns={invoiceColumnsUser}
                 dataState={dataState}
                 menu = {menu}
                 // Add={addInvoiceData}
                 load={loading}
                 getData={getData}
               />
             </Col>
           </Row>
        </>
     )
   } catch (error) {
      swal(`Failed to Load Data err: ${error.message}`).then(() => {
         return (<p>{error.message}</p>)
       }
    )
  }
}


export default GridPoleInventoryList;
