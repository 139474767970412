import { useState } from "react";

const { useRef } = require("react");
const { FormGroup, Input, Label } = require("reactstrap");

function SingleFileInput({label, setFile, placeholder}) {
    const singleFileRef = useRef()
    const [singleFileName,setSingleFileName] = useState('')
    const handleSingleFileInput = (e) => {
        singleFileRef.current.click(e);
    };

    const addSingleFile = (e, type) => {
        let fileNames = "";
        let files = e.target.files;
        for (let i = 0; i < e.target.files.length; i++) {
            fileNames = fileNames + e.target.files[i].name;
        }
        setFile(files);
        console.log(files)
        setSingleFileName(fileNames);
    };

    return (
        <>
        <Label>{label || 'Single File Input'}</Label>
        <FormGroup className="form-file-upload form-file-simple">
            <Input
            type="text"
            className="inputFileVisible"
            placeholder={ placeholder || "Select Excel/Csv File"}
            onClick={(e) => handleSingleFileInput(e)}
            defaultValue={singleFileName}
            />
            <input
            type="file"
            className="inputFileHidden"
            style={{ zIndex: -1 }}
            ref={singleFileRef}
            onChange={(e) => addSingleFile(e)}
            />
        </FormGroup>
        </>
    )
}

export default SingleFileInput