import React from "react";
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v=1.5.0";
import 'assets/css/react-tabs.css';
import "assets/css/demo.css";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "rc-pagination/assets/index.css";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { Redirect } from "react-router-dom";
import { getAccessToken } from './variables/common'

const accesstokenData = getAccessToken()

function TokenValidate() {
  if (!accesstokenData) {
    return (<Redirect from='/' to="/auth/login-page" />)
  }
  return (<div></div>)
}

createRoot(document.getElementById("root")).render(
     <BrowserRouter>
       <Route
           path="/admin"
           render={(props) => {
             return <AdminLayout {...props} />;
           }}
        />
     <Route
          path="/auth"
          render={(props) => {
            return <AuthLayout {...props} />;
          }}
        />
        <TokenValidate/>
        {/* <Redirect from='/*' to="/auth/login-page" /> */}
    </BrowserRouter>
);
