import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { PriceFormat } from "variables/common";
import Select from 'react-select'

function TransferCustomerToWarehouseBody({name,
  nameOnChange,
    warehouse,
    customerFilterOption,
    warehouseOnChange,
    customer, warehouseOptions,
    customerOnChange, qty,
    qtyState, qtyOnChange,
    priceValue, currency,
    insertDate, insertDateOnChange,
    description, descriptionOnChange
  }) {
    return (
      <>
      <Row>
        <Col className="pr-1" md="10">
          <FormGroup>
            <Label>Nama *</Label>
            <Input
              placeholder="Nama"
              value = {name}
              disabled = {!nameOnChange}
              onChange = {(e) => {
                nameOnChange(e.target.value)
              }}
              type="text"
            />
            <Label>Customer</Label>
            <Select
              // className="react-select"
              // classNamePrefix="react-select"
              placeholder="Customer"
              name="singleSelect"
              disabled
              value = {customer}
              options = {customerFilterOption}
              onChange={e => customerOnChange(e)
            }
            />
            <Label>Gudang Tujuan</Label>
            <Select
              // className="react-select"
              // classNamePrefix="react-select"
              placeholder="Gudang"
               name="singleSelect"
              value = {warehouse}
              options = {warehouseOptions}
              onChange={e => warehouseOnChange(e)
            }
            />
                          {/* <FormGroup check>
                            <Label check> PPN
                              <Input type="checkbox"
                              value = {ppn}
                              onChange = {e => ppnOnChange(e.target.checked)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup> */}
            <Label>QTY</Label>
            <Input
              placeholder="QTY"
              value = {qty}
              onChange = {(e) => {
                if (e.target.value <= qtyState && e.target.value >= 1) {
                  qtyOnChange(e.target.value)
                }
              }
              }
              type="number"
            />
            <Input
              placeholder="Harga"
              value={PriceFormat((Number(priceValue) * Number(qty)), currency.value)}
              type="text"
            />
            <Label>Tanggal Transfer</Label>
              <Input
                className = "generate"
                placeholder="Tanggal Transfer"
                              value = {insertDate}
                onChange = {e => {
                     insertDateOnChange(e.target.value)
                  }}
                type="datetime-local"
              />
            <Label>Deskripsi</Label>
            <Input
            className="react-select"
            type="textarea"
            value={description}
            disabled = {!descriptionOnChange}
            onChange = {(e) => {
              descriptionOnChange(e.target.value)
            }}
            />
          </FormGroup>
          </Col>
        </Row>
      </>
    );
}

export default TransferCustomerToWarehouseBody