/*eslint-disable*/
import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
// core components
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions"
// import Switch from "react-bootstrap-switch";
import Select from "react-select"
import { getRefreshToken } from "variables/common";
import { makePageValue } from "Main/Utils";
import BankAccountService from "Main/BankAccountService";
import { addVirtualAccountData } from "variables/common";
import { getVirtualAccountList } from "variables/common";
import { addVirtualAccountList } from "variables/common";
import { getUserPermission } from "variables/common";
import { getUserData } from "variables/common";

function VirtualAccountList(props, id) {
  const pages = 'bank-account'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const userData = getUserData()
  const userType = userData.userType
  const bankAccountService = new BankAccountService()
  const [VirtualAccountData, VirtualAccountDataOnChange] = useState(getVirtualAccountList())
  const [load, setDataLoad] = useState(true)
  const refreshToken = getRefreshToken()
  const [page, pageOnChange] = useState({value: 1, label: 'Home'})
  const [limit, limitOnChange] = useState(10)
  const totalPageOption = makePageValue(VirtualAccountData.totalPage)
  try {
    const getVirtualAccount =  async(pages = page.value, limits = limit) => {
    try {
        setDataLoad(true)
        const dat =  await bankAccountService.getAllVirtualAccount(id)
        const result = dat.data

        addVirtualAccountList(result)
        VirtualAccountDataOnChange(result)
        setDataLoad(false)
        return result
    } catch (error) {
        setDataLoad(false)
        return await CatchError(error, props, refreshToken)
      }
    }

    useEffect( () => {
      getVirtualAccount()
    }, [])

    const viewVirtualAccount = async(id)=> {
      try {
        const result = await bankAccountService.getVirtualAccount(id)
        addVirtualAccountData(result.data)
        props.history.push('/admin/virtualAccount-view')
      } catch (error) {
        return await CatchError(error, props, refreshToken)
      }
    }

    const addVirtualAccount = async() => {
      props.history.push('/admin/virtualAccount-page')
    }

    const option = () => {
      return (
        <tr>
          <td colSpan={6} >
            <Input
                    placeholder="limit"
                    value = {limit}
                    type="number"
                    onChange={(e) => {
                    if (e.target.value >= 5 && e.target.value <= 100) {
                      limitOnChange(e.target.value)
                      setDataLoad(true)
                      getVirtualAccount(page.value, e.target.value)
                    }
                    }
                  }
          /></td>
          <td colSpan={6} >
            <Select className="React-Select"  placeholder="Page"
                    name="singleSelect"
                    value = {page}
                    options={totalPageOption}
                      onChange={(value) => {
                        pageOnChange(value)
                        setDataLoad(true)
                        getVirtualAccount(value.value)
                    }
                  }
                  /></td>
          </tr>
        )
    }

      const deleteVirtualAccount = (id) => {
      swal("Delete", "Data Tidak Dapat di Recover", "info", {
        buttons: {
          cancel: 'Batal',
          delete: {text: "Hapus", value: 'del' }
        }
      }
      ).then(async(value) => {
        switch (value) {
          case 'del':
            try {
              setDataLoad(true)
              await bankAccountService.deleteVirtualAccount(id)
              await swal('Success', 'Customer VA Berhasil di Hapus', 'success')
              await getVirtualAccount(page.value, limit)
              setDataLoad(false)
              return true
            } catch (error) {
              setDataLoad(false)
              return await CatchError(error, props, refreshToken)
            }

          default:
            await getVirtualAccount(page.value, limit)
            return false
        }
      })
    }

    const ListVirtualAccountData = () => {
      const dat = VirtualAccountData.result || VirtualAccountData || []
      if (!dat.length) {
        return (
            <Table responsive bordered hover>
            {option()}
              <thead className="text-primary">
                <tr>
                  <th colSpan={1} className="text-center">View</th>
                  <th colSpan={3} >Customer</th>
                  <th colSpan={5} >Bank</th>
                  <th colSpan={2} >Virtual Account</th>
                  <th colSpan={2} >Wilayah</th>
                  {/* <th colSpan={1} className="text-center">Delete</th> */}
                </tr>
              </thead>
              <tbody>
                  <tr>
                      <td colSpan={12} className="text-center">No Data</td>
                  </tr>
              </tbody>
              {/* <Paginate/> */}
            </Table>
        )
      } else if (load) {
        return (
            <Table responsive bordered hover>
            {option()}
              <thead className="header">
                <tr>
                  <th colSpan={1} className="text-center">View</th>
                  <th colSpan={3} >Customer</th>
                  <th colSpan={5} >Bank</th>
                  <th colSpan={2} >Virtual Account</th>
                  <th colSpan={2} >Wilayah</th>
                  {/* <th colSpan={1} className="text-center">Delete</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                    <td colSpan={12} className="text-center">Loading</td>
                </tr>
              </tbody>
              {/* <Paginate/> */}
            </Table>
        )
      } else {
        return (
            <Table responsive bordered hover>
                  {option()}
                    <thead className="header">
                      <tr>
                        <th colSpan={1} className="text-center">View</th>
                        <th colSpan={3} >Customer</th>
                        <th colSpan={4} >Bank</th>
                        <th colSpan={2} >Virtual Account</th>
                        <th colSpan={2} >Wilayah</th>
                        <th colSpan={1} className="text-center">Hapus</th>
                        {/* <th colSpan={1} className="text-center">Delete</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {dat.map((virtualAccount) => {
                        return (
                        <tr key={virtualAccount.id}>
                        <td colSpan={1} className="text-center">
                        {userType === 'SUPERADMIN' ? (<Button className="small danger" color="info"
                          disabled = {!Permission.Read}
                          onClick={ async() => {
                            await viewVirtualAccount(virtualAccount.id)
                          }}
                          >View</Button>) : null}
                        </td>
                        <td colSpan={3}>{virtualAccount.name}</td>
                        <td colSpan={4}>{virtualAccount.bank_name}</td>
                        <td colSpan={2}>{virtualAccount.number}</td>
                        <td colSpan={2}>{virtualAccount.bank_region}</td>
                        <td colSpan={1} className="text-center">
                        {userType === 'SUPERADMIN' ? (<Button className="small danger"
                        disabled = {!Permission.Delete}
                        color="danger"
                          onClick={ async() => {
                            await deleteVirtualAccount(virtualAccount.id)
                          }}
                          >Delete</Button>) : null}
                        </td>
                        {/* <td colSpan={1} className="text-center">
                          <Button
                            className="btn-icon"
                            color="danger"
                            id={`btn${log.id}`}
                            size="sm"
                            type="button"
                            disabled
                            onClick={() => {deleteDatalog(log.id)}}
                            >
                            <i className="now-ui-icons ui-1_simple-remove" />
                            </Button>
                        </td> */}
                      </tr>)
                      })}
                    </tbody>
                    {/* <Paginate/> */}
                  </Table>
          )
        }
      }

    return (
          <>
              <Row>
                <Col xs={12} md={12}>
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col md={6}>
                          <CardTitle tag="h4" className="title">Virtual Account</CardTitle>
                        </Col>
                        <Col md={6}>
                          <CardTitle tag="h4">Page {page.value}/{VirtualAccountData.totalPage || 1}</CardTitle>
                        </Col>
                        <Col>
                          {userType === 'SUPERADMIN' ? (<Button color="info" onClick={addVirtualAccount} disabled={!Permission.Create}>Add</Button>) : <p></p>}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Col md = {12}>
                        <ListVirtualAccountData/>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          </>
      );
  } catch (error) {
    console.log(error.message)
      swal('Failed to Load Data').then(() => {
        return (<p>Error</p>)
      }
      )
  }
}


export default VirtualAccountList;
