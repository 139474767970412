import React from "react";
import LoadingScreen from "components/Loader/LoadingScreen";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
//   Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getOwner /*, getRefreshToken */ } from "variables/common"
import DashboardChart from "components/Chart/DashboardChart";
import SelectBasic from "components/Input/SelectBasic";
// import { getExportDataType } from "variables/FinanceStatement/FinanceVariable";
// import { CatchError } from "Main/Exceptions/exceptions";
import { parseDatetime } from "Main/Utils";
import HostpotFinanceReportService from "Main/HostpotVoucher/HostpotFinanceStatementService";
import UserService from "Main/UserService";
import HostpotCardStats from "components/Table/HostpotCardStats";
import { CatchError } from "Main/Exceptions/exceptions";
import { verifySuperAdmin } from "Main/Utils";
import { getRefreshToken } from "variables/common";
import CommonList from "components/Table/CommonList";
import HostpotUserHistoryService from "Main/HostpotVoucher/HostpotUserHistoryService";
import { addHostpotUseHistoryrData } from "variables/HostpotVoucher/HostpotUserHistory";
import ColumnsHistory from "./HostpotHistoryColumn";
import { customTextFromBoolean } from "Main/Utils";

function HostpotFinanceStatement(props) {
    const currency = 'idr'
    const today = new Date()
    const refreshToken = getRefreshToken()
    const { year: todayYear } = parseDatetime(today)
    const strYear = todayYear.toString()
    const financeStatementService =  new HostpotFinanceReportService()
    const hostpotHistoryService = new HostpotUserHistoryService()
    const userService = new UserService()
    const ownerData = getOwner()
    const [loading, setLoading ] = useState(true)
    const [dashData, dashDataOnChange] = useState([])
    const defaultYear = { value: strYear, label: strYear }
    const [year, yearOnChange] = useState(defaultYear)
    const [reseller, resellerOnChange ] = useState({})
    const [yearOption, yearOptionOnChange ] = useState([])
    const [resellerOption, resellerOptionOnChange] = useState([])
    const { monthlyChart, data } = dashData
    const { mitraSuper, organization, id: userId, userAdmin } = verifySuperAdmin()
    const [owner, setOwner] = useState(ownerData.find(own => own.value === organization))
    const [loadHistory, setLoadHistory] = useState(false)
    const [voucherHistoryData, setVoucherHistoryData] = useState([])
    const [chartData, setChartData] = useState({
        historyIncome: monthlyChart || [],
        historyExpense:  []
    })
    // const exportDataType = getExportDataType()
    // const [ type, typeOnChange ] = useState({})
    // const [ monthOption, monthOptionOnChange ] = useState([])
    // const download = async() => {
    //     try {
    //         loadingOnChange(true)
    //         const paramValue = {
    //             mitra: owner.mitraid,
    //             mitraName: owner.label,
    //             year: year.value,
    //             type: type.value,
    //             reseller: reseller.value
    //         }
    //         const rawData = await financeStatementService.downloadFinanceReport(paramValue)
    //         loadingOnChange(false)

    //         return rawData
    //     } catch (error) {
    //         loadingOnChange(false)
    //         return await CatchError(error, props, refreshToken)
    //     }
    // }

    // const getMonth = async (mitra, year) => {
    //     const result = await financeStatementService.getInvoiceMonth({mitra, year})
    //     monthOptionOnChange(result.data)
    // }

    async function fetchVoucherHistory(mitra = '', year = '', reseller = '') {
        const len = 'ALL'
        try {
            setLoading(true)
            setLoadHistory(true)
            console.log(len, mitra, year, reseller)
            const result = await hostpotHistoryService.getAll(len, mitra, year, reseller)
            const { data } = result
            setVoucherHistoryData(data)
            setVoucherHistoryState(mapHistoryData(data))
            setLoadHistory(false)
            setLoading(false)
            return data
        } catch (error) {
            setLoading(false)
            setLoadHistory(false)
            return await CatchError(error, props, refreshToken)
        }
    }

    async function getVoucherHistoryData(id) {
        try {
            const result = await hostpotHistoryService.get(id)
            const { data } = result
            addHostpotUseHistoryrData(data)
            props.history.push('/admin/hostpot-history-detail')
            return data
        } catch (error) {
            return await CatchError(error, props, refreshToken)
        }
    }

    const getyear = async (mitra) => {
        try {
            setLoading(true)
            const result = await financeStatementService.getYearSelect(mitra)
            yearOptionOnChange(result.data)

            if (!year.value) {
                yearOnChange(defaultYear)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            return await CatchError(error, props, refreshToken)
        }
    }

    async function getUserReseller(mitra) {
      const resellerList = await userService.getUserSelect(mitra)
      const resellerData = resellerList.data

      resellerOptionOnChange(resellerData)

      const defaultReseller = resellerData.find((data) => data.userId === userId)
      const finalSelection = !defaultReseller ? resellerData[0] : defaultReseller
      resellerOnChange(finalSelection)
      return finalSelection.userId
    }

    const FetchReportData = async (mitra, year, reseller) => {
        try {
            setLoading(true)
            const result = await financeStatementService.getFinanceStatement(mitra, year, reseller)
            const reportData = result.data

            dashDataOnChange(reportData)

            if (!reportData) {
                setLoading(false)
                return result
            }

            const { monthlyChart } = reportData
            setChartData({
                historyIncome: monthlyChart || [],
                historyExpense:  [],
            })
            setLoading(false)
            return result
        } catch (error) {
            setLoading(false)
            return await CatchError(error, props, refreshToken)
        }
    }

    const mapHistoryData = (dataset) => dataset.map((data) => {
        return {
            id: data.id,
            username: data.username,
            password: data.password,
            mitra: data.mitra,
            router: data.routerName,
            rateUp: `${data.rateUp} ${data.rateUpMeasurement}`,
            rateDown: `${data.rateDown} ${data.rateDownMeasurement}`,
            status: customTextFromBoolean(data.isPaid, 'Lunas', 'Belum-Bayar'),
            actions: (
                <div className="actions-right" key={data.id}>
                <Button
                  onClick={ async () => {
                    await getVoucherHistoryData(data.id)
                  }}
                  className="btn-icon btn-sm"
                  id={`tooltip${data.id}`}
                  color="warning"
                  size="sm">
                  <i className="fa fa-edit" />
                </Button>
              </div>
            ),
        }
    })

    const voucherHistoryMenu = (
        <p></p>
    )

    const [voucherHistoryState, setVoucherHistoryState] = useState(mapHistoryData(voucherHistoryData))

    useState(() => {
        getyear(owner.mitraid)
        getUserReseller(owner.mitraid)
        FetchReportData(owner.mitraid, year.value, reseller.userId)
        fetchVoucherHistory(owner.mitraid, year.value, reseller.userId)
        // getMonth(name, year.value)
    }, [])

    const children = (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card className="shadow-lg">
                        <CardHeader>
                            <h5 className="title">Laporan Keuangan Reseller - {reseller.value} Tahun {year.value}</h5>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col className="md-4">
                                    <SelectBasic
                                        key = {'1wedwe'}
                                        label = {'Mitra'}
                                        isDisabled = {!mitraSuper}
                                        value = {owner}
                                        option = {ownerData}
                                        onChange = {async (e) => {
                                            setOwner(e)
                                            await FetchReportData(e.mitraid, year.value, reseller.userId)
                                            await fetchVoucherHistory(e.mitraid, year.value, reseller.userId)
                                            await getUserReseller(e.mitraid)
                                            await getyear(e.mitraid)
                                        }}
                                    />
                                </Col>
                                <Col className="md-4">
                                    <SelectBasic
                                        key = {'2wdewe'}
                                        label = {'Pilih Tahun'}
                                        value = {year}
                                        onChange = {async (e) => {
                                            yearOnChange(e)
                                            await FetchReportData(owner.mitraid, e.value, reseller.userId)
                                            await fetchVoucherHistory(owner.mitraid, e.value, reseller.userId)
                                        }}
                                        option = {yearOption}
                                    />
                                </Col>
                                <Col className="md-4">
                                    <SelectBasic
                                        key = {'3wedwe'}
                                        label = {'User Reseller'}
                                        value = {reseller}
                                        isDisabled = {!mitraSuper && !userAdmin}
                                        onChange = {async (e) => {
                                            resellerOnChange(e)
                                            await FetchReportData(owner.mitraid, year.value, e.userId)
                                            await fetchVoucherHistory(owner.mitraid, year.value, e.userId)
                                        }}
                                        option = {resellerOption}
                                    />
                                </Col>
                            </Row>
                            <DashboardChart
                                key = {'4wedwe'}
                                data = {chartData}
                                size = {'md'}
                                legend = {false}
                                transparantPanel = {true}
                                chartLine1Label = {'Revenue'}
                                chartLine2Label = {'Profit'}
                                // backgroundColor = "#595959"
                                // gridColor = "rgb(115, 115, 115)"
                                // tickColor = "rgb(115, 115, 115)"
                                backgroundColor = "rgb(115, 115, 115, 0.6)"
                                gridColor = "rgb(115, 115, 115, 0.8)"
                                tickColor = "rgb(115, 115, 115, 0.6)"
                            />
                            <HostpotCardStats
                                key = {'6wdwe'}
                                title = {''}
                                size = {'4'}
                                data = {data || {}}
                                currency = {currency}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <CommonList
                key = {'6qwdbwejh'}
                header = {'History Transaksi Hostpot'}
                load = {loadHistory}
                dataState = {voucherHistoryState}
                columns = {ColumnsHistory}
                menu = {voucherHistoryMenu}
            />
        </div>
    )
    return (
        <>
            <PanelHeader size="sm" />
            <LoadingScreen
                key = {'12wed'}
                children={children}
                loading = {loading}
                text={"Loading..."}/>
        </>
    );
}


export default HostpotFinanceStatement;
