import { deleteDataLocal, getDataLocal, setDataLocal } from "./CoreWebStorage"

const identifier = ['AddBlob',
'TransferWarehouseToCustomerBlob',
'TransferCustomerToWarehouseBlob',
'TransferWarehouseToWarehouseBlob']
const [add, wTh, cTh, wTw] = identifier

export function setAddBlob(payload, userId) {
    const key = add + userId
    setDataLocal(key, payload)
}

export function getAddBlob(userId) {
    const key = add + userId
    return getDataLocal(key)
}

export function setWarehouseToCustomerBlob(payload, userId) {
    const key = wTh + userId
    setDataLocal(key, payload)
}

export function getWarehouseToCustomerBlob(userId) {
    const key = wTh + userId
    return getDataLocal(key)
}

export function setCustomerToWarehouseBlob(payload, userId) {
    const key = cTh + userId
    setDataLocal(key, payload)
}

export function getCustomerToWarehouseBlob(userId) {
    const key = cTh + userId
    return getDataLocal(key)
}

export function setWarehouseToWarehouseBlob(payload, userId) {
    const key = wTw + userId
    setDataLocal(key, payload)
}

export function getWarehouseToWarehouseBlob(userId) {
    const key = wTw + userId
    return getDataLocal(key)
}

export function deleteAddBlob(userId) {
    const key = add + userId
    return deleteDataLocal(key)
}

export function deleteWarehouseToCustomerBlob(userId) {
    const key = wTh + userId
    return deleteDataLocal(key)
}
export function deleteCustomerToWarehouseBlob(userId) {
    const key = cTh + userId
    return deleteDataLocal(key)
}
export function deleteWarehouseToWarehouseBlob(userId) {
    const key = wTw + userId
    return deleteDataLocal(key)
}