import React from "react"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup
} from "reactstrap"

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js"
import BillingService from "Main/BillingService"
import { useState, useEffect } from "react"
import { CatchError } from "Main/Exceptions/exceptions"
import LoadingScreen from "components/Loader/LoadingScreen"
import SelecttWithUserTier from "components/Input/SelectWithUserTier"
import SelectBasic from "components/Input/SelectBasic"
import { getExportDataType } from "variables/FinanceStatement/FinanceVariable"
import {
  getRefreshToken,
  getOwner,
  getUserData,
  InvoiceExportPayload
} from "variables/common"

function ExportInvoice(props) {
  const billingService =  new BillingService()
  const refreshToken = getRefreshToken()
  const ownerData = getOwner()
  const userData = getUserData()
  // const bankOptionMaster = getBankSelect()
  const bankOptionDefault = [{label: 'ALL', value: '*'}]
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const [period, periodOnChange] = useState([])
  const [bank, setBank] = useState({})
  const [fileType, setFileType] = useState({})
  const [loading, setLoading] = useState(false)
  const fileTypeOption = getExportDataType()
  const [periodOption, setPeriodOption] = useState([])
  const [changeMitra, setChangeMitra] = useState()
  const bankOption = bankOptionDefault.concat([
    { label: 'BNI', value: 'BNI' },
    { label: 'Mandiri', value: 'Mandiri' },
    { label: 'BRI', value: 'BRI' }
  ])

  const fetchPeriod =  async(mitra) => {
    try {
      const dat =  await billingService.getInvoiceListPeriod(mitra.mitraid)
      const result = dat.data
      setPeriodOption(result)
      return result
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }


  useEffect(() => {
      fetchPeriod(owner)
  }, [])

  const ExportInvoiceData = async() => {
    try {
      setLoading(true)
      const payload = InvoiceExportPayload(owner.mitraid, period.value, bank.value, fileType.value)
      const result = await billingService.downloadInvoice(payload)
      setLoading(false)
      return result
    } catch (error) {
      setLoading(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  const ExportInvoiceMutation = async() => {
    try {
      setLoading(true)
      const result = await billingService.downloadInvoiceMutation(owner.mitraid, period.value, fileType.value)
      setLoading(false)
      return result
    } catch (error) {
      setLoading(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  async function FetchInvoicePeriod (mitra) {
    setBank({})
    periodOnChange({})
    return await fetchPeriod(mitra)
  }

  const children = (
    <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Export Tagihan Invoice</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="10">
                      <FormGroup>
                        <SelecttWithUserTier
                          key={"1"}
                          label = {'Mitra *Wajib di Isi'}
                          value = {owner}
                          onChange = {ownerOnChange}
                          disabled = {changeMitra}
                          options = {ownerData}
                          subFunction = {FetchInvoicePeriod}
                          // subFunction = {deleteSelect}
                          checkText = {'Ubah Mitra'}
                          checkValue = {changeMitra}
                          checkValueOnChange = {setChangeMitra}
                        />
                        <SelectBasic
                          key={"4"}
                          label = {'Periode'}
                          value = {period}
                          option = {periodOption}
                          onChange = {periodOnChange}
                        />
                        <div>
                          <SelectBasic
                            key={"2"}
                            label = {'Tipe File *Wajib di Isi'}
                            value = {fileType}
                            option = {fileTypeOption}
                            onChange = {setFileType}
                          />
                          <Button
                            onClick = {ExportInvoiceMutation}
                            disabled = {!fileType.value}
                            color="success"
                          >Export Mutasi Pembayaran Invoice {fileType.value}</Button>
                        </div>
                        <SelectBasic
                          key={"3"}
                          label = {'Bank'}
                          value = {bank}
                          option = {bankOption}
                          onChange = {setBank}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                </Form>
                <Button
                  onClick = {ExportInvoiceData}
                  disabled = {!fileType.value}
                  color="info"
                >Export Invoice {bank.value} {fileType.value}</Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
  )

  return (
    <>
      <PanelHeader size="sm" />
      <LoadingScreen
        children={children}
        loading = {loading}
        text={`Export Invoice ${fileType.value}`}/>
    </>
  );
}


export default ExportInvoice;
