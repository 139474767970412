import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken,
  getLogisticGoodsData,
  WarehouseToWarehouse,
  addLogisticGoodsData,
  getOwner} from "./../../../../variables/common"
import { CatchError } from "Main/Exceptions/exceptions";
import { getLogisticWarehouseOption } from "variables/common";
import { GetItemCurrency } from "variables/common";
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService";
import swal from "sweetalert";
import TransferWarehouseToWarehouseBody from "./Body/TransferWarehouseToWarehouse";
import { FetchLogisticSelect } from "Main/Logistic/FetchLogistic";
import { useEffect } from "react";
import BasicInput from "components/Input/BasicInput";

function TransferWarehouseToWarehouse(props) {
  const Service = new LogisticGoodsService()
  const refreshToken = getRefreshToken();
  const logisticData = getLogisticGoodsData()
  const name = logisticData.name
  const description = logisticData.description
  var selectCurrencyOptions = GetItemCurrency()
  var [warehouseOptions, setwarehouseOptions] = useState(getLogisticWarehouseOption())
  const warehouseId = logisticData.warehouseId
  const warehouseSrcOption  = warehouseOptions.filter((data) => data.value === warehouseId)
  const warehouseFilterOptions = warehouseOptions.filter((data) => data.value !== warehouseId)
  const idLogistic = logisticData.id
  const qtyState = logisticData.qty
  // const [logisticItem, logisticItemOnChange] = useState(logisticItemOptions.find((data) => data.value === itemId))
  const [warehouse, warehouseOnChange] = useState(warehouseOptions.find((data) => data.value === warehouseId))
  const [warehouseTo, warehouseToOnChange] = useState('')
  const priceValue = logisticData.price
  const [qty, qtyOnChange] = useState(1)
  const currency  = selectCurrencyOptions.find(data => data.label === 'Rupiah')
  const [insertDate, insertDateOnChange] = useState()
  const ownerOption = getOwner()
  const owner = logisticData.owner
  const mitra = ownerOption.find((data) => data.value === owner)

  useEffect(() => {
    FetchSelectData(mitra.mitraid)
  }, [])

  const ViewLogisticGoodsData = async (id) => {
    try {
      const result = await Service.get(id)
      addLogisticGoodsData(result.data)
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const submit = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = WarehouseToWarehouse(warehouseTo.value, idLogistic, qty, insertDate)
      const result = await Service.transferWarehouseToWarehouse(payload)
      await ViewLogisticGoodsData(idLogistic)
      swal("Success", result.message, "success").then(() => {
        props.history.push('/admin/logistic-goods-update')
      })
    } catch (error) {
      return CatchError(error, props, refreshToken)
    }
  }

  const FetchSelectData = async (mitra) => {
  const { logisticWarehouse } = await FetchLogisticSelect(mitra)
    setwarehouseOptions(logisticWarehouse)
    warehouseOnChange(logisticWarehouse.find((data) => data.value === warehouseId))
    qtyOnChange(1)
    insertDateOnChange(null)
  }
  // const AddMany = () => {
  //   props.history.push('/admin/logistic-warehouse-to-warehouse-blob')
  // }

  return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Transfer Antar Gudang</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <BasicInput
                    key={1}
                      label = {'Mitra'}
                      value = {mitra.label}
                    />
                    <TransferWarehouseToWarehouseBody
                      key={2}
                      name = {name}
                      warehouse = { warehouse}
                      warehouseFilterOptions = { warehouseFilterOptions}
                      warehouseOnChange = {warehouseOnChange}
                      warehouseSrcOption = {warehouseSrcOption}
                      warehouseTo = {warehouseTo}
                      warehouseToOnChange = {warehouseToOnChange}
                      qty = {qty}
                      qtyState = {qtyState}
                      qtyOnChange = {qtyOnChange}
                      priceValue = {priceValue}
                      currency = { currency}
                      insertDate = {insertDate}
                      insertDateOnChange = {insertDateOnChange}
                      description = {description}
                    />
                  <Button onClick = {submit} color="info">Submit</Button>
                  {/* <Button onClick = {AddMany} color="info">Send Many</Button> */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default TransferWarehouseToWarehouse;


