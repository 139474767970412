const {
    FormGroup,
    Label,
    Input } = require("reactstrap");

function Checklist ({value, checked, onChange, text, disabled, classLabel = ''}) {
    return (
        <FormGroup check>
            <Label className={classLabel} check> {text || 'Check'}
                <Input type="checkbox"
                        value = {value}
                        checked = {checked}
                        onChange = {onChange}
                        disabled = {disabled}
                />
                <span className="form-check-sign" />
            </Label>
            </FormGroup>
    )
}

export default Checklist