import React, { useEffect } from "react";
import LoadingScreen from "components/Loader/LoadingScreen";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Select from 'react-select'
import { useState } from "react";
import {getRefreshToken, getOwner,
  addAddressOptions, addCustomerOptions,
  addItemOptions,
  addBillingStatus, getRouterSelect } from "variables/common"
import swal from "sweetalert";
import CustomerService from "Main/CustomerService";
import { CatchError } from "Main/Exceptions/exceptions";
import RouterService from "Main/RouterService";
import BasicInput from "components/Input/BasicInput";
import { getBillingData } from "variables/common";
import { payload } from "variables/Add-On/SubscriptionAddOn";
import SubscriptionAddOnService from "Main/Add-On/SubscriptionAddOnService";

function SubscriptionAddOnPage(props) {
  const subscriptionAddOnService = new SubscriptionAddOnService()
  const customerService = new CustomerService()
  const routerService = new RouterService()
  const refreshToken = getRefreshToken()
  const ownerData = getOwner()

  const BillingData = getBillingData()
  const networkId = BillingData.Subscription.networkId
  const name = BillingData.Subscription.subscription
  const owner = ownerData.find((own) => own.value === BillingData.Subscription.owner)
  const customerName = BillingData.Subscription.customerName
  const customer = BillingData.customer || {}
  const [routerSelectOption, routerSelectOptionOnChange] = useState(getRouterSelect())
  const [itemAddOn , itemAddOnOnChange] = useState('')
  const [loading, loadingOnChange] = useState(false)
  const [selectItemOptions, selectItemOptionsOnChange] = useState([])
  const [router, routerOnChange] = useState('')



  const getRouter = async(mitraid) => {
    const dat = await routerService.getRouterSelect(mitraid)
    const result = dat.data
    routerSelectOptionOnChange(result)
    return result
  }

  const fetchData =  async() => {
    const itemOption  = await customerService.getItemAddOnSelect()
    const statusOption = await customerService.getBillingStatusSelect()
    const customerOption = await customerService.getCustomerSelect()
    const addressOption = await customerService.getAddressSelect()
    addItemOptions(itemOption.data)
    addBillingStatus(statusOption.data)
    addAddressOptions(addressOption.data)
    addCustomerOptions(customerOption.data)
    await getRouter(owner.mitraid)
  }

  const getItemAddOn = async(routerid, mitraid, category) => {
    const dat = await customerService.getItemAddOnSelect(routerid, mitraid, category)
    const result = dat.data
    selectItemOptionsOnChange(result)
    return result
  }

  useEffect(() => {
    fetchData()
  }, [])

  const addSubscriptionAddOnData = async() => {
  try {
    loadingOnChange(true)
    const Payload = payload(owner.mitraid, networkId, itemAddOn.value)
    const result = await subscriptionAddOnService.addData(Payload)
    await fetchData()
    loadingOnChange(false)
    swal(result.status, result.message, "success").then(() => {
      props.history.push('/admin/Billing-update')
    })
  } catch (error) {
    loadingOnChange(false)
    return await CatchError(error, props, refreshToken)
  }}

  function deleteSelect () {
    itemAddOnOnChange('')
    selectItemOptionsOnChange([])
  }

  const children = (<div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Generate Customer Subscription</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <BasicInput
                          label = {"Network ID"}
                          value = {networkId}
                        />
                        <BasicInput
                          label = {"Subscription"}
                          value = {name}
                        />
                        <BasicInput
                          label = {"Pelanggan"}
                          value = {customerName}
                        />
                        <Label>Lokasi Router</Label>
                            <div>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Pilih Lokasi Router"
                              name="singleSelect"
                              value = {router}
                              options={routerSelectOption}
                              onChange={async(value) => {
                                routerOnChange(value)
                                deleteSelect()
                                await getItemAddOn(value.value, owner.mitraid, customer.category)
                                itemAddOnOnChange('')
                                }
                              }
                            />
                            {!router.status ? (
                              <label className="error">
                                Router Offline
                              </label>
                            ) : <label className="success">
                                Router Online
                              </label>}
                          </div>
                          <Label>Produk Add-On</Label>
                            <Select
                                // className="react-select"
                                // classNamePrefix="react-select"
                                placeholder="Add-On"
                                name="singleSelect"
                                value = {itemAddOn}
                                options={selectItemOptions}
                                onChange={(value) => {
                                  itemAddOnOnChange(value)
                                }}
                            />
                        </FormGroup>
                      </Col>
                      </Row>
                  </Form>
                </CardBody>
              </Card>
            <Button onClick = {addSubscriptionAddOnData}
            color="info">Submit</Button>
          </Col>
        </Row>
      </div>
    )

  return (
    <>
      <PanelHeader size="sm" />
      <LoadingScreen children={children}
      loading = {loading}/>
    </>
  );
}

export default SubscriptionAddOnPage;
