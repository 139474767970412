import React from "react";
import Select from "react-select"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken,
        addVirtualAccountList,
        getRouterSelected,
        OutdoorDevice,
        outdoorDeviceTypeOption} from "./../../../../variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions"
import RouterService from "Main/RouterService";
import InputCoordinate from "components/Input/InputCoordinate";
function OutdoorDeviceInventoryPage(props) {
  const routerService = new RouterService()
  const refreshToken = getRefreshToken()
  const router = getRouterSelected()
  const DeviceTypeOption = outdoorDeviceTypeOption()
  const [name, nameOnChange ] = useState('')
  const [deviceType, deviceTypeOnChange] = useState('')
  const [description, descriptionOnChange] = useState('')
  const [latitude, setLatitude] = useState(0.0)
  const [longitude, setLongitude] = useState(0.0)

  const submit = async() => {
    try {
      const payload = OutdoorDevice(name, router.id, deviceType.value, latitude, longitude, description)
      const result = await routerService.addOutdoorDevice(payload)
      swal(result.status, result.message, "success").then(async () => {
        const dat =  await routerService.getAllOutdoorDevice(router.id)
        const result = dat.data
        addVirtualAccountList(result)
        props.history.push('/admin/router-update')
      })
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Form Perangkat Outdooor</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <Label>Nama</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Nama"
                            name="singleSelect"
                            value = {name}
                            onChange={(e) => { nameOnChange(e.target.value) }
                            }
                          />
                        <InputCoordinate
                          latitude = {latitude}
                          setLatitude = {setLatitude}
                          longitude = {longitude}
                          setLongitude = {setLongitude}
                        />
                        <Label>Jenis Perangkat</Label>
                            <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Jenis Perangkat"
                            name="singleSelect"
                            value = {deviceType}
                            options = {DeviceTypeOption}
                            onChange={ async (e) => {
                              deviceTypeOnChange(e)
                            }
                            }
                          />
                       <Label>Deskripsi</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Deskripsi"
                            name="singleSelect"
                            value = {description}
                            onChange = {(e) => descriptionOnChange(e.target.value)}
                          />
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {submit} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default OutdoorDeviceInventoryPage;
