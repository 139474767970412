import React, { useEffect } from "react";
import LoadingScreen from "components/Loader/LoadingScreen";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken,  getUserData, getOwner, PriceFormat, getRouterSelect } from "variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions";
import BasicInput from "components/Input/BasicInput";
import SelecttWithUserTier from "components/Input/SelectWithUserTier";
import SelectBasic from "components/Input/SelectBasic";
import Checklist from "components/Button/Checklist";
import { payloadHostpotProfile } from "variables/HostpotVoucher/HostpotProfile";
import Select from 'react-select';
import RouterService from "Main/RouterService";
import { gethostpotProfileTimeMeasurementData } from "variables/HostpotVoucher/HostpotProfile";
import { gethostpotProfileInternetMeasurementData } from "variables/HostpotVoucher/HostpotProfile";
import HostpotProfileService from "Main/HostpotVoucher/HostpotProfileService";

function HostpotProfilePage(props) {
  const hostpotProfileService =  new HostpotProfileService()
  const routerService = new RouterService()
  const refreshToken = getRefreshToken();
  const userData = getUserData()
  const ownerData = getOwner()
  const timeMeasurement = gethostpotProfileTimeMeasurementData()
  const internetMeasurement = gethostpotProfileInternetMeasurementData()
  const [routerSelect, setRouterSelect] = useState(getRouterSelect())
  const [poolSelect, setPoolSelect] = useState([])
  const [mitra, mitraOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const [loading, loadingOnChange ] = useState(false)
  const [changeMitra, changeMitraOnChange] = useState(false)
  const [name, setName] = useState('')
  const [buyingPrice, setBuyingPrice] = useState(0)
  const [sellingPrice, setSellingPrice] = useState(0)
  const [pool, setPool] = useState('')
  const [ip, setIp] = useState('0.0.0.0')
  const [subnet, setSubnet] = useState(24)
  const [sharedUser, setSharedUser] = useState(1)
  const [rateUp, setRateUp] = useState(0)
  const [rateDown, setRateDown] = useState(0)
  const [priority, setPriority] = useState(1)
  const [rateUpMeasurement, setRateUpMeasurement] = useState(internetMeasurement[1])
  const [rateDownMeasurement, setRateDownMeasurement] = useState(internetMeasurement[1])
  const [lockMac, setLockMac] = useState(false)
  const [timeLimit, setTimeLimit] = useState(0)
  const [quotaLimit, setQuotaLimit] = useState(0)
  const [timeLimitMeasurement, setTimeLimitMeasurement] = useState(timeMeasurement[2])
  const [quotaLimitMeasurement, setQuotaLimitMeasurement] = useState(internetMeasurement[1])
  const [description, setDescription ] = useState('')
  const [router, setRouter] = useState('')
  const [useProfilePPPlist, setUseProfilePPPlist] = useState(false)
  const [RouterpppProfile, SetRouterpppProfile] = useState({})

  const getRouterPPP =  async(routerId) => {
    const result =  await routerService.getpppProfileSelect(routerId)
    setPoolSelect(result.data)
    return result.data
  }

  const getRouter = async(mitraid) => {
    const dat = await routerService.getRouterSelect(mitraid)
    const result = dat.data
    setRouterSelect(result)
    return result
  }

  useEffect(() => {
    getRouter(mitra.mitraid)
  }, [])

  const submit = async(e) => {
    e.preventDefault()
    try {
      loadingOnChange(true)
      const payload = payloadHostpotProfile(mitra.mitraid, router.value, name, buyingPrice, sellingPrice, pool, ip, subnet, sharedUser, rateUp, rateDown, priority, rateUpMeasurement.value, rateDownMeasurement.value, lockMac, timeLimit, quotaLimit, timeLimitMeasurement.value, quotaLimitMeasurement.value, description)
      const result = await hostpotProfileService.post(payload)
      await swal(result.status, result.message, "success")
      loadingOnChange(false)
      props.history.push('/admin/hostpot-profile-list')
    } catch (error) {
      loadingOnChange(false)
      return await CatchError(error, props, refreshToken)
  }}

  const children = (
    <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Form Hostpot Profile</h5>
                </CardHeader>
                <CardBody>
                  <SelecttWithUserTier
                    key={"1"}
                    label = {'Mitra'}
                    value = {mitra}
                    onChange = {async (e) => {
                      await getRouter(e.mitraid)
                      mitraOnChange(e)
                    }}
                    disabled = {changeMitra}
                    options = {ownerData}
                    checkText = {'Change Mitra'}
                    checkValue = {changeMitra}
                    checkValueOnChange = {changeMitraOnChange}
                  />
                  <BasicInput
                    key={"2"}
                    label = {'name'}
                    value = {name}
                    onChange = {setName}
                    type
                  />
                  <Label>Lokasi Router</Label>
                    <div>
                      <Select
                        placeholder="Lokasi Router"
                        name="singleSelect"
                        value = {router}
                        options={routerSelect}
                        onChange={async (e) => {
                          setRouter(e)
                          await getRouterPPP(e.value)
                        }}
                      />
                      {!router.status ? (
                          <label className="error">
                            Router Offline
                          </label>
                        ) : <label className="success">
                            Router Online
                      </label>}
                  </div>
                  <Row>
                    <Col>
                      <BasicInput
                        key={"3"}
                        label = {'Harga Beli'}
                        value = {buyingPrice}
                        onChange = {(e) => {
                          if (e >= 0) {
                            setBuyingPrice(e)
                          }
                        }}
                        type = {'number'}
                      />
                    </Col>
                    <Col>
                      <BasicInput
                        key={"4"}
                        label = {'Price Display'}
                        value = {PriceFormat(buyingPrice, 'idr')}
                        disabled
                        onChange = {() => {}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <BasicInput
                        key={"5"}
                        label = {'Harga Jual'}
                        value = {sellingPrice}
                        onChange = {(e) => {
                          if (e >= 0) {
                            setSellingPrice(e)
                          }
                        }}
                        type = {'number'}
                      />
                    </Col>
                    <Col>
                      <BasicInput
                        key={"6"}
                        label = {'Price Display'}
                        value = {PriceFormat(sellingPrice, 'idr')}
                        disabled
                        onChange = {() => {}}
                      />
                    </Col>
                  </Row>
                  {!useProfilePPPlist ? (
                      <>
                         <BasicInput
                           key={"8"}
                           label = {'Nama Pool'}
                           value = {pool}
                           onChange = {setPool}
                         />
                      </>
                    ) : (
                      <>
                      <Label>Pool List</Label>
                      <SelectBasic
                        key={"9"}
                        label={" Select PPP From Router"}
                        value={RouterpppProfile}
                        option={poolSelect}
                        onChange={(e) => {
                          SetRouterpppProfile(e)
                          setPool(e.value)
                          setIp(e.ip)
                          setSubnet(Number(e.subnet))
                          }} />
                      </>
                    )
                  }
                  <BasicInput
                    key={"8"}
                    label = {'IP Address'}
                    value = {ip}
                    onChange = {setIp}
                  />
                  <BasicInput
                    key={"9"}
                    label = {'Subnet'}
                    value = {subnet}
                    onChange = {(e) => {
                        if (e >= 0 && e <= 32) {
                          setSubnet(e)
                        }
                      }
                    }
                    type = {'number'}
                  />
                  <Checklist
                    key={"9"}
                    text = {"Memakai Pool Router"}
                    value = {useProfilePPPlist}
                    onChange = { async (e) => {
                      setUseProfilePPPlist(e.target.checked)
                      setPool('')
                      setIp('0.0.0.0')
                      setSubnet(24)
                      if (!e.target.checked) {
                        SetRouterpppProfile([])
                        return e
                      }
                      await getRouterPPP(router.value)
                    }}
                  />
                  <Row>
                    <Col>
                      <BasicInput
                        key={"10"}
                        label = {'Kecepatan Upload'}
                        value = {rateUp}
                        onChange = {(e) => {
                          if (e >= 0) {
                            setRateUp(e)
                          }
                        }
                        }
                        type = {'number'}
                      />
                    </Col>
                    <Col>
                      <SelectBasic
                        key={"11"}
                        label = {'Upload Measurement'}
                        value = {rateUpMeasurement}
                        onChange = {setRateUpMeasurement}
                        option = {internetMeasurement}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <BasicInput
                        key={"12"}
                        label = {'Kecepatan Download'}
                        value = {rateDown}
                        onChange = {(e) => {
                          if (e >= 0) {
                            setRateDown(e)
                          }
                        }}
                        type = {'number'}
                      />
                    </Col>
                    <Col>
                      <SelectBasic
                        key={"13"}
                        label = {'Download Measurement'}
                        value = {rateDownMeasurement}
                        onChange = {setRateDownMeasurement}
                        option = {internetMeasurement}
                      />
                    </Col>
                  </Row>
                  <BasicInput
                    key={"14"}
                    label = {'Priority'}
                    value = {priority}
                    onChange = {(e) => {
                      if (e > 0 && e <= 10) {
                        setPriority(e)
                      }
                    }}
                    type = {'number'}
                  />
                  <BasicInput
                    key={"15"}
                    label = {'Shared User'}
                    value = {sharedUser}
                    onChange = {(e) => {
                      if (e >= 0) {
                        setSharedUser(e)
                      }
                    }}
                    type = {'number'}
                  />
                  <Row>
                    <Col>
                      <BasicInput
                        key={"16"}
                        label = {'Limit Quota'}
                        value = {quotaLimit}
                        onChange = {(e) => {
                          if (e >= 0) {
                            setQuotaLimit(e)
                          }
                        }}
                        type = {'number'}
                      />
                    </Col>
                    <Col>
                      <SelectBasic
                        key={"17"}
                        label = {'Quota Measurement'}
                        value = {quotaLimitMeasurement}
                        onChange = {setQuotaLimitMeasurement}
                        option = {internetMeasurement}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <BasicInput
                        key={"18"}
                        label = {'Limit Waktu'}
                        value = {timeLimit}
                        onChange = {(e) => {
                          if (e >= 0) {
                            setTimeLimit(e)
                          }
                        }}
                        type = {'number'}
                      />
                    </Col>
                    <Col>
                      <SelectBasic
                        key={"19"}
                        label = {'Time Measurement'}
                        value = {timeLimitMeasurement}
                        onChange = {setTimeLimitMeasurement}
                        option = {timeMeasurement}
                      />
                    </Col>
                  </Row>
                  <Checklist
                    key = {"20"}
                    text = {'Lock MAC Address'}
                    value = {lockMac}
                    onChange = {(e) => {
                      setLockMac(e.target.checked)
                    }}
                  />
                  <BasicInput
                    key={"21"}
                    label = {'Deskripsi'}
                    value = {description}
                    onChange = {setDescription}
                    type = {'textarea'}
                  />
                  <Button
                    key={"22"}
                    onClick = {submit}
                    disabled = {!name}
                    color="info"
                  >Submit</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
  )
  return (
    <>
      <PanelHeader size="sm" />
      <LoadingScreen
        children={children}
        loading = {loading}
        text={"Submit"}/>
    </>
  );
}


export default HostpotProfilePage;
