import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  UncontrolledTooltip,
  CardTitle,
  FormGroup,
  Label,
  Table
} from "reactstrap";

// core components
import CustomerService from "Main/CustomerService";
import {getRefreshToken, ResoureGroup,
  getCustomerGroupRaw, getAddressTypeRaw, GetItemCategoryRaw,
  GetItemMeasurementRaw, GetItemCurrencyRaw, getStatusOptionsRaw,
  ResoureBasic, ResourceSymbol, addCustomerGroup, addCustomerGroupRaw,
  addItemCategory, addItemCategoryRaw, addAddressType, addAddressTypeRaw,
  addItemMeasurement, addItemMeasurementRaw, addItemCurrency, addItemCurrencyRaw,
  addStatusOptions, addStatusOptionsRaw, getBankSelectRaw, addBankSelect,
  addBankSelectRaw, getMitra, MitraGenerate, addoutdoorDeviceOption,
  addoutdoorDeviceOptionRaw, outdoorDeviceTypeOptionRaw, indoorDeviceTypeOptionRaw,
  addIndoorDeviceOption, addIndoorDeviceOptionRaw, addLogisticSupplierOption,
  addLogisticTypenRaw, getLogisticTypenRaw, addCustomerList, addItemList, PriceFormat} from "./../../../variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions"
import UserService from "Main/UserService";
import UserUpdatePages from "./../User/UserUpdate";
import { BinnaryUpload } from "Main/Utils";
import { StatusDisplay } from "Main/Utils";
import DynamicTable from "components/ReactTable/DynamicTable";
import { csvReferences } from "variables/referenceImport";
import LogisticType from "./LogisticTypeForm";
import LoadingScreen from "components/Loader/LoadingScreen";
import { verifySuperAdmin } from "Main/Utils";
import Checklist from "components/Button/Checklist";
import ItemService from "Main/ItemService";
import { csvItemReferences } from "variables/referenceImport";
import DateIncrementInput from "components/Input/DateIncrementInput";
import BasicInput from "components/Input/BasicInput";
import { DateTimestr } from "Main/Utils";

function Resource(props) {
  const currency = 'idr'
  const [loading, loadingOnChange] = useState(false)
  const [dataGroupLoad, setGroupDataLoad] = useState(true)
  const [CategorydataLoad, setCategoryDataLoad] = useState(true)
  const [AddressTypedataLoad, setAddressTypeDataLoad] = useState(true)
  const [MeasurementdataLoad, setMeasurementDataLoad] = useState(true)
  const [CurrencydataLoad, setCurrencyDataLoad] = useState(true)
  const [StatusdataLoad, setStatusDataLoad] = useState(true)
  const [bankDataLoad, setBankDataLoad] = useState(true)
  const [indoorDevicedataLoad, setIndoorDeviceDataLoad] = useState(true)
  const [outdoorDevicedataLoad, setOutdoorDeviceDataLoad] = useState(true)
  const [LogisticTypeDataLoad, setLogisticTypeDataLoad] = useState(true)
  const customerService =  new CustomerService()
  const userService = new UserService()
  const refreshToken = getRefreshToken();
  const itemService = new ItemService()
  const [groupName, groupNameOnChange] =  useState('')
  const [groupMin, groupMinOnChange] =  useState('')
  const [groupMax, groupMaxOnChange] =  useState('')
  const [categoryName, categoryNameOnChange] =  useState('')
  const [addressTypeName, addressTypeNameOnChange] = useState('')
  const [measurementName, measurementNameOnChange] = useState('')
  const [measurementSymbol, measurementSymbolOnChange] = useState('')
  const [currencyName, currencyNameOnChange] = useState('')
  const [currencySymbol, currencySymbolOnChange ] = useState('')
  const [statusName, statusNameOnChange] =  useState('')
  const [bankName, bankNameOnChange] =  useState('')
  const [indoorDevice, indoorDeviceOnChange] =  useState('')
  const [outdoorDevice, outdoorDeviceOnChange] =  useState('')
  const [logisticType, logisticTypeOnChange] =  useState('')
  const [CustomerGroupList, CustomerGroupListOnChange] = React.useState(getCustomerGroupRaw());
  const [itemCategoryList, ItemCategoryOnChange] = React.useState(GetItemCategoryRaw());
  const [AddressTypeList, addressTypeOnChange] = React.useState(getAddressTypeRaw());
  const [ItemMeasurementList, ItemMeasurementListOnChange] = React.useState(GetItemMeasurementRaw());
  const [ItemCurrencyList, ItemCurrencyListOnChange] = useState(GetItemCurrencyRaw())
  const [StatusList, StatusListOnChange] = useState(getStatusOptionsRaw())
  const [BankList, BankListOnChange] = useState(getBankSelectRaw())
  const [IndoorDeviceList, IndoorDeviceListOnChange] = useState(indoorDeviceTypeOptionRaw())
  const [OutdoorDeviceList, OutdoorDeviceListOnChange] = useState(outdoorDeviceTypeOptionRaw())
  const [LogisticTypeList, LogisticTypeListOnChange] = useState(getLogisticTypenRaw())
  const mitraData = getMitra()
  const mitraId = mitraData.id
  const len = 'ALL'
  const [isGenerate, isGenerateOnChage] = useState(mitraData.generateInvoice || false)
  const [dynamicInvoice, dynamicInvoiceOnChage] = useState(mitraData.dynamicInvoiceDate)
  const [generateDate, setGenerateDate] = useState(mitraData.generateTime)
  const [due, dueOnChange] = useState(mitraData.dueDate)
  const [ppn, ppnOnChange] = useState(mitraData.ppn)
  const [beforeDue, beforeDueOnChange] = useState(mitraData.beforeDue)
  const [singleFileName, setSingleFileName] = React.useState("");
  const [singleFile, setSingleFile] = React.useState(null);
  const [singleFileItemName, setSingleFileItemName] = React.useState("");
  const [singleFileItem, setSingleFileItem] = React.useState(null);
  const [importStatus, importStatusOnChange] = useState([])
  const [importItemStatus, importItemStatusOnChange] = useState([])
  const singleFileRef = React.useRef()
  const singleFileItemRef = React.useRef()
  const { mitraSuper, userSuper, userAdmin, mitraAdmin } = verifySuperAdmin()
  const [administrationFee, setAdministrationFee] = useState(mitraData.administrationFee)
  const [maxMergeDate, setMaxMergeDate] = useState(mitraData.maxMergeDate)
  const [maxMergePrice, setMaxMergePrice] = useState(mitraData.maxMergePrice)
  const [instalationFee, setInstalationFee] = useState(mitraData.instalationFee)
  const [memberFee, setMemberFee] = useState(mitraData.memberFee)
  const [syncVa, setSyncVa] = useState(mitraData.syncVa)
  const [syncHostpot, setSyncHostpot] = useState(mitraData.syncHostpot)

  const handleSingleFileInput = (e) => {
    singleFileRef.current.click(e);
  }

  const addSingleFile = (e, type) => {
    importStatusOnChange([])
    let fileNames = "";
    let files = e.target.files;
    for (let i = 0; i < e.target.files.length; i++) {
      fileNames = fileNames + e.target.files[i].name;
    }
    setSingleFile(files);
    setSingleFileName(fileNames);
  }

   const handleSingleFileItemInput = (e) => {
    singleFileItemRef.current.click(e);
  }

  const addSingleFileItem = (e, type) => {
    importItemStatusOnChange([])
    let fileNames = "";
    let files = e.target.files;
    for (let i = 0; i < e.target.files.length; i++) {
      fileNames = fileNames + e.target.files[i].name;
    }
    setSingleFileItem(files);
    setSingleFileItemName(fileNames);
  }

  const Group = (async () => {
      try {
      const Group =  await customerService.getCustomerGroup(len)
      const group = Group.data
      addCustomerGroup(group)
      addCustomerGroupRaw(Group.raw)
      CustomerGroupListOnChange(Group.raw)
      setGroupDataLoad(false)
      return Group.raw
      } catch (error) {
        setGroupDataLoad(false)
        return await CatchError(error, props, refreshToken)
      }
  })

  const Category =  (async() => {
    try {
      const Category = await customerService.getItemCategory(len)
      const category = Category.data
      addItemCategory(category)
      addItemCategoryRaw(Category.raw)
      ItemCategoryOnChange(Category.raw)
      setCategoryDataLoad(false)
      return Category.raw
    } catch (error) {
      setCategoryDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  })

  const Addresstype = (async() => {
    try {
      const AddressType = await customerService.getAddressType(len)
      const addressType = AddressType.data
      addressTypeOnChange(AddressType.raw)
      addAddressType(addressType)
      addAddressTypeRaw(AddressType.raw)
      setAddressTypeDataLoad(false)
      return AddressType.raw
    } catch (error) {
      setAddressTypeDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  })

  const Measurement = (async() => {
    try {
      const Measurement = await customerService.getItemMeasurement(len)
      const measurement = Measurement.data
      addItemMeasurement(measurement)
      addItemMeasurementRaw(Measurement.raw)
      ItemMeasurementListOnChange(Measurement.raw)
      setMeasurementDataLoad(false)
      return Measurement.raw
    } catch (error) {
      setMeasurementDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  })

  const Currency = (async () => {
    try {
      const Currency = await customerService.getItemCurrency(len)
      const currency = Currency.data
      addItemCurrency(currency)
      addItemCurrencyRaw(Currency.raw)
      ItemCurrencyListOnChange(Currency.raw)
      setCurrencyDataLoad(false)
      return Currency.raw
    } catch (error) {
      setCurrencyDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  })

  const Status = (async() => {
    try {
      const Status = await customerService.getStatusSelect(len)
      const status = Status.data
      addStatusOptions(status)
      addStatusOptionsRaw(Status.raw)
      StatusListOnChange(Status.raw)
      setStatusDataLoad(false)
      return Status.raw
    } catch (error) {
      setStatusDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  })

  const Bank = (async() => {
    try {
      const Bank = await customerService.getBankSelect(len)
      const bank = Bank.data
      addBankSelect(bank)
      addBankSelectRaw(Bank.raw)
      BankListOnChange(Bank.raw)
      setBankDataLoad(false)
      return Bank.raw
    } catch (error) {
      setBankDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  })

  const IndoorDevice = (async() => {
    try {
      const Status = await customerService.getIndoorDeviceSelect()
      const device = Status.data
      addIndoorDeviceOption(device)
      addIndoorDeviceOptionRaw(Status.raw)
      IndoorDeviceListOnChange(Status.raw)
      setIndoorDeviceDataLoad(false)
      return Status.raw
    } catch (error) {
      setIndoorDeviceDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  })

  const OutdoorDevice = (async() => {
    try {
      const Status = await customerService.getOutdoorDeviceSelect()
      const device = Status.data
      // console.log(Status)
      addoutdoorDeviceOption(device)
      addoutdoorDeviceOptionRaw(Status.raw)
      OutdoorDeviceListOnChange(Status.raw)
      setOutdoorDeviceDataLoad(false)
      return Status.raw
    } catch (error) {
      setOutdoorDeviceDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  })

  const GetLogisticType = (async() => {
    try {
      setLogisticTypeDataLoad(true)
      const Type = await customerService.getLogisticTypeOption(len)
      const dataLogisticType = Type.data
      addLogisticSupplierOption(dataLogisticType)
      addLogisticTypenRaw(Type.raw)
      LogisticTypeListOnChange(Type.raw)
      setLogisticTypeDataLoad(false)
      return Type.raw
    } catch (error) {
      setLogisticTypeDataLoad(false)
      return await CatchError(error, props, refreshToken)
    }
  })

  useEffect(() => {
    Group()
    Addresstype()
    Measurement()
    Currency()
    Category()
    Status()
    Bank()
    IndoorDevice()
    OutdoorDevice()
    GetLogisticType()
  },[])

  const deleteCustomerGroup = async(name) => {
    try {
       swal("Delete", "Data Can't Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then( async (value) => {
      switch (value) {
        case 'del':
          try {
            const result = await customerService.deleteCustomerGroup(name)
            swal(result.status, result.message, 'success').then(() => {
              sessionStorage.removeItem('customerGroupRaw')
              setGroupDataLoad(true)
              Group()
            })
            return true
          } catch (error) {
            setGroupDataLoad(false)
            return await CatchError(error, props, refreshToken)
          }

        default:
          // getUser()
          // window.location.reload()
          setGroupDataLoad(true)
          return false
      }
    })
      } catch (error) {
        return await CatchError(error, props, refreshToken)
    }
  }

  const deleteItemCategory = async(name) => {
    try {
       swal("Delete", "Data Can't Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then( async (value) => {
      switch (value) {
        case 'del':
            const result = await customerService.deleteItemCategory(name)
            swal(result.status, result.message, 'success').then(() => {
              sessionStorage.removeItem('itemCategoryRaw')
              setCategoryDataLoad(true)
              Category()
            })
            return true
        default:
          // getUser()
          // window.location.reload()
          setCategoryDataLoad(true)
          return false
      }
    })
      } catch (error) {
        return await CatchError(error, props, refreshToken)
    }

  }

  const deleteItemMeasurement = async(name) => {
    try {
       swal("Delete", "Data Can't Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then( async (value) => {
      switch (value) {
        case 'del':
            const result = await customerService.deleteItemMeasurement(name)
            swal(result.status, result.message, 'success').then(() => {
              sessionStorage.removeItem('itemMeasurementRaw')
              setMeasurementDataLoad(true)
              Measurement()
            })
            return true

        default:
          // getUser()
          // window.location.reload()
          setMeasurementDataLoad(true)
          return false
      }
    })
      } catch (error) {
        setMeasurementDataLoad(false)
        return await CatchError(error, props, refreshToken)
    }

  }

  const deleteAddressType = async(name) => {
    try {
       swal("Delete", "Data Can't Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then( async (value) => {
      switch (value) {
        case 'del':
            const result = await customerService.deleteAddressType(name)
            swal(result.status, result.message, 'success').then(() => {
              sessionStorage.removeItem('addresstypeRaw')
              setAddressTypeDataLoad(true)
              Addresstype()
            })
            return true

        default:
          // getUser()
          // window.location.reload()
          setAddressTypeDataLoad(true)
          return false
      }
    })
      } catch (error) {
        setAddressTypeDataLoad(false)
        return await CatchError(error, props, refreshToken)
    }
  }

  const deleteItemCurrency = async(name) => {
    try {
       swal("Delete", "Data Can't Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then( async (value) => {
      switch (value) {
        case 'del':
            const result = await customerService.deleteItemCurrency(name)
            swal(result.status, result.message, 'success').then(() => {
              sessionStorage.removeItem('itemCurrencyRaw')
              setCurrencyDataLoad(true)
              Currency()
            })
            return true

        default:
          // getUser()
          // window.location.reload()
          setCurrencyDataLoad(true)
          return false
      }
    })
      } catch (error) {
        setCurrencyDataLoad(false)
        return await CatchError(error, props, refreshToken)
    }
  }

  const deleteStatus = async(name) => {
    try {
       swal("Delete", "Data Can't Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then( async (value) => {
      switch (value) {
        case 'del':
            const result = await customerService.deleteStatus(name)
            swal(result.status, result.message, 'success').then(() => {
              sessionStorage.removeItem('statusOptionsRaw')
              setStatusDataLoad(true)
              Status()
            })
            return true

        default:
          // getUser()
          // window.location.reload()
          setStatusDataLoad(true)
          return false
      }
    })
      } catch (error) {
        setStatusDataLoad(false)
        return await CatchError(error, props, refreshToken)
    }
  }

  const deleteBank = async(name) => {
    try {
       swal("Delete", "Data Can't Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then( async (value) => {
      switch (value) {
        case 'del':
            const result = await customerService.deleteBank(name)
            swal(result.status, result.message, 'success').then(() => {
              sessionStorage.removeItem('bankSelectRaw')
              setBankDataLoad(true)
              Bank()
            })
            return true

        default:
          // getUser()
          // window.location.reload()
          setBankDataLoad(true)
          return false
      }
    })
      } catch (error) {
        setBankDataLoad(false)
        return await CatchError(error, props, refreshToken)
    }
  }

    const deleteIndoorDevice = async(name) => {
    try {
       swal("Delete", "Data Can't Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then( async (value) => {
      switch (value) {
        case 'del':
            const result = await customerService.deleteIndoorDevice(name)
            swal(result.status, result.message, 'success').then(() => {
              sessionStorage.removeItem('IndoorDeviceOptionRaw')
              setStatusDataLoad(true)
              IndoorDevice()
            })
            return true

        default:
          // getUser()
          // window.location.reload()
          setStatusDataLoad(true)
          return false
      }
    })
      } catch (error) {
        setStatusDataLoad(false)
        return await CatchError(error, props, refreshToken)
    }
  }

  const deleteOutdoorDevice = async(name) => {
    try {
       swal("Delete", "Data Can't Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then( async (value) => {
      switch (value) {
        case 'del':
            const result = await customerService.deleteOutdoorDevice(name)
            swal(result.status, result.message, 'success').then(() => {
              sessionStorage.removeItem('outdoorDeviceOptionRaw')
              setStatusDataLoad(true)
              OutdoorDevice()
            })
            return true

        default:
          // getUser()
          // window.location.reload()
          setStatusDataLoad(true)
          return false
      }
    })
      } catch (error) {
        setStatusDataLoad(false)
        return await CatchError(error, props, refreshToken)
    }
  }

  const deleteLogisticType = async(name) => {
    try {
       swal("Delete", "Tindakan ini hanya dapat di lakukan jika semua data Item Logistik dengan tipe tersebut telah di hapus.", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then( async (value) => {
      switch (value) {
        case 'del':
            try {
              const result = await customerService.deleteLogisticType(name)
              swal(result.status, result.message, 'success')
              sessionStorage.removeItem('LogisticTypeRaw')
              setLogisticTypeDataLoad(true)
              await GetLogisticType()
              return true
            } catch (error) {
              return CatchError(error, props, refreshToken)
            }
        default:
          setStatusDataLoad(true)
          return false
      }
    })
      } catch (error) {
        setStatusDataLoad(false)
        return await CatchError(error, props, refreshToken)
    }
  }

   const submitCustomerGroup = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = ResoureGroup(groupName, groupMin, groupMax)
      const result = await customerService.addCustomerGroup(payload)

      await Group()
      await swal(result.status, result.message, "success")
      groupNameOnChange('')
      groupMinOnChange('')
      groupMaxOnChange('')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

  const submitItemCategory = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = ResoureBasic(categoryName)
      const result = await customerService.addItemCategory(payload)

      await Category()
      await swal(result.status, result.message, "success")
      categoryNameOnChange('')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

  const submitAddressType = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = ResoureBasic(addressTypeName)
      const result = await customerService.addAddressType(payload)

      await Addresstype()
      await swal(result.status, result.message, "success")
      addressTypeNameOnChange('')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

  const submitItemMeasurement = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
    const payload = ResourceSymbol(measurementName, measurementSymbol)
      const result = await customerService.addItemMeasurement(payload)
      await Measurement()
      await swal(result.status, result.message, "success")
      measurementNameOnChange('')
      measurementSymbolOnChange('')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

  const submitItemCurrency = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = ResourceSymbol(currencyName, currencySymbol)
      const result = await customerService.addItemCurrency(payload)

      await Currency()
      await swal(result.status, result.message, "success")
      currencyNameOnChange('')
      currencySymbolOnChange('')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

  const submitStatus = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = ResoureBasic(statusName)
      const result = await customerService.addStatus(payload)

      await Status()
      await swal(result.status, result.message, "success")
      statusNameOnChange('')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

  const submitBank = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = ResoureBasic(bankName)
      const result = await customerService.addBank(payload)

      await Bank()
      await swal(result.status, result.message, "success")
      bankNameOnChange('')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

   const submitIndoorDevice = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = ResoureBasic(indoorDevice)
      const result = await customerService.addIndoorDevice(payload)

      await IndoorDevice()
      await swal(result.status, result.message, "success")
      indoorDeviceOnChange('')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

   const submitOutdoorDevice = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = ResoureBasic(outdoorDevice)
      const result = await customerService.addOutdoorDevice(payload)

      await OutdoorDevice()
      await swal(result.status, result.message, "success")
      outdoorDeviceOnChange('')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

  const submitLogisticType = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = ResoureBasic(logisticType)
      const result = await customerService.addLogisticType(payload)

      await GetLogisticType()
      await swal(result.status, result.message, "success")
      logisticTypeOnChange('')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
  }}

  const ImportCustomerCsv = async() => {
    try {
      loadingOnChange(true)
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = BinnaryUpload(singleFile)
      const result = await customerService.importCustomer(payload)
      importStatusOnChange(result.data)
      const customerList = await customerService.getAllCustomer('ALL')
      addCustomerList(customerList.data)
      await swal(result.status, result.message, "success")
      loadingOnChange(false)
    } catch (error) {
      loadingOnChange(false)
      return await CatchError(error, props, refreshToken)
  }}

  const ImportItemCsv = async() => {
    try {
      loadingOnChange(true)
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = BinnaryUpload(singleFileItem)
      const result = await itemService.importItem(payload)
      importItemStatusOnChange(result.data)
      const itemList = await itemService.getAllItem('ALL')
      addItemList(itemList.data)
      await swal(result.status, result.message, "success")
      loadingOnChange(false)
    } catch (error) {
      loadingOnChange(false)
      return await CatchError(error, props, refreshToken)
  }}

  const viewDatalog = async()=> {
    try {
      props.history.push('/admin/datalog-list')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const viewWaGateway = async () => {
    try {
      const win = window.open("https://arta-whatsapp-gateway.artamedianet.co.id/", "_blank");
      win.focus();
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const CustomerGroup = ()  => {
    const Data = CustomerGroupList

    try {
      if (!dataGroupLoad) {
        return (
      <>
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Customer Group</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Input
                      placeholder="Name"
                      type="text"
                      value = {groupName}
                      onChange={(e) => {groupNameOnChange(e.target.value)}}
                    />
                    <Input
                      placeholder="Minimum Member"
                      type="number"
                      value = {groupMin}
                      onChange={(e) => {groupMinOnChange(e.target.value)}}
                    />
                    <Input
                      placeholder="Maximum Member"
                      type="number"
                      value={groupMax}
                      onChange={(e) => {groupMaxOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={() => {submitCustomerGroup()}}
                    >Submit</Button>
                  </FormGroup>
                <Table responsive bordered hover>
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>Min</th>
                      <th>Max</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((data, i) => {
                      return (
                        <>
                      <tr key={data.name}>
                      <td>{data.name}</td>
                      <td>{data.min}</td>
                      <td>{data.max}</td>
                      <td className="text-right">
                          <Button
                          className="btn-icon"
                          color="danger"
                          id={`tooltip${Data.name}131${i}`}
                          size="sm"
                          type="button"
                          onClick={() => {deleteCustomerGroup(data.name)}}
                        >
                        <i className="now-ui-icons ui-1_simple-remove" />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target={`tooltip${Data.name}131${i}`}
                        >Delete</UncontrolledTooltip>
                      </td>
                      </tr>
                    </>
                      )
                    }
                    )}

                  </tbody>
                </Table>
              </CardBody>
            </Card>
      </>
    )
      } else {
        Group()
        return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Customer Group</CardTitle>
              </CardHeader>
              <CardBody>
              <p className="typhograpy-line">Loading...</p>
              </CardBody>
              </Card>
      )
      }
    } catch (error) {
      return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Customer Group</CardTitle>
              </CardHeader>
              <CardBody>
                <FormGroup>
                <Input
                      placeholder="Name"
                      type="text"
                      value = {groupName}
                      onChange={(e) => {groupNameOnChange(e.target.value)}}
                    />
                    <Input
                      placeholder="Minimum Member"
                      type="number"
                      value = {groupMin}
                      onChange={(e) => {groupMinOnChange(e.target.value)}}
                    />
                    <Input
                      placeholder="Maximum Member"
                      type="number"
                      value={groupMax}
                      onChange={(e) => {groupMaxOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={() => {submitCustomerGroup()}}
                    >Submit</Button>
                  </FormGroup>
              <p className="typhograpy-line">No Data</p>
              </CardBody>
              </Card>
      )
    }
  }

    const ItemCategory = ()  => {
    const Data = itemCategoryList
    try {
      if (!CategorydataLoad) {
        return (
      <>
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Item Category</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Input
                      placeholder="Add New"
                      type = "text"
                      value = {categoryName}
                      onChange={(e) => {categoryNameOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={() => {submitItemCategory()}}
                    >Submit</Button>
                  </FormGroup>
                <Table responsive bordered hover>
                  <thead className="header">
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((data, i) => {
                      return (
                        <>
                      <tr key={data.name}>
                      <td>{data.name}</td>
                      <td className="text-right">
                          <Button
                          className="btn-icon"
                          color="danger"
                          id={`tooltip${Data.name}132${i}`}
                          size="sm"
                          type="button"
                          onClick={() => {deleteItemCategory(data.name)}}
                          >
                          <i className="now-ui-icons ui-1_simple-remove" />
                          </Button>
                          <UncontrolledTooltip
                              delay={0}
                              target={`tooltip${Data.name}132${i}`}
                          >Delete</UncontrolledTooltip>
                      </td>
                      </tr>
                    </>
                      )
                    }
                    )}

                  </tbody>
                </Table>
              </CardBody>
            </Card>
      </>
    )
      } else {
        Category()
        return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Item Category</CardTitle>
              </CardHeader>
              <CardBody>
              <p className="typhograpy-line">Loading...</p>
              </CardBody>
              </Card>
      )
      }
    } catch (error) {
      return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Item Category</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Input
                      placeholder="Add New"
                      type = "text"
                      value = {categoryName}
                      onChange={(e) => {categoryNameOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={() => {submitItemCategory()}}
                    >Submit</Button>
                  </FormGroup>
              <p className="typhograpy-line">No Data</p>
              </CardBody>
              </Card>
      )
    }
  }

    const AddressType = ()  => {
    const Data = AddressTypeList

    try {
      if (!AddressTypedataLoad) {
        return (
      <>
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Address Type</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Input
                      placeholder="Add New"
                      type = "text"
                      value = {addressTypeName}
                      onChange={(e) => {addressTypeNameOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={() => {submitAddressType()}}
                    >Submit</Button>
                  </FormGroup>
                <Table responsive bordered hover>
                  <thead className="header">
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((data, i) => {
                      return (
                        <>
                      <tr key={data.name}>
                      <td>{data.name}</td>
                      <td className="text-right">
                          <Button
                          className="btn-icon"
                          color="danger"
                          id={`tooltip${Data.name}3${i}`}
                          size="sm"
                          type="button"
                          onClick={() => {deleteAddressType(data.name)}}
                          >
                          <i className="now-ui-icons ui-1_simple-remove" />
                          </Button>
                          <UncontrolledTooltip
                              delay={0}
                              target={`tooltip${Data.name}3${i}`}
                          >Delete</UncontrolledTooltip>
                      </td>
                      </tr>
                    </>
                      )
                    }
                    )}

                  </tbody>
                </Table>
              </CardBody>
            </Card>
      </>
    )
      } else {
        Addresstype()
        return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Address Type</CardTitle>
              </CardHeader>
              <CardBody>
              <p className="typhograpy-line">Loading...</p>
              </CardBody>
              </Card>
      )
      }
    } catch (error) {
      return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Address Type</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Input
                      placeholder="Add New"
                      type = "text"
                      value = {addressTypeName}
                      onChange={(e) => {addressTypeNameOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={() => {submitAddressType()}}
                    >Submit</Button>
                  </FormGroup>
              <p className="typhograpy-line">No Data</p>
              </CardBody>
              </Card>
      )
    }
  }

    const ItemMeasurement = ()  => {
    const Data = ItemMeasurementList


    try {
      if (!MeasurementdataLoad) {
        return (
      <>
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Item Measurement</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                  <Input
                    placeholder="Name"
                    type="text"
                    value = {measurementName}
                    onChange={(e) => {measurementNameOnChange(e.target.value)}}
                  />
                  <Input
                    placeholder="Symbol"
                    type="text"
                    value = {measurementSymbol}
                    onChange={(e) => {measurementSymbolOnChange(e.target.value)}}
                  />
                  <Button
                    color="info"
                    onClick={() => {submitItemMeasurement()}}
                  >Submit</Button>
                  </FormGroup>
                <Table responsive bordered hover>
                  <thead className="header">
                    <tr>
                      <th>Name</th>
                      <th>Symbol</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((data, i) => {
                      return (
                        <>
                      <tr key={data.name}>
                      <td>{data.name}</td>
                      <td>{data.symbol}</td>
                      <td className="text-right">
                          <Button
                          className="btn-icon"
                          color="danger"
                          id={`tooltip${Data.name}1${i}`}
                          size="sm"
                          type="button"
                          onClick={() => {deleteItemMeasurement(data.name)}}
                          >
                          <i className="now-ui-icons ui-1_simple-remove" />
                          </Button>
                          <UncontrolledTooltip
                              delay={0}
                              target={`tooltip${Data.name}1${i}`}
                          >Delete</UncontrolledTooltip>
                      </td>
                      </tr>
                    </>
                      )
                    }
                    )}

                  </tbody>
                </Table>
              </CardBody>
            </Card>
      </>
    )
      } else {
        Measurement()
        return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Item Measurement</CardTitle>
              </CardHeader>
              <CardBody>
              <p className="typhograpy-line">Loading...</p>
              </CardBody>
              </Card>
      )
      }
    } catch (error) {
      return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Item Measurement</CardTitle>
              </CardHeader>
              <CardBody>
                <FormGroup>
                 <Input
                    placeholder="Name"
                    type="text"
                    value = {measurementName}
                    onChange={(e) => {measurementNameOnChange(e.target.value)}}
                  />
                  <Input
                    placeholder="Symbol"
                    type="text"
                    value = {measurementSymbol}
                    onChange={(e) => {measurementSymbolOnChange(e.target.value)}}
                  />
                  <Button
                    color="info"
                    onClick={() => {submitItemMeasurement()}}
                  >Submit</Button>
                </FormGroup>
              <p className="typhograpy-line">No Data</p>
              </CardBody>
              </Card>
      )
    }
  }

    const ItemCurrency = ()  => {
    const Data = ItemCurrencyList

    try {
      if (!CurrencydataLoad) {
        return (
      <>
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Item Currency</CardTitle>
              </CardHeader>
              <CardBody>
                 <FormGroup>
                  <Input
                    placeholder="Name"
                    type="text"
                    value = {currencyName}
                    onChange={(e) => {currencyNameOnChange(e.target.value)}}
                  />
                  <Input
                    placeholder="Symbol"
                    type="text"
                    value = {currencySymbol}
                    onChange={(e) => {currencySymbolOnChange(e.target.value)}}
                  />
                  <Button
                    color="info"
                    onClick={() =>{submitItemCurrency()}}
                  >Submit</Button>
                  </FormGroup>
                <Table responsive bordered hover>
                  <thead className="header">
                    <tr>
                      <th>Name</th>
                      <th>Symbol</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((data, i) => {
                      return (
                        <>
                      <tr key={data.name}>
                      <td>{data.name}</td>
                      <td>{data.symbol}</td>
                      <td className="text-right">
                          <Button
                          className="btn-icon"
                          color="danger"
                          id={`tooltip${Data.name}14${i}`}
                          size="sm"
                          type="button"
                          onClick={() => {deleteItemCurrency(data.name)}}
                          >
                          <i className="now-ui-icons ui-1_simple-remove" />
                          </Button>
                          <UncontrolledTooltip
                              delay={0}
                              target={`tooltip${Data.name}14${i}`}
                          >Delete</UncontrolledTooltip>
                      </td>
                      </tr>
                    </>
                      )
                    }
                    )}

                  </tbody>
                </Table>
              </CardBody>
            </Card>
      </>
    )
      } else {
        Currency()
        return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Item Currency</CardTitle>
              </CardHeader>
              <CardBody>
              <p className="typhograpy-line">Loading...</p>
              </CardBody>
              </Card>
        )
      }
    } catch (error) {
      return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Item Currency</CardTitle>
              </CardHeader>
              <CardBody>
              <FormGroup>
                  <Input
                    placeholder="Name"
                    type="text"
                    value = {currencyName}
                    onChange={(e) => {currencyNameOnChange(e.target.value)}}
                  />
                  <Input
                    placeholder="Symbol"
                    type="text"
                    value = {currencySymbol}
                    onChange={(e) => {currencySymbolOnChange(e.target.value)}}
                  />
                  <Button
                    color="info"
                    onClick={() =>{submitItemCurrency()}}
                  >Submit</Button>
                  </FormGroup>
              <p className="typhograpy-line">No Data</p>
              </CardBody>
              </Card>
      )
    }
  }

  const StatusData = ()  => {
    const Data = StatusList

    try {
      if (!StatusdataLoad) {
        return (
      <>
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Status</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Input
                      placeholder="Add New"
                      type="text"
                      value={statusName}
                      onChange={(e) => {statusNameOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={() => {submitStatus()}}
                    >Submit</Button>
                  </FormGroup>
                <Table responsive bordered hover>
                  <thead className="header">
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((data, i) => {
                      return (
                        <>
                      <tr key={data.name}>
                      <td>{data.name}</td>
                      <td className="text-right">
                          <Button
                          className="btn-icon"
                          color="danger"
                          id={`tooltip${Data.name}13${i}`}
                          size="sm"
                          type="button"
                          onClick={() => {deleteStatus(data.name)}}
                          >
                          <i className="now-ui-icons ui-1_simple-remove" />
                          </Button>
                          <UncontrolledTooltip
                              delay={0}
                              target={`tooltip${Data.name}13${i}`}
                          >Delete</UncontrolledTooltip>
                      </td>
                      </tr>
                    </>
                      )
                    }
                    )}

                  </tbody>
                </Table>
              </CardBody>
            </Card>
      </>
    )
      } else {
        Status()
        return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Status</CardTitle>
              </CardHeader>
              <CardBody>
              <p className="typhograpy-line">Loading...</p>
              </CardBody>
              </Card>
      )
      }
    } catch (error) {
      return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Status</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Input
                      placeholder="Add New"
                      type="text"
                      value={statusName}
                      onChange={(e) => {statusNameOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={() => {submitStatus()}}
                    >Submit</Button>
                  </FormGroup>
              <p className="typhograpy-line">No Data</p>
              </CardBody>
              </Card>
      )
    }
  }

  const BankData = ()  => {
    const Data = BankList

    try {
      if (!bankDataLoad) {
        return (
      <>
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Bank List</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Input
                      placeholder="Add New"
                      type="text"
                      value={bankName}
                      onChange={(e) => {bankNameOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={() => {submitBank()}}
                    >Submit</Button>
                  </FormGroup>
                <Table responsive bordered hover>
                  <thead className="header">
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((data, i) => {
                      return (
                        <>
                      <tr key={data.name}>
                      <td>{data.name}</td>
                      <td className="text-right">
                           <Button
                          className="btn-icon"
                          color="danger"
                          id={`tooltip${Data.name}13${i}`}
                          size="sm"
                          type="button"
                          onClick={() => {deleteBank(data.name)}}
                          >
                          <i className="now-ui-icons ui-1_simple-remove" />
                          </Button>
                          <UncontrolledTooltip
                              delay={0}
                              target={`tooltip${Data.name}13${i}`}
                          >Delete</UncontrolledTooltip>
                      </td>
                      </tr>
                    </>
                      )
                    }
                    )}

                  </tbody>
                </Table>
              </CardBody>
            </Card>
      </>
    )
      } else {
        Bank()
        return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Bank List</CardTitle>
              </CardHeader>
              <CardBody>
              <p className="typhograpy-line">Loading...</p>
              </CardBody>
              </Card>
      )
      }
    } catch (error) {
      return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Bank List</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Input
                      placeholder="Add New"
                      type="text"
                      value={bankName}
                      onChange={(e) => {bankNameOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={() => {submitBank()}}
                    >Submit</Button>
                  </FormGroup>
              <p className="typhograpy-line">No Data</p>
              </CardBody>
              </Card>
      )
    }
  }

  const IndoorDeviceData = ()  => {
    const Data = IndoorDeviceList

    try {
      if (!indoorDevicedataLoad) {
        return (
      <>
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Indoor Device</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Input
                      placeholder="Add New"
                      type="text"
                      value={indoorDevice}
                      onChange={(e) => {indoorDeviceOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={() => {submitIndoorDevice()}}
                    >Submit</Button>
                  </FormGroup>
                <Table responsive bordered hover>
                  <thead className="header">
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((data, i) => {
                      return (
                        <>
                      <tr key={data.name}>
                      <td>{data.name}</td>
                      <td className="text-right">
                          <Button
                          className="btn-icon"
                          color="danger"
                          id={`tooltip${Data.name}13${i}`}
                          size="sm"
                          type="button"
                          onClick={() => {deleteIndoorDevice(data.name)}}
                          >
                          <i className="now-ui-icons ui-1_simple-remove" />
                          </Button>
                          <UncontrolledTooltip
                              delay={0}
                              target={`tooltip${Data.name}13${i}`}
                          >Delete</UncontrolledTooltip>
                      </td>
                      </tr>
                    </>
                      )
                    }
                    )}

                  </tbody>
                </Table>
              </CardBody>
            </Card>
      </>
    )
      } else {
        IndoorDevice()
        return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Indoor Device</CardTitle>
              </CardHeader>
              <CardBody>
              <p className="typhograpy-line">Loading...</p>
              </CardBody>
              </Card>
      )
      }
    } catch (error) {
      return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Indoor Device</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Input
                      placeholder="Add New"
                      type="text"
                      value={indoorDevice}
                      onChange={(e) => {indoorDeviceOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={() => {submitIndoorDevice()}}
                    >Submit</Button>
                  </FormGroup>
              <p className="typhograpy-line">{error.message}</p>
              </CardBody>
              </Card>
      )
    }
  }

  const OutdoorDeviceData = ()  => {
    const Data = OutdoorDeviceList

    try {
      if (!outdoorDevicedataLoad) {
        return (
      <>
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Outdoor Device</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Input
                      placeholder="Add New"
                      type="text"
                      value={outdoorDevice}
                      onChange={(e) => {outdoorDeviceOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={() => {submitOutdoorDevice()}}
                    >Submit</Button>
                  </FormGroup>
                <Table responsive bordered hover>
                  <thead className="header">
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((data, i) => {
                      return (
                        <>
                      <tr key={data.name}>
                      <td>{data.name}</td>
                      <td className="text-right">
                          <Button
                          className="btn-icon"
                          color="danger"
                          id={`tooltip${Data.name}13${i}`}
                          size="sm"
                          type="button"
                          onClick={() => {deleteOutdoorDevice(data.name)}}
                          >
                          <i className="now-ui-icons ui-1_simple-remove" />
                          </Button>
                          <UncontrolledTooltip
                              delay={0}
                              target={`tooltip${Data.name}13${i}`}
                          >Delete</UncontrolledTooltip>
                      </td>
                      </tr>
                    </>
                      )
                    }
                    )}

                  </tbody>
                </Table>
              </CardBody>
            </Card>
      </>
    )
      } else {
        OutdoorDevice()
        return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Status</CardTitle>
              </CardHeader>
              <CardBody>
              <p className="typhograpy-line">Loading...</p>
              </CardBody>
              </Card>
      )
      }
    } catch (error) {
      return (
            <Card>
              <CardHeader>
              <CardTitle tag="h4">Status</CardTitle>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Input
                      placeholder="Add New"
                      type="text"
                      value={outdoorDevice}
                      onChange={(e) => {outdoorDeviceOnChange(e.target.value)}}
                    />
                    <Button
                      color="info"
                      onClick={() => {submitOutdoorDevice()}}
                    >Submit</Button>
                  </FormGroup>
              <p className="typhograpy-line">{error.message}</p>
              </CardBody>
              </Card>
      )
    }
  }

    const SetMitraGenerateInvoice = async() => {
    swal("Update", "Data Can't be Undo", "info", {
            buttons: {
            update: {text: "Update", value: 'up' },
            cancel: 'Cancel'
          }
        }
    ).then(async(value) => {
      var result
      switch (value) {
            case 'up':
              try {
                // const dateStr = new Date(date).toISOString()
                // const DateGen = convertDateLocaleString(generateDate)
                // console.log(generateDate)
                // const newDate = new Date(m[3], m[2] - 1, m[1], m[4], m[5], m[6]);
                const payload =  MitraGenerate(
                  dynamicInvoice,
                  isGenerate,
                  generateDate,
                  due,
                  beforeDue,
                  ppn,
                  administrationFee,
                  instalationFee,
                  memberFee,
                  maxMergeDate,
                  maxMergePrice,
                  syncVa,
                  syncHostpot)
                // console.log(payload)
                result = await userService.setMitraInvoiceGenerate(mitraId, payload)
                swal(result.status, result.message, 'success')
              } catch (error) {
                return CatchError(error, props, refreshToken)
              }
              break;

            default:
              break;
            }
          })
  }

  const GenerateInvoice = (userSuper || userAdmin) ? (
          <>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Konfigurasi Invoice</CardTitle>
              </CardHeader>
              <CardBody>
                  <Checklist
                    key = {6}
                    text = 'Auto Generate Invoice'
                    value = {isGenerate}
                    checked = {isGenerate}
                    onChange = {e => isGenerateOnChage(e.target.checked)}
                  />
                  <Checklist
                    classLabel = {'text-dark'}
                    key = {5}
                    text = 'Tanggal Generate Invoice Mengikuti Aktivasi Pelanggan'
                    value = {dynamicInvoice}
                    checked = {dynamicInvoice}
                    onChange = {e => dynamicInvoiceOnChage(e.target.checked)}
                  />
                  <Label>Generate Date</Label>
                  <Input
                    placeholder="Generate"
                    value = {DateTimestr(generateDate)}
                    type="text"
                  />
                  <Label>Set Invoice Generate Date</Label>
                  <Input
                    className = "generate"
                    placeholder="Generate Date"
                    disabled = {!isGenerate || dynamicInvoice}
                    value = {generateDate}
                    onChange = {e => {
                        setGenerateDate(e.target.value)
                      }}
                    type="datetime-local"
                  />
                  <DateIncrementInput
                    key = {7}
                    labelInput = {"Jatuh Tempo (MIN -30 MAX 120 Day)"}
                    labelOutput = {'Tanggal Tertampil'}
                    value = {due}
                    onChange={(e) => {
                      if (e.target.value >= -30 && e.target.value <= 120) {
                        dueOnChange(e.target.value)
                      }
                    }}
                  />
                  <DateIncrementInput
                    key = {8}
                    labelInput = {"Notifikasi Sebelum Jatuh Tempo (MAX 30 Day)"}
                    labelOutput = {'Tanggal Tertampil'}
                    value = {beforeDue}
                    dueDisplayValue = {due}
                    decr = {true}
                    onChange={(e) => {
                      if (e.target.value >= 0 && e.target.value <= 30) {
                        beforeDueOnChange(e.target.value)
                      }
                    }}
                  />
                  <Label>PPN (Tax Percentage)</Label>
                  <Input
                    value = {ppn}
                    placeholder="PPN"
                    type="number"
                    onChange={(e) => {
                      if (e.target.value <= 100) {
                        ppnOnChange(e.target.value)
                      }
                    }}
                  />
                  <div className="row">
                    <div className="col md-6">
                      <BasicInput
                        key = {9}
                        label = {'Biaya Admin Layanan ArtaBilling'}
                        value = {administrationFee}
                        disabled
                        type = {"number"}
                        onChange = {setAdministrationFee}
                      />
                    </div>
                    <div className="col md-6">
                      <BasicInput
                        key = {10}
                        value = {PriceFormat(administrationFee, currency)}
                        onChange = {()=>{}}
                        disabled
                        label="Harga Tertampil"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col md-6">
                      <BasicInput
                        key = {11}
                        label = {'Biaya Instalasi Jaringan'}
                        value = {instalationFee}
                        type = {"number"}
                        onChange = {setInstalationFee}
                      />
                    </div>
                    <div className="col md-6">
                      <BasicInput
                        key = {12}
                        value = {PriceFormat(instalationFee, currency)}
                        onChange = {()=>{}}
                        disabled
                        label={"Harga Tertampil"}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col md-6">
                      <BasicInput
                        key = {13}
                        label = {'Biaya Member Reseller'}
                        value = {memberFee}
                        type = {"number"}
                        onChange = {setMemberFee}
                      />
                    </div>
                    <div className="col md-6">
                      <BasicInput
                        key = {14}
                        value = {PriceFormat(memberFee, currency)}
                        onChange = {()=>{}}
                        disabled
                        label = {"Harga Tertampil"}
                      />
                    </div>
                  </div>
                  <Label>Tanggal minimal Pengabungan Invoice (Customer Baru)</Label>
                  <Input
                    value = {maxMergeDate}
                    placeholder="Tanggal minimal Pengabungan Invoice"
                    type="number"
                    onChange={(e) => {
                      if (e.target.value >= 1 && e.target.value <= 25) {
                        setMaxMergeDate(e.target.value)
                      }
                    }}
                  />
                  <BasicInput
                    key = {15}
                    label = {'Harga Maksimal Pengabungan Invoice (Customer Lama)'}
                    value = {maxMergePrice}
                    type = {"number"}
                    onChange = {setMaxMergePrice}
                  />
                  <Input
                    value = {PriceFormat(maxMergePrice, currency)}
                    placeholder="Harga Maksimal Pengabungan Invoice"
                  />
                  <Checklist
                    key = {16}
                    text = 'Sinkronisasi Harian Virtual Account'
                    value = {syncVa}
                    checked = {syncVa}
                    onChange = {(e) => setSyncVa(e.target.checked)}
                  />
                  <Checklist
                    key = {17}
                    text = 'Sinkronisasi Hostpot'
                    value = {syncHostpot}
                    checked = {syncHostpot}
                    onChange = {(e) => setSyncHostpot(e.target.checked)}
                  />
                  {(mitraSuper || mitraAdmin) ? (<Button onClick = {SetMitraGenerateInvoice} color="info">Edit</Button>) : <p></p>}
              </CardBody>
            </Card>
          </>
        ) : (
          <p></p>
        )

  const Datalog = () => {
    return (
      <Card>
        <CardHeader>
          <CardTitle>
            <h5 className="title">System Services Dashboard</h5>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Button
              color="primary"
              onClick={() =>{viewWaGateway()}}
            >WA Gateway Dashboard</Button>
            <Button
              color="warning"
              onClick={() =>{viewDatalog()}}
            >Server Log Dashboard</Button>
          </FormGroup>
        </CardBody>
      </Card>
    )
  }

  const SubsStatus = (Data) => {
    return (
      <Table responsive bordered hover className="bg-secondary text-light" max-height= "400px">
            <thead className="text-light">
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
            {Data.map((data, i) => {
              return (
                <>
              <tr key={i}>
              <td>{data.customer || data.name}</td>
              <td className={!data.success ? "bg-danger": "bg-success"}>{StatusDisplay(data.success)}</td>
              <td>{data.message}</td>
              </tr>
            </>
             )
          }
          )}
        </tbody>
      </Table>)
  }

  const ImportCustomer = () => {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h3" className="title">Import Customer From Excel And CSV File</CardTitle>
          <p className="title text-danger">Customer Data Export Format have same Format to Import Format</p>
        </CardHeader>
        <CardBody>
        <DynamicTable Data = {csvReferences()}/>
        <p className="text-danger"><i className="title text-success">'registration'</i> Date must be set to use Invoice Auto-Closing Feature,
        you can limit Auto-Closing Invoice Subscription Period with <i className="title text-success">'endperiod'</i> columns</p>
        <FormGroup className="form-file-upload form-file-simple">
                <Input
                  type="text"
                  className="inputFileVisible"
                  placeholder="Select Excel/Csv File"
                  onClick={(e) => handleSingleFileInput(e)}
                  defaultValue={singleFileName}
                />
                <input
                  type="file"
                  className="inputFileHidden"
                  style={{ zIndex: -1 }}
                  ref={singleFileRef}
                  onChange={(e) => addSingleFile(e)}
                />
         </FormGroup>
        <FormGroup>
          <Button
            color="primary"
            disabled = {!singleFileName}
            onClick={() =>{ImportCustomerCsv()}}
          >Import Excel/CSV</Button>
        </FormGroup>
        <Label>Customer Import Status</Label>
        {SubsStatus(importStatus)}
        </CardBody>
      </Card>
    )
  }

  const ImportItem = () => {
    return (
            <Card>
              <CardHeader>
              <CardTitle tag="h3" className="title">Import Item Profile From Excel And CSV File</CardTitle>
              <p className="title text-danger">Item Profile Data Export Format have same Format to Import Format</p>
              </CardHeader>
              <CardBody>
              <DynamicTable Data = {csvItemReferences()}/>
              <FormGroup className="form-file-upload form-file-simple">
                      <Input
                        type="text"
                        className="inputFileVisible"
                        placeholder="Select Excel/Csv File"
                        onClick={(e) => handleSingleFileItemInput(e)}
                        defaultValue={singleFileItemName}
                      />
                      <input
                        type="file"
                        className="inputFileHidden"
                        style={{ zIndex: -1 }}
                        ref={singleFileItemRef}
                        onChange={(e) => addSingleFileItem(e)}
                      />
               </FormGroup>
               <p></p>
              <FormGroup>
                <Button
                  color="primary"
                  disabled = {!singleFileItemName}
                  onClick={() =>{ImportItemCsv()}}
                >Import Excel/CSV</Button>
              </FormGroup>
              <Label>Item Import Status</Label>
              {SubsStatus(importItemStatus)}
              </CardBody>
              </Card>
    )
  }

 const SuperAdminPage = () => {
    if (mitraSuper) {
      return (<>
            {Datalog()}
            {CustomerGroup()}
            {ItemCategory()}
            {AddressType()}
            {ItemMeasurement()}
            {ItemCurrency()}
            {StatusData()}
            {/* {BankData()} */}
            {IndoorDeviceData()}
            {OutdoorDeviceData()}
            </>)
    }
    return (
      <Label>....</Label>
    )
  }

  const ChildLayout = (
    <>
    <UserUpdatePages />
    <div className="content">
      <Row>
        <Col md="12">
          {GenerateInvoice}
          {(mitraAdmin || mitraSuper) ?
            (<>
              {ImportCustomer()}
              {ImportItem()}
            </>) : <p></p>
          }
          {mitraSuper ? (<>
            {SuperAdminPage()}
            <LogisticType
              StatusdataLoad={LogisticTypeDataLoad}
              statusName={logisticType}
              statusNameOnChange={logisticTypeOnChange}
              submit={submitLogisticType}
              Data={LogisticTypeList}
              deleteData={deleteLogisticType}
              FetchLogisticType={GetLogisticType} />
              </>) : <p></p>}
        </Col>
      </Row>
    </div></>
  )
  return (
      <>
        <LoadingScreen
          children={ChildLayout}
          loading = {loading}
        />
      </>
    );
}


export default Resource;
