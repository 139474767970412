import { JsonToTable  } from 'react-json-to-table'
import { JsonViewer } from '@textea/json-viewer'
import { ocean } from 'assets/js/style';
import { isComplexJson } from 'Main/Utils';
import { ErrorBoundary } from 'components/ErrorHandler/ErrorBoundary';

  export function RenderJSon({jsonData, id}) {
    const tableId = `${id}-table`;
    const viewerId = `${id}-viewer`;
    if (isComplexJson(jsonData)) {
        return (
          <div id={viewerId}>
            <ErrorBoundary>
                <JsonViewer value={jsonData} theme={ocean} />
            </ErrorBoundary>
          </div>
        );
    }
    return (
      <div id={tableId}>
        <ErrorBoundary>
            <JsonToTable json={jsonData} />
        </ErrorBoundary>
      </div>
    )
  }