/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { invoiceStatus } from "Main/Utils";
// import Switch from "react-switch";
// reactstrap components
import {
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import BillingService from "Main/BillingService";
import { putInvoice, addInvoiceData,
  getRefreshToken, addCustomerName } from "variables/common";
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import StatusButton from "components/Button/StatusButton";
import { addGlobalTemplate } from "variables/common";
import { getUserPermission } from "variables/common";
import CommonList from "components/Table/CommonList";
import { invoiceColumnsSuperUser, invoiceColumnsUser } from "./InvoiceColumns";
import { getUserData } from "variables/common";
import { customTextFromBoolean } from "Main/Utils";
import { CleanStringSpace } from "Main/Utils";
import LoadingScreen from "components/Loader/LoadingScreen";
import NoLabelSelect from "components/Input/NoLabelSelect";

function InvoiceLists(props) {
  const pages = 'invoice'
  const userData = getUserData()
  const permission = getUserPermission()
  const { mitraType, allowInvoiceClosing } = userData
  const Permission = permission.find(data => data.Role === pages) || ''
  const [Table, dataTableOnChange] = useState([])
  const billingService =  new BillingService()
  const refreshToken = getRefreshToken()
  const [loading, setDataLoaded] = useState(true)
  const [loadingEx, setLoadingEx] = useState(false)
  const [period, setPeriod] = useState({})
  const [selectPeriod, setSelectPeriod] = useState([])
  const superAdmin = (mitraType === 'SUPERADMIN')

  try {
    const fetchInvoice = async() => {
      const periodState = await fetchPeriod()
      return await getInvoice(periodState.value)
    }

    const getInvoice =  async(periodSelected) => {
      try {
        setDataLoaded(true)
        const len = 'ALL'

        const dat =  await billingService.getAllInvoiceGroup(len, periodSelected)
        const result = dat.data
        dataTableOnChange(result)
        setDataState(renderTable(result, periodSelected))
        setDataLoaded(false)
        return result
      } catch (error) {
        setDataLoaded(false)
        return await CatchError(error, props, refreshToken)
      }
    }

    useEffect(() => {
      fetchInvoice(period.value)
    }, [])

    const fetchPeriod =  async() => {
      try {
        const dat =  await billingService.getInvoiceListPeriod()
        const result = dat.data
        setSelectPeriod(result)
        const periodState = result[0]
        let basePeriode = period
        if (!period.value) {
          setPeriod(periodState)
          basePeriode = periodState
        }
        return basePeriode || {}
      } catch (error) {
        return await CatchError(error, props, refreshToken)
      }
    }

    const deleteInvoice = async(id, periode) => {
      swal("Hapus", "Invoice Tidak Dapat di Recover", "info", {
            buttons: {
            cancel: 'Cancel',
            delete: {text: "Delete", value: 'del' }
          }
        }
      ).then(async(value) => {
          switch (value) {
            case 'del':
              try {
                  setDataLoaded(true)
                  console.log(period)
                  await billingService.deleteInvoice(id)
                  await swal('Success', 'Invoice Berhasil di Hapus', 'success')
                  const state = await getInvoice(periode)
                  const stateRaw = renderTable(state, periode)
                  const table = stateRaw.filter((dat) => dat.id !== id)
                  setDataState(table)
                  setDataLoaded(false)
              } catch (error) {
                setDataLoaded(false)
                return await CatchError(error, props, refreshToken)
              }
              break;

          }
        })
    }

    const viewInvoice = async(id) => {
      try {
        const dat = await billingService.getInvoice(id)
        const result = dat.data
        addInvoiceData(result)
        props.history.push('/admin/invoice-update')
      } catch (error) {
        return await CatchError(error, props, refreshToken)
      }
    }

    const sendEmail = async(id) => {
      try {
        setLoadingEx(true)
        const dat = await billingService.getInvoice(id)
        const result = dat.data
        addCustomerName(result)
        const TemplateDat = await billingService.getDefaultTemplate(id)
        const TemplateResult = TemplateDat.data
        addGlobalTemplate(TemplateResult)
        const datInv = await billingService.getInvoice(id)
        const resultInv = datInv.data
        addInvoiceData(resultInv)
        setLoadingEx(false)
        props.history.push('email-page')
      } catch (error) {
        setLoadingEx(false)
        return await CatchError(error, props, refreshToken)
      }
    }

    const syncronizeVirtualAccount = async() => {
      try {
        setLoadingEx(true)
        const result = await billingService.syncBniVa()
        setLoadingEx(false)
        await swal('Sinkronisasi VA', result.message, result.status)
      } catch (error) {
        setLoadingEx(false)
        return await CatchError(error, props, refreshToken)
      }
    }

      const SendEmailAll = async() => {
        props.history.push('/admin/invoice-notification')
      }

    const dataTable = Table

    const SetInvoicePay = async(status, id, periode) => {
      setLoadingEx(true)
      console.log('invoice status', status)
      const operation = invoiceStatus(!status)
      const buttons = !status ? {
                  bri: {text: "BRI VA", value: 2 },
                  bni: {text: "BNI VA", value: 3 },
                  mandiri1: {text: "Mandiri VA", value: 4 },
                  cash: {text: "Cash", value: 0 },
                  cancel: 'Batal'
              } : {
                cash: {text: "Batalkan Pembayaran", value: 0 },
                cancel: 'Batal'
              }
      await swal(operation, "Pilih Jenis Pembayaran.", 'info', {
        buttons,
      }).then(async(value) => {
            try {
              if (value == null) {
                setLoadingEx(false)
                return
              }
              const type = !status ? 'Closing Pembayaran' : 'Pembatalan Pembayaran'
              const sendMessage = !status ? await swal(`Notifikasi ${type}`, `Klik Kirim untuk mengirimkan Notifikasi ${type} ke Pelanggan.`, 'info', {
                buttons: {
                  send: {text: "Kirim", value: true },
                  cancel: "Tidak",
                }
              }) : true
              const paymentType = value
              const dontSendNotification = !sendMessage
              const payload = putInvoice(!status, paymentType, dontSendNotification)

              const dat = await billingService.patchInvoice(id, payload)
              await swal(operation, dat.message, dat.status)
              const table = await getInvoice(periode)
              setDataState(renderTable(table, periode))
              setLoadingEx(false)
            } catch (error) {
              setLoadingEx(false)
              return await CatchError(error, props, refreshToken)
            }
      })
    }

    const Add = () => {
      props.history.push('/admin/invoice-page')
    }

    const Broadcast = () => {
      props.history.push('/admin/message-broadcast')
    }

    const Export = () => {
      props.history.push('/admin/invoice-export')
    }

    const renderTable = (table, periodSelected) => table.map((Data, i) => {
      try {
        const periodeInv = CleanStringSpace(Data.period) + ' ' + Data.year
        return {
            id: Data.id,
            customerId: Data.customerId,
            customer: Data.customerName,
            period: periodeInv,
            owner: Data.mitra_name,
            email: Data.customerEmail,
            bankSync: customTextFromBoolean(Data.is_va_sync, 'Synchronized', 'Unsynchronized'),
            messageSend: customTextFromBoolean(Data.is_message_send, 'Terkirim', 'Gagal'),
            invoiceStatus: (
              <div className="actions-center">
                <StatusButton
                disabled = {Permission.Update}
                  statusOnChange = { async() => {
                    console.log(period.value)
                    await SetInvoicePay(Data.paid, Data.id, periodSelected)
                  }}
                  status = {Data.paid}
                  disableText='unpaid'
                  enableText='paid'
                  disableColor='warning'
                />
              </div>
            ),
            invoiceStatusUser: (customTextFromBoolean(Data.paid, 'Lunas', 'Belum Bayar')),
            paid: Data.paid,
            // billingStatus: Data.status,
            item: Data.itemName,
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.netId}>
              <Button
                  disabled = {!Permission.Read}
                  className="btn-icon"
                  color="info"
                  id={`tooltip${Data.id}2`}
                  size="sm"
                  type="button"
                  onClick={async () => {
                    await sendEmail(Data.id)
                  }}
                >
                <i className="now-ui-icons users_single-02" />
              </Button>
              <Button
                disabled = {!Permission.Read}
                onClick={ async () => {
                  await viewInvoice(Data.id)
                }}
                className="btn-icon btn-round"
                color="warning"
                id={`tooltip${Data.id}3`}
                size="sm"
              >
              <i className="fa fa-edit" />
              </Button>{" "}
              <Button
                disabled = {!Permission.Delete}
                onClick={ async () => {
                    await deleteInvoice(Data.id, periodSelected)
                }}
                className="btn-icon btn-round"
                id={`tooltip${Data.id}4`}
                color="danger"
                size="sm"
              >
                <i className="fa fa-times" />
              </Button>{" "}
              </div>
            ),
          };
          } catch (error) {
            console.log(error)
            return []
          }
        })

    const [dataState, setDataState] = useState(renderTable(dataTable, period.value));
    const menu = (
      <Row>
        <Col md={1} align= "left">
          <Button
            className="font-weight-bold"
            onClick={Add}
            color="info"
            disabled = {!Permission.Create}
          >Add</Button>
        </Col>
        <Col md={2} align= "left">
          <Button
            className="font-weight-bold"
            onClick={Broadcast} color="info"
            disabled = {!Permission.Read}
          >Broadcast</Button>
        </Col>
        <Col md={4} align= "left">
          <NoLabelSelect
            placeholder = {"Periode Invoice"}
            value = {period}
            option = {selectPeriod}
            onChange = {async (e) => {
              setPeriod(e)
              await getInvoice(e.value)
            }}
          />
        </Col>
        <Col md={2} align="right">
          {
            !superAdmin ? (<></>) : (
              <>
                <Button
                  className="font-weight-bold"
                  disabled = {!Permission.Read}
                  color="primary"
                  onClick={syncronizeVirtualAccount}
                >Sync BNI VA</Button>
              </>
            )
          }
        </Col>
        <Col md={1} align="right">
          <Button
            className="font-weight-bold"
            disabled = {!Permission.Read}
            color="success"
            onClick={SendEmailAll}
          >Notify</Button>
        </Col>
        <Col md={2} align="right">
          <Button
            className="font-weight-bold"
            color="info"
            disabled = {!Permission.Read}
            onClick={Export}
          >Export</Button>
        </Col>
      </Row>
    )

    const children = (
        <>
          <div className="content">
            <CommonList
              key={1}
              header={'Invoice'}
              columns={(!superAdmin && !allowInvoiceClosing) ? invoiceColumnsUser : invoiceColumnsSuperUser}
              // columns={invoiceColumnsUser}
              dataState={dataState}
              menu = {menu}
              // Add={addInvoiceData}
              load={loading}
              getData={getInvoice}
            />
          </div>
        </>
      );

    return (
      <>
      <PanelHeader size="sm" />
        {/* <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Invoice</h2>
              </div>
            }
          /> */}
        <LoadingScreen
          key={122}
          text = {"Loading"}
          loading = {loadingEx}
          children = {children}
        />
      </>
    )
  } catch (error) {
    return (
      <>
      <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Invoice</h2>
              </div>
            }
          />
      <h3>{error.message || error}</h3>
      </>
    )
  }
}

export default InvoiceLists;
