import React from "react";
import Select from "react-select"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getOutdoorDeviceData, getRefreshToken,
  OutdoorDevice, outdoorDeviceTypeOption } from "variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions"
import { DateTimestr } from "Main/Utils";
import RouterService from "Main/RouterService";
import LoadingScreen from "components/Loader/LoadingScreen";
import InputCoordinate from "components/Input/InputCoordinate";

function OutdoorDeviceInventoryView(props) {
  const routerService = new RouterService()
  const refreshToken = getRefreshToken()
  const outdoorDeviceData = getOutdoorDeviceData()
  const OutdoorDeviceOption = outdoorDeviceTypeOption()
  const id = outdoorDeviceData.id
  const routerid = outdoorDeviceData.routerid
  const [name, nameOnChange] = useState(outdoorDeviceData.name)
  const mitra = outdoorDeviceData.mitraName
  const created = outdoorDeviceData.creation
  const modified = outdoorDeviceData.modified
  const createdBy = outdoorDeviceData.created_by
  const modifiedBy = outdoorDeviceData.modified_by
  const province = outdoorDeviceData.province_name
  const subDistrict = outdoorDeviceData.sub_district
  const urban = outdoorDeviceData.urban
  const router = outdoorDeviceData.routerName + ': IP ' + outdoorDeviceData.ip_address + ' PORT ' + outdoorDeviceData.port
  const [deviceType, deviceTypeOnChange] = useState(OutdoorDeviceOption.find(e => e.label === outdoorDeviceData.device_type.toUpperCase()))
  const [coordinate, coordinateOnChange] = useState(outdoorDeviceData.coordinate)
  const [description, descriptionOnChange] = useState(outdoorDeviceData.description)
  const [loading, loadingOnChange] = useState(false)
  const [latitude, setLatitude] = useState(outdoorDeviceData.latitude)
  const [longitude, setLongitude] = useState(outdoorDeviceData.longitude)

  const update = async() => {
    try {
          loadingOnChange(true)
          const payload = OutdoorDevice(name, routerid, deviceType.value, latitude, longitude, description)
          const result = await routerService.putOutdoorDevice(id, payload)
          // await fetch(id)
          loadingOnChange(false)
          swal(result.status, result.message, "success").then(() => {
            props.history.push('/admin/router-update')
          })
        } catch (error) {
          loadingOnChange(false)
          return await CatchError(error, props, refreshToken)
       }
    }
// children={children} loading = {loading} text={"update Data"}
    const children = (
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Detail Perangkat Outdoor</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <Label>Nama</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Nama"
                            value = {name}
                            onChange={(e) => nameOnChange(e.target.value)}
                          />
                        <InputCoordinate
                            latitude = {latitude}
                            setLatitude = {setLatitude}
                            longitude = {longitude}
                            setLongitude = {setLongitude}
                          />
                        <Label>Jenis Perangkat</Label>
                            <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Jenis Perangkat"
                            name="singleSelect"
                            value = {deviceType}
                            options = {OutdoorDeviceOption}
                            onChange={(e) => { deviceTypeOnChange(e) }
                            }
                          />
                        <Label>Coordinate</Label>
                        <Input
                          name="min_length_bank_number"
                          placeholder="Coordinate"
                          value = {coordinate}
                          onChange={(e) => {
                            coordinateOnChange(e.target.value)
                          }}
                        />
                        </FormGroup>
                        <Label>Deskripsi</Label>
                          <Input
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Deskripsi"
                            name="singleSelect"
                            value = {description}
                            onChange = {(e) => {descriptionOnChange(e.target.value)}}
                          />
                        <Label>Mitra</Label>
                        <Input
                          placeholder="Mitra"
                          value={mitra}
                          type="text"
                        />
                        <Label>Provinsi</Label>
                          <Input
                            placeholder="Provinsi"
                            value = {province}
                            type="text"
                          />
                          <Label>Kabupaten</Label>
                          <Input
                            placeholder="Kabupaten"
                            value = {subDistrict}
                            type="text"
                          />
                          <Label>Kelurahan</Label>
                          <Input
                            placeholder="Kelurahan"
                            value = {urban}
                            type="text"
                          />
                          <Label>Router</Label>
                          <Input
                            placeholder="router"
                            value = {router}
                            type="text"
                          />
                          <Label>Created</Label>
                          <Input
                            placeholder="Created"
                            value = {DateTimestr(created)}
                            type="text"
                          />
                          <Label>modified</Label>
                          <Input
                            placeholder="Modified"
                            value = {DateTimestr(modified)}
                            type="text"
                          />
                          <Label>created By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {createdBy}
                            type="email"
                          />
                          <Label>modified By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {modifiedBy}
                            type="email"
                          />
                      </Col>
                    </Row>
                  <Button onClick = {update} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

      )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen children={children} loading = {loading} text={"Update Data"}/>
      </>
    );
}


export default OutdoorDeviceInventoryView;
