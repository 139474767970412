import React from "react";
import LoadingScreen from "components/Loader/LoadingScreen";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getOwner, getRefreshToken } from "variables/common"
import DashboardChart from "components/Chart/DashboardChart";
import CardStats from "components/Table/CardStats";
import { getMitraFinanceData } from "variables/FinanceStatement/FinanceVariable";
import FinanceStatementService from "Main/FinanceStatement/FinanceStatementService";
import SelectBasic from "components/Input/SelectBasic";
import { getExportDataType } from "variables/FinanceStatement/FinanceVariable";
import { CatchError } from "Main/Exceptions/exceptions";
import CustomerService from "Main/CustomerService";
import { parseDatetime } from "Main/Utils";

function FinanceStatementDetail(props) {
    const today = new Date()
    const { year: todayYear } = parseDatetime(today)
    const financeStatementService =  new FinanceStatementService()
    const customerService = new CustomerService()
    const ownerData = getOwner()
    const refreshToken = getRefreshToken()
    const exportDataType = getExportDataType()
    const [loading, loadingOnChange ] = useState(false)
    const [dashData, dashDataOnChange] = useState(getMitraFinanceData())
    const [ year, yearOnChange ] = useState({
        value: todayYear.toString(),
        label: todayYear.toString()
        })
    const [ month /* , monthOnChange */ ] = useState({})
    const [ type, typeOnChange ] = useState({})
    const [ customer, customerOnChange ] = useState({})
    const [ yearOption, yearOptionOnChange ] = useState([])
    const [ monthOption, monthOptionOnChange ] = useState([])
    const [ customerOption, customerOptionOnChange ] = useState([])
    const { revenueStats, name, id: mitraId } = dashData
    const owner = ownerData.find(own => own.value === name)
    const currency = 'idr'
    console.log(monthOption)
    const download = async() => {
        try {
            loadingOnChange(true)
            const paramValue = {
                mitra: owner.mitraid,
                mitraName: owner.label,
                year: year.value,
                month: month.value,
                type: type.value,
                customer: customer.value
            }
            const rawData = await financeStatementService.downloadFinanceReport(paramValue)
            loadingOnChange(false)
            // const filename = new Date().toLocaleDateString() + 'Finance-Report' // rawData.headers['content-disposition'].split('filename=')[1];
            // const typeData = rawData.headers['Content-Type'];
            // const extention = type.extention
            // await Downloads(rawData.data, filename, typeData, extention)
            return rawData
        } catch (error) {
            loadingOnChange(false)
            return await CatchError(error, props, refreshToken)
        }
    }

    const getCustomer = async (mitraid) => {
        // const defaultState = [{
        //     label: 'ALL',
        //     value: ''
        // }]
        const customerOption = await customerService.getCustomerSelect(mitraid)
        // defaultState.concat()
        customerOptionOnChange(customerOption.data)
        // defaultState.splice(0, defaultState.length)
    }

    const getyear = async (mitra) => {
        const result = await financeStatementService.getInvoiceYear(mitra)
        yearOptionOnChange(result.data)
    }

    const getMonth = async (mitra, year) => {
        const result = await financeStatementService.getInvoiceMonth({mitra, year})
        monthOptionOnChange(result.data)
    }

    const getReportData = async (mitra, year) => {
        const result = await financeStatementService.getData({mitra, year})
        dashDataOnChange(result.data)
    }

    useState(() => {
        getCustomer(mitraId)
        getyear(name)
        getMonth(name, year.value)
    }, [])

    const children = (
        <div className="content">
            <Row>
                <Col md="12">
                <Card>
                    <CardHeader>
                    <h5 className="title">Detail Laporan Keuangan - {name}</h5>
                    </CardHeader>
                    <CardBody>
                        <SelectBasic
                            label = {'Pilih Tahun'}
                            value = {year}
                            onChange = {async (e) => {
                                yearOnChange(e)
                                await getReportData(name, e.value)
                            }}
                            option = {yearOption}
                        />
                        <DashboardChart
                            data = {revenueStats}
                            chartLine1Label = {"Omset"}
                            chartLine2Label = {"Pemasukan"}
                            chartLine3Label = {"Piutang"}
                            chartLine4Label = {"Pengeluaran"}
                        />
                        <CardStats
                            title = {'Quick Access Report Hanya Mencakup Data Customer yang telah di Aktivasi.'}
                            data = {dashData}
                            currency = {currency}
                        />
                        <div>
                        <h5>Download Laporan Keuangan</h5>
                        <div>
                            <SelectBasic
                                label = {'Tipe Data Export'}
                                value = {type}
                                onChange = {(e) => typeOnChange(e)}
                                option = {exportDataType}
                            />
                            <SelectBasic
                                label = {'Mitra'}
                                isDisabled = {true}
                                value = {owner}
                            />
                            {/* <SelectBasic
                                label = {'Bulan'}
                                value = {month}
                                onChange = {(e) => monthOnChange(e)}
                                option = {monthOption}
                            /> */}
                            <SelectBasic
                                label = {'Customer'}
                                value = {customer}
                                onChange = {(e) => {
                                    customerOnChange(e)
                                }}
                                option = {customerOption}
                            />
                        </div>
                            <Button color="primary"
                            onClick={ async () => await download()}
                            disabled = {!type.value}
                            >Download {type.value}</Button>
                            {!customer.value ? null
                                : <p>Download Laporan Keuangan Tampa <strong>Quick Access Report</strong></p>
                            }
                        </div>
                    </CardBody>
                </Card>
                </Col>
            </Row>
            </div>
    )
        return (
        <>
            <PanelHeader size="sm" />
            <LoadingScreen
            children={children}
            loading = {loading}
            text={"Loading..."}/>
        </>
    );
}


export default FinanceStatementDetail;
