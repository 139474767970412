import LoginService from './../LoginService'
import APIconsume from './../APIconsume'
import { getAccessToken } from "./../../variables/common"

class LogisticItemService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'LogisticItem'
    }

    async post (payload) {
        const result = await this._consume.postData(payload, this._url)
        return result.data
    }

    async get (name) {
        const url = `${this._url}/${name}`
        // console.log(this._accessToken)
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAll (mitra = '', len = 'ALL', page = '') {
        let url = `${this._url}/view?mitra=${mitra}&line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async put (name, payload) {
        const url = `${this._url}/${name}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async delete (name, noRouter) {
        const url = `${this._url}/${name}` //`${this._url}/${name}?noRouter=${!noRouter}`
        const result = await this._consume.deleteData(url)
        return result.data
    }
}

export default LogisticItemService