const { getData, setData } = require("variables/common");

const identifier = ['submissionFinanceData', 'submissionFinanceList']
const [data, list] = identifier

export function addSubmissionFinanceData(payload) {
  setData(data, payload)
}

export function getSubmissionFinanceData() {
 return getData(data)
}

export function addSubmissionFinanceList(payload) {
  setData(list, payload)
}

export function getSubmissionFinanceList() {
 return getData(list)
}

export function payload(name, price, description, attachment, spendingTime, mitra) {
    return {
        name,
        price,
        description,
        attachment,
        spendingTime,
        mitra
    }
}

export function payloadUpdate(price, description) {
    return {
        price,
        description
    }
}

export function payloadTrash(id, trash) {
    return {
        id,
        trash
    }
}

export function payloadApprove(id, type, status) {
    return {
        id,
        type,
        status
    }
}

export function payloadDownload(id) {
    return {
        id
    }
}

export function FormDataPayload(name, price, description, attachment, spendingTime, mitra) {
    const formData = new FormData()
    const fileData = !attachment ? '' : attachment[0]
    const payloadRaw = payload(name, price, description, fileData, spendingTime, mitra)
    const keys = Object.keys(payloadRaw)
    for (const obj of keys) {
        if (obj === 'attachment') {
            const attachmentData = payloadRaw[obj]
            const attachmentArr = Array.from(attachmentData)
            for(const file of attachmentArr) {
                formData.append(obj, file, file.filename)
            }
        }
        formData.append(obj, payloadRaw[obj])
    }
    // const formDataSerealized = Object.fromEntries(formData);
    return formData
}