import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import {
  getRefreshToken,
  getOwner, getUserData,
  ItemExportPayload } from "variables/common"
import { CatchError } from "Main/Exceptions/exceptions";
import LoadingScreen from "components/Loader/LoadingScreen";
import SelecttWithUserTier from "components/Input/SelectWithUserTier";
import SelectBasic from "components/Input/SelectBasic";
import { getExportDataType } from "variables/FinanceStatement/FinanceVariable";
import ItemService from "Main/ItemService";

function ItemExport(props) {
  const itemService = new ItemService()
  const refreshToken = getRefreshToken()
  const ownerData = getOwner()
  const userData = getUserData()
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const [fileType, setFileType] = useState([])
  const [ loading, setLoading ] = useState(false)
  const fileTypeOption = getExportDataType()
  const [ changeMitra, setChangeMitra ] = useState()

  const ExportItemData = async() => {
        try {
          setLoading(true)
          const payload = ItemExportPayload(owner.mitraid, fileType.value)

          const result = await itemService.downloadItem(payload)
          setLoading(false)
          return result
        } catch (error) {
          setLoading(false)
          return await CatchError(error, props, refreshToken)
        }
  }


  const children = (
      <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Export Item Profile</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <SelecttWithUserTier
                              key={"1"}
                              label = {'Mitra *Wajib di Isi'}
                              value = {owner}
                              onChange = {ownerOnChange}
                              disabled = {changeMitra}
                              options = {ownerData}
                              subFunction = {() => {}}
                              // subFunction = {deleteSelect}
                              checkText = {'Ubah Mitra'}
                              checkValue = {changeMitra}
                              checkValueOnChange = {setChangeMitra}
                            />
                          <SelectBasic
                            key={"2"}
                            label = {'Tipe File *Wajib di Isi'}
                            value = {fileType}
                            option = {fileTypeOption}
                            onChange = {setFileType}
                          />
                        </FormGroup>
                      </Col>
                      </Row>
                  </Form>
                  <Button onClick = {ExportItemData} color="info">Export</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    )

    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen children={children} loading = {loading} text={"Export Profile"}/>
      </>
    );
}


export default ItemExport;
