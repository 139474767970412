import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Select from 'react-select'
import { useState } from "react";
import {getRefreshToken, getLogisticItemData,
  getLogisticWarehouseData, getLogisticSupplierData, getOwner} from "./../../../../variables/common"
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import { LogisticGoods } from "variables/common";
import { getLogisticItemOption } from "variables/common";
import { getLogisticWarehouseOption } from "variables/common";
import { getLogisticSupplierOption } from "variables/common";
import { GetItemCurrency } from "variables/common";
import { PriceFormat } from "variables/common";
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService";
import { GlobalPropsPush } from "./FetchLogistic";
import { verifyLength } from "Main/Utils";
import { FetchLogisticSelect } from "Main/Logistic/FetchLogistic";
import { useEffect } from "react";
import BasicInput from "components/Input/BasicInput";

function LogisticGoodsPage(props) {
  const Service = new LogisticGoodsService()
  const refreshToken = getRefreshToken();
  const name = useFormInput('')
  const description = useFormInput('')
  const itemData = getLogisticItemData()
  const warehouseData = getLogisticWarehouseData()
  const supplierData = getLogisticSupplierData()
  const itemId = itemData.id
  const warehouseId = warehouseData.id
  const supplierId = supplierData.id
  const selectCurrencyOptions = GetItemCurrency()
  const [logisticItemOptions, setlogisticItemOptions] = useState(getLogisticItemOption())
  const [warehouseOptions, setwarehouseOptions] = useState(getLogisticWarehouseOption())
  const [supplierOptions, setsupplierOptions] = useState(getLogisticSupplierOption())
  const logisticItemFilter = !itemId ? logisticItemOptions : logisticItemOptions.filter((data) => data.value === itemId)
  const warehouseFilter = !warehouseId ? warehouseOptions : warehouseOptions.filter((data) => data.value === warehouseId)
  const supplierFilter = !supplierId ? supplierOptions : supplierOptions.filter((data) => data.value === supplierId)
  const [logisticItem, logisticItemOnChange] = useState(logisticItemFilter.find((data) => data.value === itemId))
  const [warehouse, warehouseOnChange] = useState(warehouseOptions.find((data) => data.value === warehouseId))
  const [supplier, supplierOnChange] = useState(supplierOptions.find((data) => data.value === supplierId))
  const [sn, snOnChage] = useState()
  const damage = false
  const [ppn, ppnOnChange] = useState(false)
  const [priceValue, priceValueOnChange] = useState(itemData.price)
  const qty= useFormInput(1)
  const currency  = selectCurrencyOptions.find(data => data.label === 'Rupiah')
  const [manualId, manualIdOnChange] = useState(false)
  const [paid, paidOnChange] = useState(false)
  const [idManual, idManualOnChange] = useState()
  const [insertDate, insertDateOnChange] = useState()
  const ownerOption = getOwner()
  const owner = itemData.owner || warehouseData.owner || supplierData.owner
  const mitra = ownerOption.find((data) => data.value === owner)

  useEffect(() => {
    FetchSelectData(mitra.mitraid)
  }, [])

  const submit = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
    const payload = LogisticGoods(name.value,
        logisticItem.value,
        supplier.value,
        warehouse.value,
        damage,
        paid,
        priceValue,
        qty.value,
        ppn,
        sn,
        description.value,
        insertDate,
        idManual)
      const result = await Service.post(payload)

      await GlobalPropsPush(props, itemId, warehouseId, supplierId, result)
    } catch (error) {
      return CatchError(error, props, refreshToken)
    }
  }

  const FetchSelectData = async (mitra) => {
    const { logisticItem,
    logisticWarehouse,
    logisticSupplier } = await FetchLogisticSelect(mitra)
    setlogisticItemOptions(logisticItem)
    setwarehouseOptions(logisticWarehouse)
    setsupplierOptions(logisticSupplier)
    logisticItemOnChange(logisticItem.find((data) => data.value === itemId))
    warehouseOnChange(logisticWarehouse.find((data) => data.value === warehouseId))
    supplierOnChange(logisticSupplier.find((data) => data.value === supplierId))
  }

  return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Form Logistik Barang</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <div>
                        <Label>Manual ID</Label>
                          <Input
                            placeholder="Manual Id"
                            value = {idManual}
                            onChange = {(e) => {
                              if (!verifyLength(e.target.value, 13)) {
                                 idManualOnChange(e.target.value)
                              }}
                            }
                            type="text"
                            disabled = {!manualId}
                          />
                          </div>
                          <FormGroup check>
                          <Label check> Generate Manual
                            <Input type="checkbox"
                            value = {manualId}
                                  onChange = {e => manualIdOnChange(e.target.checked)}
                            />
                            <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        <BasicInput
                          label = {'Mitra'}
                          value = {mitra.label}
                        />
                        <FormGroup>
                          <Label>Nama *</Label>
                          <Input
                            placeholder="Nama"
                            {...name}
                            type="text"
                          />
                        <Label>Item Gudang</Label>
                        <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Item Gudang"
                            name="singleSelect"
                            value = {logisticItem}
                            options = {logisticItemFilter}
                            onChange={e => logisticItemOnChange(e)
                          }
                          />
                          <Label>Supplier</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Supplier"
                            name="singleSelect"
                            value = {supplier}
                            options = {supplierFilter}
                            onChange={e => supplierOnChange(e)
                          }
                          />
                          <Label>Gudang</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Gudang"
                            name="singleSelect"
                            value = {warehouse}
                            options = {warehouseFilter}
                            onChange={e => warehouseOnChange(e)
                          }
                          />
                          <FormGroup check>
                            <Label check> PPN
                              <Input type="checkbox"
                              value = {ppn}
                              onChange = {e => ppnOnChange(e.target.checked)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check> Lunas
                              <Input type="checkbox"
                              value = {paid}
                              onChange = {e => paidOnChange(e.target.checked)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                          <Label>Serial Number</Label>
                          <Input
                          className="react-select"
                          type="textarea"
                          value={sn}
                          disabled={!logisticItem || !logisticItem.sn}
                          onChange={(e) => snOnChage(e.target.value.toUpperCase())
                          }
                          />
                          <Label>QTY</Label>
                          <Input
                            placeholder="QTY"
                            {...qty}
                            disabled={!logisticItem || logisticItem.sn}
                            type="number"
                          />
                          <Label>Harga</Label>
                          <Input
                            placeholder="Harga"
                            value = {priceValue}
                            onChange = {(e) => {
                              if (e.target.value <= 0) {
                                priceValueOnChange(null)
                              }
                              if (e.target.value >= 0) {
                                priceValueOnChange(e.target.value)
                              }
                            }}
                            type="number"
                          />
                          <Input
                            placeholder="Harga"
                            value={PriceFormat(Number(priceValue), currency.value)}
                            type="text"
                          />
                          <Label>Insert Date</Label>
                            <Input
                              className = "generate"
                              placeholder="Insert Date"
                              value = {insertDate}
                              onChange = {e => {
                                  insertDateOnChange(e.target.value)
                                }}
                              type="datetime-local"
                            />
                          <Label>Deskripsi</Label>
                          <Input
                          className="react-select"
                          type="textarea"
                          {...description}
                          />
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {submit} color="info">Submit</Button>
                  {/* <Button onClick = {submitMany} color="info">Submit Many</Button> */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default LogisticGoodsPage;


