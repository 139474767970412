import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
  Table,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Select from 'react-select'
import { useState } from "react";
import {getRefreshToken, getLogisticItemData} from "variables/common"
import { getLogisticItemOption } from "variables/common";
import { getLogisticWarehouseOption } from "variables/common";
import { getLogisticSupplierOption } from "variables/common";
import { GetItemCurrency } from "variables/common";
import { PriceFormat } from "variables/common";
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService";
import { verifyLength } from "Main/Utils";
import CommonList from "../../../../../components/Table/CommonList";
import { nullCompensation } from "Main/Utils";
import { Datestr } from "Main/Utils";
import swal from "sweetalert";
import { snPackMap } from "variables/common";
import { LogisticGoodsBlob } from "variables/common";
import { baseBlob } from "variables/common";
import { customTextFromBoolean } from "Main/Utils";
import SubsStatusAdd from "./StatusAddList";
import { getUserPermission } from "variables/common";
import { FetchLogisticSelect } from "Main/Logistic/FetchLogistic";
import { getOwner } from "variables/common";
import { verifySuperAdmin } from "Main/Utils";
import { getAddBlob } from "variables/WebStorage/LogisticBlobStorage";
import { deleteAddBlob } from "variables/WebStorage/LogisticBlobStorage";
import { setAddBlob } from "variables/WebStorage/LogisticBlobStorage";
import { BlobDataLen } from "Main/Utils";
import { CatchErrorFlushData } from "Main/Exceptions/exceptions";

function LogisticGoodsBlobPage(props) {
  const pages = 'Logistic'
  const { mitraSuper, organization, id: userId } = verifySuperAdmin()
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const Service = new LogisticGoodsService()
  const refreshToken = getRefreshToken();
  const [name, nameOnChange] = useState('')
  const [description, descriptionOnChange] = useState('')
  const itemData = getLogisticItemData()
  const [snPack] = useState([])
  const [BlobData] = useState(getAddBlob(userId))
  const itemId = null
  const warehouseId = null
  const supplierId = null
  var selectCurrencyOptions = GetItemCurrency()
  const [logisticItemOptions, setlogisticItemOptions] = useState(getLogisticItemOption())
  const [warehouseOptions, setwarehouseOptions] = useState(getLogisticWarehouseOption())
  const [supplierOptions, setsupplierOptions] = useState(getLogisticSupplierOption())
  const logisticItemFilter = !itemId ? logisticItemOptions : logisticItemOptions.filter((data) => data.value === itemId)
  const warehouseFilter = !warehouseId ? warehouseOptions : warehouseOptions.filter((data) => data.value === warehouseId)
  const supplierFilter = !supplierId ? supplierOptions : supplierOptions.filter((data) => data.value === supplierId)
  const [logisticItem, logisticItemOnChange] = useState('')//logisticItemFilter.find((data) => data.value === itemId))
  const [warehouse, warehouseOnChange] = useState('')//warehouseOptions.find((data) => data.value === warehouseId))
  const [supplier, supplierOnChange] = useState('')//supplierOptions.find((data) => data.value === supplierId))
  const [sn, snOnChage] = useState(null)
  const damage = false
  const [ppn, ppnOnChange] = useState(false)
  const [priceValue, priceValueOnChange] = useState(itemData.price)
  const [qty, qtyOnChange] = useState(!logisticItem.sn ? 1 : snPack.length)
  const currency  = selectCurrencyOptions.find(data => data.label === 'Rupiah')
  const [manualId, manualIdOnChange] = useState(false)
  const [paid, paidOnChange] = useState(false)
  const [snLoad, setLoadSn] = useState(false)
  const [idManual, idManualOnChange] = useState(null)
  const [insertDate, insertDateOnChange] = useState()
  const [BlobResult, BlobResultOnChange] = useState([])
  const [dataLoad, setDataLoad] = useState(true)
  const [loadResult, setLoadResult] = useState(false)
  const [snExits, setsnExits] = useState(false)
  const [idExits, setidExits] = useState(false)
  const ownerOption = getOwner()
  const [mitra, mitraOnChange] = useState(ownerOption.find((data) => data.value === organization))

  const submit = async() => {
    try {
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = baseBlob(BlobData)
      console.log(payload)
      const result = await Service.postBlob(payload)
      swal("Success", result.message, "success").then(() => {
        nameOnChange('')
        logisticItemOnChange('')
        warehouseOnChange('')
        supplierOnChange('')
        snOnChage('')
        ppnOnChange(false)
        priceValueOnChange(0)
        qtyOnChange(snPack.length)
        manualIdOnChange(false)
        paidOnChange(false)
        idManualOnChange('')
        insertDateOnChange(null)
        setLoadResult(false)
        descriptionOnChange('')
        BlobData.splice(0, BlobData.length)
        setDataState(mapping(BlobData))
        BlobResultOnChange(result.data)
        deleteAddBlob(userId)
      })
    } catch (error) {
      return await CatchErrorFlushData(error, props, refreshToken, userId)
    }
  }

  useState(() => {
    FetchLogisticSelect()
  }, [])
  const add = () => {
    setDataLoad(true)
    setLoadResult(true)
    const payload = LogisticGoodsBlob(name,
        logisticItem.value,
        supplier.value,
        warehouse.value,
        damage,
        paid,
        priceValue,
        qty,
        ppn,
        snPack,
        description,
        insertDate)
    BlobData.push(payload)
    setAddBlob(BlobData, userId)
    // BlobDataonChange(state)
    insertDateOnChange(null)
    // loadBlobData()
    setDataState(mapping(BlobData))
    BlobResult.splice(0, BlobResult.length)
    snPack.splice(0, snPack.length)
    setsnDataState(MapSn(snPack))
    nameOnChange('')
    logisticItemOnChange('')
    warehouseOnChange('')
    supplierOnChange('')
    snOnChage('')
    ppnOnChange(false)
    priceValueOnChange(0)
    qtyOnChange(snPack.length)
    manualIdOnChange(false)
    paidOnChange(false)
    idManualOnChange('')
    insertDateOnChange(null)
    descriptionOnChange('')
    setDataLoad(false)
    setLoadResult(false)
    console.log('Blob Check', baseBlob(BlobData))
  }

  const addSnPack = () => {
    setLoadSn(true)
    const payload = snPackMap(sn, idManual)
    snPack.push(payload)
    console.log('blobdata', snPack)
    setsnDataState(MapSn(snPack))
    qtyOnChange(snPack.length)
    snOnChage('')
    idManualOnChange('')
    setLoadSn(false)
  }

  const columns = [
        {
        Header: "Nama",
        accessor: "name",
        },
        {
        Header: "Item",
        accessor: "logisticItem",
        },
        {
        Header: "Supplier",
        accessor: "supplier",
        },
        {
        Header: "Gudang",
        accessor: "warehouse",
        },
        {
        Header: "Status",
        accessor: "paid",
        },
        {
        Header: "Harga",
        accessor: "price",
        },
        {
        Header: "QTY",
        accessor: "qty",
        },
        {
        Header: "Tanggal",
        accessor: "date",
        },
        {
        Header: "Hapus",
        accessor: "actions",
        }
    ]

  const DeleteBlobData = (id) => {
    setDataLoad(true)
    BlobData.pop(id)
    setDataState(mapping(BlobData))
    setAddBlob(BlobData, userId)
    setDataLoad(false)
  }

  const DeleteSnPack = (id) => {
    setDataLoad(true)
    snPack.pop(id)
    setsnDataState(MapSn(snPack))
    qtyOnChange(snPack.length)
    setDataLoad(false)
  }

  const mapping = (Dataset) => Dataset.map((Data, i) => {
    try {
        const warehouse = !warehouseOptions ? {label: Data.warehouseId || 'no-label'} : warehouseOptions.find((warehouse) => warehouse.value === Data.warehouseId) || ''
        const supplier = !supplierOptions ? {label:  Data.supplierId || 'no-label'} : supplierOptions.find((warehouse) => warehouse.value === Data.supplierId) || ''
        const logisticItem = !logisticItemOptions ? {label: Data.logisticItemId || 'no-label'} : logisticItemOptions.find((warehouse) => warehouse.value === Data.logisticItemId) || ''
        return {
            id: i,
            name: Data.name,
            logisticItem: logisticItem.label,
            supplier: supplier.label,
            warehouse: warehouse.label,
            paid: customTextFromBoolean(Data.paid, 'Lunas', 'Belum-Bayar'),
            price: PriceFormat(Data.price, 'idr'),
            qty: Data.qty,
            date: Datestr(Data.dateCreation),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.snTarget}>
                <Button
                  onClick={ async () => {
                    await DeleteBlobData(i)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.snTarget}11`}
                  color="danger"
                  size="sm"
                >
                  <i className="fa fa-times" />
                </Button>{" "}
              </div>
            ),
          };
          } catch (error) {
            console.log(error.message)
            return []
          }
        })

  const MapSn = (SnData) =>  SnData.map((Dat, i) => {
        return {
          idx: i + 1,
          sn: nullCompensation(Dat.sn),
          manualId: nullCompensation(Dat.manualId),
          action: (
            <Button
              color="danger"
              onClick={() => DeleteSnPack(i)}
            >Delete</Button>
          )
        }
      })

  const SnPackList = ({load, snData}) => {
    if (load) {
      return (
        <dov className = "Height-300px">
          <Table bordered hover>
          Loading...
          </Table>
        </dov>
      )
    }
    return (
        <div className = "Height-300px">
          <Table bordered hover>
              <tbody>
                {snData.map((Dat) => {
                return (
                  <tr>
                  <td>{Dat.idx}</td>
                  <td>{Dat.sn}</td>
                  <td>{Dat.manualId}</td>
                  <td>
                    {Dat.action}
                  </td>
                  </tr>
                )
              })}
              </tbody>
        </Table>
      </div>
      )
  }

  const [DataState, setDataState] = useState(
      mapping(BlobData)
  )

   const [snDataState, setsnDataState] = useState(
      MapSn(snPack)
  )

  const verifySn = (sn) => {
    const validate = snPack.find((data) => data.sn === sn)
    if (!validate) {
      setsnExits(false)
      return validate
    }
    setsnExits(true)
    return validate
  }

  const verifyIdManual = (id) => {
    const validate = snPack.find((data) => data.manualId === id)
    if (!validate) {
      setidExits(false)
      return validate
    }
    setidExits(true)
    return validate
  }

  const FetchSelectData = async (mitra) => {
    const { logisticItem,
    logisticWarehouse,
    logisticSupplier } = await FetchLogisticSelect(mitra)
    setlogisticItemOptions(logisticItem)
    setwarehouseOptions(logisticWarehouse)
    setsupplierOptions(logisticSupplier)
    logisticItemOnChange('')
    warehouseOnChange('')
    supplierOnChange('')
  }

  return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">BLOB Form Logistic Barang</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <Select
                          // className="react-select"
                          // classNamePrefix="react-select"
                          placeholder="Mitra"
                          value={mitra}
                          isDisabled = {!mitraSuper}
                          options={ownerOption}
                          onChange={ async (e) => {
                            mitraOnChange(e);
                            await FetchSelectData(e.mitraid)
                        } } />
                        <Label>Item Gudang</Label>
                        <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Item Gudang"
                            name="singleSelect"
                            value = {logisticItem}
                            options = {logisticItemFilter}
                            onChange={e => logisticItemOnChange(e)
                          }
                          />
                        <Label>Nama *</Label>
                          <Input
                            placeholder="Nama"
                            value={name}
                            onChange = {(e) => nameOnChange(e.target.value)}
                            type="text"
                          />
                          <Label>Supplier</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Supplier"
                            name="singleSelect"
                            value = {supplier}
                            options = {supplierFilter}
                            onChange={e => supplierOnChange(e)
                          }
                          />
                          <Label>Gudang</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Gudang"
                            name="singleSelect"
                            value = {warehouse}
                            options = {warehouseFilter}
                            onChange={e => warehouseOnChange(e)
                          }
                          />
                          <FormGroup check>
                            <Label check> PPN
                              <Input type="checkbox"
                              value = {ppn}
                              onChange = {e => ppnOnChange(e.target.checked)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check> Lunas
                              <Input type="checkbox"
                              value = {paid}
                              onChange = {e => paidOnChange(e.target.checked)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                          <Label>Harga</Label>
                          <Input
                            placeholder="Harga"
                            value = {priceValue}
                            onChange = {(e) => {
                              if (e.target.value <= 0) {
                                priceValueOnChange(null)
                              }
                              if (e.target.value >= 0) {
                                priceValueOnChange(e.target.value)
                              }
                            }}
                            type="number"
                          />
                          <Input
                            placeholder="Harga"
                            value={PriceFormat(Number(priceValue), currency.value)}
                            type="text"
                          />
                          <Label>Tanggal</Label>
                            <Input
                              className = "generate"
                              placeholder="Tanggal"
                              value = {insertDate}
                              onChange = {e => {
                                  insertDateOnChange(e.target.value)
                                }}
                              type="datetime-local"
                            />
                          <Label>Deskripsi</Label>
                          <Input
                          className="react-select"
                          type="textarea"
                          value={description}
                          onChange = {(e) => descriptionOnChange(e.target.value)}
                          />
                        <Label>QTY</Label>
                          <Input
                            placeholder="QTY"
                            value = {qty}
                            onChange = {(e) => qtyOnChange(e.target.value)}
                            disabled={!logisticItem || logisticItem.sn}
                            type="number"
                          />
                        <Label>Manual Serial Number Dan ID</Label>
                        <Row>
                              {/* <Label>Serial Number</Label> */}
                              <Col md={6}>
                              <Input
                                className="react-select"
                                placeholder="Serial Number"
                                value={sn}
                                disabled={!logisticItem || !logisticItem.sn}
                                onChange={(e) => {
                                  snOnChage(e.target.value.toUpperCase())
                                  verifySn(e.target.value.toUpperCase())
                                }}
                              />
                              {snExits ? (
                              <label className="error">
                                Serial Number Telah dipakai, coba Serial Number lain
                              </label>
                              ) : null}
                            </Col>
                            <Col md={4}>
                              <div>
                              {/* <Label>Manual ID</Label> */}
                                <Input
                                  placeholder="Manual ID"
                                  value = {idManual}
                                  onChange = {(e) => {
                                    if (!verifyLength(e.target.value, 13)) {
                                      idManualOnChange(e.target.value)
                                      verifyIdManual(e.target.value)
                                    }}
                                  }
                                  disabled = {!manualId}
                                />
                                {idExits ? (
                                <label className="error">
                                  ID Telah dipakai, coba ID lain
                                </label>
                                ) : null}
                                </div>
                                </Col>
                                <Col md={2}>
                                  <Button onClick = {addSnPack}
                                  disabled = {!logisticItem.sn ? snPack.length >= 1 : (snExits || idExits)}
                                  color="info">Add SN</Button>
                                </Col>
                            </Row>
                          <FormGroup check>
                              <Label check> Generate Manual
                                <Input type="checkbox"
                                value = {manualId}
                                  onChange = {e => manualIdOnChange(e.target.checked)}
                                />
                                <span className="form-check-sign" />
                                </Label>
                              </FormGroup>
                        </FormGroup>
                        </Col>
                    </Row>
                    <SnPackList
                      key={1}
                      load={snLoad}
                      snData={snDataState}
                    />
                  <Button onClick = {add}
                    disabled = {!logisticItem.value & !warehouse.value & !supplier.value}
                    color="info">Add</Button>
                  <SubsStatusAdd
                      key={2}
                      Data={BlobResult}
                      load = {loadResult}
                      // loadData = {GetResultData}
                    />
                    <CommonList
                      key = {3}
                      load={dataLoad}
                      dataState={DataState}
                      // getData={[]}
                      loadingLabel = {'Data Stored, Add new Data to Show'}
                      columns = {columns}
                      header={'Logistic Data'}
                    />
                  <BlobDataLen
                    blob = {BlobData}
                  />
                  <Button onClick = {submit}
                    disabled = {!Permission.Create && !BlobData.length}
                    color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default LogisticGoodsBlobPage;


