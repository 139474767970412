import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Select from 'react-select'
import { useState } from "react";
import { getRefreshToken,
  getLogisticItemData, getCustomerOptions,
  getLogisticGoodsCustomerData,
  LogisticGoodsCustomer,
  getOwner} from "./../../../../variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import { getLogisticItemOption } from "variables/common";
import { getLogisticSupplierOption } from "variables/common";
import { GetItemCurrency } from "variables/common";
import { PriceFormat } from "variables/common";
import LogisticGoodsService from "Main/Logistic/LogisticGoodsService";
import { FetchLogistic } from "./FetchLogistic";
import { DateTimestr } from "Main/Utils";
import Checklist from "components/Button/Checklist";
import { FetchLogisticSelect } from "Main/Logistic/FetchLogistic";
import CustomerService from "Main/CustomerService";
import { useEffect } from "react";

function LogisticGoodsCustomerUpdate(props) {
  const Service = new LogisticGoodsService()
  const customerService = new CustomerService()
  var selectCurrencyOptions = GetItemCurrency()
  const [logisticItemOptions, setlogisticItemOptions] = useState(getLogisticItemOption())
  const [customerOptions, setcustomerOptions] = useState(getCustomerOptions())
  const [supplierOptions, setsupplierOptions] = useState(getLogisticSupplierOption())
  const itemData = getLogisticItemData()
  const refreshToken = getRefreshToken();
  const LogisticGoodsData = getLogisticGoodsCustomerData()
  const id = LogisticGoodsData.id
  const itemId = itemData.id
  const name = useFormInput(LogisticGoodsData.name)
  const description = useFormInput(LogisticGoodsData.description)
  const [logisticItem, logisticItemOnChange] = useState(logisticItemOptions.find((data) => data.value === LogisticGoodsData.logisticItemId) || '')
  const [customer, customerOnChange] = useState(customerOptions.find((data) => data.value === LogisticGoodsData.customerId))
  const [supplier, supplierOnChange] = useState(supplierOptions.find((data) => data.value === LogisticGoodsData.supplierId))
  const [sn, snOnChage] = useState(LogisticGoodsData.sn)
  const [damage, damageOnChange] = useState(LogisticGoodsData.damaged)
  const [ppn, ppnOnChange] = useState(LogisticGoodsData.ppn)
  const [priceValue, priceValueOnChange] = useState(LogisticGoodsData.price)
  const [paid, paidOnChange] = useState(LogisticGoodsData.paid)
  const qty= useFormInput(LogisticGoodsData.qty)
  const currency  = selectCurrencyOptions.find(data => data.label === 'Rupiah')
  const created = LogisticGoodsData.creation
  const modified = LogisticGoodsData.modified
  const createdBy = LogisticGoodsData.createdBy
  const modifiedBy = LogisticGoodsData.modifiedBy
  const owner = LogisticGoodsData.owner
   const ownerOption = getOwner()
  const mitra = ownerOption.find((data) => data.value === owner)

  useEffect(() => {
    FetchSelectData(mitra.mitraid)
  }, [])

  const Edit = async() => {
    swal("Update", "Perubahan Data Tidak Dapat di Batalkan", "info", {
                buttons: {
                update: {text: "Update", value: 'up' },
                cancel: 'Batal'
              }
            }
      ).then(async(value) => {
        switch (value) {
          case 'up':
            try {
            const payload = LogisticGoodsCustomer(name.value,
                logisticItem.value,
                supplier.value,
                customer.value,
                damage,
                priceValue,
                paid,
                qty.value,
                ppn,
                description.value)
              const result = await Service.putLogisticCustomer(id, payload)
              swal('Success', result.message, result.status).then(async () => {
                await FetchLogistic(itemId)
                props.history.push('/admin/logistic-item-update')
              })
            } catch (error) {
            return await CatchError(error, props, refreshToken)
            }
            break;

          default:
            break;
        }
      })
    }

  const FetchSelectData = async (mitra) => {
    const { logisticItem,
    logisticSupplier } = await FetchLogisticSelect(mitra)
    setlogisticItemOptions(logisticItem)
    setsupplierOptions(logisticSupplier)
    logisticItemOnChange(logisticItem.find((data) => data.value === LogisticGoodsData.logisticItemId))
    supplierOnChange(logisticSupplier.find((data) => data.value === LogisticGoodsData.supplierId))
    await getCustomer(mitra)
  }

  const getCustomer = async (mitraid) => {
    const customerOption = await customerService.getCustomerSelect(mitraid)
    const options = customerOption.data
    setcustomerOptions(options)
    customerOnChange(options.find((data) => data.value === LogisticGoodsData.customerId))
  }

  return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Detail Barang Logistik Customer</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <Label>Nama *</Label>
                          <Input
                            placeholder="Nama"
                            {...name}
                            type="text"
                          />
                        <Label>Item Gudang</Label>
                        <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Item Gudang"
                            name="singleSelect"
                            value = {logisticItem}
                            options = {logisticItemOptions}
                            onChange={e => logisticItemOnChange(e)
                          }
                          />
                          <Label>Supplier</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Supplier"
                            name="singleSelect"
                            value = {supplier}
                            options = {supplierOptions}
                            onChange={e => supplierOnChange(e)
                          }
                          />
                          <Label>Customer</Label>
                          <Select
                            // className="react-select"
                            // classNamePrefix="react-select"
                            placeholder="Customer"
                            name="singleSelect"
                            value = {customer}
                            options = {customerOptions}
                            onChange={e => customerOnChange(e)
                          }
                          />
                          <FormGroup check>
                            <Label check> PPN
                              <Input type="checkbox"
                              value = {ppn}
                              checked={ppn}
                              onChange = {e => ppnOnChange(e.target.checked)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check> Rusak
                              <Input type="checkbox"
                              value = {damage}
                              checked={damage}
                              onChange = {e => damageOnChange(e.target.checked)}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                          <Checklist
                            text={'Lunas'}
                            value={paid}
                            onChange={(e) => {
                              paidOnChange(e.target.checked)
                            }}
                            checked={paid}
                          />
                          <Label>Serial Number</Label>
                          <Input
                          className="react-select"
                          type="textarea"
                          value={sn}
                          disabled={!logisticItem.sn}
                          onChange={(e) => snOnChage(e.target.value.toUpperCase())
                          }
                          />
                          <Label>QTY</Label>
                          <Input
                            placeholder="QTY"
                            {...qty}
                            disabled={logisticItem.sn}
                            type="number"
                          />
                          <Label>Harga</Label>
                          <Input
                            placeholder="Harga"
                            value = {priceValue}
                            onChange = {(e) => {
                              if (e.target.value <= 0) {
                                priceValueOnChange(null)
                              }
                              if (e.target.value >= 0) {
                                priceValueOnChange(e.target.value)
                              }
                            }}
                            type="number"
                          />
                          <Input
                            placeholder="Harga"
                            value={PriceFormat(Number(priceValue), currency.value)}
                            type="text"
                          />
                          <Label>Deskripsi</Label>
                          <Input
                          className="react-select"
                          type="textarea"
                          {...description}
                          />
                          <Label>Owner</Label>
                          <Input
                            placeholder="Modified By"
                            value = {owner}
                            type="email"
                          />
                          <Label>Created</Label>
                          <Input
                            placeholder="Created"
                            value = {DateTimestr(created)}
                            type="text"
                          />
                          <Label>modified</Label>
                          <Input
                            placeholder="Modified"
                            value = {DateTimestr(modified)}
                            type="text"
                          />
                          <Label>created By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {createdBy}
                            type="email"
                          />
                          <Label>modified By</Label>
                          <Input
                            placeholder="Modified By"
                            value = {modifiedBy}
                            type="email"
                          />
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {Edit} color="info">Edit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}


export default LogisticGoodsCustomerUpdate;


