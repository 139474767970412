import React from "react";
import LoadingScreen from "components/Loader/LoadingScreen";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { useState } from "react";
import { getRefreshToken,  getUserData, getOwner } from "variables/common"
import swal from "sweetalert";
import {CatchError} from "Main/Exceptions/exceptions";
import BasicInput from "components/Input/BasicInput";
import SelecttWithUserTier from "components/Input/SelectWithUserTier";
import Checklist from "components/Button/Checklist";
import ErpNextClientService from "Main/ErpNextClient/ErpNextClientService";
import { payload } from "variables/ErpNextClient/ErpNextClient";
import { addErpNextClientList } from "variables/ErpNextClient/ErpNextClient";

function ErpNextClientPage(props) {
  const erpNextClientService =  new ErpNextClientService()
  const refreshToken = getRefreshToken()
  const userData = getUserData()
  const ownerData = getOwner()
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const [loading, loadingOnChange ] = useState(false)
  const [changeMitra, changeMitraOnChange] = useState(false)
  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [apiSecret, setApiSecret] = useState('')
  const [sync, setSync] = useState(false)
  const [view, setView] = useState(false)

  const getAll =  async() => {
    const len = 'ALL'
    const result =  await erpNextClientService.getAllData(len)
    addErpNextClientList(result.data)
  }

  const submit = async(e) => {
    e.preventDefault()
    try {
      loadingOnChange(true)
      const payloadFinal = payload(owner.mitraid, name, url, apiKey, apiSecret, sync)
      console.log(payloadFinal)
      const result = await erpNextClientService.addData(payloadFinal)
      await getAll()
      await swal(result.status, result.message, "success")
      loadingOnChange(false)
      props.history.push('/admin/erpnext-client-list')

    } catch (error) {
      loadingOnChange(false)
      return await CatchError(error, props, refreshToken)
  }}


  const children = (
    <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Form ErpNext Client</h5>
                </CardHeader>
                <CardBody>
                  <SelecttWithUserTier
                    key={"1"}
                    label = {'Mitra'}
                    value = {owner}
                    onChange = {ownerOnChange}
                    disabled = {changeMitra}
                    options = {ownerData}
                    // subFunction = {deleteSelect}
                    checkText = {'Ganti Mitra'}
                    checkValue = {changeMitra}
                    checkValueOnChange = {changeMitraOnChange}
                  />
                  <BasicInput
                    key={"2"}
                    label = {'nama'}
                    value = {name}
                    onChange = {setName}
                    type
                  />
                  <BasicInput
                    key={"3"}
                    label = {'url'}
                    value = {url}
                    onChange = {setUrl}
                    type
                  />
                  <BasicInput
                    key={"4"}
                    label = {'API Key'}
                    value = {apiKey}
                    onChange = {setApiKey}
                    type = {"Text"}
                  />
                  <BasicInput
                    key={"5"}
                    label = {'API Secret'}
                    value = {apiSecret}
                    onChange = {setApiSecret}
                    type = {!view ? "password" : "text"}
                  />
                  <Checklist
                    key={"6"}
                    text = {"View Secret"}
                    value = {view}
                    onChange = {e => setView(e.target.checked)}
                  />
                  <Checklist
                    key={"7"}
                    text = {"Aktifkan Sinkronisasi ERP-Next"}
                    value = {sync}
                    onChange = {(e) => setSync(e.target.checked)}
                  />
                  <Button onClick = {submit}
                  disabled = {!url}
                  color="info">Submit</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
  )
    return (
      <>
        <PanelHeader size="sm" />
        <LoadingScreen
          children={children}
          loading = {loading}
          text={"Submit"}/>
      </>
    );
}


export default ErpNextClientPage;
