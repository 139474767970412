import FileDownloads from './Download/DownloadService'
import axios from 'axios'
import data from './ApiConfig.json'

class ConsumeAPI {
  constructor (accessToken) {
    this._header = `Bearer ${accessToken}`
    this._pathServer = `${data.TYPE}://${data.API_SERVER}/`
  }

 async getData (path) {
    let config = {
      method: 'get',
      url: `${this._pathServer}${path}`,
      headers: {
        Authorization: this._header
      }
    }

    if (!this._header) {
      config = {
      method: 'get',
      url: `${this._pathServer}${path}`,
    }
    }
    return await axios(config)
  }

 async postData (data, path, type) {
    let config = {
      method: 'post',
      url: `${this._pathServer}${path}`,
      data: data,
      headers: {
        Authorization: this._header,
        'Content-Type': type || 'application/json'
      }
    }
     if (!this._header) {
       config = {
       method: 'post',
       url: `${this._pathServer}${path}`,
       data: data
     }
     }
    return await axios(config)
  }

  async putData (data, path, type) {
    let config = {
      method: 'put',
      url: `${this._pathServer}${path}`,
      data: data,
      headers: {
        'Content-Type': type || 'application/json',
        Authorization: this._header
      }
    }
    if (!this._header) {
      config = {
      method: 'put',
      url: `${this._pathServer}${path}`,
      data: data
    }
    }
    return await axios(config)
  }

    async patchData (data, path, type) {
      let config = {
        method: 'patch',
        url: `${this._pathServer}${path}`,
        data: data,
        headers: {
          'Content-Type': type || 'application/json',
          Authorization: this._header
        }
      }

      if (!this._header) {
        config = {
        method: 'patch',
        url: `${this._pathServer}${path}`,
        data: data
        }
      }
      return await axios(config)
  }


 async deleteData (path, data) {
    let config = {
      method: 'delete',
      data: data,
      url: `${this._pathServer}${path}`,
      headers: {
        Authorization: this._header
      }
    }
    if (!this._header) {
      config = {
      method: 'delete',
      data: data,
      url: `${this._pathServer}${path}`
      }
    }
    return await axios(config)
  }

  async GlobalDownload (path, Filename, Extention, headers) {
    let config = {
      method: 'get',
      responseType: "blob",
      url: `${this._pathServer}${path}`,
      headers: headers || {
        Authorization: this._header
      }
    }
    if (!this._header) {
      config = {
      method: 'get',
      responseType: "blob",
      url: `${this._pathServer}${path}`
      }
    }
    return await axios(config).then(response => {
        return FileDownloads(response, Filename, Extention)
    });
  }

  async GlobalDownloadPost (payload, path, Filename, Extention, headers) {
    let config = {
      method: 'post',
      responseType: "blob",
      data: payload,
      url: `${this._pathServer}${path}`,
      headers: headers || {
        Authorization: this._header
      }
    }
    if (!this._header) {
      config = {
      method: 'post',
      responseType: "blob",
      data: payload,
      url: `${this._pathServer}${path}`
      }
    }
    return await axios(config).then(response => {
        return FileDownloads(response, Filename, Extention)
    });
  }
}

export default ConsumeAPI
