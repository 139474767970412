import React, { useEffect } from "react";
import LoadingScreen from "components/Loader/LoadingScreen";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Select from "react-select"
import { useState } from "react";
import {getRefreshToken,
  GetBillingStatus,
  getRouterSelect,
  getOwner,
  getUserPermission} from "variables/common"
import { CatchError } from "Main/Exceptions/exceptions";
import swal from "sweetalert";
import CustomerService from "Main/CustomerService";
import { DateTimestr } from "Main/Utils";
import RouterService from "Main/RouterService";
import BasicInput from "components/Input/BasicInput";
import SelectBasic from "components/Input/SelectBasic";
import SubscriptionAddOnService from "Main/Add-On/SubscriptionAddOnService";
import { payloadUpdate } from "variables/Add-On/SubscriptionAddOn";
import { getSubscriptionAddOnData } from "variables/Add-On/SubscriptionAddOn";


function SubscriptionAddOnUpdate(props) {
  const pages = 'billing'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const subscriptionAddOnService =  new SubscriptionAddOnService()
  const customerService = new CustomerService()
  const routerService = new RouterService()
  const refreshToken = getRefreshToken()
  const BillingData = getSubscriptionAddOnData()
  var statusOptions = GetBillingStatus()
  const ownerData = getOwner()
  const [routerSelectOption, routerSelectOptionOnChange] = useState(getRouterSelect())
  const [selectItemOptions, selectItemOptionsOnChange] = useState([])
  const {id, creation, modified,
    createdBy, modifiedBy,
    routerid, AddonsId,
    customerName, customerCategory: category,
    subscriptionName: name} = BillingData
  const owner = ownerData.find((own) => own.value === BillingData.owner)
  const [itemAddOn , itemAddOnOnChange] = useState(selectItemOptions.find(item => item.value === BillingData.AddonsId || []))
  const [status , statusOnChange] = useState(statusOptions.find(stat => stat.value === BillingData.status))
  const [loading, loadingOnChange] = useState(false)
  const start = DateTimestr(BillingData.startSubscribe)
  const end = DateTimestr(BillingData.endSubscribe)
  const [router, routerOnChange] = useState(routerSelectOption.find((rtr) => rtr.value === BillingData.routerid) || [])

  useEffect(() => {
    getRouter(owner.mitraid)
  }, [])

  const getRouter = async(mitraid) => {
    // sessionStorage.removeItem('urban')
    const dat = await routerService.getRouterSelect(mitraid)
    const result = dat.data
    routerSelectOptionOnChange(result)
    const selected = result.find((rtr) => rtr.value === routerid) || []
    routerOnChange(selected)
    await getItemAddOn(selected.value, owner.mitraid, category)
    return result
  }

  const getItemAddOn = async(routerid, mitraid, category) => {
    // sessionStorage.removeItem('urban')
    const dat = await customerService.getItemAddOnSelect(routerid, mitraid, category)
    const result = dat.data
    selectItemOptionsOnChange(result)
    itemAddOnOnChange(result.find(item => item.value === AddonsId) || [])
    return result
  }


  const putData = async() => {
    swal("Update", "Change Data Can't be Undo", "info", {
                buttons: {
                update: {text: "Update", value: 'up' },
                cancel: 'Cancel'
              }
            }
      ).then(async(value) => {
        switch (value) {
          case 'up':
            loadingOnChange(true)
            try {
              const payload = payloadUpdate(owner.mitraid, itemAddOn.value)

              const result = await subscriptionAddOnService.putData(id, payload)
              loadingOnChange(false)
              swal(result.status, result.message, 'success').then(() => {
                props.history.push('/admin/Billing-update')
              })
            } catch (error) {
              loadingOnChange(false)
              return await CatchError(error, props, refreshToken)
            }
            break;

          default:
            break;
        }
      })
    }

  const children = (
    <><div className="content">
          <Row>
          <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">{name}</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                        <BasicInput
                          label = "Owner"
                          value={owner.value}
                          />
                        <div>
                          <BasicInput
                            label = "Customer"
                            value={customerName}
                            />
                          <Label>Customer Category {category}</Label>
                        </div>
                        <Label>Available Router Location</Label>
                            <div>
                            <Select
                              // className="react-select"
                              // classNamePrefix="react-select"
                              placeholder="Router Location"
                              name="singleSelect"
                              value = {router}
                              isDisabled ={true}
                              options={routerSelectOption}
                              onChange={(value) => {
                                routerOnChange(value)
                                getItemAddOn(value.value, owner.mitraid, category)
                                itemAddOnOnChange('')
                                }
                              }
                            />
                            {!router.status ? (
                              <label className="error">
                                Router Offline
                              </label>
                            ) : <label className="success">
                                Router Online
                              </label>}
                          </div>
                          <SelectBasic
                            label = {"Paket Layanan Add-On"}
                            value = {itemAddOn}
                            option={selectItemOptions}
                            onChange={(value) => {
                              itemAddOnOnChange(value)
                            }}
                        />
                        <SelectBasic
                            label = {"Status"}
                            isDisabled = {true}
                            value = {status}
                            option={statusOptions}
                            onChange={(value) => {
                              statusOnChange(value)
                            }}
                        />
                        <BasicInput
                          label = "Mulai Langganan"
                          value={start}
                        />
                        <BasicInput
                          label = "Akhir Langganan"
                          value={end}
                        />
                        <BasicInput
                          label = "Creation"
                          value={creation}
                        />
                        <BasicInput
                          label = "Modified"
                          value={modified}
                        />
                        <BasicInput
                          label = "Created By"
                          value={createdBy}
                        />
                        <BasicInput
                          label = "Modified By"
                          value={modifiedBy}
                        />
                        </FormGroup>
                      </Col>
                      </Row>
                  </Form>
                  <Button onClick = {putData}
                    color="info"
                    disabled = {!Permission.Update}>Update</Button>
                </CardBody>
              </Card>
            </Col>
        </Row>
      </div>
    </>
  );
return (
    <>
    <PanelHeader size="sm" />
    <LoadingScreen children={children} loading = {loading}/>
    </>
  );
}


export default SubscriptionAddOnUpdate;
