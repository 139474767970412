import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {getRefreshToken,
  addLogisticWarehouseList,
  getOwner,
  LogisticWarehouseAdd} from "./../../../variables/common"
import swal from "sweetalert";
import { CatchError } from "Main/Exceptions/exceptions";
import { useFormInput } from "Main/Utils";
import LogisticWarehouseService from "Main/Logistic/LogisticWarehouseService";
import SelectBasic from "components/Input/SelectBasic";
import { verifySuperAdmin } from "Main/Utils";
import InputCoordinate from "components/Input/InputCoordinate";
import LoadingScreen from "components/Loader/LoadingScreen";

function LogisticItemPage(props) {
  const Service = new LogisticWarehouseService()
  const refreshToken = getRefreshToken();
  const name = useFormInput('')
  const [latitude, latitudeOnChange] = useState(0.0)
  const [longitude, longitudeOnChange] = useState(0.0)
  const address = useFormInput('')
  const ownerOption = getOwner()
  const { mitraSuper, organization } = verifySuperAdmin()
  const [mitra, mitraOnChange] = useState(ownerOption.find((data) => data.value === organization))
  const [loading, setLoading] = useState(false)

  const getAll =  async() => {
        const len = 'ALL'
        const result =  await Service.getAll(len)
        addLogisticWarehouseList(result.data)
  }

  const submit = async() => {
    try {
      setLoading(true)
        //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')
      const payload = LogisticWarehouseAdd(name.value, String(latitude), String(longitude), address.value, mitra.value)
      const result = await Service.post(payload)
      await getAll()
      setLoading(false)
      swal("Success", result.message, "success").then(() => {
       props.history.push('/admin/logistic-warehouse-list')
      })
    } catch (error) {
      setLoading(false)
      return CatchError(error, props, refreshToken)
    }
  }

  const child = (
    <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Form Gudang</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="10">
                        <FormGroup>
                          <SelectBasic
                          label = {'Mitra'}
                          value = {mitra}
                          onChange = {mitraOnChange}
                          isDisabled = {!mitraSuper}
                          option = {ownerOption}
                        />
                          <Label>nama *</Label>
                          <Input
                            placeholder="nama"
                            {...name}
                            type="text"
                          />
                          <InputCoordinate
                            latitude = {latitude}
                            setLatitude = {latitudeOnChange}
                            longitude = {longitude}
                            setLongitude = {longitudeOnChange}
                          />
                          <Label>Alamat</Label>
                          <Input
                          className="react-select"
                          type="textarea"
                          {...address}
                          />
                        </FormGroup>
                        </Col>
                    </Row>
                  <Button onClick = {submit} color="info">Submit</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
  )

  return (
    <>
      <PanelHeader size="sm" />
      <LoadingScreen children={child} loading = {loading} text={"Upload Data"}/>
    </>
  );
}


export default LogisticItemPage;


