import Select from 'react-select'
const { Label } = require("reactstrap");

function SelectWithMessage({value, onChange, condition, errMesssage, message, label, isDisabled, option}) {
    return (
        <>
        <Label>{label || 'default Label'}</Label>
        <div>
            <Select
                // className="react-select"
                // classNamePrefix="react-select"
                placeholder={label || 'Select Here'}
                name="singleSelect"
                value={value}
                options={option}
                isDisabled = {isDisabled}
                onChange={(value) => onChange(value)} />
            {!condition ? (
                <label className="error">
                    {errMesssage || 'Default Error Msg'}
                </label>
            ) : <label className="success">
                {message || 'Message'}
            </label>}
        </div></>
    )
}

export default SelectWithMessage